import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ text, bold }) => (
  <p className={`vx_text-4 page-title${bold && ' bold'}`} data-automation-id="activityHeaderLabel">{text}</p>
);

PageTitle.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  bold: PropTypes.bool,
};

PageTitle.defaultProps = {
  bold: false,
};

export default PageTitle;
