import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import log from 'loglevel';
import { Col, Row, Text } from '@swift-paypal/pp-react';
import withUrlParams from '../../../hocs/with-url-params';
import { getShouldUseNewStips } from '../../../lib/stipulation-utils';
import { useShallowSelector } from '../../../hooks';
import { renewLoanAction } from '../../../redux/actions/account-items/account-items-actions';
import { markStipulationFulfilledAction } from '../../../redux/actions/notifications/notifications-actions';
import { activeIdsSelector } from '../../../redux/selectors';
import StipulationItemsList from '../../action-items/stipulation-items-list';
import LoanSummaryList from '../../loan-summary/loan-summary-list/loan-summary-list';
import { OffbookAdvanceTileList, OpportunitySummary } from '../../features';
import DocuSignComponent from '../docusign/docusign';
import LoanAgreementComponent from '../loan-agreement/loan-agreement';
import NoProducts from '../no-products';
import RenewalTile from '../renewal-tile/renewal-tile';
import { getNewStipulationFlagAction } from '../../../redux/actions/stipulations/stipulations-actions';

const AccountSummary = ({
  account,
  blnStipulations,
  country,
  flattenedStipulations,
  integratedDocusignUSEnabled,
  integratedDocusignCAEnabled,
  isRenewalEligible,
  markStipulationFulfilled,
  owners,
  renewalsEnabled,
  renewFunc,
  renewLoan,
  getNewStipulationFlag,
  isNewStipEnabled,
}) => {
  const { opportunityId } = useShallowSelector(activeIdsSelector);
  const [showNewStip, setShowNewStip] = useState(false);

  const loansForRenewal = account.loans.filter((loan) => loan.renewalEligible && loan.primaryLoanForRenewal && loan.opportunity.type !== 'SBA');
  let hasRenewals = false;

  if (flattenedStipulations?.length) {
    hasRenewals = !!flattenedStipulations.find((x) => x.isRenewal);
  }

  const integratedDocusignEnabled = (integratedDocusignUSEnabled && country === 'us') || (integratedDocusignCAEnabled && country === 'ca');
  let showDocuSign = false;
  let showLoanProcessing = false;

  if (owners && blnStipulations?.[0]?.applications?.length) {
    const {
      isAutomationQualified,
      isBankAccountGIACTVerified,
      isContractGenerated,
      isContractSigned,
      signer1OwnershipPct,
    } = blnStipulations[0].applications[0];
    const additionalSigners = owners.filter((owner) => !owner.isApplicant && owner.isSigner);

    showLoanProcessing = isContractSigned;
    showDocuSign = integratedDocusignEnabled && signer1OwnershipPct > 50
      && (
        (country === 'us' && isAutomationQualified && isBankAccountGIACTVerified)
        || (isContractGenerated && additionalSigners.length === 0)
      );
  }

  const showBlnStipulations = blnStipulations && !loansForRenewal.length && !hasRenewals && country !== 'ca';
  const { opportunity } = account.inFlightOpportunities?.find(({ opportunity: opp }) => opp.id === opportunityId) || {};

  let showNoProduct;
  let showOpportunitySummary;
  let stage;
  let isBLNStip = false;

  if (opportunity) {
    stage = opportunity.stages?.find((st) => st.stageName === opportunity.stage);
    showOpportunitySummary = getShouldUseNewStips(country, opportunity.type);
    isBLNStip = opportunity.type === 'BLN';

    if (opportunity.type === 'SBA' && opportunity.stageName === 'Closed Lost') {
      showNoProduct = opportunity.adverseActionNoticeSent;
    }

    if (!opportunity.stageName === 'Closed Lost') {
      log.error(`Error looking for stage. Opp id: ${opportunity.id} stage: ${stage}`);
    }
  }

  useEffect(async () => {
    if (opportunity && opportunity.id && getNewStipulationFlag) {
      await getNewStipulationFlag(opportunity.id);
    }
  }, [opportunity]);

  useEffect(() => {
    if (isNewStipEnabled) {
      setShowNewStip(true);
    }
  }, [isNewStipEnabled]);

  if (stage?.stageName === 'ST3') {
    return (
      <Row>
        <Col>
          <Text>{stage.detailText}</Text>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {(stage?.stageName === 'ST4')
        && (
          ((showDocuSign) && (
            <Row>
              <Col>
                <DocuSignComponent opportunityId={opportunity.id} showLoanProcessing={showLoanProcessing} />
              </Col>
            </Row>
          ))
          || ((country === 'us') && (
            <Text>{stage.detailText}</Text>
          ))
          || (
            <Row>
              <Col>
                <LoanAgreementComponent opportunityId={opportunity.id} />
              </Col>
            </Row>
          )
        )}

      {showOpportunitySummary && <OpportunitySummary isBLNStip={isBLNStip} />}

      {showNoProduct && <NoProducts account={account} />}

      {showBlnStipulations && !showNewStip && (
        <StipulationItemsList
          renewFunc={renewLoan}
          clearStipFunc={markStipulationFulfilled}
          stipulations={blnStipulations}
        />
      )}

      {showBlnStipulations && showNewStip && <OpportunitySummary isBLNStip={isBLNStip} />}

      {isRenewalEligible && loansForRenewal.length !== 0 && renewalsEnabled && (
        <Row align="center">
          <Col>
            <RenewalTile loan={loansForRenewal[0]} renewFunc={renewFunc} />
          </Col>
        </Row>
      )}
      <OffbookAdvanceTileList accountId={account.accountId} />
      <LoanSummaryList accountId={account.accountId} />
    </>
  );
};

const mapStateToProps = (state) => ({
  bfes: state.notifications.bfes,
  error: state.notifications.error,
  integratedDocusignUSEnabled: state.config.integratedDocusignUSEnabled,
  integratedDocusignCAEnabled: state.config.integratedDocusignCAEnabled,
  isRenewalEligible: state.isRenewalEligible,
  flattenedStipulations: state.notifications.flattenedStipulations,
  renewalsEnabled: state.config.renewalsEnabled,
  blnStipulations: state.notifications.stipulations,
  newStips: state.stipulations.allStipulations,
  isNewStipEnabled: state.newStipulationFlag.isEnabled,
});

const mapDispatchToProps = {
  renewLoan: renewLoanAction,
  markStipulationFulfilled: markStipulationFulfilledAction,
  getNewStipulationFlag: getNewStipulationFlagAction,
};

AccountSummary.propTypes = {
  account: PropTypes.shape({
    accountId: PropTypes.string,
    hasClosedLostOpportunities: PropTypes.bool,
    loans: PropTypes.arrayOf(PropTypes.object),
    inFlightOpportunities: PropTypes.arrayOf(PropTypes.object),
  }),
  blnStipulations: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.string,
  flattenedStipulations: PropTypes.arrayOf(PropTypes.object),
  integratedDocusignUSEnabled: PropTypes.bool.isRequired,
  integratedDocusignCAEnabled: PropTypes.bool.isRequired,
  isRenewalEligible: PropTypes.bool,
  markStipulationFulfilled: PropTypes.func,
  owners: PropTypes.arrayOf(PropTypes.object),
  renewalsEnabled: PropTypes.bool,
  renewFunc: PropTypes.func.isRequired,
  renewLoan: PropTypes.func,
  getNewStipulationFlag: PropTypes.func,
  isNewStipEnabled: PropTypes.bool,
};

AccountSummary.defaultProps = {
  account: undefined,
  blnStipulations: undefined,
  country: undefined,
  flattenedStipulations: undefined,
  isRenewalEligible: false,
  markStipulationFulfilled: undefined,
  owners: undefined,
  renewalsEnabled: false,
  renewLoan: undefined,
  getNewStipulationFlag: undefined,
  isNewStipEnabled: false,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlParams,
)(AccountSummary);

export const AccountSummaryPage = AccountSummary;
