import { formName } from './fields/field-constants';

export { useShallowSelector } from '../../hooks/useShallowSelector';

export const fieldSelector = (fieldName) => (state) => ({
  error: state.form?.[formName]?.syncErrors?.[fieldName],
  registered: state.form?.[formName]?.registeredFields?.[fieldName],
  touched: state.form?.[formName]?.fields?.[fieldName]?.touched,
  value: state.form?.[formName]?.values?.[fieldName],
});

export const pageSelector = (state) => ({
  advanceId: state.activeIds.advanceId,
  advancesById: state.entities.advances.byId,
  bfes: state.accountItems.bfes,
  caseWorker: state.entities.loanForgiveness.byId[state.activeIds.forgivenessId]?.caseWorker,
  environment: state.config.environment,
  query: state.router.location?.query,
});

export const componentSelector = (state) => ({
  advanceId: state.activeIds.advanceId,
  advances: state.entities.advances,
  country: state.country.country,
  forgivenessId: state.activeIds?.forgivenessId,
  loanForgiveness: state.entities.loanForgiveness,
  opportunities: state.entities.opportunities,
  registeredFields: state.form?.[formName]?.registeredFields,
  updateLoanForgiveness: state.updateLoanForgiveness,
  values: state.form?.[formName]?.values,
});
