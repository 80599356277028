export const CASE_TYPES_REQUEST_PENDING = 'case_types_pending';
export const CASE_TYPES_RECEIVED = 'case_types_received';
export const CASE_TYPES_REQUEST_ERROR = 'case_types_request_error';

export const CASE_SUBMIT_REQUEST_PENDING = 'case_submit_request_pending';
export const CASE_SUBMIT_RECEIVED = 'case_submit_received';
export const CASE_SUBMIT_REQUEST_ERROR = 'case_submit_request_error';

export const CONTACT_US_CLEAR_STATE = 'contact_us_clear_state';
export const CONTACT_US_RESET_COMPLETE = 'case_reset_complete';
