import React from 'react';
import './floating-label-field.js.less';
import PropTypes from 'prop-types';

const floatingLabelField = ({
  input,
  label,
  manualSet = false,
  type,
  className,
  noErrorClass,
  maxLength,
  autocomplete = 'on',
  disabled = false,
  meta: { touched, error, warning },
}) => (
  <div
    className={`${manualSet ? 'vx_floatingLabel_active' : 'vx_floatingLabel'}
        ${disabled ? 'vx_is-disabled' : ''}
        ${touched && error ? 'vx_has-error-with-message' : ''}
        ${(manualSet && input.value) ? 'hasValue' : ''}`}
    data-label-content={label}
  >
    <label htmlFor={input.name}>
      {label}
    </label>
    <input
      {...input}
      id={input.name}
      disabled={disabled}
      maxLength={maxLength}
      type={type}
      className={`${className} ${(!error || !touched) && noErrorClass ? noErrorClass : ''}`}
      autoComplete={autocomplete}
    />
    {touched && !disabled
        && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
          || (warning && <span className="vx_form-control-message margin-bottom-sm">{warning}</span>))}
  </div>
);

floatingLabelField.propTypes = {
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  manualSet: PropTypes.bool,
  maxLength: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  noErrorClass: PropTypes.string,
  type: PropTypes.string.isRequired,
};

floatingLabelField.defaultProps = {
  autocomplete: undefined,
  className: undefined,
  disabled: false,
  input: undefined,
  manualSet: false,
  maxLength: undefined,
  meta: undefined,
  noErrorClass: undefined,
};

export default floatingLabelField;
