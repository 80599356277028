/* eslint-disable no-nested-ternary */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Button, Col, Divider, Row,
} from '@swift-paypal/pp-react';
import routes from '../../../routes';
import resolve from '../../../services/route.service';
import withUrlParams from '../../../hocs/with-url-params';
import './stipulation-item.less';

class StipulationItem extends React.Component {
  constructor(props) {
    super(props);
    this.renew = this.renew.bind(this);
  }

  renew() {
    const { opportunity, renewFunc } = this.props;
    renewFunc(opportunity.account.id);
  }

  render() {
    const {
      country,
      stipulation,
      opportunity,
      successStipulationGroupName,
      successStipulationName,
    } = this.props;

    let stipulationName = stipulation.isBankAccountVerification ? stipulation.name : stipulation.groupName;
    if (stipulationName.length > 1) {
      stipulationName = stipulationName.substring(0, 1).toUpperCase()
        + stipulationName.substring(1, stipulationName.length).toLowerCase();
    }
    if (successStipulationGroupName === stipulation.groupName
      || successStipulationName === stipulation.name) {
      // set stip as fulfilled in case salesforce update is slightly behind
      stipulation.isFulfilled = true;
    }

    return (
      <div className="item">
        {stipulation
        && (
          <>
            <Divider />
            <Row className="stipulation">
              <Col xs={6}>
                {stipulationName}
              </Col>
              <Col xs={6} className="call-to-action">
                {stipulation.isFulfilled
                  ? (
                    <div data-fulfilled className="center-content fulfilled-container">
                      <span className="vx_icon vx_icon-large vx_icon-positive-large fulfilled" />
                    </div>
                  )
                  : stipulation.isDocument
                    ? (
                      <Button
                        as={Link}
                        to={resolve(routes['upload-document-group'].path, {
                          country,
                          opportunityId: opportunity.id,
                          groupName: stipulation.groupName || stipulation.name,
                        })}
                        secondary
                        size="sm"
                        className="fixed-btn"
                        data-document
                      >
                        <span data-text>{stipulation.hasFulfilledStipulations ? 'Continue' : 'Get Started'}</span>
                      </Button>
                    )
                    : stipulation.isReference
                      ? (
                        <Button
                          as={Link}
                          to={resolve(routes.references.path, {
                            country,
                            opportunityId: opportunity.id,
                            groupName: stipulation.groupName,
                          })}
                          secondary
                          size="sm"
                          className="fixed-btn"
                          data-form
                        >
                          Get Started
                        </Button>
                      )
                      : stipulation.isBankAccountInformation
                        ? (
                          <Button
                            as={Link}
                            to={resolve(routes['application-add-bank-accounts'].path, {
                              country,
                              opportunityId: opportunity.id,
                              stipulationName: stipulation.name,
                            })}
                            secondary
                            size="sm"
                            className="fixed-btn"
                            data-bank
                          >
                            Get Started
                          </Button>
                        )
                        : stipulation.isBankAccountVerification
                          ? (
                            <Button
                              as={Link}
                              to={resolve(routes['application-verify-bank-accounts'].path, {
                                country,
                                opportunityId: opportunity.id,
                                stipulationName: stipulation.name,
                              })}
                              secondary
                              size="sm"
                              className="fixed-btn"
                              data-bank
                            >
                              Get Started
                            </Button>
                          )
                          : (
                            <Button
                              as={Link}
                              to={resolve(routes['application-ownership'].path, {
                                country,
                                opportunityId: opportunity.id,
                                groupName: stipulation.groupName,
                                stipulationType: stipulation.type,
                                stipulationName: stipulation.name,
                              })}
                              secondary
                              size="sm"
                              className="fixed-btn"
                              data-form
                            >
                              {stipulation.isIncompleteOwnershipStipulation ? 'Continue' : 'Get Started'}
                            </Button>
                          )}
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

StipulationItem.propTypes = {
  country: PropTypes.string,
  opportunity: PropTypes.shape({
    id: PropTypes.string,
    account: PropTypes.object,
  }),
  renewFunc: PropTypes.func,
  stipulation: PropTypes.shape({
    name: PropTypes.string,
    groupName: PropTypes.string,
    isFulfilled: PropTypes.bool,
    isBankAccountVerification: PropTypes.shape({}),
    isDocument: PropTypes.bool,
    hasFulfilledStipulations: PropTypes.bool,
    isReference: PropTypes.bool,
    isBankAccountInformation: PropTypes.shape({
      groupName: PropTypes.string,
      index: PropTypes.number,
      isBankAccountInformation: PropTypes.bool,
      isDocument: PropTypes.bool,
      isRenewal: PropTypes.bool,
      isRequired: PropTypes.bool,
      name: PropTypes.string,
      opportunityId: PropTypes.string,
      type: PropTypes.string,
    }),
    type: PropTypes.string,
    isIncompleteOwnershipStipulation: PropTypes.bool,
  }),
  successStipulationGroupName: PropTypes.string,
  successStipulationName: PropTypes.string,
};

StipulationItem.defaultProps = {
  country: undefined,
  opportunity: undefined,
  renewFunc: undefined,
  stipulation: undefined,
  successStipulationGroupName: undefined,
  successStipulationName: undefined,
};

export default compose(
  withRouter,
  withUrlParams,
  connect(),
)(StipulationItem);
