import { STIPULATIONS_REQUEST_RECEIVED } from '../actions/stipulations-entity/stipulations-entity-action-types';

const defaultState = {
  allIds: [],
  byId: {},
};

const stipulations = (state = defaultState, action) => {
  const results = action.payload;
  switch (action.type) {
    case STIPULATIONS_REQUEST_RECEIVED:
      return {
        allIds: results.map((stipulation) => stipulation.id),
        byId: results.reduce((result, stipulation) => {
          result[stipulation.id] = stipulation;
          return result;
        }, {}),
      };
    default:
      return state;
  }
};

export default stipulations;
