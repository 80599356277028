import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

async function updateLoanForgiveness(apiUrl, data) {
  const url = `${apiUrl}/v2/loan-forgiveness`;
  const res = await axios.patch(url, data, defaultRequestOptions());
  return res.data;
}

async function resumeLoanForgivenessApp(apiUrl, forgivenessId) {
  const url = `${apiUrl}/v2/loan-forgiveness/resume`;
  const data = {
    loanForgivenessId: forgivenessId,
  };
  const res = await axios.patch(url, data, defaultRequestOptions());
  return res;
}
const debouncedUpdateLoanForgiveness = asyncDebounce(updateLoanForgiveness);

export default {
  updateLoanForgiveness: debouncedUpdateLoanForgiveness,
  resumeLoanForgivenessApp,
};
