import React from 'react';
import PropTypes from 'prop-types';

const renderTextArea = ({
  className,
  input,
  label,
  noErrorClass,
  meta: { touched, error, warning },
}) => (
  <div className={(touched && error) ? 'vx_has-error-with-message' : ''}>
    <textarea
      {...input}
      className={`${className} ${(!error || !touched) ? noErrorClass : 'unround-corners'}`}
      aria-label={label}
    />
    {touched
      && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
        || (warning && <span className="vx_form-control-message margin-bottom-sm">{warning}</span>))}
  </div>
);

renderTextArea.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  noErrorClass: PropTypes.string,
};

renderTextArea.defaultProps = {
  className: undefined,
  input: undefined,
  meta: undefined,
  noErrorClass: undefined,
};

export default renderTextArea;
