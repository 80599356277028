import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BodyText, Tooltip } from '@swift-paypal/pp-react';
import SBAForm3508TooltipText from './sba-form-3508-tooltip-text';

const StandardBodyText = ({ loanbuilderUrl }) => (
  <>
    <BodyText as="p">
      Download and complete the
      {' '}
      <a
        href={`${loanbuilderUrl}/sba_form_3508ez.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        data-automation-id="downloadSBAForm3508EZLink"
      >
        SBA Form 3508EZ
      </a>
      {' '}
      if you meet any of the following criteria:
    </BodyText>
    <ul className="forgivenessListItems">
      <BodyText as="li">
        You are self-employed, an independent contractor, or a sole proprietor and had no employees at the time of your
        PPP loan application.
      </BodyText>
      {' '}
      <BodyText as="li">
        Between Jan 1, 2020 and the end of the
        covered period or alternative payroll covered period
        <Tooltip
          className="pp-react__tooltip--inline"
          description={<SBAForm3508TooltipText />}
          dataAutomationId="coveredPeriodTooltip"
        />
        , you did not reduce the number of employees or the average paid
        hours of your employees;
        <strong> and</strong>
        , during your covered period you did not reduce the
        salaries or wages of any of your employees by more than 25% and did not reduce the
        number of hours of your employees compared to the period between Jan 1, 2020 – Mar 31, 2020.
      </BodyText>
      {' '}
      <BodyText as="li">
        Your business was unable to operate during your covered period at the same level of business activity
        as it did before Feb 15, 2020 because of COVID-19 related governmental requirements, restrictions,
        or guidance;
        <strong> and</strong>
        , during your covered period you did not reduce the salaries or wages of any of your employees
        by more than 25% and did not reduce the number of hours of your employees compared to the period between Jan 1,
        2020 - Mar 31, 2020.
      </BodyText>
    </ul>
    <BodyText as="p">
      Otherwise, download and complete the standard
      {' '}
      <a
        href={`${loanbuilderUrl}/sba_form_3508.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        data-automation-id="downloadSBAForm3508Link"
      >
        SBA Form 3508.
      </a>
    </BodyText>
    <BodyText as="p" className="upload-instructions">
      Upload your completed and signed copy of the SBA Form 3508EZ or the standard
      SBA Form 3508
    </BodyText>
  </>
);

StandardBodyText.propTypes = {
  loanbuilderUrl: PropTypes.string.isRequired,
};

const SBAFormStipSBodyText = ({ loanbuilderUrl }) => (
  <BodyText as="p">
    Upload your completed and signed copy of the
    {' '}
    <a
      href={`${loanbuilderUrl}/sba_form_3508S.pdf`}
      target="_blank"
      rel="noopener noreferrer"
      data-automation-id="downloadSBAForm3508SLink"
    >
      SBA Form 3508S
    </a>
    .
  </BodyText>
);

SBAFormStipSBodyText.propTypes = {
  loanbuilderUrl: PropTypes.string.isRequired,
};

const SBAFormStipEzBodyText = ({ loanbuilderUrl }) => (
  <BodyText as="p">
    Upload your completed and signed copy of the
    {' '}
    <a
      href={`${loanbuilderUrl}/sba_form_3508ez.pdf`}
      target="_blank"
      rel="noopener noreferrer"
      data-automation-id="downloadSBAForm3508EZLink"
    >
      SBA Form 3508EZ
    </a>
    .
  </BodyText>
);

SBAFormStipEzBodyText.propTypes = {
  loanbuilderUrl: PropTypes.string.isRequired,
};

const SBAFormStipBodyText = ({ loanbuilderUrl }) => (
  <BodyText as="p">
    Upload your completed and signed copy of the
    {' '}
    <a
      href={`${loanbuilderUrl}/sba_form_3508.pdf`}
      target="_blank"
      rel="noopener noreferrer"
      data-automation-id="downloadSBAForm3508Link"
    >
      SBA Form 3508
    </a>
    .
  </BodyText>
);

SBAFormStipBodyText.propTypes = {
  loanbuilderUrl: PropTypes.string.isRequired,
};

const SBAForm3508BodyText = ({
  advanceId, advancesById, forgivenessAppEnabled, loanbuilderUrl, loanForgivenessById, userSetEZAppEligible,
}) => {
  const advance = advanceId ? advancesById[advanceId] : undefined;
  const forgivenessObject = Object.values(loanForgivenessById)
    .find((forgiveness) => forgiveness.advanceId === advance.id);

  const getBodyText = () => {
    if (!forgivenessAppEnabled) {
      return <StandardBodyText loanbuilderUrl={loanbuilderUrl} />;
    }

    if (forgivenessObject?.applicationVersionSelected === '3508S') {
      return <SBAFormStipSBodyText loanbuilderUrl={loanbuilderUrl} />;
    }

    if (advance?.ezAppEligible || userSetEZAppEligible) {
      return <SBAFormStipEzBodyText loanbuilderUrl={loanbuilderUrl} />;
    }

    return <SBAFormStipBodyText loanbuilderUrl={loanbuilderUrl} />;
  };

  return (
    <div className="body-text">
      {getBodyText()}
    </div>
  );
};

SBAForm3508BodyText.propTypes = {
  advanceId: PropTypes.string,
  advancesById: PropTypes.arrayOf(PropTypes.shape({
    ezAppEligible: PropTypes.bool.isRequired,
    ezQuestionnaireCompleted: PropTypes.string,
  })).isRequired,
  forgivenessAppEnabled: PropTypes.bool.isRequired,
  loanbuilderUrl: PropTypes.string.isRequired,
  loanForgivenessById: PropTypes.shape({}).isRequired,
  userSetEZAppEligible: PropTypes.bool.isRequired,
};

SBAForm3508BodyText.defaultProps = {
  advanceId: undefined,
};

function mapStateToProps(state) {
  return {
    advancesById: state.entities.advances.byId,
    forgivenessAppEnabled: state.config.forgivenessAppEnabled,
    loanbuilderUrl: state.config.loanbuilderUrl,
    loanForgivenessById: state.entities?.loanForgiveness?.byId,
    userSetEZAppEligible: state.userSetEZAppEligible,
  };
}

export default connect(mapStateToProps)(SBAForm3508BodyText);
