import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { CaptionText } from '@swift-paypal/pp-react';
import { minimumRequiredFileSize } from '../../../../constants/upload-document';
import uploadIcon from '../../../../static/images/icons/upload-icon.svg';
import './upload-section.less';

const bytesInMb = 1024 * 1024;

const UploadSectionComponent = ({
  allowedFileExtensions,
  dropFilesFunc,
  maximumPerFileSizeInBytes,
  maximumTotalSizeInBytes,
  rejectFilesFunc,
  helperText,
}) => {
  const perFileMaxInMb = Math.floor(maximumPerFileSizeInBytes / bytesInMb);
  const totalMaxInMb = Math.floor(maximumTotalSizeInBytes / bytesInMb);
  return (
    <div className="upload-section">
      {helperText && (
        <CaptionText strong className="helper-text text">{helperText}</CaptionText>
      )}
      <div className="dropzone-container">
        <Dropzone
          multiple
          onDrop={dropFilesFunc}
          onDropRejected={rejectFilesFunc}
          maxSize={maximumPerFileSizeInBytes}
          minSize={minimumRequiredFileSize}
          accept={allowedFileExtensions.map((ext) => `.${ext}`)}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone-area" {...getRootProps()}>
              <div>
                <div className="align-center">
                  <img className="upload" src={uploadIcon} alt="Upload icon" />
                </div>
                <div>
                  <input aria-label="File Upload" title="File Upload" id="fileUploadInput" {...getInputProps()} />
                  <div className="vx_text-body-md text">Drag and drop or click to browse</div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="file-info-container vx_text-body-sm text">
        {allowedFileExtensions.map((ext) => ext.toUpperCase()).join(' ')}
        {` | Up to ${perFileMaxInMb}MB each, ${totalMaxInMb}MB total`}
      </div>
    </div>
  );
};

UploadSectionComponent.propTypes = {
  allowedFileExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  dropFilesFunc: PropTypes.func.isRequired,
  maximumPerFileSizeInBytes: PropTypes.number.isRequired,
  maximumTotalSizeInBytes: PropTypes.number.isRequired,
  rejectFilesFunc: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};

UploadSectionComponent.defaultProps = {
  helperText: undefined,
};

export default UploadSectionComponent;
