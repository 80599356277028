import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  confirmation, format, length, required,
} from 'redux-form-validators';
import escapeStringRegexp from '../../regex';
import constants from '../../../constants/index';
import newPasswordField from './new-password-field';
import standardPasswordField from './standard-password-field';
import './password-field.less';

const { errors, passwordLength, regex } = constants;

const newPasswordValidators = [
  required({ msg: errors.requiredField }),
  format({ with: regex.letterNumber.pattern }),
  format({ with: regex.specialChars.pattern }),
  length({ min: passwordLength.min, max: passwordLength.max }),
];

class PasswordField extends React.Component {
  static propTypes = {
    confirmationField: PropTypes.string, // name of the field to compare with for confirmation
    label: PropTypes.string.isRequired, // field label
    name: PropTypes.string.isRequired, // name of the field
    newPassword: PropTypes.bool, // set to true if this password is new and needs the checklist
    username: PropTypes.string,
  };

  static defaultProps = {
    confirmationField: undefined,
    newPassword: undefined,
    username: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  toggleShow = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  render() {
    const {
      name, label, newPassword, confirmationField, username,
    } = this.props;

    const { show } = this.state;
    const confirmationValidators = confirmationField
      ? [
        confirmation({
          field: confirmationField,
          message: errors.passwordMatch,
        }),
        required({ msg: errors.requiredField }),
      ] : null;

    let usernameRegex,
      usernameValidator;
    if (username) {
      usernameRegex = new RegExp(`^(?!${escapeStringRegexp(username)}$).*$`);
      usernameValidator = format({
        with: usernameRegex,
        message: errors.cannotBeUsername,
      });
    }

    return (
      <>
        {newPassword ? (
          <Field
            name={name}
            component={newPasswordField}
            label={label}
            show={show}
            toggleShow={this.toggleShow}
            validate={[...newPasswordValidators, usernameValidator]}
            props={{ usernameRegex }}
          />
        ) : (
          <Field
            name={name}
            component={standardPasswordField}
            label={label}
            show={show}
            toggleShow={this.toggleShow}
            validate={confirmationValidators || required({ msg: errors.requiredField })}
          />
        )}
      </>
    );
  }
}

export default PasswordField;
