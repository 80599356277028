import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, BodyText, Button, HeadingText,
} from '@swift-paypal/pp-react';
import { createPppLeadAction } from '../../../redux/actions/account/account-actions';
import './apply-for-ppp-loan-tile.less';

const ApplyForPPPLoanTile = ({
  createPppLead,
  opportunities,
  pppV3PhaseOneFirstDrawEnabled,
  pppV3PhaseOneSecondDrawEnabled,
}) => {
  if (!opportunities) {
    return (<></>);
  }

  const sbaOneLoanExists = opportunities.some((opp) => ['20PPPLoan'].includes(opp.loanVersion));
  const sbaTwoLoanExists = opportunities.some((opp) => ['21PPPLoan'].includes(opp.loanVersion));
  const secondDrawLoanExists = opportunities.some((opp) => ['21SDPPP'].includes(opp.loanVersion));

  const opportunity = opportunities?.[0];

  async function applyForPppLoan() {
    await createPppLead(opportunity?.accountId, opportunity?.contactId);
  }

  if (pppV3PhaseOneFirstDrawEnabled && !sbaTwoLoanExists && !sbaOneLoanExists && !secondDrawLoanExists) {
    return (
      <Container className="apply-for-ppp-loan-tile" fluid>
        <Row align="center">
          <Col>
            <HeadingText size="sm" className="apply-for-ppp-loan-tile__learn-more">
              Apply for the 2021 PPP Loan
            </HeadingText>
            <BodyText className="apply-for-ppp-loan-tile__learn-more">
              Complete the online application to see if your business may be eligible for a
              Paycheck Protection Program Loan brought to you by PayPal.
            </BodyText>
            <Button
              className="apply-for-ppp-loan-tile__apply-now"
              onClick={applyForPppLoan}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  if (pppV3PhaseOneSecondDrawEnabled && !sbaTwoLoanExists && !secondDrawLoanExists && sbaOneLoanExists) {
    return (
      <Container className="apply-for-ppp-loan-tile" fluid>
        <Row align="center">
          <Col>
            <HeadingText size="sm" className="apply-for-ppp-loan-tile__learn-more">
              Apply for the 2021 Second Draw PPP Loan
            </HeadingText>
            <BodyText className="apply-for-ppp-loan-tile__learn-more">
              Complete the online application to see if your business may be eligible for a Second
              Draw Paycheck Protection Program Loan brought to you by PayPal.
            </BodyText>
            <Button
              className="apply-for-ppp-loan-tile__apply-now"
              onClick={applyForPppLoan}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  return (<></>);
};

ApplyForPPPLoanTile.propTypes = {
  createPppLead: PropTypes.func.isRequired,
  opportunities: PropTypes.arrayOf(PropTypes.shape({
    byId: PropTypes.object,
  })),
  pppV3PhaseOneFirstDrawEnabled: PropTypes.bool,
  pppV3PhaseOneSecondDrawEnabled: PropTypes.bool,
};

ApplyForPPPLoanTile.defaultProps = {
  opportunities: [],
  pppV3PhaseOneFirstDrawEnabled: undefined,
  pppV3PhaseOneSecondDrawEnabled: undefined,
};

function mapStateToProps(state) {
  return {
    accounts: state.accountItems.accounts,
    opportunities: Object.values(state.entities.opportunities?.byId || {}),
    pppV3PhaseOneFirstDrawEnabled: state.config.pppV3PhaseOneFirstDrawEnabled,
    pppV3PhaseOneSecondDrawEnabled: state.config.pppV3PhaseOneSecondDrawEnabled,
  };
}

const mapDispatchToProps = {
  createPppLead: createPppLeadAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyForPPPLoanTile);
