import React from 'react';
import PropTypes from 'prop-types';
// noinspection ES6UnusedImports
// eslint-disable-next-line no-unused-vars
import { hot } from 'react-hot-loader';
import CsvDownloader from 'react-csv-downloader';

const CsvDownload = ({
  content,
  columns,
  filename,
  linkStyleClass,
  text,
}) => (
  <CsvDownloader
    bom={false}
    filename={filename}
    columns={columns}
    datas={content}
    text={text}
  >
    <button type="button" className={`button-link ppvx_link ${linkStyleClass}`} data-automation-id="exportActivityLink">{text}</button>
  </CsvDownloader>
);

CsvDownload.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  filename: PropTypes.string,
  linkStyleClass: PropTypes.string,
  text: PropTypes.string,
};

CsvDownload.defaultProps = {
  linkStyleClass: 'vx_anchor clickable inline-block',
  text: 'Export activity csv',
  filename: 'download',
};

export default CsvDownload;
