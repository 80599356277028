import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import AnonymousPage from '../hocs/anonymous-page';
import getApiHealthAction from '../redux/actions/maintenance/maintenance-actions';
import MaintenanceComponent from '../components/maintenance/maintenance';
import { createUrlBeforeApiWentDown as createUrlBeforeApiWentDownAction } from '../redux/actions/redirect-urls/redirect-urls-actions';
import { setInterval, clearInterval } from '../services/window.service';
import displayNames from '../constants/displayNames';

class MaintenancePage extends React.Component {
  static displayName = displayNames.MaintenancePage;

  static propTypes = {
    canadaCustomerSupportPhone: PropTypes.string,
    canadaCustomerSupportEmail: PropTypes.string,
    country: PropTypes.string,
    customerSupportPhone: PropTypes.string,
    customerSupportEmail: PropTypes.string,
    createUrlBeforeApiWentDown: PropTypes.func.isRequired,
    getApiHealth: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    redirect: PropTypes.string,
    urlBeforeApiWentDown: PropTypes.string,
  };

  static defaultProps = {
    canadaCustomerSupportPhone: undefined,
    canadaCustomerSupportEmail: undefined,
    country: undefined,
    customerSupportPhone: undefined,
    customerSupportEmail: undefined,
    redirect: undefined,
    urlBeforeApiWentDown: undefined,
  };

  constructor(props) {
    super(props);

    this.checkInterval = null;
    this.checkApiHealth = this.checkApiHealth.bind(this);
  }

  componentDidMount() {
    const {
      createUrlBeforeApiWentDown,
      redirect,
    } = this.props;

    if (redirect) {
      createUrlBeforeApiWentDown(decodeURIComponent(redirect));
    }
    this.checkApiHealth();
    this.checkInterval = setInterval(this.checkApiHealth, 30000);
  }

  componentWillUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  checkApiHealth() {
    const {
      getApiHealth,
      urlBeforeApiWentDown,
      match,
    } = this.props;
    getApiHealth(match, urlBeforeApiWentDown, 'maintenance');
  }

  render() {
    const {
      canadaCustomerSupportPhone,
      canadaCustomerSupportEmail,
      country,
      customerSupportPhone,
      customerSupportEmail,
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>Maintenance</title>
        </Helmet>
        { country === 'ca'
          ? <MaintenanceComponent phone={canadaCustomerSupportPhone} email={canadaCustomerSupportEmail} />
          : <MaintenanceComponent phone={customerSupportPhone} email={customerSupportEmail} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerSupportPhone: state.config.customerSupportPhone,
    customerSupportEmail: state.config.customerSupportEmail,
    canadaCustomerSupportPhone: state.config.canadaCustomerSupportPhone,
    canadaCustomerSupportEmail: state.config.canadaCustomerSupportEmail,
    urlBeforeApiWentDown: state.redirectUrls.urlBeforeApiWentDown,
  };
}

export default connect(
  mapStateToProps,
  {
    getApiHealth: getApiHealthAction,
    createUrlBeforeApiWentDown: createUrlBeforeApiWentDownAction,
  },
)(AnonymousPage(MaintenancePage));
