import { last } from 'lodash';

export default {
  buildPayload,
  buildMultipleFilesPayload,
};

function normalizeFileExt(fileBlob) {
  const fileExt = last(fileBlob.name.split('.'));
  return fileBlob.name.replace(`.${fileExt}`, `.${fileExt.toLowerCase()}`);
}

function buildPayload(file, opportunityId, documentType) {
  if (!file || !opportunityId || !documentType) {
    return null;
  }

  const newFileName = normalizeFileExt(file);
  const payload = new FormData();
  payload.append('file', file, newFileName);
  payload.append('opportunity_id', opportunityId);
  payload.append('document_type', documentType);

  return payload;
}

function buildMultipleFilesPayload(files, opportunityId, documentType) {
  if (!files || !files.length || !opportunityId || !documentType) {
    return null;
  }
  const payload = new FormData();
  files.forEach((file) => {
    payload.append('file', file, normalizeFileExt(file));
  });
  payload.append('opportunity_id', opportunityId);
  payload.append('document_type', documentType);

  return payload;
}
