import {
  stageContent2020,
  stageContent2021FirstDraw,
  stageContent2021SecondDraw,
  stageContentOther,
} from './forgiveness-stage-messaging-constants';

export const retrieveTileMessaging = (stage, conditionalMap) => {
  const { applicationVersionSelected, loanVersion } = conditionalMap;
  if (loanVersion === '21PPPLoan') {
    return stageContent2021FirstDraw[stage];
  }
  if (loanVersion === '21SDPPP') {
    return stageContent2021SecondDraw[stage];
  }
  if (stage === 'Document Upload' && applicationVersionSelected === '3508S') {
    return stageContentOther.ppp213508SDocumentUpload;
  }
  return stageContent2020[stage];
};

export const retrieveApplicationLabel = (loanVersion) => {
  if (loanVersion === '21SDPPP') {
    return 'PPP Loan 2 - Forgiveness';
  }

  return 'PPP Loan 1 - Forgiveness';
};
