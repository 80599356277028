module.exports = {
  navigateToSsoWindow,
};

function navigateToSsoWindow(paypalBaseUrl, paypalAuthorizeUrl, paypalClientId, paypalCallbackUrl, country = 'us') {
  const baseUrl = paypalBaseUrl;
  const authorizeUrl = paypalAuthorizeUrl;
  const clientId = paypalClientId;
  const callbackUrl = paypalCallbackUrl.replace('/us/', `/${country}/`);
  const paypalAuthUrl = `${baseUrl}${authorizeUrl}?client_id=${clientId}&redirect_uri=${callbackUrl}&response_type=code`;

  window.location.href = paypalAuthUrl;
  return paypalAuthUrl;
}
