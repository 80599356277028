import {
  ACCOUNTS_REQUEST_RECEIVED,
} from '../actions/account/account-action-types';

const defaultState = {
  byId: {},
  allIds: [],
};

const accounts = (state = defaultState, action) => {
  const results = action.payload;
  switch (action.type) {
    case ACCOUNTS_REQUEST_RECEIVED:
      return {
        allIds: results.map((account) => account.id),
        byId: results.reduce((result, account) => {
          result[account.id] = account;
          return result;
        }, {}),
      };
    default:
      return state;
  }
};

export default accounts;
