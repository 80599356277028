export const CUSTOMER_SIGNOFF_MESSAGING = `We finished reviewing your application.
    Please confirm that you agree with the loan forgiveness amount recommendation that we'll submit to the SBA.`;

export const TITLE = 'PPP loan forgiveness application';

export const REVIEW = 'Review';

export const TITLE_21_FIRST_DRAW = '2021 First Draw PPP loan forgiveness application';

export const TITLE_21_SECOND_DRAW = '2021 Second Draw PPP loan forgiveness application';
