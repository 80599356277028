import util from '../../../util';
import { FORGIVENESS_STAGES } from './forgiveness-summary-tile-constants';

export default function shouldHideForgivenessTile(
  isGuarantyClaimAvailable, forgivenessStage, estimatedMaturityDate, loanForgivenessId,
) {
  if (isGuarantyClaimAvailable) return true;

  const isTermInPastOrToday = util.isDateInPastOrToday(estimatedMaturityDate);

  // borrower applied but application was never completed and expired
  const hideSummaryTileStages = [FORGIVENESS_STAGES.EXPIRED];

  if ((!loanForgivenessId || !forgivenessStage
    || (loanForgivenessId && hideSummaryTileStages.includes(forgivenessStage)))
  && isTermInPastOrToday) {
    return true;
  }
  return false;
}
