import { BodyText, Button } from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRedirect, useShallowSelector } from '../../../../hooks';
import { setActiveAdvanceId, setActiveForgivenessId } from '../../../../redux/actions/active-ids/active-ids-actions';
import { getAdvancesAction } from '../../../../redux/actions/advances/advances-actions';
import { getOrCreateLoanForgivenessAction } from '../../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import routes from '../../../../routes';
import { ForYouCard } from '../../../features';
import { retrieveApplicationLabel } from '../forgiveness-stage-messaging/forgiveness-stage-messaging-utils';
import { FORGIVENESS_SUMMARY } from '../forgiveness-summary-tile-constants';
import './start2021Forgiveness3508S.less';
import { start2021Forgiveness3508SSelector } from './start2021Forgiveness3508S.selector';
import { loanVersionTag } from './start2021Forgiveness3508S.utils';

export const Start2021Forgiveness3508S = ({ advanceId }) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const { advances, loanForgiveness, portalRedesignEnabled } = useShallowSelector(start2021Forgiveness3508SSelector);
  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    const loanForgivenessId = loanForgiveness.allIds.find((id) => loanForgiveness.byId[id].advanceId === advanceId);
    if (isApplying && loanForgivenessId) {
      dispatch(getAdvancesAction());
      dispatch(setActiveAdvanceId(advanceId));
      dispatch(setActiveForgivenessId(loanForgivenessId));
      redirect(routes[FORGIVENESS_SUMMARY].path);
    }
  }, [loanForgiveness, isApplying]);

  const onApply = () => {
    dispatch(getOrCreateLoanForgivenessAction(advanceId));
    setIsApplying(true);
  };

  const loanVersion = advances.byId[advanceId]?.loanVersion;

  const title = loanVersionTag`Start your 3508S ${loanVersion} 2021 PPP loan forgiveness application`;
  const description = loanVersionTag`Provide the information to process your 2021 ${loanVersion} PPP loan forgiveness application.`;

  if (!portalRedesignEnabled) {
    return (
      <div className="start-2021-forgiveness-3508s">
        <BodyText strong>
          {title}
        </BodyText>
        <BodyText as="p">
          {description}
        </BodyText>
        <Button onClick={onApply}>
          Continue
        </Button>
      </div>
    );
  }

  return (
    <ForYouCard
      applicationLabel={retrieveApplicationLabel(loanVersion)}
      callToActionFunction={onApply}
      callToActionText="Continue"
      cardTitle={title}
      description={description}
    />
  );
};

Start2021Forgiveness3508S.propTypes = {
  advanceId: PropTypes.string.isRequired,
};
