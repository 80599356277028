import React from 'react';
import {
  Col, Container, Icon, Row,
} from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import inactiveCircle from './circle_empty.svg';
import activeCircle from './circle_green.svg';

const getProgressBarStyleClassName = (stageId, activeStage, index, stageLength) => {
  let styleName = index === 0 ? 'progress-begin ' : '';
  styleName = (index + 1) === stageLength ? 'progress-end ' : styleName;

  if (stageId < activeStage) {
    styleName += 'active';
  }
  else if (stageId === activeStage) {
    styleName += 'in-progress';
  }
  else {
    styleName += 'inactive';
  }
  return styleName;
};

const getTextStyleClassName = (stageId, activeStage, index, stageLength) => {
  let styleName = index === 0 ? 'stage-begin ' : '';
  styleName = (index + 1) === stageLength ? 'stage-end ' : styleName;

  if (stageId === activeStage) {
    styleName += 'stage-active vx_text-body-md_medium';
  }
  else if (stageId < activeStage) {
    styleName += 'stage-inactive vx_text-body-md_regular';
  }
  else {
    styleName += 'stage-inactive vx_text-body-md light-grey';
  }
  return styleName;
};

// This hacky addition exists because the pp-react library doesn't have empty circle icons yet.
const getIcon = (stageId, activeStageId) => {
  if (stageId < activeStageId) {
    return (
      <Icon
        name="check-alt"
        className="active-icon"
        size="xs"
      />
    );
  }
  if (stageId === activeStageId) {
    return (
      <img
        src={activeCircle}
        className="in-progress-icon"
        alt="Full Circle"
      />
    );
  }
  return (
    <img
      src={inactiveCircle}
      className="circle_empty"
      alt="Empty Circle"
    />
  );
};

const VerticalProgressTracker = (props) => {
  const {
    stages,
    activeStage,
  } = props;

  return (
    stages.map((stage, index) => {
      const stageId = stage.id + 1;
      const activeStageId = activeStage.id + 1;

      return (
        <Container className="progress-tracker__layout--vertical" key={stage.id}>
          <Row align="start">
            <Col
              xs={1}
              className={`progress-bar ${getProgressBarStyleClassName(stageId, activeStageId, index, stages.length)}`}
            >
              {getIcon(stageId, activeStageId)}
            </Col>
            <Col xs={11}>
              <div className={`progress-text ${getTextStyleClassName(stageId, activeStageId, index, stages.length)}`}>
                {stage.displayName}
              </div>
            </Col>
          </Row>
        </Container>
      );
    }));
};

VerticalProgressTracker.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
};

export default VerticalProgressTracker;
