import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { email, required } from 'redux-form-validators';
import PropTypes from 'prop-types';
import {
  CaptionText, Container, HeadingText, Button,
} from '@swift-paypal/pp-react';
import floatingLabelField from '../lib/forms/floating-label-field';
import { Alert, AlertTypes } from './common/alert';
import lbAppLogo from '../static/images/logos/lbapps.svg';
import './forgot-password-revamp.less';

export const ForgotPasswordForm = ({ handleSubmit, submitFunc, forgotPasswordError }) => (
  <Container className="form forgot-password align-center">
    <img src={lbAppLogo} alt="" className="lb-logo" />
    <HeadingText className="page-title fp-heading">Forgot your password?</HeadingText>
    <CaptionText className="fp-caption">
      Enter the email you used to create your account and
      we&apos;ll send you instructions to reset your password.
    </CaptionText>
    <form onSubmit={handleSubmit(submitFunc)} className="fp-input">
      {forgotPasswordError && <Alert message={forgotPasswordError} alertType={AlertTypes.critical} />}
      <Field
        name="email"
        manualSet
        className="form-control vx_form-control do-not-record"
        component={floatingLabelField}
        type="email"
        label="Email"
        noErrorClass="margin-bottom-sm"
        validate={[required({ msg: 'Email address is required' }), email({ msg: 'Invalid email address' })]}
      />
      <Button type="submit" className="next-button">Next</Button>
    </form>
  </Container>
);

ForgotPasswordForm.propTypes = {
  forgotPasswordError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitFunc: PropTypes.func.isRequired,
};

ForgotPasswordForm.defaultProps = {
  forgotPasswordError: undefined,
};

const ForgotPasswordRevamp = reduxForm({
  form: 'forgotPassword',
})(ForgotPasswordForm);

export default ForgotPasswordRevamp;
