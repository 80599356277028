import React from 'react';
import PropTypes from 'prop-types';
import './form-message.less';

const FormMessage = ({ message, iconClass }) => (
  <span className="form-message">
    {message}
    <span className={`${iconClass} message-icon`} />
  </span>
);

FormMessage.propTypes = {
  message: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
};

export default FormMessage;
