import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createNumberMask } from 'redux-form-input-masks';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import {
  required, numericality,
} from 'redux-form-validators';
import {
  BodyText, Button, Col, Link, Row, Tile,
} from '@swift-paypal/pp-react';
import withUrlParams from '../../hocs/with-url-params';
import PageTitle from '../common/page-title';
import { Alert, AlertTypes } from '../common/alert';
import './reduction-in-gross-receipts.less';
import constants from '../../constants';
import RadioField from './radio-field';
import TextField from './text-field';
import SelectionField from './selection-field';

const { errors } = constants;

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

export class ReductioninGrossReceiptsReduxComponent extends React.Component {
  static propTypes = {
    noTile: PropTypes.bool,
    updatePppObjectFunc: PropTypes.func.isRequired,
    submissionError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    handleSubmit: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunityId: PropTypes.string,
    syncErrors: PropTypes.objectOf(PropTypes.string),
    fields: PropTypes.objectOf(PropTypes.shape({
      touched: PropTypes.bool,
    })),
    registeredFields: PropTypes.objectOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  };

  static defaultProps = {
    noTile: false,
    submissionError: undefined,
    handleSubmit: undefined,
    match: undefined,
    opportunityId: undefined,
    syncErrors: {},
    fields: {},
    registeredFields: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      showQuartersVersion: undefined,
      showAnnualVersion: undefined,
      showQ2QuartersYesVersion: undefined,
      showQ2QuartersNoVersion: undefined,
    };
  }

  handleQ1 = (event) => {
    const { name, value } = event.target;
    const { answers } = this.state;

    if (name === 'q1') {
      if (value === 'Quarters') {
        this.setState({ showQuartersVersion: true, showAnnualVersion: false });
      }
      else if (value === 'Annual') {
        this.setState({ showQuartersVersion: false, showAnnualVersion: true });
      }
    }
    this.setState({ answers });
  }

  handleQ2Quarters = (event) => {
    const { name, value } = event.target;
    const { answers } = this.state;

    if (name === 'QuartersQ2') {
      if (value === 'Yes') {
        this.setState({ showQ2QuartersYesVersion: true, showQ2QuartersNoVersion: false });
      }
      else if (value === 'No') {
        this.setState({ showQ2QuartersYesVersion: false, showQ2QuartersNoVersion: true });
      }
    }
    this.setState({ answers });
  }

  submit = (values) => {
    const { updatePppObjectFunc, registeredFields } = this.props;

    const mapQuestionToField = {
      QuartersQ2: 'businessActive2019',
      Quarter2019: 'period2Quarter', // if undefined, default is 2019, else, this value is a quarter in 2019
      QuarterRevenue2019: 'period2Revenue', // if Quarter2019 is undefined, this is annual revenue for 2019, else, quarter revenue for 2019 in Quarter2019
      Quarter2020: 'period1Quarter', // if undefined, default is 2020, else, this value is a quarter in 2020
      QuarterRevenue2020: 'period1Revenue', // if Quarter2020 is undefined, this is annual revenue for 2020, else, quarter revenue for 2020 in Quarter2020
      QuartersQ2No: 'grossReceiptReductionIsDemonstrated',
      AnnualRevenue2019: 'period2Revenue', // see above
      AnnualRevenue2020: 'period1Revenue', // see above
    };

    const data = {};
    Object.values(registeredFields).forEach((field) => {
      if (mapQuestionToField[field.name]) {
        data[mapQuestionToField[field.name]] = values[field.name];
      }
    });

    const { match } = this.props;
    const { opportunityId } = match.params;

    const submitReductionInGrossReceiptsObject = {
      data,
      opportunityId,
    };

    updatePppObjectFunc(submitReductionInGrossReceiptsObject);
  }

  renderReductionInGrossReceiptsBody = (submissionError) => {
    const {
      showQuartersVersion,
      showAnnualVersion,
      showQ2QuartersYesVersion,
      showQ2QuartersNoVersion,
    } = this.state;
    const {
      handleSubmit,
      syncErrors,
      fields,
    } = this.props;
    return (
      <div className="reduction-in-gross-receipts-component">
        <PageTitle text="Reduction in Gross Receipts" />
        <BodyText as="h3">
          Use this form to provide the details on the reduction in Gross Receipts that
          you had specified on your PPP loan application.
        </BodyText>
        <BodyText as="h3">
          Applicants for loans of more than $150,000 must provide the additional
          information below to  support the 25% or more gross receipt reduction between
          your selected 2020 Quarter and your 2019 Reference Quarter, or, if you so elected,
          between Calendar Year 2020 and Calendar Year 2019.
        </BodyText>
        <BodyText as="h3">
          For more information see the
          {' '}
          <Link
            className="pp-react__link--inline"
            data-automation-id="sbaForm2483SDInstructions"
            href="https://www.sba.gov/document/sba-form-2483-sd-ppp-second-draw-borrower-application-form"
            target="_blank"
            rel="noopener noreferrer"
          >
            SBA Form 2483-SD Instructions
          </Link>
          .
        </BodyText>
        <form id="reductionInGrossReceiptsForm">
          <Row>
            <Col xs={12} sm={10}>
              {submissionError && (<Alert message={submissionError} alertType={AlertTypes.critical} />)}
              <div className="reduction-in-gross-receipts-component">
                <SelectionField
                  size="sm"
                  name="q1"
                  onChange={this.handleQ1}
                  errorText={fields.q1?.touched && syncErrors.q1}
                  label={(
                    <>
                      Would you like to compare 2019 to 2020 quarters or 2019 to 2020
                      annual periods for reduction in gross receipts?
                    </>
                  )}
                  validate={[required({ message: 'Required' })]}
                >
                  <option value="" key="" disabled>Select</option>
                  ,
                  <option
                    key="Quarters"
                    value="Quarters"
                  >
                    Quarters
                  </option>
                  <option
                    key="Annual"
                    value="Annual"
                  >
                    Annual
                  </option>
                </SelectionField>

                {showQuartersVersion && (
                  <>
                    <RadioField
                      name="QuartersQ2"
                      label={(
                        <>
                          Were you in business in 2019?
                        </>
                      )}
                      error={syncErrors.QuartersQ2}
                      touched={fields.QuartersQ2?.touched}
                      onChange={this.handleQ2Quarters}
                      options={[
                        {
                          className: 'form-control',
                          label: 'Yes',
                          name: 'QuartersQ2',
                          type: 'radio',
                          value: 'Yes',
                        },
                        {
                          className: 'form-control',
                          label: 'No',
                          name: 'QuartersQ2',
                          type: 'radio',
                          value: 'No',
                        },
                      ]}
                      validate={[
                        required({ msg: errors.requiredField }),
                      ]}
                    />
                    {showQ2QuartersYesVersion && (
                      <>
                        <SelectionField
                          size="sm"
                          name="Quarter2019"
                          errorText={fields.Quarter2019?.touched && syncErrors.Quarter2019}
                          label={(
                            <>
                              Select the 2019 quarter that you are using for comparison purposes
                            </>
                          )}
                          validate={[required({ message: 'Required' })]}
                        >
                          <option value="" key="" disabled>Select</option>
                          ,
                          <option
                            key="1Q"
                            value="1Q"
                          >
                            Q1
                          </option>
                          <option
                            key="2Q"
                            value="2Q"
                          >
                            Q2
                          </option>
                          <option
                            key="3Q"
                            value="3Q"
                          >
                            Q3
                          </option>
                          <option
                            key="4Q"
                            value="4Q"
                          >
                            Q4
                          </option>
                        </SelectionField>
                        <TextField
                          name="QuarterRevenue2019"
                          label={(
                            <>
                              Enter the Gross Receipts for your selected Quarter in 2019
                            </>
                          )}
                          error={syncErrors.QuarterRevenue2019}
                          touched={fields.QuarterRevenue2019?.touched}
                          validate={[
                            required({ msg: errors.enterAnAmount }),
                            numericality({ '>=': 0, msg: errors.enterAnAmount }),
                          ]}
                          {...currencyMask}
                        />
                        <SelectionField
                          size="sm"
                          name="Quarter2020"
                          errorText={fields.Quarter2020?.touched && syncErrors.Quarter2020}
                          label={(
                            <>
                              Select the 2020 quarter that you are using for comparison purposes
                            </>
                          )}
                          validate={[required({ message: 'Required' })]}
                        >
                          <option value="" key="" disabled>Select</option>
                          ,
                          <option
                            key="1Q"
                            value="1Q"
                          >
                            Q1
                          </option>
                          <option
                            key="2Q"
                            value="2Q"
                          >
                            Q2
                          </option>
                          <option
                            key="3Q"
                            value="3Q"
                          >
                            Q3
                          </option>
                          <option
                            key="4Q"
                            value="4Q"
                          >
                            Q4
                          </option>
                        </SelectionField>
                        <TextField
                          name="QuarterRevenue2020"
                          label={(
                            <>
                              Enter the Gross Receipts for your selected Quarter in 2020
                            </>
                          )}
                          error={syncErrors.QuarterRevenue2020}
                          touched={fields.QuarterRevenue2020?.touched}
                          validate={[
                            required({ msg: errors.enterAnAmount }),
                            numericality({ '>=': 0, msg: errors.enterAnAmount }),
                          ]}
                          {...currencyMask}
                        />
                      </>
                    )}
                    {showQ2QuartersNoVersion && (
                      <>

                        <RadioField
                          name="QuartersQ2No"
                          label={(
                            <>
                              If you were not in business during 2019, but were in operation on
                              February 15, 2020, did you have gross receipts during the second, third, or
                              fourth quarter of 2020 that demonstrate at least a 25 percent reduction from
                              your gross receipts during the first quarter of 2020?
                            </>
                          )}
                          error={syncErrors.QuartersQ2No}
                          touched={fields.QuartersQ2No?.touched}
                          options={[
                            {
                              className: 'form-control',
                              label: 'Yes',
                              name: 'QuartersQ2No',
                              type: 'radio',
                              value: 'Yes',
                            },
                            {
                              className: 'form-control',
                              label: 'No',
                              name: 'QuartersQ2No',
                              type: 'radio',
                              value: 'No',
                            },
                          ]}
                          validate={[
                            required({ msg: errors.requiredField }),
                          ]}
                        />
                      </>
                    )}
                  </>
                )}
                {showAnnualVersion && (
                  <>
                    <TextField
                      name="AnnualRevenue2019"
                      label={(
                        <>
                          Enter the Gross Receipts for 2019
                        </>
                      )}
                      error={syncErrors.AnnualRevenue2019}
                      touched={fields.AnnualRevenue2019?.touched}
                      validate={[
                        required({ msg: errors.enterAnAmount }),
                        numericality({ '>=': 0, msg: errors.enterAnAmount }),
                      ]}
                      {...currencyMask}
                    />
                    <TextField
                      name="AnnualRevenue2020"
                      label={(
                        <>
                          Enter the Gross Receipts for 2020
                        </>
                      )}
                      error={syncErrors.AnnualRevenue2020}
                      touched={fields.AnnualRevenue2020?.touched}
                      validate={[
                        required({ msg: errors.enterAnAmount }),
                        numericality({ '>=': 0, msg: errors.enterAnAmount }),
                      ]}
                      {...currencyMask}
                    />
                  </>
                )}
                <div className="reduction-in-gross-receipts-component__btn">
                  <Button
                    data-submit-form
                    form="reduction-in-gross-receipts"
                    onClick={handleSubmit(this.submit)}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  };

  render() {
    const { noTile, submissionError } = this.props;

    if (noTile) {
      return this.renderReductionInGrossReceiptsBody(submissionError);
    }

    return (
      <Tile card className="reduction-in-gross-receipts-tile">
        {this.renderReductionInGrossReceiptsBody(submissionError)}
      </Tile>
    );
  }
}

const mapStateToProps = (state) => ({
  fields: state.form?.reductionInGrossReceiptsForm?.fields,
  syncErrors: state.form?.reductionInGrossReceiptsForm?.syncErrors,
  registeredFields: state.form?.reductionInGrossReceiptsForm?.registeredFields,
});

export default compose(
  reduxForm({
    form: 'reductionInGrossReceiptsForm',
  }),
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(ReductioninGrossReceiptsReduxComponent);
