import {
  isNil, isUndefined, pick, isEmpty,
} from 'lodash';
import moment from 'moment';
import { isIE, isSafari } from 'react-device-detect';
import { DateFieldConstants } from '../../constants/date-field-constants';
import { getDateValidatorBounds } from '../../lib/forms/date-field-utils';
import {
  conversionTypes,
  FieldNames,
  sfFieldsByPage,
} from './constants';

export function getTotalPayrollCost(page2Values = {}, page3Values = {}) {
  const { totalCashCompensationTable1, totalCashCompensationTable2 } = page2Values;
  const {
    employeeHealthInsurance,
    employeeRetirementPlans,
    stateAndLocalTaxes,
    ownerCompensationTotal,
  } = page3Values;

  if (!totalCashCompensationTable1
      && !totalCashCompensationTable2
      && !employeeHealthInsurance
      && !employeeRetirementPlans
      && !stateAndLocalTaxes
      && !ownerCompensationTotal) {
    return undefined;
  }

  const totalPayrollCost = (totalCashCompensationTable1 || 0)
  + (totalCashCompensationTable2 || 0)
  + (employeeHealthInsurance || 0)
  + (employeeRetirementPlans || 0)
  + (stateAndLocalTaxes || 0)
  + (ownerCompensationTotal || 0);

  return totalPayrollCost;
}

export function getFlatApplicationValues(pageValuesList) {
  const [one, two, three, four, five, six] = pageValuesList;

  return {
    ...one,
    ...two,
    ...three,
    ...four,
    ...five,
    ...six,
  };
}

export const mapFieldToRequestBody = (formValues, body = {}) => {
  if (!formValues) {
    return body;
  }
  Object.entries(formValues).forEach(([key, value]) => {
    if (isUndefined(value)) {
      return;
    }
    if (conversionTypes.stringToBoolean.includes(key)) {
      if (isEmpty(value)) {
        return;
      }

      body[key] = JSON.parse(value);
      return;
    }
    if (conversionTypes.stringToNumber.includes(key)) {
      if (isEmpty(value)) {
        return;
      }

      body[key] = parseInt(value, 10);
      return;
    }
    if (key === FieldNames.StandardCoveredPeriodEndDate) {
      body.standardCoveredPeriodEndDate = moment(value);
      return;
    }
    body[key] = value;
  });

  return body;
};

export const mapEntityToField = (
  entity, formPageNumber, prefillData = {},
) => {
  if (!formPageNumber || !entity) {
    return undefined;
  }

  const entityDataForPage = pick(entity, sfFieldsByPage[formPageNumber]);
  const fieldDataForPage = {};
  Object.entries(entityDataForPage).forEach(([key, value]) => {
    if (isNil(value)) {
      return;
    }

    if (key === FieldNames.StandardCoveredPeriodEndDate && entity?.dateFirstWireIssued) {
      const { validRangeLowerBound, validRangeUpperBound } = getDateValidatorBounds(
        entity.dateFirstWireIssued,
        DateFieldConstants.DaysUntilCoveredEndDateStart,
        DateFieldConstants.DaysUntilCoveredEndDateEnd,
      );

      const isValidDate = moment(value).isBetween(validRangeLowerBound, validRangeUpperBound);

      if (isValidDate) {
        if (isIE || isSafari) {
          fieldDataForPage.standardCoveredPeriodEndDate = moment(value).format('MM/DD/YYYY');
        }
        else {
          fieldDataForPage.standardCoveredPeriodEndDate = value;
        }
      }
      return;
    }

    // Salesforce is currently always setting these fields to "false" whether they've been
    // previously filled out or not, so we can't trust "false" as a prefill value.
    if (typeof value === 'boolean') {
      if (!value) {
        return;
      }

      fieldDataForPage[key] = value.toString();
      return;
    }

    fieldDataForPage[key] = value;
  });
  prefillData[formPageNumber] = { ...fieldDataForPage };
  return prefillData;
};
