// This "service" should cease to exist once Salesforce corrects their data.
import { get, reduce } from 'lodash';
import {
  filter, flow, map, some, groupBy,
} from 'lodash/fp';
import { mapParamToName } from '../constants/forgivenessStipulationConstants';

export default {
  getCaStipulationGroups,
};

const stipMapTypes = {
  forgiveness: 'Forgiveness Application',
  credit: 'Credit Application',
};

function getCaStipulationGroups(contactId, location, stipulations) {
  const params = new URLSearchParams(location.search);
  const uploadedStipulationDocumentType = params.get('successStipulationGroupName');

  const checkHasAdditionalStips = createHasAdditionalStipsChecker(contactId);
  const getStipGroups = createStipGroupsGetter(uploadedStipulationDocumentType);

  const stipGroups = !stipulations ? [] : getStipGroups(stipulations);
  const hasAdditionalIdentityStips = !stipulations ? false : checkHasAdditionalStips(stipulations);

  if (hasAdditionalIdentityStips) {
    stipGroups.push({
      name: 'Additional identification',
      completed: false,
    });
  }

  return stipGroups;
}

const transformStipToGroup = (uploadedStipulationDocumentType) => (stip) => {
  let name = get(stip, 'stipulationMap.classification');
  let isForgivenessStip = false;

  if (!name) {
    const stipMapType = get(stip, 'stipulationMap.requestType');
    const stipMapName = get(stip, 'stipulationMap.name');
    if (Object.values(stipMapTypes).includes(stipMapType) && stipMapName) {
      name = stipMapName;
      isForgivenessStip = stipMapType === stipMapTypes.forgiveness;
    }
  }

  if (name && name.length > 1 && !isForgivenessStip) {
    name = name.substring(0, 1).toUpperCase() + name.substring(1, name.length).toLowerCase();
  }

  return {
    name,
    completed: !!stip.fulfilled
      || (
        stip.stipulationMap.documentType
        && uploadedStipulationDocumentType
        && stip.stipulationMap.documentType === uploadedStipulationDocumentType
      )
      || (
        stip.stipulationMap.name
        && uploadedStipulationDocumentType
        && (
          stip.stipulationMap.name === uploadedStipulationDocumentType
          || stip.stipulationMap.name === mapParamToName[uploadedStipulationDocumentType]
        )
      ),
  };
};

const createStipGroupsGetter = (uploadedStipulationDocumentType) => flow(
  filter((stip) => get(stip, 'stipulationMap.customerFacingCopy')),
  map(transformStipToGroup(uploadedStipulationDocumentType)),
  groupBy((stip) => stip.name),
  map(combineGroups),
);

const createHasAdditionalStipsChecker = (contactId) => flow(
  filter((stip) => !stip.fulfilled),
  filter((stip) => get(stip, 'stipulationMap.classification') === 'Identification'),
  filter((stip) => !!stip.contactId && stip.contactId !== contactId),
  some((stip) => stip),
);

function combineGroups(matchingGroups) {
  if (matchingGroups.length === 1) {
    return matchingGroups[0];
  }

  return reduce(matchingGroups, (current, next) => ({
    name: next.name,
    completed: current.completed && next.completed,
  }));
}
