import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';

const EntityGroupFour = () => (
  <>
    <BodyText className="payroll-verification__content-list-header">
      To substantiate the payroll costs calculation for the partners, please upload:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1065 including Schedule K-1.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To substantiate your payroll costs calculation for your employees, if applicable, please upload:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 941 Taxable Medicare wages &amp; tips from each quarter or equivalent payroll processor
        records or IRS Wage and Tax Statements, along with documentation of any retirement or group health,
        life, disability, vision and dental contributions.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        In lieu of IRS Form 941, IRS Form W-2s and IRS Form W-3 or payroll processor reports,
        including quarterly and annual tax reports may be provided, as well as annual IRS Form
        944 or annual IRS Form 943 (when applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        State quarterly wage reporting forms to support employer state and local taxes
        assessed on employee compensation.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate your business was in operation on Feb 15, 2020:
    </BodyText>
    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        If the partnership has employees, a payroll statement or similar documentation
        from the pay period that covered Feb 15, 2020 must be provided to establish the
        partnership was in operation and had employees on that date.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        If the partnership has no employees, an invoice, bank statement, or book of record
        establishing the partnership was in operation on Feb 15, 2020 must instead be provided.
      </BodyText>
    </BodyText>
  </>
);

export default EntityGroupFour;
