import React, { useContext } from 'react';
import { Sheet, IconButton, ArrowLeftIcon } from '@paypalcorp/pp-react';
import { OneTimePaymentContext } from './OneTimePaymentContext';
import {
  PaymentComponentMapping,
  shouldShowBackAction,
  getPrevNavigationComponent,
} from './paymentComponentMappings';
import { getInnerDisplayName } from '../../lib/get-display-name';

const PaymentModalComponentContainer = () => {
  const {
    visiblePaymentComponent: VisiblePaymentComponent,
    footerContent,
    setVisibleComponent,
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    resetEditData,
    currentBank,
  } = useContext(OneTimePaymentContext);

  const closeSheet = () => {
    setIsPaymentModalOpen(false);
    resetEditData();
  };

  const handleBackAction = () => {
    const currentVisibleComponentName = getInnerDisplayName(VisiblePaymentComponent);
    const prevComponentName = getPrevNavigationComponent(currentVisibleComponentName, currentBank);
    setVisibleComponent(PaymentComponentMapping[prevComponentName]);
  };

  return (
    <div className="payment-tile-wrapper">
      <Sheet
        isOpen={isPaymentModalOpen}
        onClose={closeSheet}
        showLogo
        footerContents={footerContent}
        footerAlign="center"
        action={
          shouldShowBackAction(getInnerDisplayName(VisiblePaymentComponent)) ? (
            <div role="button" onClick={handleBackAction} tabIndex={0} onKeyDown={handleBackAction}>
              <IconButton svgIconComponent={ArrowLeftIcon} buttonType="tertiary">
                Go Back
              </IconButton>
            </div>
          ) : null
        }
      >
        <VisiblePaymentComponent />
      </Sheet>
    </div>
  );
};

export default PaymentModalComponentContainer;
