import constants from '../../constants';

export const FORM_NAME = 'calculatePayrollDataForm';

export const QUESTION_TO_FIELD_MAP = {
  q1: 'seasonalRevenue',
  q2Yes: 'startDateOfPeriod',
  q2No: 'timePeriodOfPayrollCosts',
  q3: 'hiredHelpExpenses',
  q4: 'cashTipsEquivalent',
  q5: 'vacationLeaveBenefits',
  q6: 'allowanceSeparationDismissal',
  q7: 'yrlyHealthcareBenefitExpenses',
  q8: 'yrlyPayrollTaxExpenses',
  q10: 'eIDLRefinanceUse',
  q11: 'eIDLLoanNumber',
  q12: 'economicDisasterInjuryLoan',
};

export const BOOLEAN_QUESTION_NAME_ARRAY = ['q1', 'q10', 'q10Plus'];

export const formInstructions = [
  'Use the form below to provide detailed information on your payroll data. This information will be used to calculate your PPP loan offer amount, pending verification of your payroll documentation.',
  'Please maintain and upload all of the payroll documentation used to calculate your totals below and upload your payroll documents under the section entitled Supporting Payroll Documents.',
  'Please enter in your total aggregate amounts for the selected period in the fields below:',
];

export const formQuestions = {
  q1: 'Is your business a seasonal business?',
  q2Yes: 'Please select the Start Date of your 12-week time period between Feb 15, 2019 and Feb 15, 2020 that you wish to use to calculate your payroll costs. This information will be used to calculate your PPP loan offer amount, pending verification of your payroll documentation.',
  q2No: 'Please select the time period that you are using to calculate your payroll costs',
  q3: 'Salary, wages, commissions, or similar compensation',
  q4: 'Cash tips or the equivalent',
  q5: 'Payment for vacation, parental, family, medical, or sick leave',
  q6: 'Allowance for separation or dismissal',
  q7: 'Payment for the provision of employee benefits (including insurance premiums) consisting of group health care coverage, group life, disability, vision, or dental insurance, and retirement benefits',
  q8: 'Payment of state and local taxes assessed on compensation of employees',
  q10: 'Did you obtain an SBA EIDL Loan made between Jan 31, 2020 – Apr 3, 2020?',
  q10Plus: 'If approved for the PPP loan, are you planning to use any part of your PPP loan to refinance all or part of your EIDL loan?',
  q11: 'Enter your SBA EIDL Loan number',
  q12: 'Enter your outstanding EIDL amount balance if you plan to use your PPP loan to Refinance your EIDL Loan. Do not include any EIDL Advance Amount.',
};

export const toolTipInfo = {
  q1: 'A borrower is a seasonal employer if it does not operate for more than 7 months in any calendar year or, during the preceding calendar year, it had gross receipts for any 6 months of that year that were not more than 33.33 percent of the businesses’ gross receipts for the other 6 months of that year.',
  q2Yes: 'A seasonal employer must determine its maximum loan amount for PPP loan purposes by using the employer’s average total monthly payments for payroll for any 12-week period selected by the seasonal employer beginning Feb 15, 2019, and ending Feb 15, 2020.',
  q2No: 'Second Draw PPP Loan borrowers who are not self-employed, sole proprietorships or independent contractors, are also permitted to use the precise 1- year period before the date on which the loan application is made to calculate payroll costs if they choose not to use 2019 or 2020 to calculate payroll costs.',
  q3: 'Subtract any compensation paid to an employee in excess of $100,000 on an annualized basis, as prorated for the period during which the payments are made or the obligation to make the payments is incurred.',
};

export const TAB_KEYCODE = 'Tab';

export const SUBMIT = 'Submit';

export const YEAR_2019 = '2019';

export const YEAR_2020 = '2020';

export const MONTH_PERIOD_12 = 'Precise 12 month period (prior to PPP Application)';

export const isSafariOrIeValidationPayload = {
  msg: constants.errors.invalidDateFormat,
  format: 'mm/dd/yyyy',
};

export const isNotSafariOrIeValidationPayload = {
  msg: {
    format: constants.errors.invalidDateFormat,
  },
};
