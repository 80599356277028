import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import { Col, Row } from '@swift-paypal/pp-react';
import resolve from '../services/route.service';
import PageTitle from '../components/common/page-title';
import AnonymousPage from '../hocs/anonymous-page';
import {
  verifyEmailAction,
  updateSettingsMessageAction,
  clearSettingsMessageAction,
} from '../redux/actions/settings/settings-actions';
import { createLoginPageMessageAction } from '../redux/actions/login/login-page-actions';
import { verifyResetTokenAction, clearResetTokenStatusAction } from '../redux/actions/auth/auth-actions';
import constants from '../constants';
import { AlertTypes, Alert } from '../components/common/alert';
import routes from '../routes';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class VerifyEmailPage extends Component {
  static displayName = displayNames.VerifyEmailPage;

  static propTypes = {
    clearResetTokenStatus: PropTypes.func.isRequired,
    clearSettingsMessage: PropTypes.func.isRequired,
    country: PropTypes.string,
    createIndexPageMessage: PropTypes.func.isRequired,
    customerSupportPhone: PropTypes.string,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    push: PropTypes.func.isRequired,
    resetTokenIsInvalid: PropTypes.bool,
    token: PropTypes.string,
    updateSettingsMessage: PropTypes.func.isRequired,
    verifyEmail: PropTypes.func.isRequired,
    verifyEmailError: PropTypes.bool,
    verifyResetToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: 'us',
    customerSupportPhone: undefined,
    messageBody: undefined,
    messageType: undefined,
    resetTokenIsInvalid: true,
    token: undefined,
    verifyEmailError: true,
  };

  async componentDidMount() {
    const {
      customerSupportPhone,
      token,
      updateSettingsMessage,
      verifyResetToken,
    } = this.props;

    if (token) {
      await verifyResetToken(token);
    }
    else {
      updateSettingsMessage(
        constants.errors.verifyEmailError(customerSupportPhone),
        AlertTypes.critical,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      country,
      createIndexPageMessage,
      customerSupportPhone,
      push,
      resetTokenIsInvalid,
      token,
      updateSettingsMessage,
      verifyEmail,
      verifyEmailError,
    } = this.props;

    if (resetTokenIsInvalid !== prevProps.resetTokenIsInvalid) {
      if (resetTokenIsInvalid) {
        updateSettingsMessage(
          constants.errors.verifyEmailError(customerSupportPhone),
          AlertTypes.critical,
        );
      }
      else {
        verifyEmail(token);
      }
    }
    else if (verifyEmailError !== prevProps.verifyEmailError) {
      if (verifyEmailError) {
        updateSettingsMessage(
          constants.errors.verifyEmailError(customerSupportPhone),
          AlertTypes.critical,
        );
      }
      else {
        createIndexPageMessage('Email updated', AlertTypes.success);
        const RoutePayload = { country };
        push(resolve(routes.login.path, RoutePayload));
      }
    }
  }

  componentWillUnmount() {
    const { clearResetTokenStatus, clearSettingsMessage } = this.props;
    clearResetTokenStatus();
    clearSettingsMessage();
  }

  render() {
    const { messageBody, messageType } = this.props;
    return messageBody && messageType ? (
      <div>
        <Helmet>
          <title>Verify Email</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <>
              <PageTitle text="Verify Email" />
              <Row>
                <Col xs={10}>
                  <Alert message={messageBody} alertType={messageType} />
                </Col>
              </Row>
            </>
          )}
          sidebarElement={<></>}
        />
      </div>
    ) : <></>;
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    verifyEmailError: state.settings.verifyEmailError,
    resetTokenIsInvalid: state.resetToken.error,
    email: state.resetToken.email,
    messageBody: state.settings.messageBody,
    messageType: state.settings.messageType,
    customerSupportPhone: state.config.customerSupportPhone,
  };
}

export default connect(mapStateToProps, {
  verifyEmail: verifyEmailAction,
  createIndexPageMessage: createLoginPageMessageAction,
  verifyResetToken: verifyResetTokenAction,
  clearResetTokenStatus: clearResetTokenStatusAction,
  updateSettingsMessage: updateSettingsMessageAction,
  clearSettingsMessage: clearSettingsMessageAction,
  push: pushAction,
})(AnonymousPage(VerifyEmailPage));
