import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import resolve from '../../../services/route.service';
import { Alert } from '../../common/alert';
import PasswordField from '../../../lib/forms/password/password-field';
import routes from '../../../routes';
import withUrlParams from '../../../hocs/with-url-params';

const formName = 'change-password';

const ChangePasswordForm = ({
  handleSubmit,
  messageBody,
  messageType,
  country,
  user,
}) => (
  <div className="change-password-form form">
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} sm={10}>
          {messageBody && <Alert message={messageBody} alertType={messageType} />}
          <PasswordField name="currentPassword" formName={formName} label="Current password" />
          <PasswordField name="newPassword" formName={formName} label="New password" username={user.email} newPassword />
          <PasswordField
            name="confirmPassword"
            formName={formName}
            label="Confirm password"
            confirmationField="newPassword"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={5} md={4}>
          <Button type="submit" className="pp-react__button--block">
            Continue
          </Button>
        </Col>
        <Col xs={6} sm={5} md={4}>
          <Link to={resolve(routes.settings.path, { country })}>
            <Button secondary type="button" className="pp-react__button--block" tabIndex="-1">
              Cancel
            </Button>
          </Link>
        </Col>
      </Row>
    </form>
  </div>
);

ChangePasswordForm.propTypes = {
  country: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  messageBody: PropTypes.string,
  messageType: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

ChangePasswordForm.defaultProps = {
  messageBody: undefined,
  messageType: undefined,
  user: undefined,
};

const mapStateToProps = (state) => ({
  user: state.authorize.user || {},
});

export default compose(
  reduxForm({ form: formName }),
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(ChangePasswordForm);
