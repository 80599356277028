import React from 'react';
import PropTypes from 'prop-types';
import { AdditionalUnderReviewDetailsEntities } from './under-review-sba-details-constants';

const UnderReviewSbaDetails = ({ businessEntityType }) => (
  <div data-application-stipulation-text className="ppvx_text--md under-review-detail-text">
    <p>
      If you are seeing this message, your application is in one of three sequential steps within the
      underwriting stage.
    </p>
    <p>
      Please know that our team is working hard to process your application and will provide status updates
      along the way through email. We appreciate your patience.
    </p>
    <p>
      <strong>
        Step 1 - Application Document Upload &amp; Review
      </strong>
      {': '}
      We&apos;re reviewing your application. Your Loan Specialist will contact you once we&apos;ve reviewed your
      application and supporting documents and we will provide updates through email. Once we complete our review,
      we will either move you to the next step in the process or provide you with a notification (as required by law)
      providing the specific reason(s) why your application was unable to be approved.
    </p>
    {AdditionalUnderReviewDetailsEntities.includes(businessEntityType) && (
      <p>
        <strong>
          Note
        </strong>
        {': '}
        For sole proprietors, self-employed individuals, independent contractors and single member LLC applicants
        who file taxes using IRS Form 1040 Schedule C, we will use your Gross Income from line 7 of your 1040 Schedule
        C as the basis for including owner salary and wage in the computation of your PPP loan amount. Please contact
        us if you would instead like to use Net Profit from line 31 of your 1040 Schedule C as the basis for including
        owner salary and wages in the computation of your PPP loan amount.
      </p>
    )}
    <p>
      <strong>
        Step 2 - Loan Application, Agreement, and Note Issuance &amp; Signing
      </strong>
      {': '}
      If you are in this step, we have generated an email to you through DocuSign containing documents for your
      completion and electronic signature. If you don&apos;t see this email, please check your spam or junk folders.
      Once we have received your electronic signatures, and all documents are appropriately signed and completed,
      we will, subject to final review and verification, move you to the next step in the process.
    </p>
    <p>
      <strong>
        Step 3 - Waiting for SBA Loan Number
      </strong>
      {': '}
      Once your application passes through step 2, your completed application is placed into a queue to be sent to the
      SBA for review as quickly as possible. We expect it may take up to several days to process completed
      applications through the SBA and to receive an SBA loan number necessary for funding. For funding to occur,
      the SBA must approve your application and program funds must remain available. Our contact centers do not have
      access to the status of your loan, and they will be unable to provide any updates if you call.
      We will continue to communicate updates through email.
    </p>
  </div>
);

UnderReviewSbaDetails.propTypes = {
  businessEntityType: PropTypes.string.isRequired,
};

export default UnderReviewSbaDetails;
