export const RESET_PASSWORD_REQUEST_PENDING = 'reset_password_request_pending';
export const RESET_PASSWORD_RECEIVED = 'reset_password_received';
export const RESET_PASSWORD_REQUEST_ERROR = 'reset_password_request_error';
export const CLEAR_RESET_PASSWORD = 'clear_reset_password';

export const DEAUTHORIZED = 'deauthorized';
export const addDeauthorized = () => ({ type: DEAUTHORIZED });

export const SECURITY_QUESTION_WITH_TOKEN_REQUESTED = 'security_question_requested';
export const addSecurityQuestionWithTokenRequested = () => ({ type: SECURITY_QUESTION_WITH_TOKEN_REQUESTED });

export const SECURITY_QUESTION_WITH_TOKEN_RECEIVED = 'security_question_received';
export const addSecurityQuestionWithTokenReceived = (question) => ({
  type: SECURITY_QUESTION_WITH_TOKEN_RECEIVED,
  question,
});

export const SECURITY_QUESTION_WITH_TOKEN_REQUEST_ERROR = 'security_question_request_error';
export const addSecurityQuestionWithTokenRequestError = () => ({ type: SECURITY_QUESTION_WITH_TOKEN_REQUEST_ERROR });

export const UPDATE_RESET_PASSWORD_MESSAGE = 'update_reset_password_message';
export const CLEAR_RESET_PASSWORD_MESSAGE = 'clear_reset_password_message';
export const SET_RESET_TOKEN = 'set_reset_token';
