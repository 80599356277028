export const DOCUMENT_TYPES_REQUEST_PENDING = 'document_types_request_pending';
export const DOCUMENT_TYPES_REQUEST_RECEIVED = 'document_types_request_received';
export const DOCUMENT_TYPES_REQUEST_REQUEST_ERROR = 'document_types_request_request_error';

export const DOCUMENT_UPLOAD_REQUEST_PENDING = 'document_upload_request_pending';
export const DOCUMENT_UPLOAD_REQUEST_COMPLETED = 'document_upload_request_completed';
export const DOCUMENT_UPLOAD_REQUEST_ERROR = 'document_upload_request_error';
export const DOCUMENT_UPLOAD_CLEAR_PENDING = 'document_upload_request_clear_pending';
export const DOCUMENT_UPLOAD_CLEAR_REQUEST_ERROR = 'document_upload_request_clear_error';

export const DOCUMENTS_UPLOAD_REQUEST_PENDING = 'documents_upload_request_pending';
export const DOCUMENTS_UPLOAD_REQUEST_COMPLETED = 'documents_upload_request_completed';
export const DOCUMENTS_UPLOAD_REQUEST_CLEAR = 'documents_upload_request_clear';
export const DOCUMENTS_UPLOAD_REQUEST_ERROR = 'documents_upload_request_error';
export const DOCUMENTS_UPLOAD_CLEAR_FAILED_FILES = 'documents_upload_clear_failed_files';

export const DOCUMENT_UPLOAD_STIPULATION_SUCCESS = 'document_upload_stipulation_success';
export const DOCUMENT_UPLOAD_CLEAR_STIPULATION_SUCCESS = 'document_upload_clear_stipulation_success';
