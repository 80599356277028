import { push } from 'connected-react-router';
import resolve from '../../../services/route.service';
import apiHealthService from '../../../services/apiHealth.service';
import routes from '../../../routes';
import { addApiHealthError, addApiHealthOk, addApiHealthPending } from './maintenance-action-types';

function getApiHealthAction(match, redirectUrl = '', referralPage = '') {
  return async (dispatch, getState) => {
    const { country } = match.params;

    try {
      dispatch(addApiHealthPending());
      await apiHealthService.check(getState().config.apiUrl);

      dispatch(addApiHealthOk());
      if (referralPage === 'maintenance') {
        const url = redirectUrl || '/';
        dispatch(push(resolve(url, { country })));
      }
    }
    catch (e) {
      dispatch(addApiHealthError());
      if (referralPage !== 'maintenance') {
        dispatch(push(resolve(routes.maintenance.path, { country })));
      }
    }
  };
}

export default getApiHealthAction;
