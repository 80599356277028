import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BodyText, Alert, CaptionText, Button, AddIcon, Row, Col,
} from '@paypalcorp/pp-react';
import moment from 'moment';
import { isNull } from 'lodash';
import { OneTimePaymentContext } from '../../OneTimePaymentContext';
import { PaymentComponentMapping } from '../../paymentComponentMappings';
import '../../payments.less';
import PaymentCalendarInput from '../../components/paymentCalendarInput';
import displayNames from '../../../../constants/displayNames';
import {
  scheduleServiceACHAction,
  resetSelfServiceBankAddAction,
} from '../../../../redux/actions/one-time-payment/one-time-payment';
import { ResponsiveHeadingText } from '../../components/responsiveTypography';
import PaymentAmountInput, {
  paymentAmountInputValidation,
} from '../../components/paymentAmountInput';
import { RenderFIComponent } from '../../components/renderFiContainer';
import PaymentModalLoader from '../../components/paymentModalLoader';
import { useShallowSelector, oneTimePaymentSelector } from '../../one-time-payment-selectors';

const MakeAPayment = () => {
  const {
    selectedLoanAdvance,
    setFooterContent,
    paymentAmount,
    paymentDate,
    setPaymentAmount,
    setIsPaymentModalOpen,
    setVisibleComponent,
    currentBank,
    resetEditData,
    invalidPaymentDateError,
  } = useContext(OneTimePaymentContext);

  const {
    scheduledPaymentStatus, scheduledPaymentErrorMsg, bankUpdateStatus, bankUpdateMessage,
  } = useShallowSelector(oneTimePaymentSelector);

  const dispatch = useDispatch();

  const [currentBankError, setCurrentBankError] = useState(false);

  const handlePaymentAuthLink = () => {
    setVisibleComponent(PaymentComponentMapping.PaymentAuthorization);
    setFooterContent(() => (
      <Button onClick={() => setVisibleComponent(PaymentComponentMapping.MakeAPayment)}>
        Done
      </Button>
    ));
  };

  const handleCancelAction = () => {
    setIsPaymentModalOpen(false);
  };

  const ErrorHeader = ({
    // eslint-disable-next-line react/prop-types
    scheduledPaymentStatusFlag, bankUpdateStatusFlag, scheduledPaymentStatusMessage, bankUpdateStatusMessage,
  }) => {
    const GetErrorElement = () => {
      if (scheduledPaymentStatusFlag === 'error') {
        return <Alert type="error">{scheduledPaymentStatusMessage}</Alert>;
      }
      if (bankUpdateStatusFlag === 'success') {
        return <Alert type="success">{bankUpdateStatusMessage}</Alert>;
      }
      if (currentBankError) {
        return <Alert type="error">Add your Business Bank Account</Alert>;
      }
      return <></>;
    };
    return <GetErrorElement />;
  };

  const handleScheduleACH = (event) => {
    event.preventDefault();
    const isPastDuePending = !!selectedLoanAdvance?.PastDueAmountWithFees;
    const amountErrorText = paymentAmountInputValidation(
      paymentAmount,
      isPastDuePending,
      selectedLoanAdvance.Balance,
    );
    setPaymentAmount({
      ...paymentAmount,
      amountError: amountErrorText,
    });
    if (amountErrorText) {
      return;
    }
    if (!currentBank) {
      setCurrentBankError(true);
      return;
    }
    if (!isNull(invalidPaymentDateError)) {
      return;
    }
    const bankInfo = { ...currentBank, BankFIStreetAddress: null };
    const requestPayload = {
      advanceId: selectedLoanAdvance.Id,
      // Fix: default format will move the date one day behind
      effectiveDate: moment(paymentDate).format('LL'),
      transactionAmount: paymentAmount.amount,
      fiInfo: bankInfo,
    };
    dispatch(resetSelfServiceBankAddAction());
    dispatch(scheduleServiceACHAction(requestPayload));
  };

  useEffect(() => {
    if (!selectedLoanAdvance.IsEligibeForSSACH || !(selectedLoanAdvance.Balance > 0)) {
      setIsPaymentModalOpen(false);
    }
  }, [selectedLoanAdvance.IsEligibeForSSACH, selectedLoanAdvance.Balance]);

  useEffect(() => {
    if (scheduledPaymentStatus === 'success') {
      setVisibleComponent(PaymentComponentMapping.PaymentConfirmation);
    }
  }, [scheduledPaymentStatus]);

  return (
    <>
      {scheduledPaymentStatus === 'pending' ? (
        <PaymentModalLoader paymentLoaderText="We are scheduling your transaction" />
      ) : (
        <div>
          <ErrorHeader
            scheduledPaymentStatusFlag={scheduledPaymentStatus}
            scheduledPaymentStatusMessage={scheduledPaymentErrorMsg}
            bankUpdateStatusFlag={bankUpdateStatus}
            bankUpdateStatusMessage={bankUpdateMessage}
          />
          <ResponsiveHeadingText className="heading" mobile="sm" desktop="lg">
            Make a One-Time Payment
          </ResponsiveHeadingText>
          <form onSubmit={handleScheduleACH}>
            <CaptionText strong>Pay with</CaptionText>
            <div className="input-container">
              {currentBank ? (
                <Row noGutters>
                  <Col xs={9}>
                    <RenderFIComponent fiInfo={currentBank} />
                  </Col>
                  <Col xs={3}>
                    <Button
                      onClick={() => setVisibleComponent(PaymentComponentMapping.ChangePaymentMethod)}
                      tertiary
                    >
                      Change
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row noGutters>
                  <Button
                    onClick={() => {
                      resetEditData();
                      setVisibleComponent(PaymentComponentMapping.AddBusinessInfo);
                    }}
                    tertiary
                    iconComponent={AddIcon}
                  >
                    Add a new bank
                  </Button>
                </Row>
              )}
            </div>
            <PaymentAmountInput />
            <PaymentCalendarInput />
            <BodyText strong className="payment-disclaimer">
              By clicking Schedule Payment, you agree to the &nbsp;
              <Button onClick={handlePaymentAuthLink} className="payment-auth-link" tertiary>
                Payment Authorization
              </Button>
              &nbsp; and to be bound by the rules of NACHA.
            </BodyText>
            <div className="btn-wrapper">
              <Button size="lg" type="submit">
                Schedule Payment
              </Button>
              <Button size="lg" tertiary onClick={handleCancelAction}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

MakeAPayment.displayName = displayNames.MakeAPayment;

export default MakeAPayment;
