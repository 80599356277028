import { BodyText } from '@swift-paypal/pp-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useShallowSelector } from '../../../../hooks';
import { fulfilledFirstTimeSelector } from '../sba3508SForm.selectors';
import { Sba3508SFormInstructionsLink } from '../sba3508SFormInstructionsLink';
import FirstVisitDetails from './firstVisitDetails';
import RevisitDetails from './revisitDetails';

export const Sba3508SFormDetails = () => {
  const { stipulationId } = useParams();
  const fulfilledFirstTime = useShallowSelector(fulfilledFirstTimeSelector, stipulationId);

  return (
    <>
      {fulfilledFirstTime ? <RevisitDetails /> : <FirstVisitDetails />}
      <BodyText as="p">
        For more information, refer to the
        {' '}
        <Sba3508SFormInstructionsLink />
        .
      </BodyText>
    </>
  );
};
