import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import securePage from '../../../hocs/secure-page';
import { useActiveForgivenessSelected, useShallowSelector } from '../../../hooks';
import displayNames from '../../../constants/displayNames';
import BetterStandardLayout from '../../../layouts/better-standard-layout/better-standard-layout';
import CaseWorkerTile from '../../common/case-worker-tile/case-worker-tile';
import { SbaLoanInformationSidebar } from '../../forgiveness/sba-loan-information-sidebar';
import { Sba3508SForm } from '../../features';
import { getAdvancesAction } from '../../../redux/actions/advances/advances-actions';
import { getLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { getOpportunitiesAction } from '../../../redux/actions/opportunities/opportunities-actions';
import getStipulationsAction from '../../../redux/actions/stipulations/stipulations-actions';
import { setActiveForgivenessId } from '../../../redux/actions/active-ids/active-ids-actions';
import { pageSelector } from '../../features/sba3508SForm/sba3508SForm.selectors';
import { buildPrefillData } from '../../features/sba3508SForm/sba3508SForm.utils';

const Sba3508SFormPage = () => {
  const dispatch = useDispatch();
  const { country, opportunityId } = useParams();

  useActiveForgivenessSelected();
  useEffect(() => {
    dispatch(getAdvancesAction());
    dispatch(getOpportunitiesAction());
  }, []);

  const {
    activeForgivenessId, advanceId, loanForgiveness,
  } = useShallowSelector(pageSelector);

  useEffect(() => {
    const forgivenessId = loanForgiveness.allIds
      .find((id) => advanceId === loanForgiveness.byId[id]?.advanceId);

    if (!forgivenessId && advanceId) {
      dispatch(getLoanForgivenessAction([advanceId]));
    }

    if (forgivenessId && forgivenessId !== activeForgivenessId) {
      dispatch(setActiveForgivenessId(forgivenessId));
    }
  }, [advanceId, loanForgiveness]);

  useEffect(() => {
    if (country && opportunityId) {
      dispatch(getStipulationsAction(opportunityId, country));
    }
  }, [country, opportunityId]);

  const activeForgivenessObject = loanForgiveness.byId[activeForgivenessId];
  const caseWorker = activeForgivenessObject?.caseWorker;
  const initialValues = buildPrefillData(activeForgivenessObject);

  return (
    <div className="summary-container">
      <Helmet title="Summary" />
      <BetterStandardLayout
        bodyElement={<Sba3508SForm initialValues={initialValues} />}
        sidebarElement={<SbaLoanInformationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

Sba3508SFormPage.displayName = displayNames.Sba3508SFormPage;

export default securePage(Sba3508SFormPage);
