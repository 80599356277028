import { FORGIVENESS_APPLICATION } from './stipulations.constants';

export const stipulationSelector = (stipulationId) => ({ entities, stipulations }) => {
  const {
    advances,
    loanForgiveness,
  } = entities;

  const {
    fulfilled,
    opportunityId,
    stipulationMap: {
      documentName,
      name,
      requestType,
    },
  } = stipulations.allStipulations?.find(({ id }) => id === stipulationId) || {};

  const response = {
    documentName,
    fulfilled,
    opportunityId,
    requestType,
    stipulationName: name,
  };

  if (requestType === FORGIVENESS_APPLICATION) {
    const advanceId = advances.allIds.find((id) => opportunityId === advances.byId[id].opportunityId);
    const forgivenessId = loanForgiveness.allIds.find((id) => advanceId === loanForgiveness.byId[id].advanceId);
    response.applicationVersionSelected = loanForgiveness.byId[forgivenessId]?.applicationVersionSelected;
  }

  return response;
};
