import pDebounce from 'p-debounce';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import constants from './constants';
import { getToken } from './lib/token';

export default {
  getCurrency,
  conditionallyDisplayCents,
  flattenObject,
  formatFloat,
  formatPhoneNumber,
  getLoanName,
  closeNav,
  securityQuestionsAreInvalid,
  blurOnSubmit,
  cleanSin,
  cleanSsn,
  prependAccountStars,
  redirect,
  dateSort,
  isDateInPastOrToday,
  generateCurrencyText,
  decryptResponse,
};

/**
 * Formats a phone number as ###-###-####
 * @param phoneNumber The phone number to format
*/
function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return undefined;
  }

  let digits = phoneNumber.replace(/[^\d]/g, '');

  if (digits.length < 10) {
    return undefined;
  }

  digits = removeUsCountryCodeFromDigits(digits);
  digits = removeExtensionFromDigits(digits);

  const formattedPhoneNumber = `${digits.substring(0, 3)}-${digits.substring(3, 6)}-${digits.substring(6, 10)}`;

  return formattedPhoneNumber;
}

/**
 * Removes the US Country Code from the phone number digits
 * @param digits The phone number digits
*/
function removeUsCountryCodeFromDigits(digits) {
  if (digits.length >= 11 && digits[0] === '1') {
    return digits.substring(1);
  }

  return digits;
}

/**
 * Removes any extension from the phone number digits
 * @param digits The phone number digits
*/
function removeExtensionFromDigits(digits) {
  if (digits.length > 10) {
    return digits.substring(0, 10);
  }

  return digits;
}

export const overrideConfigWithQuery = (env, queryParams, updateConfigAction) => {
  if (env !== 'debug' && env !== 'development' && env !== 'integration') {
    return;
  }

  const overridableConfigValues = ['loginRevamp'];
  const configOverrides = Object.entries(queryParams).reduce((overrideValues, [key, value]) => {
    if (overridableConfigValues.includes(key)) {
      return {
        ...overrideValues,
        [key]: value,
      };
    }
    return overrideValues;
  }, undefined);
  if (configOverrides) {
    updateConfigAction({ ...configOverrides });
  }
};

export function closeNavMenu() {
  document.body.classList.remove('vx_hasOpenNav');
}

const debounceTimeout = 200;
const debounceOptions = { leading: true };
export const asyncDebounce = (asyncFn) => pDebounce(asyncFn, debounceTimeout, debounceOptions);

function getCurrency(amount, decimals = 2) {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);
}

function conditionallyDisplayCents(amount) {
  if (amount % 1 === 0) {
    return getCurrency(amount, 0);
  }
  return getCurrency(amount);
}

function formatFloat(float, minDecimalPlaces = 0, maxDecimalPlaces = 3) {
  return Intl.NumberFormat('en-us', {
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: maxDecimalPlaces,
  }).format(float);
}

function isPayPal(partner) {
  return partner === constants.loan.payPalPartner || partner.toLowerCase().indexOf('paypal') > -1;
}

function getLoanName(partner) {
  return partner && isPayPal(partner) ? constants.loan.payPal : constants.loan.loan;
}

function closeNav() {
  if (document && document.body.classList.contains('vx_hasOpenNav')) {
    document.body.classList.remove('vx_hasOpenNav');
  }
}

function securityQuestionsAreInvalid(values) {
  const {
    question1, question1Custom, question2, question2Custom, answer1, answer2,
  } = values;
  return (
    (!question1Custom && !question2Custom && question1.toLowerCase() === question2.toLowerCase())
    || (!question1Custom && question2Custom && question1.toLowerCase() === question2Custom.toLowerCase())
    || (question1Custom && !question2Custom && question1Custom.toLowerCase() === question2.toLowerCase())
    || (question1Custom && question2Custom && question1Custom.toLowerCase() === question2Custom.toLowerCase())
    || answer1.toLowerCase() === answer2.toLowerCase()
  );
}

function blurOnSubmit() {
  // if user submits with the 'enter' key we must manually blur the last selected field
  if (process.browser && document && document.activeElement) {
    document.activeElement.blur();
  }
}

function cleanSin(value) {
  return value ? value.replace(/[^\d]/g, '') : null;
}

function cleanSsn(value) {
  return value ? value.replace(/[^\d]/g, '') : null;
}

// if valid input, prepends with 7 asterisks, otherwise returns undefined
function prependAccountStars(lastFour) {
  return !lastFour ? undefined : `*******${lastFour}`;
}

function redirect(destination) {
  window.location.href = destination;
}

export function isEzEligble(data) {
  if (!data) return null;
  return data.ezAppQ1 || (!data.ezAppQ2 && !data.ezAppQ3)
    || (!data.ezAppQ2 && data.ezAppQ4);
}

export function isSEligible(answers, advanceAmount) {
  return !answers?.ezAppQ6 && advanceAmount <= 50000;
}

function flattenObject(obj, prefix = '') {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});
}

export const updateApplicationVersionSelectedEffect = (
  forgivenessObject, ezAppEligible, updateLoanForgivenessAction,
) => () => {
  const updateData = async () => {
    if (forgivenessObject
      && !forgivenessObject?.applicationVersionSelected
      && !forgivenessObject?.sApp) {
      const applicationVersionSelected = ezAppEligible ? '3508EZ' : '3508';
      await updateLoanForgivenessAction({
        ...forgivenessObject,
        applicationVersionSelected,
      });
    }
  };
  updateData();
};

function dateSort(a, b, ascending = true) {
  const aDate = moment(a.postDate);
  const bDate = moment(b.postDate);

  if (aDate === bDate) {
    return 0;
  }

  if (ascending) {
    return aDate > bDate ? -1 : 1;
  }

  return aDate > bDate ? 1 : -1;
}

function generateCurrencyText(amount) {
  return amount ? getCurrency(amount, 2) : '$0.00';
}

function isDateInPastOrToday(date) {
  if (date) {
    return moment().isSameOrAfter(date);
  }
  return false;
}

function decryptResponse(response) {
  const token = getToken();
  if (!token) {
    return undefined;
  }
  const tokenGuid = token?.guid;
  const bytes = CryptoJS.AES.decrypt(response, tokenGuid);
  const decryptedResponse = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedResponse;
}
