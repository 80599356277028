import React, { memo } from 'react';
import { Col, Row } from '@swift-paypal/pp-react';
import { fieldNames } from './sba3508SFormFields.constants';
import { useShallowSelector } from '../../../../hooks';
import YesOrNoRadioField from '../../../common/yes-or-no-radio-field';
import { Sba3508SFormInstructionsLink } from '../sba3508SFormInstructionsLink';
import { fieldSelector } from '../sba3508SForm.selectors';

const AttestToReductions = () => {
  const {
    attestToReductions: fieldName,
    reducedSalariesWagesEmployees,
  } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  const reducedSalaries = useShallowSelector(fieldSelector, reducedSalariesWagesEmployees);

  return reducedSalaries?.registered && reducedSalaries?.value === 'true' ? (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={content}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  ) : null;
};

const content = (
  <>
    Can you attest, after having read the
    {' '}
    <Sba3508SFormInstructionsLink
      anchor="#page=3"
      text="FTE and Salary/Wage Reduction"
    />
    {' '}
    section on page 3 of the SBA Form 3508S Forgiveness application instructions,
    that the borrower is not subject to any reductions in Loan Forgiveness?
  </>
);

export default memo(AttestToReductions);
