import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from '@swift-paypal/pp-react';
import errors from '../../constants/errors';
import { useShallowSelector } from '../../hooks';
import { fieldNames } from './fields/field-constants';
import { fieldSelector } from './questionnaire-selectors';

const FormAlert = ({ hasSubmissionError }) => {
  const { questionnaireQ5 } = fieldNames;

  const { registered, value } = useShallowSelector(fieldSelector, questionnaireQ5) || {};

  if (registered && value === 'false') {
    return (
      <Row>
        <Col form="full">
          <Alert type="error">
            You must submit your signed First Draw PPP loan forgiveness application to the Lender for that
            loan before you can apply for loan forgiveness on your Second Draw PPP loan.
          </Alert>
        </Col>
      </Row>
    );
  }

  return hasSubmissionError ? (
    <Row>
      <Col form="full">
        <Alert type="error">{errors.technicalIssue}</Alert>
      </Col>
    </Row>
  ) : null;
};

FormAlert.propTypes = {
  hasSubmissionError: PropTypes.bool.isRequired,
};

export default memo(FormAlert);
