import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, CaptionText } from '@swift-paypal/pp-react';

export const InfoItem = ({ caption, content }) => (
  <BodyText className="margin-top-xs">
    {content}
    <CaptionText className="info-item__caption">
      {caption}
    </CaptionText>
  </BodyText>
);

InfoItem.propTypes = {
  caption: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
