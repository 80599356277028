import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BodyText, CaptionText } from '@swift-paypal/pp-react';
import util from '../../../util';
import formLabels from '../../../constants/formLabels';
import withUrlParams from '../../../hocs/with-url-params';
import Detail from './detail';
import ExistingLoan from './existing-loan';

const RenewalTerms = (props) => {
  const {
    cashDisbursed,
    country,
    detailsVisible,
    existingLoanPayoff,
    hideDisclosures,
    loans,
    terms,
  } = props;

  if (hideDisclosures) {
    return (
      <div className="term-details">
        <BodyText>{`${terms.numberOfPayments} weeks`}</BodyText>
        <CaptionText className="vx_text-body-sm loan-term-label">{formLabels.loanTerm}</CaptionText>
      </div>
    );
  }

  return detailsVisible ? (
    <div className="loan-details">
      {loans.map((loan) => (
        <ExistingLoan key={loan.id} loan={loan} />
      ))}
      <div className="new-loan-summary">
        <div className="detail-header">New Loan Summary</div>
        <Detail label={formLabels.newLoanAmount} amount={util.conditionallyDisplayCents(terms.loanAmount)} />
        { country === 'ca'
          ? <Detail label={formLabels.totalLoanFee} amount={terms.totalInterestCharge} />
          : <Detail label={formLabels.totalInterestCharge} amount={terms.totalInterestCharge} />}
        <Detail label={formLabels.totalRepaymentAmount} amount={terms.totalRepaymentAmount} />
        <Detail label={formLabels.loanTerm} amount={`${terms.numberOfPayments} Weeks`} />
        <Detail label={formLabels.weeklyPayment} amount={terms.weeklyPayment} />
      </div>
      <div className="transaction-summary">
        <div className="detail-header">Transaction Summary</div>
        <Detail label={formLabels.newLoanAmount} amount={util.conditionallyDisplayCents(terms.loanAmount)} />
        <Detail label={formLabels.existingLoanPayoff} amount={existingLoanPayoff} />
        <Detail label={`${formLabels.newCashDisbursed}*`} amount={Object.values(cashDisbursed).join('')} highlight />
      </div>
      <div className="footnote">
        *New Cash Disbursed and Total Payoff Amount are subject to change and may be impacted by the balance of the
        Existing Loan as well as any third party loans/obligations that may also need to be paid off at the time of
        funding.
      </div>
    </div>
  ) : <></>;
};

RenewalTerms.propTypes = {
  cashDisbursed: PropTypes.shape({}),
  country: PropTypes.string,
  detailsVisible: PropTypes.bool,
  existingLoanPayoff: PropTypes.string,
  hideDisclosures: PropTypes.bool.isRequired,
  loans: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.shape({
    loanAmount: PropTypes.number,
    totalInterestCharge: PropTypes.string,
    totalRepaymentAmount: PropTypes.string,
    numberOfPayments: PropTypes.number,
    weeklyPayment: PropTypes.string,
  }),
};

RenewalTerms.defaultProps = {
  cashDisbursed: undefined,
  country: undefined,
  detailsVisible: false,
  existingLoanPayoff: undefined,
  loans: undefined,
  terms: undefined,
};

const mapStateToProps = (state) => ({
  hideDisclosures: state.config.hideDisclosures,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(RenewalTerms);
