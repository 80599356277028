import { BodyText, Link } from '@swift-paypal/pp-react';
import React from 'react';
import { useShallowSelector } from '../../../hooks';
import {
  FORGIVENESS_DETAIL,
  FORGIVENESS_DETAIL_TITLE,
  PPP_DETAIL,
  SBA_DECISION_STAGES,
  WEBSITE,
} from './pppLoanPaymentDeferral.constants';
import { pppLoanPaymentDeferralSelector } from './pppLoanPaymentDeferral.selectors';
import util from '../../../util';

export const PppLoanPaymentDeferral = () => {
  const { advances, loanbuilderUrl, loanForgiveness } = useShallowSelector(pppLoanPaymentDeferralSelector);

  const { allIds: forgivenessAllIds, byId: forgivenessById } = loanForgiveness;

  // get forgiveness loans not that are not in SBA_DECISION_STAGES
  const forgivenessLoansNotInDecisionStages = forgivenessAllIds.length > 0
    ? forgivenessAllIds.filter((id) => !SBA_DECISION_STAGES.includes(forgivenessById[id]?.stage)) : [];

  // get all the firstPaymentDates from forgiveness loans that are not in SBA_DECISION_STAGES
  const forgivenessLoansFirstPaymentDateList = forgivenessLoansNotInDecisionStages.map((id) => {
    const advance = advances.byId[forgivenessById[id]?.advanceId];
    return advance.firstPaymentDateExpected;
  });

  // if no firstPaymentDate, we don't check the deferred condition
  let shouldDisplay = !forgivenessLoansFirstPaymentDateList
    .every((date) => {
      if (date) return util.isDateInPastOrToday(date);
      return true;
    });

  // If forgiveness list is empty, check other kinds of loans' firstPaymentDates
  if (forgivenessAllIds.length === 0) {
    const otherLoansFirstPaymentDateList = advances.allIds.map((id) => (advances.byId[id]?.firstPaymentDateExpected));
    shouldDisplay = !otherLoansFirstPaymentDateList
      .every((date) => {
        if (date) return util.isDateInPastOrToday(date);
        return true;
      });
  }

  if (shouldDisplay) {
    return (
      <div>
        <BodyText as="h3">
          {FORGIVENESS_DETAIL_TITLE}
        </BodyText>
        <BodyText as="p" className="body-text">
          {FORGIVENESS_DETAIL}
        </BodyText>
        <BodyText as="p" className="body-text">
          {`${PPP_DETAIL} `}
          <Link
            className="pp-react__link--inline"
            data-automation-id="pppLoanInfoLink"
            href={`${loanbuilderUrl}/ppp-loan-info`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {WEBSITE}
          </Link>
        </BodyText>
      </div>
    );
  }
  return null;
};
