import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import getDisplayName from '../lib/get-display-name';

export default function withUrlParams(WrappedComponent) {
  const WithUrlParams = (props) => {
    const { match, location } = props;
    const matchParams = match.params || {};
    const searchParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(searchParams.entries());

    return <WrappedComponent {...props} {...matchParams} {...queryParams} />;
  };

  WithUrlParams.displayName = `withUrlParams(${getDisplayName(WrappedComponent)})`;
  WithUrlParams.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
  };

  return WithUrlParams;
}
