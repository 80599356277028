import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { numericality, required } from 'redux-form-validators';
import { BodyText, TextInput, Tooltip } from '@swift-paypal/pp-react';
import util from '../../../../../util';
import constants from '../../../../../constants';
import { MAX_AMOUNT_THRESHOLD, TOOLTIP_DESCRIPTION, TOOLTIP_NAME } from './owner-compensation-total-constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

const { errors } = constants;

const OwnerCompensationTotal = ({
  coveredEnd, coveredStart, error, submitted, touched, value,
}) => (
  <>
    <BodyText as="label" htmlFor="ownerCompensationTotal">
      Total amount paid to owner-employees / self-employed individuals / general partners between
      {` ${coveredStart} `}
      &ndash;
      {` ${coveredEnd}`}
    </BodyText>
    <Field
      className="field__mapped-input"
      key="owner-compensation-total"
      name="ownerCompensationTotal"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      placeholder="$"
      rightIcon={<Tooltip description={TOOLTIP_DESCRIPTION} name={TOOLTIP_NAME} />}
      value={value}
      validate={[
        required({ msg: errors.currencyZeroError }),
        numericality({ '<=': MAX_AMOUNT_THRESHOLD, msg: { '<=': `Total amount must be less than ${util.getCurrency(MAX_AMOUNT_THRESHOLD, 2)}.` } }),
      ]}
      {...currencyMask}
    />
  </>
);

OwnerCompensationTotal.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
  value: undefined,
};

OwnerCompensationTotal.propTypes = {
  coveredEnd: PropTypes.string.isRequired,
  coveredStart: PropTypes.string.isRequired,
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.number,
};

export default memo(OwnerCompensationTotal);
