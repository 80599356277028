import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as pushAction } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Alert, Button, Checkbox } from '@swift-paypal/pp-react';
import resolve from '../../../services/route.service';
import routes from '../../../routes';
import './upload-documents.less';
import UploadSectionComponent from './upload-section/upload-section';
import FileQueueSectionComponent from './file-queue-section/file-queue-section';

const bytesInMb = 1024 * 1024;
const specialCharacters = /[|:<>/\\]+/;
const fileTooSmallCode = 'file-too-small';

class UploadDocumentsComponent extends Component {
  static propTypes = {
    allowedFileExtensions: PropTypes.arrayOf(PropTypes.string),
    country: PropTypes.string,
    isForgivenessStipulation: PropTypes.bool,
    maximumPerFileSizeInBytes: PropTypes.number,
    maximumTotalSizeInBytes: PropTypes.number,
    submitFunc: PropTypes.func.isRequired,
    checkboxText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    customClassName: PropTypes.string,
    optOutCheckbox: PropTypes.bool,
    optOutSubmitFunc: PropTypes.func,
    push: PropTypes.func.isRequired,
    helperText: PropTypes.string,
  }

  static defaultProps = {
    allowedFileExtensions: ['jpg', 'gif', 'png'],
    country: 'us',
    isForgivenessStipulation: false,
    maximumPerFileSizeInBytes: 5000000,
    maximumTotalSizeInBytes: 25000000,
    checkboxText: undefined,
    customClassName: '',
    optOutCheckbox: false,
    optOutSubmitFunc: undefined,
    helperText: undefined,
  }

  constructor() {
    super();
    this.state = {
      queuedFiles: [],
      rejectedFiles: [],
      emptyFiles: [],
      invalidFileCharacters: [],
      exceededMaxTotalSize: false,
      checkboxValue: false,
      showOptOutError: false,
      showTermsError: false,
    };
  }

  dropFiles = (acceptedFiles, rejectedFiles = []) => {
    const { maximumTotalSizeInBytes, optOutCheckbox } = this.props;
    const { checkboxValue, queuedFiles } = this.state;
    const invalidFileCharacters = acceptedFiles.filter((file) => specialCharacters.test(file.name));
    const newFiles = acceptedFiles
      .filter((f) => !queuedFiles.some((qf) => qf.name === f.name))
      .filter((f) => !invalidFileCharacters.includes(f));

    const emptyFiles = rejectedFiles.filter(({ errors: [{ code }] }) => code === fileTooSmallCode);
    const queuedFilesTotalSize = queuedFiles.reduce((totalSize, file) => totalSize + file.size, 0);
    const newFilesTotalSize = newFiles.reduce((totalSize, file) => totalSize + file.size, 0);

    this.setState({
      checkboxValue: optOutCheckbox ? checkboxValue : false,
      showTermsError: false,
    });

    if (queuedFilesTotalSize + newFilesTotalSize > maximumTotalSizeInBytes) {
      this.setState({
        exceededMaxTotalSize: true,
      });
      return;
    }

    const newQueue = [...queuedFiles, ...newFiles];

    this.setState({
      queuedFiles: newQueue,
      rejectedFiles,
      emptyFiles,
      invalidFileCharacters,
      exceededMaxTotalSize: false,
      showOptOutError: optOutCheckbox && checkboxValue && newQueue.length > 0,
    });
  }

  removeFile = (file) => {
    const { optOutCheckbox } = this.props;
    const { checkboxValue, queuedFiles } = this.state;
    const newQueue = queuedFiles.filter((f) => f.name !== file.name);

    this.setState({
      queuedFiles: newQueue,
      showOptOutError: optOutCheckbox && checkboxValue && newQueue.length > 0,
    });
  }

  rejectFiles = (files) => {
    this.setState({
      rejectedFiles: files,
    });
  }

  submit = async () => {
    const {
      submitFunc, optOutCheckbox, optOutSubmitFunc,
    } = this.props;
    const { showOptOutError } = this.state;
    const { queuedFiles, checkboxValue } = this.state;
    const optOutEnabledAndChecked = optOutCheckbox && checkboxValue;

    if (optOutEnabledAndChecked && optOutSubmitFunc && !showOptOutError) {
      optOutSubmitFunc();
      return;
    }

    if (!optOutEnabledAndChecked && queuedFiles.length < 1) {
      this.setState({
        showTermsError: true,
      });
      return;
    }

    if (!showOptOutError) {
      submitFunc(queuedFiles);

      this.setState({
        queuedFiles: [],
        rejectedFiles: [],
      });
    }
  }

  handleCheckboxClick = () => {
    const { optOutCheckbox } = this.props;
    const { checkboxValue, queuedFiles } = this.state;
    const newCheckboxValue = !checkboxValue;

    this.setState({
      checkboxValue: newCheckboxValue,
      showTermsError: false,
      showOptOutError: optOutCheckbox && newCheckboxValue && queuedFiles.length > 0,
    });
  }

  cancelToReturnToSummary = async () => {
    const { country, isForgivenessStipulation, push } = this.props;
    if (isForgivenessStipulation) {
      return push(resolve(routes['forgiveness-summary'].path, { country }));
    }
    return push(resolve(routes.summary.path, { country }));
  }

  render() {
    const {
      allowedFileExtensions,
      maximumPerFileSizeInBytes,
      maximumTotalSizeInBytes,
      customClassName,
      optOutCheckbox,
      checkboxText,
      helperText,
    } = this.props;

    const {
      exceededMaxTotalSize,
      queuedFiles,
      rejectedFiles,
      emptyFiles,
      invalidFileCharacters,
      showOptOutError,
      showTermsError,
    } = this.state;

    const invalidTypeOrExcessiveSizeFiles = rejectedFiles.filter((file) => !emptyFiles.includes(file));

    const maxTotalMb = Math.floor(maximumTotalSizeInBytes / bytesInMb);

    return (
      <div className={`upload-documents-component ${customClassName}`}>
        <UploadSectionComponent
          allowedFileExtensions={allowedFileExtensions}
          dropFilesFunc={this.dropFiles}
          maximumPerFileSizeInBytes={maximumPerFileSizeInBytes}
          maximumTotalSizeInBytes={maximumTotalSizeInBytes}
          rejectFilesFunc={this.rejectFiles}
          helperText={helperText}
        />

        {exceededMaxTotalSize && (
          <Alert type="warning" className="upload-warning">
            The files you selected were not queued for upload because the total
            upload size of all of the files would have exceeded the limit of
            {` ${maxTotalMb}MB.`}
          </Alert>
        )}

        {emptyFiles.length > 0 && (
          <Alert type="warning" className="upload-warning">
            These files haven&apos;t been queued for upload because they are not larger than 0MB:
            {` ${emptyFiles.map((fileBlob) => fileBlob.file.name).join(', ')}.`}
          </Alert>
        )}

        {invalidTypeOrExcessiveSizeFiles.length > 0 && (
          <Alert type="warning" className="upload-warning">
            These files haven&apos;t been queued for upload because they are the wrong type or are above
            the size limit:
            {` ${invalidTypeOrExcessiveSizeFiles.map((fileBlob) => fileBlob.file.name).join(', ')}.`}
          </Alert>
        )}

        {invalidFileCharacters.length > 0 && (
          <Alert type="warning" className="upload-warning">
            These files haven&apos;t been queued for upload because they contain special characters:
            {` ${invalidFileCharacters.map((file) => file.name).join(', ')}.`}
          </Alert>
        )}

        <FileQueueSectionComponent queuedFiles={queuedFiles} removeFileFunc={this.removeFile} />

        <div className="submit-section">
          {optOutCheckbox && (
          <div className="submit-section-checkbox-container left-align">
            <Checkbox
              label={checkboxText}
              id="submitSectionCheckbox"
              className="uploadDocuments__checkbox"
              onClick={this.handleCheckboxClick}
            />
            {showOptOutError && (
              <Alert type="warning" className="submit-section__alert">
                All files queued for upload must be removed if you wish to
                opt out of submitting this supporting documentation.
              </Alert>
            )}
            {showTermsError && (
              <Alert type="warning" className="submit-section__alert">
                Please confirm you have provided all of your information.
              </Alert>
            )}
          </div>
          )}
          {!optOutCheckbox && showTermsError && (
            <Alert type="error" className="submit-section__alert">
              Please upload all required documents before submitting.
            </Alert>
          )}
          <Button
            className="minimum-width"
            onClick={this.submit}
            tabIndex="0"
            type="button"
          >
            Submit
          </Button>
          <Button
            className="minimum-width"
            onClick={this.cancelToReturnToSummary}
            secondary
            tabIndex="0"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(undefined, { push: pushAction }),
  withRouter,
)(UploadDocumentsComponent);
