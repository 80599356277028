import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { createNumberMask } from 'redux-form-input-masks';
import { BodyText, TextInput } from '@swift-paypal/pp-react';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const numberMask = createNumberMask({ allowEmpty: true });

const EmployeesAtApplicationTime = ({
  className, error, submitted, touched, value,
}) => (
  <>
    <BodyText as="label" htmlFor="employeesAtApplicationTime">
      Current number of employees
    </BodyText>
    <Field
      className={className}
      key="employees-at-application-time"
      name="employeesAtApplicationTime"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      type="tel"
      value={value}
      validate={[required({ msg: 'Enter an amount' })]}
      {...numberMask}
    />
  </>
);

EmployeesAtApplicationTime.defaultProps = {
  className: undefined,
  error: undefined,
  submitted: false,
  touched: false,
  value: undefined,
};

EmployeesAtApplicationTime.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.number,
};

export default memo(EmployeesAtApplicationTime);
