import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import { lnbUrlSelector } from '../sba3508SForm.selectors';

export const Sba3508SFormInstructionsLink = ({ anchor, formName, text }) => {
  const lnbUrl = useShallowSelector(lnbUrlSelector);
  return (
    <Link
      className="pp-react__link--inline"
      data-automation-id="sbaInstructionsLink"
      href={`${lnbUrl}/${formName}${anchor}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </Link>
  );
};

Sba3508SFormInstructionsLink.propTypes = {
  anchor: PropTypes.string,
  formName: PropTypes.string,
  text: PropTypes.string,
};

Sba3508SFormInstructionsLink.defaultProps = {
  anchor: '',
  formName: 'sba_form_3508S.pdf',
  text: 'SBA Form 3508S Instructions',
};
