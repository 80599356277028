import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { createNumberMask } from 'redux-form-input-masks';
import { BodyText, TextInput, Tooltip } from '@swift-paypal/pp-react';
import { TOOLTIP_DESCRIPTION, TOOLTIP_NAME } from './covered-supplier-costs-constants';
import constants from '../../../../../constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

const CoveredSupplierCosts = ({
  className, error, submitted, touched, value,
}) => (
  <>
    <BodyText as="label" htmlFor="coveredSupplierCosts">
      Covered supplier costs
    </BodyText>
    <Field
      className={className}
      key="covered-supplier-costs"
      name="coveredSupplierCosts"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      rightIcon={<Tooltip description={TOOLTIP_DESCRIPTION} name={TOOLTIP_NAME} />}
      type="tel"
      placeholder="$"
      value={value}
      validate={[required({ msg: constants.errors.currencyZeroError })]}
      {...currencyMask}
    />
  </>
);

CoveredSupplierCosts.defaultProps = {
  className: undefined,
  error: undefined,
  submitted: false,
  touched: false,
  value: undefined,
};

CoveredSupplierCosts.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.number,
};

export default memo(CoveredSupplierCosts);
