import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, BodyText } from '@swift-paypal/pp-react';
import { ppp21LoanVersions } from '../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';
import './body-text.less';

const FTEBodyText = ({ forgivenessAppEnabled, loanVersion }) => (
  <div className="body-text">
    {!forgivenessAppEnabled && (
      <BodyText as="p">
        FTE documents are required if you are using the SBA 3508 Form only. If you are
        using the SBA 3508EZ Form, please see the check box below.
      </BodyText>
    )}
    <BodyText as="p">
      {!forgivenessAppEnabled && (
        'If you are using the standard SBA 3508 Form, upload '
      )}
      {forgivenessAppEnabled && (
        'Upload '
      )}
      supporting documents to verify your Full-Time Equivalent (FTE)
      <br />
      employment levels.
      <Tooltip
        className="pp-react__tooltip--inline"
        description={
          'The Full-Time Equivalent ("FTE") is calculated for each employee by taking '
          + 'the number of hours paid per week, dividing by 40, and rounding the result '
          + 'to the nearest tenth. The maximum number for any single employee is 1.0. '
          + 'You may also use a simplified method that assigns a 1.0 for employees who '
          + 'work 40 hours or more per week and 0.5 for employees who work fewer hours.'
        }
      />
    </BodyText>
    <BodyText as="p">
      Please provide documentation showing the following:
    </BodyText>
    <ol>
      <BodyText as="li">
        The average number of FTE employees on payroll per week between
        <strong> Feb 15, 2019 – Jun 30, 2019 </strong>
        or
      </BodyText>
      <BodyText as="li">
        The average number of FTE employees on payroll per week between
        <strong> Jan 1, 2020 – Feb 29, 2020 </strong>
        or
      </BodyText>
      <BodyText as="li">
        If you are a Seasonal Employer, you may provide documentation
        showing the average number of FTE employees on payroll per week
        during any 1 of these periods:
        <ul>
          <BodyText as="li">
            <strong>Feb 15, 2019 – Jun 30, 2019 </strong>
            or
          </BodyText>
          <BodyText as="li">
            <strong>Jan 1, 2020 – Feb 29, 2020 </strong>
            or
          </BodyText>
          <BodyText as="li">
            Any consecutive
            <strong> 12-week period </strong>
            between
            {ppp21LoanVersions.includes(loanVersion)
              ? <strong> Feb 15, 2019 – Feb 15, 2020</strong>
              : <strong> May 1, 2019 – Sep 15, 2019</strong>}
          </BodyText>
        </ul>
      </BodyText>
    </ol>
    <BodyText as="p">
      The time period you choose must be the same time period you use to complete the
      <strong> PPP Schedule A, line 11</strong>
      . Documents submitted may cover periods longer than the specified time periods.
    </BodyText>
    <BodyText as="p">Your documentation must include:</BodyText>
    <ul>
      <BodyText as="li">
        Payroll tax filings reported, or that you will report, to the IRS (typically Form 941); and
      </BodyText>
      <BodyText as="li">
        State quarterly business and individual employee wage reporting and unemployment insurance tax filings
        reported, or that will be reported, to the relevant state(s).
      </BodyText>
      {!forgivenessAppEnabled && (
        <BodyText as="li">
          If you are using the SBA 3508EZ Form, and if you checked only the second box on page 1 of the SBA 3508EZ Form,
          provide documentation for the average number of FTE employees on payroll on Jan 1, 2020 and at the end of the
          covered period.
        </BodyText>
      )}
    </ul>
  </div>
);

FTEBodyText.propTypes = {
  forgivenessAppEnabled: PropTypes.bool.isRequired,
  loanVersion: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    forgivenessAppEnabled: state.config.forgivenessAppEnabled,
  };
}
export default connect(mapStateToProps)(FTEBodyText);
