import { isEmpty } from 'lodash';
import {
  SELF_SERVICE_LOAN_REQUEST_ERROR,
  SELF_SERVICE_LOAN_REQUEST_RECEIVED,
  SELF_SERVICE_LOAN_REQUEST_PENDING,
  SELF_SERVICE_BANKLIST_REQUEST_RECEIVED,
  SELF_SERVICE_BANKLIST_REQUEST_PENDING,
  SELF_SERVICE_BANKLIST_REQUEST_ERROR,
  SCHEDULE_SERVICE_ACH_RECEIVED,
  SCHEDULE_SERVICE_ACH_PENDING,
  SCHEDULE_SERVICE_ACH_ERROR,
  SCHEDULE_SERVICE_ACH_STATUS_RESET,
  SELF_SERVICE_BANK_UPDATE_REQUEST_RECEIVED,
  SELF_SERVICE_BANK_UPDATE_REQUEST_PENDING,
  SELF_SERVICE_BANK_UPDATE_REQUEST_ERROR,
  SELF_SERVICE_BANK_UPDATE_REQUEST_RESET,
  SELF_SERVICE_BANKLIST_REQUEST_MODAL_PENDING,
} from '../actions/one-time-payment/one-time-payment-types';
import { getLoansBasedOnDLQ } from '../../components/pages/oneTimePaymentPage/oneTimePayment.utils';
import oneTimePaymentConstants from '../../constants/oneTimePayments';

const defaultSelfServiceLoans = {
  loanAdvances: [],
  error: false,
  ssAchError: false,
  loading: false,
  bankList: {},
  scheduledPaymentStatus: null,
  scheduledPaymentErrorMsg: null,
  loanDropdownOptions: [],
  scheduledPaymentAmount: null,
  bankUpdateStatus: null,
  bankUpdateMessage: null,
  bankUpdateStatusCode: null,
  bankUpdatedDetails: null,
};
export const selfServiceLoan = (state = defaultSelfServiceLoans, action) => {
  switch (action.type) {
    case SELF_SERVICE_LOAN_REQUEST_RECEIVED: {
      const { loansList, selectedLoanAdvanceId = null } = action.payload;
      const loanAdvanceList = loansList.Advances || [];
      const { mostDLQLoanAdvance, loanDropdownOptions } = getLoansBasedOnDLQ(loanAdvanceList);
      const defaultLoanAdvance = loanAdvanceList?.find(({ Id }) => Id === selectedLoanAdvanceId) || mostDLQLoanAdvance;
      return {
        ...state,
        error: null,
        ssAchError: null,
        loading: false,
        defaultLoanAdvance,
        loanDropdownOptions,
        loanAdvances: loanAdvanceList,
      };
    }
    case SELF_SERVICE_LOAN_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
      };
    case SELF_SERVICE_LOAN_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload.message,
        ssAchError: true,
        loading: false,
      };
    case SELF_SERVICE_BANKLIST_REQUEST_RECEIVED: {
      const bankListReceivedForAdvId = action.payload?.bankList;
      const updatedBankList = Object.assign(state.bankList, bankListReceivedForAdvId);
      return {
        ...state,
        error: null,
        loading: false,
        bankList: updatedBankList,
      };
    }
    case SELF_SERVICE_BANKLIST_REQUEST_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SELF_SERVICE_BANKLIST_REQUEST_MODAL_PENDING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SELF_SERVICE_BANKLIST_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case SCHEDULE_SERVICE_ACH_RECEIVED: {
      let transactionAmt = '0';
      const transactionInfo = action.payload?.transactionScheduleInfo;
      if (!isEmpty(transactionInfo) && transactionInfo.transactionAmount) {
        transactionAmt = transactionInfo.transactionAmount;
      }
      return {
        ...state,
        scheduledPaymentStatus: 'success',
        scheduledPaymentAmount: transactionAmt,
      };
    }
    case SCHEDULE_SERVICE_ACH_PENDING: {
      return {
        ...state,
        scheduledPaymentStatus: 'pending',
        scheduledPaymentErrorMsg: null,
        scheduledPaymentAmount: null,
      };
    }
    case SCHEDULE_SERVICE_ACH_ERROR: {
      const defaultErrMsg = 'There is some error in scheduling payment now';
      const duplicateErrorMsg = 'Select a different date or adjust the amount to proceed, as there is already an existing transaction scheduled for the same date with the same amount.';
      const errorDetails = action.payload?.response?.data;
      const errCodes = ['DUPLICATE_ENTRY_EXISTS', 'FIELD_CUSTOM_VALIDATION_EXCEPTION'];
      let errMessage;
      if (errorDetails && errCodes.includes(errorDetails?.errCode)) {
        if (errorDetails?.errCode === 'DUPLICATE_ENTRY_EXISTS') {
          errMessage = duplicateErrorMsg;
        }
        else {
          errMessage = errorDetails?.errMessage;
        }
      }
      return {
        ...state,
        scheduledPaymentStatus: 'error',
        scheduledPaymentErrorMsg: errMessage || defaultErrMsg,
      };
    }
    case SCHEDULE_SERVICE_ACH_STATUS_RESET: {
      return {
        ...state,
        scheduledPaymentErrorMsg: null,
        scheduledPaymentAmount: null,
        scheduledPaymentStatus: null,
      };
    }
    case SELF_SERVICE_BANK_UPDATE_REQUEST_RECEIVED: {
      let bankDetails = null;
      let updateMessageText = null;
      let updateStatus = 'success';
      let statusCode = null;
      const { response, isEditMode } = action.payload;
      const { messages, failedStatusList } = oneTimePaymentConstants;
      const { Status: status, Records: records } = response;
      if (failedStatusList.includes(status) || records?.length === 0) {
        updateStatus = 'error';
        const errorCode = Object.keys(messages).find((messageId) => status.includes(messageId)) || 'FAILED_DEFAULT';
        updateMessageText = messages[errorCode];
        statusCode = errorCode;
      }
      else if (records?.length > 0) {
        [bankDetails] = records;
        updateMessageText = isEditMode ? messages.SUCCESS_EDIT_BANK : messages.SUCCESS_ADD_BANK;
      }
      return {
        ...state,
        bankUpdateMessage: updateMessageText,
        bankUpdateStatusCode: statusCode,
        bankUpdateStatus: updateStatus,
        bankUpdatedDetails: bankDetails,
      };
    }
    case SELF_SERVICE_BANK_UPDATE_REQUEST_PENDING:
      return {
        ...state,
        bankUpdateMessage: null,
        bankUpdateStatus: 'pending',
        bankUpdateStatusCode: null,
        bankUpdatedDetails: null,
      };
    case SELF_SERVICE_BANK_UPDATE_REQUEST_ERROR: {
      const statusCode = 'FAILED_DEFAULT';
      const { messages } = oneTimePaymentConstants;
      return {
        ...state,
        bankUpdateMessage: messages[statusCode],
        bankUpdateStatus: 'error',
        bankUpdateStatusCode: statusCode,
        bankUpdatedDetails: null,
      };
    }
    case SELF_SERVICE_BANK_UPDATE_REQUEST_RESET: {
      return {
        ...state,
        bankUpdateMessage: null,
        bankUpdateStatus: null,
        bankUpdateStatusCode: null,
        bankUpdatedDetails: null,
      };
    }
    default:
      return state;
  }
};
