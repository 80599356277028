import * as actions from '../actions/terms/terms-action-types';

const terms = (state = {}, action) => {
  if (action.type === actions.TERMS_OF_USE_REQUEST_RECEIVED) {
    return { ...state, error: null, termsOfUse: action.payload };
  }
  return state;
};

export default terms;
