import * as actions from '../actions/contact-us/contact-us-action-types';

const contactUs = (state = { submissionSuccess: false }, action) => {
  switch (action.type) {
    case actions.CASE_TYPES_RECEIVED:
      return { ...state, caseTypes: action.payload, error: false };
    case actions.CASE_TYPES_REQUEST_ERROR:
      return { ...state, error: action.payload.message };
    case actions.CASE_SUBMIT_RECEIVED:
      return {
        ...state,
        submissionSuccess: true,
        needsReset: true,
        error: false,
      };
    case actions.CASE_SUBMIT_REQUEST_ERROR:
      return {
        ...state,
        error: true,
        submissionSuccess: false,
        needsReset: false,
      };
    case actions.CONTACT_US_CLEAR_STATE:
      return {};
    case actions.CASE_SUBMIT_REQUEST_PENDING:
      return {
        ...state, submissionSuccess: false, error: undefined, needsReset: false,
      };
    case actions.CONTACT_US_RESET_COMPLETE:
      return { ...state, needsReset: false };
    default:
      return state;
  }
};

export default contactUs;
