import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './upload-spinner.less';

export const UploadSpinner = ({ children, display }) => (
  <div className={display === true ? 'vx_has-spinner-large upload-spinner' : ''}>
    {display === true && <div className="info-text vx_text-body-sm">Uploading documents. Please do not reload your browser.</div>}
    {children}
  </div>
);

const mapStateToProps = (state) => ({
  display: state.uploadDocument.uploadPending,
});

UploadSpinner.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  display: PropTypes.bool,
};

UploadSpinner.defaultProps = {
  children: undefined,
  display: false,
};

export default connect(mapStateToProps)(UploadSpinner);
