import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRedirect } from './useRedirect';

export const useActiveForgivenessSelected = () => {
  const activeAdvanceId = useSelector((state) => state.activeIds.advanceId);
  const redirect = useRedirect();
  useEffect(() => {
    if (!activeAdvanceId) {
      redirect();
    }
  }, [activeAdvanceId]);
};
