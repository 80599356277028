/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import '../oneTimePaymentPage.less';
import { isNil } from 'lodash';
import { BodyText, EditIcon } from '@paypalcorp/pp-react';
import { useTheme as useEmotionTheme } from '@emotion/react';
import constants from '../../../../constants';

const ScheduledTransactionStatus = ({
  transaction, editBankModalOpener, setScheduleTransactionEditBankData, loanAdvance,
}) => {
  const { sysColorBackgroundMediumContrast } = useEmotionTheme();
  const { transactionStatus } = constants.oneTimePayments;
  const transactionSource = transaction?.transactionSource;
  const transactionData = transactionStatus[transactionSource];
  const paymentEligibility = loanAdvance.IsEligibeForSSACH && loanAdvance.Balance > 0;

  const openEditBankModal = () => {
    if (transaction?.IsEditable) {
      const bankAccountAssocitedWithtrxn = transaction.BankAccount;
      if (!isNil(bankAccountAssocitedWithtrxn)) {
        setScheduleTransactionEditBankData({ isEditMode: true, fiData: bankAccountAssocitedWithtrxn });
      }
    }
    editBankModalOpener();
  };

  const TransactionStatusMessage = () => (
    <>
      <BodyText style={{ color: sysColorBackgroundMediumContrast }}>
        {transactionData.message}
      </BodyText>
      {
        transactionData.editBankCTA && transaction?.IsEditable && paymentEligibility
        && (
          <>
            <BodyText style={{ color: sysColorBackgroundMediumContrast }}>
              <span>
                <EditIcon size="xs" color="sysColorLinkMain" />
              </span>
              <span tabIndex="-1" role="button" style={{ color: '#0070ba', cursor: 'pointer' }} onClick={() => openEditBankModal()}>&nbsp;Please update your business bank account details</span>
              <span>&nbsp;and reschedule one-time payment.</span>
            </BodyText>
          </>

        )
      }

    </>
  );

  return (
    <>
      {
        transactionData !== undefined && (
          <>

            <TransactionStatusMessage />

          </>
        )
      }
    </>
  );
};

export default ScheduledTransactionStatus;
