import React from 'react';
import PropTypes from 'prop-types';
import {
  Sheet, Button, HeadingText, BodyText,
} from '@swift-paypal/pp-react';
import warningIcon from '../../../static/images/icons/icon_warning.svg';
import './session-extension-modal.less';

const noOp = () => {};

const SessionExtensionModal = ({
  isOpen, stayLoggedInFunc,
}) => (
  <Sheet
    center
    className="session-extension-sheet"
    wrapperClassName="session-extension-index"
    hideTitle
    isOpen={isOpen}
    noCloseButton
    onClose={noOp}
    requestClose={noOp}
    title="sessionExtensionSheet"
  >
    <img alt="" className="warning-icon" src={warningIcon} />
    <HeadingText as="h2">All done?</HeadingText>
    <BodyText>We&apos;ll log you out in a few moments.</BodyText>
    <Button className="stay-logged-in-button" onClick={stayLoggedInFunc}>Stay Logged In</Button>
  </Sheet>
);

SessionExtensionModal.propTypes = {
  isOpen: PropTypes.bool,
  stayLoggedInFunc: PropTypes.func,
};

SessionExtensionModal.defaultProps = {
  isOpen: false,
  stayLoggedInFunc: undefined,
};

export default SessionExtensionModal;
