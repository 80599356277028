import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { renewLoanAction } from '../../redux/actions/account-items/account-items-actions';
import Activity from './activity';

const ActivityWrapper = (props) => {
  const { loan, transactions, renewLoan } = props;
  return (
    <Activity loan={loan} transactions={transactions} renewLoanAction={renewLoan} />
  );
};

const mapStateToProps = (state) => ({
  loan: state.accountItems.selectedActivityLoan,
});

ActivityWrapper.propTypes = {
  loan: PropTypes.shape({}),
  renewLoan: PropTypes.func,
  transactions: PropTypes.arrayOf(PropTypes.object),
};

ActivityWrapper.defaultProps = {
  loan: undefined,
  renewLoan: undefined,
  transactions: undefined,
};

export default connect(mapStateToProps, { renewLoan: renewLoanAction })(ActivityWrapper);
