import axios from 'axios';

export default {
  verify,
};

async function verify(apiUrl, token) {
  const res = await axios.post(`${apiUrl}/auth/verify-email`, { token });
  return res.status;
}
