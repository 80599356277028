import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from '@swift-paypal/pp-react';

const StandardLayout = ({
  contentElement,
  sidebarElement,
}) => (
  sidebarElement ? (
    <Container fluid>
      <Row align="center">
        <Col
          xs={{ order: 2, span: 12 }}
          lg={{ order: 1, span: 4 }}
          className="standard-layout__sidebar standard-layout__sidebar--desktop"
        >
          {sidebarElement}
        </Col>
        <Col
          xs={{ order: 1, span: 12 }}
          lg={{ order: 2, span: 8 }}
          className="standard-layout__content"
        >
          {contentElement}
        </Col>
      </Row>
    </Container>
  ) : (
    <div className="standard-layout__sidebar">
      {contentElement}
    </div>
  )
);

StandardLayout.defaultProps = {
  contentElement: undefined,
  sidebarElement: undefined,
};

StandardLayout.propTypes = {
  contentElement: PropTypes.element,
  sidebarElement: PropTypes.element,
};

export default StandardLayout;
