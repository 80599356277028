import { SAppDetails } from './sAppDetails';
import { InReviewDetails } from './inReviewDetails';
import { StandardDetails } from './standardDetails';
import { SubmittedForReviewDetails } from './submittedDetails';

import {
  EZ_APP,
  IN_REVIEW,
  S_APP,
  STANDARD_APP,
  SUBMITTED_FOR_REVIEW,
} from '../../stipulations/stipulations.constants';

export const ForgivenessDetails = new Map([
  [EZ_APP, StandardDetails],
  [IN_REVIEW, InReviewDetails],
  [S_APP, SAppDetails],
  [STANDARD_APP, StandardDetails],
  [SUBMITTED_FOR_REVIEW, SubmittedForReviewDetails],
]);
