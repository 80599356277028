const formLabels = {
  air: 'AIR',
  existingLoan: 'Existing Loan',
  existingLoanPayoff: 'Existing Loan Payoff',
  interestRemaining: 'Total Loan Fee remaining',
  interestToBeWaived: 'Total Loan Fee to be waived',
  loanTerm: 'Loan Term',
  monthlyPayment: 'Monthly Payment',
  newCashDisbursed: 'New Cash Disbursed',
  newLoanAmount: 'New Loan Amount',
  principalRemaining: 'Principal Remaining',
  totalInterestCharge: 'Total Loan Fee',
  totalLoanFee: 'Total Loan Fee',
  totalPayoffAmount: 'Total Payoff Amount',
  totalRepaymentAmount: 'Total Repayment Amount',
  weeklyPayment: 'Weekly Payment',
};

export default formLabels;
