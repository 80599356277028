import React from 'react';
import PropTypes from 'prop-types';
import util from '../../../util';
import formLabels from '../../../constants/formLabels';
import Detail from './detail';

const ExistingLoan = ({ loan }) => {
  const {
    name, totalPayoff, waiveInterest, taPrincipalAdvancePortion, taInterestPremiumPortion,
  } = loan;

  return (
    <div className="existing-loan">
      <div className="detail-header">
        {formLabels.existingLoan}
        :
        {' '}
        {name}
      </div>
      <Detail
        label={formLabels.principalRemaining}
        amount={util.conditionallyDisplayCents(taPrincipalAdvancePortion)}
      />
      <Detail label={formLabels.interestRemaining} amount={util.conditionallyDisplayCents(taInterestPremiumPortion)} />
      {(waiveInterest && (
        <Detail
          label={formLabels.interestToBeWaived}
          amount={util.conditionallyDisplayCents(-taInterestPremiumPortion)}
        />
      ))}
      <Detail label={`${formLabels.totalPayoffAmount}*`} amount={util.conditionallyDisplayCents(totalPayoff)} />
    </div>
  );
};

ExistingLoan.propTypes = {
  loan: PropTypes.shape({
    name: PropTypes.string,
    taPrincipalAdvancePortion: PropTypes.number,
    taInterestPremiumPortion: PropTypes.number,
    totalPayoff: PropTypes.number,
    waiveInterest: PropTypes.bool,
  }),
};

ExistingLoan.defaultProps = {
  loan: undefined,
};

export default ExistingLoan;
