import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Loan from '../loan/loan';
import './business.less';

const Business = ({ businessName, advances }) => (
  <li>
    <ul className="business">
      <div className="business-name" data-automation-id="activityAccountLabel">{businessName}</div>
      {_.orderBy(advances, ['issuedDate'], ['desc']).map((advance) => <Loan advance={advance} key={advance.id} />)}
    </ul>
  </li>
);

Business.propTypes = {
  businessName: PropTypes.string,
  advances: PropTypes.arrayOf(PropTypes.object),
};

Business.defaultProps = {
  businessName: undefined,
  advances: undefined,
};

export default Business;
