import { LOAN_AGREEMENT_STATUS_REQUEST_PENDING, LOAN_AGREEMENT_STATUS_REQUEST_ERROR, LOAN_AGREEMENT_STATUS_RECEIVED } from '../actions/account-items/account-items-action-types';

const loanAgreementStatus = (state = {}, action) => {
  const status = action.payload;
  switch (action.type) {
    case LOAN_AGREEMENT_STATUS_RECEIVED:
      return status;
    case LOAN_AGREEMENT_STATUS_REQUEST_ERROR:
      return 'request_error';
    case LOAN_AGREEMENT_STATUS_REQUEST_PENDING:
      return 'request_pending';
    default:
      return state;
  }
};

export default loanAgreementStatus;
