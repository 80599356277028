import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col, Tile, Row, HeadingText,
} from '@swift-paypal/pp-react';
import withUrlParams from '../../hocs/with-url-params';
import opportunityConstants from '../../constants/opportunity';
import errors from '../../constants/errors';
import correctSalesforceDataService from '../../services/correctSalesforceData.service';
import { Alert, AlertTypes } from '../common/alert';
import Title from '../common/title';
import { ForgivenessTileList } from '../features';
import AccountSummary from './account-summary/account-summary';
import ApplyForPPPLoanTile from './apply-for-ppp-loan-tile/apply-for-ppp-loan-tile';
import NoProducts from './no-products';

const Summary = ({
  accounts,
  advances,
  country,
  flattenedStipulations,
  location,
  openAccounts,
  opportunities,
  owners,
  renewFunc,
  loanForgivenessError,
}) => {
  correctSalesforceDataService.correctAccountsStages(location, accounts, flattenedStipulations, country);

  return (
    <Tile card className="summary-tile">
      <HeadingText size="md">Summary</HeadingText>
      {loanForgivenessError && (
        <Alert
          message={errors.technicalIssue}
          alertType={AlertTypes.critical}
        />
      )}
      {country !== 'ca' && <ApplyForPPPLoanTile />}
      <ForgivenessTileList />
      {openAccounts?.map((account) => (
        <div key={account.accountId}>
          <Title text={account.accountName} />
          <Row className="account-container" align="center">
            <Col>
              {showNoProducts(account, advances, opportunities)
                ? (
                  <NoProducts account={account} />
                ) : (
                  <AccountSummary
                    account={account}
                    renewFunc={renewFunc}
                    owners={owners}
                  />
                )}
            </Col>
          </Row>
        </div>
      ))}
      {openAccounts.length === 0 && (
        <p className="vx_text-body-md">
          You do not have any open applications or loans at the moment.
        </p>
      )}
    </Tile>
  );
};

const showNoProducts = (account, advances, opportunities) => {
  const { accountId } = account;
  const accountAdvances = advances.filter((advance) => advance.accountId === accountId);
  const accountOpps = opportunities.filter((opp) => opp.accountId === accountId);
  const { closedLost, closedWon, types: { sba: SBA } } = opportunityConstants;
  const nonNullRescindedDate = advances.every((advance) => !!advance.rescindedDate);

  const numberOfLoans = accountAdvances.reduce((loans, advance) => {
    if (advance?.balance > 0) {
      loans += 1;
    }
    return loans;
  }, 0);

  const [
    numberOfClosedWonOpps,
    numberOfInFlightOpps,
    numberOfNotNotifiedSbaClosedLost,
  ] = accountOpps.reduce(([closedWonOpps, inFlight, notNotified], opp) => {
    const {
      adverseActionNoticeSent, isInFlight, stageName, type,
    } = opp;

    if (isInFlight) {
      if (stageName === closedWon) {
        closedWonOpps += 1;
      }
      else {
        inFlight += 1;
      }
    }

    if (stageName === closedLost && type === SBA && !adverseActionNoticeSent) {
      notNotified += 1;
    }

    return [closedWonOpps, inFlight, notNotified];
  }, [0, 0, 0]);

  if (numberOfNotNotifiedSbaClosedLost > 0) {
    return false;
  }

  return (numberOfLoans === 0 || nonNullRescindedDate) && numberOfClosedWonOpps === 0 && numberOfInFlightOpps === 0;
};

Summary.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  advances: PropTypes.arrayOf(PropTypes.object).isRequired,
  country: PropTypes.string,
  flattenedStipulations: PropTypes.arrayOf(PropTypes.object),
  location: ReactRouterPropTypes.location.isRequired,
  openAccounts: PropTypes.arrayOf(PropTypes.object),
  opportunities: PropTypes.arrayOf(PropTypes.object).isRequired,
  owners: PropTypes.arrayOf(PropTypes.object),
  renewFunc: PropTypes.func.isRequired,
  loanForgivenessError: PropTypes.bool,
};

Summary.defaultProps = {
  accounts: [],
  country: undefined,
  flattenedStipulations: undefined,
  openAccounts: [],
  owners: undefined,
  loanForgivenessError: false,
};

const mapStateToProps = (state) => ({
  advances: Object.values(state.entities.advances.byId),
  flattenedStipulations: state.notifications.flattenedStipulations,
  opportunities: Object.values(state.entities.opportunities.byId),
  owners: state.ownership.selectedOpportunityOwners,
  loanForgivenessError: state.entities.loanForgiveness.error,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(Summary);
