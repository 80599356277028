import EmploymentAndBusinessActivityForm from './employment-and-business-activity/employment-and-business-activity';
import EstimateForgivenessAmountForm from './estimate-forgiveness-amount/estimate-forgiveness-amount';
import PayrollCostsForm from './payroll-costs-form/payroll-costs-form';
import OtherBusinessDetailsForm from './other-business-details/other-business-details';
import OtherPayrollCostsForm from './other-payroll-costs/other-payroll-costs';
import CoveredPeriodEndDateForm from './covered-period-end-date-form/covered-period-end-date-form';

const pages = [
  {
    description: 'Payroll covered period',
    component: CoveredPeriodEndDateForm,
    percent: 1,
  },
  {
    description: 'Payroll costs',
    component: PayrollCostsForm,
    percent: 17,
  },
  {
    description: 'Other payroll costs',
    component: OtherPayrollCostsForm,
    percent: 34,
  },
  {
    description: 'FTE and business activity',
    component: EmploymentAndBusinessActivityForm,
    percent: 51,
  },
  {
    description: 'Other business details',
    component: OtherBusinessDetailsForm,
    percent: 68,
  },
  {
    description: 'Estimating your forgiveness amount',
    component: EstimateForgivenessAmountForm,
    percent: 84,
  },
];

export default pages;
