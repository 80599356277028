import React from 'react';
import waitIcon from '../../../static/images/icons/wait_icon.svg';
import './loan-state.less';

const VoidLoanAgreement = () => (
  <div className="loan-state">
    <img alt="Void loan logo" src={waitIcon} className="wait-icon" />
    <h3 className="vx_text-3">We&apos;re working on your loan agreement</h3>
    <div className="void-body">
      We&apos;ll create a new loan agreement, and then a business funding
      expert will call you. Afterwards, you&apos;ll be able to sign and
      upload the new loan agreement.
    </div>
  </div>
);
export default VoidLoanAgreement;
