import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import constants from '../constants/index';
import PageTitle from '../components/common/page-title';
import SecurePage from '../hocs/secure-page';
import ContactUsForm from '../components/contact-us-form/contact-us-form';
import { getOpportunitiesAction } from '../redux/actions/opportunities/opportunities-actions';
import { getAdvancesAction } from '../redux/actions/advances/advances-actions';
import {
  getCaseTypesAction,
  submitCase as submitCaseAction,
  clearState as clearStateAction,
  resetForm as resetFormAction,
  contactUsResetCompleteAction,
} from '../redux/actions/contact-us/contact-us-actions';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class ContactUsPage extends Component {
  static displayName = displayNames.ContactUsPage;

  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {
      accountId, getAdvances, getOpportunities, requestType, getCaseTypes,
    } = this.props;

    await getOpportunities();
    await getAdvances();
    await getCaseTypes();
    if (requestType) {
      switch (requestType) {
        case constants.contactUs.addMoney:
          this.setState({
            initialValues: {
              accountId,
              caseType: 'Request Additional Funding',
              message: 'I would like to request additional funding.',
            },
          });
          break;
        case constants.contactUs.addressUpdate:
          this.setState({
            initialValues: {
              accountId,
              caseType: 'Address Update',
            },
          });
          break;
        default:
          break;
      }
    }
    else if (accountId) {
      this.setState({ initialValues: { accountId } });
    }
  }

  componentWillUnmount() {
    const { clearState } = this.props;
    clearState();
  }

  submit = async (values) => {
    const { initialValues } = this.state;
    const { accounts, history, submitCase } = this.props;
    const { caseType, message, accountId } = values;
    let singleAccountId;

    if (accounts && accounts.length === 1) {
      singleAccountId = accounts[0].accountId;
    }

    const res = await submitCase(singleAccountId || accountId, caseType, message);
    if (!res.error && !initialValues) {
      this.resetForm();
    }
    else if (!res.error) {
      history.replace(`contact-us?accountId=${accountId}`);
      this.resetForm();
      this.setState({ initialValues: { accountId } });
    }
  };

  resetForm() {
    const { contactUsResetComplete, resetForm } = this.props;
    resetForm();

    contactUsResetComplete();
  }

  render() {
    const { initialValues } = this.state;

    const {
      accounts, advances, caseTypes, contactUsError, contactUsSuccess, opportunities,
    } = this.props;

    const hasSbaAdvance = Object.values(advances.byId)?.some(({ type }) => type === 'SBA');
    const hasSbaOpportunity = Object.values(opportunities.byId).some(({ type }) => type === 'SBA');
    const hasSbaLoans = hasSbaAdvance || hasSbaOpportunity;

    const filteredCaseTypes = caseTypes.filter(({ type, value }) => (hasSbaLoans ? type === 'SBA' || initialValues?.caseType === value : type !== 'SBA'));

    return (
      <div>
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <PageTitle text="Contact Us" />
              <ContactUsForm
                accounts={accounts}
                caseTypes={filteredCaseTypes}
                contactUsError={contactUsError}
                contactUsSuccess={contactUsSuccess}
                hasSbaLoans={hasSbaLoans}
                initialValues={initialValues}
                onSubmit={this.submit}
              />
            </div>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    advances: state.entities.advances,
    accounts: state.accountItems.accounts && _.sortBy(state.accountItems.accounts, ['accountName']),
    contactUsError: state.contactUs.error,
    caseTypes: state.contactUs.caseTypes,
    contactUsSuccess: state.contactUs.submissionSuccess,
    needsReset: state.contactUs.needsReset,
    opportunities: state.entities.opportunities,
  };
}

ContactUsPage.propTypes = {
  accountId: PropTypes.string,
  accounts: PropTypes.arrayOf(PropTypes.object),
  advances: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  caseTypes: PropTypes.arrayOf(PropTypes.object),
  clearState: PropTypes.func.isRequired,
  contactUsError: PropTypes.bool,
  contactUsResetComplete: PropTypes.func.isRequired,
  contactUsSuccess: PropTypes.bool,
  getAdvances: PropTypes.func.isRequired,
  getCaseTypes: PropTypes.func.isRequired,
  getOpportunities: PropTypes.func.isRequired,
  opportunities: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  requestType: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  submitCase: PropTypes.func.isRequired,
};

ContactUsPage.defaultProps = {
  accountId: undefined,
  accounts: [],
  caseTypes: [],
  contactUsError: undefined,
  contactUsSuccess: false,
  requestType: undefined,
};

const mapDispatchToProps = {
  getAdvances: getAdvancesAction,
  getCaseTypes: getCaseTypesAction,
  getOpportunities: getOpportunitiesAction,
  submitCase: submitCaseAction,
  clearState: clearStateAction,
  resetForm: resetFormAction,
  contactUsResetComplete: contactUsResetCompleteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurePage(ContactUsPage));
