/* eslint-disable max-len */
import loanService from '../../../services/loan.service';
import bankService from '../../../services/bank.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './one-time-payment-types';
import advanceService from '../../../services/advance.service';
import util from '../../../util';

const asyncActionHOC = (asyncTypes, actionFn) => asyncAuthorizedActionCreator(asyncTypes, actionFn);

const getSelfServiceLoansActionFunction = (selectedLoanAdvanceId) => async (dispatch, getState) => {
  const loansList = await loanService.getSelfServiceLoans(getState().config.apiUrl);
  return { loansList, selectedLoanAdvanceId };
};
export const getSelfServiceLoansAction = asyncActionHOC(
  {
    pending: actions.SELF_SERVICE_LOAN_REQUEST_PENDING,
    complete: actions.SELF_SERVICE_LOAN_REQUEST_RECEIVED,
    error: actions.SELF_SERVICE_LOAN_REQUEST_ERROR,
  }, getSelfServiceLoansActionFunction,
);

const getSelfServiceBankListFunction = (advanceId) => async (dispatch, getState) => {
  const banksListResponse = await bankService.getSelfServicePaymentBankList(
    getState().config.apiUrl,
    advanceId,
  );
  const decryptedResponse = util.decryptResponse(banksListResponse);
  const advanceBankMapping = {};
  advanceBankMapping[advanceId] = decryptedResponse;
  return { bankList: advanceBankMapping };
};

export const getSelfServicePaymentBankListAction = asyncActionHOC({
  pending: actions.SELF_SERVICE_BANKLIST_REQUEST_PENDING,
  complete: actions.SELF_SERVICE_BANKLIST_REQUEST_RECEIVED,
  error: actions.SELF_SERVICE_BANKLIST_REQUEST_ERROR,
}, getSelfServiceBankListFunction);

// this is same as getSelfServicePaymentBankListAction but without a loader just for modals
export const getSelfServicePaymentBankListActionForModal = asyncActionHOC({
  pending: actions.SELF_SERVICE_BANKLIST_REQUEST_MODAL_PENDING,
  complete: actions.SELF_SERVICE_BANKLIST_REQUEST_RECEIVED,
  error: actions.SELF_SERVICE_BANKLIST_REQUEST_ERROR,
}, getSelfServiceBankListFunction);

export const scheduleServiceACHAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SCHEDULE_SERVICE_ACH_PENDING,
    complete: actions.SCHEDULE_SERVICE_ACH_RECEIVED,
    error: actions.SCHEDULE_SERVICE_ACH_ERROR,
  },
  (requestPayload) => async (_dispatch, getState) => {
    const scheduleserviceACHResponse = await advanceService.postScheduleServiceACHTransaction(
      getState().config.apiUrl,
      requestPayload,
    );
    return scheduleserviceACHResponse;
  },
);

export const selfServiceBankAddAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SELF_SERVICE_BANK_UPDATE_REQUEST_PENDING,
    complete: actions.SELF_SERVICE_BANK_UPDATE_REQUEST_RECEIVED,
    error: actions.SELF_SERVICE_BANK_UPDATE_REQUEST_ERROR,
  },
  ({ payload, isEditMode }) => async (_dispatch, getState) => {
    const response = await bankService.postSelfServiceAddEditBank(
      getState().config.apiUrl,
      payload,
    );
    return { response, isEditMode };
  },
);

export const resetPaymentProcessingStatus = () => (dispatch) => dispatch({ type: actions.SCHEDULE_SERVICE_ACH_STATUS_RESET });
export const resetSelfServiceBankAddAction = () => (dispatch) => dispatch({ type: actions.SELF_SERVICE_BANK_UPDATE_REQUEST_RESET });
