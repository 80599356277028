export const formName = 'questionnaireForm';

export const fieldNames = {
  questionnaireQ1: 'ezAppQ1',
  questionnaireQ2: 'ezAppQ2',
  questionnaireQ3: 'ezAppQ3',
  questionnaireQ4: 'ezAppQ4',
  questionnaireQ5: 'appliedForFirstDraw',
};

export const q3Question = `Between Jan 1, 2020 and the end of your covered period, did you reduce the
  number of your employees or the average paid hours of your employees?`;

export const q3Prompt2020 = `Ignore reductions because you were unable to rehire people who were employees
  on Feb 15, 2020, and you were unable to hire similarly qualified employees
  for unfilled positions on or before December 31, 2020. Also ignore reductions
  in an employee’s hours that you offered to restore and the employee refused.`;

export const q3Promot2021 = `Ignore reductions because you were unable to rehire people who were employees
  on Feb 15, 2020, and you were unable to hire similarly qualified employees for unfilled
  positions on or before the end of your Covered Period. Also ignore reductions in an employee’s
  hours that you offered to restore and the employee refused.`;
