import resolve from '../../../services/route.service';
import {
  CLOSED_LOST,
  EZ_APP,
  EzAppStipulations,
  FORGIVENESS_APPLICATION,
  ForgivenessInReviewStages,
  ForgivenessRoutesNoOppIdParam,
  ForgivenessStipsParamCase,
  ForgivenessStipsSortOrder,
  ForgivenessStipulations,
  IN_REVIEW,
  ModifiedDisplayNames,
  S_APP,
  SAppSecondDrawStipulations,
  SAppStipulations,
  SBA,
  StipulationActionRoutes,
  SUBMITTED_FOR_REVIEW,
} from './stipulations.constants';

const filterForgivenessStipsByOppId = ({ opportunityId, stipulationMap }, oppId) => (
  opportunityId === oppId && stipulationMap.requestType === FORGIVENESS_APPLICATION
);

const filterForgivenessStipsByAppVersion = (
  { stipulationMap },
  applicationVersionSelected,
  loanVersion,
) => {
  if (applicationVersionSelected === S_APP) {
    if (loanVersion === '21SDPPP') {
      return SAppSecondDrawStipulations.includes(stipulationMap.name);
    }
    return SAppStipulations.includes(stipulationMap.name);
  }
  if (applicationVersionSelected === EZ_APP) {
    return EzAppStipulations.includes(stipulationMap.name);
  }
  if (stipulationMap.name === ForgivenessStipulations.SbaForm3508) {
    return false;
  }
  return true;
};

const sortForgivenessStipsByName = ({ stipulationMap: stipA }, { stipulationMap: stipB }) => {
  const a = ForgivenessStipsSortOrder.indexOf(stipA.name);
  const b = ForgivenessStipsSortOrder.indexOf(stipB.name);
  return a - b;
};

export const getForgivenessDetailsKey = (forgivenessStage, applicationVersionSelected, stipulations) => {
  const allStipsFulfilled = stipulations?.every(({ fulfilled }) => fulfilled) || false;

  if (allStipsFulfilled && ForgivenessInReviewStages.includes(forgivenessStage)) {
    return IN_REVIEW;
  }

  if (allStipsFulfilled) {
    return SUBMITTED_FOR_REVIEW;
  }

  return applicationVersionSelected;
};

export const getForgivenessStipulations = (
  activeOppId,
  stipulationList,
  applicationVersionSelected,
  loanVersion,
) => {
  const filteredStipulations = activeOppId && stipulationList
    ?.filter((stip) => filterForgivenessStipsByOppId(stip, activeOppId))
    ?.filter((stip) => filterForgivenessStipsByAppVersion(
      stip, applicationVersionSelected, loanVersion,
    ));

  if (!filteredStipulations) {
    return filteredStipulations;
  }

  const actionRequiredStips = filteredStipulations
    .filter(({ fulfilled }) => !fulfilled)
    .sort(sortForgivenessStipsByName);

  const fulfilledStips = filteredStipulations
    .filter(({ fulfilled }) => fulfilled)
    .sort(sortForgivenessStipsByName);

  return [...actionRequiredStips, ...fulfilledStips];
};

export const getCreditStipulations = (activeOppId, stipulationList) => (
  activeOppId && stipulationList
    ?.filter(({ opportunityId, stipulationMap }) => (
      opportunityId === activeOppId
      && stipulationMap.requestType !== FORGIVENESS_APPLICATION
      && stipulationMap.classification
      && (stipulationMap.documentName || stipulationMap.name)
    ))
    .sort((stipA, stipB) => stipA.fulfilled - stipB.fulfilled)
);

export const getClassName = (element, modifier, additionalClasses = []) => {
  let additionalClassesString = '';
  let modifierString = '';

  if (modifier) {
    modifierString = `--${modifier}`;
  }

  if (additionalClasses?.length > 0) {
    additionalClassesString = [' ', ...additionalClasses].join(' ');
  }

  return `stipulation__${element}${modifierString}${additionalClassesString}`;
};

export const getIsApplicationUnderReview = (opportunity, stipulations) => {
  if (opportunity?.type === SBA && opportunity?.stageName === CLOSED_LOST) {
    return !opportunity.adverseActionNoticeSent;
  }

  return stipulations?.every(({ fulfilled }) => fulfilled) || false;
};

export const getRouteKey = (actionData) => {
  const { applicationVersionSelected, stipulationName } = actionData;

  if (stipulationName === ForgivenessStipulations.SbaForm3508 && applicationVersionSelected === S_APP) {
    return S_APP;
  }

  return stipulationName;
};

export const getStipulationRouteData = (actionData) => {
  const {
    country,
    applicationVersionSelected,
    opportunityId,
    requestType,
    stipulationId,
    stipulationName,
  } = actionData;

  const routeKey = getRouteKey(actionData);

  const path = StipulationActionRoutes[routeKey];

  const params = { country, opportunityId };

  if (requestType === FORGIVENESS_APPLICATION) {
    if (applicationVersionSelected === S_APP && stipulationName === ForgivenessStipulations.SbaForm3508) {
      params.stipulationId = stipulationId;
    }
    else if (ForgivenessRoutesNoOppIdParam.includes(stipulationName)) {
      delete params.opportunityId;
    }
    else {
      params.groupName = ForgivenessStipsParamCase[stipulationName];
    }
  }

  return path ? resolve(path, params) : undefined;
};

export const getStipulationDisplayName = (documentName, name, applicationVersionSelected) => {
  if (name === ForgivenessStipulations.SbaForm3508) {
    if (applicationVersionSelected === EZ_APP || applicationVersionSelected === S_APP) {
      return ModifiedDisplayNames[applicationVersionSelected];
    }
  }

  if (name in ModifiedDisplayNames) {
    return ModifiedDisplayNames[name];
  }

  if (documentName) {
    return documentName;
  }

  return name;
};
