import React from 'react';

const DownloadPayrollCalculator = () => (
  <div className="secondary-gray margin-bottom-sm">
    1. Your completed Average Monthly Payroll Calculator
    {' '}
    <a href="https://www.loanbuilder.com/ppp_calculator.xlsx" target="_blank" rel="noopener noreferrer" data-automation-id="downloadPayrollCalculatorLink">(click here to download)</a>
    .
  </div>
);

export default DownloadPayrollCalculator;
