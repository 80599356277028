import CONFIG_SAVED, { CONFIG_VALUES_UPDATED } from './config-action-types';

export const updateConfigValuesAction = (updatedConfigValues) => (dispatch, getState) => {
  const { config } = getState();
  dispatch({
    type: CONFIG_VALUES_UPDATED,
    payload: {
      ...config,
      ...updatedConfigValues,
    },
  });
};

export default (config) => ({
  type: CONFIG_SAVED,
  payload: config,
});
