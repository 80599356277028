import { BodyText } from '@swift-paypal/pp-react';
import React from 'react';

const FirstVisitDetails = () => (
  <BodyText as="p">
    Enter the details below for your SBA Form 3508S application.
  </BodyText>
);

export default FirstVisitDetails;
