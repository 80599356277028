import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tile } from '@swift-paypal/pp-react';
import { SbaLoanInformationSidebar } from '../../forgiveness/sba-loan-information-sidebar';
import HaveQuestionsTile from '../have-questions-tile/have-questions-tile';
import ProgressTrackerTile from '../../summary/progress-tracker-tile/progress-tracker-tile';
import CaseWorkerTile from '../case-worker-tile/case-worker-tile';
import opportunityConstants from '../../../constants/opportunity';

const SidebarComponent = ({
  advancesById,
  children,
  loanForgiveness,
  opportunities,
  showLoanForgivenessView,
}) => {
  const hasLoans = opportunities.some((opp) => opp.isInFlight);
  const isExclusivelySbaLoans = opportunities.every((opp) => opp.type === 'SBA');
  const haveAnyPppOpps = opportunities.some(({ type }) => type === 'SBA');
  const advances = Object.values(advancesById);
  const bfes = opportunities.map((opp) => opp.businessFundingExpert);

  return (
    <>
      {(!isExclusivelySbaLoans && !showNoProducts(opportunities, advances) && (
        <ProgressTrackerTile />
      ))}
      {children}
      {!showLoanForgivenessView && hasLoans && (<HaveQuestionsTile bfes={bfes} haveAnyPppOpps={haveAnyPppOpps} />)}
      {showLoanForgivenessView && (
        <>
          <Tile card className="margin-top-sm">
            <SbaLoanInformationSidebar />
          </Tile>
          <CaseWorkerTile caseWorker={loanForgiveness?.caseWorker} />
        </>
      )}
    </>
  );
};

const showNoProducts = (opportunities = [], advances = []) => {
  const hasOpenOpportunities = opportunities.some(
    (opportunity) => opportunity.stageName !== opportunityConstants.closedWon
      && opportunity.stageName !== opportunityConstants.closedLost,
  );
  const inFlightOpportunities = opportunities.filter((opportunity) => opportunity.isInFlight);
  const closedWonOpportunities = inFlightOpportunities.filter((opportunity) => opportunity.stageName);
  return (
    advances.length === 0
    && closedWonOpportunities.length === 0
    && !hasOpenOpportunities
  );
};

SidebarComponent.propTypes = {
  advancesById: PropTypes.objectOf(PropTypes.shape({})),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  loanForgiveness: PropTypes.shape({
    advanceId: PropTypes.string,
    caseWorker: PropTypes.shape({}),
    loanInformation: PropTypes.shape({}),
  }),
  opportunities: PropTypes.arrayOf(PropTypes.shape({
    isInFlight: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
  })),
  showLoanForgivenessView: PropTypes.bool,
};

SidebarComponent.defaultProps = {
  advancesById: {},
  children: undefined,
  loanForgiveness: {},
  opportunities: [],
  showLoanForgivenessView: false,
};

const mapStateToProps = (state) => ({
  advancesById: state.entities.advances?.byId,
  opportunities: Object.values(state.entities.opportunities?.byId || {}),
  loanForgiveness: state.entities.loanForgiveness?.byId[state.entities.loanForgiveness?.allIds?.[0]],
});

export default connect(mapStateToProps)(SidebarComponent);
