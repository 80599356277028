import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert, Row, Col, CaptionText, Tile, Divider, Container, HeadingText, Button,
} from '@swift-paypal/pp-react';
import ppLogoMain from '../../static/images/logos/paypal.svg';
import lbAppLogo from '../../static/images/logos/lbapps.svg';
import lbLogo from '../../static/images/icons/lb_logo_circle.svg';
import PaypalLogin from './paypal-login-revamp';
import paypalAuthErrors from '../../constants/paypalAuthErrors';
import constants from '../../constants';
import './login-revamp.less';
import LoginFormRevamp from './login-form-revamp/login-form-revamp';
import OrDivider from './or-divider/or-divider';

const LoginRevamp = ({
  country, message, type, paypalAuthError, submitFunc,
}) => {
  const [showLoanBuilderLogin, setShowLoanBuilderLogin] = useState(
    message === constants.messages.forgotPasswordSuccess,
  );

  const handleClick = () => {
    setShowLoanBuilderLogin(!showLoanBuilderLogin);
  };

  const LoginOptions = () => (
    <Container className="align-center login-revamp">
      <Tile className="center-content login-tile" card>
        <Row>
          <Col>
            <img src={ppLogoMain} alt="" className="logo" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <HeadingText className="login-title">Login</HeadingText>
          </Col>
        </Row>
        <Row className="caption-container">
          <Col xs={12}>
            <CaptionText className="caption login-caption">
              Which account did you use when you first applied to your business loan?
            </CaptionText>
            {paypalAuthError === paypalAuthErrors.accessDenied
          && (
          <Alert className="alert" type="error">Some of your info is incorrect. Please check it and try again.</Alert>
          )}
            {paypalAuthError === paypalAuthErrors.notFound
          && (
          <Alert className="alert" type="warning">We couldn&apos;t find a loan attached to that account. Try using a different login option.</Alert>
          )}
          </Col>
        </Row>
        <Divider />
        <PaypalLogin country={country} />
        <Divider />
        <Row onClick={handleClick}>
          <Col xs={2}>
            <img src={lbLogo} alt="" className="icon" />
          </Col>
          <Col className="login-option" xs={10}>
            <HeadingText className="text">LoanBuilder</HeadingText>
          </Col>
        </Row>
      </Tile>
    </Container>
  );

  const LoanBuilderLogin = () => (
    <Container className="align-center loan-builder-revamp">
      <Tile className="center-content login-tile" card>
        <img src={lbAppLogo} alt="" />
        <HeadingText className="login-title">Login</HeadingText>
        <LoginFormRevamp submitFunc={submitFunc} message={message} type={type} country={country} />
        <Link to={`/${country}/forgot-password`}>Having trouble logging in?</Link>
        <OrDivider forceHorizontal />
        <CaptionText className="option-caption">Not what you&apos;re looking for?</CaptionText>
        <Button type="button" onClick={handleClick} className="vx_anchor"> See more login options </Button>
      </Tile>
    </Container>
  );

  return (
    (showLoanBuilderLogin
      ? <LoanBuilderLogin />
      : <LoginOptions />
    ));
};

LoginRevamp.propTypes = {
  country: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  paypalAuthError: PropTypes.string,
  submitFunc: PropTypes.func.isRequired,
};

LoginRevamp.defaultProps = {
  country: undefined,
  message: undefined,
  type: undefined,
  paypalAuthError: undefined,
};

export default LoginRevamp;
