import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import AnonymousPage from '../hocs/anonymous-page';
import Login from '../components/login/login';
import LoginRevamp from '../components/login/login-revamp';
import { removeLocalStorage } from '../services/window.service';
import { authenticateAction } from '../redux/actions/auth/auth-actions';
import { addDeauthorized } from '../redux/actions/auth/auth-action-types';
import getApiHealthAction from '../redux/actions/maintenance/maintenance-actions';
import { clearLoginPageMessageAction } from '../redux/actions/login/login-page-actions';
import displayNames from '../constants/displayNames';
import paypalAuthErrors from '../constants/paypalAuthErrors';

class LoginPage extends Component {
  static displayName = displayNames.LoginPage;

  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    clearMessage: PropTypes.func.isRequired,
    country: PropTypes.string,
    deauthorize: PropTypes.func.isRequired,
    getApiHealth: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    loginRevamp: PropTypes.bool.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    message: PropTypes.string,
    paypalAuthError: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    message: undefined,
    paypalAuthError: undefined,
    type: undefined,
  };

  async componentDidMount() {
    const {
      deauthorize, getApiHealth, match, paypalAuthError,
    } = this.props;
    if (process.browser) {
      removeLocalStorage('token');
    }

    if (!paypalAuthError || paypalAuthError === paypalAuthErrors.accessDenied
      || paypalAuthError === paypalAuthErrors.notFound) {
      removeLocalStorage('utm');
      deauthorize();
    }

    getApiHealth(match, '/', 'index');
  }

  componentWillUnmount() {
    const { clearMessage } = this.props;
    clearMessage();
  }

  submit = (values) => {
    const { authenticate, country, history } = this.props;
    const { password, ...otherValues } = values;
    authenticate({
      ...otherValues,
      password: encodeURIComponent(password),
      country,
      history,
    });
  };

  render() {
    const {
      country, message, type, loginRevamp, paypalAuthError,
    } = this.props;
    return (
      <div>
        <Helmet>
          <title>Log In</title>
        </Helmet>
        {
        loginRevamp
          ? (
            <LoginRevamp
              message={message}
              country={country}
              paypalAuthError={paypalAuthError}
              submitFunc={this.submit}
            />
          )
          : <Login message={message} type={type} submitFunc={this.submit} country={country} />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginRevamp: state.config.loginRevamp,
    message: state.indexPage.messageBody,
    type: state.indexPage.messageType,
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    authenticate: (args) => dispatch(authenticateAction(args)),
    getApiHealth: (...args) => dispatch(getApiHealthAction(...args)),
    deauthorize: () => dispatch(addDeauthorized()),
    clearMessage: () => dispatch(clearLoginPageMessageAction()),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousPage(LoginPage));
