import React from 'react';
import PropTypes from 'prop-types';
import { useShallowSelector } from '../../../hooks';
import { OffbookAdvanceTile } from './offbookAdvanceTile';
import { offbookAdvanceTileListSelector } from './offbookAdvanceTileList.selectors';
import './offbookAdvanceTileList.less';

export const OffbookAdvanceTileList = ({ accountId }) => {
  const { advances, opportunities } = useShallowSelector(offbookAdvanceTileListSelector);
  const { allIds, byId } = advances;
  const { byId: oppsById } = opportunities;

  const offbookAdvanceIds = allIds.filter((id) => {
    const advance = byId[id];
    const opportunity = oppsById[advance?.opportunityId];
    return (
      opportunity?.type === 'SBA'
      && advance?.accountId === accountId
      && advance?.type === 'OFB'
    );
  });

  if (!offbookAdvanceIds?.length) {
    return null;
  }

  return (
    <div className="offbook-advance-tile-list">
      {offbookAdvanceIds.map((offbookAdvanceId) => (
        <OffbookAdvanceTile advance={byId[offbookAdvanceId]} key={offbookAdvanceId} />
      ))}
    </div>
  );
};

OffbookAdvanceTileList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default OffbookAdvanceTileList;
