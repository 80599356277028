import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  BodyText, Col, Container, Divider, HeadingText, Row,
} from '@swift-paypal/pp-react';
import NumberOfOwners from '../../fields/number-of-owners/number-of-owners';
import { nonCashPayrollCostsFields } from './other-payroll-constants';
import OwnerCompensationTotal from '../../fields/owner-compensation-total/owner-compensation-total';
import { otherPayrollCostsSelector } from './other-payroll-costs-selectors';
import { useShallowSelector } from '../../../../../hooks';
import { getCoveredPeriodFromDates } from './other-payroll-costs-utils';
import './other-payroll-costs.less';

const OtherPayrollCosts = ({
  children,
  handleSubmit,
  submitted,
}) => {
  const {
    fields,
    values,
    syncErrors,
    standardCoveredPeriodStartDate,
    standardCoveredPeriodEndDate,
  } = useShallowSelector(otherPayrollCostsSelector);

  const [coveredStart, coveredEnd] = getCoveredPeriodFromDates({
    standardCoveredPeriodStartDate, standardCoveredPeriodEndDate,
  });

  return (
    <Container className="other-payroll-costs" form as="form" onSubmit={handleSubmit} noValidate>
      <Row>
        <Col form="full">
          <HeadingText size="sm" as="h2">Tell us about your non-cash compensation employer costs</HeadingText>
          <BodyText className="field-section-header" strong as="h3">
            Non-cash payroll costs between
            {` ${coveredStart} `}
            &ndash;
            {` ${coveredEnd}`}
          </BodyText>
          <Divider secondary />
          {nonCashPayrollCostsFields.map(([FieldComponent, fieldKey]) => (
            <FieldComponent
              error={syncErrors?.[fieldKey]}
              key={fieldKey}
              submitted={submitted}
              touched={fields?.[fieldKey]?.touched}
              value={values?.[fieldKey]}
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <BodyText className="field-section-header" strong as="h3">
            Owner compensation
          </BodyText>
          <Divider secondary />
          <NumberOfOwners
            error={syncErrors?.numberOfOwners}
            submitted={submitted}
            touched={fields?.numberOfOwners?.touched}
            value={values?.numberOfOwners}
          />
          <OwnerCompensationTotal
            coveredEnd={coveredEnd}
            coveredStart={coveredStart}
            error={syncErrors?.ownerCompensationTotal}
            submitted={submitted}
            touched={fields?.ownerCompensationTotal?.touched}
            value={values?.ownerCompensationTotal}
          />
        </Col>
      </Row>
      {children}
    </Container>
  );
};

OtherPayrollCosts.defaultProps = {
  submitted: false,
};

OtherPayrollCosts.propTypes = {
  children: PropTypes.element.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'forgivenessPage3',
})(OtherPayrollCosts);
