import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './contract-action-types';
import contractService from '../../../services/contract.service';
import { openFile, openInNewTab } from '../../../services/window.service';

export const displayContractAction = asyncAuthorizedActionCreator(
  {
    pending: actions.DISPLAY_CONTRACT_REQUEST_PENDING,
    error: actions.DISPLAY_CONTRACT_REQUEST_ERROR,
    complete: actions.DISPLAY_CONTRACT_REQUEST_RECEIVED,
  },
  (opportunityId) => async (dispatch, getState) => {
    const data = await contractService.getContract(getState().config.apiUrl, opportunityId);
    const file = new Blob(
      [data],
      { type: 'application/pdf' },
    );
    const fileURL = URL.createObjectURL(file);
    openFile(fileURL);
  },
);

export const uploadContractAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CONTRACT_UPLOAD_REQUEST_PENDING,
    error: actions.CONTRACT_UPLOAD_REQUEST_ERROR,
    complete: actions.CONTRACT_UPLOAD_REQUEST_RECEIVED,
  },
  (opportunityId, files) => (dispatch, getState) => {
    const result = contractService.upload(getState().config.apiUrl, opportunityId, files);
    return result;
  },
);

export const reviewContractAction = asyncAuthorizedActionCreator(
  {
    pending: actions.REVIEW_CONTRACT_REQUEST_PENDING,
    error: actions.REVIEW_CONTRACT_REQUEST_ERROR,
    complete: actions.REVIEW_CONTRACT_REQUEST_RECEIVED,
  },
  (opportunityId) => async (dispatch, getState) => {
    const data = await contractService.getContractViewUrl(getState().config.apiUrl, opportunityId);
    openInNewTab(data.url);
  },
);
