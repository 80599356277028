import { BodyText, Button, HeadingText } from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRedirect, useShallowSelector } from '../../../../hooks';
import { setActiveAdvanceId, setActiveForgivenessId } from '../../../../redux/actions/active-ids/active-ids-actions';
import routes from '../../../../routes';
import { ForYouCard } from '../../../features';
import { retrieveApplicationLabel } from '../forgiveness-stage-messaging/forgiveness-stage-messaging-utils';
import { ppp21LoanVersions, PPP21_FIRST_DRAW } from '../forgiveness-summary-tile-constants';
import {
  CUSTOMER_SIGNOFF_MESSAGING,
  REVIEW,
  TITLE,
  TITLE_21_FIRST_DRAW,
  TITLE_21_SECOND_DRAW,
} from './customer-signoff-messaging-constants';
import { customerSignoffMessagingSelector } from './customer-signoff-messaging-selectors';
import './customer-signoff-messaging.less';

const CustomerSignoffMessaging = ({ advanceId, forgivenessId, signoffCompleted }) => {
  const { advancesById, country, portalRedesignEnabled } = useShallowSelector(customerSignoffMessagingSelector);
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const redirectToReviewPage = () => {
    const routeKey = signoffCompleted ? 'forgiveness-customer-signoff-completed' : 'forgiveness-customer-signoff';
    dispatch(setActiveAdvanceId(advanceId));
    dispatch(setActiveForgivenessId(forgivenessId));
    redirect(routes[routeKey].path, { country });
  };

  const { loanVersion } = advancesById[advanceId];
  const message = CUSTOMER_SIGNOFF_MESSAGING;
  let title = TITLE;
  if (ppp21LoanVersions.includes(loanVersion)) {
    title = loanVersion === PPP21_FIRST_DRAW ? TITLE_21_FIRST_DRAW : TITLE_21_SECOND_DRAW;
  }

  if (!portalRedesignEnabled) {
    return (
      <div className="customer-signoff-messaging">
        <HeadingText size="sm">
          {title}
        </HeadingText>
        <BodyText as="p">
          {message}
        </BodyText>
        <Button onClick={redirectToReviewPage}>{REVIEW}</Button>
      </div>
    );
  }

  return (
    <ForYouCard
      applicationLabel={retrieveApplicationLabel(loanVersion)}
      callToActionFunction={redirectToReviewPage}
      callToActionText={REVIEW}
      cardTitle={title}
      description={message}
    />
  );
};

CustomerSignoffMessaging.defaultProps = {
  signoffCompleted: undefined,
};

CustomerSignoffMessaging.propTypes = {
  advanceId: PropTypes.string.isRequired,
  forgivenessId: PropTypes.string.isRequired,
  signoffCompleted: PropTypes.string,
};

export default CustomerSignoffMessaging;
