import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Container } from '@swift-paypal/pp-react';
import redirect from '../../lib/redirect';
import util from '../../util';
import Title from '../../components/common/title';
import PageTitle from '../../components/common/page-title';
import SecurePage from '../../hocs/secure-page';
import HomePhoneFormComponent from '../../components/settings/home-phone/home-phone-form';
import { addHomePhoneAction, clearSettingsMessageAction, getContactAccountsAction } from '../../redux/actions/settings/settings-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import updatedSettingNames from '../../constants/updatedSettingNames';

class AddHomePhonePage extends Component {
  static displayName = displayNames.AddHomePhonePage;

  static propTypes = {
    addHomePhone: PropTypes.func.isRequired,
    apiUrl: PropTypes.string.isRequired,
    clearSettingsMessage: PropTypes.func.isRequired,
    contactAccounts: PropTypes.arrayOf(PropTypes.object),
    contactAccountsPending: PropTypes.bool,
    getContactAccounts: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
  };

  static defaultProps = {
    contactAccounts: undefined,
    contactAccountsPending: false,
  };

  async componentDidMount() {
    const { apiUrl, getContactAccounts } = this.props;
    await getContactAccounts(apiUrl);
  }

  componentWillUnmount() {
    const { clearSettingsMessage } = this.props;
    clearSettingsMessage();
  }

  submit = (values) => {
    const { phone } = values;
    const {
      apiUrl,
      contactAccounts,
      addHomePhone,
      history,
      match,
    } = this.props;
    util.blurOnSubmit();

    addHomePhone(apiUrl, phone, contactAccounts[0].contact.id);
    const queryParams = {
      updatedSettingName: updatedSettingNames.homePhone,
      updatedSettingValue: phone,
    };
    redirect(history, match, routes.settings.path, queryParams);
  };

  render() {
    const {
      contactAccountsPending,
    } = this.props;

    return (
      <div data-automation-id="addHomePhonePage">
        {(contactAccountsPending === false) && (
          <div>
            <Helmet>
              <title>Add Home Phone Number</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <Container fluid>
                  <PageTitle text="Additional Information" />
                  <Title text="Home phone number" />
                  <HomePhoneFormComponent
                    onSubmit={this.submit}
                  />
                </Container>
              )}
              sidebarElement={<></>}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    bfes: state.accountItems.bfes,
    contactAccounts: state.settings.contactAccounts,
    contactAccountsPending: state.settings.contactAccountsPending,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addHomePhone: (apiUrl, homePhoneNumber, contactId) => {
      dispatch(addHomePhoneAction(apiUrl, homePhoneNumber, contactId));
    },
    clearSettingsMessage: () => {
      dispatch(clearSettingsMessageAction());
    },
    getContactAccounts: (apiUrl) => {
      dispatch(getContactAccountsAction(apiUrl));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurePage(AddHomePhonePage));
