import settingsService from '../../../services/settings.service';
import authService from '../../../services/auth.service';
import emailVerificationService from '../../../services/emailVerification.service';
import { asyncAuthorizedActionCreator, asyncNoAuthActionCreator } from '../util/async-action-creator';
import * as actions from './settings-action-types';

export const getContactAccountsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CONTACT_ACCOUNTS_REQUEST_PENDING,
    complete: actions.CONTACT_ACCOUNTS_RECEIVED,
    error: actions.CONTACT_ACCOUNTS_REQUEST_ERROR,
  },
  (apiUrl) => () => settingsService.getContactAccounts(apiUrl),
);

const getHomePhoneAndWeb = (apiUrl, contactId) => settingsService.getHomePhoneAndBusinessWebAddress(apiUrl, contactId);

export const getHomePhoneAndBusinessWebAddressAction = asyncAuthorizedActionCreator(
  {
    pending: actions.GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_REQUEST_PENDING,
    complete: actions.GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_RECEIVED,
    error: actions.GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_REQUEST_ERROR,
  },
  (contactId) => (dispatch, getState) => getHomePhoneAndWeb(getState().config.apiUrl, contactId),
);

export const changePasswordAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CHANGE_PASSWORD_REQUEST_PENDING,
    complete: actions.CHANGE_PASSWORD_RECEIVED,
    error: actions.CHANGE_PASSWORD_REQUEST_ERROR,
  },
  (password, newPassword, confirmPassword) => (dispatch, getState) => {
    const { config } = getState();
    return settingsService.changePassword(config.apiUrl, password, newPassword, confirmPassword);
  },
);

export const listSecurityQuestionsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.LIST_SECURITY_QUESTIONS_REQUEST_PENDING,
    complete: actions.LIST_SECURITY_QUESTIONS_RECEIVED,
    error: actions.LIST_SECURITY_QUESTIONS_ERROR,
  },
  () => (dispatch, getState) => authService.listSecurityQuestions(getState().config.apiUrl),
);

export const changeSecurityQuestionsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CHANGE_SECURITY_QUESTIONS_REQUEST_PENDING,
    complete: actions.CHANGE_SECURITY_QUESTIONS_RECEIVED,
    error: actions.CHANGE_SECURITY_QUESTIONS_REQUEST_ERROR,
  },
  (question1, answer1, question2, answer2) => (dispatch, getState) => {
    const { config } = getState();
    return authService.changeSecurityQuestions(config.apiUrl, question1, answer1, question2, answer2);
  },
);

export const updateSettingsMessageAction = (messageBody, messageType) => async (dispatch) => {
  dispatch({ type: actions.UPDATE_SETTINGS_MESSAGE, messageBody, messageType });
};

export const clearSettingsMessageAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_SETTINGS_MESSAGE });
};

export const changeEmailAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CHANGE_EMAIL_REQUEST_PENDING,
    complete: actions.CHANGE_EMAIL_RECEIVED,
    error: actions.CHANGE_EMAIL_REQUEST_ERROR,
  },
  (apiUrl, newEmail, password) => () => settingsService.changeEmail(apiUrl, newEmail, password),
);

export const verifyEmailAction = asyncNoAuthActionCreator({
  pending: actions.VERIFY_EMAIL_REQUEST_PENDING,
  complete: actions.VERIFY_EMAIL_RECEIVED,
  error: actions.VERIFY_EMAIL_REQUEST_ERROR,
},
(token) => (dispatch, getState) => emailVerificationService.verify(getState().config.apiUrl, token));

export const addHomePhoneAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ADD_HOME_PHONE_REQUEST_PENDING,
    complete: actions.ADD_HOME_PHONE_RECEIVED,
    error: actions.ADD_HOME_PHONE_REQUEST_ERROR,
  },
  (apiUrl, homePhoneNumber, contactId) => () => settingsService.addHomePhone(apiUrl, homePhoneNumber, contactId),
);

export const addBusinessWebAddressAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ADD_BUSINESS_WEB_ADDRESS_REQUEST_PENDING,
    complete: actions.ADD_BUSINESS_WEB_ADDRESS_RECEIVED,
    error: actions.ADD_BUSINESS_WEB_ADDRESS_REQUEST_ERROR,
  },
  (apiUrl, website, contactId) => () => settingsService.addBusinessWebAddress(apiUrl, website, contactId),
);
