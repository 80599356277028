export const forgivenessApplicationSidebarSelector = (state) => ({
  advances: state.entities.advances,
  activeForgivenessId: state.activeIds.forgivenessId,
  loanForgiveness: state.entities.loanForgiveness,
  lastPageSubmitted: state.forgivenessForm?.lastPageSubmitted,
  opportunitiesById: state.entities.opportunities.byId,
  page1Values: state.form?.forgivenessPage1?.values || {},
  page5Values: state.form?.forgivenessPage5?.values || {},
  page6Values: state.form?.forgivenessPage6?.values || {},
});
