/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import getDisplayName from '../lib/get-display-name';
import { setLocalStorage } from '../services/window.service';

const storeUtmFromUrl = (Page) => {
  class StoreUtmFromUrl extends React.Component {
    static displayName = `StoreUtmFromUrl(${getDisplayName(Page)})`;

    static propTypes = {
      utm_campaign: PropTypes.string,
    };

    static defaultProps = {
      utm_campaign: undefined,
    };

    componentDidMount() {
      const { utm_campaign } = this.props;

      if (utm_campaign) {
        setLocalStorage('utm', utm_campaign);
      }
    }

    render() {
      return <Page {...this.props} />;
    }
  }

  return StoreUtmFromUrl;
};

export default storeUtmFromUrl;
