import routes from '../../../routes';

export const CLOSED_LOST = 'Closed Lost';
export const FORGIVENESS_APPLICATION = 'Forgiveness Application';
export const EZ_APP = '3508EZ';
export const S_APP = '3508S';
export const STANDARD_APP = '3508';
export const IN_REVIEW = 'inReview';
export const SUBMITTED_FOR_REVIEW = 'submittedForReview';

export const SBA = 'SBA';
export const STANDARD_DETAILS = 'Please complete the following action items so that we can proceed with the review of your loan application.';
export const US = 'us';
export const UNDER_REVIEW_DETAILS = 'Your application is currently under review. We will be in touch when the review is complete or if there is anything else needed.';

export const ClassNames = {
  Button: 'button',
  ButtonColumn: 'button-column',
  Fulfilled: 'fulfilled',
  Row: 'row',
  StipulationName: 'name',
};

export const ForgivenessInReviewStages = [
  'Document Review',
  'Quality Check Sendback',
  'Internal Quality Check',
];

export const ForgivenessStipulations = {
  CoveredCostsDocuments: 'Covered Cost / Expenditure Documents',
  ScheduleAWorksheet: 'Schedule A Worksheet',
  DemographicInformation: 'Demographic Information',
  ForgivenessDemographicInformation: 'Forgiveness Demographic Information',
  PayrollSupportingDocuments: 'Payroll Supporting Documents',
  FteDocuments: 'FTE Documents',
  MortgageInterestDocuments: 'Mortgage Interest Documents',
  RentDocuments: 'Rent Documents',
  UtilitiesDocuments: 'Utilities Documents',
  SbaForm3508: 'SBA Form 3508',
  LoFoReductionInGrossReceiptsInfo: 'Forgiveness Reduction in Gross Receipts Information',
  LoFoReductionInGrossReceiptsDocuments: 'Forgiveness Reduction in Gross Receipts Documents',
};

export const ForgivenessStipsSortOrder = [
  ForgivenessStipulations.SbaForm3508,
  ForgivenessStipulations.ScheduleAWorksheet,
  ForgivenessStipulations.PayrollSupportingDocuments,
  ForgivenessStipulations.FteDocuments,
  ForgivenessStipulations.MortgageInterestDocuments,
  ForgivenessStipulations.RentDocuments,
  ForgivenessStipulations.UtilitiesDocuments,
  ForgivenessStipulations.CoveredCostsDocuments,
  ForgivenessStipulations.DemographicInformation,
  ForgivenessStipulations.ForgivenessDemographicInformation,
  ForgivenessStipulations.LoFoReductionInGrossReceiptsInfo,
  ForgivenessStipulations.LoFoReductionInGrossReceiptsDocuments,
];

export const EzAppStipulations = [
  ForgivenessStipulations.CoveredCostsDocuments,
  ForgivenessStipulations.SbaForm3508,
  ForgivenessStipulations.PayrollSupportingDocuments,
  ForgivenessStipulations.MortgageInterestDocuments,
  ForgivenessStipulations.RentDocuments,
  ForgivenessStipulations.UtilitiesDocuments,
  ForgivenessStipulations.DemographicInformation,
  ForgivenessStipulations.ForgivenessDemographicInformation,
];

export const SAppStipulations = [
  ForgivenessStipulations.SbaForm3508,
  ForgivenessStipulations.DemographicInformation,
  ForgivenessStipulations.ForgivenessDemographicInformation,
];

export const SAppSecondDrawStipulations = [
  ForgivenessStipulations.SbaForm3508,
  ForgivenessStipulations.DemographicInformation,
  ForgivenessStipulations.ForgivenessDemographicInformation,
  ForgivenessStipulations.LoFoReductionInGrossReceiptsInfo,
  ForgivenessStipulations.LoFoReductionInGrossReceiptsDocuments,
];

export const CreditStipulations = {
  CalculatePayrollData: 'Calculate Payroll Data',
  DriversLicense: 'Driver\'s License',
  ReductionInGrossReceiptsDocuments: 'Reduction in Gross Receipts Documents',
  EidlSummary: 'EIDL Summary',
  PayrollVerification: 'Payroll Verification',
  BankStatements: 'Bank Statements',
  BeneficialOwnerInformation: 'Beneficial Owner Information',
  VerifyBankAccount: 'Verify Bank Account',
  OwnershipAndAffiliations: 'Ownership and Affiliations',
  BusinessCertificateLicense: 'Business Certificate/License',
  GrossReceiptsDocuments: 'Gross Receipts Documents',
  ReductionInGrossReceipts: 'Reduction in Gross Receipts',
  BorrowerDemographicInformation: 'Borrower Demographic Information',
  TaxReturn: 'Tax Return',
  ProofOfIdentity: 'Valid proof of identity',
  VoidedCheck: 'Copy of a Voided Check',
};

export const CreditStipulationActionRoutes = {
  [CreditStipulations.CalculatePayrollData]: routes['calculate-payroll-data'].path,
  [CreditStipulations.DriversLicense]: routes['drivers-license'].path,
  [CreditStipulations.ReductionInGrossReceiptsDocuments]: routes['reduction-in-gross-receipts-documents'].path,
  [CreditStipulations.EidlSummary]: routes['upload-addendum-b'].path,
  [CreditStipulations.PayrollVerification]: routes['payroll-verification'].path,
  [CreditStipulations.BankStatements]: routes['upload-canadian-bank-statements'].path,
  [CreditStipulations.BeneficialOwnerInformation]: routes['business-ownership'].path,
  [CreditStipulations.VerifyBankAccount]: routes['application-add-bank-accounts'].path,
  [CreditStipulations.OwnershipAndAffiliations]: routes['upload-addendum-a'].path,
  [CreditStipulations.BusinessCertificateLicense]: routes['business-license'].path,
  [CreditStipulations.GrossReceiptsDocuments]: routes['reduction-in-gross-receipts-documents'].path,
  [CreditStipulations.ReductionInGrossReceipts]: routes['reduction-in-gross-receipts'].path,
  [CreditStipulations.BorrowerDemographicInformation]: routes['demographic-information'].path,
  [CreditStipulations.TaxReturn]: routes['tax-return'].path,
  [CreditStipulations.ProofOfIdentity]: routes['proof-of-identity'].path,
  [CreditStipulations.VoidedCheck]: routes['voided-check'].path,
};

export const StipulationActionRoutes = {
  [CreditStipulations.CalculatePayrollData]: routes['calculate-payroll-data'].path,
  [CreditStipulations.DriversLicense]: routes['drivers-license'].path,
  [CreditStipulations.ReductionInGrossReceiptsDocuments]: routes['reduction-in-gross-receipts-documents'].path,
  [CreditStipulations.EidlSummary]: routes['upload-addendum-b'].path,
  [CreditStipulations.PayrollVerification]: routes['payroll-verification'].path,
  [CreditStipulations.BankStatements]: routes['upload-canadian-bank-statements'].path,
  [CreditStipulations.BeneficialOwnerInformation]: routes['business-ownership'].path,
  [CreditStipulations.VerifyBankAccount]: routes['application-add-bank-accounts'].path,
  [CreditStipulations.OwnershipAndAffiliations]: routes['upload-addendum-a'].path,
  [CreditStipulations.BusinessCertificateLicense]: routes['business-license'].path,
  [CreditStipulations.GrossReceiptsDocuments]: routes['reduction-in-gross-receipts-documents'].path,
  [CreditStipulations.ReductionInGrossReceipts]: routes['reduction-in-gross-receipts'].path,
  [CreditStipulations.BorrowerDemographicInformation]: routes['demographic-information'].path,
  [CreditStipulations.TaxReturn]: routes['tax-return'].path,
  [CreditStipulations.ProofOfIdentity]: routes['proof-of-identity'].path,
  [CreditStipulations.VoidedCheck]: routes['voided-check'].path,
  [ForgivenessStipulations.ScheduleAWorksheet]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.DemographicInformation]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.ForgivenessDemographicInformation]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.PayrollSupportingDocuments]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.FteDocuments]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.MortgageInterestDocuments]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.RentDocuments]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.UtilitiesDocuments]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.SbaForm3508]: routes['upload-loan-forgiveness'].path,
  [ForgivenessStipulations.CoveredCostsDocuments]: routes['covered-costs-documents'].path,
  [ForgivenessStipulations.LoFoReductionInGrossReceiptsInfo]: routes['forgiveness-reduction-in-gross-receipts'].path,
  [ForgivenessStipulations.LoFoReductionInGrossReceiptsDocuments]: routes['forgiveness-reduction-in-gross-receipts-documents'].path,
  [S_APP]: routes['sba-3508s'].path,
};

export const ForgivenessRoutesNoOppIdParam = [
  ForgivenessStipulations.CoveredCostsDocuments,
  ForgivenessStipulations.LoFoReductionInGrossReceiptsDocuments,
  ForgivenessStipulations.LoFoReductionInGrossReceiptsInfo,
];

export const ForgivenessStipsParamCase = {
  [ForgivenessStipulations.DemographicInformation]: 'demographic-information',
  [ForgivenessStipulations.ForgivenessDemographicInformation]: 'forgiveness-demographic-information',
  [ForgivenessStipulations.FteDocuments]: 'fte-documents',
  [ForgivenessStipulations.MortgageInterestDocuments]: 'mortgage-interest-documents',
  [ForgivenessStipulations.PayrollSupportingDocuments]: 'payroll-supporting-documents',
  [ForgivenessStipulations.RentDocuments]: 'rent-documents',
  [ForgivenessStipulations.ScheduleAWorksheet]: 'schedule-a-worksheet',
  [ForgivenessStipulations.UtilitiesDocuments]: 'utilities-documents',
  [ForgivenessStipulations.SbaForm3508]: 'sba-form-3508',
};

export const ModifiedDisplayNames = {
  [CreditStipulations.BankStatements]: 'Bank statement upload',
  [CreditStipulations.CalculatePayrollData]: 'Payroll Calculation Data',
  [CreditStipulations.GrossReceiptsDocuments]: 'Reduction In Gross Receipts Documents',
  [EZ_APP]: 'SBA Form 3508EZ',
  [ForgivenessStipulations.DemographicInformation]: 'PPP Borrower Demographic Information Form',
  [ForgivenessStipulations.ForgivenessDemographicInformation]: 'PPP Borrower Demographic Information Form',
  [ForgivenessStipulations.FteDocuments]: 'FTE documents',
  [ForgivenessStipulations.MortgageInterestDocuments]: 'Business mortgage interest documents',
  [ForgivenessStipulations.PayrollSupportingDocuments]: 'Payroll documents',
  [ForgivenessStipulations.RentDocuments]: 'Business rent/lease documents',
  [ForgivenessStipulations.ScheduleAWorksheet]: 'Schedule A worksheet',
  [ForgivenessStipulations.UtilitiesDocuments]: 'Business utility documents',
  [ForgivenessStipulations.CoveredCostsDocuments]: 'Covered cost / expenditure documents',
  [ForgivenessStipulations.LoFoReductionInGrossReceiptsInfo]: 'Reduction in Gross Receipts Information',
  [ForgivenessStipulations.LoFoReductionInGrossReceiptsDocuments]: 'Reduction in Gross Receipts Documents',
  [S_APP]: 'SBA Form 3508S Information',
};
