import _fp from 'lodash/fp';
import { allowedSingleFileSize, allowedFileTypes, mimeTypes } from '../constants/upload-document';
import errors from '../constants/errors';

export default {
  getFilesErrors,
};

function getFilesErrors(files, customRules = null) {
  const filesErrors = [];

  files.forEach((file) => {
    const fileErrors = getFileErrors(file, customRules);
    if (fileErrors.length) {
      filesErrors.push(fileErrors);
    }
  });

  return filesErrors;
}

function getFileErrors(file, customRules) {
  const fileErrors = [];

  const filePathError = getFilePathError(file);
  if (filePathError) {
    fileErrors.push(filePathError);
  }

  const fileSizeError = getFileSizeError(file);
  if (fileSizeError) {
    fileErrors.push(fileSizeError);
  }

  const fileTypeError = getFileTypeError(file, customRules);
  if (fileTypeError) {
    fileErrors.push(fileTypeError);
  }

  const fileNameError = getFileNameError(file);
  if (fileNameError) {
    fileErrors.push(getFileNameError(file));
  }

  return fileErrors;
}

function getFilePathError(file) {
  if (!file || file.size === undefined) {
    throw new TypeError('file is undefined, or does not have the size defined.');
  }
}

function getFileSizeError(file) {
  if (!file || file.size === undefined) {
    throw new TypeError('file is undefined, or does not have the size defined.');
  }

  if (file.size <= 0) {
    return {
      fingerPrint: `${file.size}_${file.name}`,
      message: errors.uploads.fileTooSmall,
      fileName: file.name,
    };
  }

  return (file.size > 0 && file.size > allowedSingleFileSize)
    ? {
      fingerPrint: `${file.size}_${file.name}`,
      message: errors.uploads.fileTooBig,
      fileName: file.name,
    } : null;
}

function getFileTypeError(file, customRules) {
  if (!file || file.type === undefined) {
    throw new TypeError('file is undefined, or does not have the type defined.');
  }

  const toLower = (str) => (str ? str.toLowerCase() : str);
  const fileExt = _fp.compose(_fp.last, (str) => (str ? str.split('.') : str))(file.name);

  const allowedFileTypesResolved = customRules && customRules.allowedFileTypes
    ? customRules.allowedFileTypes : allowedFileTypes;
  const isValidExt = _fp.flatten(_fp.values(allowedFileTypesResolved)).includes(toLower(fileExt));

  const mimeTypesResolved = customRules && customRules.mimeTypes ? customRules.mimeTypes : mimeTypes;
  const isCsvType = mimeTypesResolved.csv ? file.type.includes(mimeTypesResolved.csv) : true;
  const isDocxType = mimeTypesResolved.docx ? file.type.includes(mimeTypesResolved.docx) : true;
  const isPdfType = mimeTypesResolved.pdf ? file.type.includes(mimeTypesResolved.pdf) : true;
  const isXlsxType = mimeTypesResolved.xlsx ? file.type.includes(mimeTypesResolved.xlsx) : true;
  const isImageType = mimeTypesResolved.images
    ? (
      _fp.compose(
        _fp.partial(
          _fp.contains,
          [_fp.placeholder],
          _fp.map(toLower, mimeTypesResolved.images),
        ),
        toLower,
      )(file.type)
    ) : true;

  const isValidType = isCsvType || isDocxType || isImageType || isPdfType || isXlsxType;
  const errorMessageResolved = customRules
    ? errors.uploads.fileTypeIsNotAllowedTemplate.replace('{fileTypes}', customRules.fileTypeIsNotAllowedList)
    : errors.uploads.fileTypeIsNotAllowed;

  return isValidExt && isValidType
    ? null
    : {
      fingerPrint: `${file.size}_${file.name}`,
      message: errorMessageResolved,
      fileName: file.name,
    };
}

function getFileNameError(file) {
  if (!file || file.name === undefined) {
    throw new TypeError('file is undefined, or does not have the name defined.');
  }

  const notAllowedCharsRegex = /[|:<>/\\]+/;
  return notAllowedCharsRegex.test(file.name)
    ? {
      fingerPrint: `${file.size}_${file.name}`,
      message: errors.uploads.fileNameIsNotAllowed,
      fileName: file.name,
    } : null;
}
