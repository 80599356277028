import React from 'react';
import PropTypes from 'prop-types';
import { CaptionText, Col, Row } from '@swift-paypal/pp-react';

const PasswordReq = ({ predicate, reqText }) => (
  <Row className={predicate ? 'meets-reqs' : 'fails-reqs'}>
    <Col xs={1} className={`vx_icon vx_icon-small icon-col ${predicate ? 'vx_icon-checkmark-sm check' : 'oval'}`} />
    <Col xs={11} className="checklist-text">
      <CaptionText>{reqText}</CaptionText>
    </Col>
  </Row>
);

PasswordReq.propTypes = {
  predicate: PropTypes.bool.isRequired,
  reqText: PropTypes.string.isRequired,
};

export default PasswordReq;
