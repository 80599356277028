import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Field } from 'redux-form';
import {
  BodyText, Col, Row, TextInput,
} from '@swift-paypal/pp-react';
import { getDateFieldAttributesByDevice } from '../../../../lib/forms/date-field-utils';
import { useShallowSelector } from '../../../../hooks';
import { dateFirstWireIssuedSelector, fieldSelector } from '../sba3508SForm.selectors';
import { fieldNames } from './sba3508SFormFields.constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const CoveredPeriodEndDateField = () => {
  const fieldName = fieldNames.coveredPeriodEndDate;
  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);
  const dateFirstWireIssued = useShallowSelector(dateFirstWireIssuedSelector);
  const [dynamicAttributes, setDynamicAttributes] = useState({});

  useEffect(() => {
    if (dateFirstWireIssued) {
      setDynamicAttributes(getDateFieldAttributesByDevice(dateFirstWireIssued));
    }
  }, [dateFirstWireIssued]);

  if (!dateFirstWireIssued) {
    return null;
  }

  return (
    <Row>
      <Col form="full">
        <BodyText as="label" htmlFor={fieldNames.coveredPeriodEndDate}>
          Select the End Date for your Covered Period starting on
          {` ${moment(dateFirstWireIssued).format('MM/DD/YYYY')}`}
        </BodyText>
        <Field
          component={MappedInput}
          errorText={touched ? error : undefined}
          helperText={helperText}
          key="covered-period-end-date-field"
          name={fieldNames.coveredPeriodEndDate}
          value={value}
          placeholder="mm/dd/yyyy"
          {...dynamicAttributes}
        />
      </Col>
    </Row>
  );
};

const helperText = 'The Covered Period begins with your loan disbursement date'
  + ' (which is shown to you as the start date in this question) and ends on a date selected'
  + ' by you that is at least 8 weeks from, but not more than 24 weeks after your loan disbursement date.';

export default memo(CoveredPeriodEndDateField);
