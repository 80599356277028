export const ACCOUNTS_REQUEST_PENDING = 'accounts_request_pending';
export const ACCOUNTS_REQUEST_RECEIVED = 'accounts_request_received';
export const ACCOUNTS_REQUEST_ERROR = 'accounts_request_error';

export const IS_RENEWAL_ELIGIBLE_REQUEST_PENDING = 'is_renewal_eligible_pending';
export const IS_RENEWAL_ELIGIBLE_REQUEST_RECEIVED = 'is_renewal_eligible_received';
export const IS_RENEWAL_ELIGIBLE_REQUEST_ERROR = 'is_renewal_eligible_request_error';

export const CREATE_PPP_LEAD_REQUEST_PENDING = 'create_ppp_lead_pending';
export const CREATE_PPP_LEAD_REQUEST_RECEIVED = 'create_ppp_lead_received';
export const CREATE_PPP_LEAD_REQUEST_ERROR = 'create_ppp_lead_request_error';
