import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { numericality, required } from 'redux-form-validators';
import {
  BodyText,
  Button,
  Col,
  Container,
  HeadingText,
  Link,
  Row,
} from '@swift-paypal/pp-react';
import errors from '../../../constants/errors';
import { useRedirect, useShallowSelector } from '../../../hooks';
import { fulfillStipulationAction } from '../../../redux/actions/stipulations/stipulations-actions';
import routes from '../../../routes';
import paycheckProtectionPlanService from '../../../services/paycheckProtectionPlan.service';
import { Alert, AlertTypes } from '../../common/alert';
import { forgivenessGrossReceiptsSelector } from './forgivenessGrossReceipts.selectors';
import RadioField from './radio-field';
import SelectionField from './selection-field';
import TextField from './text-field';
import './forgivenessGrossReceipts.less';

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

const ForgivenessGrossReceipts = ({ handleSubmit }) => {
  const redirect = useRedirect();
  const {
    activeAdvanceId,
    advances,
    apiUrl,
    fields,
    fulfillStipulation,
    opportunities,
    syncErrors,
    stipulationId,
    registeredFields,
    values,
  } = useShallowSelector(forgivenessGrossReceiptsSelector);
  const dispatch = useDispatch();
  useEffect(() => onFulfillStipulation(), [fulfillStipulation]);

  const [submissionError, setSubmissionError] = useState(false);

  const { opportunityId } = advances.byId[activeAdvanceId] || {};
  const { paycheckProtectionPlanId } = opportunities.byId[opportunityId] || {};

  const onCancel = () => {
    redirect(routes['forgiveness-summary'].path);
  };

  const onFulfillStipulation = () => {
    setSubmissionError(!!fulfillStipulation?.error);
    if (!fulfillStipulation?.pending && fulfillStipulation?.success) {
      redirect(routes['forgiveness-summary'].path, { country: 'us' });
    }
  };

  const onSubmit = async () => {
    const mapQuestionToField = {
      QuartersQ2: 'businessActive2019',
      Quarter2019: 'period2Quarter', // if undefined, default is 2019, else, this value is a quarter in 2019
      QuarterRevenue2019: 'period2Revenue', // if Quarter2019 is undefined, this is annual revenue for 2019, else, quarter revenue for 2019 in Quarter2019
      Quarter2020: 'period1Quarter', // if undefined, default is 2020, else, this value is a quarter in 2020
      QuarterRevenue2020: 'period1Revenue', // if Quarter2020 is undefined, this is annual revenue for 2020, else, quarter revenue for 2020 in Quarter2020
      QuartersQ2No: 'grossReceiptReductionIsDemonstrated',
      AnnualRevenue2019: 'period2Revenue', // see above
      AnnualRevenue2020: 'period1Revenue', // see above
    };

    const data = {};
    Object.values(registeredFields).forEach((field) => {
      if (mapQuestionToField[field.name]) {
        data[mapQuestionToField[field.name]] = values[field.name];
      }
    });
    data.paycheckProtectionPlanId = paycheckProtectionPlanId;
    const submitData = {
      ...data,
      opportunityId,
    };
    try {
      const result = await paycheckProtectionPlanService.updatePppObject(apiUrl, submitData);
      if (!result || result.error) {
        setSubmissionError(true);
        return;
      }
      dispatch(fulfillStipulationAction(opportunityId, stipulationId));
    }
    catch (error) {
      setSubmissionError(true);
    }
  };

  return (
    <div className="forgiveness-gross-receipts">
      <HeadingText size="md">Reduction in Gross Receipts</HeadingText>
      <BodyText as="p" className="body-text">
        Use this form to provide the details on the reduction in Gross Receipts of at least
        25% that you acknowledged and certified to on your PPP loan application. While providing
        this information was not required at the time you applied for your second draw loan, the
        SBA requires that you now provide it with your loan forgiveness application.
      </BodyText>
      <BodyText as="p" className="body-text">
        Provide the additional information requested below to support the 25% or more gross receipt
        reduction you acknowledged and certified on your PPP loan application.
      </BodyText>
      <BodyText as="p" className="body-text">
        For more information on the Gross Receipt reduction requirements, see the
        {' '}
        <Link
          className="pp-react__link--inline"
          data-automation-id="sbaForm2483SDInstructions"
          href="https://www.sba.gov/document/sba-form-2483-sd-ppp-second-draw-borrower-application-form"
          target="_blank"
          rel="noopener noreferrer"
        >
          SBA Form 2483-SD
        </Link>
        {' '}
        or
        {' '}
        <Link
          className="pp-react__link--inline"
          data-automation-id="sbaForm2483SD-CInstructions"
          href="https://www.sba.gov/document/sba-form-2483-sd-c-second-draw-borrower-application-form-schedule-c-filers-using-gross-income"
          target="_blank"
          rel="noopener noreferrer"
        >
          2483-SD-C
        </Link>
        {' '}
        Instructions, as applicable to your 2021 Second Draw loan application.
      </BodyText>
      <Container className="forgiveness-gross-receipts__form" form>
        <Row align="center">
          <Col form="half">
            {submissionError && <Alert message={errors.technicalIssue} alertType={AlertTypes.critical} />}
            <SelectionField
              size="sm"
              name="q1"
              errorText={fields?.q1?.touched && syncErrors?.q1}
              label="Would you like to compare 2019 to 2020 quarters or 2019 to 2020 annual periods for reduction in gross receipts?"
              validate={[required({ message: 'Required' })]}
            >
              <option value="" key="" disabled>Select</option>
              ,
              <option key="Quarters" value="Quarters">Quarters</option>
              <option key="Annual" value="Annual">Annual</option>
            </SelectionField>

            {values.q1 === 'Quarters' && (
            <>
              <RadioField
                name="QuartersQ2"
                label="Were you in business in 2019?"
                error={syncErrors?.QuartersQ2}
                touched={fields?.QuartersQ2?.touched}
                options={[
                  {
                    className: 'form-control',
                    label: 'Yes',
                    name: 'QuartersQ2',
                    type: 'radio',
                    value: 'Yes',
                  },
                  {
                    className: 'form-control',
                    label: 'No',
                    name: 'QuartersQ2',
                    type: 'radio',
                    value: 'No',
                  },
                ]}
                validate={[
                  required({ msg: errors.requiredField }),
                ]}
              />
              {values.QuartersQ2 === 'Yes' && (
                <>
                  <SelectionField
                    size="sm"
                    name="Quarter2019"
                    errorText={fields?.Quarter2019?.touched && syncErrors?.Quarter2019}
                    label="Select the 2019 quarter that you are using for comparison purposes"
                    validate={[required({ message: 'Required' })]}
                  >
                    <option value="" key="" disabled>Select</option>
                    ,
                    <option key="1Q" value="1Q">Q1</option>
                    <option key="2Q" value="2Q">Q2</option>
                    <option key="3Q" value="3Q">Q3</option>
                    <option key="4Q" value="4Q">Q4</option>
                  </SelectionField>
                  <TextField
                    name="QuarterRevenue2019"
                    label="Enter the Gross Receipts for your selected Quarter in 2019"
                    error={syncErrors?.QuarterRevenue2019}
                    touched={fields?.QuarterRevenue2019?.touched}
                    validate={[
                      required({ msg: errors.enterAnAmount }),
                      numericality({ '>=': 0, msg: errors.enterAnAmount }),
                    ]}
                    {...currencyMask}
                  />
                  <SelectionField
                    size="sm"
                    name="Quarter2020"
                    errorText={fields?.Quarter2020?.touched && syncErrors?.Quarter2020}
                    label="Select the 2020 quarter that you are using for comparison purposes"
                    validate={[required({ message: 'Required' })]}
                  >
                    <option value="" key="" disabled>Select</option>
                    ,
                    <option key="1Q" value="1Q">Q1</option>
                    <option key="2Q" value="2Q">Q2</option>
                    <option key="3Q" value="3Q">Q3</option>
                    <option key="4Q" value="4Q">Q4</option>
                  </SelectionField>
                  <TextField
                    name="QuarterRevenue2020"
                    label="Enter the Gross Receipts for your selected Quarter in 2020"
                    error={syncErrors?.QuarterRevenue2020}
                    touched={fields?.QuarterRevenue2020?.touched}
                    validate={[
                      required({ msg: errors.enterAnAmount }),
                      numericality({ '>=': 0, msg: errors.enterAnAmount }),
                    ]}
                    {...currencyMask}
                  />
                </>
              )}
              {values.QuartersQ2 === 'No' && (
                <RadioField
                  name="QuartersQ2No"
                  label="If you were not in business during 2019, but were in operation on February 15, 2020, did you have gross receipts during the second, third, or fourth quarter of 2020 that demonstrate at least a 25 percent reduction from your gross receipts during the first quarter of 2020?"
                  error={syncErrors?.QuartersQ2No}
                  touched={fields?.QuartersQ2No?.touched}
                  options={[
                    {
                      className: 'form-control',
                      label: 'Yes',
                      name: 'QuartersQ2No',
                      type: 'radio',
                      value: 'Yes',
                    },
                    {
                      className: 'form-control',
                      label: 'No',
                      name: 'QuartersQ2No',
                      type: 'radio',
                      value: 'No',
                    },
                  ]}
                  validate={[
                    required({ msg: errors.requiredField }),
                  ]}
                />
              )}
            </>
            )}
            {values.q1 === 'Annual' && (
              <>
                <TextField
                  name="AnnualRevenue2020"
                  label="Enter your Gross Receipts for 2020"
                  error={syncErrors?.AnnualRevenue2020}
                  touched={fields?.AnnualRevenue2020?.touched}
                  validate={[
                    required({ msg: errors.enterAnAmount }),
                    numericality({ '>=': 0, msg: errors.enterAnAmount }),
                  ]}
                  {...currencyMask}
                />
                <TextField
                  name="AnnualRevenue2019"
                  label="Enter your Gross Receipts for 2019"
                  error={syncErrors?.AnnualRevenue2019}
                  touched={fields?.AnnualRevenue2019?.touched}
                  validate={[
                    required({ msg: errors.enterAnAmount }),
                    numericality({ '>=': 0, msg: errors.enterAnAmount }),
                  ]}
                  {...currencyMask}
                />
              </>
            )}
            <Row align="center" className="margin-top-sm">
              <Col form="auto">
                <Button
                  className="form-button"
                  data-submit-form
                  form="reduction-in-gross-receipts"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </Col>
              <Col form="auto">
                <Button
                  className="form-button"
                  onClick={onCancel}
                  secondary
                  type="cancel"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ForgivenessGrossReceipts.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'reductionInGrossReceipts' })(ForgivenessGrossReceipts);
