import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';

const EntityGroupThree = () => (
  <>
    <BodyText className="payroll-verification__content-list-header">
      To substantiate your payroll costs calculation, please upload:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1040 Schedule C: a) form must have a positive value on line 7 and b)
        must be completed and provided even if it has not yet been filed with the IRS
        (may be applicable if you selected calendar year 2020 for your payroll costs calculation).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        Farmers &amp; ranchers will provide IRS Form 1040 Schedule, Schedule 1 and Schedule F – which must
        be completed and provided even if it has not yet been filed with the IRS (may be applicable if
        you selected calendar year 2020 for your payroll costs calculation).
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To substantiate your payroll costs calculation for your employees, please upload:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 941 Taxable Medicare wages & tips from each quarter or equivalent payroll
        processor records or IRS Wage and Tax Statements, along with documentation of any retirement
        or group health, life, disability, vision and dental contributions.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        In lieu of IRS Form 941, IRS Form W-2s and IRS Form W-3 or payroll processor reports, including
        quarterly and annual tax reports may be provided, as well as annual IRS Form 944 or annual IRS
        Form 943 (or in addition to Form 941).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        State quarterly wage reporting forms to support employer state and local taxes assessed
        on employee compensation.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate your business was in operation on Feb 15, 2020:
    </BodyText>
    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        A payroll statement or similar documentation from the pay period that covered Feb 15, 2020 to
        establish that your busines was in operation on Feb 15, 2020.
      </BodyText>
    </BodyText>
  </>
);

export default EntityGroupThree;
