export const entryLabels = {
  totalPayrollCost: 'Total payroll cost',
  mortgageInterestPayments: 'Business mortgage interest cost',
  rentOrLeasePayments: 'Business rent/lease cost',
  utilityPayments: 'Business utility cost',
  totalSalaryReductions: 'Total salary/hourly wage reduction',
  fteReductionQuotient: 'FTE reduction quotient',
  actualForgivenessAmount: 'Forgiveness amount',
  totalCashCompensationTable1: 'Cash compensation from Table 1 - Box 1',
  totalCashCompensationTable2: 'Cash Compensation from Table 2 - Box 4',
  employeeHealthInsurance: 'Employer contributions for employee health insurance',
  employeeRetirementPlans: 'Employer contributions for employee retirement plans',
  stateAndLocalTaxes: 'State and local taxes assessed on employee compensation',
  ownerCompensationTotal: 'Amount paid to owner-employees / self-employed individuals / general partners',
  coveredOperationsExpenditures: 'Covered operations expenditures',
  coveredPropertyDamageCosts: 'Covered property damage costs',
  coveredSupplierCosts: 'Covered supplier costs',
  coveredWorkerProtectionExpenditures: 'Covered worker protection expenditures',
};

export const form3508S = {
  [entryLabels.actualForgivenessAmount]: {
    approved: 'actualForgivenessAmount',
    sr: 'srActualForgivenessAmount',
  },
};

export const ezEntries = {
  [entryLabels.totalPayrollCost]: {
    approved: 'totalPayrollCost',
    sr: 'srTotalPayrollCost',
  },
  [entryLabels.mortgageInterestPayments]: {
    approved: 'mortgageInterestPayments',
    sr: 'srMortgageInterestPayments',
  },
  [entryLabels.rentOrLeasePayments]: {
    approved: 'rentOrLeasePayments',
    sr: 'srRentOrLeasePayments',
  },
  [entryLabels.utilityPayments]: {
    approved: 'utilityPayments',
    sr: 'srUtilityPayments',
  },
  [entryLabels.coveredOperationsExpenditures]: {
    approved: 'coveredOperationsExpenditures',
    sr: 'srCoveredOperationsExpenditures',
  },
  [entryLabels.coveredPropertyDamageCosts]: {
    approved: 'coveredPropertyDamageCosts',
    sr: 'srCoveredPropertyDamageCosts',
  },
  [entryLabels.coveredSupplierCosts]: {
    approved: 'coveredSupplierCosts',
    sr: 'srCoveredSupplierCosts',
  },
  [entryLabels.coveredWorkerProtectionExpenditures]: {
    approved: 'coveredWorkerProtectionExpenditures',
    sr: 'srCoveredWorkerProtectionExpenditures',
  },
  [entryLabels.actualForgivenessAmount]: {
    approved: 'actualForgivenessAmount',
    sr: 'srActualForgivenessAmount',
  },
};

export const standardEntries = {
  [entryLabels.totalPayrollCost]: {
    approved: 'totalPayrollCost',
    sr: 'srTotalPayrollCost',
  },
  [entryLabels.mortgageInterestPayments]: {
    approved: 'mortgageInterestPayments',
    sr: 'srMortgageInterestPayments',
  },
  [entryLabels.rentOrLeasePayments]: {
    approved: 'rentOrLeasePayments',
    sr: 'srRentOrLeasePayments',
  },
  [entryLabels.utilityPayments]: {
    approved: 'utilityPayments',
    sr: 'srUtilityPayments',
  },
  [entryLabels.coveredOperationsExpenditures]: {
    approved: 'coveredOperationsExpenditures',
    sr: 'srCoveredOperationsExpenditures',
  },
  [entryLabels.coveredPropertyDamageCosts]: {
    approved: 'coveredPropertyDamageCosts',
    sr: 'srCoveredPropertyDamageCosts',
  },
  [entryLabels.coveredSupplierCosts]: {
    approved: 'coveredSupplierCosts',
    sr: 'srCoveredSupplierCosts',
  },
  [entryLabels.coveredWorkerProtectionExpenditures]: {
    approved: 'coveredWorkerProtectionExpenditures',
    sr: 'srCoveredWorkerProtectionExpenditures',
  },
  [entryLabels.totalSalaryReductions]: {
    approved: 'totalSalaryReductions',
    sr: 'srTotalSalaryReductions',
  },
  [entryLabels.fteReductionQuotient]: {
    approved: 'fteReductionQuotient',
    sr: 'srFteReductionQuotient',
  },
  [entryLabels.actualForgivenessAmount]: {
    approved: 'actualForgivenessAmount',
    sr: 'srActualForgivenessAmount',
  },
};

export const expandedEntries = {
  [entryLabels.totalCashCompensationTable1]: {
    approved: 'totalCashCompensationTable1',
    sr: 'srTotalCashCompensationTable1',
  },
  [entryLabels.totalCashCompensationTable2]: {
    approved: 'totalCashCompensationTable2',
    sr: 'srTotalCashCompensationTable2',
  },
  [entryLabels.employeeHealthInsurance]: {
    approved: 'employeeHealthInsurance',
    sr: 'srEmployeeHealthInsurance',
  },
  [entryLabels.employeeRetirementPlans]: {
    approved: 'employeeRetirementPlans',
    sr: 'srEmployeeRetirementPlans',
  },
  [entryLabels.stateAndLocalTaxes]: {
    approved: 'stateAndLocalTaxes',
    sr: 'srStateAndLocalTaxes',
  },
  [entryLabels.ownerCompensationTotal]: {
    approved: 'ownerCompensationTotal',
    sr: 'srOwnerCompensationTotal',
  },
  [entryLabels.totalPayrollCost]: {
    approved: 'totalPayrollCost',
    sr: 'srTotalPayrollCost',
  },
};

export const completeEntries = {
  ...standardEntries,
  ...expandedEntries,
};

export const getEntryValueType = (label, useSrAmount) => {
  if (useSrAmount) {
    return completeEntries[label].sr;
  }
  return completeEntries[label].approved;
};

export const formatFixedDisplayValue = (value) => (
  (value - (Math.round((value + Number.EPSILON) * 10) / 10) === 0)
    ? value.toFixed(1)
    : value.toFixed(2)
);
