import React from 'react';
import { BodyText, Link } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import { lnbUrlSelector } from '../sba3508SForm.selectors';

const RevisitDetails = () => {
  const lnbUrl = useShallowSelector(lnbUrlSelector);
  return (
    <BodyText as="p">
      The SBA published a new Form 3508S Loan Forgiveness Application for loans of $150,000 or less.
      The new form is required to be used beginning March 5th, 2021. While you may have previously
      completed an older version of the forgiveness application form, please review and provide the
      additional information requested below. We will then complete the new form for you and
      provide it to you for review and electronic signature. To learn about the benefits of
      this new form visit our
      {' '}
      <Link
        className="pp-react__link--inline"
        data-automation-id="sbaInstructionsLink"
        href={`${lnbUrl}/ppp-loan-info`}
        target="_blank"
        rel="noopener noreferrer"
      >
        website
      </Link>
      .
    </BodyText>
  );
};

export default RevisitDetails;
