import React, { memo } from 'react';
import { BodyText, TextInput } from '@swift-paypal/pp-react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const TextField = ({
  label,
  error,
  touched,
  ...props
}) => (
  <>
    {label && (
      <BodyText as="label">
        {label}
      </BodyText>
    )}
    <Field
      errorText={touched && error}
      component={MappedInput}
      {...props}
    />
  </>
);

TextField.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  error: PropTypes.string,
  touched: PropTypes.bool,
};

TextField.defaultProps = {
  label: undefined,
  error: undefined,
  touched: false,
};

export default memo(TextField);
