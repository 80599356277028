import { cloneDeep } from 'lodash';
import {
  GET_LOAN_FORGIVENESS_REQUEST_RECEIVED,
  CREATE_LOAN_FORGIVENESS_REQUEST_ERROR,
  CREATE_LOAN_FORGIVENESS_REQUEST_RECEIVED,
  UPDATE_LAST_PAGE_SUBMITTED,
  UPDATE_LOAN_FORGIVENESS_REQUEST_ERROR,
  UPDATE_LOAN_FORGIVENESS_REQUEST_RECEIVED,
  UPDATE_LOAN_FORGIVENESS_REQUEST_CLEAR,
  UPDATE_LOAN_FORGIVENESS_REQUEST_PENDING,
  GET_LOAN_FORGIVENESS_REQUEST_ERROR,
  RESUME_LOAN_FORGIVENESS_REQUEST_ERROR,
} from '../actions/loan-forgiveness/loan-forgiveness-action-types';
import { isEzEligble } from '../../util';

const defaultLoanForgivenessState = {
  allIds: [],
  byId: {},
  error: false,
};

export const loanForgiveness = (state = defaultLoanForgivenessState, action) => {
  let newState;
  const result = action.payload;
  switch (action.type) {
    case GET_LOAN_FORGIVENESS_REQUEST_RECEIVED:
      newState = cloneDeep(state);
      result.forEach((forgivenessObject) => {
        if (!newState.allIds.includes(forgivenessObject.loanForgiveness.id)) {
          newState.allIds.push(forgivenessObject.loanForgiveness.id);
        }

        newState.byId[forgivenessObject.loanForgiveness.id] = {
          ...forgivenessObject.loanForgiveness,
          caseWorker: forgivenessObject.caseWorker,
          loanInformation: forgivenessObject.loanInformation,
        };
      });
      return newState;
    case CREATE_LOAN_FORGIVENESS_REQUEST_RECEIVED:
      if (!result || !result.loanForgiveness || !result.loanForgiveness.id) {
        return {
          ...state,
          error: false,
        };
      }

      newState = cloneDeep(state);
      if (!newState.allIds.find((id) => id === result.loanForgiveness.id)) {
        newState.allIds.push(result.loanForgiveness.id);
      }

      newState.byId[result.loanForgiveness.id] = {
        ...result.loanForgiveness,
        caseWorker: result.caseWorker,
        loanInformation: result.loanInformation,
      };
      return newState;
    case UPDATE_LOAN_FORGIVENESS_REQUEST_RECEIVED: {
      newState = cloneDeep(state);

      const currentLoanForgiveness = newState.byId[result?.id];

      if (currentLoanForgiveness) {
        newState.byId[result?.id] = {
          ...currentLoanForgiveness,
          ...result,
        };
      }
      return newState;
    }
    case RESUME_LOAN_FORGIVENESS_REQUEST_ERROR:
    case CREATE_LOAN_FORGIVENESS_REQUEST_ERROR:
    case GET_LOAN_FORGIVENESS_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const userSetEZAppEligible = (state = null, action) => {
  const result = action.payload;
  switch (action.type) {
    case UPDATE_LOAN_FORGIVENESS_REQUEST_RECEIVED:
      return isEzEligble(result);
    default:
      return state;
  }
};

export const forgivenessForm = (state = {}, action) => {
  let newState = cloneDeep(state);
  switch (action.type) {
    case UPDATE_LAST_PAGE_SUBMITTED:
      newState = cloneDeep(state);
      newState.lastPageSubmitted = action.payload;
      return newState;
    default:
      return state;
  }
};

export const updateLoanForgiveness = (state = null, action) => {
  switch (action.type) {
    case UPDATE_LOAN_FORGIVENESS_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_LOAN_FORGIVENESS_REQUEST_ERROR:
    case UPDATE_LOAN_FORGIVENESS_REQUEST_RECEIVED:
      return {
        ...state,
        error: action.error,
        success: !action.error,
        pending: false,
      };
    case UPDATE_LOAN_FORGIVENESS_REQUEST_CLEAR:
      return null;
    default:
      return state;
  }
};
