import { asyncAuthorizedActionCreator } from '../../util/async-action-creator';
import opportunityService from '../../../../services/opportunity.service';
import bankService from '../../../../services/bank.service';
import { isUserAuthorized } from '../../../../lib/token';
import { addDeauthorized } from '../../auth/auth-action-types';
import * as actions from './verify-bank-accounts-action-types';

export const getBankInformation = asyncAuthorizedActionCreator(
  {
    pending: actions.GET_BANK_INFORMATION_REQUEST_PENDING,
    complete: actions.GET_BANK_INFORMATION_RECEIVED,
    error: actions.GET_BANK_INFORMATION_REQUEST_ERROR,
  },
  (opportunityId) => (dispatch, getState) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    opportunityService.getBankInformation(getState().config.apiUrl, opportunityId),
);

export function getRoutingNumberInstitution(routingNumber, accountType) {
  return async (dispatch, getState) => {
    if (!isUserAuthorized()) {
      return dispatch(addDeauthorized());
    }

    dispatch({ type: actions.GET_ROUTING_INSTITUTION_REQUEST_PENDING, accountType });

    try {
      const state = getState();
      const businessState = state.settings?.contactAccounts?.[0]?.account?.state;
      const institution = await bankService.getRoutingNumberInstitution(state.config.apiUrl, routingNumber);
      const hasRouteNumberWarning = bankService.checkRoutingNumberWarningValidation(institution, businessState);
      return dispatch({
        type: actions.GET_ROUTING_INSTITUTION_RECEIVED,
        institution,
        accountType,
        hasRouteNumberWarning,
      });
    }
    catch (error) {
      if (error.response && error.response.status === 422) {
        return dispatch({ type: actions.GET_ROUTING_INSTITUTION_REQUEST_ERROR, accountType });
      }

      return dispatch({ type: actions.GET_ROUTING_INSTITUTION_REQUEST_ERROR });
    }
  };
}

export const saveBankInformation = asyncAuthorizedActionCreator(
  {
    pending: actions.BANK_ACCOUNT_SAVE_PENDING,
    complete: actions.BANK_ACCOUNT_SAVE_RECEIVED,
    error: actions.BANK_ACCOUNT_SAVE_ERROR,
  },
  (oppId, bankAccounts) => (dispatch, getState) => {
    const { config } = getState();
    return opportunityService.saveBankInformation(config.apiUrl, oppId, bankAccounts);
  },
);

export const addBankInformation = asyncAuthorizedActionCreator(
  {
    pending: actions.BANK_ACCOUNT_ADD_PENDING,
    complete: actions.BANK_ACCOUNT_ADD_RECEIVED,
    error: actions.BANK_ACCOUNT_ADD_ERROR,
  },
  (oppId, bankAccounts) => (dispatch, getState) => {
    const { config } = getState();
    return opportunityService.addBankInformation(config.apiUrl, oppId, bankAccounts);
  },
);

export const resetAddBankError = () => (dispatch) => dispatch({ type: actions.BANK_ACCOUNT_ADD_RESET_ERROR });

export function getRoutingNumberList() {
  return async (dispatch, getState) => {
    if (!isUserAuthorized()) {
      return dispatch(addDeauthorized());
    }
    try {
      const state = getState();
      const institutionList = await bankService.getRoutingNumberInstitutionList(state.config.apiUrl);
      return dispatch({
        type: actions.GET_ROUTING_INSTITUTION_LIST_RECEIVED,
        institutionList,
      });
    }
    catch (error) {
      return dispatch({ type: actions.GET_ROUTING_INSTITUTION_LIST_REQUEST_ERROR });
    }
  };
}
