import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useShallowSelector } from '../../../hooks';
import CustomerSignoffMessaging from './customer-signoff-messaging/customer-signoff-messaging';
import { ForgivenessStageMessaging } from './forgiveness-stage-messaging';
import { CollateralPackageMessaging } from './collateral-package-messaging';
import shouldHideForgivenessTile from './forgiveness-summary-tile-utils';
import ForgivenessStandardMessaging from './forgiveness-standard-messaging/forgiveness-standard-messaging';
import {
  CUSTOMER_SIGNOFF,
  forgivenessMessagingStages,
  ONE_HUNDRED_FIFTY_THOUSAND,
  ppp21LoanVersions,
  COLLATERAL_STATES,
} from './forgiveness-summary-tile-constants';
import { forgivenessSummaryTileSelector } from './forgiveness-summary-tile-selectors';
import { Start2021Forgiveness3508S } from './start2021Forgiveness3508S';
import { getGuarantyClaimAction } from '../../../redux/actions/guaranty-claims/guaranty-claims-action';
import './forgiveness-summary-tile.less';

const ForgivenessSummaryTile = ({ advanceId, getGuarantyClaim, isGuarantyClaimAvailable }) => {
  const { advancesById, forgivenessById } = useShallowSelector(forgivenessSummaryTileSelector);
  const [isFirstTimeApplying, setIsFirstTimeApplying] = useState(false);

  const {
    amount,
    estimatedMaturityDate,
    loanForgivenessId,
    loanVersion,
    collateralPackageStatus,
  } = advancesById[advanceId] || {};
  const {
    applicationVersionSelected,
    customerSignoffTimestamp: signoffCompleted,
    readyForCustomerSignoff,
    stage,
  } = forgivenessById[loanForgivenessId] || {};

  const hideForgivenessSummaryTile = shouldHideForgivenessTile(
    isGuarantyClaimAvailable, stage, estimatedMaturityDate, loanForgivenessId,
  );
  const customerSignoff = stage === CUSTOMER_SIGNOFF && readyForCustomerSignoff;
  const showCustomerSignoffMessaging = !isFirstTimeApplying && (customerSignoff || signoffCompleted);
  const showForgivenessStageMessaging = forgivenessMessagingStages.includes(stage)
    && !isFirstTimeApplying && applicationVersionSelected;

  useEffect(() => {
    const fetchData = async () => {
      if (advanceId) {
        await getGuarantyClaim(advanceId);
      }
    };
    fetchData();
  }, []);

  if (hideForgivenessSummaryTile) {
    return null;
  }

  if (COLLATERAL_STATES.includes(collateralPackageStatus)) {
    return <CollateralPackageMessaging />;
  }

  if (showForgivenessStageMessaging) {
    return (
      <ForgivenessStageMessaging
        advanceId={advanceId}
        forgivenessId={loanForgivenessId}
      />
    );
  }

  if (showCustomerSignoffMessaging) {
    return (
      <CustomerSignoffMessaging
        advanceId={advanceId}
        forgivenessId={loanForgivenessId}
        signoffCompleted={signoffCompleted}
      />
    );
  }

  if (amount < ONE_HUNDRED_FIFTY_THOUSAND && ppp21LoanVersions.includes(loanVersion)) {
    return <Start2021Forgiveness3508S advanceId={advanceId} />;
  }

  return (
    <ForgivenessStandardMessaging
      advanceId={advanceId}
      setIsFirstTimeApplying={setIsFirstTimeApplying}
      isFirstTimeApplying={isFirstTimeApplying}
    />
  );
};

ForgivenessSummaryTile.propTypes = {
  advanceId: PropTypes.string.isRequired,
  forgivenessObject: PropTypes.shape({
    customerSignoffTimestamp: PropTypes.string,
    id: PropTypes.string,
    stage: PropTypes.string,
    readyForCustomerSignoff: PropTypes.bool,
    applicationVersionSelected: PropTypes.string,
  }),
  isGuarantyClaimAvailable: PropTypes.bool,
  getGuarantyClaim: PropTypes.func.isRequired,
};

ForgivenessSummaryTile.defaultProps = {
  forgivenessObject: undefined,
  isGuarantyClaimAvailable: false,
};

const mapStateToProps = (state) => ({
  isGuarantyClaimAvailable: state.isGuarantyClaimAvailable,
});

const mapDispatchToProps = {
  getGuarantyClaim: getGuarantyClaimAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ForgivenessSummaryTile);
