import EmployeeHealthInsurance from '../../fields/employee-health-insurance';
import EmployeeRetirementPlans from '../../fields/employee-retirement-plans';
import StateAndLocalTaxes from '../../fields/state-and-local-taxes';

export const nonCashPayrollCostsFields = [
  [
    EmployeeHealthInsurance,
    'employeeHealthInsurance',
  ],
  [
    EmployeeRetirementPlans,
    'employeeRetirementPlans',
  ],
  [
    StateAndLocalTaxes,
    'stateAndLocalTaxes',
  ],
];
