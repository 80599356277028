import 'core-js';
import 'core-js/stage/3';
import 'regenerator-runtime/runtime';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import ReactHeap from 'reactjs-heap';
import spinner from './lib/spinner-utils';
import maintenance from './lib/maintenance-utils';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GA_TRACKING_ID } from './lib/google-analytics';

ReactGA.initialize(GA_TRACKING_ID);

loadConfigAndStart();

async function loadConfigAndStart() {
  const response = await axios.get(`${process.env.PUBLIC_URL}/config.json`);
  const config = response.data;

  spinner.initialize(config.renewalsEnabled); // adds axios interceptors for spinner
  maintenance.initialize(config.renewalsEnabled); // adds axios interceptors for redirect to maintenance
  ReactHeap.initialize(config.heapAppId);

  ReactDOM.render(<App config={config} />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
