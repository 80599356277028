import { change } from 'redux-form';
import { asyncAuthorizedActionCreator, asyncNoAuthActionCreator } from '../../util/async-action-creator';
import * as actions from './ownership-action-types';
import ownershipService from '../../../../services/ownership.service';
import legalService from '../../../../services/legal.service';
import { getContactNotificationsAction } from '../../notifications/notifications-actions';

export const getAcceptanceAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ACCEPTANCE_REQUEST_PENDING,
    complete: actions.ACCEPTANCE_REQUEST_RECEIVED,
    error: actions.ACCEPTANCE_REQUEST_ERROR,
  },
  () => (dispatch, getState) => legalService.getAcceptance(getState().config.apiUrl),
);

export const getAttestationAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ATTESTATION_REQUEST_PENDING,
    complete: actions.ATTESTATION_REQUEST_RECEIVED,
    error: actions.ATTESTATION_REQUEST_ERROR,
  },
  () => (dispatch, getState) => legalService.getAttestation(getState().config.apiUrl),
);

export const getTermsAndConditionsAction = asyncNoAuthActionCreator(
  {
    pending: actions.TERMS_AND_CONDITIONS_REQUEST_PENDING,
    complete: actions.TERMS_AND_CONDITIONS_REQUEST_RECEIVED,
    error: actions.TERMS_AND_CONDITIONS_REQUEST_ERROR,
  },
  (country) => (dispatch, getState) => legalService.getTermsAndConditions(getState().config.apiUrl, country),
);

export const getContactsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.OWNERS_CONTACT_REQUEST_PENDING,
    complete: actions.OWNERS_CONTACT_REQUEST_RECEIVED,
    error: actions.OWNERS_CONTACT_REQUEST_ERROR,
  },
  () => (dispatch, getState) => ownershipService.getContacts(getState().config.apiUrl),
);

export const getOwnersAction = asyncAuthorizedActionCreator(
  {
    pending: actions.OWNERS_REQUEST_PENDING,
    complete: actions.OWNERS_REQUEST_RECEIVED,
    error: actions.OWNERS_REQUEST_ERROR,
  },
  (opportunityId) => (
    dispatch,
    getState,
  ) => ownershipService.getExistingOwners(getState().config.apiUrl, opportunityId),
);

export const saveAcceptanceAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SAVE_ACCEPTANCE_REQUEST_PENDING,
    complete: actions.SAVE_ACCEPTANCE_REQUEST_RECEIVED,
    error: actions.SAVE_ACCEPTANCE_REQUEST_ERROR,
  },
  (documentId, opportunityId, ignoreRequestCount) => (dispatch, getState) => {
    const { config } = getState();
    return legalService.saveAcceptance(config.apiUrl, documentId, opportunityId, ignoreRequestCount);
  },
);

export const saveAttestationAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SAVE_ATTESTATION_REQUEST_PENDING,
    complete: actions.SAVE_ATTESTATION_REQUEST_RECEIVED,
    error: actions.SAVE_ATTESTATION_REQUEST_ERROR,
  },
  (attestationId, opportunityId, ignoreRequestCount) => (dispatch, getState) => {
    const { config } = getState();
    return legalService.saveAttestation(config.apiUrl, attestationId, opportunityId, ignoreRequestCount);
  },
);

export const saveOwnershipAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SAVE_OWNERSHIP_REQUEST_PENDING,
    complete: actions.SAVE_OWNERSHIP_REQUEST_RECEIVED,
    error: actions.SAVE_OWNERSHIP_REQUEST_ERROR,
  },
  (contacts, accountId, opportunityId, country) => (dispatch, getState) => {
    const { config } = getState();
    return ownershipService.saveOwnership(config.apiUrl, contacts, accountId, opportunityId, country);
  },
);

export const getPossibleSignersAction = asyncAuthorizedActionCreator(
  {
    pending: actions.GET_POSSIBLE_SIGNERS_REQUEST_PENDING,
    complete: actions.GET_POSSIBLE_SIGNERS_RECEIVED,
    error: actions.GET_POSSIBLE_SIGNERS_REQUEST_ERROR,
  },
  (opportunityId) => (
    dispatch,
    getState,
  ) => ownershipService.getExistingOwners(getState().config.apiUrl, opportunityId),
);

export const saveAdditionalSignersAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SAVE_ADDITIONAL_SIGNERS_REQUEST_PENDING,
    complete: actions.SAVE_ADDITIONAL_SIGNERS_RECEIVED,
    error: actions.SAVE_ADDITIONAL_SIGNERS_REQUEST_ERROR,
  },
  (contacts, accountId, opportunityId, country) => (dispatch, getState) => {
    const { config } = getState();
    return ownershipService.saveOwnership(config.apiUrl, contacts, accountId, opportunityId, country);
  },
);

export function saveBusinessRepresentative({
  opportunityId,
  accountId,
  contacts,
  country,
  documentId,
  attestationId,
  saveAcceptance = false,
  saveAttestation = false,
  location,
}) {
  return async (dispatch, getState) => {
    if (saveAcceptance) {
      await dispatch(saveAcceptanceAction(documentId, opportunityId, true));
      if (getState().ownership.error) {
        return dispatch({ type: actions.ERROR_WHILE_PROCESSING_OWNERSHIP });
      }
    }
    if (saveAttestation) {
      await dispatch(saveAttestationAction(attestationId, opportunityId, true));
      if (getState().ownership.error) {
        return dispatch({ type: actions.ERROR_WHILE_PROCESSING_OWNERSHIP });
      }
    }
    await dispatch(saveOwnershipAction(contacts, accountId, opportunityId, country, true));
    if (getState().ownership.error) {
      return dispatch({ type: actions.ERROR_WHILE_PROCESSING_OWNERSHIP });
    }
    // refresh action items when success
    await dispatch(getContactNotificationsAction(location));
    return dispatch({ type: actions.OWNERSHIP_SAVED_SUCCESSFULLY });
  };
}

export const clearSelectedOpportunityAction = () => (dispatch) => dispatch({ type: actions.OWNERS_REQUEST_CLEAR });

export const setControllingManagerAction = (controllingManager) => async (dispatch) => {
  dispatch(change('ownership-action-item-form', 'controllingManager', controllingManager));
};

export const clearAdditionalSignersFulfilledAction = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_ADDITIONAL_SIGNERS_FULFILLED });
};
