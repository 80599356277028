export const CONTRACT_UPLOAD_REQUEST_PENDING = 'contract_upload_request_pending';
export const CONTRACT_UPLOAD_REQUEST_RECEIVED = 'contract_upload_request_received';
export const CONTRACT_UPLOAD_REQUEST_ERROR = 'contract_upload_request_error';

export const DISPLAY_CONTRACT_REQUEST_PENDING = 'display_contract_request_pending';
export const DISPLAY_CONTRACT_REQUEST_RECEIVED = 'display_contract_request_received';
export const DISPLAY_CONTRACT_REQUEST_ERROR = 'display_contract_request_error';

export const REVIEW_CONTRACT_REQUEST_PENDING = 'review_contract_request_pending';
export const REVIEW_CONTRACT_REQUEST_RECEIVED = 'review_contract_request_received';
export const REVIEW_CONTRACT_REQUEST_ERROR = 'review_contract_request_error';
