import {
  BodyText,
  Button,
} from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import React from 'react';
import './forYouCard.less';

export const ForYouCard = ({
  applicationLabel,
  callToActionFunction,
  callToActionText,
  cardTitle,
  description,
}) => (
  <div className="for-you-card">
    <div>
      <BodyText>
        {applicationLabel}
      </BodyText>
      <BodyText strong>
        {cardTitle}
      </BodyText>
      <BodyText className="margin-top-xs">
        {description}
      </BodyText>
    </div>
    <div className="call-to-action">
      <Button size="sm" onClick={callToActionFunction}>
        {callToActionText}
      </Button>
    </div>
  </div>
);

ForYouCard.propTypes = {
  applicationLabel: PropTypes.string.isRequired,
  callToActionFunction: PropTypes.func.isRequired,
  callToActionText: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ForYouCard;
