import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  getContacts: asyncDebounce(getContacts),
  getExistingOwners: asyncDebounce(getExistingOwners),
  isApplicant,
  saveOwnership,
  getUniqueOwnerKey,
  doOwnersMatch,
};

async function getContacts(apiUrl) {
  const res = await axios.get(`${apiUrl}/contacts`, defaultRequestOptions());
  return res.data;
}

async function getExistingOwners(apiUrl, opportunityId) {
  const res = await axios.get(`${apiUrl}/ownership/${opportunityId}`, defaultRequestOptions());
  return res.data;
}

async function saveOwnership(apiUrl, contacts, accountId, opportunityId, country) {
  setSigners(contacts);
  contacts = removeApplicantAndNonStakeholders(contacts);

  if (!contacts || contacts.length === 0) {
    return {};
  }
  const requestData = {
    contacts: mapContacts(contacts, accountId, country),
    opportunityId,
  };
  const res = await axios.post(`${apiUrl}/ownership`, requestData, defaultRequestOptions());
  return res.data;
}

function doOwnersMatch(o1, o2) {
  const fields = ['email', 'name.first', 'name.last'];
  return _.isEqual(getUniqueOwnerKey(_.pick(o1, fields)), getUniqueOwnerKey(_.pick(o2, fields)));
}

function getUniqueOwnerKey(owner) {
  return {
    email: cleanValue(owner.email),
    name: {
      first: cleanValue(owner.name.first),
      last: cleanValue(owner.name.last),
    },
  };
}

function isApplicant(currentOwners, owner) {
  const email = cleanValue(owner.email);
  const firstName = cleanValue(owner.name.first);
  const lastName = cleanValue(owner.name.last);

  return currentOwners.find((o) => o.name.first === firstName
    && o.name.last === lastName
    && o.email === email
    && o.isApplicant);
}

function cleanValue(value) {
  return value ? value.trim().toLowerCase() : null;
}

function setSigners(currentOwners) {
  _.filter(currentOwners, (owner) => owner.isSigner).forEach((owner, index) => {
    owner.contactRole = `Signer ${index + 1}`;
  });
}

function removeApplicantAndNonStakeholders(currentOwners) {
  return _.filter(
    currentOwners,
    (owner) => !owner.isApplicant && (owner.isSigner || owner.isBeneficialOwner || owner.isControllingManager),
  );
}

function mapContacts(contacts, accountId, country) {
  return contacts.map((c) => {
    const contact = {
      accountId,
      firstName: c.name.first,
      lastName: c.name.last,
      title: c.jobTitle?.job,
      street: c.postalAddress.street,
      city: c.postalAddress.city,
      state: c.postalAddress.state || c.postalAddress.province,
      zip: c.postalAddress.zip || c.postalAddress.postal,
      country: country.toUpperCase(),
      ssn: (c.ssn || c.sin),
      phone: c.phone,
      email: c.email,
      isControllingManager: c.isControllingManager,
      isBeneficialOwner: c.isBeneficialOwner,
      isSigner: c.isSigner,
      contactRole: c.contactRole,
      isApplicant: c.isApplicant,
    };

    if (c.id) {
      contact.id = c.id;
    }

    if (c.percentOwned) {
      if (!_.isNumber(c.percentOwned)) {
        contact.percentOwned = _.toNumber(c.percentOwned);
      }
      else {
        contact.percentOwned = c.percentOwned;
      }
    }

    if (moment(c.dateOfBirth, 'MM/DD/YYYY', true).isValid()) {
      contact.dateOfBirth = moment(c.dateOfBirth, 'MM/DD/YYYY', true).format('YYYY-MM-DD');
    }
    else if (moment(c.dateOfBirth, 'YYYY/MM/DD', true).isValid()) {
      contact.dateOfBirth = moment(c.dateOfBirth, 'YYYY/MM/DD', true).format('YYYY-MM-DD');
    }

    return contact;
  });
}
