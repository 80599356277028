import { BodyText, Link } from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useShallowSelector } from '../../../hooks';
import { CollateralPackageMessaging } from '../../summary/forgiveness-summary-tile/collateral-package-messaging';
import { offbookTitleGenerator } from './offbookAdvanceTile.utils';
import {
  OFFBOOK_ADVANCE_SUBTEXT_PART_1,
  OFFBOOK_ADVANCE_SUBTEXT_PART_2,
  OFFBOOK_ADVANCE_SUBTEXT_PART_3,
  OFFBOOK_ADVANCE_SUBTEXT_EMAIL,
  OFFBOOK_ADVANCE_SUBTEXT_PHONE,
} from './offbookAdvanceTile.constants';
import { offbookAdvanceTileSelector } from './offbookAdvanceTileList.selectors';

export const OffbookAdvanceTile = ({ advance }) => {
  const { offbookMessagingEnabled } = useShallowSelector(offbookAdvanceTileSelector);

  if (!offbookMessagingEnabled) {
    return <CollateralPackageMessaging />;
  }

  const offbookTitle = offbookTitleGenerator(advance);

  return (
    <div className="offbook-advance-tile">
      <BodyText strong>
        {offbookTitle}
      </BodyText>
      <BodyText as="p">
        {OFFBOOK_ADVANCE_SUBTEXT_PART_1}
        <Link href="mailto:PPServicing@Windsoradvantage.com">
          {OFFBOOK_ADVANCE_SUBTEXT_EMAIL}
        </Link>
        {OFFBOOK_ADVANCE_SUBTEXT_PART_2}
        <Link href="tel:13125857666">
          {OFFBOOK_ADVANCE_SUBTEXT_PHONE}
        </Link>
        {OFFBOOK_ADVANCE_SUBTEXT_PART_3}
      </BodyText>
    </div>
  );
};

OffbookAdvanceTile.propTypes = {
  advance: PropTypes.shape({
    loanVersion: PropTypes.string,
  }).isRequired,
};

export default OffbookAdvanceTile;
