import { IS_RENEWAL_ELIGIBLE_REQUEST_RECEIVED, IS_RENEWAL_ELIGIBLE_REQUEST_ERROR } from '../actions/account/account-action-types';

const isRenewalEligible = (state = null, action) => {
  const renewalEligible = action.payload;

  switch (action.type) {
    case IS_RENEWAL_ELIGIBLE_REQUEST_RECEIVED:
      return renewalEligible;
    case IS_RENEWAL_ELIGIBLE_REQUEST_ERROR:
      return null;
    default:
      return state;
  }
};

export default isRenewalEligible;
