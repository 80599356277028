import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';

const EntityGroupSix = () => (
  <>
    <BodyText className="payroll-verification__content-list-header">
      If your business files taxes using IRS Form 1040 Schedule C, please upload the following documents:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1040 Schedule C:  a) form must have a positive value on line 7 if you are a single member LLC,
        and a positive value on line 31 if you are an LLC with more than one member and b) must be
        completed and provided even if it has not yet been filed with the IRS (may be applicable
        if you selected calendar year 2020 for your payroll costs calculation).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        Farmers &amp; ranchers will provide IRS Form 1040 Schedule, Schedule 1 and Schedule
        F – which must be completed and provided even if it has not yet been filed with
        the IRS (may be applicable if you selected calendar year 2020 for your payroll costs calculation).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1099 MISC (if applicable) detailing nonemployee compensation received (box 7).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1099-K (if applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        If neither IRS Form 1099-MISC or IRS Form 1099-K are applicable, provide an invoice,
        bank statement or other book of record establishing you were self-employed during the
        selected payroll costs calculation timeframe.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      If your business files taxes as a corporation, please upload the following documents:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 941 Taxable Medicare wages &amp; tips from each quarter or equivalent payroll processor
        records or IRS Wage and Tax Statements.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        In lieu of IRS Form 941, IRS Form W-2s and IRS Form W-3 or payroll processor reports,
        including quarterly and annual tax reports may be provided, as well as annual IRS Form
        944 or annual IRS Form 943 (when applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1120 or IRS Form 1120-S or other documentation of any group health, life, disability,
        vision, and dental insurance contributions and retirement contributions.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        State quarterly wage reporting forms to support employer state and local taxes
        assessed on employee compensation.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      If your business files taxes as a partnership, please upload the following documents:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1065 including Schedule K-1.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 941 Taxable Medicare wages &amp; tips from each quarter or equivalent payroll processor
        records or IRS Wage and Tax Statements, along with documentation of any retirement or group
        health, life, disability, vision and dental contributions.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        In lieu of IRS Form 941, IRS Form W-2s and IRS Form W-3 or payroll processor reports,
        including quarterly and annual tax reports may be provided, as well as annual IRS Form 944
        or annual IRS Form 943 (when applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        State quarterly wage reporting forms to support employer state and local taxes
        assessed on employee compensation.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate your organization was in operation on Feb 15, 2020:
    </BodyText>
    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        If you have had no employees, a 2020 invoice, bank statement, or book of record that
        establishes that you were self-employed and in operation on or around Feb 15, 2020.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        If you had employees, a payroll statement or similar documentation from the pay period that
        covered Feb 15, 2020 to establish that you were in operation and had employees on Feb 15, 2020.
      </BodyText>
    </BodyText>
  </>
);

export default EntityGroupSix;
