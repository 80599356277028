import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './logo.less';
import { payPalLogoBlue, payPalLogoWhite } from '../../static/images/logos';
import resolve from '../../services/route.service';
import routes from '../../routes';

const Logo = ({
  authorized, country, mobile,
}) => (
  <div className="logo-container">
    <Link
      data-automation-id="paypalImageLink"
      to={authorized ? resolve(routes.summary.path, { country }) : '/'}
      className={`vx_globalNav-brand_${mobile ? 'mobile' : 'desktop'}
        ${authorized ? '' : 'unauthorized'} logo-focus-highlight`}
    >
      <img alt="Paypal logo" className="scalable-image" src={authorized ? payPalLogoWhite : payPalLogoBlue} />
      <span className="vx_a11yText">More About PayPal</span>
    </Link>
  </div>
);

Logo.propTypes = {
  authorized: PropTypes.bool,
  country: PropTypes.string,
  mobile: PropTypes.bool,
};

Logo.defaultProps = {
  authorized: false,
  country: undefined,
  mobile: undefined,
};

export default Logo;
