export const allowedFileTypes = {
  csv: ['csv'],
  docx: ['docx'],
  images: ['jpg', 'jpeg', 'png', 'tiff', 'tif'],
  pdf: ['pdf'],
  xlsx: ['xlsx'],
};

export const mimeTypes = {
  csv: 'text/csv',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  images: {
    jpg: 'image/jpeg',
    png: 'image/png',
    tiff: 'image/tiff',
    distortedJpg: 'image/jpg',
  },
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

// browser adds some weight to the file size.
export const allowedSingleFileSize = 24505856;

export const allowedTotalFileCount = 15;

export const minimumRequiredFileSize = 1;

export const fileTypesToFilter = [
  'AccountsPayable',
  'AccountsReceivable',
  'AdditionalOwnershipForm',
  'Application',
  'BankStatementsConsolidated',
  'BankStatementsMTD',
  'BusinessCreditReport',
  'BusinessLicense',
  'Cash_Flow',
  'Cash Flow',
  'CBR',
  'CompetitorAgreement',
  'Consolidated',
  'EarlyPayAddendum',
  'FloorplanStatement',
  'IncomeStatement',
  'Lease',
  'LockboxAccountForm',
  'LockboxBankLetter',
  'MerchantProcessingStatements',
  'PersonalGuarantee',
  'PublicRecordsSearch',
  'SettlementAgreement',
  'SiteInspection',
  'SplitAuthorizationForm',
  'StatementSummary',
  'TaxReturn',
  'Terms_and_Conditions',
  'Terms and Conditions',
  'UCC1',
  'UCC3',
  'VendorReference',
];

export const docTypeMappings = {
  '# Months of Business Bank Account Statements': 'Business Bank Account Statements',
  'Tax Information Authorization Form': 'IRS Tax Form 8821 Authorization Form',
};

// suggested constant for stip-specific validations, should they differ from default ones
export const stipulationSpecificValidations = {
  BankStatements: {
    allowedFileTypes: {
      pdf: allowedFileTypes.pdf,
    },
    mimeTypes: {
      pdf: mimeTypes.pdf,
    },
    fileTypeIsNotAllowedList: 'pdf',
  },
};

export const stipulationGroupsDescriptions = {
  'Bank Statement Upload': 'Please upload the following statements for your business primary checking account. '
    + 'Be sure to include all pages, and use PDF format.',
  'Identity and business verification': 'We were unable to verify some information that you have submitted. That\'s okay! '
      + 'But we will need you to help us finish the review of your loan application by completing the following.',
  Default: 'Please upload the following documents. To assist with the review of your '
             + 'information, please make sure your documents are as up to date and accurate as possible.',
};
