import React from 'react';
import PropTypes from 'prop-types';

const TotalBalanceSvg = ({ percentValue }) => {
  const calculatedValue = percentValue ? (percentValue * 288) / 100 : 0;

  return (
    <>
      <style jsx="true">
        {`
            #filled-semi-circle {
              stroke-dasharray: ${calculatedValue} 288;
              animation: dash 0.5s linear forwards;
            }
            @keyframes dash {
              from {
                stroke-dashoffset: ${calculatedValue};
              }
              to {
                stroke-dashoffset: 0;
              }
            }
          `}
      </style>
      <svg height="97px" version="1.1" viewBox="0 0 189 97" width="189px">
        <g strokeWidth="1" fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeLinecap="round">
          <g strokeWidth="5" id="Activity-/-Loan-Progress" transform="translate(-175,-29)">
            <g id="Group-2" transform="translate(133,31)">
              <g id="Group-3" transform="translate(45)">
                <g id="Group-4">
                  <path
                    id="unfilled-semi-circle"
                    d="M 137.2 -45 C 86.8 -45 46 -4 46 46.5 C 46 97 86.8 138 137.2 138"
                    stroke="#f5f7fa"
                    transform="translate(91.6,46.5) rotate(-270) translate(-91.6,-46.5)"
                  />
                  <path
                    id="filled-semi-circle"
                    d="M 137.2 -45 C 86.8 -45 46 -4 46 46.5 C 46 97 86.8 138 137.2 138"
                    stroke="#029cde"
                    transform="translate(91.6,46.5) scale(-1,1) rotate(-270) translate(-91.6,-46.5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

TotalBalanceSvg.propTypes = {
  percentValue: PropTypes.number.isRequired,
};

export default TotalBalanceSvg;
