import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '@swift-paypal/pp-react';
import PageTitle from '../common/page-title';
import LoginFormContainer from './login-form/login-form-container';
import PaypalLogin from './paypal-login/paypal-login';
import OrDivider from './or-divider/or-divider';
import './login.less';

class Login extends React.Component {
  submitFunc = undefined;

  static propTypes = {
    country: PropTypes.string,
    message: PropTypes.string,
    submitFunc: PropTypes.func.isRequired,
    type: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    message: undefined,
    type: undefined,
  };

  constructor(props) {
    super(props);
    this.submitFunc = props.submitFunc.bind(this);
  }

  render() {
    const {
      message, type, submitFunc, country,
    } = this.props;

    return (
      <div className="login">
        <Container>
          <PageTitle text="Login" />
          <br />
          <Row>
            <Col xs={12} lg={6}>
              <LoginFormContainer submitFunc={submitFunc} message={message} type={type} />
            </Col>
            <Col xs={12} lg={1}>
              <OrDivider />
            </Col>
            <Col xs={12} lg={5} className="paypal-login">
              <PaypalLogin country={country} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
