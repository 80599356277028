export const LOAN_YEAR_PLACEHOLDER = '<LOAN_YEAR>';

export const FORGIVENESS_MESSAGE_PART_1 = `If you've already used all of your ${LOAN_YEAR_PLACEHOLDER} PPP loan funds for which you plan to request loan forgiveness, you can now apply`;

export const FORGIVENESS_MESSAGE_PART_2 = `We are accepting loan forgiveness applications for ${LOAN_YEAR_PLACEHOLDER} PPP loans.
    If you haven’t used all of your loan funds, please consider doing so before you apply, as it may help maximize your loan forgiveness amount.
    Your loan forgiveness request can include eligible expenses over a period of up to 24 weeks from loan funding.`;

export const FORGIVENESS_MESSAGE_PART_3 = `At least 60% of loan proceeds must be used for payroll costs.
    Using the remainder for other forgiveness eligible expenses during your covered period will help maximize loan forgiveness.`;

export const LEARN_MORE = 'Learn more';

export const FORGIVENESS_MESSAGE_PART_4 = `You will need to provide necessary documents
    required by SBA rules to support your loan forgiveness application and requested loan forgiveness amount.`;

export const FORGIVENESS_MESSAGE_PART_5 = 'If you are ready to request loan forgiveness, click on Apply below.';

export const APPLY = 'Apply';

export const LEARN_MORE_URL_LINK = 'https://www.sba.gov/funding-programs/loans/covid-19-relief-options/paycheck-protection-program/ppp-loan-forgiveness';

export const REDESIGN_FORGIVENESS_MESSAGE = `If you've already used all of your ${LOAN_YEAR_PLACEHOLDER} PPP loan funds for which you plan to request loan forgiveness, you can now apply`;

export const REDESIGN_FORGIVENESS_SUBTEXT = `We are accepting loan forgiveness applications for ${LOAN_YEAR_PLACEHOLDER} PPP loans`;

export const GET_STARTED = 'Get Started';

export const REDESIGN_HEADER_TEXT = 'PPP Loan 1 - Forgiveness';
