import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { BodyText, Button, Container } from '@swift-paypal/pp-react';
import { compose } from 'redux';
import { Alert, AlertTypes } from '../common/alert';
import renderTextarea from '../../lib/forms/render-textarea';
import renderSelect from '../../lib/forms/select-field';
import resolve from '../../services/route.service';
import routes from '../../routes';
import PppLoanInfoLink from './ppp-loan-info-link';
import './contact-us-form.less';
import errors from '../../constants/errors';

const successMessage = 'Your inquiry has been received. Due to the high volume of inquiries, '
  + 'responses may be delayed. We appreciate your patience.';
const accountsPlaceholder = 'Please select a business...';
const caseTypesPlaceholder = 'How can we help you?';
const messagePlaceholder = 'Provide a detailed message about your issue.';

class ContactUsForm extends React.Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    caseTypes: PropTypes.arrayOf(PropTypes.object),
    contactUsError: PropTypes.bool,
    contactUsSuccess: PropTypes.bool,
    history: PropTypes.array.isRequired,
    country: PropTypes.string,
    hasSbaLoans: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      accountId: PropTypes.string,
    }),
  };

  static defaultProps = {
    accounts: [],
    caseTypes: [],
    contactUsError: undefined,
    contactUsSuccess: false,
    country: undefined,
    hasSbaLoans: false,
    initialValues: {
      accountId: undefined,
    },
  };

  accountOptions = (accounts) => [
    <option value="" key="" disabled>
      {accountsPlaceholder}
    </option>,
    accounts
      ? accounts.map((account) => (
        <option key={account.accountId} value={account.accountId}>
          {account.accountName}
        </option>
      ))
      : undefined,
  ];

  caseTypeOptions = (caseTypes) => [
    <option value="" key="" disabled>
      {caseTypesPlaceholder}
    </option>,
    caseTypes
      ? caseTypes.map((caseType) => (
        <option key={caseType.value} value={caseType.value}>
          {caseType.displayName}
        </option>
      ))
      : undefined,
  ];

  render() {
    const {
      caseTypes,
      hasSbaLoans,
      handleSubmit,
      contactUsError,
      contactUsSuccess,
      country,
      accounts,
      initialValues,
      history,
    } = this.props;

    const details = hasSbaLoans ? sbaPppDetails : standardDetails;

    return (
      <div className="form contact-us">
        <Container fluid>
          <form onSubmit={handleSubmit}>
            {Object.entries(details).map(([key, value]) => (
              <BodyText className="contact-us__detail-item" key={key}>
                {value}
              </BodyText>
            ))}
            {contactUsError && (
              <Alert message={errors.technicalIssue} alertType={AlertTypes.critical} />
            )}
            {!contactUsError && contactUsSuccess && (
              <Alert message={successMessage} alertType={AlertTypes.success} />
            )}
            <div className="contact-us-form-group">
              {accounts && accounts.length > 1 && (
                <Field
                  component={renderSelect}
                  label={accountsPlaceholder}
                  name="accountId"
                  disabled={initialValues && initialValues.accountId}
                  className="vx_form-control"
                  noErrorClass="margin-bottom-xs"
                  validate={[required({ message: 'Required' })]}
                >
                  {this.accountOptions(accounts)}
                </Field>
              )}
              <Field
                component={renderSelect}
                label={caseTypesPlaceholder}
                name="caseType"
                className="vx_form-control no-top-margin"
                noErrorClass="margin-bottom-xs"
                validate={[required({ message: 'Required' })]}
              >
                {this.caseTypeOptions(caseTypes)}
              </Field>
              <Field
                label={messagePlaceholder}
                component={renderTextarea}
                className="vx_form-control text-area"
                noErrorClass="no-error"
                name="message"
                validate={[required({ message: 'Required' })]}
              />
            </div>
            <div className="buttons">
              <Button type="submit" className="pp-react__button--block continue-button">
                Continue
              </Button>
              <Button
                secondary
                type="button"
                className="pp-react__button--block cancel-button"
                onClick={() => {
                  history.push(resolve(routes.summary.path, { country }));
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Container>
      </div>
    );
  }
}

const standardDetails = {
  0: 'Use the form below to get in touch with us.',
};

const sbaPppDetails = {
  0: (
    <>
      If you are a Second Draw loan applicant and are looking for your SBA Loan Number for your
      initial PPP loan obtained through PayPal, select Activity in the navigational menu above. For
      additional details and instructions on PPP Loan forgiveness, see our
      {' '}
      <PppLoanInfoLink />
      .
    </>
  ),
  1: `
    If you did not obtain your initial PPP loan through PayPal, please contact the lender for your
    loan to obtain the SBA loan number.
  `,
  2: 'Otherwise, use the form below to get in touch with us.',
};

export default compose(
  reduxForm({
    form: 'contact_us',
    enableReinitialize: true,
  }),
  withRouter,
)(ContactUsForm);
