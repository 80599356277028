import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import redirect from '../lib/redirect';

export const useRedirect = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const redirectCallback = useCallback((path = undefined, params = undefined) => {
    redirect(history, match, path, params);
  }, []);

  return redirectCallback;
};
