import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import SecurePage from '../../hocs/secure-page';
import ReductionInGrossReceiptsDocuments from '../../components/reduction-in-gross-receipts-documents/reduction-in-gross-receipts-documents';
import displayNames from '../../constants/displayNames';
import BetterStandardLayout from '../../layouts/better-standard-layout/better-standard-layout';

const ReductionInGrossReceiptsDocumentsPage = () => (
  <div className="summary-container">
    <Helmet>
      <title>Reduction in Gross Receipts Documents</title>
    </Helmet>
    <BetterStandardLayout
      bodyElement={<ReductionInGrossReceiptsDocuments />}
    />
  </div>
);

ReductionInGrossReceiptsDocumentsPage.displayName = displayNames.ReductionInGrossReceiptsDocumentsPage;

export default connect()(SecurePage(ReductionInGrossReceiptsDocumentsPage));
