import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@swift-paypal/pp-react';
import LoanProcessing from '../loan-state/loan-processing';
import contractIcon from './contract_icon.svg';
import { reviewContractAction } from '../../../redux/actions/contract/contract-actions';
import './docusign.less';

const DocuSignComponent = ({
  opportunityId,
  reviewContract,
  showLoanProcessing,
}) => {
  if (showLoanProcessing) {
    return (<LoanProcessing />);
  }

  const viewContract = async () => {
    await reviewContract(opportunityId);
  };

  return (
    <div className="docusign-component">
      <img src={contractIcon} alt="A minimalist document with the PayPal logo being signed by a pencil" />
      <div className="vx_text-body-md_medium content">
        <strong className="vx_text-4 bold-body-text">Your loan agreement is ready to sign</strong>
        <p className="vx_text-6_lighter">
          After your signed loan agreement is reviewed, if approved, funding should occur
          in 1 to 2 business days.
        </p>
        <p className="vx_text-6_lighter">
          You&apos;ll be redirected to DocuSign to complete your contract.
        </p>
      </div>
      <Button type="submit" className="fixed-btn" onClick={() => viewContract()}>Sign Loan Agreement</Button>
      <hr className="divider" />
      <p className="vx_text-6_lighter">
        Once your loan agreement is signed, it can take up to 15 minutes for this page to update.
      </p>
    </div>
  );
};

DocuSignComponent.propTypes = {
  showLoanProcessing: PropTypes.bool,
  opportunityId: PropTypes.string,
  reviewContract: PropTypes.func.isRequired,
};

DocuSignComponent.defaultProps = {
  showLoanProcessing: false,
  opportunityId: undefined,
};

const mapDispatchToProps = {
  reviewContract: reviewContractAction,
};

export default connect(null, mapDispatchToProps)(DocuSignComponent);
