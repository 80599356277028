import * as actions from '../actions/business-property/business-property-action-types';
import { AlertTypes } from '../../components/common/alert';

const businessProperty = (state = { ownBusinessProperty: undefined }, action) => {
  switch (action.type) {
    case actions.SET_OPPORTUNITY_AS_LANDLORD_ERROR:
    case actions.CREATE_LANDLORD_CONTACT_ERROR:
      return {
        ...state,
        messageBody: 'Failed to submit your references; please try again.',
        messageType: AlertTypes.critical,
      };
    case actions.SET_OPPORTUNITY_AS_LANDLORD_RECEIVED:
    case actions.CREATE_LANDLORD_CONTACT_RECEIVED:
      return {
        ...state,
        messageBody: 'You have provided us with business property information.',
        messageType: AlertTypes.success,
      };
    case actions.UPDATE_LANDLORD_MESSAGE:
      return { ...state, messageBody: action.messageBody, messageType: action.messageType };
    case actions.CLEAR_LANDLORD_MESSAGE:
      return { ...state, messageBody: undefined, messageType: undefined };
    case actions.SET_OWN_BUSINESS_PROPERTY:
      return { ...state, ownBusinessProperty: action.payload };
    default:
      return state;
  }
};

export default businessProperty;
