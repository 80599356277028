import React from 'react';
import PropTypes from 'prop-types';

const PhoneNumber = (props) => {
  const { number, ext, dataAutomationId } = props;
  let modifiedNumber = number;
  if (number.charAt(0) === '(') {
    modifiedNumber = modifiedNumber.replace(')', '-');
    modifiedNumber = modifiedNumber.replace('(', '-');
    modifiedNumber = modifiedNumber.replace(' ', '');
    modifiedNumber = `1${modifiedNumber}`;
  }
  return (
    <span data-automation-id={dataAutomationId}>
      {modifiedNumber}
      {ext ? ` ext. ${ext}` : ''}
    </span>
  );
};

PhoneNumber.propTypes = {
  ext: PropTypes.string,
  number: PropTypes.string.isRequired,
  dataAutomationId: PropTypes.string,
};

PhoneNumber.defaultProps = {
  ext: undefined,
  dataAutomationId: undefined,
};

export default PhoneNumber;
