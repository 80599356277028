import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { email, required } from 'redux-form-validators';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from '@swift-paypal/pp-react';
import { Alert, AlertTypes } from '../common/alert';
import floatingLabelField from '../../lib/forms/floating-label-field';
import Title from '../common/title';
import PageTitle from '../common/page-title';

export const ForgotPasswordForm = (props) => {
  const { handleSubmit, submitFunc, forgotPasswordError } = props;

  return (
    <Container>
      <PageTitle text="Forgot LoanBuilder Password" />
      <form onSubmit={handleSubmit(submitFunc)} className="forgot-password-form">
        <Title text="Enter your email address to reset your password." />
        <Row>
          <Col xs={10}>
            {forgotPasswordError && <Alert message={forgotPasswordError} alertType={AlertTypes.critical} />}
            <Field
              name="email"
              manualSet
              className="form-control vx_form-control do-not-record"
              component={floatingLabelField}
              type="email"
              label="Email"
              noErrorClass="margin-bottom-sm"
              validate={[required({ msg: 'Email address is required' }), email({ msg: 'Invalid email address' })]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={5} md={4}>
            <Button type="submit" className="pp-react__button--block">
              Continue
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

ForgotPasswordForm.propTypes = {
  forgotPasswordError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitFunc: PropTypes.func.isRequired,
};

ForgotPasswordForm.defaultProps = {
  forgotPasswordError: undefined,
};

const ForgotPasswordFormComponent = reduxForm({
  form: 'forgotPassword',
})(ForgotPasswordForm);

export default ForgotPasswordFormComponent;
