import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import PasswordReq from './password-req';

const { errors, passwordLength, regex } = constants;

const PasswordReqs = ({ current, usernameRegex }) => (
  <div className="password-checklist padding-top-bottom-xs">
    <PasswordReq predicate={regex.letterNumber.pattern.test(current)} reqText={regex.letterNumber.message} />
    <PasswordReq
      predicate={current.length >= passwordLength.min && current.length <= passwordLength.max}
      reqText={passwordLength.message}
    />
    <PasswordReq predicate={regex.specialChars.pattern.test(current)} reqText={regex.specialChars.message} />
    {usernameRegex && (
      <PasswordReq predicate={usernameRegex.test(current)} reqText={errors.cannotBeUsername} />
    )}
  </div>
);

PasswordReqs.propTypes = {
  current: PropTypes.string.isRequired,
  usernameRegex: PropTypes.string,
};

PasswordReqs.defaultProps = {
  usernameRegex: undefined,
};

export default PasswordReqs;
