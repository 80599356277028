import React from 'react';
import { Button } from '@swift-paypal/pp-react';

const launchPlaid = () => {
  // eslint-disable-next-line no-console
  console.log('TODO: Launch plaid!');
};

const LaunchPlaidButton = () => (
  <Button onClick={launchPlaid}>
    Launch Plaid
  </Button>
);

export default LaunchPlaidButton;
