import { Container, HeadingText } from '@swift-paypal/pp-react';
import React from 'react';
import { ForgivenessTileList } from '../forgivenessTileList';

const ForYou = () => (
  <div className="summary-page-layer">
    <div className="summary-page-layer__content">
      <HeadingText size="sm">For you</HeadingText>
      <Container className="card-container" fluid>
        <ForgivenessTileList />
      </Container>
    </div>
  </div>
);

export default ForYou;
