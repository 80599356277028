import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { BodyText, RadioButton } from '@swift-paypal/pp-react';
import constants from '../../../../../constants';
import './loan-greater-than-two-million.less';

const { errors } = constants;

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <RadioButton {...input} {...props} />;

const LoanGreaterThan2Million = ({
  className, error, submitted, touched,
}) => (
  <>
    <BodyText as="label" htmlFor="loanGreaterThan2Million">
      Have you (alone or together with your affiliates) received PPP loans of $2 million or more?
    </BodyText>
    <Field
      className={`${className} loan-greater-than-2-million`}
      key="loan-greater-than-2-million"
      name="loanGreaterThan2Million"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      validate={[required({ msg: errors.chooseYesOrNo })]}
      options={[
        { label: 'Yes', value: 'true', className: 'radio-item' },
        { label: 'No', value: 'false', className: 'radio-item' },
      ]}
    />
  </>
);

LoanGreaterThan2Million.defaultProps = {
  className: '',
  error: undefined,
  submitted: false,
  touched: false,
};

LoanGreaterThan2Million.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
};

export default memo(LoanGreaterThan2Million);
