import React from 'react';
import { BodyText, Link } from '@swift-paypal/pp-react';
import {
  DETAILS_SECTION_1,
  DETAILS_SECTION_2,
  DETAILS_SECTION_3,
  DETAILS_SECTION_4,
  DETAILS_SECTION_5,
  DETAILS_SECTION_6_HEADER,
  DETAILS_SECTION_6_ITEM_1,
  DETAILS_SECTION_6_ITEM_2,
  DETAILS_SECTION_6_ITEM_3,
  DETAILS_SECTION_7_LINK_1_ADDRESS,
  DETAILS_SECTION_7_LINK_1_TEXT,
  DETAILS_SECTION_7_LINK_2_ADDRESS,
  DETAILS_SECTION_7_LINK_2_TEXT,
  DETAILS_SECTION_7_PART_1,
  DETAILS_SECTION_7_PART_2,
  DETAILS_SECTION_7_PART_3,
} from './forgivenessGrossReceiptsDocumentsDetails.constants';

export const ForgivenessGrossReceiptsDocumentsDetails = () => (
  <>
    <BodyText as="p" className="margin-top-sm body-text">{DETAILS_SECTION_1}</BodyText>
    <BodyText as="p" className="margin-top-sm body-text">{DETAILS_SECTION_2}</BodyText>
    <BodyText as="p" className="margin-top-sm body-text">{DETAILS_SECTION_3}</BodyText>
    <BodyText as="p" className="margin-top-sm body-text">{DETAILS_SECTION_4}</BodyText>
    <BodyText as="p" className="margin-top-sm body-text">{DETAILS_SECTION_5}</BodyText>
    <BodyText as="p" className="margin-top-sm body-text" strong>{DETAILS_SECTION_6_HEADER}</BodyText>
    <ul>
      <BodyText className="body-text" as="li">{DETAILS_SECTION_6_ITEM_1}</BodyText>
      <BodyText className="body-text" as="li">{DETAILS_SECTION_6_ITEM_2}</BodyText>
      <BodyText className="body-text" as="li">{DETAILS_SECTION_6_ITEM_3}</BodyText>
    </ul>
    <BodyText as="p" className="margin-top-sm body-text">
      {`${DETAILS_SECTION_7_PART_1} `}
      <Link
        className="pp-react__link--inline"
        href={DETAILS_SECTION_7_LINK_1_ADDRESS}
        target="_blank"
        rel="noopener noreferrer"
      >
        {DETAILS_SECTION_7_LINK_1_TEXT}
      </Link>
      {` ${DETAILS_SECTION_7_PART_2} `}
      <Link
        className="pp-react__link--inline"
        href={DETAILS_SECTION_7_LINK_2_ADDRESS}
        target="_blank"
        rel="noopener noreferrer"
      >
        {DETAILS_SECTION_7_LINK_2_TEXT}
      </Link>
      {` ${DETAILS_SECTION_7_PART_3}`}
    </BodyText>
  </>
);
