import { merge } from 'lodash';
import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';
import { maxUploadBodySize } from '../constants/contract';

async function getContract(apiUrl, id) {
  const url = `${apiUrl}/opportunities/${id}/contract`;
  const axiosOptions = merge(defaultRequestOptions(),
    {
      headers: { Accept: 'application/pdf' },
      responseType: 'blob',
    });
  const res = await axios.get(url, axiosOptions);
  return res.data;
}
const debouncedGetContract = asyncDebounce(getContract);

async function upload(apiUrl, opportunityId, files) {
  const formData = new FormData();
  files.forEach((file) => formData.append('file', file));
  const options = { ...defaultRequestOptions() };
  options.maxContentLength = maxUploadBodySize;

  const res = await axios.post(`${apiUrl}/opportunities/${opportunityId}/contract`, formData, options);
  return res.data;
}

async function getContractViewUrl(apiUrl, id) {
  const url = `${apiUrl}/opportunities/${id}/contract/view`;
  const axiosOptions = { ...defaultRequestOptions() };
  const res = await axios.get(url, axiosOptions);
  return res.data;
}
const debouncedGetContractViewUrl = asyncDebounce(getContractViewUrl);

export default {
  getContract: debouncedGetContract,
  getContractViewUrl: debouncedGetContractViewUrl,
  upload,
};
