import React from 'react';
import PropTypes from 'prop-types';
import ShowHideButton from './show-hide-button';

const standardPasswordField = (props) => {
  const {
    input, label, show, toggleShow, meta,
  } = props;
  const {
    error, visited, touched, submitting, submitFailed,
  } = meta;
  if (submitting && show) {
    toggleShow();
  }
  const showError = ((visited && touched) || submitFailed) && error;
  return (
    <div
      className={`vx_floatingLabel_active password-field
        ${showError ? 'vx_has-error-with-message' : ''}
        ${(input.value) ? 'hasValue' : ''}`}
      data-label-content={label}
    >
      <label htmlFor={input.name}>
        {label}
      </label>
      <input
        {...input}
        id={input.name}
        type={!show ? 'password' : 'text'}
        className={`form-control vx_form-control do-not-record ${!showError ? 'margin-bottom-sm' : ''}`}
      />
      <ShowHideButton show={show} toggleShow={toggleShow} />
      {showError && <span className="vx_form-control-message margin-bottom-sm">{error}</span>}
    </div>
  );
};

standardPasswordField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  show: PropTypes.bool,
  toggleShow: PropTypes.func.isRequired,
};

standardPasswordField.defaultProps = {
  input: undefined,
  meta: undefined,
  show: false,
};

export default standardPasswordField;
