import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col } from '@swift-paypal/pp-react';
import downArrowWhite from '../../../static/images/icons/down-arrow--white.svg';
import Business from './business/business';
import './activity-dropdown.less';

const ActivityDropdown = ({ accounts, advancesList }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const advancesByAccountId = accounts?.reduce((prevResult, { accountId }) => {
    const accountAdvances = advancesList?.filter((advance) => advance.loanId && advance.accountId === accountId);
    if (accountAdvances?.length > 0) {
      return {
        ...prevResult,
        [accountId]: accountAdvances,
      };
    }
    return prevResult;
  }, {});

  const accountsWithFundedLoansPresent = Object.keys(advancesByAccountId)?.length;

  const renderDropdownContent = () => {
    if (accountsWithFundedLoansPresent === 0) {
      return (
        <ul className="dropdown-content">
          <li className="button-link dropdown-header no-activity">There are no viewable loans.</li>
        </ul>
      );
    }

    return (
      <ul className="dropdown-content">
        {_.sortBy(accounts, ['accountName']).map((account) => (
          <Business
            advances={advancesByAccountId[account.accountId]}
            businessName={account.accountName}
            key={account.accountId}
          />
        ))}
      </ul>
    );
  };

  return (
    <Row
      as="li"
      align="start"
      className="dropdown activity"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <Col
        as="button"
        type="button"
        className="button-link
        vx_globalNav-links
        bold-nav-links
        focus-highlight"
        data-automation-id="activityButton"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        Activity
        {' '}
        <img alt="Dropdown arrow" className="dropdown-arrow" src={downArrowWhite} />
      </Col>
      {(dropdownOpen && renderDropdownContent())}
    </Row>
  );
};

ActivityDropdown.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  advancesList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActivityDropdown.defaultProps = {
  accounts: undefined,
};

const mapStateToProps = (state) => ({
  accounts: state.accountItems.accounts || [],
  advancesList: Object.values(state.entities.advances.byId),
});

export default connect(mapStateToProps)(ActivityDropdown);
export const ActivityDropdownComponent = ActivityDropdown;
