import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { BodyText } from '@swift-paypal/pp-react';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import UploadMultipleDocumentsComponent from '../../components/upload-multiple-documents/upload-multiple-documents';
import getStipulationsAction, { fulfillStipulationAction } from '../../redux/actions/stipulations/stipulations-actions';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadDocumentsAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { stipulationGroupsDescriptions } from '../../constants/upload-document';

const stipulationGroupName = 'Business License';

class BusinessLicensePage extends Component {
  static displayName = displayNames.BusinessLicensePage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    fulfillStipulation: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    getStipulations: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        opportunityId: PropTypes.string,
      }),
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
    })),
    push: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    uploadDocuments: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.bool,
  };

  static defaultProps = {
    country: undefined,
    match: undefined,
    opportunitiesById: {},
    stipulations: [],
    uploadPending: true,
    uploadError: false,
  };

  async componentDidMount() {
    const {
      country,
      getProductsAndBfes,
      getOpportunities,
      getStipulations,
      match,
      opportunitiesById,
    } = this.props;
    const { opportunityId } = match.params;
    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
    await getStipulations(opportunityId, country);
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = (files) => {
    const { match, uploadDocuments } = this.props;
    const { opportunityId } = match.params;
    const fileBlobs = files.map(({ fileBlob }) => fileBlob);
    const payload = uploadUtils.buildMultipleFilesPayload(fileBlobs, opportunityId, 'BusinessLicense');

    uploadDocuments(payload);
  }

  async redirectToSummaryConfirmation() {
    const {
      country, fulfillStipulation, match, push, stipulations,
    } = this.props;
    const { opportunityId } = match.params;
    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };
    const stipulation = stipulations.find(({ stipulationMap: { name } }) => name === 'Business Certificate/License');

    if (!stipulation) {
      return undefined;
    }

    await fulfillStipulation(opportunityId, stipulation.id);

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      uploadPending, uploadError, opportunitiesById, match,
    } = this.props;
    const { opportunityId } = match.params;

    let pageTitle = 'Business License';

    let groupDescription = stipulationGroupsDescriptions.Default;

    let fakeStipulation = {
      displayName: 'Business License: Upload documents',
      description: (
        <>
          <p>
            Please upload a valid business license for verification.
            If you do not have a business license, you can upload
            your IRS Form 1040 Schedule C or equivalent documentation.
          </p>
          <p>
            If you are an Independent Contractor or Self-Employed please provide a copy of your driver&apos;s license.
          </p>
        </>
      ),
    };

    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;
    const showPPPLoan = ['21PPPLoan', '21SDPPP', '20PPPLoan'].includes(loanVersion);

    if (showPPPLoan) {
      pageTitle = 'Business License & Other Documentation';

      groupDescription = (
        <>
          <BodyText as="h3">
            <strong>Business License: </strong>
            Please upload a valid business license for verification. If you are an Independent Contractor
            or Self Employed Individual and do not have a business license, you can upload your IRS Form
            1040 Schedule C or equivalent tax forms.
          </BodyText>
          <BodyText as="h3">
            <strong>Other Organization Documentation: </strong>
            If you received an email requesting additional organizational documentation (most often received
            by nonprofit organizations), please upload all additional information requested in that email here.
          </BodyText>
        </>
      );

      fakeStipulation = {
        displayName: 'Upload your Business License or other organization documentation requested',
      };
    }
    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    return (
      <>
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StandardLayout
            contentElement={(
              <div>
                <PageTitle bold text={pageTitle} />
                <UploadMultipleDocumentsComponent
                  groupDescription={groupDescription}
                  uploadError={uploadError}
                  uploadPending={uploadPending}
                  stipulations={[fakeStipulation]}
                  minimumStipulationsRequired={1}
                  submit={this.submit}
                  showPPPLoan
                />
              </div>
            )}
            sidebarElement={<></>}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    stipulations: state.stipulations.allStipulations,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    fulfillStipulation: fulfillStipulationAction,
    getOpportunities: getOpportunitiesAction,
    getProductsAndBfes: getProductsAndBfesAction,
    getStipulations: getStipulationsAction,
    uploadDocuments: uploadDocumentsAction,
  },
)(SecurePage(BusinessLicensePage));
