import React from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@swift-paypal/pp-react';
import { ppp21LoanVersions } from '../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';
import './body-text.less';

const MortgageInterestBodyText = ({ loanVersion }) => (
  <div className="body-text">
    <BodyText as="p">
      Business mortgage interest documents are required if
      {ppp21LoanVersions.includes(loanVersion)
        && ' such costs are'}
      {' '}
      included in your loan forgiveness request. If not, please see the check box below.
    </BodyText>
    <BodyText as="p">
      Upload supporting documents to verify your business mortgage interest amounts and
      eligible payments. Here&apos;s what you need to provide:
    </BodyText>
    <ul className="forgivenessListItems">
      <BodyText as="li">
        A copy of
        <strong> lender amortization schedule and receipts or cancelled checks </strong>
        verifying eligible payments for the covered period, or
      </BodyText>
      <BodyText as="li">
        <strong>Lender account statements </strong>
        from Feb 2020 and the months of your selected covered period through 1 month
        after the end of the covered period, verifying interest amounts and eligible payments.
      </BodyText>
    </ul>
  </div>
);

MortgageInterestBodyText.propTypes = {
  loanVersion: PropTypes.string.isRequired,
};

export default MortgageInterestBodyText;
