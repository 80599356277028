export const CUSTOMER_SIGNOFF = 'Customer Signoff';

export const EZ_APP = '3508EZ';

export const FORGIVENESS_APPLICATION = 'forgiveness-application';

export const FORGIVENESS_SUMMARY = 'forgiveness-summary';

export const forgivenessMessagingStages = [
  'Declined',
  'Expired',
  'Forgiven',
  'Document Review',
  'Document Upload',
  'Quality Check Sendback',
  'Internal Quality Check',
  'Additional Documents Requested',
  'Pending SBA Submission',
  'Awaiting SBA Response',
  'SBA Response Received',
  'SBA Additional Info Required',
];

export const FORGIVENESS_STAGES = {
  FORGIVEN: 'Forgiven',
  EXPIRED: 'Expired',
  DECLINED: 'Declined',
  AWAITING_SBA_RESPONSE: 'Awaiting SBA Response',
  SBA_RESPONSE_RECEIVED: 'SBA Response Received',
  SBA_ADDITIONAL_INFO_REQUIRED: 'SBA Additional Info Required',
  ADDITIONAL_DOCUMENTS_REQUESTED: 'Additional Documents Requested',
  DOCUMENT_REVIEW: 'Document Review',
  DOCUMENT_UPLOAD: 'Document Upload',
  QUALITY_CHECK_SENDBACK: 'Quality Check Sendback',
  INTERNAL_QUALITY_CHECK: 'Internal Quality Check',
  PENDING_SBA_SUBMISSION: 'Pending SBA Submission',
  CUSTOMER_SIGNOFF: 'Customer Signoff',

};

export const COLLATERAL_STATES = ['Ready', 'Packaged'];

export const ONE_HUNDRED_FIFTY_THOUSAND = 150000;

export const PPP21_FIRST_DRAW = '21PPPLoan';

export const PPP21_SECOND_DRAW = '21SDPPP';

export const ppp21LoanVersions = [PPP21_SECOND_DRAW, PPP21_FIRST_DRAW];

export const S_APP = '3508S';

export const STANDARD_APP = '3508';

export const USA = 'us';
