import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  getContactNotifications: asyncDebounce(getContactNotifications),
};

async function getContactNotifications(apiUrl, location) {
  const res = await axios.get(`${apiUrl}/contacts/notifications`, defaultRequestOptions());
  res.data.location = location;
  return res.data;
}
