export const STIPULATIONS_REQUEST_PENDING = 'stipulations_request_pending';
export const STIPULATIONS_REQUEST_RECEIVED = 'stipulations_request_received';
export const STIPULATIONS_REQUEST_ERROR = 'stipulations_request_error';

export const FULFILL_STIPULATION_PENDING = 'fulfill_stipulation_pending';
export const FULFILL_STIPULATION_RECEIVED = 'fulfill_stipulation_received';
export const FULFILL_STIPULATION_ERROR = 'fulfill_stipulation_error';
export const FULFILL_STIPULATION_CLEAR = 'fulfill_stipulation_clear';

export const NEW_STIPULATION_FLAG_PENDING = 'new_stipulation_flag_pending';
export const NEW_STIPULATION_FLAG_RECEIVED = 'new_stipulation_flag_received';
export const NEW_STIPULATION_FLAG_ERROR = 'new_stipulation_flag_error';
