import axios from 'axios';

const ignoreRequestCountHeader = 'Ignore-Request-Count';

export default {
  initialize: initialize(),
  show,
  hide,
};

function initialize() {
  let requestCount = 0;
  let ignoreRequestCount = false;
  return () => {
    axios.interceptors.request.use((config) => {
      if (config.headers[ignoreRequestCountHeader]) {
        ignoreRequestCount = config.headers[ignoreRequestCountHeader];
        delete config.headers[ignoreRequestCountHeader];
      }
      else {
        ignoreRequestCount = false;
      }
      requestCount += 1;

      // if param hideSpinner exists and is true, don't show spinner but do remove that param from request
      if (!config.params || !config.params.hideSpinner) {
        show();
      }
      else {
        const { hideSpinner, ...paredParams } = config.params;
        config.params = paredParams;
      }

      return config;
    }, (error) => {
      requestCount -= 1;
      // for errors, always hide spinner if ignoreRequestCount is true
      if (ignoreRequestCount) {
        hide();
      }
      else if (requestCount === 0) {
        hide();
      }
      return Promise.reject(error);
    });
    axios.interceptors.response.use((response) => {
    // timout helps to avoid page flicker between requests
      setTimeout(() => {
        requestCount -= 1;
        if (requestCount === 0 && !ignoreRequestCount) {
          hide();
        }
      }, 250);
      return response;
    }, (error) => {
    // timout helps to avoid page flicker between requests
      setTimeout(() => {
        requestCount -= 1;
        // for errors, always hide spinner if ignoreRequestCount is true
        if (ignoreRequestCount) {
          hide();
        }
        else if (requestCount === 0) {
          hide();
        }
      }, 250);
      return Promise.reject(error);
    });
  };
}

function show() {
  hideElement('portal-content');
  showElement('spinner');
}

function hide() {
  hideElement('spinner');
  showElement('portal-content');
}

function hideElement(className) {
  const el = document.getElementsByClassName(className)[0];
  if (el) {
    el.classList.add('display-none');
  }
}

function showElement(className) {
  const el = document.getElementsByClassName(className)[0];
  if (el) {
    el.classList.remove('display-none');
  }
}
