import countryPostalCodeNormalizations from '../constants/countryPostalCodeNormalizations';

export default {
  getNormalizationByCountry,
};

function getNormalizationByCountry(country) {
  if (!country) {
    return countryPostalCodeNormalizations.us;
  }

  const normalization = countryPostalCodeNormalizations[country.toLowerCase()];

  if (!normalization) {
    return countryPostalCodeNormalizations.us;
  }

  return normalization;
}
