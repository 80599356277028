import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import downArrowWhite from '../../../static/images/icons/down-arrow--white.svg';
import withUrlParams from '../../../hocs/with-url-params';
import { expireAction } from '../../../redux/actions/auth/auth-actions';
import resolve from '../../../services/route.service';
import routes from '../../../routes';
import { closeNavMenu } from '../../../util';

const ProfileDropdown = ({ country, expire, user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const renderDropdownContent = () => {
    if (!dropdownOpen) {
      return null;
    }

    return (
      <ul className="dropdown-content">
        <li>
          <div className="menuNameLabel button-link dropdown-header default-cursor" data-automation-id="menuNameLabel">
            { user.firstName && user.lastName && `${user.firstName} ${user.lastName}`}
          </div>
          <div className="menuEmailLabel button-link default-cursor" data-automation-id="menuEmailLabel">
            {user.email}
          </div>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <Link data-automation-id="menuSettingsLink" onClick={closeNavMenu} to={resolve(routes.settings.path, { country })}>
            Settings
          </Link>
        </li>
        <li>
          <Link data-automation-id="menuLogoutLink" onClick={expire} to={resolve(routes.login.path, { country })}>
            Log Out
          </Link>
        </li>
      </ul>
    );
  };

  return (
    <div href="#" className="vx_globalNav-list">
      <li
        className="vx_hidden-phone dropdown"
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
      >
        <button
          type="button"
          className="button-link vx_globalNav-links bold-nav-links focus-highlight"
          data-automation-id="menuButton"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          { user.firstName && user.lastName && `${user.firstName} ${user.lastName}`}
          <img alt="Dropdown arrow" className="dropdown-arrow" src={downArrowWhite} />
        </button>
        <span className="vx_a11yText">Profile</span>
        {renderDropdownContent()}
      </li>
    </div>
  );
};

ProfileDropdown.propTypes = {
  country: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  expire: PropTypes.func.isRequired,
};

ProfileDropdown.defaultProps = {
  country: undefined,
  user: undefined,
};

const mapStateToProps = (state) => ({
  user: state.authorize.user || {},
});

const mapDispatchToProps = {
  expire: expireAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlParams,
)(ProfileDropdown);
