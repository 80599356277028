import PropTypes from 'prop-types';
import React from 'react';
import countryPostalCodeService from '../../services/countryPostalCode.service';

const Address = (props) => {
  const {
    city, country, line1, line2, state, zip,
  } = props;

  const normalizePostalCode = countryPostalCodeService.getNormalizationByCountry(country);

  return (
    <div className="vx_text-body-md margin-bottom-xs">
      <div>
        {line1 && <div>{line1}</div>}
        {line2 && <div>{line2}</div>}
      </div>
      <div>
        {city && `${city}, `}
        {state && `${state} `}
        {zip && `${normalizePostalCode(zip)}`}
      </div>
    </div>
  );
};

Address.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
};

Address.defaultProps = {
  city: undefined,
  country: undefined,
  line1: undefined,
  line2: undefined,
  state: undefined,
  zip: undefined,
};

export default Address;
