import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm, change as changeAction } from 'redux-form';
import {
  Container, Row, Col, HeadingText, BodyText, Link, Divider,
} from '@swift-paypal/pp-react';
import moment from 'moment';
import TotalCashCompensationField from '../../fields/total-cash-compensation/total-cash-compensation';
import AverageFteField from '../../fields/average-fte';
import MaintainedHourlyWageField from '../../fields/maintained-hourly-wage/maintained-hourly-wage';
import TotalSalaryReductionsField from '../../fields/total-salary-reductions';
import './payroll-costs-form.less';
import { payrollCostsFormSelector } from './payroll-costs-form-selectors';
import { useShallowSelector } from '../../../../../hooks';

const PayrollCostsForm = ({
  children,
  fields,
  handleSubmit,
  submitted,
}) => {
  const {
    loanbuilderUrl,
    page1Values,
    selectedAverageFteTable1,
    selectedAverageFteTable2,
    selectedMaintainedHourlyWage,
    selectedTotalCashCompensationTable1,
    selectedTotalCashCompensationTable2,
    selectedTotalSalaryReductions,
    syncErrors,
  } = useShallowSelector(payrollCostsFormSelector);
  const dispatch = useDispatch();
  const requireSalaryReductions = selectedMaintainedHourlyWage && !JSON.parse(selectedMaintainedHourlyWage);
  const coveredStart = moment(page1Values.standardCoveredPeriodStartDate).format('ll');
  const coveredEnd = moment(page1Values.standardCoveredPeriodEndDate).format('ll');

  useEffect(() => {
    if (isNil(selectedMaintainedHourlyWage) || !JSON.parse(selectedMaintainedHourlyWage)) {
      return;
    }
    dispatch(changeAction('forgivenessPage2', 'totalSalaryReductions', null));
  }, [selectedMaintainedHourlyWage]);

  function CoveredPeriodText() {
    return (` ${coveredStart} \u2013 ${coveredEnd} `
    );
  }

  return (
    <Container form as="form" className="payroll-costs-form" onSubmit={handleSubmit} noValidate>
      <Row>
        <Col form="full">
          <HeadingText size="sm" as="h2">Help us understand your payroll costs</HeadingText>
          <BodyText className="instructions">
            Calculate your payroll costs between
            <CoveredPeriodText />
            using the
            {' '}
            <Link
              href={`${loanbuilderUrl}/sba_form_3508.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              data-automation-id="downloadScheduleAWorksheetLink"
            >
              Schedule A worksheet
            </Link>
            , then enter the total values from Tables 1 and 2. To speed up our review, save the
            Schedule A worksheet to upload at the end of the application.
          </BodyText>
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <BodyText strong as="h3">Table 1</BodyText>
          <Divider secondary />
          <TotalCashCompensationField
            table={1}
            box={1}
            error={syncErrors.totalCashCompensationTable1}
            submitted={submitted}
            touched={fields.totalCashCompensationTable1?.touched}
            value={selectedTotalCashCompensationTable1}
          />
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <AverageFteField
            table={1}
            box={2}
            error={syncErrors.averageFteTable1}
            submitted={submitted}
            touched={fields.averageFteTable1?.touched}
            value={selectedAverageFteTable1}
          />
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <MaintainedHourlyWageField
            error={syncErrors.maintainedHourlyWage}
            submitted={submitted}
            touched={fields.maintainedHourlyWage?.touched}
            value={selectedMaintainedHourlyWage}
            coveredStart={coveredStart}
            coveredEnd={coveredEnd}
          />
        </Col>
      </Row>
      {requireSalaryReductions && (
        <Row>
          <Col form="full">
            <TotalSalaryReductionsField
              error={syncErrors.totalSalaryReductions}
              submitted={submitted}
              touched={fields.totalSalaryReduction?.touched}
              value={selectedTotalSalaryReductions}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col form="full">
          <BodyText strong as="h3">Table 2</BodyText>
          <Divider secondary />
          <TotalCashCompensationField
            table={2}
            box={4}
            error={syncErrors.totalCashCompensationTable2}
            submitted={submitted}
            touched={fields.totalCashCompensationTable2?.touched}
            value={selectedTotalCashCompensationTable2}
          />
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <AverageFteField
            table={2}
            box={5}
            error={syncErrors.averageFteTable2}
            submitted={submitted}
            touched={fields.averageFteTable2?.touched}
            value={selectedAverageFteTable2}
          />
        </Col>
      </Row>
      {children}
    </Container>
  );
};

PayrollCostsForm.defaultProps = {
  fields: {},
  submitted: false,
};

PayrollCostsForm.propTypes = {
  children: PropTypes.element.isRequired,
  fields: PropTypes.objectOf(PropTypes.shape({
    touched: PropTypes.bool,
  })),
  handleSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
};

export default
reduxForm({
  form: 'forgivenessPage2',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PayrollCostsForm);
