import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  BodyText, Col, Container, HeadingText, Row, Link,
} from '@swift-paypal/pp-react';
import util from '../../util';
import { loanSummarySelector, useShallowSelector } from './loan-summary-lib/selectors';
import { getLoanName, getPayFrequencyAndValue, shouldHidePaymentFrequencyInfo } from './loan-summary-lib/utils';
import LoanSummaryActivityLink from './loan-summary-activity-link/loan-summary-activity-link';
import { getGuarantyClaimAction } from '../../redux/actions/guaranty-claims/guaranty-claims-action';

import './loan-summary.less';

const GUARANTY_CLAIMED_DESCRIPTION = 'An SBA Guaranty Purchase has been completed on your PPP loan. Questions concerning SBA Guaranty purchase of PPP loans can be directed to the SBA at ';
const PPPSbaEmailAddress = 'PPPloanpurchase@sba.gov';

const LoanSummary = ({ advanceId, getGuarantyClaim, isGuarantyClaimAvailable }) => {
  const { advance, forgivenessStage, oppPartnerName } = useShallowSelector(loanSummarySelector, advanceId);

  if (!advance) {
    return null;
  }

  const {
    balance,
    currentPayment,
    id,
    name,
    estimatedMaturityDate,
    paymentFrequency,
    loanForgivenessId,
    firstPaymentDateExpected,
    type,
  } = advance;

  const { frequency, payment } = getPayFrequencyAndValue({
    paymentFrequency,
    currentPayment,
    type,
    forgivenessStage,
    firstPaymentDateExpected,
  });
  const loanName = getLoanName(name, oppPartnerName);
  const hidePaymentFrequencyInfo = shouldHidePaymentFrequencyInfo({
    type, balance, forgivenessStage, estimatedMaturityDate, loanForgivenessId,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (advanceId) {
        await getGuarantyClaim(advanceId);
      }
    };
    fetchData();
  }, []);

  if (isGuarantyClaimAvailable) {
    return (
      <div className="guaranty-claim-messaging">
        <BodyText as="p" strong>
          {GUARANTY_CLAIMED_DESCRIPTION}
          <Link
            className="pp-react__link--inline"
            data-automation-id="pppLoanInfoLink"
            href={`mailto:${PPPSbaEmailAddress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {PPPSbaEmailAddress}
          </Link>
          .
        </BodyText>
      </div>
    );
  }

  return (
    <Container className="padding-top-bottom-sm" fluid>
      <HeadingText size="sm">{loanName}</HeadingText>
      <Row>
        <Col className="padding-top-bottom-sm" xs={12} sm={6}>
          <BodyText>Current balance</BodyText>
          <BodyText strong>{util.getCurrency(balance)}</BodyText>
        </Col>
        {!hidePaymentFrequencyInfo && (
        <Col className="padding-top-bottom-sm" xs={12} sm={6}>
          <BodyText>{frequency}</BodyText>
          <BodyText strong>{payment}</BodyText>
        </Col>
        )}
      </Row>
      <LoanSummaryActivityLink loanId={id} />
    </Container>
  );
};

LoanSummary.propTypes = {
  advanceId: PropTypes.string.isRequired,
  isGuarantyClaimAvailable: PropTypes.bool,
  getGuarantyClaim: PropTypes.func.isRequired,
};

LoanSummary.defaultProps = {
  isGuarantyClaimAvailable: false,
};

const mapStateToProps = (state) => ({
  isGuarantyClaimAvailable: state.isGuarantyClaimAvailable,
});

const mapDispatchToProps = {
  getGuarantyClaim: getGuarantyClaimAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LoanSummary);
