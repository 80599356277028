import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { TextInput, BodyText } from '@swift-paypal/pp-react';
import constants from '../../../../constants';

const { errors } = constants;

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const AverageFTEInputField = ({
  error, submitted, touched, name, content,
}) => (
  <>
    <BodyText as="label" htmlFor={name}>
      {content}
    </BodyText>
    <Field
      key="average-fte-input-field"
      className="average-fte-field"
      name={name}
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      validate={[
        required({ msg: errors.requiredField }),
        numericality({ msg: errors.mustBeNumber }),
      ]}
    />
  </>
);

AverageFTEInputField.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
};

AverageFTEInputField.propTypes = {
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default memo(AverageFTEInputField);
