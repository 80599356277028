import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change as changeAction } from 'redux-form';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import {
  Col, Container, Divider, HeadingText, Row,
} from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../../hooks';
import { otherBusinessDetailsSelector } from './other-business-details-selectors';
import { otherBusinessDetailsFields } from './other-business-details-constants';
import { getCoveredPeriodFromDates } from './other-business-details-utils';
// import { EidlAdvanceRadio } from '../../fields/eidl-advance-radio';
import './other-business-details.less';

const OtherBusinessDetails = ({
  children,
  handleSubmit,
  submitted,
}) => {
  const {
    fields,
    values,
    syncErrors,
    standardCoveredPeriodStartDate,
    standardCoveredPeriodEndDate,
  } = useShallowSelector(otherBusinessDetailsSelector);
  const dispatch = useDispatch();
  const { eidlAdvanceRadio } = values;

  useEffect(() => {
    if (isNil(eidlAdvanceRadio) || JSON.parse(eidlAdvanceRadio)) {
      return;
    }

    dispatch(changeAction('forgivenessPage5', 'eidlLoanNumber', null));
    dispatch(changeAction('forgivenessPage5', 'eidlAmount', null));
  }, [eidlAdvanceRadio]);

  const [coveredStart, coveredEnd] = getCoveredPeriodFromDates({
    standardCoveredPeriodStartDate, standardCoveredPeriodEndDate,
  });

  return (
    <Container className="other-business-details" form as="form" onSubmit={handleSubmit} noValidate>
      <Row>
        <Col form="full">
          <HeadingText size="sm" as="h2">
            Other business details from
            {` ${coveredStart} `}
            &ndash;
            {` ${coveredEnd}`}
          </HeadingText>
          <Divider secondary />
          {otherBusinessDetailsFields.map(([FieldComponent, fieldKey]) => (
            <FieldComponent
              className="input-field"
              error={syncErrors?.[fieldKey]}
              key={fieldKey}
              submitted={submitted}
              touched={fields?.[fieldKey]?.touched}
              value={values?.[fieldKey]}
            />
          ))}
        </Col>
      </Row>
      {children}
    </Container>
  );
};

OtherBusinessDetails.defaultProps = {
  submitted: false,
};

OtherBusinessDetails.propTypes = {
  children: PropTypes.element.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'forgivenessPage5',
})(OtherBusinessDetails);
