import React, { Component } from 'react';
import { compose } from 'redux';
import { push as pushAction } from 'connected-react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import resolve from '../services/route.service';
import routeList from '../constants/routes-lists';
import { isUserAuthenticated, isUserAuthorized, getToken } from '../lib/token';
import { createLoginPageMessageAction } from '../redux/actions/login/login-page-actions';
import routes from '../routes';
import withUrlParams from './with-url-params';
import getDisplayName from '../lib/get-display-name';

const routeWithCountryRegex = /\/[A-Za-z]{2}(\/.+)/g;

const ensureSafeRouting = (Page) => {
  class EnsureSafeRouting extends Component {
    static displayName = `EnsureSafeRouting(${getDisplayName(Page)})`;

    static propTypes = {
      country: PropTypes.string,
      createLoginPageMessage: PropTypes.func.isRequired,
      pathname: PropTypes.string,
      push: PropTypes.func.isRequired,
    };

    static defaultProps = {
      country: undefined,
      pathname: undefined,
    };

    componentDidMount() {
      const {
        country,
        createLoginPageMessage,
        pathname,
        push,
      } = this.props;
      const token = getToken();

      const routeWithCountryRegexResult = RegExp(routeWithCountryRegex).exec(pathname);

      // if an authorized user tries to access pages meant for special flows
      if (isUserAuthorized(token) && routeWithCountryRegexResult
          && (routeList.ROUTES_REQUIRING_SECRET_KEY.includes(routeWithCountryRegexResult[1])
          || routeList.ROUTES_REQUIRING_AUTHENTICATED_TOKEN.includes(routeWithCountryRegexResult[1]))
      ) {
        push(resolve(routes.summary.path, { country }));
        return;
      }

      // if an unauthorized and unauthenticated user tries to access pages meant for 'authenticated' token
      if (!isUserAuthorized(token)
          && !isUserAuthenticated(token)
          && routeWithCountryRegexResult
          && routeList.ROUTES_REQUIRING_AUTHENTICATED_TOKEN.includes(routeWithCountryRegexResult[1])
          && !routeList.ROUTES_ACCESSIBLE_BY_ANONYMOUS_USER.includes(routeWithCountryRegexResult[1])) {
        createLoginPageMessage('Your session has expired. Please log back in to continue.', 'CRITICAL');
        push(resolve(routes.login.path, { country }));
      }
    }

    render() {
      return (
        <Page {...this.props} />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      authorized: state.authorize.authorized,
      authenticated: state.authenticate.authenticated,
      pathname: state.router.location.pathname,
    };
  }

  return compose(
    withRouter,
    withUrlParams,
    connect(mapStateToProps, {
      createLoginPageMessage: createLoginPageMessageAction,
      push: pushAction,
    }),
  )(EnsureSafeRouting);
};

export default ensureSafeRouting;
