import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Col, Container, Link, Row,
} from '@swift-paypal/pp-react';
import constants from '../../constants/index';
import Title from '../common/title';
import Address from '../common/address';
import resolve from '../../services/route.service';
import routes from '../../routes';
import withUrlParams from '../../hocs/with-url-params';

const Business = (props) => {
  const {
    contactAccount, country, homePhone, businessWebAddress,
  } = props;
  const { account, contact } = contactAccount;

  const ContactUsLink = (
    <Link
      as={RouterLink}
      to={resolve(routes['contact-us'].path, {
        accountId: account.id,
        requestType: constants.contactUs.addressUpdate,
        country,
      })}
    >
      Update
    </Link>
  );

  return (
    <Container fluid>
      <Title text={account.name} />
      <Row className="left-gutter">
        <Col sm={12} md={6} className="address">
          <div className="vx_text-body-sm padding-bottom-sm personal-address"><i>Personal address</i></div>
          <Address
            line1={contact.street1}
            line2={contact.street2}
            city={contact.city}
            state={contact.state}
            zip={contact.zip}
            country={country}
          />
          {ContactUsLink}
          { homePhone
            && (
            <div>
              <div className="vx_text-body-sm padding-top-bottom-sm home-phone-number"><i>Home phone number</i></div>
              <div className="vx_text-body-md margin-bottom-xs">{homePhone}</div>
            </div>
            )}
        </Col>
        <Col sm={12} md={6} className="address">
          <div className="vx_text-body-sm padding-bottom-sm business-address"><i>Business address</i></div>
          <Address
            line1={account.street}
            city={account.city}
            state={account.state}
            zip={account.zip}
            country={country}
          />
          {ContactUsLink}
          { businessWebAddress
            && (
            <div>
              <div className="vx_text-body-sm padding-top-bottom-sm business-web-address"><i>Business web address</i></div>
              <div className="vx_text-body-md margin-bottom-xs word-break">{businessWebAddress}</div>
            </div>
            )}
        </Col>
      </Row>
    </Container>
  );
};

Business.propTypes = {
  contactAccount: PropTypes.shape({
    account: PropTypes.object,
    contact: PropTypes.object,
  }).isRequired,
  country: PropTypes.string,
  homePhone: PropTypes.string,
  businessWebAddress: PropTypes.string,
};

Business.defaultProps = {
  country: undefined,
  homePhone: undefined,
  businessWebAddress: undefined,
};

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(Business);
