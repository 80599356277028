import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Container } from '@swift-paypal/pp-react';
import PageTitle from '../../components/common/page-title';
import SecurePage from '../../hocs/secure-page';
import ChangePasswordFormComponent from '../../components/settings/password/change-password-form';
import Title from '../../components/common/title';
import '../../components/settings/password/change-password.less';
import {
  changePasswordAction,
  updateSettingsMessageAction,
  clearSettingsMessageAction,
} from '../../redux/actions/settings/settings-actions';
import { AlertTypes } from '../../components/common/alert';
import util from '../../util';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

class ChangePasswordPage extends Component {
  static displayName = displayNames.ChangePasswordPage;

  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    clearSettingsMessage: PropTypes.func.isRequired,
    country: PropTypes.string,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    resetForm: PropTypes.func.isRequired,
    updateSettingsMessage: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
  };

  static defaultProps = {
    messageBody: '',
    messageType: '',
    user: {},
    country: undefined,
  };

  componentWillUnmount() {
    const { clearSettingsMessage } = this.props;
    clearSettingsMessage();
  }

  submit = async (values) => {
    const { currentPassword, newPassword, confirmPassword } = values;

    const {
      changePassword,
      clearSettingsMessage,
      resetForm,
      updateSettingsMessage,
    } = this.props;

    util.blurOnSubmit();

    if (currentPassword === newPassword) {
      updateSettingsMessage('Please enter a different password.', AlertTypes.critical);
      return undefined;
    }
    clearSettingsMessage();

    resetForm();

    // must return promise for form submitting metadata to work
    return changePassword(currentPassword, newPassword, confirmPassword);
  };

  render() {
    const
      {
        messageBody,
        messageType,
        country,
      } = this.props;

    return (
      <div className="change-password">
        <Helmet>
          <title>Change LoanBuilder Password</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <Container fluid>
              <PageTitle text="Change LoanBuilder Password" />
              <Title text="Use the form below to confirm and change your password." />
              <ChangePasswordFormComponent
                messageBody={messageBody}
                messageType={messageType}
                country={country}
                onSubmit={this.submit}
              />
            </Container>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    bfes: state.accountItems.bfes,
    messageBody: state.settings.messageBody,
    messageType: state.settings.messageType,
    user: state.authorize.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (password, newPassword, confirmPassword) => {
      dispatch(changePasswordAction(password, newPassword, confirmPassword));
    },
    updateSettingsMessage: (body, type) => {
      dispatch(updateSettingsMessageAction(body, type));
    },
    resetForm: () => {
      dispatch(reset('change-password'));
    },
    clearSettingsMessage: () => {
      dispatch(clearSettingsMessageAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurePage(ChangePasswordPage));
