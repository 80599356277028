import React, { memo } from 'react';
import { Col, Row } from '@swift-paypal/pp-react';
import YesOrNoRadioField from '../../common/yes-or-no-radio-field';
import { fieldNames } from './field-constants';
import { useShallowSelector, fieldSelector } from '../questionnaire-selectors';
import { filterNonForgivenessAdvanceIds } from '../../features/forgivenessTileList/forgivenessTileList.utils';
import { PPP21_SECOND_DRAW } from '../../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';

const basePrompt = 'Have you already submitted your signed loan forgiveness application to the Lender for your First Draw PPP loan with SBA Loan Number';

const QuestionnaireQ5 = () => {
  const { questionnaireQ5 } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, questionnaireQ5);
  const { activeAdvanceId, advances } = useShallowSelector(
    ({ activeIds, entities }) => ({ activeAdvanceId: activeIds.advanceId, advances: entities.advances }),
  );

  const { firstDrawSbaLoanNumber, loanVersion } = advances.byId[activeAdvanceId] || {};
  const sbaAdvanceIds = filterNonForgivenessAdvanceIds(advances.allIds, advances.byId);

  if (!firstDrawSbaLoanNumber || loanVersion !== PPP21_SECOND_DRAW || sbaAdvanceIds.length > 1) {
    return null;
  }

  return (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={`${basePrompt} ${firstDrawSbaLoanNumber}?`}
          error={error}
          name={questionnaireQ5}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  );
};

export default memo(QuestionnaireQ5);
