import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Divider, BodyText, CaptionText } from '@swift-paypal/pp-react';
import './amount-option.less';

const AmountOptionText = ({ caption, label, value }) => (
  <div className="amount-option-text">
    <div className="row">
      <BodyText as="span" className="text">{label}</BodyText>
      <Divider className="dot-leader" secondary />
      <BodyText as="span" strong className="text value">{value}</BodyText>
    </div>
    <CaptionText as="div" className="row caption">
      {caption}
    </CaptionText>
  </div>
);

AmountOptionText.defaultProps = {
  caption: undefined,
};

AmountOptionText.propTypes = {
  caption: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default memo(AmountOptionText);
