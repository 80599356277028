import axios from 'axios';

export default {
  initialize,
};

function initialize(apiUrl) {
  axios.interceptors.request.use(null, (error) => errorHandler(error, apiUrl));
  axios.interceptors.response.use(null, (error) => errorHandler(error, apiUrl));
}

function errorHandler(error, apiUrl) {
  const loc = window.location;

  // if (!error.config.url.includes(apiUrl) || loc.href.includes('redirect')) return Promise.reject(error);
  if (
    // only check for errors between the api and the portal
    !error.config.url.includes(apiUrl)
    // only check for non-health-check requests, those are handled redux actions and components
    || error.config.url === apiUrl) {
    return Promise.reject(error);
  }

  // check for whether the api is down
  if (
    !error.response
    || error.request.status === 503
    || error.request.status === -1
    || error.description === 'Network Error' // for IE11 compatibility
  ) {
    const circularRedirectFromMaintenancePage = loc.pathname.includes('maintenance');
    if (!circularRedirectFromMaintenancePage) {
      const prefixes = loc.pathname.match(/^\/[A-Za-z]{2}\//g);
      let prefix = '/us/';
      if (prefixes && prefixes.length > 0) {
        [prefix] = prefixes;
      }
      loc.href = `${prefix}maintenance?redirect=${encodeURIComponent(loc.pathname)}`;
    }
  }
  return Promise.reject(error);
}
