import { createNumberMask } from 'redux-form-input-masks';
import businessEntityTypes from '../../../../constants/businessEntityTypes';

export const LOAN_AMOUNT_THRESHOLD = 50000;

export const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

export const excusedBusinessEntities = [
  businessEntityTypes.eligibleSelfEmployedIndividual,
  businessEntityTypes.independentContractor,
  businessEntityTypes.soleProprietorship,
];

export const numberMask = createNumberMask({ allowEmpty: true });

export const formName = 'sba3508SForm';

export const fieldNames = {
  attestToReductions: 'attestToReductions',
  coveredPeriodEndDate: 'coveredPeriodEndDate',
  currentEmployees: 'currentEmployees',
  receivedLargeFunding: 'receivedLargeFunding',
  reducedSalariesWagesEmployees: 'reducedSalariesWagesEmployees',
  requestedForgivenessAmount: 'requestedForgivenessAmount',
  reviewedAdjustedForgivenessAmount: 'reviewedAdjustedForgivenessAmount',
  payrollCosts: 'payrollCosts',
};

export const sfFieldNames = {
  employeesAtApplicationTime: 'employeesAtApplicationTime',
  ezAppQ6: 'ezAppQ6',
  forgivenessAmountAdjusted3508s: 'forgivenessAmountAdjusted3508s',
  forgivenessAmount3508s: 'forgivenessAmount3508s',
  payrollAmount3508s: 'payrollAmount3508s',
  reductionAttestation3508s: 'reductionAttestation3508s',
  standardCoveredPeriodEndDate: 'standardCoveredPeriodEndDate',
  wageReduction3508s: 'wageReduction3508s',
};
