import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionItem from './action-item/action-item';

const AccountActionItems = (props) => {
  const { stipulations, isRenewalEligible } = props;

  return (
    <>
      {stipulations && !!stipulations.length && stipulations.map((accountStipulation) => (
        <div className="action-items" key={accountStipulation.id}>
          <div data-account-stipulation className="list-items margin-top-xs margin-bottom-xs">
            {accountStipulation.stipulations && accountStipulation.stipulations
              .filter((stip) => {
                if (!stip.isRenewal) {
                  return true;
                }

                if (isRenewalEligible) {
                  return true;
                }

                return false;
              })
              .map((stipulation) => (
                <ActionItem
                  key={stipulation.index}
                  stipulation={stipulation}
                  renewFunc={props.renewFunc}
                  clearStipFunc={props.clearStipFunc}
                  opportunity={accountStipulation}
                />
              ))}
          </div>
        </div>
      ))}
    </>
  );
};

AccountActionItems.propTypes = {
  clearStipFunc: PropTypes.func,
  isRenewalEligible: PropTypes.bool,
  renewFunc: PropTypes.func,
  stipulations: PropTypes.arrayOf(PropTypes.object),
};

AccountActionItems.defaultProps = {
  clearStipFunc: undefined,
  isRenewalEligible: undefined,
  renewFunc: undefined,
  stipulations: undefined,
};

function mapStateToProps(state) {
  return {
    isRenewalEligible: state.isRenewalEligible,
  };
}

export default connect(mapStateToProps)(AccountActionItems);
