/*   Product related      */

export const PRODUCTS_REQUEST_PENDING = 'products_pending';
export const PRODUCTS_RECEIVED = 'products_received';
export const PRODUCTS_REQUEST_ERROR = 'products_request_error';

export const BFES_REQUEST_PENDING = 'bfes_pending';
export const BFES_RECEIVED = 'bfes_received';
export const BFES_REQUEST_ERROR = 'bfes_request_error';

export const PRODUCTS_BFES_REQUEST_PENDING = 'products_bfes_pending';
export const PRODUCTS_BFES_RECEIVED = 'products_bfes_received';
export const PRODUCTS_BFES_REQUEST_ERROR = 'products_bfes_request_error';

export const ACCOUNT_RENEWAL_PENDING = 'account_renewal_pending';
export const ACCOUNT_RENEWAL_RECEIVED = 'account_renewal_received';
export const ACCOUNT_RENEWAL_ERROR = 'account_renewal_error';

export const LOAN_REQUEST_PENDING = 'loan_request_pending';
export const LOAN_REQUEST_RECEIVED = 'loan_request_received';
export const LOAN_REQUEST_CLEAR = 'loan_request_clear';
export const LOAN_REQUEST_ERROR = 'loan_request_error';

export const TRANSACTIONS_REQUEST_PENDING = 'transactions_pending';
export const TRANSACTIONS_RECEIVED = 'transactions_received';
export const TRANSACTIONS_REQUEST_ERROR = 'transactions_request_error';

export const CONTACTS_REQUEST_PENDING = 'contacts_pending';
export const CONTACTS_REQUEST_RECEIVED = 'contacts_received';
export const CONTACTS_REQUEST_ERROR = 'contacts_request_error';
export const CONTACTS_REQUEST_CLEAR = 'contacts_request_clear';

export const CREATE_CONTACTS_REQUEST_PENDING = 'create_contacts_pending';
export const CREATE_CONTACTS_REQUEST_RECEIVED = 'create_contacts_received';
export const CREATE_CONTACTS_REQUEST_ERROR = 'create_contacts_error';

export const UPDATE_CONTACTS_REQUEST_PENDING = 'update_contacts_pending';
export const UPDATE_CONTACTS_REQUEST_RECEIVED = 'update_contacts_received';
export const UPDATE_CONTACTS_REQUEST_ERROR = 'update_contacts_received';

export const LOAN_AGREEMENT_STATUS_RECEIVED = 'loan_agreement_status_received';
export const LOAN_AGREEMENT_STATUS_REQUEST_ERROR = 'loan_agreement_status_request_error';
export const LOAN_AGREEMENT_STATUS_REQUEST_PENDING = 'loan_agreement_status_request_pending';
