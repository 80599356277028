import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  Field, reduxForm, formValueSelector, clearFields as clearFieldsAction,
} from 'redux-form';
import { required } from 'redux-form-validators';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import resolve from '../../../services/route.service';
import Title from '../../common/title';
import selectField from '../../../lib/forms/select-field';
import floatingLabelField from '../../../lib/forms/floating-label-field';
import routes from '../../../routes';
import withUrlParams from '../../../hocs/with-url-params';
import '../settings.less';

const customQuestionPrompt = 'Create your own security question';

export class ChangeSecurityQuestionsForm extends React.Component {
  static propTypes = {
    clearFields: PropTypes.func.isRequired,
    country: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    question1IsCustom: PropTypes.bool,
    question2IsCustom: PropTypes.bool,
    securityQuestions: PropTypes.shape({
      userQuestions: PropTypes.arrayOf(PropTypes.string),
      defaultQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    history: PropTypes.array.isRequired,
  };

  static defaultProps = {
    country: undefined,
    question1IsCustom: false,
    question2IsCustom: false,
  };

  static renderQuestions(name, securityQuestions) {
    return (
      <Field
        name={name}
        id={name}
        label="Question"
        component={selectField}
        className="vx_form-control form-control"
        validate={[required({ msg: 'Required' })]}
      >
        <>
          <option value="" disabled>Question</option>
          {/* first render default questions */}
          {securityQuestions.defaultQuestions.map((question) => (
            <option key={question} value={question}>
              {question}
            </option>
          ))}
          {/* then render any previous custom questions */}
          {securityQuestions.userQuestions && securityQuestions.userQuestions.map((question) => {
            if (securityQuestions.defaultQuestions.find((q) => q === question) || question === customQuestionPrompt) {
              return null;
            }

            return (
              <option key={question} value={question}>{question}</option>
            );
          })}
          {/* always render the create your question prompt last */}
          <option value={customQuestionPrompt}>{customQuestionPrompt}</option>
        </>
      </Field>
    );
  }

  static renderCustomQuestionField(name) {
    return (
      <Field
        name={name}
        manualSet
        label="Type a unique question"
        component={floatingLabelField}
        type="text"
        className="vx_form-control form-control"
        validate={[required({ msg: 'Required' })]}
      />
    );
  }

  static renderAnswerField(name) {
    return (
      <Field
        name={name}
        manualSet
        label="Answer"
        component={floatingLabelField}
        type="password"
        className="vx_form-control form-control do-not-record"
        validate={[required({ msg: 'Required' })]}
      />
    );
  }

  componentDidUpdate(prevProps) {
    const { clearFields, question1IsCustom, question2IsCustom } = this.props;

    if (question1IsCustom !== prevProps.question1IsCustom && !question1IsCustom) {
      clearFields(false, false, 'question1Custom');
    }

    if (question2IsCustom !== prevProps.question2IsCustom && !question2IsCustom) {
      clearFields(false, false, 'question2Custom');
    }
  }

  onClickCancelHandler(country) {
    const { history } = this.props;
    history.push(resolve(routes.settings.path, { country }));
  }

  render() {
    const {
      country,
      handleSubmit,
      securityQuestions,
      question1IsCustom,
      question2IsCustom,
    } = this.props;

    return (
      <div className="change-security-questions-form form">
        <form onSubmit={handleSubmit}>
          <Title text="Question 1" />
          <Row className="question-one">
            <Col xs={12} sm={10}>
              {securityQuestions && ChangeSecurityQuestionsForm.renderQuestions('question1', securityQuestions)}
              {question1IsCustom && ChangeSecurityQuestionsForm.renderCustomQuestionField('question1Custom')}
              {ChangeSecurityQuestionsForm.renderAnswerField('answer1')}
            </Col>
          </Row>
          <Title text="Question 2" />
          <Row className="question-two">
            <Col xs={12} sm={10}>
              {securityQuestions && ChangeSecurityQuestionsForm.renderQuestions('question2', securityQuestions)}
              {question2IsCustom && ChangeSecurityQuestionsForm.renderCustomQuestionField('question2Custom')}
              {ChangeSecurityQuestionsForm.renderAnswerField('answer2')}
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={5} md={4}>
              <Button type="submit" className="pp-react__button--block">
                Continue
              </Button>
            </Col>
            <Col xs={6} sm={5} md={4}>
              <Button secondary type="button" className="pp-react__button--block" onClick={() => this.onClickCancelHandler(country)}>
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    country: state.country.counrty,
    question1IsCustom: selector(state, 'question1') === customQuestionPrompt,
    question2IsCustom: selector(state, 'question2') === customQuestionPrompt,
  };
}

const ChangeSecurityQuestionsFormComponent = reduxForm({
  form: 'change-security-questions',
  initialValues: {
    question1Custom: undefined,
    question2Custom: undefined,
  },
})(ChangeSecurityQuestionsForm);

const selector = formValueSelector('change-security-questions');

export default compose(
  connect(
    mapStateToProps,
    { clearFields: clearFieldsAction },
  ),
  withRouter,
  withUrlParams,
)(ChangeSecurityQuestionsFormComponent);
