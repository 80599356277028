import { isIE } from 'react-device-detect';

const defaultRequestOptions = (ignoreRequestCount = false) => {
  const token = window.localStorage.getItem('token');
  const options = { headers: { Authorization: `JWT ${token}` } };
  if (ignoreRequestCount) {
    options.headers['Ignore-Request-Count'] = ignoreRequestCount;
  }
  if (isIE) {
    options.headers.Pragma = 'no-cache';
  }
  return options;
};

export default defaultRequestOptions;
