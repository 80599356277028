import React, { useContext, useEffect } from 'react';
import { Button, BodyText } from '@paypalcorp/pp-react';
import '../../payments.less';
import { useDispatch } from 'react-redux';
import { ResponsiveHeadingText } from '../../components/responsiveTypography';
import SuccessIcon from '../../../../static/images/icons/success@2x.png';
import displayNames from '../../../../constants/displayNames';
import { useShallowSelector, oneTimePaymentSelector } from '../../one-time-payment-selectors';
import { OneTimePaymentContext } from '../../OneTimePaymentContext';
import { PaymentComponentMapping } from '../../paymentComponentMappings';
import { getSelfServiceLoansAction } from '../../../../redux/actions/one-time-payment/one-time-payment';
import Currency from '../../../common/currency';

const PaymentConfirmation = () => {
  const dispatch = useDispatch();
  const {
    setIsPaymentModalOpen,
    selectedLoanAdvance,
    setVisibleComponent,
    resetSchedulePaymentState,
  } = useContext(OneTimePaymentContext);

  const { scheduledPaymentAmount } = useShallowSelector(oneTimePaymentSelector);

  const handleSuccessEvent = () => {
    setIsPaymentModalOpen(false);
  };

  useEffect(
    () => () => {
      // re-trigger queued trasaction api on close of success modal
      // to show the updated queued transactions in the dashboard table
      resetSchedulePaymentState();
      dispatch(getSelfServiceLoansAction(selectedLoanAdvance.Id));
    },
    [],
  );

  const handleAnotherPaymentAction = () => {
    setVisibleComponent(PaymentComponentMapping.MakeAPayment);
  };

  return (
    <div className="centered-container">
      <img src={SuccessIcon} alt="confirmation-logo" className="confirmation-logo" />
      <ResponsiveHeadingText mobile="sm" desktop="lg" className="confirmation-heading">
        You scheduled a one-time payment for
        {' '}
        <Currency
          amount={Number(scheduledPaymentAmount)}
          dataAutomationId="achPaymentConfirmationText"
        />
        .
      </ResponsiveHeadingText>
      <BodyText>
        Your payment will be posted within 1-2 business days of the scheduled payment date.
        <br />
        <br />
        Your autodraft payment/information will not change.
      </BodyText>
      <div className="btn-wrapper select-payment-btn">
        <Button size="lg" onClick={handleSuccessEvent}>
          Done
        </Button>
        <Button size="lg" tertiary onClick={handleAnotherPaymentAction}>
          Make another payment
        </Button>
      </div>
    </div>
  );
};

PaymentConfirmation.displayName = displayNames.PaymentConfirmation;
export default PaymentConfirmation;
