/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change as changeAction } from 'redux-form';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import ReactRouterPropTypes from 'react-router-prop-types';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  getBankInformation as getBankInformationAction,
  getRoutingNumberInstitution as getRoutingNumberInstitutionAction,
  saveBankInformation as saveBankInformationAction,
  getRoutingNumberList as getRoutingNumberListAction,
} from '../../redux/actions/application/verify-bank-accounts/verify-bank-accounts-actions';
import { getContactAccountsAction } from '../../redux/actions/settings/settings-actions';
import redirect from '../../lib/redirect';
import notificationUtil from '../../lib/notification-util';
import VerifyBankAccountsFormComponent from '../../components/verify-bank-accounts/verify-bank-accounts-form';
import constants from '../../constants';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

class VerifyBankAccountsPage extends Component {
  static displayName = displayNames.VerifyBankAccountsPage;

  static propTypes = {
    apiUrl: PropTypes.string,
    accountsPending: PropTypes.bool,
    bankInformation: PropTypes.shape({
      paymentDay: PropTypes.string,
    }),
    change: PropTypes.func.isRequired,
    country: PropTypes.string,
    depositInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    getBankInformation: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getRoutingNumberInstitution: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history,
    inFlightOpportunities: PropTypes.arrayOf(PropTypes.object),
    match: ReactRouterPropTypes.match,
    notifications: PropTypes.arrayOf(PropTypes.object),
    notificationsPending: PropTypes.bool,
    opportunityId: PropTypes.string,
    saveBankInfoError: PropTypes.bool,
    saveBankInformation: PropTypes.func.isRequired,
    saveBankInfoSuccess: PropTypes.bool,
    withdrawalInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    hasRouteNumberWarning: PropTypes.bool,
    getContactAccounts: PropTypes.func.isRequired,
    getRoutingNumberList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    apiUrl: undefined,
    accountsPending: false,
    bankInformation: undefined,
    country: undefined,
    depositInstitution: undefined,
    history: undefined,
    inFlightOpportunities: undefined,
    match: undefined,
    notifications: undefined,
    notificationsPending: false,
    opportunityId: undefined,
    saveBankInfoError: undefined,
    saveBankInfoSuccess: false,
    withdrawalInstitution: undefined,
    hasRouteNumberWarning: false,
  };

  constructor(props) {
    super(props);
    this.asyncValidate = this.asyncValidate.bind(this);
  }

  async componentDidMount() {
    const {
      apiUrl,
      getBankInformation,
      getProductsAndBfes,
      opportunityId,
      getContactAccounts,
      getRoutingNumberList,
    } = this.props;
    getBankInformation(opportunityId);
    getProductsAndBfes();
    await getContactAccounts(apiUrl);
    await getRoutingNumberList();
  }

  getStipulationInfo() {
    const { inFlightOpportunities, opportunityId, notifications } = this.props;
    const stipulations = notificationUtil.getAccountStipulations(opportunityId, inFlightOpportunities, notifications);

    let currentStipulation;

    if (stipulations) {
      currentStipulation = stipulations.find((x) => x.isBankAccountVerification);
    }

    return currentStipulation;
  }

  submit = (values) => {
    const {
      bankInformation,
      depositInstitution,
      match,
      saveBankInformation,
      withdrawalInstitution,
    } = this.props;
    const { opportunityId } = match.params;

    if (depositInstitution === constants.bank.INVALID_ROUTING_NUMBER
      || withdrawalInstitution === constants.bank.INVALID_ROUTING_NUMBER
      || depositInstitution === constants.bank.ROUTING_NUMBER_PENDING
      || withdrawalInstitution === constants.bank.ROUTING_NUMBER_PENDING) {
      return;
    }
    const submitDeposit = values[constants.bank.DEPOSIT].routingNumber !== constants.bank.UNCHANGED;
    const submitWithdrawal = values[constants.bank.WITHDRAWAL].routingNumber !== constants.bank.UNCHANGED;

    saveBankInformation(opportunityId, {
      [constants.bank.WITHDRAWAL]: submitWithdrawal
        ? {
          changedInPortal: true,
          routingNumber: values[constants.bank.WITHDRAWAL].routingNumber,
          accountNumber: values[constants.bank.WITHDRAWAL].accountNumber,
        } : { changedInPortal: false },
      [constants.bank.DEPOSIT]: submitDeposit
        ? {
          changedInPortal: true,
          routingNumber: values[constants.bank.DEPOSIT].routingNumber,
          accountNumber: values[constants.bank.DEPOSIT].accountNumber,
        } : { changedInPortal: false },
      paymentDay: values.paymentDay === bankInformation.paymentDay
        ? { changedInPortal: false }
        : { changedInPortal: true, day: values.paymentDay },
    });
  };

  validateOpportunityAndStipulation() {
    const {
      accountsPending,
      country,
      history,
      inFlightOpportunities,
      match,
      notifications,
      notificationsPending,
      saveBankInfoSuccess,
      saveBankInfoError,
    } = this.props;
    const { opportunityId } = match.params;

    if (saveBankInfoSuccess) {
      const currentStipulation = this.getStipulationInfo();
      const RoutePayload = {
        country,
        successStipulationName: currentStipulation.name,
        successStipulationGroupName: currentStipulation.groupName,
      };

      heapService.markUserStipulationAsCompleted(opportunityId, currentStipulation.groupName);
      history.push(resolve(routes.summary.path, RoutePayload));
      return;
    }

    if (saveBankInfoError) {
      return;
    }

    if (opportunityId && !notificationsPending
      && !accountsPending && inFlightOpportunities && notifications) {
      // validate opportunity with inflight opportunities
      // validate stipulation for forms
      const currentStipulation = this.getStipulationInfo();
      if (currentStipulation) {
        return;
      }
      redirect(history, match);
    }
  }

  async asyncValidate(values, dispatch, formAsyncErrors, field) {
    const { getRoutingNumberInstitution } = this.props;
    if (field === `${constants.bank.WITHDRAWAL}.routingNumber`) {
      await getRoutingNumberInstitution(values[constants.bank.WITHDRAWAL].routingNumber,
        constants.bank.WITHDRAWAL);
    }
    else if (field === `${constants.bank.DEPOSIT}.routingNumber`) {
      await getRoutingNumberInstitution(values[constants.bank.DEPOSIT].routingNumber,
        constants.bank.DEPOSIT);
    }
  }

  render() {
    const {
      bankInformation,
      accountsPending,
      notificationsPending,
      notifications,
      withdrawalInstitution,
      depositInstitution,
      change,
      saveBankInfoError,
      saveBankInfoSuccess,
      hasRouteNumberWarning,
    } = this.props;
    const pageTitle = 'Bank account information';
    const initialValues = bankInformation ? {
      paymentDay: bankInformation.paymentDay,
      [constants.bank.DEPOSIT]: {
        routingNumber: constants.bank.UNCHANGED,
        accountNumber: constants.bank.UNCHANGED,
      },
      [constants.bank.WITHDRAWAL]: {
        routingNumber: constants.bank.UNCHANGED,
        accountNumber: constants.bank.UNCHANGED,
      },
    } : {};
    return (
      <div>
        { this.validateOpportunityAndStipulation() }
        { (saveBankInfoSuccess || (!accountsPending && !notificationsPending && notifications)) && (
          <>
            <Helmet>
              <title>Bank Verification</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <div>
                  <PageTitle text={saveBankInfoSuccess ? 'Success!' : pageTitle} />
                  {bankInformation && !saveBankInfoSuccess
                  && (
                  <VerifyBankAccountsFormComponent
                    bankInformation={bankInformation}
                    initialValues={initialValues}
                    asyncValidate={this.asyncValidate}
                    withdrawalInstitution={withdrawalInstitution}
                    depositInstitution={depositInstitution}
                    saveBankInfoError={saveBankInfoError}
                    hasRouteNumberWarning={hasRouteNumberWarning}
                    change={change}
                    onSubmit={this.submit}
                  />
                  )}
                </div>
              )}
              sidebarElement={<></>}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    bfes: state.accountItems.bfes,
    inFlightOpportunities: state.accountItems.inFlightOpportunities,
    accountsPending: state.accountItems.accountsPending,
    notificationsPending: state.notifications.notificationsPending,
    notifications: state.notifications.stipulations,
    bankInformation: state.getBankInformation.bankInformation,
    withdrawalInstitution: state.getRoutingInstitution.withdrawalInstitution,
    depositInstitution: state.getRoutingInstitution.depositInstitution,
    saveBankInfoError: state.saveBankInformation.error,
    saveBankInfoSuccess: state.saveBankInformation.success,
    hasRouteNumberWarning: state.getRoutingInstitution?.hasRouteNumberWarning,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProductsAndBfes: (...args) => {
      dispatch(getProductsAndBfesAction(...args));
    },
    getBankInformation: (...args) => {
      dispatch(getBankInformationAction(...args));
    },
    getRoutingNumberInstitution: (...args) => {
      dispatch(getRoutingNumberInstitutionAction(...args));
    },
    change: (field, value) => {
      dispatch(changeAction('verify-bank-accounts', field, value));
    },
    saveBankInformation: (...args) => {
      dispatch(saveBankInformationAction(...args));
    },
    getContactAccounts: (...args) => {
      dispatch(getContactAccountsAction(...args));
    },
    getRoutingNumberList: (...args) => {
      dispatch(getRoutingNumberListAction(...args));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurePage(VerifyBankAccountsPage));
