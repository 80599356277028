import opportunityService from '../../../services/opportunity.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './stipulations-action-types';

export default asyncAuthorizedActionCreator(
  {
    pending: actions.STIPULATIONS_REQUEST_PENDING,
    complete: actions.STIPULATIONS_REQUEST_RECEIVED,
    error: actions.STIPULATIONS_REQUEST_ERROR,
  },
  (oppId, country) => (
    _dispatch,
    getState,
  ) => opportunityService.getStipulations(getState().config.apiUrl, oppId, country.toUpperCase()),
);

export const fulfillStipulationAction = asyncAuthorizedActionCreator(
  {
    pending: actions.FULFILL_STIPULATION_PENDING,
    complete: actions.FULFILL_STIPULATION_RECEIVED,
    error: actions.FULFILL_STIPULATION_ERROR,
  },
  (opportunityId, stipulationId) => (_dispatch, getState) => {
    const { config } = getState();
    return opportunityService.fulfillStipulation(config.apiUrl, opportunityId, stipulationId);
  },
);

export const getNewStipulationFlagAction = asyncAuthorizedActionCreator(
  {
    pending: actions.NEW_STIPULATION_FLAG_PENDING,
    complete: actions.NEW_STIPULATION_FLAG_RECEIVED,
    error: actions.NEW_STIPULATION_FLAG_ERROR,
  },
  (opportunityId) => (_dispatch, getState) => {
    const { config } = getState();
    return opportunityService.getNewStipulationFlag(config.apiUrl, opportunityId);
  },
);

export const clearFulfillStipulationAction = () => ({
  type: actions.FULFILL_STIPULATION_CLEAR,
});
