import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getContactNotificationsAction } from '../../redux/actions/notifications/notifications-actions';
import withUrlParams from '../../hocs/with-url-params';
import LoggedInHeader from './logged-in-header';
import LoggedOutHeader from './logged-out-header';
import './header.less';

class Header extends React.Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    accountsPending: PropTypes.bool,
    authorized: PropTypes.bool,
    country: PropTypes.string,
    getContactNotifications: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({}),
  };

  static defaultProps = {
    accounts: undefined,
    accountsPending: false,
    authorized: false,
    country: undefined,
    stipulations: undefined,
    user: undefined,
  };

  async componentDidMount() {
    const {
      accounts,
      accountsPending,
      getContactNotifications,
      getProductsAndBfes,
      location,
      stipulations,
    } = this.props;
    if (!accounts && !accountsPending) {
      await getProductsAndBfes();
    }
    if (!stipulations) {
      getContactNotifications(location);
    }
  }

  render() {
    const {
      country,
      authorized,
      location,
      user,
    } = this.props;
    return authorized && !location.pathname.includes('/maintenance')
      ? <LoggedInHeader user={user} />
      : <LoggedOutHeader country={country} />;
  }
}

const mapStateToProps = (state) => ({
  authorized: state.authorize.authorized,
  user: state.authorize.user,
  accounts: state.accountItems.accounts,
  accountsPending: state.accountItems.accountsPending,
  stipulations: state.notifications.stipulations,
  location: state.router.location,
});

export default compose(
  connect(mapStateToProps, {
    getProductsAndBfes: getProductsAndBfesAction,
    getContactNotifications: getContactNotificationsAction,
  }),
  withRouter,
  withUrlParams,
)(Header);
export const HeaderComponent = Header;
