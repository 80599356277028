import routes from '../../../routes';

export const FORGIVENESS_SUMMARY_PATH = routes['forgiveness-summary'].path;
export const US_COUNTRY_CODE = 'us';
export const RedirectParams = {
  path: FORGIVENESS_SUMMARY_PATH,
  additionalParams: {
    country: US_COUNTRY_CODE,
  },
};
