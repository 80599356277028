import React from 'react';
import PropTypes from 'prop-types';
import { Container, Divider } from '@swift-paypal/pp-react';
import { StipulationItem } from '../stipulationItem';
import { CreditStipulationActionRoutes } from '../stipulations.constants';
import './stipulationList.less';

export const StipulationList = ({ activeStipulations, isBLNStip = false }) => {
  const filteredActiveStipulations = isBLNStip
    // eslint-disable-next-line max-len
    ? activeStipulations.filter(({ stipulationMap }) => stipulationMap.name in CreditStipulationActionRoutes) : activeStipulations;

  return (
    <Container className="stipulations-list" fluid>
      {filteredActiveStipulations.map(({ id }, index) => (
        <React.Fragment key={id}>
          <StipulationItem stipulationId={id} />
          {(index < filteredActiveStipulations.length - 1) && (
            <Divider className="stipulations-list__divider" secondary />
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

StipulationList.propTypes = {
  activeStipulations: PropTypes.arrayOf(PropTypes.object).isRequired,
  isBLNStip: PropTypes.bool.isRequired,
};
