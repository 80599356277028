import React from 'react';

const SBAForm3508TooltipText = () => (
  <>
    <p>
      The
      {' '}
      <strong>Covered Period</strong>
      {' '}
      is either the 24-week (168-day) period beginning on your loan disbursement date or,
      only if you received your loan before Jun 5, 2020, the 8-week (56-day) period beginning
      on your PPP Loan disbursement date, whichever you choose.
    </p>
    <p>
      If your business uses a biweekly (or more frequent) payroll schedule, you may elect to use
      the
      {' '}
      <strong>Alternative Payroll Covered Period</strong>
      , which is the 24-week period or, only if you received your loan before Jun 5, 2020, the
      8-week period, whichever you choose, starting on the first day of your first pay period
      after your PPP Loan disbursement date.
    </p>
    <p>
      Using the 24-week covered period may help maximize your loan forgiveness and we encourage
      you to evaluate which of the available covered periods work best for you. See the PPP Loan
      Forgiveness Application Form 3508EZ Instructions for more info.
    </p>
  </>
);

export default SBAForm3508TooltipText;
