import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from '@swift-paypal/pp-react';

const PppLoanInfoLink = ({ loanbuilderUrl }) => (
  <Link
    className="pp-react__link--inline"
    data-automation-id="pppLoanInfoLink"
    href={`${loanbuilderUrl}/ppp-loan-info`}
    target="_blank"
    rel="noopener noreferrer"
  >
    Frequently Asked Questions
  </Link>
);

PppLoanInfoLink.propTypes = {
  loanbuilderUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  loanbuilderUrl: state.config.loanbuilderUrl,
});

export default connect(mapStateToProps)(PppLoanInfoLink);
