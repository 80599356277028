import moment from 'moment';
import { isIE, isSafari } from 'react-device-detect';
import { date, required } from 'redux-form-validators';
import errors from '../../constants/errors';
import { DateFieldConstants, NATIVE_DATE_FORMAT, NORMALIZED_DATE_FORMAT } from '../../constants/date-field-constants';
import { normalizeDate } from './normalizations';

export const getDateValidatorBounds = (baseDate, validRangeStartOffset, validRangeEndOffset) => {
  const validRangeLowerBound = moment(baseDate).add(validRangeStartOffset, 'days').toDate();
  const validRangeUpperBound = moment(baseDate).add(validRangeEndOffset, 'days').toDate();
  return { validRangeLowerBound, validRangeUpperBound };
};

const dateBoundsValidator = (value, validRangeLowerBound, validRangeUpperBound) => {
  const format = isSafari || isIE ? NORMALIZED_DATE_FORMAT : NATIVE_DATE_FORMAT;
  if (value && !moment(value, format.toUpperCase()).isBetween(validRangeLowerBound, validRangeUpperBound)) {
    return errors.dateBoundsError;
  }
  return undefined;
};

export const getDateFieldAttributesByDevice = (dateFirstWireIssued) => {
  const { validRangeLowerBound, validRangeUpperBound } = getDateValidatorBounds(
    dateFirstWireIssued,
    DateFieldConstants.DaysUntilCoveredEndDateStart,
    DateFieldConstants.DaysUntilCoveredEndDateEnd,
  );

  const dateFormatValidationRules = {
    format: NATIVE_DATE_FORMAT,
    msg: {
      format: errors.invalidDateFormat,
    },
  };

  let normalize;
  let type = 'date';

  if (isSafari || isIE) {
    dateFormatValidationRules.format = NORMALIZED_DATE_FORMAT;
    normalize = normalizeDate;
    type = 'tel';
  }

  return {
    normalize,
    type,
    validate: [
      required({ msg: errors.requiredField }),
      date(dateFormatValidationRules),
      (value) => dateBoundsValidator(value, validRangeLowerBound, validRangeUpperBound),
    ],
  };
};
