import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import log from 'loglevel';
import resolve from '../services/route.service';
import SecurePage from '../hocs/secure-page';
import PageTitle from '../components/common/page-title';
import uploadUtils from '../lib/document-upload-utils';
import { getContactNotificationsAction } from '../redux/actions/notifications/notifications-actions';
import { stipulationGroupsDescriptions } from '../constants/upload-document';
import UploadMultipleDocumentsComponent from '../components/upload-multiple-documents/upload-multiple-documents';
import {
  clearUploadErrorAction, clearUploadPendingAction,
  uploadBatchDocumentsAction,
} from '../redux/actions/upload-document/upload-document-actions';
import routes from '../routes';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class UploadDocumentGroupPage extends Component {
  static displayName = displayNames.UploadDocumentGroupPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    getContactNotifications: PropTypes.func.isRequired,
    groupName: PropTypes.string,
    location: ReactRouterPropTypes.location.isRequired,
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    stipulationsPending: PropTypes.bool,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    response: PropTypes.shape({
      params: PropTypes.object,
    }),
  };

  static defaultProps = {
    country: undefined,
    groupName: undefined,
    opportunityId: undefined,
    stipulations: undefined,
    stipulationsPending: false,
    uploadPending: true,
    uploadError: false,
    response: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { groupedStipulations: null };
  }

  componentDidMount() {
    const {
      getContactNotifications,
      location,
      stipulations,
      stipulationsPending,
    } = this.props;
    if (!stipulations && !stipulationsPending) {
      getContactNotifications(location);
    }
  }

  componentDidUpdate() {
    const {
      uploadPending,
      uploadError,
    } = this.props;

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
    }
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  getApplicableStipulations() {
    const {
      stipulations, opportunityId, groupName,
    } = this.props;

    return this.filterStipulations(stipulations, opportunityId, groupName);
  }

  filterStipulations = (stipulations, opportunityId, groupName, updateState = false) => {
    const { groupedStipulations } = this.state;
    if (groupedStipulations) {
      return groupedStipulations;
    }
    const filteredStipulations = stipulations && stipulations.length ? stipulations
      .filter((stip) => stip.opportunityId === opportunityId && stip.groupName === groupName) : null;
    if (updateState) {
      this.setState({ groupedStipulations: filteredStipulations });
    }
    return filteredStipulations;
  };

  submit = (files) => {
    const {
      opportunityId,
      uploadBatchDocuments,
    } = this.props;
    const groupedStipulations = this.getApplicableStipulations();
    const payloads = [];
    files.forEach((file, i) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, groupedStipulations[i].type));
      }
    });
    uploadBatchDocuments(payloads);
  };

  redirectToSummaryConfirmation() {
    const { country, groupName, push } = this.props;
    const RoutePayload = {
      country,
      successStipulationGroupName: groupName,
    };
    push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      stipulations, uploadPending, uploadError, response, groupName,
    } = this.props;
    const groupedStipulations = this.getApplicableStipulations();
    const stipulationGroupsDescriptionResolved = stipulationGroupsDescriptions[groupName]
      ? stipulationGroupsDescriptions[groupName] : stipulationGroupsDescriptions.Default;

    if (uploadError) {
      log.error(`response: ${response} uploadError: ${uploadError}`);
    }

    if (!stipulations || !stipulations.length) {
      return null;
    }

    return (
      <div>
        <Helmet>
          <title>{groupName}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <PageTitle text={groupName} />
              <UploadMultipleDocumentsComponent
                submit={this.submit}
                uploadError={uploadError}
                uploadPending={uploadPending}
                response={response}
                groupDescription={stipulationGroupsDescriptionResolved}
                stipulations={groupedStipulations}
              />
            </div>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    stipulations: state.notifications.flattenedStipulations,
    stipulationsPending: state.notifications.notificationsPending,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
    response: state.uploadDocument.response,
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getContactNotifications: (location) => dispatch(getContactNotificationsAction(location)),
    uploadBatchDocuments: (uploads) => dispatch(uploadBatchDocumentsAction(uploads)),
    clearUploadError: () => dispatch(clearUploadErrorAction()),
    clearUploadPending: () => dispatch(clearUploadPendingAction()),
    push: (path) => dispatch(pushAction(path)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  SecurePage,
)(UploadDocumentGroupPage);
