import React from 'react';
import PropTypes from 'prop-types';
import './paypalBankIcon.less';

const PaypalBankIcon = ({ bank }) => {
  const generateBankBrandIdentifier = (bankName) => {
    if (!bankName) {
      return 'generic_bank';
    }

    return bankName
      .replace(/[.,]/g, '')
      .replace(/\s/g, '_')
      .toLowerCase();
  };
  const bankToCssIdentifier = generateBankBrandIdentifier(bank);

  return <div className={`bank-icon ${bankToCssIdentifier}`} />;
};

PaypalBankIcon.defaultProps = {
  bank: null,
};

PaypalBankIcon.propTypes = {
  bank: PropTypes.string,
};

export default PaypalBankIcon;
