import React, { memo } from 'react';
import {
  BodyText, Col, Row, TextInput,
} from '@swift-paypal/pp-react';
import { Field } from 'redux-form';
import { numericality, required } from 'redux-form-validators';
import errors from '../../../../constants/errors';
import { useShallowSelector } from '../../../../hooks';
import { fieldSelector } from '../sba3508SForm.selectors';
import { fieldNames, numberMask } from './sba3508SFormFields.constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const CurrentEmployeesField = () => {
  const fieldName = fieldNames.currentEmployees;
  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  return (
    <Row>
      <Col form="full">
        <BodyText as="label" htmlFor={fieldName}>
          Employees at time of Forgiveness Application
        </BodyText>
        <Field
          component={MappedInput}
          errorText={touched ? error : undefined}
          name={fieldName}
          type="tel"
          validate={[
            required({ msg: errors.requiredField }),
            numericality({ '>': 0, msg: { '>': 'Total number must be at least 1' } }),
          ]}
          value={value}
          {...numberMask}
        />
      </Col>
    </Row>
  );
};

export default memo(CurrentEmployeesField);
