import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@swift-paypal/pp-react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import routes from '../../../routes';
import resolve from '../../../services/route.service';

const LoanSummaryActivityLink = ({ loanId }) => {
  const { country } = useParams();

  return (
    <Link
      as={RouterLink}
      className="pp-react__link--inline"
      to={resolve(routes.activity.path, {
        country,
        loanId,
      })}
    >
      See Activity
    </Link>
  );
};

LoanSummaryActivityLink.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanSummaryActivityLink;
