import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import YesOrNoRadioField from '../../../common/yes-or-no-radio-field';
import { fieldNames } from './sba3508SFormFields.constants';
import { getReducedSalariesFieldVisibility } from '../sba3508SForm.utils';
import {
  fieldSelector,
  forgivenessAmountSelector,
  opportunitySelector,
} from '../sba3508SForm.selectors';

const content = 'Have you reduced the annual salaries, hourly wages, average paid hours'
  + ' or your number of employees between Jan 1, 2020 and the end of your Covered Period?';

const ReducedSalariesWagesEmployees = () => {
  const {
    reducedSalariesWagesEmployees: fieldName,
    receivedLargeFunding,
  } = fieldNames;
  const { opportunityId } = useParams();
  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);
  const { businessEntityType, numberOfEmployees } = useShallowSelector(opportunitySelector, opportunityId) || {};
  const largeFunding = useShallowSelector(fieldSelector, receivedLargeFunding);
  const pppLoanAmount = useShallowSelector(forgivenessAmountSelector);

  const visibilityContingency = largeFunding?.registered && largeFunding?.value === 'true';
  const isFieldVisible = getReducedSalariesFieldVisibility(
    pppLoanAmount,
    businessEntityType,
    numberOfEmployees,
    visibilityContingency,
  );

  return isFieldVisible ? (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={content}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  ) : null;
};

export default memo(ReducedSalariesWagesEmployees);
