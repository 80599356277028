import {
  addLoginPageMessageCleared,
  addLoginPageMessageCreated,
} from './login-page-action-types';

// eslint-disable-next-line operator-linebreak
export const createLoginPageMessageAction =
  (messageBody, messageType) => (dispatch) => dispatch(addLoginPageMessageCreated(messageBody, messageType));

export const clearLoginPageMessageAction = () => (dispatch) => dispatch(addLoginPageMessageCleared());
