import React from 'react';
import AttestToReductionsField from './attestToReductionsField';
import CoveredPeriodEndDateField from './coveredPeriodEndDateField';
import CurrentEmployeesField from './currentEmployeesField';
import { PayrollCostsField } from './payrollCostsField';
import ReceivedLargeFundingField from './receivedLargeFundingField';
import ReducedSalariesWagesEmployeesField from './reducedSalariesWagesEmployeesField';
import RequestedForgivenessAmountField from './requestedForgivenessAmountField';
import ReviewedAdjustedForgivenessAmountField from './reviewedAdjustedForgivenessAmountField';

export const Sba3508SFormFields = () => (
  <>
    <CurrentEmployeesField />
    <CoveredPeriodEndDateField />
    <PayrollCostsField />
    <RequestedForgivenessAmountField />
    <ReceivedLargeFundingField />
    <ReducedSalariesWagesEmployeesField />
    <AttestToReductionsField />
    <ReviewedAdjustedForgivenessAmountField />
  </>
);
