import CONFIG_SAVED, { CONFIG_VALUES_UPDATED } from '../actions/config/config-action-types';

function config(state = {}, action) {
  if (action.type === CONFIG_VALUES_UPDATED) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type !== CONFIG_SAVED) {
    return state;
  }

  return action.payload;
}

export default config;
