import resolve from '../services/route.service';
import routes from '../routes';

// by default redirect to summary page
export default (history, match, target = routes.summary.path, additionalParams = undefined) => {
  let { country } = match.params;
  if (!country) {
    country = 'us';
  }

  const RoutePayload = { country };
  if (additionalParams) {
    Object.assign(RoutePayload, additionalParams);
  }

  history.push(resolve(target, RoutePayload));
};
