export const forgivenessSummarySelector = ({
  activeIds, entities, stipulations,
}) => {
  const forgiveness = entities.loanForgiveness.byId[activeIds.forgivenessId];

  return {
    activeOpportunityId: activeIds.opportunityId,
    applicationVersionSelected: forgiveness?.applicationVersionSelected,
    loanVersion: forgiveness?.loanInformation?.loanVersion,
    stage: forgiveness?.stage,
    stipulations: stipulations?.allStipulations,
  };
};
