import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@swift-paypal/pp-react';

const bytesInMb = 1024 * 1024;

const FileQueueSectionComponent = ({ queuedFiles, removeFileFunc }) => (
  <div className="file-queue-section">
    <ul>
      {queuedFiles.map((file, index, list) => (
        <li key={file.name} className={`item ${index === list.length - 1 ? '' : 'not-last-item'}`}>
          <div className="item__name" title={file.name}>
            {file.name}
          </div>
          <span className="item__size">
            {`(${+(file.size / bytesInMb).toFixed(1)}MB)`}
          </span>
          <button className="button-link item__remove-btn" onClick={() => removeFileFunc(file)} tabIndex="0" type="button">
            <Icon className="remove-btn__icon" name="trash-alt" />
          </button>
        </li>
      ))}
    </ul>
  </div>
);

FileQueueSectionComponent.propTypes = {
  queuedFiles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  })).isRequired,
  removeFileFunc: PropTypes.func.isRequired,
};

export default FileQueueSectionComponent;
