import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { BodyText, TextInput } from '@swift-paypal/pp-react';
import { createNumberMask } from 'redux-form-input-masks';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const quotientMask = createNumberMask({
  allowEmpty: true,
  decimalPlaces: 2,
  locale: 'en-US',
});

const AverageFteField = ({
  box,
  error,
  submitted,
  table,
  touched,
}) => (
  <>
    <BodyText as="label" htmlFor={`averageFteTable${table}`}>
      Average FTE from
      {` Table ${table} `}
      &ndash;
      {` Box ${box}`}
    </BodyText>
    <Field
      key={`average-fte-table-${table}`}
      name={`averageFteTable${table}`}
      type="tel"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      validate={[
        required({ msg: `Enter value from Schedule A Worksheet, Table ${table} - Box ${box}` }),
      ]}
      {...quotientMask}
    />
  </>
);

AverageFteField.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
};

AverageFteField.propTypes = {
  box: PropTypes.number.isRequired,
  error: PropTypes.string,
  submitted: PropTypes.bool,
  table: PropTypes.number.isRequired,
  touched: PropTypes.bool,
};

export default memo(AverageFteField);
