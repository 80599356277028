import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import { Tooltip } from '@swift-paypal/pp-react';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import OwnershipComponent from '../../components/ownership/ownership';

import {
  clearSelectedOpportunityAction,
  getAcceptanceAction,
  getAttestationAction,
  getContactsAction,
  getOwnersAction,
  getTermsAndConditionsAction,
  setControllingManagerAction,
  saveBusinessRepresentative as saveBusinessRepresentativeAction,
  saveAdditionalSignersAction,
  clearAdditionalSignersFulfilledAction,
  getPossibleSignersAction,
} from '../../redux/actions/application/ownership/ownership-actions';
import AdditionalSignersFormComponent from '../../components/ownership/additional-signers';
import notificationUtil from '../../lib/notification-util';
import redirect from '../../lib/redirect';
import constants from '../../constants';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

const additionalSignersStipulation = 'AdditionalSigners';

class OwnershipPage extends Component {
  static displayName = displayNames.OwnershipPage;

  static propTypes = {
    acceptance: PropTypes.arrayOf(PropTypes.object),
    accountsPending: PropTypes.bool,
    additionalSignersFulfilled: PropTypes.bool,
    attestation: PropTypes.shape({
      id: PropTypes.string,
    }),
    canStartAdditionalSigners: PropTypes.bool,
    clearAdditionalSignersFulfilled: PropTypes.func.isRequired,
    clearSelectedOpportunity: PropTypes.func.isRequired,
    country: PropTypes.string,
    getAcceptance: PropTypes.func.isRequired,
    getAttestation: PropTypes.func.isRequired,
    getContacts: PropTypes.func.isRequired,
    getOwners: PropTypes.func.isRequired,
    getPossibleSigners: PropTypes.func.isRequired,
    getTermsAndConditions: PropTypes.func.isRequired,
    groupName: PropTypes.string,
    history: ReactRouterPropTypes.history.isRequired,
    inFlightOpportunities: PropTypes.arrayOf(PropTypes.object),
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.object),
    notificationsPending: PropTypes.bool,
    opportunityId: PropTypes.string,
    owners: PropTypes.arrayOf(PropTypes.object),
    ownershipError: PropTypes.bool,
    ownershipFulfilled: PropTypes.bool,
    ownershipReceived: PropTypes.bool,
    saveAdditionalSigners: PropTypes.func.isRequired,
    saveBusinessRepresentative: PropTypes.func.isRequired,
    setControllingManager: PropTypes.func.isRequired,
    stipulationName: PropTypes.string,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    stipulationType: PropTypes.string,
    termsAndConditions: PropTypes.shape({
      id: PropTypes.string,
    }),
  };

  static defaultProps = {
    acceptance: undefined,
    accountsPending: false,
    additionalSignersFulfilled: false,
    attestation: undefined,
    canStartAdditionalSigners: false,
    country: undefined,
    groupName: undefined,
    inFlightOpportunities: undefined,
    notifications: undefined,
    notificationsPending: false,
    opportunityId: undefined,
    owners: undefined,
    ownershipError: undefined,
    ownershipFulfilled: false,
    ownershipReceived: false,
    stipulationName: undefined,
    stipulations: undefined,
    stipulationType: undefined,
    termsAndConditions: undefined,
  };

  async componentDidMount() {
    const {
      country,
      getAcceptance,
      getAttestation,
      getContacts,
      getOwners,
      getTermsAndConditions,
      opportunityId,
    } = this.props;

    getOwners(opportunityId);
    getAcceptance();
    getAttestation();
    getContacts();
    getTermsAndConditions(country);
  }

  componentWillUnmount() {
    const {
      clearSelectedOpportunity,
      clearAdditionalSignersFulfilled,
    } = this.props;

    clearSelectedOpportunity();
    clearAdditionalSignersFulfilled();
  }

  setControllingManager = (controllingManager) => {
    const { setControllingManager } = this.props;
    setControllingManager(controllingManager);
  };

  getCurrentStipulation() {
    const { opportunityId, stipulations, stipulationType } = this.props;

    if (stipulations) {
      let currentStipulation = null;
      stipulations.forEach((stipulation) => {
        const applications = stipulation.applications.filter((application) => application.id === opportunityId);
        if (applications && applications.length) {
          const applicationStipulations = _.head(applications).stipulations;
          currentStipulation = _.head(_.filter(applicationStipulations,
            (stip) => stip.type === stipulationType));
        }
      });
      return currentStipulation || null;
    }
    return null;
  }

  saveBusinessRepresentative = async ({
    contacts,
    saveAcceptance = false,
    saveAttestation = false,
  }) => {
    const {
      attestation,
      country,
      location,
      opportunityId,
      saveBusinessRepresentative,
      termsAndConditions,
    } = this.props;
    const applicant = _.head(contacts.filter((x) => x.isApplicant));

    await saveBusinessRepresentative({
      opportunityId,
      accountId: applicant.accountId,
      contacts,
      country,
      location,
      documentId: termsAndConditions.id,
      attestationId: attestation.id,
      saveAcceptance,
      saveAttestation,
    });
  };

  saveAdditionalSigners = ({ contacts }) => {
    const { country, opportunityId, saveAdditionalSigners } = this.props;
    const applicant = _.head(contacts.filter((x) => x.isApplicant));

    saveAdditionalSigners(contacts, applicant.accountId, opportunityId, country);
  };

  validateOpportunityAndStipulation() {
    const {
      accountsPending,
      additionalSignersFulfilled,
      country,
      groupName,
      history,
      inFlightOpportunities,
      match,
      notifications,
      notificationsPending,
      ownershipFulfilled,
      ownershipReceived,
      stipulationName,
      opportunityId,
    } = this.props;
    if (ownershipFulfilled || ownershipReceived || additionalSignersFulfilled) {
      const currentStipulation = this.getCurrentStipulation();
      // if ownership is fulfilled and additional signers is not required, take to success
      // or if both ownership fulfilled and additionalSignersFulfilled take to success
      if (additionalSignersFulfilled || (ownershipFulfilled
        && (!currentStipulation || !currentStipulation.showAdditionalSigners))) {
        const RoutePayload = {
          country,
          successStipulationGroupName: groupName,
          successStipulationName: stipulationName,
        };

        heapService.markUserStipulationAsCompleted(opportunityId, stipulationName);
        history.push(resolve(routes.summary.path, RoutePayload));
      }
      return;
    }

    if (opportunityId && !notificationsPending
      && !accountsPending && inFlightOpportunities && notifications) {
      // validate opportunity with inflight opportunities
      // validate stipulation for forms
      const stipulations = notificationUtil.getAccountStipulations(opportunityId,
        inFlightOpportunities, notifications);
      if (stipulations) {
        const isBusinessOwnershipFulfilled = stipulations.find((stipulation) => stipulation.type === 'BusinessOwnership' && stipulation.isFulfilled);
        if (isBusinessOwnershipFulfilled) {
          redirect(history, match);
        }
        const { stipulationType } = this.props;
        const currentStipulation = _.head(stipulations.filter((x) => x.type === stipulationType));
        if (currentStipulation || !((ownershipFulfilled && !currentStipulation)
          || additionalSignersFulfilled)) {
          return;
        }
      }
      redirect(history, match);
    }
  }

  render() {
    const {
      acceptance,
      accountsPending,
      additionalSignersFulfilled,
      attestation,
      canStartAdditionalSigners,
      getPossibleSigners,
      notifications,
      notificationsPending,
      opportunityId,
      owners,
      ownershipError,
      ownershipFulfilled,
      stipulationType,
      termsAndConditions,
    } = this.props;

    const currentStipulation = this.getCurrentStipulation();
    const stipulationTitle = stipulationType === additionalSignersStipulation
      ? 'Loan signers' : (
        <>
          Verify your business representatives
          <Tooltip description={constants.tooltips.ownershipTip} className="pp-react__tooltip--inline" />
        </>
      );

    const isSuccess = (ownershipFulfilled && !currentStipulation) || additionalSignersFulfilled;

    const pageTitle = isSuccess ? 'Success!' : stipulationTitle;

    const showOwnership = currentStipulation && !ownershipFulfilled
      && (currentStipulation.showOwners || currentStipulation.showManagers);
    const showSigners = !showOwnership && currentStipulation && !(currentStipulation.showOwners
      || currentStipulation.showManagers) && currentStipulation.showAdditionalSigners
      && !additionalSignersFulfilled;

    if (showSigners && !canStartAdditionalSigners) {
      getPossibleSigners(opportunityId);
    }

    return (
      <div>
        {this.validateOpportunityAndStipulation()}
        {(!isSuccess || (!accountsPending && !notificationsPending && notifications)) && (
          <>
            <Helmet>
              <title>Verify Ownership</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <div>
                  <PageTitle text={pageTitle} />
                  <>
                    {owners && attestation && acceptance
                    && (
                      <>
                        {showOwnership
                              && (
                              <OwnershipComponent
                                attestation={attestation}
                                acceptance={acceptance}
                                stipulation={currentStipulation}
                                ownershipError={ownershipError}
                                termsAndConditions={termsAndConditions}
                                setControllingManager={this.setControllingManager}
                                saveBusinessRepresentative={this.saveBusinessRepresentative}
                                owners={owners}
                              />
                              )}
                        {showSigners && canStartAdditionalSigners
                              && (
                              <AdditionalSignersFormComponent
                                owners={owners}
                                initialValues={{
                                  owners: owners
                                    .filter((x) => !(x.isApplicant || x.percentOwned < 1)),
                                }}
                                saveAdditionalSigners={this.saveAdditionalSigners}
                                ownershipError={ownershipError}
                              />
                              )}
                      </>
                    )}
                  </>
                </div>
              )}
              sidebarElement={<></>}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    acceptance: state.ownership.acceptance,
    accountError: state.accountItems.error,
    attestation: state.ownership.attestation,
    bfes: state.accountItems.bfes,
    controllingManager: state.ownership.controllingManager,
    owners: state.ownership.selectedOpportunityOwners,
    ownershipError: state.ownership.error,
    ownershipFulfilled: state.ownership.ownershipFulfilled,
    messageBody: state.ownership.businessRepresentative
      ? state.ownership.businessRepresentative.messageBody : null,
    messageType: state.ownership.businessRepresentative
      ? state.ownership.businessRepresentative.messageType : null,
    stipulations: state.notifications.stipulations,
    additionalSignersFulfilled: state.ownership.additionalSignersFulfilled,
    termsAndConditions: state.ownership.termsAndConditions,
    inFlightOpportunities: state.accountItems.inFlightOpportunities,
    notifications: state.notifications.stipulations,
    accountsPending: state.accountItems.accountsPending,
    notificationsPending: state.notifications.notificationsPending,
    ownershipReceived: state.ownership.ownershipReceived,
    canStartAdditionalSigners: state.ownership.canStartAdditionalSigners,
  };
}

export default connect(mapStateToProps, {
  clearSelectedOpportunity: clearSelectedOpportunityAction,
  getAcceptance: getAcceptanceAction,
  getAttestation: getAttestationAction,
  getContacts: getContactsAction,
  getOwners: getOwnersAction,
  getTermsAndConditions: getTermsAndConditionsAction,
  saveBusinessRepresentative: saveBusinessRepresentativeAction,
  setControllingManager: setControllingManagerAction,
  saveAdditionalSigners: saveAdditionalSignersAction,
  clearAdditionalSignersFulfilled: clearAdditionalSignersFulfilledAction,
  getPossibleSigners: getPossibleSignersAction,
})(SecurePage(OwnershipPage));
