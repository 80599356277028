import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import ActionItem from './action-item/action-item';

const ApplicationActionItems = (props) => {
  const { applications } = props;
  const groupStipulations = (stipulations) => _(stipulations).groupBy((x) => x.groupName).map((value, key) => ({
    groupName: key,
    name: key,
    displayName: (value.find((x) => x.displayName)) || key,
    hasFulfilledStipulations: value.filter((x) => x.isFulfilled).length > 0,
    isFulfilled: value.filter((x) => !x.isFulfilled).length === 0,
    stipulations: value.filter((x) => !x.isFulfilled),
    isDocument: value.filter((x) => x.isDocument).length > 0,
    index: getGroupIndex(key),
    isReference: value.filter((x) => x.isVendorReference || x.isLandlordReference).length > 0,
    type: value.filter((x) => !x.isFulfilled).length === 1 ? value.filter((x) => !x.isFulfilled)[0].type : key,
  })).value();

  const getGroupIndex = (groupName) => {
    if (groupName.toLowerCase().includes('bank account')) {
      return 10;
    }
    if (groupName.toLowerCase().includes('business ownership')) {
      return 20;
    }
    if (groupName.toLowerCase().includes('bank statement')) {
      return 30;
    }
    if (groupName.toLowerCase().includes('financial statement')) {
      return 40;
    }
    if (groupName.toLowerCase().includes('identity')) {
      return 50;
    }
    if (groupName.toLowerCase().includes('reference')) {
      return 60;
    }
    return 100;
  };

  return (
    <>
      {applications && !!applications.length && applications.map((application) => (
        <div className="action-items" key={application.id}>
          <div data-application-stipulation-text className="vx_text-body-md">
            Please complete the following action items so that we can proceed with the review of your
            loan application started on
            {' '}
            {moment(application.applicationReceivedDate).format('MM/DD/YYYY')}
            .
          </div>
          <div data-application-stipulation className="list-items margin-top-xs margin-bottom-xs">
            {application.stipulations && _.orderBy(groupStipulations(application.stipulations), ['index'])
              .map((stipulation) => (
                <ActionItem
                  key={stipulation.index}
                  stipulation={stipulation}
                  opportunity={application}
                  clearStipFunc={props.clearStipFunc}
                />
              ))}
          </div>
        </div>
      ))}
    </>
  );
};

ApplicationActionItems.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.object),
  clearStipFunc: PropTypes.func,
};

ApplicationActionItems.defaultProps = {
  applications: undefined,
  clearStipFunc: undefined,
};

export default ApplicationActionItems;
