import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../components/common/page-title';
import SecurePage from '../hocs/secure-page';
import {
  getContactNotificationsAction,
  markStipulationFulfilledAction,
} from '../redux/actions/notifications/notifications-actions';
import {
  getProductsAndBfesAction,
  renewLoanAction,
} from '../redux/actions/account-items/account-items-actions';
import { getHomePhoneAndBusinessWebAddressAction } from '../redux/actions/settings/settings-actions';
import { getIsRenewalEligibleAction } from '../redux/actions/account/account-actions';
import { Alert, AlertTypes } from '../components/common/alert';
import ActionItemsList from '../components/action-items/action-items-list';
import AutoDraftItemsList from '../components/action-items/auto-draft-items-list';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class ActionItemsPage extends Component {
  static displayName = displayNames.ActionItemsPage;

  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    inFlightOpportunities: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    flattenedStipulations: PropTypes.arrayOf(PropTypes.object),
    getHomePhoneAndBusinessWebAddress: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getIsRenewalEligible: PropTypes.func.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    markStipulationFulfilled: PropTypes.func.isRequired,
    renewLoan: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    autoDraftItems: PropTypes.arrayOf(PropTypes.string),
    homePhoneAndBusinessWebAddress: PropTypes.shape({
      homePhone: PropTypes.string,
      businessWebAddress: PropTypes.string,
    }),
  };

  static defaultProps = {
    accounts: undefined,
    error: undefined,
    flattenedStipulations: undefined,
    inFlightOpportunities: undefined,
    stipulations: undefined,
    autoDraftItems: [],
    homePhoneAndBusinessWebAddress: {
      businessWebAddress: undefined,
      homePhone: undefined,
    },
  };

  async componentDidMount() {
    const {
      accounts,
      getIsRenewalEligible,
      getProductsAndBfes,
      getHomePhoneAndBusinessWebAddress,
      location,
    } = this.props;
    getContactNotificationsAction(location);
    await getProductsAndBfes();
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      await getIsRenewalEligible(account.accountId);
    }

    const { inFlightOpportunities } = this.props;
    if (inFlightOpportunities && inFlightOpportunities.length > 0) {
      const opp = inFlightOpportunities[0];
      await getHomePhoneAndBusinessWebAddress(opp.contactId);
    }
  }

  render() {
    const {
      error,
      flattenedStipulations,
      markStipulationFulfilled,
      renewLoan,
      stipulations,
      autoDraftItems,
      homePhoneAndBusinessWebAddress,
    } = this.props;
    let hasRenewals = false;

    if (flattenedStipulations && flattenedStipulations.length) {
      hasRenewals = !!flattenedStipulations.find((x) => x.isRenewal);
    }

    return (
      <>
        <Helmet>
          <title>Notifications</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div className="padding-bottom-md">
              <PageTitle text="Notifications" />
              {error && <Alert message={error} alertType={AlertTypes.critical} />}
              {stipulations && (
                <ActionItemsList
                  renewFunc={renewLoan}
                  clearStipFunc={markStipulationFulfilled}
                  stipulations={stipulations}
                  hasRenewals={hasRenewals}
                  homePhone={homePhoneAndBusinessWebAddress.homePhone}
                  businessWebAddress={homePhoneAndBusinessWebAddress.businessWebAddress}
                />
              )}
              {autoDraftItems.length ? (
                <AutoDraftItemsList autoDraftItems={autoDraftItems} />
              ) : null}
            </div>
          )}
          sidebarElement={<></>}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bfes: state.accountItems.bfes,
  stipulations: state.notifications.stipulations,
  autoDraftItems: state.notifications.autoDraftItems,
  error: state.notifications.error,
  accounts: state.accountItems.accounts,
  inFlightOpportunities: state.accountItems.inFlightOpportunities,
  flattenedStipulations: state.notifications.flattenedStipulations,
  location: state.router.location,
  homePhoneAndBusinessWebAddress: state.settings.homePhoneAndBusinessWebAddress,
});

const mapDispatchToProps = {
  getContactNotifications: getContactNotificationsAction,
  getHomePhoneAndBusinessWebAddress: getHomePhoneAndBusinessWebAddressAction,
  getProductsAndBfes: getProductsAndBfesAction,
  getIsRenewalEligible: getIsRenewalEligibleAction,
  renewLoan: renewLoanAction,
  markStipulationFulfilled: markStipulationFulfilledAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurePage(ActionItemsPage));
