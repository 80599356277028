function getOppStages(contactNumber) {
  return [{
    id: 0,
    displayName: 'Check eligibility',
    displayIfNotActive: true,
  },
  {
    id: 1,
    stageName: 'ST1',
    displayName: 'Select loan terms',
    detailText: 'Talk to your Business Funding Expert to discuss options for funding. We will be reaching out to '
    + `you${contactNumber ? ` at ${contactNumber}` : ''}.`,
    displayIfNotActive: true,
    showDetailText: true,
    showLoanTerms: false,
    showStipulations: false,
  },
  {
    id: 2,
    stageName: 'ST2',
    displayName: 'Underwriting review',
    detailText: 'There are action items that you will need to complete so that we can proceed with the review'
      + ' of your loan application. Please view and fulfill these by using the button below.',
    hasActionItems: true,
    displayIfNotActive: false,
    showDetailText: false,
    showLoanTerms: true,
    showStipulations: true,
  },
  {
    id: 3,
    stageName: 'ST3',
    displayName: 'Underwriting review',
    detailText: 'Your application is currently under review. We will be in touch when the review is complete or '
      + 'if there is anything else needed.',
    dontDisplayIfActive: [2, 4],
    displayIfNotActive: true,
    showDetailText: true,
    showLoanTerms: true,
    showStipulations: false,
  },
  {
    id: 4,
    stageName: 'ERROR',
    displayName: 'Underwriting review',
    detailText: 'Your application is currently under review. We will be in touch when the review is complete or '
      + 'if there is anything else needed.',
    dontDisplayIfActive: [2, 3],
    displayIfNotActive: false,
    showDetailText: true,
    showLoanTerms: true,
    showStipulations: false,
  },
  {
    id: 5,
    stageName: 'ST4',
    displayName: 'Sign loan agreement',
    detailText: 'Your loan agreement has been sent to you via email from DocuSign. Please review and '
      + 'electronically sign the agreement. Once you complete the e-signature, we will perform our final checks '
      + 'in preparation for funding your loan. If approved, we will initiate the transfer to your account.',
    displayIfNotActive: false,
    showDetailText: true,
    showLoanTerms: true,
    showStipulations: false,
  },
  {
    id: 6,
    stageName: 'ST5',
    displayName: 'Sign loan agreement',
    detailText: 'Your loan agreement has been sent to you via email from DocuSign. Please review and '
    + 'electronically sign the agreement. Once you complete the e-signature, we will perform our final checks '
    + 'in preparation for funding your loan. If approved, we will initiate the transfer to your account.',
    dontDisplayIfActive: [5],
    displayIfNotActive: true,
    showDetailText: true,
    showLoanTerms: true,
    showStipulations: false,
  },
  {
    id: 7,
    stageName: 'ST6',
    displayName: 'Receive loan funding',
    detailText: `We are performing final checks and preparing to fund your loan. Once complete we will be sending
        the money to your account. Depending on your financial institution, funds typically appear within one
        business day.`,
    dontDisplayIfActive: [8],
    displayIfNotActive: true,
    showDetailText: true,
    showLoanTerms: true,
    showStipulations: false,
  },
  {
    id: 8,
    stageName: 'CLOSED',
    displayName: 'Receive loan funding',
    detailText: 'We are performing final checks and preparing to fund your loan. Once complete we will be sending'
      + ' the money to your account. Depending on your financial institution, funds typically appear within one '
      + 'business day.',
    dontDisplayIfActive: [7],
    displayIfNotActive: false,
    showDetailText: true,
    showLoanTerms: true,
    showStipulations: false,
  }];
}

module.exports = {
  getOppStages,
};
