import React from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@swift-paypal/pp-react';
import { Alert, AlertTypes } from '../common/alert';
import errors from '../../constants/errors';

const FailedUploadsMessage = ({ allFailed, failedFiles }) => (
  <Alert
    alertType={AlertTypes.critical}
    message={allFailed ? errors.technicalIssue : (
      <>
        <BodyText>
          We failed to upload the following file(s) due to the reason(s) provided:
        </BodyText>
        <ul>
          {failedFiles.map((file) => (
            <li key={file.filename}>
              {file.filename}
              :
              {' '}
              {file.failureReason}
            </li>
          ))}
        </ul>
      </>
    )}
  />
);

FailedUploadsMessage.propTypes = {
  allFailed: PropTypes.bool.isRequired,
  failedFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default (FailedUploadsMessage);
