import {
  ADVANCES_REQUEST_RECEIVED,
} from '../actions/advances/advances-action-types';

const defaultState = {
  byId: {},
  allIds: [],
};

const advances = (state = defaultState, action) => {
  const results = action.payload;
  switch (action.type) {
    case ADVANCES_REQUEST_RECEIVED:
      return {
        allIds: results.map((advance) => advance.id),
        byId: results.reduce((result, advance) => {
          result[advance.id] = advance;
          return result;
        }, {}),
      };
    default:
      return state;
  }
};

export default advances;
