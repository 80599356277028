import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BodyText, CaptionText } from '@swift-paypal/pp-react';
import Currency from '../../common/currency';
import Percentage from '../../common/percentage';
import formLabels from '../../../constants/formLabels';
import withUrlParams from '../../../hocs/with-url-params';
import Detail from './detail';

const StandardTerms = (props) => {
  const {
    advanceIds,
    advancesById,
    country,
    detailsVisible,
    hideDisclosures,
    opportunityId,
    oppsById,
  } = props;

  const opportunity = oppsById[opportunityId];

  if (!opportunity) {
    return <></>;
  }

  const air = opportunity.annualInterestRate;
  const totalInterestCharge = opportunity.originationFee + opportunity.premiumAmount;
  const totalRepaymentAmount = opportunity.repaymentAmount;
  const weeklyPayment = opportunity.averagePayment;
  const isSBAOpp = opportunity.type === 'SBA';

  let paymentLabel = formLabels.weeklyPayment;
  let termUnit = 'Weeks';
  const advanceId = advanceIds.find((id) => advancesById[id].opportunityId === opportunityId);
  let loanTerm = advanceId ? advancesById[advanceId].termInWeeks : opportunity.numberOfPayments;
  if (isSBAOpp) {
    paymentLabel = formLabels.monthlyPayment;
    termUnit = 'Months';
    loanTerm = advanceId ? advancesById[advanceId].termInMonths : loanTerm;
  }

  if (hideDisclosures) {
    return (
      <div className="term-details">
        <BodyText>{`${loanTerm} ${termUnit.toLowerCase()}`}</BodyText>
        <CaptionText className="vx_text-body-sm loan-term-label">{formLabels.loanTerm}</CaptionText>
      </div>
    );
  }

  return detailsVisible ? (
    <div className="loan-details">
      {country === 'ca'
        && <Detail label={formLabels.air} amount={<Percentage number={air} />} /> }
      { country === 'ca'
        ? (
          <Detail
            label={formLabels.totalLoanFee}
            amount={
              <Currency amount={totalInterestCharge} decimals={0} />
            }
          />
        )
        : (
          <Detail
            label={formLabels.totalLoanFee}
            amount={
              <Currency amount={totalInterestCharge} decimals={0} />
            }
          />
        )}
      <Detail
        label={formLabels.totalRepaymentAmount}
        amount={
          <Currency amount={totalRepaymentAmount} decimals={0} />
        }
      />
      <Detail label={formLabels.loanTerm} amount={`${loanTerm} ${termUnit}`} />
      <Detail
        label={paymentLabel}
        amount={isSBAOpp ? 'Deferred' : <Currency amount={weeklyPayment} />}
      />
    </div>
  ) : <></>;
};

StandardTerms.propTypes = {
  country: PropTypes.string,
  detailsVisible: PropTypes.bool,
  opportunityId: PropTypes.string.isRequired,
  oppsById: PropTypes.objectOf(PropTypes.shape({
    advanceAmount: PropTypes.number.isRequired,
    annualInterestRate: PropTypes.number,
    originationFee: PropTypes.number.isRequired,
    premiumAmount: PropTypes.number.isRequired,
    repaymentAmount: PropTypes.number.isRequired,
    numberOfPayments: PropTypes.number.isRequired,
    averagePayment: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  advancesById: PropTypes.objectOf(PropTypes.object).isRequired,
  advanceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideDisclosures: PropTypes.bool.isRequired,
};

StandardTerms.defaultProps = {
  country: undefined,
  detailsVisible: false,
};

const mapStateToProps = (state) => ({
  oppsById: state.entities.opportunities.byId,
  advancesById: state.entities.advances.byId,
  advanceIds: state.entities.advances.allIds,
  hideDisclosures: state.config.hideDisclosures,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(StandardTerms);
