import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as pushAction } from 'connected-react-router';
import { Button } from '@swift-paypal/pp-react';
import constants from '../../../constants/index';
import resolve from '../../../services/route.service';
import Currency from '../../common/currency';
import routes from '../../../routes';
import withUrlParams from '../../../hocs/with-url-params';
import TotalBalanceSvg from './total-balance-svg';
import './total-balance-tile.less';

class TotalBalanceTile extends React.Component {
  static propTypes = {
    country: PropTypes.string,
    loan: PropTypes.shape({
      account: PropTypes.object,
      name: PropTypes.string,
      percentOfBalanceRemaining: PropTypes.number,
      balance: PropTypes.number,
    }).isRequired,
    isRenewalEligible: PropTypes.bool,
    push: PropTypes.func.isRequired,
    renewalsEnabled: PropTypes.bool,
    renewFunc: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: undefined,
    isRenewalEligible: false,
    renewalsEnabled: false,
  };

  addMoney = () => {
    const {
      country,
      loan,
      isRenewalEligible,
      push,
      renewFunc,
      renewalsEnabled,
    } = this.props;

    if (isRenewalEligible && renewalsEnabled) {
      renewFunc(loan.account.id);
    }
    else {
      const RouteParams = {
        country,
        requestType: constants.contactUs.addMoney,
        accountId: loan.account.id,
      };
      push(resolve(routes['contact-us'].path, RouteParams));
    }
  };

  render() {
    const {
      isRenewalEligible,
      loan,
      renewalsEnabled,
    } = this.props;
    const { balance, percentOfBalanceRemaining } = loan;

    return (
      <div className="total-balance-tile">
        <div className="total-balance-top">
          <div className="total-balance-svg">
            <TotalBalanceSvg percentValue={100 - percentOfBalanceRemaining} />
          </div>
          <div className="total-balance-owed">
            <div className="vx_text-body-md">Total Balance</div>
            <div className="vx_text-2"><Currency amount={balance} dataAutomationId="tileTotalBalanceValueLabel" /></div>
          </div>
        </div>
        <div className="total-balance-buttons">
          {
            renewalsEnabled && isRenewalEligible && (
              <Button
                className="renewal-btn"
                data-automation-id="renewalButton"
                inverse
                onClick={this.addMoney}
                type="button"
              >
                Add Money
              </Button>
            )
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isRenewalEligible: state.isRenewalEligible,
    renewalsEnabled: state.config.renewalsEnabled,
  };
}

export default compose(
  connect(mapStateToProps, { push: pushAction }),
  withRouter,
  withUrlParams,
)(TotalBalanceTile);
