import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  has, isArray, isEmpty, isNull, isString, isUndefined,
} from 'lodash';
import PaymentModalComponentContainer from './paymentModalComponentContainer';
import { OneTimePaymentContext } from './OneTimePaymentContext';
import { useShallowSelector, oneTimePaymentSelector } from './one-time-payment-selectors';
import { PaymentComponentMapping, PaymentTriggerSource } from './paymentComponentMappings';
import {
  getNextDate,
  getDefaultBank,
  validationUtil,
  getIntialDataForEdit,
  getIntialBankInfo,
  getInitialBankWarning,
  hasRoutingNumberWarning,
  suggestRoutingNumber,
  MapRoutingToBank,
} from '../pages/oneTimePaymentPage/oneTimePayment.utils';
import {
  resetPaymentProcessingStatus,
  resetSelfServiceBankAddAction,
} from '../../redux/actions/one-time-payment/one-time-payment';
import oneTimePaymentContants from '../../constants/oneTimePayments';

const PaymentModal = ({
  isPaymentModalOpen, setIsPaymentModalOpen,
  selectedLoanAdvance, paymentTiggeredSource,
  scheduleTransactionEditBankData,
}) => {
  const [visiblePaymentComponent, setVisiblePaymentComponent] = useState(() => {
    if (paymentTiggeredSource === PaymentTriggerSource.MakeAPaymentButton) {
      return PaymentComponentMapping.MakeAPayment;
    } if (paymentTiggeredSource === PaymentTriggerSource.TransactionList) {
      return PaymentComponentMapping.AddBusinessInfo;
    }
    return PaymentComponentMapping.MakeAPayment;
  });

  const routingInstitutionInfo = useSelector((state) => state?.getRoutingInstitution);
  const routingInstitutionList = routingInstitutionInfo?.institutionList || [];
  const routingMap = useMemo(
    () => MapRoutingToBank(routingInstitutionList),
    [routingInstitutionList],
  );

  const [footerContent, setFooterContent] = useState(null);
  const [bankErrorVisited, setBankErrorVisited] = useState(false);

  const [paymentAmount, setPaymentAmount] = useState({
    amountType: '',
    amount: null,
  });

  const { bankList, bankUpdatedDetails } = useShallowSelector(oneTimePaymentSelector);
  const [paymentDate, setPaymentDate] = useState(getNextDate);
  const [invalidPaymentDateError, setInvalidPaymentDateError] = useState(null);
  const [currentBank, setCurrentBank] = useState(() => getDefaultBank(bankList, selectedLoanAdvance,
    bankUpdatedDetails));

  const bankInfoConstants = oneTimePaymentContants.BankInfoConstants;

  const [bankInfo, setBankInfo] = useState(getIntialBankInfo());
  const [bankValidationError, setBankValidationError] = useState(getIntialBankInfo('error'));
  const [bankValidationWarning, setBankValidationWarning] = useState(getInitialBankWarning());
  const [editData, setEditData] = useState(() => {
    if (!isNull(scheduleTransactionEditBankData) && !isNull(scheduleTransactionEditBankData?.fiData
      && paymentTiggeredSource === PaymentTriggerSource.TransactionList)) {
      return scheduleTransactionEditBankData;
    }
    return { isEditMode: false, fiData: null };
  });

  const resetEditData = () => {
    dispatch(resetSelfServiceBankAddAction());
    setBankErrorVisited(null);
    setBankValidationError(getIntialBankInfo('error'));
    setBankValidationWarning(getInitialBankWarning());
    setEditData({ isEditMode: false, fiData: null });
  };

  useEffect(() => {
    setBankErrorVisited(null);
    const initialEditData = getIntialDataForEdit(editData.fiData);
    setBankInfo(initialEditData);
  }, [editData]);

  const dispatch = useDispatch();

  const setVisibleComponent = (component) => {
    setVisiblePaymentComponent(() => component);
  };

  const resetSchedulePaymentState = () => {
    dispatch(resetPaymentProcessingStatus());
    setPaymentDate(getNextDate());
    setPaymentAmount({
      amountType: '',
      amount: null,
    });
  };

  /**
   * updates the bank fields data in bankinfo object in context provider
   * @param {object} bankFieldKey - field key of object
   * @param {string} bankFieldKeyValue - value of the field in add/edit modal
   * @param {object} patternValidationInputFn - pattern matching validation
   * function to check whether we should update our field value or not
   */

  const updateBankInfoContext = (
    bankFieldKey,
    bankFieldKeyValue,
    patternValidationInputFn = null,
  ) => {
    if (has(bankInfoConstants.BankAccount, bankFieldKey)) {
      if (
        patternValidationInputFn !== null
        && bankFieldKeyValue !== ''
        && !isUndefined(bankFieldKeyValue)
      ) {
        if (!patternValidationInputFn(bankFieldKeyValue)) {
          return;
        }
      }
      setBankInfo({
        ...bankInfo,
        [bankFieldKey]: bankFieldKeyValue,
      });
    }
    updateBankErrorContext(bankFieldKey, bankFieldKeyValue);
  };

  /**
   * This function returns if form has any error on ts field or not
   * @param {object} errorObject - object that holds all error values
   * @returns {boolean}-  is form error free from validation or not {isFormErrorFree}
   */
  const getFormErrorFreeState = (errorObject) => {
    if (isEmpty(errorObject)) {
      return true;
    }
    const errorValues = Object.values(errorObject);
    const allNullValues = errorValues.every((errorState) => errorState === null);
    return allNullValues;
  };

  const checkRo3Warning = (fieldKey, fieldValue = null) => {
    if (!isEmpty(fieldValue) && !isEmpty(bankInfo?.State)
    && fieldKey === bankInfoConstants.BankAccount.ACHRoutingNumber) {
      const hasWarning = hasRoutingNumberWarning(fieldValue, bankInfo?.State);
      let bankWarningFields = {
        AchRoutingNumberWarning: null,
      };
      if (hasWarning) {
        const bankState = bankInfo[bankInfoConstants.BankAccount.State];
        const routingSuggestion = suggestRoutingNumber(fieldValue, bankState, routingMap);
        bankWarningFields = {
          AchRoutingNumberWarning: routingSuggestion,
        };
      }
      setBankValidationWarning(bankWarningFields);
    }
  };

  /**
   * This function runs the validation of all fields provided in fielderrorkey against the field value
   * you can send array of fields for final validation
   * also you can validate on change of each fields
   * if field value is null, it checks field value from bankinfo object of context
   * else just check against field value we provide
   * @param {Array<string>|string} fieldErrorKey - error key for which validation message needs to be updated
   * @param {any} fieldValue - value of the field in add/edit modal
   * @param {object} bankInfo - object that holds all value
   * @returns {boolean}-  is form error free from validation or not {isFormErrorFree}
   */

  const updateBankErrorContext = (fieldKey, fieldValue = null) => {
    const errorObject = {};
    if (isArray(fieldKey) && !isEmpty(fieldKey)) {
      fieldKey.forEach((fkey) => {
        validationUtil(fkey, errorObject, fieldValue, bankInfo);
      });
    }

    if (!isArray(fieldKey) && isString(fieldKey) && !isEmpty(fieldKey)) {
      validationUtil(fieldKey, errorObject, fieldValue, bankInfo);
    }

    if (!isEmpty(errorObject)) {
      const currentErrorValidation = {
        ...bankValidationError,
        ...errorObject,
      };
      setBankValidationError(currentErrorValidation);
    }
    const isFormErrorFree = getFormErrorFreeState(errorObject);
    return isFormErrorFree;
  };

  return (
    <div className="payment-tile-wrapper">
      <OneTimePaymentContext.Provider
        value={{
          visiblePaymentComponent,
          setVisibleComponent,
          isPaymentModalOpen,
          footerContent,
          setFooterContent,
          setIsPaymentModalOpen,
          selectedLoanAdvance,
          paymentAmount,
          setPaymentAmount,
          paymentDate,
          setPaymentDate,
          resetSchedulePaymentState,
          currentBank,
          setCurrentBank,
          bankInfo,
          bankErrorVisited,
          setBankErrorVisited,
          updateBankInfoContext,
          bankValidationError,
          updateBankErrorContext,
          editData,
          setEditData,
          resetEditData,
          bankValidationWarning,
          setBankValidationWarning,
          checkRo3Warning,
          invalidPaymentDateError,
          setInvalidPaymentDateError,
        }}
      >
        <PaymentModalComponentContainer />
      </OneTimePaymentContext.Provider>
    </div>
  );
};

PaymentModal.propTypes = {
  isPaymentModalOpen: PropTypes.bool.isRequired,
  selectedLoanAdvance: PropTypes.object.isRequired,
  setIsPaymentModalOpen: PropTypes.func.isRequired,
  paymentTiggeredSource: PropTypes.string.isRequired,
  scheduleTransactionEditBankData: PropTypes.any,
};

PaymentModal.defaultProps = {
  scheduleTransactionEditBankData: null,
};

export default PaymentModal;
