import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

async function getAdvances(apiUrl) {
  const url = `${apiUrl}/v2/advances`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetAdvances = asyncDebounce(getAdvances);

async function getLoanForgiveness(apiUrl, advanceIds) {
  const serializedParams = advanceIds.map((id) => `advanceIds=${id}`).join('&');
  const url = `${apiUrl}/v2/loan-forgiveness/?${serializedParams}`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}
const debouncedGetLoanForgiveness = asyncDebounce(getLoanForgiveness);

async function getOrCreateLoanForgiveness(apiUrl, id, formType) {
  const data = {
    sbaFormType: formType,
  };
  const url = `${apiUrl}/v2/advances/${id}/forgiveness`;
  const res = await axios.post(url, data, defaultRequestOptions());
  return res.data;
}
const debouncedGetOrCreateLoanForgiveness = asyncDebounce(getOrCreateLoanForgiveness);

async function postScheduleServiceACHTransaction(apiUrl, requestPayload) {
  const url = `${apiUrl}/advances/scheduleTransactionACH`;
  const res = await axios.post(url, requestPayload, { ...defaultRequestOptions(), params: { hideSpinner: true } });
  return res.data;
}
const debouncedPostScheduleServiceACHTransaction = asyncDebounce(postScheduleServiceACHTransaction);

export default {
  getLoanForgiveness: debouncedGetLoanForgiveness,
  getOrCreateLoanForgiveness: debouncedGetOrCreateLoanForgiveness,
  getAdvances: debouncedGetAdvances,
  postScheduleServiceACHTransaction: debouncedPostScheduleServiceACHTransaction,
};
