import { asyncNoAuthActionCreator } from '../util/async-action-creator';
import legalService from '../../../services/legal.service';
import * as actions from './privacy-action-types';

const getPrivacyPolicyAction = asyncNoAuthActionCreator(
  {
    pending: actions.PRIVACY_POLICY_REQUEST_PENDING,
    complete: actions.PRIVACY_POLICY_REQUEST_RECEIVED,
    error: actions.PRIVACY_POLICY_REQUEST_ERROR,
  },
  (country) => (dispatch, getState) => legalService.getPrivacyPolicy(getState().config.apiUrl, country),
);

export default getPrivacyPolicyAction;
