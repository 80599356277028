import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import displayNames from '../../constants/displayNames';
import AnonymousPage from '../../hocs/anonymous-page';
import SpinnerStandaloneComponent from '../../components/common/spinner-standalone/spinner-standalone';
import { navigateToSsoWindow } from '../../lib/paypal-sso';

class OAuthLandingPage extends Component {
  static displayName = displayNames.OAuthLandingPage;

  static propTypes = {
    country: PropTypes.string,
    paypalAuthorizeUrl: PropTypes.string,
    paypalBaseUrl: PropTypes.string,
    paypalClientId: PropTypes.string,
    paypalCallbackUrl: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    paypalAuthorizeUrl: undefined,
    paypalBaseUrl: undefined,
    paypalClientId: undefined,
    paypalCallbackUrl: undefined,
  };

  componentDidMount() {
    const {
      country,
      paypalBaseUrl,
      paypalAuthorizeUrl,
      paypalClientId,
      paypalCallbackUrl,
    } = this.props;

    navigateToSsoWindow(paypalBaseUrl, paypalAuthorizeUrl, paypalClientId, paypalCallbackUrl, country);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Log in with PayPal</title>
        </Helmet>
        <SpinnerStandaloneComponent />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    paypalBaseUrl: state.config.paypalBaseUrl,
    paypalAuthorizeUrl: state.config.paypalAuthorizeUrl,
    paypalClientId: state.config.paypalClientId,
    paypalCallbackUrl: state.config.paypalCallbackUrl,
  };
}

export default connect(mapStateToProps)(AnonymousPage(OAuthLandingPage));
