export default {
  letterNumber: {
    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/,
    message: 'Must contain at least one number, one upper-case letter, and one lower-case letter.',
  },
  specialChars: {
    pattern: /^(?=.*[!.@#$%&*]).*$/,
    message: 'Must contain one of the following special characters: !.@#$%&*',
  },
  lengthMinMax: {
    pattern: /^.{8,20}$/,
    message: 'Must be between 8 and 20 characters long.',
  },
};
