import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import securePage from '../hocs/secure-page';
import { updateConfigValuesAction } from '../redux/actions/config/config-actions';
import { getAdvancesAction } from '../redux/actions/advances/advances-actions';
import { getLoanForgivenessAction } from '../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { getOpportunitiesAction } from '../redux/actions/opportunities/opportunities-actions';
import getStipulationsAction from '../redux/actions/stipulations/stipulations-actions';
import {
  overrideConfigWithQuery,
} from '../util';
import QuestionnaireComponent from '../components/questionnaire/questionnaire';
import displayNames from '../constants/displayNames';
import BetterStandardLayout from '../layouts/better-standard-layout/better-standard-layout';
import { useShallowSelector, pageSelector } from '../components/questionnaire/questionnaire-selectors';
import { setActiveForgivenessId } from '../redux/actions/active-ids/active-ids-actions';
import { SbaLoanInformationSidebar } from '../components/forgiveness/sba-loan-information-sidebar';
import CaseWorkerTile from '../components/common/case-worker-tile/case-worker-tile';
import { useActiveForgivenessSelected } from '../hooks';

const QuestionnairePage = () => {
  const dispatch = useDispatch();
  const { country, opportunityId } = useParams();

  const {
    advancesById, advanceId, caseWorker, environment, query,
  } = useShallowSelector(pageSelector);

  useActiveForgivenessSelected();
  useEffect(() => {
    dispatch(getAdvancesAction());
    dispatch(getOpportunitiesAction());
    overrideConfigWithQuery(environment, query, updateConfigValuesAction);
  }, []);

  useEffect(() => {
    const forgivenessId = advancesById[advanceId]?.loanForgivenessId;
    if (forgivenessId) {
      dispatch(setActiveForgivenessId(forgivenessId));
    }
    if (advanceId) {
      dispatch(getLoanForgivenessAction([advanceId]));
    }
  }, [advanceId, advancesById]);

  useEffect(() => {
    if (country && opportunityId) {
      dispatch(getStipulationsAction(opportunityId, country));
    }
  }, [country, opportunityId]);

  return (
    <div className="summary-container">
      <Helmet>
        <title>Questionnaire</title>
      </Helmet>
      <div className="standard-layout">
        <BetterStandardLayout
          bodyElement={<QuestionnaireComponent />}
          sidebarElement={<SbaLoanInformationSidebar />}
          secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
        />
      </div>
    </div>
  );
};

QuestionnairePage.displayName = displayNames.QuestionnairePage;

export default securePage(QuestionnairePage);
