import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BodyText, Col, Row } from '@swift-paypal/pp-react';
import YesOrNoRadioField from '../../common/yes-or-no-radio-field';
import { fieldNames } from './field-constants';
import { useShallowSelector, fieldSelector } from '../questionnaire-selectors';

const QuestionnaireQ4 = ({ loanVersion }) => {
  const {
    questionnaireQ4: fieldName,
  } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  return (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={generateContent(loanVersion)}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  );
};

const generateContent = (loanVersion) => (
  <>
    <BodyText>
      Was your business unable to operate between February 15, 2020 and the end of
      your covered period at the same level of activity as it did before February
      15, 2020 because of Covid-19 related governmental requirements, restrictions
      and guidance?
    </BodyText>
    <BodyText as="p" className="ppvx_radio-group__helper-text">
      {
        loanVersion === '20PPPLoan' && (
          <>
            This includes factors related to federal, state, or local requirements,
            restrictions, or guidance issued between Mar 1, 2020 – Dec 31, 2020 regarding
            sanitation, social distancing, or any other work or customer safety requirement
            related to COVID-19.
          </>
        )
      }
      {
        loanVersion !== '20PPPLoan' && (
          <>
            This includes factors related to federal, state, or local requirements, restrictions,
            or guidance issued between Mar 1, 2020 through the end of your Covered Period regarding sanitation,
            social distancing, or any other work or customer safety requirement related to COVID-19.
          </>
        )
      }
    </BodyText>
  </>
);

QuestionnaireQ4.propTypes = {
  loanVersion: PropTypes.string,
};

QuestionnaireQ4.defaultProps = {
  loanVersion: undefined,
};

export default memo(QuestionnaireQ4);
