import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import log from 'loglevel';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import resolve from '../../services/route.service';
import Title from '../common/title';
import { Alert, AlertTypes } from '../common/alert';
import fileValidationService from '../../lib/document-upload-validation-utils';
import { scrollToTop } from '../../services/window.service';
import UploadSingleDocumentComponent from '../common/upload-single-document/upload-single-document';
import {
  allowedFileTypes,
  allowedSingleFileSize,
  stipulationSpecificValidations,
} from '../../constants/upload-document';
import './upload-groups-of-documents.less';
import errors from '../../constants/errors';
import routes from '../../routes';
import withUrlParams from '../../hocs/with-url-params';

class UploadGroupsOfDocumentsComponent extends Component {
  static propTypes = {
    country: PropTypes.string,
    groupDescription: PropTypes.string,
    minimumStipulationsRequiredPerGroup: PropTypes.number,
    stipulationGroups: PropTypes.shape({}),
    submit: PropTypes.func.isRequired,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    uploadPending: PropTypes.bool,
    response: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    groupDescription: undefined,
    minimumStipulationsRequiredPerGroup: undefined,
    stipulationGroups: undefined,
    uploadError: undefined,
    uploadPending: false,
    response: undefined,
  };

  constructor(props) {
    super(props);
    const stipulations = this.getFlatStipulations();

    this.state = {
      stagedFiles: stipulations.map((stip, i) => ({
        expanded: !stip.isFulfilled && i === 0,
        fileBlob: undefined,
        isValid: stip.isFulfilled ? true : undefined,
        errors: [],
      })),
      submitted: false,
    };
  }

  getFlatStipulations = () => {
    const { stipulationGroups } = this.props;
    return [].concat(...Object.values(stipulationGroups));
  }

  onDrop = (i) => (file) => {
    const stipulations = this.getFlatStipulations();
    const { stagedFiles } = this.state;
    const { type } = stipulations[i];
    file.errors = fileValidationService.getFilesErrors([file.fileBlob], stipulationSpecificValidations[type] || null);

    if (!file.errors.length) {
      file.isValid = true;
      if (stagedFiles[i + 1] && !stagedFiles[i + 1].expanded && !stagedFiles[i + 1].fileBlob) {
        stagedFiles[i + 1].expanded = true;
      }
      else {
        const collapsed = stagedFiles.filter((f) => !f.expanded && !f.fileBlob);
        if (collapsed.length) {
          const ii = stagedFiles.indexOf(collapsed[0]);
          stagedFiles[ii].expanded = true;
        }
      }
    }
    else {
      log.error(`fileError: ${file.errors}`);
    }
    stagedFiles[i] = file;
    this.setState({ stagedFiles, submitted: false });
  };

  removeFile = (i) => (file) => {
    const { stagedFiles } = this.state;
    stagedFiles[i] = file;
    this.setState({ stagedFiles, submitted: false });
  };

  showHide = (i) => () => {
    const stipulations = this.getFlatStipulations();
    const { stagedFiles } = this.state;
    if (!stipulations[i].isFulfilled) {
      stagedFiles[i].expanded = !stagedFiles[i].expanded;
      this.setState({ stagedFiles });
    }
  };

  submit = () => {
    const { submit: submitFunc } = this.props;
    const { stagedFiles } = this.state;
    this.setState({ submitted: true });
    const errorsPresent = stagedFiles.find((file) => file.errors.length);
    if (errorsPresent) {
      return;
    }

    const emptyFilesPresent = this.hasEmptyFiles(stagedFiles);
    if (emptyFilesPresent) {
      return;
    }

    submitFunc(stagedFiles);
  };

  hasEmptyFiles = (stagedFiles) => {
    const { minimumStipulationsRequiredPerGroup } = this.props;
    const stipulations = this.getFlatStipulations();
    const minimumRequiredPerGroup = minimumStipulationsRequiredPerGroup || stagedFiles.length;
    const stipulationsFulfilled = stagedFiles.filter((file, i) => stipulations[i].isFulfilled || !!file.fileBlob);
    return stipulationsFulfilled.length < minimumRequiredPerGroup;
  };

  mapUploadComponents = (name, stipulations) => {
    const { stagedFiles } = this.state;
    const keyName = name.toLowerCase().replace(/([^\w]+)/g, '-');
    const flatStipulations = this.getFlatStipulations();

    return stipulations.map((stipulation) => {
      const i = flatStipulations.findIndex((stip) => stip === stipulation);
      const keyDisplayName = stipulation.displayName.toLowerCase().replace(/([^\w]+)/g, '-');
      const key = `${keyName}-${i}-${keyDisplayName}`;
      const allowedFileTypesResolved = stipulationSpecificValidations[stipulation.type]
        ? stipulationSpecificValidations[stipulation.type].allowedFileTypes : allowedFileTypes;
      const allowedFileTypesFlatteneded = _.flatten(Object.values(allowedFileTypesResolved));
      const showBorderTop = i === 0 && !stagedFiles[i].expanded ? 'show-border-top' : '';
      const showBorderBottom = !(stagedFiles[i] && stagedFiles[i].expanded)
        && !(stagedFiles[i + 1] && stagedFiles[i + 1].expanded) ? 'show-border-bottom' : '';

      if (!stipulation || !stagedFiles[i]) {
        return null;
      }

      return (
        <div key={key} className="upload-multuple-documents-container">
          <UploadSingleDocumentComponent
            allowedFileSize={allowedSingleFileSize}
            allowedFileTypes={allowedFileTypesFlatteneded}
            showBorderTop={showBorderTop}
            showBorderBottom={showBorderBottom}
            stipulation={stipulation}
            onDrop={this.onDrop(i)}
            showHide={this.showHide(i)}
            removeFile={this.removeFile(i)}
            fileStaged={stagedFiles[i]}
          />
        </div>
      );
    });
  };

  mapUploadGroups = () => {
    const { stipulationGroups } = this.props;
    return (
      <>
        {stipulationGroups && Object.keys(stipulationGroups).map((key) => (
          <>
            <div className="vx_text-body_secondary group-title">
              {key}
              {' '}
              - documents
            </div>
            {/* {File upload areas} */}
            {this.mapUploadComponents(key, stipulationGroups[key])}
          </>
        ))}
      </>
    );
  };

  render() {
    const {
      country, uploadError, uploadPending, response, groupDescription,
    } = this.props;
    const { stagedFiles, submitted } = this.state;
    const errorsPresent = stagedFiles.find((file) => file.errors.length);
    const emptyFilesPresent = this.hasEmptyFiles(stagedFiles);
    const showServerError = uploadPending === false && !!uploadError && submitted;

    if (uploadError) {
      log.error(`response: ${response} uploadError: ${uploadError}`);
    }

    const showAlert = (errorsPresent || emptyFilesPresent || showServerError) && submitted;
    if (showAlert) {
      scrollToTop();
    }

    return (
      <>
        <Title text="Upload documents" />
        <div className="left-gutter">
          {/* {Group description} */}
          {groupDescription && <div className="vx_text-body margin-bottom-sm">{groupDescription}</div>}

          {/* {Generic error} */}
          {showServerError && (
          <Alert
            message={errors.technicalIssue}
            alertType={AlertTypes.critical}
          />
          )}
          {this.shouldShowAlert() && !showServerError && (
            <Alert
              message={errors.uploads.uploadAllMessage}
              alertType={AlertTypes.critical}
            />
          )}

          {/* {File upload groups} */}
          {this.mapUploadGroups()}

          {/* {Upload button} */}
          <Row className="margin-top-sm">
            <Col xs={6} sm={5} md={4}>
              <Button type="submit" className="pp-react__button--block" onClick={this.submit}>
                Submit
              </Button>
            </Col>
            <Col xs={6} sm={5} md={4}>
              <Link to={resolve(routes.summary.path, { country })}>
                <Button secondary type="button" className="pp-react__button--block">
                  Cancel
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(UploadGroupsOfDocumentsComponent);
