import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, HeadingText } from '@swift-paypal/pp-react';
import UploadDocuments from '../../common/upload-documents/upload-documents';
import errors from '../../../constants/errors';
import { useShallowSelector } from '../../../hooks';
import documentUploadUtils from '../../../lib/document-upload-utils';
import { fulfillStipulationAction } from '../../../redux/actions/stipulations/stipulations-actions';
import { uploadDocumentsAction } from '../../../redux/actions/upload-document/upload-document-actions';
import { CoveredCostsDocumentsDetails } from './coveredCostsDocumentsDetails';
import {
  AllowedFileExtensions,
  AllowedSingleFileSize,
  OPT_OUT_TEXT,
  UPLOAD_HELPER_TEXT,
} from './coveredCostsDocuments.constants';
import { coveredCostsDocumentsSelector } from './coveredCostsDocuments.selectors';

export const CoveredCostsDocuments = () => {
  const dispatch = useDispatch();
  const {
    hasServerError,
    stipulation,
  } = useShallowSelector(coveredCostsDocumentsSelector);

  const handleSubmitDocumentUpload = (files) => {
    const payload = documentUploadUtils.buildMultipleFilesPayload(files, opportunityId, documentType);

    dispatch(uploadDocumentsAction(payload));
  };

  const handleSubmitOptOut = () => {
    dispatch(fulfillStipulationAction(opportunityId, id));
  };

  if (!stipulation) {
    return null;
  }

  const {
    id,
    opportunityId,
    stipulationMap: { documentType },
  } = stipulation;

  return (
    <>
      <HeadingText size="md">
        Covered costs / expenditures documents
      </HeadingText>
      <CoveredCostsDocumentsDetails />
      {hasServerError && <Alert className="margin-top-sm" type="error">{errors.technicalIssue}</Alert>}
      <UploadDocuments
        allowedFileExtensions={AllowedFileExtensions}
        helperText={UPLOAD_HELPER_TEXT}
        checkboxText={OPT_OUT_TEXT}
        customClassName="increased-width"
        isForgivenessStipulation
        maximumPerFileSizeInBytes={AllowedSingleFileSize}
        maximumTotalSizeInBytes={AllowedSingleFileSize}
        optOutCheckbox
        optOutSubmitFunc={handleSubmitOptOut}
        submitFunc={handleSubmitDocumentUpload}
      />
    </>
  );
};
