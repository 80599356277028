import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Container } from '@swift-paypal/pp-react';
import { getTermsAndConditionsAction } from '../redux/actions/application/ownership/ownership-actions';
import AnonymousPage from '../hocs/anonymous-page';
import displayNames from '../constants/displayNames';

class TermsAndConditionsPage extends Component {
  static displayName = displayNames.TermsAndConditionsPage;

  static propTypes = {
    country: PropTypes.string,
    getTermsAndConditions: PropTypes.func.isRequired,
    terms: PropTypes.shape({
      copy: PropTypes.string,
    }),
  };

  static defaultProps = {
    country: undefined,
    terms: undefined,
  };

  componentDidMount() {
    const { country, getTermsAndConditions } = this.props;
    getTermsAndConditions(country);
  }

  static getMarkup(markup) {
    return { __html: markup };
  }

  render() {
    const { terms } = this.props;

    if (!terms) {
      return null;
    }

    return (
      <Container>
        <Helmet>
          <title>Terms and Conditions</title>
        </Helmet>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={TermsAndConditionsPage.getMarkup(terms.copy)} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    terms: state.ownership.termsAndConditions,
  };
}

export default connect(
  mapStateToProps, {
    getTermsAndConditions: getTermsAndConditionsAction,
  },
)(AnonymousPage(TermsAndConditionsPage));
