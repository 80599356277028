import { get, cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import { Col } from '@swift-paypal/pp-react';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  uploadBatchDocumentsAction,
  clearUploadErrorAction, clearUploadPendingAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import UploadGroupsOfDocumentsComponent from '../../components/upload-groups-of-documents/upload-groups-of-documents';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

class AdditionalIdentificationPage extends Component {
  static displayName = displayNames.AdditionalIdentificationPage;

  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    getProductsAndBfes: PropTypes.func.isRequired,
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    response: PropTypes.shape({
      params: PropTypes.object,
    }),
  };

  static defaultProps = {
    accounts: [],
    country: undefined,
    opportunityId: undefined,
    uploadError: false,
    uploadPending: false,
    response: undefined,
  };

  async componentDidMount() {
    const { getProductsAndBfes } = this.props;
    await getProductsAndBfes();
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = (files) => {
    const { opportunityId, uploadBatchDocuments } = this.props;
    const payloads = [];
    files.forEach((file) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, 'ProofOfIdentity'));
      }
    });
    uploadBatchDocuments(payloads);
  }

  redirectToSummaryConfirmation() {
    const { country, push, opportunityId } = this.props;
    const successStipulationGroupName = 'AdditionalIdentities';
    const RoutePayload = {
      country,
      successStipulationGroupName,
    };

    heapService.markUserStipulationAsCompleted(opportunityId, successStipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const { accounts } = this.props;

    const account = get(accounts, '[0].inFlightOpportunities[0]');

    if (!account) {
      return null;
    }

    const { contactId } = account;
    const stipulations = get(account, 'opportunity.stipulations');

    if (!stipulations) {
      return null;
    }

    const proofOfFinancialsTemplate = {
      displayName: 'Proof of financials',
      description: (
        <>
          <p>
            Upload one of the following: Bank account, credit card statement, loan
            account statement.
          </p>
          <p>
            Note: Must show logo, full name, full address, unique identifier (i.e.
            account number, student ID, Tax ID) and be most current statement.
          </p>
        </>
      ),
    };

    const proofOfIdentityTemplate = {
      displayName: 'Proof of identity',
      description: (
        <>
          <p>
            Upload one of the following: Financial Statement or Student Record
            from registered Canadian College or University (post-secondary
            institution), Notice of Assessment or Reassessment, Statement from
            Insurer (life, home or auto), Statement of CPP Contributions from
            customer Service Canada online account, Recognized government
            agency issued statement (i.e. Ministry related)
          </p>
          <p>
            Note: All documents must show full name and DOB.
          </p>
        </>
      ),
    };

    const proofOfAddressTemplate = {
      displayName: 'Proof of address',
      description: (
        <>
          <p>
            Upload one of the following: College or University (post-secondary
            institution), CRA statement (e.g. requirements to pay, installment
            notice or benefits statement), Financial Statement or Student Record
            from registered Canadian University, Municipal Property Tax
            Assessment, Notice of Assessment or Reassessment, Recognized
            government agency issued statement (i.e. Ministry related), utility bill.
          </p>
          <p>
            Note: Must show logo, full name, full address, unique identifier (i.e.
            account number, student ID, Tax ID) and be most current statement.
          </p>
        </>
      ),
    };

    const additionalIdentityStips = stipulations
      .filter((stipulation) => !!stipulation.contactId && stipulation.contactId !== contactId);

    const contacts = {};
    const allStipulations = [];

    additionalIdentityStips.forEach((stip) => {
      if (!Object.keys(contacts).includes(stip.contactName)) {
        contacts[stip.contactName] = [];
      }
      let template;
      if (!stip.fulfilled) {
        switch (stip.stipulationMap.customerFacingCopy) {
          case 'Proof of Address':
            template = cloneDeep(proofOfAddressTemplate);
            break;
          case 'Proof of Financials':
            template = cloneDeep(proofOfFinancialsTemplate);
            break;
          case 'Proof of Identity':
            template = cloneDeep(proofOfIdentityTemplate);
            break;
          default:
            break;
        }
        contacts[stip.contactName].push(template);
        allStipulations.push(template);
      }
    });

    const { uploadPending, uploadError, response } = this.props;
    const pageTitle = 'Additional identity verification';

    const groupDescription = (
      <span>
        For each person listed, please upload 2 of the 3 document types described below. Refer to each category for
        a list of acceptable documents.
        {' '}
        <strong>
          Please note that the same document cannot
          be used to fulfill an additional document type.
        </strong>
      </span>
    );

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <PageTitle text={pageTitle} />
        <StandardLayout
          contentElement={(
            <Col xs={12} sm={12} md={7}>
              <UploadGroupsOfDocumentsComponent
                groupDescription={groupDescription}
                minimumStipulationsRequiredPerGroup={2}
                uploadError={uploadError}
                uploadPending={uploadPending}
                response={response}
                stipulationGroups={contacts}
                submit={this.submit}
              />
            </Col>
          )}
          sidebarElement={<></>}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    accounts: state.accountItems.accounts,
    bfes: state.accountItems.bfes,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
    response: state.uploadDocument.response,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    getProductsAndBfes: getProductsAndBfesAction,
    push: pushAction,
    uploadBatchDocuments: uploadBatchDocumentsAction,
  },
)(SecurePage(AdditionalIdentificationPage));
