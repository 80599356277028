import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import resolve from '../../services/route.service';
import { closeNavMenu } from '../../util';
import routes from '../../routes';
import withUrlParams from '../../hocs/with-url-params';
import ActivityDropdown from './activity-dropdown/activity-dropdown';
import NotificationIcon from './notification-icon';
import ProfileDropdown from './profile-dropdown/profile-dropdown';
import './navigation.less';

const Navigation = ({ country }) => (
  <div className="vx_globalNav-navContainer">
    <nav className="vx_globalNav-nav" role="navigation">
      <ul className="vx_globalNav-list">
        <li>
          <Link
            data-automation-id="summaryPageLink"
            onClick={closeNavMenu}
            to={resolve(routes.summary.path, { country })}
            className="vx_globalNav-links bold-nav-links focus-highlight"
          >
            Summary
          </Link>
        </li>
        <ActivityDropdown />
        <li>
          <Link
            data-automation-id="oneTimePaymentPageLink"
            onClick={closeNavMenu}
            to={resolve(routes['one-time-payment'].path, { country })}
            className="vx_globalNav-links bold-nav-links focus-highlight"
          >
            One-Time Payment
          </Link>
        </li>
        <li>
          <Link
            data-automation-id="contactUsPageLink"
            onClick={closeNavMenu}
            to={resolve(routes['contact-us'].path, { country })}
            className="vx_globalNav-links bold-nav-links focus-highlight"
          >
            Contact Us
          </Link>
        </li>
      </ul>
      <ul className="vx_globalNav-list_secondary">
        <li className="vx_hidden-phone">
          <NotificationIcon />
        </li>
      </ul>
      <ProfileDropdown />
    </nav>
  </div>
);

Navigation.propTypes = {
  country: PropTypes.string,
};

Navigation.defaultProps = {
  country: undefined,
};

export default compose(
  withRouter,
  withUrlParams,
)(Navigation);
