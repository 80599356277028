import axios from 'axios';
import _ from 'lodash';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  getCaseTypes: asyncDebounce(getCaseTypes),
  submitCase,
};

async function getCaseTypes(apiUrl) {
  const res = await axios.get(`${apiUrl}/cases/types`, defaultRequestOptions());
  return _.sortBy(res.data, ['value']);
}

async function submitCase(apiUrl, accountId, caseType, message) {
  const res = await axios.post(`${apiUrl}/cases`,
    {
      accountId,
      type: caseType,
      description: message,
      subject: 'Request submitted through customer portal',

    }, defaultRequestOptions());
  return res.data;
}
