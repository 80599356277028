import React from 'react';
import PropTypes from 'prop-types';
import './body-text.less';
import { BodyText } from '@swift-paypal/pp-react';
import { ppp21LoanVersions } from '../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';

const PayrollSupportingBodyText = ({ loanVersion }) => (
  <div className="body-text">
    <BodyText as="p">
      Upload supporting documents to verify your payroll costs during the covered period,
      including documents that extend beyond the covered period, if necessary. Here&apos;s
      what you need to provide:
    </BodyText>
    <BodyText as="h4"><strong>Cash compensation paid</strong></BodyText>
    <ul>
      <BodyText as="li">
        Either through third-party payroll service provider reports or bank statements
        that overlap your selected covered period.
      </BodyText>
    </ul>
    <BodyText as="p" className="alignWithList">
      (Note: If cash tips or the equivalent are included in your cash compensation forgiveness
      request, please provide employer records of past tips or, in the absence of such
      records, a reasonable, good-faith estimate of such tips.)
    </BodyText>
    {ppp21LoanVersions.includes(loanVersion)
      ? (
        <BodyText as="h4">
          <strong>Tax forms (if you have employees)</strong>
        </BodyText>
      ) : (
        <BodyText as="h4">
          <strong>Tax forms</strong>
          {' '}
          (if included in your loan forgiveness application)
        </BodyText>
      )}
    <ul className="forgivenessListItems">
      <BodyText as="li">
        Tax forms (or equivalent third-party payroll service provider reports) for the periods
        that overlap with your covered period.
      </BodyText>
      <BodyText as="li">
        Payroll tax filings reported, or that you will report, to the IRS (typically Form 941)
        for your selected covered period.
      </BodyText>
    </ul>
    {ppp21LoanVersions.includes(loanVersion)
      ? (
        <BodyText as="h4">
          <strong>State tax forms (if you have employees)</strong>
        </BodyText>
      ) : (
        <BodyText as="h4">
          <strong>State tax forms</strong>
          {' '}
          (if included in your loan forgiveness application)
        </BodyText>
      )}
    <ul>
      <BodyText as="li">
        Quarterly business and individual employee wage and unemployment insurance tax filings
        reported, or that will be reported, to the relevant state(s) for your selected covered
        period.
      </BodyText>
    </ul>
    {ppp21LoanVersions.includes(loanVersion)
      ? (
        <BodyText as="h4">
          <strong>
            Employee insurance benefit and retirement plan contributions (if such costs
            included in your loan forgiveness application)
          </strong>
        </BodyText>
      ) : (
        <BodyText as="h4">
          <strong>Employee health insurance and retirement plan contributions</strong>
          {' '}
          (if included in your loan forgiveness application)
        </BodyText>
      )}
    <ul>
      {ppp21LoanVersions.includes(loanVersion)
        ? (
          <BodyText as="li">
            Payment receipts, cancelled checks, or account statements, documenting the amount of any employer
            contributions to employee group health, life disability, vision or dental insurance and retirement
            plans that the borrower included in the forgiveness amount.
          </BodyText>
        ) : (
          <BodyText as="li">
            Payment receipts, cancelled checks, or account statements documenting the amount of
            contributions for your selected covered period.
          </BodyText>
        )}
    </ul>
    {ppp21LoanVersions.includes(loanVersion)
      ? (
        <BodyText as="h4">
          <strong>For self-employment income – 1040 Schedule C and 1040 Schedule F Filers</strong>
        </BodyText>
      ) : (
        <BodyText as="h4">
          <strong>2019 IRS Form 1040C</strong>
          {' '}
          (for those individuals who file this IRS Tax Form)
        </BodyText>
      )}
    <ul>
      <BodyText as="li">
        In addition, you must provide bank statements and cancelled checks (or other
        documentation if you don&apos;t have bank statements or cancelled checks) to show that
        you paid the owner compensation to yourself during your forgiveness covered period.
      </BodyText>
    </ul>
  </div>
);

PayrollSupportingBodyText.propTypes = {
  loanVersion: PropTypes.string.isRequired,
};

export default PayrollSupportingBodyText;
