import {
  URL_BEFORE_API_WENT_DOWN_CREATED,
  URL_BEFORE_API_WENT_DOWN_DELETED,
  URL_BEFORE_LOGGED_OUT_CREATED,
  URL_BEFORE_LOGGED_OUT_DELETED,
} from './redirect-urls-action-types';

export const createUrlBeforeLoggedOut = (url) => (dispatch) => {
  dispatch({ type: URL_BEFORE_LOGGED_OUT_CREATED, url });
};

export const deleteUrlBeforeLoggedOut = () => (dispatch) => {
  dispatch({ type: URL_BEFORE_LOGGED_OUT_DELETED });
};

export const createUrlBeforeApiWentDown = (url) => (dispatch) => {
  dispatch({ type: URL_BEFORE_API_WENT_DOWN_CREATED, url });
};

export const deleteUrlBeforeApiWentDown = () => (dispatch) => {
  dispatch({ type: URL_BEFORE_API_WENT_DOWN_DELETED });
};
