import React, { memo } from 'react';
import { Col, Row } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import YesOrNoRadioField from '../../../common/yes-or-no-radio-field';
import { Sba3508SFormInstructionsLink } from '../sba3508SFormInstructionsLink';
import { fieldSelector } from '../sba3508SForm.selectors';
import { fieldNames } from './sba3508SFormFields.constants';

const ReviewedAdjustedForgivenessAmount = () => {
  const {
    reviewedAdjustedForgivenessAmount: fieldName,
    attestToReductions,
  } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  const attestToReductionsField = useShallowSelector(fieldSelector, attestToReductions);

  return attestToReductionsField?.registered && attestToReductionsField?.value === 'false' ? (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={content}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  ) : null;
};

const content = (
  <>
    Have you reviewed and, if required, adjusted your Requested Loan Forgiveness Amount
    according to the instructions provided in the
    {' '}
    <Sba3508SFormInstructionsLink
      formName="sba_form_3508.pdf"
      text="SBA Form 3508 Loan Forgiveness Application"
    />
    ?
  </>
);

export default memo(ReviewedAdjustedForgivenessAmount);
