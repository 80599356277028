import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { reset } from 'redux-form';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Col, Row } from '@swift-paypal/pp-react';
import resolve from '../services/route.service';
import { removeLocalStorage } from '../services/window.service';
import PageTitle from '../components/common/page-title';
import Title from '../components/common/title';
import ResetPasswordFormComponent from '../components/settings/password/reset-password-form';
import {
  getSecurityQuestionTokenAction,
  resetPasswordAction,
  clearResetPasswordAction,
  updateResetPasswordMessageAction,
  clearResetPasswordMessageAction,
  setResetToken as setResetTokenAction,
} from '../redux/actions/reset-password/reset-password-actions';
import AnonymousPage from '../hocs/anonymous-page';
import { AlertTypes } from '../components/common/alert';
import { verifyResetTokenAction } from '../redux/actions/auth/auth-actions';
import { addDeauthorized } from '../redux/actions/auth/auth-action-types';
import constants from '../constants';
import { createLoginPageMessageAction } from '../redux/actions/login/login-page-actions';
import util from '../util';
import routes from '../routes';
import displayNames from '../constants/displayNames';

const { errors } = constants;

class ResetPasswordPage extends Component {
  static displayName = displayNames.ResetPasswordPage;

  static propTypes = {
    addDeauthorizedToState: PropTypes.func.isRequired,
    clearResetPassword: PropTypes.func.isRequired,
    clearResetPasswordMessage: PropTypes.func.isRequired,
    createIndexPageMessage: PropTypes.func.isRequired,
    country: PropTypes.string,
    getSecurityQuestionToken: PropTypes.func.isRequired,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    push: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    resetToken: PropTypes.string,
    securityQuestion: PropTypes.string,
    setResetToken: PropTypes.func.isRequired,
    success: PropTypes.bool,
    token: PropTypes.string,
    updateResetPasswordMessage: PropTypes.func.isRequired,
    verifyResetToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: undefined,
    messageBody: undefined,
    messageType: undefined,
    resetToken: undefined,
    securityQuestion: undefined,
    success: false,
    token: undefined,
  };

  async componentDidMount() {
    const {
      addDeauthorizedToState,
      getSecurityQuestionToken,
      setResetToken,
      updateResetPasswordMessage,
      verifyResetToken,
    } = this.props;
    try {
      removeLocalStorage('token');

      addDeauthorizedToState();
      const { token } = this.props;

      if (token) {
        setResetToken(token);
        await verifyResetToken(token);
        await getSecurityQuestionToken(token);
      }
      else {
        updateResetPasswordMessage(errors.resetPasswordErrorMessage, AlertTypes.critical);
      }
    }
    catch (error) {
      updateResetPasswordMessage(errors.resetPasswordErrorMessage, AlertTypes.critical);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { country, createIndexPageMessage, push } = this.props;
    if (nextProps.success) {
      createIndexPageMessage('Password saved. You may now Log In.', AlertTypes.success);
      const RoutePayload = { country };
      push(resolve(routes.login.path, RoutePayload));
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    const { clearResetPassword, clearResetPasswordMessage } = this.props;
    clearResetPassword();
    clearResetPasswordMessage();
  }

  submit = async (values) => {
    const {
      resetForm,
      resetPassword,
      resetToken,
      securityQuestion,
    } = this.props;
    const { password, answer } = values;
    util.blurOnSubmit();
    resetForm();

    // must return promise for form submitting metadata to work
    return resetPassword(resetToken, password, securityQuestion, answer);
  };

  render() {
    const { messageType, messageBody, securityQuestion } = this.props;

    return (
      <div className="reset-password">
        <Helmet>
          <title>Reset LoanBuilder Password</title>
        </Helmet>
        <PageTitle text="Reset LoanBuilder Password" />
        <Row>
          <Col xs={12} sm={12} md={7}>
            <Title text="Enter and confirm your new password." />
            <ResetPasswordFormComponent
              messageBody={messageBody}
              securityQuestion={securityQuestion}
              messageType={messageType}
              onSubmit={this.submit}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    securityQuestion: state.resetPassword.question,
    resetToken: state.resetPassword.resetToken,
    messageBody: state.resetPassword.messageBody,
    messageType: state.resetPassword.messageType,
    success: state.resetPassword.resetPasswordSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (apiUrl, resetToken, password, question, answer) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      dispatch(resetPasswordAction(apiUrl, resetToken, password, question, answer)),
    clearResetPassword: () => {
      dispatch(clearResetPasswordAction());
    },
    resetForm: () => {
      dispatch(reset('reset-password'));
    },
    getSecurityQuestionToken: (resetToken) => {
      dispatch(getSecurityQuestionTokenAction(resetToken));
    },
    updateResetPasswordMessage: (body, type) => {
      dispatch(updateResetPasswordMessageAction(body, type));
    },
    clearResetPasswordMessage: () => {
      dispatch(clearResetPasswordMessageAction());
    },
    setResetToken: (resetToken) => {
      dispatch(setResetTokenAction(resetToken));
    },
    verifyResetToken: (resetToken) => {
      dispatch(verifyResetTokenAction(resetToken));
    },
    createIndexPageMessage: (...args) => dispatch(createLoginPageMessageAction(...args)),
    addDeauthorizedToState: () => {
      dispatch(addDeauthorized());
    },
    push: (path) => dispatch(pushAction(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousPage(ResetPasswordPage));
