import { format } from 'redux-form-validators';
import constants from '../../constants';

const { errors } = constants;
const validators = {
  ssnValidator: format({
    with: /^\d{3}-\d{2}-\d{4}$/,
    msg: errors.invalidSsn,
  }),
  sinValidator: format({
    with: /^\d{3}-\d{3}-\d{3}$/,
    msg: errors.invalidSin,
  }),
  postalCodeValidator: format({
    with: /^[a-zA-Z][0-9][a-xA-Z] [0-9][a-zA-Z][0-9]$/,
    msg: errors.invalidPostalCode,
  }),
};

export default validators;
