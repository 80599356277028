import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyText, CaptionText, Badge, LockIcon, Button, Row, Col,
} from '@paypalcorp/pp-react';
import { useTheme as useEmotionTheme } from '@emotion/react';
import {
  statusIcons as icons,
  paymentBadgesConfig,
} from '../../pages/oneTimePaymentPage/oneTimePayment.config';
import '../payments.less';
import PaypalBankIcon from './paypalBankIcon/paypalBankIcon';

export const RenderFIComponent = ({ fiInfo }) => {
  const { sysColorBackgroundMediumContrast } = useEmotionTheme();
  const fiBageConfig = paymentBadgesConfig[fiInfo?.BankAccountACHStatusDescription];
  return (
    <Row noGutters>
      <Col xs={4} sm={3}>
        <PaypalBankIcon bank={fiInfo.FinancialInstitutionName} />
      </Col>
      <Col xs={8} sm={9}>
        <BodyText style={{ width: '10rem' }}>{fiInfo?.FinancialInstitutionName || 'NA'}</BodyText>
        <CaptionText style={{ color: sysColorBackgroundMediumContrast }} className="routing-number">
          {fiInfo?.BankAccountNumber
            ? `Business Checking ••${fiInfo.BankAccountNumber?.slice(-4)}`
            : 'NA'}
        </CaptionText>
        {fiBageConfig && (
          <Badge
            type={fiBageConfig.type}
            leftIcon={icons[fiBageConfig.icon]}
            leftIconLabel={fiBageConfig.icon}
          >
            {fiInfo.BankAccountACHStatusDescription}
          </Badge>
        )}
      </Col>
    </Row>
  );
};

RenderFIComponent.propTypes = {
  fiInfo: PropTypes.shape({
    BankAccountACHStatusDescription: PropTypes.string,
    BankAccountNumber: PropTypes.string,
    FinancialInstitutionName: PropTypes.string,
  }).isRequired,
};

export const RenderFiContainer = ({ fiInfo, handleEditBank }) => (
  <div className="radio-label" key={fiInfo.Id}>
    <Row noGutters>
      <Col xs={8} sm={10}>
        <RenderFIComponent fiInfo={fiInfo} />
      </Col>
      <Col xs={4} sm={2}>
        {fiInfo.BankAccountACHStatus === 'Non-editable' ? (
          <LockIcon
            size="sm"
            style={{ margin: 'auto 0 auto 3rem' }}
            color="sysColorBackgroundMediumContrast"
          />
        ) : (
          <Button
            onClick={() => handleEditBank(fiInfo)}
            style={{ margin: 'auto 0 auto 2rem' }}
            tertiary
          >
            Edit
          </Button>
        )}
      </Col>
    </Row>
  </div>
);

RenderFiContainer.propTypes = {
  fiInfo: PropTypes.shape({
    BankAccountACHStatus: PropTypes.string,
    Id: PropTypes.string,
    BankAccountACHStatusDescription: PropTypes.string,
    BankAccountNumber: PropTypes.string,
    FinancialInstitutionName: PropTypes.string,
  }).isRequired,
  handleEditBank: PropTypes.func.isRequired,
};
