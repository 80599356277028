import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import resolve from '../../services/route.service';
import AnonymousPage from '../../hocs/anonymous-page';
import { getSsoTokenAction } from '../../redux/actions/auth/auth-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';

class OAuthPayPalPage extends Component {
  static displayName = displayNames.OAuthPayPalPage;

  static propTypes = {
    code: PropTypes.string,
    error: PropTypes.string,
    getSsoToken: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  };

  static defaultProps = {
    code: undefined,
    error: undefined,
  };

  async componentDidMount() {
    const {
      getSsoToken, history, location, code, error,
    } = this.props;
    if (code) {
      await getSsoToken(location, history, code);
    }
    else {
      const RoutePayload = {
        country: 'us',
        paypalAuthError: error,
      };
      history.push(resolve(routes.login.path, RoutePayload));
      getSsoToken(location, history);
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Log in with PayPal</title>
        </Helmet>
        <div className="padding-top-bottom-sm">
          <div className="vx_spinner-large center-content" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.authorize.error,
  };
}

export default connect(mapStateToProps, { getSsoToken: getSsoTokenAction })(AnonymousPage(OAuthPayPalPage));
