/* eslint-disable operator-assignment */
/* eslint-disable no-unused-vars */
import * as actions from '../../actions/application/verify-bank-accounts/verify-bank-accounts-action-types';
import constants from '../../../constants';

export function getBankInformation(state = {}, action) {
  switch (action.type) {
    case actions.GET_BANK_INFORMATION_RECEIVED:
      return { ...state, bankInformation: action.payload };
    case actions.GET_BANK_INFORMATION_REQUEST_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

export function getRoutingInstitution(state = {}, action) {
  switch (action.type) {
    case actions.GET_ROUTING_INSTITUTION_REQUEST_PENDING:
      // for pending requests, set institution to pending to not allow submission during that time
      switch (action.accountType) {
        case constants.bank.DEPOSIT:
          return { ...state, depositInstitution: constants.bank.ROUTING_NUMBER_PENDING };
        case constants.bank.WITHDRAWAL:
          return { ...state, withdrawalInstitution: constants.bank.ROUTING_NUMBER_PENDING };
        case constants.bank.ADDITIONAL:
          return { ...state, additionalInstitution: constants.bank.ROUTING_NUMBER_PENDING };
        default:
          return { ...state, error: true };
      }
    case actions.GET_ROUTING_INSTITUTION_RECEIVED:
      switch (action.accountType) {
        case constants.bank.DEPOSIT:
          return { ...state, depositInstitution: action.institution };
        case constants.bank.WITHDRAWAL:
          return {
            ...state,
            withdrawalInstitution: action.institution,
            hasRouteNumberWarning: action.hasRouteNumberWarning,
          };
        case constants.bank.ADDITIONAL:
          return { ...state, additionalInstitution: action.institution };
        default:
          return { ...state, error: true };
      }
    case actions.GET_ROUTING_INSTITUTION_REQUEST_ERROR:
      switch (action.accountType) {
        case constants.bank.DEPOSIT:
          return { ...state, depositInstitution: constants.bank.INVALID_ROUTING_NUMBER };
        case constants.bank.WITHDRAWAL:
          return { ...state, withdrawalInstitution: constants.bank.INVALID_ROUTING_NUMBER };
        case constants.bank.ADDITIONAL:
          return { ...state, additionalInstitution: constants.bank.INVALID_ROUTING_NUMBER };
        default:
          return { ...state, error: true };
      }
    case actions.GET_ROUTING_INSTITUTION_LIST_RECEIVED:
      return { ...state, institutionList: action.institutionList };
    case actions.GET_ROUTING_INSTITUTION_LIST_REQUEST_ERROR:
      return { ...state, institutionList: [] };
    default:
      return state;
  }
}

export function saveBankInformation(state = {}, action) {
  switch (action.type) {
    case actions.BANK_ACCOUNT_SAVE_PENDING:
      return { ...state, error: false };
    case actions.BANK_ACCOUNT_SAVE_RECEIVED:
      return { ...state, success: true, error: false };
    case actions.BANK_ACCOUNT_SAVE_ERROR:
      return {
        ...state,
        success: null,
        error: true,
      };
    default:
      return state;
  }
}

export function addBankInformation(state = {}, action) {
  switch (action.type) {
    case actions.BANK_ACCOUNT_ADD_PENDING:
      return { ...state, error: false };
    case actions.BANK_ACCOUNT_ADD_RECEIVED:
      return { ...state, success: true, error: false };
    case actions.BANK_ACCOUNT_ADD_RESET_ERROR:
      return {
        ...state,
        success: null,
        error: false,
        addBankErrorMessage: [],
      };
    case actions.BANK_ACCOUNT_ADD_ERROR:
    {
      const errorDetails = action.payload?.response?.data || [];
      const errorMessageSet = new Set();
      if (Array.isArray(errorDetails)) {
        errorDetails?.forEach((errCode) => {
          // eslint-disable-next-line prefer-template
          if (constants.errors.addBankErrorObject[errCode] !== undefined) {
            errorMessageSet.add(constants.errors.addBankErrorObject[errCode]);
          }
        });
      }
      if (errorMessageSet.size === 0) {
        errorMessageSet.add(constants.errors.technicalIssue);
      }
      const errorMessageArray = Array.from(errorMessageSet);
      return {
        ...state,
        success: null,
        error: true,
        addBankErrorMessage: errorMessageArray,
      };
    }
    default:
      return state;
  }
}
