import Cookie from 'js-cookie';

function getWindow() {
  return window;
}

// location
function redirect(destinationUrl) {
  window.location.href = destinationUrl;
}

function getLocation() {
  return window.location;
}

function getPathname() {
  return window.location.pathname;
}

function getSearch() {
  return window.location.search;
}

function openInNewTab(url) {
  window.open(url, '_blank');
}

// Open Files
function openFile(fileUrl) {
  window.open(fileUrl);
}

// ScrollTo
function scrollTo(x, y) {
  if (process.browser && window) {
    window.scrollTo({ left: x, top: y, behavior: 'smooth' });
  }
}

function scrollToWithOptions(options) {
  window.scrollTo(options);
}

function scrollToTop() {
  if (document && window) {
    scrollToWithOptions({
      top: 0,
      behavior: 'smooth',
    });
  }
}

function getCookie(name) {
  const pattern = new RegExp(`${name}=.[^;]*`);
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split('=');
    return cookie[1];
  }
  return undefined;
}

// useLocalStorage
function getLocalStorage(key) {
  return window.localStorage.getItem(key);
}

function setLocalStorage(key, defaultValue) {
  window.localStorage.setItem(key, defaultValue);
}

function removeLocalStorage(key) {
  window.localStorage.removeItem(key);
  Cookie.remove(key);
}

// eventListener
function addEventListener(event, handler) {
  window.addEventListener(event, handler);
}

function removeEventListener(event, handler) {
  window.removeEventListener(event, handler);
}

// interval
function setInterval(handler, timeout) {
  window.setInterval(handler, timeout);
}

function clearInterval(handler) {
  window.clearInterval(handler);
}

export {
  getWindow,
  redirect,
  getLocation,
  getPathname,
  getSearch,
  openFile,
  openInNewTab,
  scrollTo,
  scrollToWithOptions,
  scrollToTop,
  getCookie,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  addEventListener,
  removeEventListener,
  setInterval,
  clearInterval,
};
