import countryCodes from '../constants/countryCodes';
import stipulationGroupService from './stipulationGroup.service';

// This "service" should cease to exist once Salesforce corrects their data.
export default {
  correctAccountsStages,
  correctStipulationStages,
};

const stageOne = 'ST1';
const stageTwo = 'ST2';

function correctAccountsStages(location, accounts, flattenedStipulations, country) {
  if (!accounts || !accounts.length || !flattenedStipulations || !flattenedStipulations.length) {
    return;
  }

  accounts.forEach((account) => {
    if (!account || !account.inFlightOpportunities || !account.inFlightOpportunities.length) {
      return;
    }

    account.inFlightOpportunities.forEach((inFlightDeal) => {
      if (!inFlightDeal) {
        return;
      }

      const { opportunity } = inFlightDeal;
      if (!opportunity) {
        return;
      }

      const stipulations = flattenedStipulations.filter((stip) => stip.opportunityId === opportunity.id);
      correctStage(location, stipulations, opportunity);

      if (country.toUpperCase() === countryCodes.canada) {
        correctStipulationGroups(inFlightDeal, location, opportunity);
      }
    });
  });
}

function correctStipulationGroups(inFlightDeal, location, opportunity) {
  const stipulationGroups = stipulationGroupService
    .getCaStipulationGroups(inFlightDeal.contactId, location, opportunity.stipulations);
  if (!stipulationGroups || !stipulationGroups.length) {
    return;
  }

  if (opportunity.stage !== stageOne && stipulationGroups.some((stip) => !stip.completed)) {
    opportunity.stage = stageTwo;
  }
}

function correctStipulationStages(location, stipulations) {
  if (!stipulations || !stipulations.length) {
    return;
  }

  stipulations.forEach((stipulation) => {
    if (!stipulation) {
      return;
    }

    const { applications } = stipulation;
    if (!applications || !applications.length) {
      return;
    }

    applications.forEach((application) => {
      if (!application || !isStageCorrectable(application.stage)) {
        return;
      }

      const innerStipulations = application.stipulations;
      if (!innerStipulations || !innerStipulations.length) {
        return;
      }

      correctStage(location, innerStipulations, application);
    });
  });
}

function correctStage(location, stipulations, stageCarrier) {
  const bankAccountInfoStip = stipulations.find((stip) => stip.isBankAccountInformation);
  const bankAccountVerificationStip = stipulations.find((stip) => stip.isBankAccountVerification);
  const beneficialOwnerStip = stipulations.find((stip) => stip.type === 'BusinessOwnership');

  const params = new URLSearchParams(location.search);

  const successStipulationGroupName = params.get('successStipulationGroupName');
  const successStipulationName = params.get('successStipulationName');

  if (
    stageCarrier.stage !== stageOne
    && bankAccountInfoStip
    && !bankAccountInfoStip.isFulfilled
    && successStipulationGroupName !== bankAccountInfoStip.groupName
    && successStipulationName !== bankAccountInfoStip.name
  ) {
    stageCarrier.stage = stageTwo;
    return;
  }

  if (
    stageCarrier.stage !== stageOne
    && bankAccountVerificationStip
    && !bankAccountVerificationStip.isFulfilled
    && successStipulationGroupName !== bankAccountVerificationStip.groupName
    && successStipulationName !== bankAccountVerificationStip.name
  ) {
    stageCarrier.stage = stageTwo;
    return;
  }

  if (
    stageCarrier.stage !== stageOne
    && beneficialOwnerStip
    && !beneficialOwnerStip.isFulfilled
    && successStipulationGroupName !== beneficialOwnerStip.groupName
    && successStipulationName !== beneficialOwnerStip.name
  ) {
    stageCarrier.stage = stageTwo;
  }
}

function isStageCorrectable(stage) {
  const correctableStages = ['ST3', 'ST4', 'ST5', 'ST6'];

  return correctableStages.includes(stage);
}
