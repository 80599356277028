export const normalizeDate = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');
  if (numbers.length <= 2) {
    return numbers;
  }
  if (numbers.length <= 4) {
    return `${numbers.slice(0, 2)}/${numbers.slice(2)}`;
  }
  return `${numbers.slice(0, 2)}/${numbers.slice(2, 4)}/${numbers.slice(4, 8)}`;
};

export const normalizeYMD = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');
  if (numbers.length <= 4) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(2, 4)}/${numbers.slice(4, 6)}/${numbers.slice(0, 2)}`;
  }
  return `${numbers.slice(4, 6)}/${numbers.slice(6, 8)}/${numbers.slice(0, 4)}`;
};

export const normalizeCanadianDateOfBirth = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');
  if (numbers.length <= 4) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(0, 2)}/${numbers.slice(2)}`;
  }
  return `${numbers.slice(0, 4)}/${numbers.slice(4, 6)}/${numbers.slice(6, 8)}`;
};

export const normalizeSocialSecurity = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 5) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
  }
  return `${numbers.slice(0, 3)}-${numbers.slice(3, 5)}-${numbers.slice(5, 9)}`;
};

export const normalizeSocialInsurance = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
  }
  return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6, 9)}`;
};

export const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 7) {
    return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
  }
  return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
};

export const normalizeZip = (value) => {
  if (!value) {
    return value;
  }

  const numbers = value.replace(/[^\d]/g, '');

  return numbers.slice(0, 5);
};

export const normalizePostalCode = (value) => {
  if (!value) {
    return value;
  }

  const chars = value.replace(/[^a-zA-Z0-9]/g, '');
  if (chars.length <= 3) {
    return chars;
  }
  return `${chars.slice(0, 3)} ${chars.slice(3, 6)}`;
};

export const normalizeNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '');
};

export const normalizeNumericTextField = (value) => value.replace(/[^0-9]/, '');

export const normalizeAccountNumber = (value) => {
  if (!value) {
    return value;
  }

  return value.replace(/[^0-9a-zA-Z]/g, '');
};

export const normalizeRoutingNumber = (value) => {
  if (!value) {
    return value;
  }

  const numbers = normalizeNumericTextField(value);

  return numbers.slice(0, 9);
};

export const trimWhitespace = (value) => value.trim();

export default {
  normalizeAccountNumber,
  normalizeDateOfBirth: normalizeDate,
  normalizeSocialSecurity,
  normalizePhone,
  normalizeZip,
  normalizeNumericTextField,
  trimWhitespace,
};
