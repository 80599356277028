import React, { Component } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import { PAYPAL_THEME } from '@paypalcorp/pp-react';
import { ThemeProvider } from '@emotion/react';
import { history, store } from './redux/store';
import getRoutes from './routes.factory';
import './App.css';
import configSaved from './redux/actions/config/config-actions';
import RedirectPage from './pages/redirect';

class App extends Component {
  constructor(props) {
    super(props);
    const { config } = props;
    store.dispatch(configSaved(config));
  }

  render() {
    return (
      <ThemeProvider theme={PAYPAL_THEME}>
        <Provider store={store}>
          <HelmetProvider>
            <ConnectedRouter history={history}>
              <Switch>
                {getRoutes()}
                <Route component={RedirectPage} />
              </Switch>
            </ConnectedRouter>
          </HelmetProvider>
        </Provider>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  // eslint-disable-next-line
  config: PropTypes.any,
};

export default App;
