/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '@swift-paypal/pp-react';

const renderAttestationCheckField = ({
  input,
  id,
  className,
  value,
  label,
  meta: { touched, error, warning },
}) => (
  <Row>
    <Col xs={1} className="ownership-attestation">
      <div className={`vx_checkbox ${(touched && error) ? 'vx_has-error' : ''}`}>
        <input
          {...input}
          id={id}
          type="checkbox"
          value={value}
          className={className}
        />
        <label htmlFor={id}> </label>
      </div>
    </Col>
    <Col xs={11}>
      <label htmlFor={id} className={`clickable ${(touched && error) ? 'vx_has-error' : ''}`}>
        <em>{label}</em>
      </label>
    </Col>
    <Col xs={12} className={(touched && error) ? 'vx_has-error-with-message' : ''}>
      {touched
      && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
        || (warning && <span className="vx_form-control-message margin-bottom-sm">{warning}</span>))}
    </Col>
  </Row>
);

renderAttestationCheckField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  noErrorClass: PropTypes.string,
  value: PropTypes.bool,
};

renderAttestationCheckField.defaultProps = {
  className: undefined,
  input: undefined,
  meta: undefined,
  noErrorClass: undefined,
  value: false,
};

export default renderAttestationCheckField;
