import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import util, { closeNavMenu } from '../../../../util';
import resolve from '../../../../services/route.service';
import routes from '../../../../routes';
import { partnerNameSelector, useShallowSelector } from '../activity-dropdown-selectors';

const Loan = ({ advance }) => {
  const { country } = useParams();
  const partnerName = useShallowSelector(partnerNameSelector, advance?.opportunityId);

  return (
    <li className="loan">
      <Link
        onClick={closeNavMenu}
        to={resolve(routes.activity.path, {
          loanId: advance.id,
          country,
        })}
        data-automation-id="activityLink"
      >
        <div className="loan-details">
          <div className="loan-type">{util.getLoanName(partnerName)}</div>
          <div className="loan-number">{advance.name}</div>
        </div>
      </Link>
    </li>
  );
};

Loan.propTypes = {
  advance: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    opportunityId: PropTypes.string,
  }),
};

Loan.defaultProps = {
  advance: undefined,
};

export default Loan;
