import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import PropTypes from 'prop-types';
import { initialize } from 'redux-form';
import { Col, Row } from '@swift-paypal/pp-react';
import util from '../util';
import { setUpdatePasswordMessageAction, updatePasswordAction, verifyResetTokenAction } from '../redux/actions/auth/auth-actions';
import PageTitle from '../components/common/page-title';
import SetPasswordFormComponent from '../components/set-password-form';
import constants from '../constants';
import { AlertTypes, Alert } from '../components/common/alert';
import AnonymousPage from '../hocs/anonymous-page';
import resolve from '../services/route.service';
import { removeLocalStorage } from '../services/window.service';
import routes from '../routes';
import displayNames from '../constants/displayNames';

const { errors } = constants;
const { accounts } = constants;

class ResetAccountPage extends Component {
  static displayName = displayNames.ResetAccountPage;

  static propTypes = {
    country: PropTypes.string,
    email: PropTypes.string,
    push: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    resetTokenIsInvalid: PropTypes.bool,
    resetTokenMessageBody: PropTypes.string,
    resetTokenMessageType: PropTypes.string,
    setUpdatePasswordMessage: PropTypes.func.isRequired,
    successToken: PropTypes.string,
    token: PropTypes.string,
    updatePassword: PropTypes.func.isRequired,
    updatePasswordMessageBody: PropTypes.string,
    updatePasswordMessageType: PropTypes.string,
    verifyResetToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: undefined,
    email: undefined,
    resetTokenIsInvalid: false,
    resetTokenMessageBody: undefined,
    resetTokenMessageType: undefined,
    successToken: undefined,
    token: undefined,
    updatePasswordMessageBody: undefined,
    updatePasswordMessageType: undefined,
  };

  constructor(props) {
    super(props);
    const { token } = props;
    this.state = { title: accounts.resetAccount, inputToken: token };
  }

  async componentDidMount() {
    const { token, verifyResetToken } = this.props;
    removeLocalStorage('token');

    if (token) {
      await verifyResetToken(token);
    }
    else {
      this.createErrorMessage();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { country, push } = this.props;
    if (nextProps.successToken) {
      push(resolve(routes['security-questions'].path, { country }));
      return false;
    }

    return true;
  }

  async componentDidUpdate(prevProps) {
    const { inputToken } = this.state;
    const { resetTokenIsInvalid } = this.props;
    if (inputToken && resetTokenIsInvalid !== prevProps.resetTokenIsInvalid) {
      if (resetTokenIsInvalid) {
        this.createErrorMessage();
      }
    }
  }

  submit = (values) => {
    const { inputToken } = this.state;
    const { resetForm, updatePassword } = this.props;
    util.blurOnSubmit();

    if (inputToken && values && values.password) {
      updatePassword(inputToken, values.password);
    }
    resetForm();
  };

  createErrorMessage() {
    const { setUpdatePasswordMessage } = this.props;
    setUpdatePasswordMessage(errors.createError, AlertTypes.critical);
  }

  render() {
    const {
      resetTokenMessageBody,
      resetTokenMessageType,
      updatePasswordMessageBody,
      updatePasswordMessageType,
      email,
    } = this.props;

    const { inputToken, title } = this.state;

    if (!inputToken) {
      return null;
    }

    const displayMessage = resetTokenMessageBody && resetTokenMessageType;

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <PageTitle text={title} />
        <Row>
          <Col sm={12} md={7}>
            {displayMessage
              ? (<Alert message={resetTokenMessageBody} alertType={resetTokenMessageType} />)
              : (
                <SetPasswordFormComponent
                  hasToken={!!inputToken}
                  onSubmit={this.submit}
                  messageBody={updatePasswordMessageBody}
                  messageType={updatePasswordMessageType}
                  email={email}
                  showEmail
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resetTokenMessageBody: state.resetToken.messageBody,
  resetTokenMessageType: state.resetToken.messageType,
  updatePasswordMessageBody: state.updatePassword.messageBody,
  updatePasswordMessageType: state.updatePassword.messageType,
  successToken: state.updatePassword.token,
  resetTokenIsInvalid: state.resetToken.error,
  email: state.resetToken.email,
});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (token, password) => {
    dispatch(updatePasswordAction(token, password));
  },
  setUpdatePasswordMessage: (messageBody, messageType) => {
    dispatch(setUpdatePasswordMessageAction(messageBody, messageType));
  },
  resetForm: () => {
    dispatch(initialize('create-password'));
  },
  verifyResetToken: (resetToken) => {
    dispatch(verifyResetTokenAction(resetToken));
  },
  push: (path) => dispatch(pushAction(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousPage(ResetAccountPage));
