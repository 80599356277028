import React from 'react';
import { BodyText, Link } from '@swift-paypal/pp-react';
import { scheduleAWorksheetBodyTextSelector, useShallowSelector } from './schedule-a-worksheet-body-text-selectors';

const ScheduleAWorksheetBodyText = () => {
  const { loanbuilderUrl } = useShallowSelector(scheduleAWorksheetBodyTextSelector);

  return (
    <BodyText>
      You can choose to upload your completed
      {' '}
      <Link
        href={`${loanbuilderUrl}/sba_form_3508.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        data-automation-id="downloadScheduleAWorksheetLink"
      >
        Schedule A Worksheet
      </Link>
      {' '}
      as part of your PPP loan forgiveness application. If you have employees and have reduced the number of employees
      or their salaries/wages by more than 25% and/or have reduced the number of hours of your employees, we recommend
      uploading the worksheet as part of your forgiveness request.
    </BodyText>
  );
};

export default ScheduleAWorksheetBodyText;
