export default {
  customerCarePhoneNumber: '8338490314',
  BankInfoConstants: {
    AccountId: 'AccountId',
    AccountName: 'AccountName',
    BankAccount: {
      BusinessName: 'BusinessName',
      BankName: 'BankName',
      ACHRoutingNumber: 'ACHRoutingNumber',
      ReACHRoutingNumber: 'ReACHRoutingNumber',
      BankAccountNumber: 'BankAccountNumber',
      ReBankAccountNumber: 'ReBankAccountNumber',
      // Country: 'Country',
      StreetAddress1: 'StreetAddress1',
      StreetAddress2: 'StreetAddress2',
      State: 'State',
      City: 'City',
      Zip: 'Zip',
    },
  },
  BankInfoErrorFields: {
    BankAccount: {
      BusinessNameError: 'BusinessNameError',
      BankNameError: 'BankNameError',
      ACHRoutingNumberError: 'ACHRoutingNumberError',
      ReACHRoutingNumberError: 'ReACHRoutingNumberError',
      BankAccountNumberError: 'BankAccountNumberError',
      ReBankAccountNumberError: 'ReBankAccountNumberError',
      CountryError: 'CountryError',
      StreetAddress1Error: 'StreetAddress1Error',
      StreetAddress2Error: 'StreetAddress2Error',
      StateError: 'StateError',
      CityError: 'CityError',
      ZipError: 'ZipError',
    },
  },
  messages: {
    FAILED_INVALID_ROUTING_NUMBER: 'Incorrect Routing Number',
    FAILED_WIRE_ROUTING_NUMBER: 'This is a  wire routing number. Please enter ACH routing number',
    FAILED_ACCOUNT_MISMATCH:
      'Your Business name on our records does not match with the Business name in your bank records. To proceed, please update the correct Business name',
    FAILED_INVALID_ACCOUNT:
      'An error has occurred. Please try again or contact us at 8338490314 if the issue persists',
    FAILED_DEFAULT:
      'An error has occurred. Please try again or contact us at 8338490314 if the issue persists',
    INVALID_BANK_BUSINESS_NAME: 'Your Business name on our records does not match with the Business name in your bank records. To proceed, please update the correct Business name',
    SUCCESS_ADD_BANK: 'Your business bank account was successfully added',
    SUCCESS_EDIT_BANK: 'Your business bank account was successfully updated',
  },
  failedStatusList: ['FAILED_INVALID_ROUTING_NUMBER', 'FAILED_WIRE_ROUTING_NUMBER', 'FAILED_ACCOUNT_MISMATCH', 'FAILED_INVALID_ACCOUNT', 'FAILED_DEFAULT', 'INVALID_BANK_BUSINESS_NAME'],
  invalidDateErrorMessage: 'Date is invalid. Please use the calendar to select an available date.',
  transactionStatus: {
    R01: { message: 'There was an issue processing your request. The account has insufficient balance.', editBankCTA: false },
    R02: { message: 'This bank account is closed.', editBankCTA: true },
    R03: { message: 'The bank account number / routing number is not correct.', editBankCTA: true },
    R04: { message: 'The bank account number appears invalid.', editBankCTA: true },
    R05: { message: 'There was an issue processing your request. Your payment was not authorized. Please contact your business banking institution for more details.', editBankCTA: false },
    R06: { message: 'There was an issue processing your request.  Your payment was returned. Please select a different bank account for scheduling one time payment.', editBankCTA: false },
    R07: { message: 'There was an issue processing your request. Your payment was not authorized. Please contact your business banking institution for more details.', editBankCTA: false },
    R08: { message: 'There was an issue processing your request.  You stopped the payment.', editBankCTA: false },
    R09: { message: 'There was an issue processing your request. The account has an insufficient balance.', editBankCTA: false },
    R10: { message: 'There was an issue processing your request. The payment was not authorized by you. Please contact your business banking institution for more details.', editBankCTA: false },
    R11: { message: 'There was an issue processing your request. The payment request is not as per your authorization. Please contact your business banking institution for more details.', editBankCTA: false },
    R12: { message: 'There was an issue processing your request.  Your bank account details have been updated. Please contact your banking institution for more details.', editBankCTA: true },
    R13: { message: 'There was an issue processing your request. Your banking insitution does not support ACH payments.', editBankCTA: true },
    R14: { message: 'There is an issue processing your request. ', editBankCTA: false },
    R15: { message: 'There is an issue processing your request. ', editBankCTA: false },
    R16: { message: 'There was an issue processing your request. Your account is frozen. Please select a different bank account for scheduling one time payment. ', editBankCTA: false },
    R17: { message: 'There is an issue processing your request, your payment was returned by your business banking institution. ', editBankCTA: true },
    R20: { message: 'There is an issue processing your request, your payment was returned by your business banking institution. Please select a different bank account for scheduling one time payment.', editBankCTA: false },
    R21: { message: "There was an issue processing your request. Your company information doesn't match your business's bank records.", editBankCTA: true },
    R22: { message: "There was an issue processing your request. Your company information doesn't match your business's bank records.", editBankCTA: true },
    R24: { message: 'There was an issue processing your request. The payment has been returned by your business banking institution as Duplicate. Please contact your business banking institution for more details.', editBankCTA: false },
    R29: { message: 'There was an issue processing your request. The payment was not authorized by you. Please contact your business banking institution for more details.', editBankCTA: false },
    R31: { message: 'There was an issue processing your request. Please select a different bank account for one time payment.', editBankCTA: false },
    R33: { message: 'We are unable to process this transaction. Please select a different bank account for one time payment. ', editBankCTA: false },
    R34: { message: 'There was an issue processing your request.  Please contact your business banking institution for more details.', editBankCTA: false },
    R38: { message: 'There was an issue processing your request.  Please contact your business banking institution for more details.', editBankCTA: false },
    C01: { message: 'There was an issue processing your request. Your bank account number is incorrect.', editBankCTA: true },
    C02: { message: 'There was an issue processing your request. Your bank account routing number is incorrect.', editBankCTA: true },
    C03: { message: 'There was an issue processing your request. Your bank account number or routing number is incorrect.', editBankCTA: true },
    C05: { message: 'There was an issue processing your request. Please select a different bank account for your one-time payment.', editBankCTA: false },
    C06: { message: 'There was an issue processing your request. Your bank account number and/or routing number is incorrect.', editBankCTA: true },
  },
};
