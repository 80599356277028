import { reverse } from 'named-urls';
import buildQueryString from 'build-query-string';

const matchUrlParams = /:([A-za-z]+)/g;

export default function resolve(pathname, params) {
  const urlParams = {};
  const queryStringParams = {};
  const matches = [...pathname.matchAll(matchUrlParams)];

  if (!matches) {
    const queryString = buildQueryString(params);

    if (queryString === '') {
      return pathname;
    }

    return `${pathname}?${queryString}`;
  }

  matches.forEach((match) => {
    const name = match[1];
    if (Object.prototype.hasOwnProperty.call(params, name)) {
      urlParams[name] = params[name];
    }
  });

  Object.keys(params).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(urlParams, key)) {
      queryStringParams[key] = params[key];
    }
  });

  const url = reverse(pathname, urlParams);
  const queryString = buildQueryString(queryStringParams);

  return `${url}?${queryString}`;
}
