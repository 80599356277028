import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, change as changeAction } from 'redux-form';
import { connect } from 'react-redux';
import {
  Container, Row, Col, HeadingText, Link,
} from '@swift-paypal/pp-react';
import YesOrNoRadioField from '../../../../common/yes-or-no-radio-field';
import AverageFTEInputField from '../../fields/average-fte-input-field';
import './employment-and-business-activity.less';

const EmploymentAndBusinessActivityForm = ({
  change,
  children,
  fields,
  handleSubmit,
  loanbuilderUrl,
  selectedNoReductionInEmployeeAverageHours,
  selectedHasCoveredFteReductions,
  selectedFteSafeHarbor1,
  selectedFteSafeHarbor2,
  submitted,
  syncErrors,
  initialValues,
}) => {
  let displayCoveredReductionQuestion,
    displayAverageFTEQuestion;

  if (selectedNoReductionInEmployeeAverageHours === 'false') {
    displayCoveredReductionQuestion = true;
  }

  if (selectedNoReductionInEmployeeAverageHours === 'false'
    && selectedHasCoveredFteReductions === 'false'
    && selectedFteSafeHarbor1 === 'false'
    && selectedFteSafeHarbor2 === 'false') {
    displayAverageFTEQuestion = true;
  }

  const questions = {
    noReductionInEmployeeAverageHours: 'Have you maintained the same number of employees and the average '
      + 'paid hours of your employees between Jan 1, 2020 and the end of your covered period?',
    hasCoveredFteReductions: (
      <>
        Are the reductions covered under the
        {' '}
        <Link
          className="form-link"
          data-automation-id="sbaForm3508FirstLink"
          href={`${loanbuilderUrl}/sba_form_3508.pdf`}
          target="_blank"
        >
          FTE Reduction Exceptions
        </Link>
        ?
      </>
    ),
    fteSafeHarbor1: (
      <>
        Were you
        {' '}
        <strong>unable</strong>
        {' '}
        to operate between Feb 15, 2020 and the end of your covered period at the same level of business
        activity as before Feb 15, 2020 because of compliance with COIVD-19-related government guidance
        or requirements for maintaining standards of sanitation, social distancing, worker safety, or
        customer safety issued between Mar 1, 2020 - Dec 31, 2020?
      </>
    ),
    fteSafeHarbor2: (
      <>
        Do you satisfy Full-Time Equivalent (FTE) Reduction Safe Harbor 2? (See
        {' '}
        <Link
          className="form-link"
          data-automation-id="sbaForm3508SecondLink"
          href={`${loanbuilderUrl}/sba_form_3508.pdf`}
          target="_blank"
        >
          SBA Form 3508 Instructions
        </Link>
        {' '}
        for details)
      </>
    ),
    averageFteDuringChosenPeriod: (
      <>
        What was the Average FTE during your
        {' '}
        <Link
          className="form-link"
          data-automation-id="sbaForm3508ThirdLink"
          href={`${loanbuilderUrl}/sba_form_3508.pdf`}
          target="_blank"
        >
          chosen reference period
        </Link>
        ?
      </>
    ),
  };

  useEffect(() => {
    if (!displayAverageFTEQuestion && !submitted) {
      change('forgivenessPage4', 'averageFteDuringChosenPeriod', initialValues.averageFteDuringChosenPeriod);
    }
    if (!displayCoveredReductionQuestion) {
      change('forgivenessPage4', 'hasCoveredFteReductions', '');
    }
  }, [
    selectedNoReductionInEmployeeAverageHours,
    selectedHasCoveredFteReductions,
    selectedFteSafeHarbor1,
    selectedFteSafeHarbor2,
  ]);

  return (
    <Container form as="form" onSubmit={handleSubmit} noValidate className="employment-and-business-activity">
      <Row>
        <Col form="full">
          <HeadingText size="sm" as="h2">Tell us about your employment and business activity</HeadingText>
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <YesOrNoRadioField
            error={syncErrors.noReductionInEmployeeAverageHours}
            submitted={submitted}
            touched={fields.noReductionInEmployeeAverageHours?.touched}
            name="noReductionInEmployeeAverageHours"
            content={questions.noReductionInEmployeeAverageHours}
          />
        </Col>
      </Row>
      {displayCoveredReductionQuestion && (
        <Row>
          <Col form="full">
            <YesOrNoRadioField
              error={syncErrors.hasCoveredFteReductions}
              submitted={submitted}
              touched={fields.hasCoveredFteReductions?.touched}
              name="hasCoveredFteReductions"
              content={questions.hasCoveredFteReductions}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col form="full">
          <YesOrNoRadioField
            error={syncErrors.fteSafeHarbor1}
            submitted={submitted}
            touched={fields.fteSafeHarbor1?.touched}
            name="fteSafeHarbor1"
            content={questions.fteSafeHarbor1}
          />
        </Col>
      </Row>
      <Row>
        <Col form="full">
          <YesOrNoRadioField
            error={syncErrors.fteSafeHarbor2}
            submitted={submitted}
            touched={fields.fteSafeHarbor2?.touched}
            name="fteSafeHarbor2"
            content={questions.fteSafeHarbor2}
          />
        </Col>
      </Row>
      {displayAverageFTEQuestion && (
        <Row>
          <Col>
            <AverageFTEInputField
              error={syncErrors.averageFteDuringChosenPeriod}
              submitted={submitted}
              touched={fields.averageFteDuringChosenPeriod?.touched}
              content={questions.averageFteDuringChosenPeriod}
              name="averageFteDuringChosenPeriod"
            />
          </Col>
        </Row>
      )}
      {children}
    </Container>
  );
};

EmploymentAndBusinessActivityForm.defaultProps = {
  selectedNoReductionInEmployeeAverageHours: undefined,
  selectedHasCoveredFteReductions: undefined,
  selectedFteSafeHarbor1: undefined,
  selectedFteSafeHarbor2: undefined,
  submitted: false,
  fields: {},
  syncErrors: {},
  initialValues: {},
};

EmploymentAndBusinessActivityForm.propTypes = {
  selectedNoReductionInEmployeeAverageHours: PropTypes.string,
  selectedHasCoveredFteReductions: PropTypes.string,
  selectedFteSafeHarbor1: PropTypes.string,
  selectedFteSafeHarbor2: PropTypes.string,
  change: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  fields: PropTypes.objectOf(PropTypes.shape({
    touched: PropTypes.bool,
  })),
  handleSubmit: PropTypes.func.isRequired,
  loanbuilderUrl: PropTypes.string.isRequired,
  submitted: PropTypes.bool,
  syncErrors: PropTypes.shape({
    noReductionInEmployeeAverageHours: PropTypes.string,
    hasCoveredFteReductions: PropTypes.string,
    fteSafeHarbor1: PropTypes.string,
    fteSafeHarbor2: PropTypes.string,
    averageFteDuringChosenPeriod: PropTypes.number,
  }),
  initialValues: PropTypes.shape({
    averageFteDuringChosenPeriod: PropTypes.number,
    hasCoveredFteReductions: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  loanbuilderUrl: state.config.loanbuilderUrl,
  selectedNoReductionInEmployeeAverageHours: state.form?.forgivenessPage4?.values?.noReductionInEmployeeAverageHours,
  selectedHasCoveredFteReductions: state.form?.forgivenessPage4?.values?.hasCoveredFteReductions,
  selectedFteSafeHarbor1: state.form?.forgivenessPage4?.values?.fteSafeHarbor1,
  selectedFteSafeHarbor2: state.form?.forgivenessPage4?.values?.fteSafeHarbor2,
  syncErrors: state.form?.forgivenessPage4?.syncErrors,
});

const mapDispatchToProps = (dispatch) => ({
  change: (...args) => dispatch(changeAction(...args)),
});

export default compose(
  reduxForm({
    form: 'forgivenessPage4',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EmploymentAndBusinessActivityForm);
