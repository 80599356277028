import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Divider, Tile, Col, Row,
} from '@swift-paypal/pp-react';
import log from 'loglevel';
import LoanTerms from '../loan-terms/loan-terms';
import ProgressTracker from './progress-tracker';
import withUrlParams from '../../../hocs/with-url-params';
import opportunityConstants from '../../../constants/opportunity';
import { getOppStages } from '../../../constants/stages';
import { useShallowSelector } from '../../../hooks';
import { activeIdsSelector } from '../../../redux/selectors';
import { setActiveOpportunityId } from '../../../redux/actions/active-ids/active-ids-actions';
import util from '../../../util';
import './progress-tracker.less';

const ProgressTrackerTile = ({ opportunities }) => {
  const { opportunityId } = useShallowSelector(activeIdsSelector);
  const dispatch = useDispatch();

  const inFlightOpportunities = opportunities.filter((opp) => opp.isInFlight);
  const filteredOpportunities = inFlightOpportunities.filter((opportunity) => (
    opportunity.type !== 'PWL' && opportunity.stageName !== opportunityConstants.closedLost
  ));

  useEffect(() => {
    if (filteredOpportunities?.length === 1 && opportunityId === '') {
      dispatch(setActiveOpportunityId(filteredOpportunities[0].id));
    }
  }, [filteredOpportunities, opportunityId]);

  return (
    <>
      {filteredOpportunities.map((opportunity) => {
        const stages = getOppStages(util.formatPhoneNumber(opportunity.businessPhone));
        const stage = stages.find((st) => st.stageName === opportunity.cfStatusTrackerScreen);
        let showLoanTerms = true;

        if (opportunity.stageName === opportunityConstants.closedWon) {
          return null;
        }

        if (stage) {
          ({ showLoanTerms } = stage);
        }
        else {
          log.info(`Stage not set on opportunity with id ${opportunity.id}.`);
        }

        const isSba2Loan = ['21SDPPP', '21PPPLoan'].includes(opportunity.loanVersion);

        if (isSba2Loan) {
          showLoanTerms = false;
        }

        return (
          <Row align="center" key={opportunity.id}>
            <Col>
              <Tile card className="progress-tracker__tile">
                <Tile.Header className={`progress-tracker__loan-terms${opportunity.id !== opportunityId ? ' margin-bottom-override' : ''}`}>
                  <LoanTerms
                    opportunityId={opportunity.id}
                    showLoanTerms={showLoanTerms}
                    isSba2Loan={isSba2Loan}
                    dottedBottom
                  />
                </Tile.Header>
                {opportunity.id === opportunityId
                  && opportunity.stage !== 'CLOSED'
                  && (
                  <>
                    { showLoanTerms && <Divider secondary /> }
                    { (stage && opportunity.type !== 'SBA') && (
                    <Tile.Content>
                      <ProgressTracker
                        stages={stages}
                        activeStage={stage}
                      />
                    </Tile.Content>
                    )}
                  </>
                  )}
              </Tile>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  opportunities: Object.values(state.entities.opportunities?.byId || {}),
  stipulations: state.notifications.stipulations,
});

ProgressTrackerTile.propTypes = {
  opportunities: PropTypes.arrayOf(PropTypes.shape({
    isInFlight: PropTypes.bool.isRequired,
    stageName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })),
};

ProgressTrackerTile.defaultProps = {
  opportunities: [],
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(ProgressTrackerTile);
