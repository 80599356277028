import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Container } from '@swift-paypal/pp-react';
import PageTitle from '../../components/common/page-title';
import SecurePage from '../../hocs/secure-page';
import ChangeSecurityQuestionsFormComponent from '../../components/settings/security-questions/change-security-questions-form';
import { Alert, AlertTypes } from '../../components/common/alert';
import {
  changeSecurityQuestionsAction,
  updateSettingsMessageAction,
  listSecurityQuestionsAction,
  clearSettingsMessageAction,
} from '../../redux/actions/settings/settings-actions';
import util from '../../util';
import constants from '../../constants';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { scrollTo } from '../../services/window.service';

class ChangeSecurityQuestionsPage extends Component {
  static displayName = displayNames.ChangeSecurityQuestionsPage;

  static propTypes = {
    changeSecurityQuestions: PropTypes.func.isRequired,
    clearSettingsMessage: PropTypes.func.isRequired,
    listSecurityQuestions: PropTypes.func.isRequired,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    resetForm: PropTypes.func.isRequired,
    securityQuestions: PropTypes.shape({
      userQuestions: PropTypes.arrayOf(PropTypes.string),
      defaultQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    updateSettingsMessage: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
  };

  static defaultProps = {
    messageBody: undefined,
    messageType: undefined,
    securityQuestions: undefined,
    user: undefined,
  };

  async componentDidMount() {
    const { listSecurityQuestions } = this.props;
    listSecurityQuestions();
  }

  componentWillUnmount() {
    const { clearSettingsMessage } = this.props;
    clearSettingsMessage();
  }

  submit = (values) => {
    const {
      question1, answer1, question2, answer2, question1Custom, question2Custom,
    } = values;

    const {
      changeSecurityQuestions,
      clearSettingsMessage,
      resetForm,
      updateSettingsMessage,
    } = this.props;

    util.blurOnSubmit();

    if (util.securityQuestionsAreInvalid(values)) {
      updateSettingsMessage(constants.errors.securityQuestionsMatchError, AlertTypes.critical);
      scrollTo(0, 0);
      return;
    }
    clearSettingsMessage();

    resetForm();
    changeSecurityQuestions(question1Custom || question1, answer1, question2Custom || question2, answer2);
    scrollTo(0, 0);
  };

  render() {
    const
      {
        messageType,
        messageBody,
        securityQuestions,
      } = this.props;

    return (
      <div className="change-security-questions">
        <Helmet>
          <title>Change Security Questions</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <Container fluid>
              <PageTitle text="Change Security Questions" />
              <div className="vx_text-body-lg margin-bottom-sm">
                You must complete both questions in order to save your changes.
              </div>
              {messageBody && (
                <div>
                  <Alert message={messageBody} alertType={messageType} />
                </div>
              )}
              <ChangeSecurityQuestionsFormComponent onSubmit={this.submit} securityQuestions={securityQuestions} />
            </Container>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    messageBody: state.settings.messageBody,
    messageType: state.settings.messageType,
    securityQuestions: state.settings.securityQuestions,
    user: state.authorize.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeSecurityQuestions: (question1, answer1, question2, answer2) => {
      dispatch(changeSecurityQuestionsAction(question1, answer1, question2, answer2));
    },
    updateSettingsMessage: (body, type) => {
      dispatch(updateSettingsMessageAction(body, type));
    },
    listSecurityQuestions: () => {
      dispatch(listSecurityQuestionsAction());
    },
    clearSettingsMessage: () => {
      dispatch(clearSettingsMessageAction());
    },
    resetForm: () => {
      dispatch(initialize('change-security-questions'));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurePage(ChangeSecurityQuestionsPage));
