import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { BodyText, TextInput, Tooltip } from '@swift-paypal/pp-react';
import { createNumberMask } from 'redux-form-input-masks';
import { TOOLTIP_DESCRIPTION, TOOLTIP_NAME } from './total-cash-compensation-constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

const TotalCashCompensationField = ({
  box,
  error,
  submitted,
  table,
  touched,
}) => (
  <>
    <BodyText as="label" htmlFor={`totalCashCompensationTable${table}`}>
      {`Total Cash Compensation from Table ${table} `}
      &ndash;
      {` Box ${box}`}
    </BodyText>
    <Field
      key={`total-cash-compensation-table-${table}`}
      name={`totalCashCompensationTable${table}`}
      rightIcon={<Tooltip description={TOOLTIP_DESCRIPTION} name={TOOLTIP_NAME} />}
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      placeholder="$"
      validate={[
        required({ msg: `Enter value from Schedule A Worksheet, Table ${table} - Box ${box}` }),
      ]}
      {...currencyMask}
    />
  </>
);

TotalCashCompensationField.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
};

TotalCashCompensationField.propTypes = {
  box: PropTypes.number.isRequired,
  error: PropTypes.string,
  submitted: PropTypes.bool,
  table: PropTypes.number.isRequired,
  touched: PropTypes.bool,
};

export default memo(TotalCashCompensationField);
