export const forgivenessGrossReceiptsSelector = (state) => ({
  advances: state.entities.advances,
  activeAdvanceId: state.activeIds?.advanceId,
  apiUrl: state.config.apiUrl,
  fulfillStipulation: state.fulfillStipulation,
  fields: state.form?.reductionInGrossReceipts?.fields,
  opportunities: state.entities.opportunities,
  syncErrors: state.form?.reductionInGrossReceipts?.syncErrors,
  stipulationId: state.activeIds?.stipulationId,
  registeredFields: state.form?.reductionInGrossReceipts?.registeredFields,
  values: state.form?.reductionInGrossReceipts?.values || {},
});
