import axios from 'axios';
import constants from '../constants';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  login,
  getOAuthToken,
  getOAuthTokenMigratedFlow,
  answerSecurityQuestions,
  getSecurityQuestion,
  getSecurityQuestionByToken,
  forgotPassword,
  keepAlive,
  listSecurityQuestions,
  changeSecurityQuestions,
  createSecurityQuestions,
  updatePassword,
  verifyPassword,
  verifyResetToken: asyncDebounce(verifyResetToken),
  confirmPaypalAccount,
  expire,
  confirmPaypalAccountMigratedFlow,
};

// login methods

async function login(apiUrl, email, password, partner) {
  const res = await axios.post(`${apiUrl}/auth/login`, {
    email, password, partner, brand: constants.emailBrand, validatePartner: false,
  });
  return res.data;
}

async function getOAuthToken(apiUrl, code = '') {
  const url = `${apiUrl}/sso/paypal`;
  const res = await axios.post(url, { code, partner: 'paypal' });
  return res.data;
}

async function getOAuthTokenMigratedFlow(apiUrl, code = '') {
  const url = `${apiUrl}/sso/migrated-flow/paypal`;
  const res = await axios.post(url, { code, partner: 'paypal' });
  return res.data;
}

// security question methods

async function answerSecurityQuestions(apiUrl, answer, questionId) {
  const res = await axios.post(`${apiUrl}/auth/authorize`,
    { answer: encodeURIComponent(answer), questionId },
    defaultRequestOptions());
  return res.data;
}

async function getSecurityQuestion(apiUrl) {
  const res = await axios.post(`${apiUrl}/auth/security-question`, {}, defaultRequestOptions());
  return res.data;
}

async function getSecurityQuestionByToken(apiUrl, resetToken) {
  const res = await axios.post(`${apiUrl}/auth/security-question`, { token: resetToken });
  return res.data;
}

async function listSecurityQuestions(apiUrl) {
  const res = await axios.get(`${apiUrl}/auth/security-questions`, defaultRequestOptions());
  return res.data;
}

async function changeSecurityQuestions(apiUrl, question1, answer1, question2, answer2) {
  const body = [
    { question: question1, answer: encodeURIComponent(answer1) },
    { question: question2, answer: encodeURIComponent(answer2) },
  ];
  const res = await axios.post(`${apiUrl}/auth/change-security-questions`, { questions: body }, defaultRequestOptions());
  return res.data;
}

async function createSecurityQuestions(apiUrl, question1, answer1, question2, answer2) {
  const body = [
    { question: question1, answer: encodeURIComponent(answer1) },
    { question: question2, answer: encodeURIComponent(answer2) },
  ];
  const res = await axios.post(`${apiUrl}/auth/security-questions`, { questions: body }, defaultRequestOptions());
  return res.data;
}

// password methods

async function forgotPassword(apiUrl, email) {
  const res = await axios.post(`${apiUrl}/auth/forgot-password`, { email });
  return res.data;
}

async function updatePassword(apiUrl, token, password) {
  const res = await axios.post(`${apiUrl}/auth/password`, {
    token,
    password: encodeURIComponent(password),
  });
  return res.data;
}

async function verifyPassword(apiUrl, password) {
  const res = await axios.post(`${apiUrl}/auth/verify-password`, { password }, defaultRequestOptions());
  return res;
}

// reset token methods

async function verifyResetToken(apiUrl, token) {
  const res = await axios.get(`${apiUrl}/reset-token/${token}/partners`, defaultRequestOptions());
  return res;
}

// misc auth methods

async function confirmPaypalAccount(apiUrl, token) {
  const res = await axios.post(`${apiUrl}/paypal/confirm-account`, { token, partner: 'paypal' });
  return res;
}

async function confirmPaypalAccountMigratedFlow(apiUrl, token) {
  const res = await axios.post(`${apiUrl}/paypal/migrated-flow/confirm-account`, { token, partner: 'paypal' });
  return res;
}

// keep user token alive for another 15 minutes

async function keepAlive(apiUrl) {
  const res = await axios.post(`${apiUrl}/auth/keep-alive`, {}, defaultRequestOptions());
  return res;
}

// expire the current login session

async function expire(apiUrl) {
  const res = await axios.post(`${apiUrl}/auth/expire`, {}, defaultRequestOptions());
  return res;
}
