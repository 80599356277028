import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  getAcceptance: asyncDebounce(getAcceptance),
  getAttestation: asyncDebounce(getAttestation),
  getTermsAndConditions: asyncDebounce(getTermsAndConditions),
  getTermsOfUse: asyncDebounce(getTermsOfUse),
  getPrivacyPolicy: asyncDebounce(getPrivacyPolicy),
  saveAcceptance,
  saveAttestation,
};

async function getAcceptance(apiUrl) {
  const res = await axios.get(`${apiUrl}/users/acceptance`, defaultRequestOptions());
  return res.data;
}

async function getAttestation(apiUrl) {
  const res = await axios.get(`${apiUrl}/terms/attestation`, defaultRequestOptions());
  return res.data;
}

async function getTermsAndConditions(apiUrl, countryCode) {
  const res = await axios.get(`${apiUrl}/terms/terms-and-conditions/${countryCode}?include-content=true`,
    defaultRequestOptions());
  return res.data;
}

async function getTermsOfUse(apiUrl, countryCode) {
  const res = await axios.get(`${apiUrl}/terms/${countryCode}`, defaultRequestOptions());
  return res.data;
}

async function getPrivacyPolicy(apiUrl, countryCode) {
  const res = await axios.get(`${apiUrl}/privacy/${countryCode}`, defaultRequestOptions());
  return res.data;
}

async function saveAcceptance(apiUrl, documentId, opportunityId, ignoreRequestCount) {
  const res = await axios.post(`${apiUrl}/users/acceptance`, { documentId, opportunityId }, defaultRequestOptions(ignoreRequestCount));
  return res.data;
}

async function saveAttestation(apiUrl, attestationId, opportunityId, ignoreRequestCount) {
  const res = await axios.post(`${apiUrl}/terms/attestation`, { attestationId, opportunityId }, defaultRequestOptions(ignoreRequestCount));
  return res.data;
}
