import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Field, reduxForm, SubmissionError, destroy,
} from 'redux-form';
import {
  BodyText, Button, Col, HeadingText, RadioButton, Row, Tooltip,
} from '@swift-paypal/pp-react';
import constants from '../../constants';
import { Alert, AlertTypes } from '../common/alert';
import ownershipService from '../../services/ownership.service';
import { scrollTo } from '../../services/window.service';
import withUrlParams from '../../hocs/with-url-params';
import renderTermsCheckField from './forms/render-terms-check-field';
import renderAttestationCheckField from './forms/render-attestation-check-field';
import OwnershipFormComponent from './ownership-form';
import OwnershipTile from './ownership-tile';
import './ownership.less';

const ownershipErrors = constants.errors.ownership;
const businessOwnerAlert = 'error-business-owner';
const controllingManagerAlert = 'error-controlling-manager';
const businessOwnerForm = 'business-owner-form';
const controllingManagerForm = 'controlling-manager-form';
const businessRepresentativeForm = 'ownership-action-item-form';

export class OwnershipActionItemComponent extends React.PureComponent {
  static propTypes = {
    acceptance: PropTypes.arrayOf(PropTypes.object),
    attestation: PropTypes.shape({
      copy: PropTypes.string,
    }).isRequired,
    country: PropTypes.string,
    destroyForm: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.shape({
      isBeneficialOwner: PropTypes.bool,
    }),
    location: ReactRouterPropTypes.location.isRequired,
    ownershipError: PropTypes.bool,
    owners: PropTypes.arrayOf(PropTypes.object),
    setControllingManager: PropTypes.func,
    saveBusinessRepresentative: PropTypes.func,
    termsAndConditions: PropTypes.shape({
      version: PropTypes.string,
    }).isRequired,
    isSbaLoan: PropTypes.bool,
    showPPPLoan: PropTypes.bool,
  };

  static defaultProps = {
    acceptance: undefined,
    country: undefined,
    destroyForm: undefined,
    handleSubmit: undefined,
    initialValues: undefined,
    ownershipError: undefined,
    owners: undefined,
    setControllingManager: undefined,
    saveBusinessRepresentative: undefined,
    isSbaLoan: false,
    showPPPLoan: undefined,
  };

  constructor(props) {
    super(props);
    const { owners, isSbaLoan } = this.props;
    const requiredOwnershipPercentage = isSbaLoan ? 20 : 25;
    this.state = {
      hasUpdatedControllingManager: false,
      owners,
      requiredOwnershipPercentage,
    };
  }

  componentDidMount() {
    const {
      isSbaLoan,
    } = this.props;
    const { owners } = this.state;

    const applicant = _.head(owners.filter((x) => x.isApplicant));
    const applicantOwnership = +applicant.percentOwned;
    const totalOwnership = _.reduce(owners, (percentAmount, owner) => {
      percentAmount += +owner.percentOwned;
      return percentAmount;
    }, 0);
    let displayThreshold = 76;
    if (isSbaLoan) {
      displayThreshold = 81;
    }

    this.setState({
      owners,
      // dont display business owner section if applicant has displayThreshold value or higher ownership
      showBusinessOwnersSection: applicantOwnership < displayThreshold,
      // dont display the add a business owner button if total ownership is displayThreshold value or higher
      showAddBusinessOwnersButton: totalOwnership < displayThreshold,
      // dont display controlling manager section if applicant is controlling manager
      showBusinessManagementSection: !applicant.isControllingManager,
      // dont display term and conditions if already accepted
      showTermsAndConditions: this.shouldDisplayTermsAndConditions(),
    });
  }

  addBusinessOwner = () => {
    this.setState({
      showBusinessOwner: true,
      errorBusinessOwner: null,
      businessOwner: {
        initialValues: {
          isControllingManager: false,
          isBeneficialOwner: true,
        },
      },
      businessOwnerIndex: -1,
    });
  };

  closeBusinessOwner = () => {
    this.setState({
      showBusinessOwner: false,
      errorBusinessOwner: null,
      errorControllingManager: null,
      businessOwner: {
        initialValues: null,
      },
      businessOwnerIndex: null,
      editBusinessOwner: false,
    });
  };

  closeControllingManager = () => {
    this.setState({
      showControllingManager: false,
      errorBusinessOwner: null,
      errorControllingManager: null,
      controllingManager: {
        initialValues: null,
      },
      controllingManagerIndex: null,
      editControllingManager: false,
    });
  };

  addControllingManager = () => {
    this.setState({
      showControllingManager: true,
      errorControllingManager: null,
      controllingManager: {
        initialValues: {
          isControllingManager: true,
          isBeneficialOwner: false,
        },
      },
      controllingManagerIndex: -1,
    });
  };

  editBusinessOwner = (initialValues) => {
    const { destroyForm } = this.props;
    const {
      controllingManager,
      businessOwnerIndex,
      owners,
    } = this.state;

    if (controllingManager
      && initialValues === controllingManager.initialValues) {
      this.setState({
        errorBusinessOwner: ownershipErrors.editSameBusinessOwner,
      });
    }
    else {
      this.closeBusinessOwner();
      if (owners.indexOf(initialValues) !== businessOwnerIndex) {
        destroyForm(businessOwnerForm);
      }
      this.setState({
        showBusinessOwner: true,
        errorBusinessOwner: null,
        businessOwner: {
          initialValues,
        },
        businessOwnerIndex: owners.indexOf(initialValues),
        editBusinessOwner: true,
      });
    }
  };

  editControllingManager = (initialValues) => {
    const { destroyForm } = this.props;
    const {
      businessOwner,
      controllingManagerIndex,
      owners,
    } = this.state;

    if (businessOwner
      && initialValues === businessOwner.initialValues) {
      this.setState({
        errorControllingManager: ownershipErrors.editSameControllingManager,
      });
    }
    else {
      this.closeControllingManager();
      if (owners.indexOf(initialValues) !== controllingManagerIndex) {
        destroyForm(controllingManagerForm);
      }
      this.setState({
        showControllingManager: true,
        errorControllingManager: null,
        controllingManager: {
          initialValues,
        },
        controllingManagerIndex: owners.indexOf(initialValues),
        editControllingManager: true,
      });
    }
  };

  reOpenBusinessOwner = () => {
    this.closeBusinessOwner();
    this.addBusinessOwner();
  };

  reOpenControllingManager = () => {
    this.closeControllingManager();
    this.addControllingManager();
  };

  removeBusinessOwner = (owner) => {
    const {
      businessOwner,
      controllingManager,
      owners,
    } = this.state;

    // make sure owner is not currently being edited elsewhere
    if ((businessOwner && owner === businessOwner.initialValues)
      || (controllingManager && owner === controllingManager.initialValues)) {
      this.setState({
        errorBusinessOwner: ownershipErrors.deleteBusinessOwner,
      });
      this.scrollToView(businessOwnerAlert);
    }
    else {
      const ownerValues = owners.filter((x) => x.email !== owner.email && x.name.first !== owner.name.first
        && x.name.last !== owner.name.last);
      this.setState({
        owners: ownerValues,
        errorControllingManager: null,
      });
    }
  };

  removeControllingManager = (owner) => {
    const {
      businessOwner,
      controllingManager,
      owners,
    } = this.state;

    // make sure manager is not currently being edited elsewhere
    if ((businessOwner && owner === businessOwner.initialValues)
      || (controllingManager && owner === controllingManager.initialValues)) {
      this.setState({
        errorControllingManager: ownershipErrors.deleteControllingManager,
      });
      this.scrollToView(controllingManagerAlert);
    }
    else {
      const ownerValues = owners.filter((x) => x.email !== owner.email && x.name.first !== owner.name.first
        && x.name.last !== owner.name.last);
      this.setState({
        owners: ownerValues,
        errorControllingManager: null,
      });
    }
  };

  addOrUpdateBusinessOwner = (owner) => {
    const {
      businessOwnerIndex,
      owners,
    } = this.state;

    const ownerIndex = businessOwnerIndex;
    if (!ownerIndex || ownerIndex === -1 || ownerIndex >= owners.length) {
      owners.push(owner);
    }
    else {
      owners.splice(ownerIndex, 1, owner);
    }
    this.setState({
      owners,
      error: null,
    });
  };

  addOrUpdateControllingManager = (owner) => {
    const {
      controllingManagerIndex,
      owners,
      requiredOwnershipPercentage,
    } = this.state;
    const ownerIndex = controllingManagerIndex;
    // when a controlling manager has required minimum ownership, add to business owner
    owner.isBeneficialOwner = owner.percentOwned >= requiredOwnershipPercentage;

    if (!ownerIndex || ownerIndex === -1 || ownerIndex >= owners.length) {
      owners.push(owner);
    }
    else {
      owners.splice(ownerIndex, 1, owner);
    }

    this.setState({
      hasUpdatedControllingManager: true,
      owners,
      error: null,
    });
  };

  validateForm = (formValues) => {
    const {
      owners,
      showTermsAndConditions,
      showBusinessManagementSection,
    } = this.state;

    const {
      location,
      setControllingManager,
      saveBusinessRepresentative,
    } = this.props;
    const errors = {};
    let hasErrors = false;
    // validate t&c only when displayed
    if (showTermsAndConditions && !formValues.termsAndConditions) {
      errors.termsAndConditions = ownershipErrors.agreeTermsAndConditions;
      hasErrors = true;
    }
    if (!formValues.attestation) {
      errors.attestation = ownershipErrors.agreeOwnershipInformation;
      hasErrors = true;
    }

    // validate controlling manager only when displayed
    if (showBusinessManagementSection && !this.isControllingManagerSelected()) {
      errors.controllingManager = ownershipErrors.addControllingManager;
      hasErrors = true;
      setControllingManager(undefined);
      this.setState({
        errorControllingManager: ownershipErrors.addControllingManager,
      });
    }
    else {
      this.setState({
        errorControllingManager: null,
      });
    }
    if (hasErrors) {
      throw new SubmissionError(errors);
    }
    else {
      saveBusinessRepresentative({
        location,
        contacts: owners,
        saveAcceptance: showTermsAndConditions && formValues.termsAndConditions,
        saveAttestation: formValues.attestation,
      });
    }
  };

  isControllingManagerSelected = () => {
    const { owners } = this.state;
    return owners.find((o) => o.isControllingManager === true);
  }

  changeControllingManager = (event, newValue) => {
    let { owners } = this.state;
    owners = owners.map((o) => {
      o.isControllingManager = ownershipService.doOwnersMatch(o, JSON.parse(newValue));
      return o;
    });

    this.setState({ owners });
  };

  scrollToView = (id) => {
    if (process.browser && document && window) {
      const element = document.getElementById(id);
      if (element) {
        scrollTo(element.offsetLeft, element.offsetTop);
      }
    }
  };

  shouldDisplayTermsAndConditions() {
    const { acceptance, showPPPLoan } = this.props;

    if (showPPPLoan) return false;

    if (acceptance && acceptance.length > 0) {
      const completedAcceptances = _.filter(acceptance, (record) => _.get(record, 'document.version') === _.get(this.props, 'termsAndConditions.version'));
      return !(completedAcceptances.length > 0);
    }
    return true;
  }

  render() {
    const {
      attestation,
      country,
      ownershipError,
      handleSubmit,
      isSbaLoan,
      showPPPLoan,
    } = this.props;

    const { owners } = this.state;

    const {
      businessOwner,
      controllingManager,
      editBusinessOwner,
      editControllingManager,
      error,
      errorBusinessOwner,
      errorControllingManager,
      hasUpdatedControllingManager,
      showBusinessManagementSection,
      showAddBusinessOwnersButton,
      showBusinessOwnersSection,
      showBusinessOwner,
      showControllingManager,
      showTermsAndConditions,
      requiredOwnershipPercentage,
    } = this.state;

    return (
      <>
        <div className="vx_text-body-lg">
          {isSbaLoan && showPPPLoan
            ? (
              <>
                <BodyText as="h3">
                  You are being asked to provide additional information on your business
                  ownership structure because you have either indicated that you have additional
                  20% (or greater) owners of your business OR that you are not the controlling
                  manager for the organization.
                </BodyText>
                <BodyText as="h3">
                  If you have additional 20% (or greater) owners of your business, please add
                  them below. Once all owners have been added, please complete and submit this
                  form for our review.
                </BodyText>
                <BodyText as="h3">
                  If you are not the controlling manager, please add the controlling manager&apos;s
                  contact information below. This manager role has a significant impact on business
                  decisions. In a big business, it might be the CEO, or CFO. In a smaller business,
                  it might be the general manager or office manager.
                </BodyText>
                <BodyText as="h3">
                  We&apos;re required by law to verify the identity of key stakeholders in
                  any business that applies for loan funding.
                </BodyText>
              </>
            ) : (
              <BodyText as="h3">
                We&apos;re required by law to verify the identity of key stakeholders in
                any business that applies for funding.
              </BodyText>
            )}
        </div>
        <form id={businessRepresentativeForm}>
          {ownershipError
          && (
          <div className="margin-top-sm">
            <Alert message={constants.errors.technicalIssue} alertType={AlertTypes.critical} />
          </div>
          )}
          {showBusinessOwnersSection && (
            <div>
                {isSbaLoan && showPPPLoan
                  ? (
                    <>
                      <HeadingText size="sm">
                        Additional Business Owners
                      </HeadingText>
                      <BodyText as="h3">
                        Please identify everyone who owns 20% or more of your business.
                        If you are the sole owner of the business, you do not need to provide
                        any additional ownership information. Just complete and submit this
                        form for our review. The Authorized Representative of the applicant
                        submitting the application will not appear if their ownership is less
                        than 20%.
                      </BodyText>
                    </>
                  ) : (
                    <>
                      <span className="vx_text-body-md_medium">
                        Business Owners
                        {' '}
                        <Tooltip
                          description={constants.tooltips.businessOwnerTip}
                          className="pp-react__tooltip--inline"
                        />
                      </span>
                      <span>
                        Please identify everyone who owns
                        {' '}
                        {requiredOwnershipPercentage}
                        % or more of the business. Adding
                        someone here will not give them access to the loan.
                        {' '}
                      </span>
                      <span>
                        The applicant will not appear if their ownership is less than
                        {' '}
                        {requiredOwnershipPercentage}
                        %.
                      </span>
                    </>
                  )}
              <Row>
                <Col xs={12} id={businessOwnerAlert}>
                  {errorBusinessOwner
                  && (
                  <Alert
                    message={errorBusinessOwner}
                    alertType={AlertTypes.critical}
                  />
                  )}
                </Col>
              </Row>
              <div data-business-owner-list>
                {
                  owners && owners.map((owner) => (owner.isBeneficialOwner && (
                    <OwnershipTile
                      key={owner.email}
                      owner={owner}
                      editOwner={this.editBusinessOwner}
                      removeOwner={this.removeBusinessOwner}
                    />
                  )))
                }
              </div>
              {!showBusinessOwner && showBusinessOwnersSection && showAddBusinessOwnersButton
              && (
              <span>
                <button
                  className="button-link vx_anchor vx_embeddedIcon vx_embeddedIcon-simple clickable"
                  data-add-business-owner
                  type="button"
                  onClick={this.addBusinessOwner}
                >
                  <span className="vx_icon vx_icon-large vx_icon-add-small" />
                  Add a business owner
                </button>
              </span>
              )}
              {showBusinessOwner
              && (
              <div data-business-owner-form>
                <OwnershipFormComponent
                  owners={owners}
                  key="businessOwner"
                  initialValues={businessOwner.initialValues}
                  form={businessOwnerForm}
                  closeForm={this.closeBusinessOwner}
                  openForm={this.addBusinessOwner}
                  continueFunc={this.addOrUpdateBusinessOwner}
                  reopenForm={this.reOpenBusinessOwner}
                  editExisting={editBusinessOwner}
                  submitError={error}
                  isSbaLoan={isSbaLoan}
                />
              </div>
              )}
              <hr className="vx_hr" />
            </div>
          )}
          {(showBusinessManagementSection || hasUpdatedControllingManager)
          && (
          <div>
              {isSbaLoan && showPPPLoan
                ? (
                  <>
                    <HeadingText size="sm">
                      Business Management
                    </HeadingText>
                    <BodyText as="h3">
                      Please identify the controlling manager. If the controlling
                      manager is already listed below, or if you are the controlling
                      manager of your business, you do not need to provide any additional
                      information. Just complete and submit this form for our review.
                    </BodyText>
                  </>
                ) : (
                  <>
                    <span className="vx_text-body-md_medium">
                      Business Management
                      {' '}
                      <Tooltip description={constants.tooltips.businessManagementTip} className="pp-react__tooltip--inline" />
                    </span>
                    <span>
                      We need to understand this business&apos; management structure.
                      Please identify the controlling manager for this business.
                      If the controlling manager is not listed, please add them.
                    </span>
                  </>
                )}
            <Row>
              <Col xs={12} id={controllingManagerAlert}>
                {errorControllingManager
                && (
                <Alert
                  message={errorControllingManager}
                  alertType={AlertTypes.critical}
                />
                )}
              </Col>
            </Row>
            {owners && owners.map((owner) => (!owner.isApplicant && (
              <Row key={owner.email}>
                <Col xs={1}>
                  <Field
                    component={({ input, ...props }) => <RadioButton {...input} {...props} />}
                    onChange={this.changeControllingManager}
                    data-id="controllingManager"
                    options={[
                      {
                        className: 'do-not-record vx_has-error',
                        name: 'controllingManager',
                        label: '',
                        type: 'radio',
                        value: JSON.stringify(ownershipService.getUniqueOwnerKey(owner)),
                      },
                    ]}
                  />
                </Col>
                <Col xs={11} className="margin-top-xs">
                  <div data-controlling-manager-tile>
                    <OwnershipTile
                      owner={owner}
                      editOwner={this.editControllingManager}
                      removeOwner={this.removeControllingManager}
                    />
                  </div>
                </Col>
              </Row>
            )))}
            {!showControllingManager
            && (
            <span>
              <button
                className="button-link vx_anchor vx_embeddedIcon vx_embeddedIcon-simple clickable"
                data-add-controlling-manager
                type="button"
                onClick={this.addControllingManager}
              >
                <span className="vx_icon vx_icon-large vx_icon-add-small" />
                Or add a controlling manager
              </button>
            </span>
            )}
            {(showControllingManager)
            && (
            <div data-controlling-manager-form>
              <OwnershipFormComponent
                owners={owners}
                key="controllingManager"
                businessOwner={controllingManager
                  .initialValues.isBeneficialOwner}
                initialValues={controllingManager.initialValues}
                form={controllingManagerForm}
                closeForm={this.closeControllingManager}
                openForm={this.addControllingManager}
                continueFunc={this.addOrUpdateControllingManager}
                reopenForm={this.reOpenControllingManager}
                editExisting={editControllingManager}
                submitError={error}
                isSbaLoan={isSbaLoan}
              />
            </div>
            )}
            <hr className="vx_hr" />
          </div>
          )}
          <Field
            id="attestation"
            name="attestation"
            component={renderAttestationCheckField}
            label={attestation.copy}
            type="checkbox"
            className="vx_form-control form-control"
          />
          {showTermsAndConditions
          && (
          <Field
            id="termsAndConditions"
            name="termsAndConditions"
            component={renderTermsCheckField}
            type="checkbox"
            className="vx_form-control form-control"
            country={country}
          />
          )}
          <Row>
            <Col
              xs={{ offset: 2, span: 8 }}
              sm={{ offset: 3, span: 6 }}
              md={{ offset: 4, span: 4 }}
              className="margin-top-sm"
            >
              <Button
                type="submit"
                className="pp-react__button--block"
                data-submit-form
                form={businessRepresentativeForm}
                onClick={handleSubmit(this.validateForm)}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  destroyForm: (formName) => dispatch(destroy(formName)),
});

const mapStateToProps = (state) => ({
  initialValues: {
    controllingManager: state.ownership.controllingManager,
  },
  location: state.router.location,
});

const OwnershipComponent = reduxForm({
  form: businessRepresentativeForm,
  initialValues: {
    controllingManager: undefined,
  },
})(OwnershipActionItemComponent);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlParams,
)(OwnershipComponent);
