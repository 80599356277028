import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { push as pushAction } from 'connected-react-router';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import UploadMultipleDocumentsComponent from '../../components/upload-multiple-documents/upload-multiple-documents';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  uploadBatchDocumentsAction,
  clearUploadErrorAction, clearUploadPendingAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

const stipulationGroupName = 'ProofOfBusiness';

class ProofOfBusinessPage extends Component {
  static displayName = displayNames.ProofOfBusinessPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadError: PropTypes.bool,
    uploadPending: PropTypes.bool,
  };

  static defaultProps = {
    country: undefined,
    opportunityId: undefined,
    uploadError: false,
    uploadPending: true,
  };

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = (files) => {
    const { opportunityId, uploadBatchDocuments } = this.props;
    const payloads = [];
    files.forEach((file) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, stipulationGroupName));
      }
    });
    uploadBatchDocuments(payloads);
  };

  redirectToSummaryConfirmation() {
    const { country, push, opportunityId } = this.props;
    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const { uploadPending, uploadError } = this.props;
    const pageTitle = 'Proof of business';

    const groupDescription = 'Please upload a document to verify your business '
      + 'based on your entity type (e.g. sole-prop, partnership, corporation).';

    const fakeStipulation = {
      displayName: 'Verify your business',
      description: (
        <>
          <p>
            <strong>For a corporation upload one of the following: </strong>
            Articles of Incorporation, eCertificate of Corporate Status,
            Published Annual Report.
          </p>
          <p>
            <strong>For a partnership upload one of the following: </strong>
            Articles of Incorporation, Business Existence Documents,
            Partnership Agreement.
          </p>
          <p>
            <strong>For sole proprietors upload one of the following: </strong>
            Trade name registration confirmation.
          </p>
        </>
      ),
    };

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <PageTitle text={pageTitle} />
              <UploadMultipleDocumentsComponent
                groupDescription={groupDescription}
                uploadError={uploadError}
                uploadPending={uploadPending}
                stipulations={[fakeStipulation]}
                submit={this.submit}
              />
            </div>
          )}
          sidebarElement={<></>}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
  };
}

export default connect(mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    getProductsAndBfes: getProductsAndBfesAction,
    uploadBatchDocuments: uploadBatchDocumentsAction,
    push: pushAction,
  })(SecurePage(ProofOfBusinessPage));
