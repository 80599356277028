import React, { memo } from 'react';
import { BodyText, Col, Row } from '@swift-paypal/pp-react';
import YesOrNoRadioField from '../../common/yes-or-no-radio-field';
import { fieldNames } from './field-constants';
import { useShallowSelector, fieldSelector } from '../questionnaire-selectors';

const QuestionnaireQ1 = () => {
  const {
    questionnaireQ1: fieldName,
  } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  return (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={content}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  );
};

const content = (
  <BodyText>
    Are you self-employed, an independent contractor, or a sole proprietor
    who had no employees at the time of your PPP loan application and did
    not include any employee salaries in calculating your average monthly
    payroll in the Borrower Application Form (SBA Form 2483)?
  </BodyText>
);

export default memo(QuestionnaireQ1);
