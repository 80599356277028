import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import resolve from '../services/route.service';
import routes from '../routes';
import countryCodes from '../constants/countryCodes';
import displayNames from '../constants/displayNames';
import migratedFlowConstant from '../constants/migratedFlowConstant';

const us = countryCodes.unitedStates.toLowerCase();
const matchCountry = /^\/([A-Za-z]{2})\//;

class RedirectPage extends Component {
  static redirectToLogin() {
    return <Redirect to={resolve(routes.login.path, { country: us })} />;
  }

  static redirectToSummary(country) {
    return <Redirect to={resolve(routes.summary.path, { country })} />;
  }

  static displayName = displayNames.RedirectPage;

  static propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
  };

  redirectToDefaultCountry() {
    const { location } = this.props;
    const {
      pathname, search, hash, state,
    } = location;
    const destination = {
      search,
      hash,
      state,
      pathname: `/us${pathname}`,
    };
    return <Redirect to={destination} />;
  }

  render() {
    const { location } = this.props;
    const { pathname: url } = location;
    const queryParams = new URLSearchParams(location.search);
    // eslint-disable-next-line camelcase
    const utm_campaign = queryParams.get('utm_campaign');

    if (url === '/') {
      // eslint-disable-next-line camelcase
      if (utm_campaign) {
        // eslint-disable-next-line camelcase
        return <Redirect to={resolve(routes.login.path, { country: us, utm_campaign })} />;
      }

      return <Redirect to={resolve(routes.login.path, { country: us })} />;
    }

    const match = url.match(matchCountry);
    if (!match || match.length < 2) {
      return this.redirectToDefaultCountry();
    }

    const country = match[1];
    // const matchVersion = url.match(matchVersionRegex);
    const version = url.split('/')[2];
    // this is for email flow only where paypal-login for old flow
    // and /pp-app/paypal-login for new flow
    if (version === migratedFlowConstant.URL_APPEND) {
      return <Redirect to={resolve(routes.summary.path, { country, version })} />;
    }
    return <Redirect to={resolve(routes.summary.path, { country })} />;
  }
}

function mapStateToProps(state) {
  return {
    location: state.router.location,
  };
}

export default connect(mapStateToProps)(RedirectPage);
