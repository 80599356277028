import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import Title from '../common/title';
import EditableTile from '../common/editable-tile/editable-tile';
import util from '../../util';
import constants from '../../constants';
import { Alert, AlertTypes } from '../common/alert';
import resolve from '../../services/route.service';
import withUrlParams from '../../hocs/with-url-params';
import routes from '../../routes';
import checkHoverUs from '../../static/images/check-hover-us.png';
import BankAccountForm from './bank-account-form';
import PaymentDayForm from './payment-day-form';
import './verify-bank-accounts.less';

export class VerifyBankAccountsForm extends React.Component {
  static propTypes = {
    bankInformation: PropTypes.shape({
      deposit: PropTypes.object,
      withdrawal: PropTypes.object,
      paymentDay: PropTypes.string,
    }).isRequired,
    change: PropTypes.func.isRequired,
    country: PropTypes.string,
    depositInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    handleSubmit: PropTypes.func.isRequired,
    saveBankInfoError: PropTypes.bool,
    withdrawalInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    hasRouteNumberWarning: PropTypes.bool,
  };

  static defaultProps = {
    country: undefined,
    depositInstitution: undefined,
    saveBankInfoError: undefined,
    withdrawalInstitution: undefined,
    hasRouteNumberWarning: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      depositForm: false,
      withdrawalForm: false,
      paymentDay: false,
    };
  }

  showDepositForm = () => {
    const { change } = this.props;
    change(`${constants.bank.DEPOSIT}.routingNumber`, '');
    change(`${constants.bank.DEPOSIT}.accountNumber`, '');
    this.setState({ depositForm: true });
  };

  showWithdrawalForm = () => {
    const { change } = this.props;
    change(`${constants.bank.WITHDRAWAL}.routingNumber`, '');
    change(`${constants.bank.WITHDRAWAL}.accountNumber`, '');
    this.setState({ withdrawalForm: true });
  };

  showPaymentDay = () => {
    this.setState({ paymentDay: true });
  };

  getDepositForm = (depositForm, depositInstitution, bankInformation) => {
    if (depositForm) {
      return <BankAccountForm accountType={constants.bank.DEPOSIT} institution={depositInstitution} />;
    }

    if (bankInformation.deposit) {
      return (
        <EditableTile
          line1={bankInformation.deposit.isPaypalAccount
            ? 'PayPal account' : bankInformation.deposit.institution}
          line2={bankInformation.deposit.isPaypalAccount
            ? bankInformation.deposit.paypalUsername
            : util.prependAccountStars(bankInformation.deposit.lastFour)}
          canEdit={!bankInformation.deposit.isPaypalAccount}
          editPressed={this.showDepositForm}
        />
      );
    }
    return <></>;
  };

  getWithdrawalForm = (withdrawalForm, withdrawalInstitution, bankInformation, hasRouteNumberWarning) => {
    if (withdrawalForm) {
      return (
        <BankAccountForm
          accountType={constants.bank.WITHDRAWAL}
          institution={withdrawalInstitution}
          hasRouteNumberWarning={hasRouteNumberWarning}
        />
      );
    }

    if (bankInformation.withdrawal) {
      return (
        <EditableTile
          line1={bankInformation.withdrawal.isPaypalAccount
            ? 'PayPal account' : bankInformation.withdrawal.institution}
          line2={bankInformation.withdrawal.isPaypalAccount
            ? bankInformation.withdrawal.paypalUsername
            : util.prependAccountStars(bankInformation.withdrawal.lastFour)}
          canEdit={!bankInformation.withdrawal.isPaypalAccount}
          editPressed={this.showWithdrawalForm}
        />
      );
    }

    return <></>;
  };

  render() {
    const {
      bankInformation,
      handleSubmit,
      depositInstitution,
      withdrawalInstitution,
      country,
      saveBankInfoError,
      hasRouteNumberWarning,
    } = this.props;
    const { depositForm, withdrawalForm, paymentDay } = this.state;

    if (!depositForm && !bankInformation.deposit) {
      this.showDepositForm();
    }

    if (!withdrawalForm && !bankInformation.withdrawal) {
      this.showWithdrawalForm();
    }

    return (
      <div className="verify-bank-accounts-form">
        {saveBankInfoError && <Alert message={saveBankInfoError} alertType={AlertTypes.critical} />}
        <form onSubmit={handleSubmit}>
          <div>
            <Title
              text="Deposit Account"
              tooltipImage={checkHoverUs}
              imageClassName="check-image"
            />
            <div>
              {this.getDepositForm(depositForm, depositInstitution, bankInformation)}
            </div>
          </div>
          <div>
            <Title
              text="Withdrawal Account"
              tooltipImage={checkHoverUs}
              imageClassName="check-image"
            />
            <div>
              {this.getWithdrawalForm(withdrawalForm, withdrawalInstitution, bankInformation, hasRouteNumberWarning)}
            </div>
          </div>
          <div>
            <Title text="Payment Day" />
            <div>
              {paymentDay
                ? <PaymentDayForm />
                : (
                  <EditableTile
                    line1={bankInformation.paymentDay}
                    canEdit
                    editPressed={this.showPaymentDay}
                  />
                )}
            </div>
          </div>
          <Row className="margin-top-sm">
            <Col xs={6} sm={5} md={4}>
              <Button type="submit" className="pp-react__button--block">
                Submit
              </Button>
            </Col>
            <Col xs={6} sm={5} md={4}>
              <Link to={resolve(routes.summary.path, { country })}>
                <Button secondary type="button" className="pp-react__button--block">
                  Cancel
                </Button>
              </Link>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const VerifyBankAccountsFormComponent = reduxForm({
  form: 'verify-bank-accounts',
  asyncBlurFields: [`${constants.bank.DEPOSIT}.routingNumber`, `${constants.bank.WITHDRAWAL}.routingNumber`],
})(VerifyBankAccountsForm);

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(VerifyBankAccountsFormComponent);
