import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import YesOrNoRadioField from '../../../common/yes-or-no-radio-field';
import { excusedBusinessEntities, fieldNames } from './sba3508SFormFields.constants';
import { fieldSelector, opportunitySelector } from '../sba3508SForm.selectors';

const content = 'Have you, together with your affiliates, received PPP loans totaling $2 million or more?';

const ReceivedLargeFundingField = () => {
  const fieldName = fieldNames.receivedLargeFunding;
  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);
  const { opportunityId } = useParams();
  const { businessEntityType, numberOfEmployees } = useShallowSelector(opportunitySelector, opportunityId) || {};

  if (excusedBusinessEntities.includes(businessEntityType) && numberOfEmployees === 1) {
    return null;
  }

  return (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={content}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  );
};

export default memo(ReceivedLargeFundingField);
