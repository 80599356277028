import _ from 'lodash';
import { AlertTypes } from '../../components/common/alert';
import * as actions from '../actions/account-items/account-items-action-types';
import opportunityService from '../../services/opportunity.service';
import opportunityConstants from '../../constants/opportunity';

const { closedLost, closedWon } = opportunityConstants;

function accountItems(state = {}, action) {
  switch (action.type) {
    case actions.PRODUCTS_RECEIVED:
      return {
        ...state,
        accountsPending: false,
        accounts: sortAccounts(mapProducts(action.payload)),
        accountsError: null,
        inFlightOpportunities: action.payload.filter(
          (product) => opportunityService.shouldShowStatusTracker(product.opportunity),
        ),
      };
    case actions.PRODUCTS_REQUEST_ERROR:
      return { ...state, accountsPending: false, accountsError: actions.PRODUCTS_REQUEST_ERROR };
    case actions.PRODUCTS_REQUEST_PENDING:
      return { ...state, accountsPending: true };
    case actions.BFES_REQUEST_ERROR:
      return { ...state, bfesError: actions.BFES_REQUEST_ERROR };
    case actions.BFES_RECEIVED:
      return { ...state, bfes: action.payload, bfesError: null };
    case actions.ACCOUNT_RENEWAL_RECEIVED:
      return { ...state, renewalApplicationUrl: action.payload };
    case actions.ACCOUNT_RENEWAL_ERROR:
      return { ...state, error: actions.ACCOUNT_RENEWAL_ERROR };
    case actions.LOAN_REQUEST_RECEIVED:
      return { ...state, selectedActivityLoan: action.payload, loanRequestError: null };
    case actions.LOAN_REQUEST_ERROR:
      return { ...state, loanRequestError: actions.LOAN_REQUEST_ERROR };
    case actions.LOAN_REQUEST_CLEAR:
      return {
        ...state, selectedActivityLoan: null, loanRequestError: null, error: null,
      };
    case actions.TRANSACTIONS_REQUEST_PENDING:
      return { ...state, transactionsPending: true };
    case actions.TRANSACTIONS_RECEIVED:
      return {
        ...state, transactions: action.payload, transactionsError: null, transactionsPending: false,
      };
    case actions.TRANSACTIONS_REQUEST_ERROR:
      return { ...state, transactionsError: actions.TRANSACTIONS_REQUEST_ERROR, transactionsPending: false };
    case actions.CONTACTS_REQUEST_RECEIVED:
      return { ...state, opportunityContacts: action.payload, contactsError: null };
    case actions.CONTACTS_REQUEST_ERROR:
      return { ...state, contactsError: actions.CONTACTS_REQUEST_ERROR };
    case actions.CONTACTS_REQUEST_CLEAR:
      return {
        ...state, opportunityContacts: null, error: null, contactsError: null,
      };
    case actions.CREATE_CONTACTS_REQUEST_RECEIVED:
      return { ...state, vendorReferences: { messageBody: 'Your references have been submitted!', messageType: AlertTypes.success } };
    case actions.CREATE_CONTACTS_REQUEST_ERROR:
      return { ...state, vendorReferences: { messageBody: 'Failed to submit your references; please try again.', messageType: AlertTypes.critical } };
    default:
      return state;
  }
}

function mapProducts(products) {
  return _(products)
    .groupBy((x) => x.accountId)
    .map((value, key) => ({
      accountId: key,
      accountName: _.first(value).accountName,
      loans: _.orderBy(value.filter((product) => product.loanId), ['issuedDate'], ['desc']),
      inFlightOpportunities: _.orderBy(mapApplications(value), ['applicationReceivedDate'], ['desc']),
      hasClosedLostOpportunities: value.filter((product) => product.opportunity.stageName === closedLost).length !== 0,
      hasOpenOpportunities: value.filter(
        (product) => product.opportunity.stageName !== closedWon && product.opportunity.stageName !== closedLost,
      ).length !== 0,
    })).value();
}

function mapApplications(product) {
  return product.filter((x) => opportunityService.shouldShowStatusTracker(x.opportunity));
}

function sortAccounts(accounts) {
  _(accounts).forEach((account) => {
    account.recentLoan = account.loans.length ? _.first(account.loans).opportunity.closedDate : null;
    account.hasRenewal = account.loans.length
      ? account.loans.filter((loan) => loan.renewalEligible && loan.primaryLoanForRenewal).length > 0
      : false;
    account.hasOpenApplications = account.inFlightOpportunities.length > 0;
    account.recentApplicationDate = account.hasOpenApplications
      ? _.first(account.inFlightOpportunities).applicationReceivedDate : null;
  });
  // order accounts by
  // 1. accounts that have open application
  //    1.1 recent application on top
  // 2. accounts that are eligible for renewal
  // 3. accounts that have recent funded loan
  // 4. default sort by account name alphabetically
  return _.orderBy(accounts,
    ['hasOpenApplications', 'recentApplicationDate', 'hasRenewal', 'recentLoan', 'accountName'],
    ['desc', 'desc', 'desc', 'desc', 'asc']);
}

export default accountItems;
