import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { BodyText, RadioButton } from '@swift-paypal/pp-react';
import constants from '../../../../../constants';
import './maintained-hourly-wage.less';

const { errors } = constants;

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <RadioButton {...input} {...props} />;

function CoveredPeriodText({ coveredStart, coveredEnd }) {
  return (` ${coveredStart} \u2013 ${coveredEnd}`
  );
}

const MaintainedHourlyWageField = ({
  error, submitted, touched, coveredStart, coveredEnd,
}) => (
  <>
    <BodyText as="label" htmlFor="maintainedHourlyWage">
      Was the average salary or hourly wage for each employee listed on the PPP Schedule A
      Worksheet, Table 1 during the period between
      <CoveredPeriodText coveredStart={coveredStart} coveredEnd={coveredEnd} />
      {' '}
      at least 75%
      of such employee&apos;s average annual salary or hourly wage for the most recent full
      quarter before the start of your selected covered period?
    </BodyText>
    <Field
      key="maintained-hourly-wage"
      className="maintained-hourly-wage-radio"
      name="maintainedHourlyWage"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      validate={[required({ msg: errors.chooseYesOrNo })]}
      options={[
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ]}
    />
  </>
);

MaintainedHourlyWageField.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
};

MaintainedHourlyWageField.propTypes = {
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  coveredStart: PropTypes.string.isRequired,
  coveredEnd: PropTypes.string.isRequired,
};

export default memo(MaintainedHourlyWageField);
