import React, { Component } from 'react';
import { isMobile, isAndroid } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { CaptionText } from '@swift-paypal/pp-react';
import './upload-single-document.less';
import cloudIcon from '../../../static/images/icons/cloud-icon.svg';
import DocumentErrors from './document-errors/document-errors';
import { CreditStipulations } from '../../features/stipulations/stipulations.constants';

class UploadSingleDocumentComponent extends Component {
  constructor() {
    super();
    this.emptyFile = {
      expanded: true,
      fileBlob: undefined,
      isValid: undefined,
      errors: [],
    };
    this.dropZoneRef = React.createRef();
  }

  openFileSelector = (e) => {
    e.preventDefault();
    setTimeout(this.dropZoneRef.current.open(), 0);
  };

  stageFile = (fileBlob) => {
    const { onDrop } = this.props;
    onDrop({ ...this.emptyFile, fileBlob });
  }

  onChange = (e) => {
    if (e.target.files && e.target.files.length) {
      this.stageFile(e.target.files[0]);
    }
  };

  onDrop = (fileBlobs) => {
    if (isMobile || isAndroid) {
      return;
    }
    this.stageFile(fileBlobs[0]);
  };

  removeFile = (e) => {
    const { removeFile } = this.props;
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    removeFile({ ...this.emptyFile });
  };

  showHide = (e, expanded = false) => {
    const { showHide } = this.props;
    if (!expanded) showHide(e);
  };

  renderArrow = (stipulation, expanded) => {
    if (expanded) {
      return (<div className="icon-arrow vx_icon vx_icon-arrow-up-half-small" />);
    }
    if (stipulation.isFulfilled) {
      return null;
    }

    return (<div className="icon-arrow vx_icon vx_icon-arrow-down-half-small" />);
  }

  render() {
    const {
      stipulation,
      fileStaged,
      allowedFileTypes,
      allowedFileSize,
      showBorderTop,
      showBorderBottom,
    } = this.props;
    const showErrors = !!fileStaged.errors.length;
    if (showErrors) {
      log.error(`fileStagedErrors: ${fileStaged.errors}`);
    }
    const expanded = stipulation.isFulfilled ? false : fileStaged.expanded;

    return (
      <div className={
        `upload-single-document-container ${expanded ? ''
          : 'upload-area-collapsed'} vx_foreground-container ${showBorderTop} ${showBorderBottom}`
      }
      >
        {/* title row */}
        <div
          className={`title-container ${expanded ? 'margin-bottom-sm' : ''}`}
          role="button"
          tabIndex="0"
          onClick={this.showHide}
          onKeyDown={(e) => this.showHide(e, expanded)}
        >
          {this.renderArrow(stipulation, expanded)}

          <div className={`name-container ${stipulation.isFulfilled ? 'fulfilled-container' : ''}`}>
            <button
              onClick={(e) => e.preventDefault()}
              onKeyDown={(e) => e.preventDefault()}
              type="button"
              tabIndex="-1"
              className="button-link secondary-gray vx_text-body_secondary"
            >
              {stipulation.displayName || stipulation.name}
            </button>
            {fileStaged.isValid && !expanded
              && <span className="vx_icon vx_icon-large vx_icon-positive-large fulfilled" />}
            {!fileStaged.isValid && !expanded && fileStaged.errors.length > 0
              && <span className="vx_icon vx_icon-large vx_icon-critical-large error-color" />}
          </div>
        </div>

        {/* subtitle row */}
        {(stipulation.description || stipulation.customerFacingDescription)
          && expanded
          && (
            <div className="secondary-gray margin-bottom-sm">
              {stipulation.description || stipulation.customerFacingDescription}
            </div>
          )}
        {/* dropzone */}
        <div className={`dropzone-container ${expanded ? '' : 'display-none'}`}>
          <Dropzone ref={this.dropZoneRef} onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className="single-document-dropzone-area" {...getRootProps()}>
                <div>
                  {fileStaged.fileBlob

                    ? (
                      <div className={`${showErrors ? 'dropzone-file-info-container' : ''} align-center`}>
                        {fileStaged.isValid
                          ? <div className="vx_icon vx_icon-large vx_icon-positive-large fulfilled" />
                          : <div className="cw_icon cw_icon-invoice invoice" />}
                        <div className={`${showErrors ? 'dropzone-file-name' : ''} vx_text-body-sm_medium`}>
                          {fileStaged.fileBlob.name}
                        </div>
                        <CaptionText>
                          <button
                            className="button-link ppvx_link pp-react__link--inline"
                            type="button"
                            tabIndex="0"
                            onClick={this.removeFile}
                          >
                            Remove file
                          </button>
                        </CaptionText>
                      </div>
                    )

                    : (
                      <div>
                        <div className="align-center">
                          <img alt="Cloud icon" className="cloud" src={cloudIcon} />
                        </div>
                        <div>
                          <input {...getInputProps()} onChange={this.onChange} />
                          {!isMobile && !isAndroid && <div className="secondary-gray">Drag & drop</div>}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>

        {/* bottom button and info row */}
        {expanded && (
          <div>
            <div className="file-info-container vx_text-body-sm_medium">
              <div>
                <button className="button-link ppvx_link" type="button" tabIndex="0" onClick={this.openFileSelector}>Browse files</button>
              </div>
              {stipulation.name === CreditStipulations.BankStatements ? (
                <div className="secondary-gray">
                  Upload only digitally created PDFs (no PDFs of scanned documents) |
                  {' '}
                  {Math.floor((allowedFileSize / 1000000))}
                  {' '}
                  MB total
                </div>
              ) : (
                <div className="secondary-gray">
                  {allowedFileTypes.join(', ')}
                  {' '}
                  |
                  {' '}
                  {Math.floor((allowedFileSize / 1000000))}
                  MB total
                </div>
              )}
            </div>
            {fileStaged.errors.length > 0 && <DocumentErrors errors={fileStaged.errors[0]} />}
          </div>
        )}
      </div>
    );
  }
}

UploadSingleDocumentComponent.propTypes = {
  allowedFileSize: PropTypes.number.isRequired,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileStaged: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.any),
    expanded: PropTypes.bool,
    fileBlob: PropTypes.object,
    isValid: PropTypes.bool,
  }),
  onDrop: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  showBorderBottom: PropTypes.string,
  showBorderTop: PropTypes.string,
  showHide: PropTypes.func.isRequired,
  stipulation: PropTypes.shape({
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    displayName: PropTypes.string,
    isFulfilled: PropTypes.bool,
    name: PropTypes.string,
    customerFacingDescription: PropTypes.string,
  }).isRequired,
};

UploadSingleDocumentComponent.defaultProps = {
  fileStaged: undefined,
  showBorderBottom: undefined,
  showBorderTop: undefined,
};

export default UploadSingleDocumentComponent;
