import axios from 'axios';
import authService from './auth.service';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  addBusinessWebAddress: asyncDebounce(addBusinessWebAddress),
  addHomePhone: asyncDebounce(addHomePhone),
  getContactAccounts: asyncDebounce(getContactAccounts),
  getHomePhoneAndBusinessWebAddress: asyncDebounce(getHomePhoneAndBusinessWebAddress),
  changePassword,
  changeEmail,
  resetPassword,
};

async function addBusinessWebAddress(apiUrl, websiteUrl, contactId) {
  const res = await axios.post(`${apiUrl}/contacts/${contactId}`, { businessWebAddress: websiteUrl }, defaultRequestOptions());
  return res.data;
}

async function addHomePhone(apiUrl, homePhoneNumber, contactId) {
  const res = await axios.post(`${apiUrl}/contacts/${contactId}`, { homePhone: homePhoneNumber }, defaultRequestOptions());
  return res.data;
}

async function getHomePhoneAndBusinessWebAddress(apiUrl, contactId) {
  const res = await axios.get(`${apiUrl}/contacts/${contactId}`, defaultRequestOptions());
  const receivedData = {
    homePhone: res.data.homePhone,
    businessWebAddress: res.data.businessWebAddress,
  };
  return receivedData;
}

async function getContactAccounts(apiUrl) {
  const res = await axios.get(`${apiUrl}/contacts/accounts`, defaultRequestOptions());
  return res.data;
}

async function changePassword(apiUrl, password, newPassword, confirmNewPassword) {
  const requestData = {
    currentPassword: encodeURIComponent(password),
    newPassword: encodeURIComponent(newPassword),
    confirmNewPassword: encodeURIComponent(confirmNewPassword),
  };
  const res = await axios.post(`${apiUrl}/auth/change-password`, requestData, defaultRequestOptions());
  return res.data;
}

async function resetPassword(apiUrl, resetToken, password, question, answer) {
  const requestData = {
    token: resetToken,
    password: encodeURIComponent(password),
    question,
    answer: encodeURIComponent(answer),
  };
  const res = await axios.post(`${apiUrl}/auth/password`, requestData, defaultRequestOptions());
  return res.data;
}

async function changeEmail(apiUrl, newEmail, password) {
  const passwordVerification = await authService.verifyPassword(apiUrl, password);
  if (passwordVerification.status !== 200) {
    return passwordVerification;
  }
  const res = await axios.post(`${apiUrl}/auth/request-change-email`, { email: newEmail }, defaultRequestOptions());
  return res.data;
}
