export const PPP_LOAN_AMOUNT = 'pppLoanAmount';
export const PPP_LOAN_ISSUE_DATE = 'pppLoanIssueDate';

export const InfoItemCaptions = {
  sbaPppLoanNumber: 'SBA PPP Loan Number',
  lenderPppLoanNumber: 'Lender PPP Loan Number',
  pppLoanAmount: 'PPP Loan Amount',
  pppLoanIssueDate: 'PPP Loan Disbursement Date',
  totalEmployees: 'Total Employees at time of application',
};
