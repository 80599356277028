import businessEntityTypes from '../../constants/businessEntityTypes';

export const entityGroups = {
  groupOne: [
    businessEntityTypes.cCorporation,
    businessEntityTypes.sCorporation,
    businessEntityTypes.housingCooperative,
    businessEntityTypes.other,
  ],
  groupsTwoAndThree: [
    businessEntityTypes.eligibleSelfEmployedIndividual,
    businessEntityTypes.independentContractor,
    businessEntityTypes.soleProprietorship,
  ],
  groupFour: [
    businessEntityTypes.partnership,
  ],
  groupFive: [
    businessEntityTypes.c3nonProfit,
    businessEntityTypes.c6Organization,
    businessEntityTypes.veteransOrganization,
    businessEntityTypes.tribalBusiness,
  ],
  groupSix: [
    businessEntityTypes.limitedLiabilityCompany,
  ],
};

export default entityGroups;
