import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container, Row, Col,
} from '@swift-paypal/pp-react';
import { getAccountsAction } from '../../redux/actions/account/account-actions';
import utils from '../../util';
import './account-info-banner.less';
import { accountInfoBannerSelector, useShallowSelector } from './account-info-banner-selectors';

const AccountsInformationBanner = () => {
  const dispatch = useDispatch();
  const { accountsById, advances, opportunities } = useShallowSelector(accountInfoBannerSelector);

  useEffect(() => {
    if (Object.values(accountsById).length === 0) {
      dispatch(getAccountsAction());
    }
  }, []);

  if (!opportunities || !opportunities.length || !advances || !advances.length) {
    return null;
  }

  const closedOpportunityIds = opportunities
    .filter((opp) => opp.stageName === 'Closed Won')
    .map((opp) => (opp.id));

  const loanSummary = {
    activeLoans: 0,
    totalBorrowed: 0,
  };

  advances.forEach((advance) => {
    if (advance.type === 'OFB' || !closedOpportunityIds.includes(advance.opportunityId)) {
      return;
    }

    if (advance.balance && advance.balance > 0) {
      loanSummary.activeLoans += 1;
    }

    loanSummary.totalBorrowed += advance.amount;
  });

  const displayAccountId = advances[0].accountId;
  const displayAccount = accountsById[displayAccountId];
  const activeLoansText = loanSummary.activeLoans === 1 ? 'Active Loan' : 'Active Loans';

  return (
    <Container fluid className="account-banner">
      <Row className="account-banner__row">
        <Col lg={8} md={12} className="account-name">
          {displayAccount?.name}
        </Col>
        <Col lg={{ span: 2 }} className="account-details">
          <Row>{loanSummary.activeLoans}</Row>
          <Row className="grey-text">{activeLoansText}</Row>
        </Col>
        <Col lg={2} className="account-details">
          <Row>{utils.conditionallyDisplayCents(loanSummary.totalBorrowed)}</Row>
          <Row className="grey-text">Total Borrowed</Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountsInformationBanner;
