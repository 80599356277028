import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { numericality, required } from 'redux-form-validators';
import { createNumberMask } from 'redux-form-input-masks';
import { BodyText, TextInput, Tooltip } from '@swift-paypal/pp-react';
import constants from '../../../../../constants';
import { TOOLTIP_DESCRIPTION, TOOLTIP_NAME } from './number-of-owners-constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const { errors } = constants;

const numberMask = createNumberMask({ allowEmpty: true });

const NumberOfOwners = ({
  error, submitted, touched, value,
}) => (
  <>
    <BodyText as="label" htmlFor="numberOfOwners">
      Total number of owner-employees / self-employed individuals / general partners in your business
    </BodyText>
    <Field
      className="field__mapped-input"
      key="number-of-owners"
      name="numberOfOwners"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      rightIcon={<Tooltip description={TOOLTIP_DESCRIPTION} name={TOOLTIP_NAME} />}
      type="tel"
      value={value}
      validate={[
        required({ msg: errors.requiredField }),
        numericality({ '>': 0, msg: { '>': 'Total number must be at least 1' } }),
      ]}
      {...numberMask}
    />
  </>
);

NumberOfOwners.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
  value: undefined,
};

NumberOfOwners.propTypes = {
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.number,
};

export default memo(NumberOfOwners);
