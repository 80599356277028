import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import withUrlParams from '../../../hocs/with-url-params';
import './action-item.less';

class ActionItem extends React.Component {
  static propTypes = {
    opportunity: PropTypes.shape({
      account: PropTypes.object,
    }),
    renewFunc: PropTypes.func,
    stipulation: PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      groupName: PropTypes.string,
      isRenewal: PropTypes.bool,
    }),
  };

  static defaultProps = {
    opportunity: undefined,
    renewFunc: undefined,
    stipulation: undefined,
  };

  constructor(props) {
    super(props);
    this.renew = this.renew.bind(this);
  }

  renew() {
    const { renewFunc, opportunity } = this.props;
    renewFunc(opportunity.account.id);
  }

  render() {
    const { stipulation } = this.props;

    return (
      <div className="item">
        {stipulation
        && (
          <>
            <Row className="stipulation" noGutters>
              <Col xs={6} sm={8} md={7}>
                <span className="stipulation-name">
                  {stipulation.displayName || stipulation.groupName || stipulation.name}
                </span>
              </Col>
              <Col xs={6} sm={4} md={5}>
                {stipulation.isRenewal
                  ? (
                    <Button className="fixed-btn" onClick={this.renew} data-renewal>
                      Get Started
                    </Button>
                  ) : <></>}
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(ActionItem);
