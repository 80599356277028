import moment from 'moment';
import { isIE, isSafari } from 'react-device-detect';
import {
  excusedBusinessEntities,
  fieldNames,
  LOAN_AMOUNT_THRESHOLD,
  sfFieldNames,
} from './sba3508SFormFields/sba3508SFormFields.constants';
import { scrollTo } from '../../../services/window.service';

export const buildSfPatchData = (formValues, registeredFields, forgivenessId) => {
  if (!forgivenessId) {
    return undefined;
  }

  const sfPatchData = Object.values(registeredFields).reduce(
    (patchData, { name }) => {
      const sfFieldName = formFieldNamesToSfFieldNames[name];
      const rawValue = formValues[name];
      const sfValue = getSfFriendlyValue(sfFieldName, rawValue);
      return ({
        ...patchData,
        [sfFieldName]: sfValue,
      });
    },
    {},
  );

  sfPatchData.id = forgivenessId;
  sfPatchData.setForgivenessCalculation = true;

  return sfPatchData;
};

export const buildPrefillData = (forgivenessObject) => {
  if (!forgivenessObject) {
    return undefined;
  }

  const prefillData = Object.values(fieldNames).reduce(
    (data, name) => {
      const sfFieldName = formFieldNamesToSfFieldNames[name];
      const sfValue = forgivenessObject[sfFieldName];

      const isStandardCoveredPeriodEndDate = sfFieldName === sfFieldNames.standardCoveredPeriodEndDate;
      if (sfValue === null || sfValue === 'N/A' || isStandardCoveredPeriodEndDate) {
        return data;
      }

      const formValue = getFormValue(sfFieldName, sfValue);

      return ({
        ...data,
        [name]: formValue,
      });
    },
    {},
  );

  if (fieldNames.reducedSalariesWagesEmployees in prefillData) {
    prefillData[fieldNames.receivedLargeFunding] = forgivenessObject.ezAppQ6 ? 'true' : 'false';
  }

  return prefillData;
};

const picklistFields = [
  sfFieldNames.forgivenessAmountAdjusted3508s,
  sfFieldNames.reductionAttestation3508s,
  sfFieldNames.wageReduction3508s,
];

export const getSfFriendlyValue = (sfFieldName, value) => {
  if (sfFieldName === sfFieldNames.ezAppQ6) {
    return value === 'true';
  }

  if (picklistFields.includes(sfFieldName)) {
    return value === 'true' ? 'Yes' : 'No';
  }

  if (sfFieldName === sfFieldNames.standardCoveredPeriodEndDate) {
    return moment(value);
  }

  return value;
};

export const getFormValue = (sfFieldName, sfValue) => {
  if (sfFieldName === sfFieldNames.ezAppQ6) {
    // This field is false by default in SF so it should not pre-fill unless wageReduction3508s is not N/A
    return undefined;
  }

  if (sfFieldName === sfFieldNames.standardCoveredPeriodEndDate && (isIE || isSafari)) {
    return moment(sfValue).format('MM/DD/YYYY');
  }

  if (picklistFields.includes(sfFieldName)) {
    if (sfValue === 'Yes') {
      return 'true';
    }
    if (sfValue === 'No') {
      return 'false';
    }
  }

  return sfValue;
};

export const sfFieldNamesToFormFieldNames = {
  [sfFieldNames.employeesAtApplicationTime]: fieldNames.currentEmployees,
  [sfFieldNames.forgivenessAmountAdjusted3508s]: fieldNames.reviewedAdjustedForgivenessAmount,
  [sfFieldNames.forgivenessAmount3508s]: fieldNames.requestedForgivenessAmount,
  [sfFieldNames.ezAppQ6]: fieldNames.receivedLargeFunding,
  [sfFieldNames.payrollAmount3508s]: fieldNames.payrollCosts,
  [sfFieldNames.reductionAttestation3508s]: fieldNames.attestToReductions,
  [sfFieldNames.standardCoveredPeriodEndDate]: fieldNames.coveredPeriodEndDate,
  [sfFieldNames.wageReduction3508s]: fieldNames.reducedSalariesWagesEmployees,
};

export const formFieldNamesToSfFieldNames = {
  [fieldNames.currentEmployees]: sfFieldNames.employeesAtApplicationTime,
  [fieldNames.reviewedAdjustedForgivenessAmount]: sfFieldNames.forgivenessAmountAdjusted3508s,
  [fieldNames.requestedForgivenessAmount]: sfFieldNames.forgivenessAmount3508s,
  [fieldNames.receivedLargeFunding]: sfFieldNames.ezAppQ6,
  [fieldNames.payrollCosts]: sfFieldNames.payrollAmount3508s,
  [fieldNames.attestToReductions]: sfFieldNames.reductionAttestation3508s,
  [fieldNames.coveredPeriodEndDate]: sfFieldNames.standardCoveredPeriodEndDate,
  [fieldNames.reducedSalariesWagesEmployees]: sfFieldNames.wageReduction3508s,
};

export const getReducedSalariesFieldVisibility = (pppLoanAmount, businessEntity, employeeCount, contingency) => {
  if (excusedBusinessEntities.includes(businessEntity) && employeeCount === 1) {
    return false;
  }

  if (pppLoanAmount > LOAN_AMOUNT_THRESHOLD) {
    return true;
  }

  return contingency;
};

export const onSubmitFailHandler = (errors) => {
  const errorsArray = Object.keys(errors);
  const result = { x: 0, y: window.innerHeight + window.scrollY };
  const SCROLL_Y_OFFSET = 120;
  errorsArray.forEach((error) => {
    const elements = document.getElementsByName(error);
    const coordinates = elements[0].getBoundingClientRect();
    coordinates.y += window.scrollY;
    if (result.y > coordinates.y) {
      result.y = coordinates.y;
    }
    result.y -= SCROLL_Y_OFFSET;
    scrollTo(0, result.y);
  });
};
