/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from '@swift-paypal/pp-react';
import resolve from '../../../services/route.service';
import routes from '../../../routes';

const renderTermsCheckField = ({
  input,
  id,
  className,
  value,
  meta: { touched, error, warning },
  country,
}) => (
  <Row>
    <Col xs={1} className="ownership-terms">
      <div className={`vx_checkbox ${(touched && error) ? 'vx_has-error' : ''}`}>
        <input
          {...input}
          id={id}
          type="checkbox"
          value={value}
          className={className}
        />
        <label htmlFor={id}> </label>
      </div>
    </Col>
    <Col xs={11}>
      <label htmlFor={id} className={`clickable ${(touched && error) ? 'vx_has-error' : ''}`}>
        <em>
          I agree to the
          {' '}
          <Link to={resolve(routes['terms-and-conditions'].path, { country })} target="_blank">
            Terms and Conditions
          </Link>
          {' '}
          and the
          {' '}
          <Link to={resolve(routes['privacy-policy'].path, { country })} target="_blank">
            Privacy Policy
          </Link>
          .
        </em>
      </label>
    </Col>
    <Col xs={12} className={(touched && error) ? 'vx_has-error-with-message' : ''}>
      {touched
      && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
        || (warning && <span className="vx_form-control-message margin-bottom-sm">{warning}</span>))}
    </Col>
  </Row>

);

renderTermsCheckField.propTypes = {
  className: PropTypes.string,
  country: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  noErrorClass: PropTypes.string,
  value: PropTypes.shape({}),
};

renderTermsCheckField.defaultProps = {
  className: undefined,
  country: undefined,
  input: undefined,
  meta: undefined,
  noErrorClass: undefined,
  value: undefined,
};

export default renderTermsCheckField;
