import React from 'react';
import {
  BodyText, Col, Container, Row,
} from '@swift-paypal/pp-react';
import step1Icon from '../../static/images/icons/app_overview_1.svg';
import step2Icon from '../../static/images/icons/app_overview_2.svg';
import step3Icon from '../../static/images/icons/app_overview_3.svg';

const QuestionnaireDetails = () => (
  <>
    <Container fluid className="application-steps">
      <Row className="steps-img">
        <Col>
          <img alt="" src={step1Icon} />
          Provide information about your payroll and eligible expenses
        </Col>
      </Row>
      <Row className="steps-img">
        <Col>
          <img alt="" src={step2Icon} />
          See your requested loan forgiveness amount
        </Col>
      </Row>
      <Row className="steps-img">
        <Col>
          <img alt="" src={step3Icon} />
          Upload supporting documents and submit for review
        </Col>
      </Row>
    </Container>
    <BodyText as="p">
      Before you start, help us understand how your business operated over
      the past few months so we can determine which SBA Forgiveness
      Application Form is applicable to your business.
    </BodyText>
  </>
);

export default QuestionnaireDetails;
