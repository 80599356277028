import { allowedFileTypes, allowedSingleFileSize } from '../../../constants/upload-document';

export const OPT_OUT_TEXT = 'Or click here if you aren\'t uploading documents because you didn’t include covered costs / expenditures in your forgiveness application.';

export const UPLOAD_HELPER_TEXT = 'Upload your covered costs / expenditures documents';

export const AllowedFileExtensions = [
  ...allowedFileTypes.csv,
  ...allowedFileTypes.images,
  ...allowedFileTypes.pdf,
  ...allowedFileTypes.xlsx,
  ...allowedFileTypes.docx,
];

export const AllowedSingleFileSize = allowedSingleFileSize;

export const CoveredCostsDetailsContent = [
  {
    key: 'section1',
    body: 'Upload supporting documents to verify the amounts noted on your application for the following categories or, if you do not have any amounts to claim for these areas, select the option provided at the bottom of this page.',
  },
  {
    key: 'section2',
    header: 'Covered operations expenditures',
    body: 'Upload documentation that supports any payments made for business software or cloud computing services that facilitated your business operations, products or service delivery, the processing, payment, or tracking of payroll expenses, human resources, sales and billing functions, or the accounting of supplies, inventory, records and expenses.',
  },
  {
    key: 'section3',
    header: 'Covered property damage costs',
    body: 'Upload documentation that demonstrates costs related to property damage and vandalism or looting due to public disturbances that occurred during 2020 that were not covered by insurance or other compensation.',
  },
  {
    key: 'section4',
    header: 'Covered supplier costs',
    body: 'Upload documentation that shows expenditures made to a supplier of goods for the supply of goods that are essential to your operations at the time at which the expenditure was made, and pursuant to a contract, order, or purchase order in effect prior to the beginning of the Covered Period. For perishable goods, the contract, order or purchase may have been in effect before or at any time during the Covered Period.',
  },
  {
    key: 'section5',
    header: 'Covered worker protection expenditures',
    body: 'Upload documentation that demonstrates the operating or capital expenditures that facilitated the adaptation of your business to comply with the requirements established or guidance issued by the Department of Health and Human Services, the Centers for Disease Control, or the Occupational Safety and Health Administration, or any equivalent requirements or guidance issued by a State or local government, during the period starting on Mar 1, 2020 and ending on the date on which the national emergency declared by the President with respect to COVID-19 expires.',
  },
];
