import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import injectEnvironmentVar from './inject-environment-var';
import injectToken from './inject-token';
import wrapWithLayout from './wrap-with-layout';
import ensureSafeRouting from './ensure-safe-routing';
import storeUtmFromUrl from './store-utm-from-url';
import Country from './country';
import googleAnalytics from './google-analytics';
import withUrlParams from './with-url-params';

const injectAuthApiUrl = injectEnvironmentVar('API_URL');

// A pile of middleware that we use on every page
export default compose(
  wrapWithLayout,
  ensureSafeRouting,
  injectToken,
  Country,
  injectAuthApiUrl,
  storeUtmFromUrl,
  withRouter,
  withUrlParams,
  googleAnalytics,
);
