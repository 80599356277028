const buildSfPatchData = (formValues, registeredFields, forgivenessId, sbaOppId, pppId) => {
  if (!forgivenessId || !sbaOppId || !pppId) {
    return undefined;
  }

  const sfPatchData = Object.values(registeredFields).reduce(
    (patchData, { name }) => {
      const sfValue = formValues[name];
      return ({
        ...patchData,
        [name]: sfValue,
      });
    },
    {},
  );

  sfPatchData.id = forgivenessId;
  sfPatchData.oppId = sbaOppId;
  sfPatchData.paycheckProtectionPlanId = pppId;

  return sfPatchData;
};

export default buildSfPatchData;
