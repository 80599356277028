import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import CaseWorkerTile from '../components/common/case-worker-tile/case-worker-tile';
import { ForgivenessApplicationSidebar } from '../components/forgiveness/forgiveness-application-sidebar';
import ForgivenessDetail from '../components/forgiveness/forgiveness-detail/forgiveness-detail';
import displayNames from '../constants/displayNames';
import SecurePage from '../hocs/secure-page';
import { useActiveForgivenessSelected } from '../hooks';
import BetterStandardLayout from '../layouts/better-standard-layout/better-standard-layout';
import redirect from '../lib/redirect';

const ForgivenessDetailPage = ({
  activeForgivenessId,
  history,
  loanForgiveness,
  match,
}) => {
  useActiveForgivenessSelected();
  useEffect(() => {
    if (!activeForgivenessId) {
      redirect(history, match);
    }
  }, []);

  if (!activeForgivenessId) {
    return null;
  }

  const caseWorker = loanForgiveness.byId[activeForgivenessId]?.caseWorker;

  return (
    <div className="summary-container">
      <Helmet>
        <title>Summary</title>
      </Helmet>
      <BetterStandardLayout
        bodyElement={<ForgivenessDetail />}
        sidebarElement={<ForgivenessApplicationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

ForgivenessDetailPage.displayName = displayNames.ForgivenessDetailPage;

ForgivenessDetailPage.defaultProps = {
  history: undefined,
  match: undefined,
};

ForgivenessDetailPage.propTypes = {
  activeForgivenessId: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history,
  loanForgiveness: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  match: ReactRouterPropTypes.match,
};

const mapStateToProps = (state) => ({
  activeForgivenessId: state.activeIds.forgivenessId,
  bfes: state.accountItems.bfes,
  loanForgiveness: state.entities.loanForgiveness,
});

export default connect(mapStateToProps)(SecurePage(ForgivenessDetailPage));
