export const expiredStageContent2021 = {
  actionRoute: 'summary',
  actionText: 'Resume',
  message: 'Click Resume to re-open and continue your 2021 PPP loan forgiveness application where you left off. Please be sure to promptly provide all requested information.',
  title: 'Your 2021 PPP loan forgiveness application has been closed as incomplete since we have not received sufficient information from you to make a decision on your loan forgiveness request.',
};
export const reviewStageContent = {
  actionRoute: 'forgiveness-summary',
  actionText: 'See Your Application',
  message: 'We\'re reviewing your application. Your Loan Forgiveness Specialist will contact you'
  + ' once we\'ve reviewed your application. Our review may take up to 60 days from when you'
  + ' submitted your complete application.',
  title: 'PPP loan forgiveness application',
};

export const reviewStageContent2021 = {
  actionRoute: 'forgiveness-summary',
  actionText: 'View',
  message: 'We\'re reviewing your application and will contact you once the review is complete.'
  + ' Our review may take up to 60 days from when you submitted your complete application.',
  title: '2021 First Draw PPP loan forgiveness application',
};

export const sbaResponseAwaitingContent = {
  actionRoute: 'forgiveness-detail',
  actionText: 'See Details',
  message: 'We\'ve submitted your loan forgiveness application to the SBA. The SBA has up to 90 days to complete its review.',
  title: '2021 First Draw PPP loan forgiveness application',
};

export const reviewStageContent2021SD = {
  actionRoute: 'forgiveness-summary',
  actionText: 'View',
  message: 'We\'re reviewing your application and will contact you once the review is complete.'
  + ' Our review may take up to 60 days from when you submitted your complete application.',
  title: '2021 Second Draw PPP loan forgiveness application',
};

export const stageContentOther = {
  ppp213508SDocumentUpload: {
    actionRoute: 'forgiveness-summary',
    actionText: 'Continue',
    message: 'To apply for loan forgiveness or complete an existing loan forgiveness application for your 2020 PPP loan.',
    title: 'New 3508S PPP loan forgiveness application',
  },
};

export const stageContent2020 = {
  'Document Review': reviewStageContent,
  'Quality Check Sendback': reviewStageContent,
  'Internal Quality Check': reviewStageContent,
  'Document Upload': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Continue',
    message: 'Complete your existing loan forgiveness application for your 2020 PPP loan.',
    title: '2020 PPP loan forgiveness application',
  },
  'Additional Documents Requested': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'Additional documents are required for us to review your loan forgiveness application.',
    title: 'PPP loan forgiveness application',
  },
  'Pending SBA Submission': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We are preparing your application for submission to the SBA for review.',
    title: 'PPP loan forgiveness application',
  },
  'Awaiting SBA Response': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We\'ve submitted your loan forgiveness application to the SBA. The SBA has up to 90 days to complete its review.',
    title: 'PPP loan forgiveness application',
  },
  'SBA Response Received': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We\'ve submitted your loan forgiveness application to the SBA. The SBA has up to 90 days to complete its review.',
    title: 'PPP loan forgiveness application',
  },
  'SBA Additional Info Required': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'The SBA has requested additional information regarding your forgiveness application. We will send you an email with more details.',
    title: 'PPP loan forgiveness application',
  },
  Declined: {
    actionRoute: 'forgiveness-detail',
    actionText: 'See SBA Approved Amount',
    message: 'We received your loan forgiveness decision from the SBA.',
    title: 'PPP loan forgiveness application',
  },
  Forgiven: {
    actionRoute: 'forgiveness-detail',
    actionText: 'See SBA Approved Amount',
    message: 'We received your loan forgiveness decision from the SBA.',
    title: 'PPP loan forgiveness application',
  },
  Expired: {
    actionRoute: 'summary',
    actionText: 'Resume',
    message: 'Click Resume to re-open and continue your 2020 PPP loan forgiveness application where you left off. Please be sure to promptly provide all requested information.',
    title: 'Your 2020 PPP loan forgiveness application has been closed as incomplete since we have not received sufficient information from you to make a decision on your loan forgiveness request.',
  },
};

export const stageContent2021FirstDraw = {
  'Document Review': reviewStageContent2021,
  Expired: expiredStageContent2021,
  'Quality Check Sendback': reviewStageContent2021,
  'Internal Quality Check': reviewStageContent2021,
  'Document Upload': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'Finish uploading your required documents before submitting your application for review.',
    title: '2021 First Draw PPP loan forgiveness application',
  },
  'Additional Documents Requested': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'Additional documents are required for us to review your loan forgiveness application.',
    title: '2021 First Draw PPP loan forgiveness application',
  },
  'Pending SBA Submission': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We are preparing your application for submission to the SBA for review.',
    title: '2021 First Draw PPP loan forgiveness application',
  },
  'Awaiting SBA Response': sbaResponseAwaitingContent,
  'SBA Response Received': sbaResponseAwaitingContent,
  'SBA Additional Info Required': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'The SBA has requested additional information regarding your forgiveness application. We will send you an email with more details.',
    title: '2021 First Draw PPP loan forgiveness application',
  },
  Declined: {
    actionRoute: 'forgiveness-detail',
    actionText: 'See SBA Approved Amount',
    message: 'We received your loan forgiveness decision from the SBA.',
    title: '2021 First Draw PPP loan forgiveness application',
  },
  Forgiven: {
    actionRoute: 'forgiveness-detail',
    actionText: 'See SBA Approved Amount',
    message: 'We received your loan forgiveness decision from the SBA.',
    title: '2021 First Draw PPP loan forgiveness application',
  },
};

export const stageContent2021SecondDraw = {
  'Document Review': reviewStageContent2021SD,
  Expired: expiredStageContent2021,
  'Quality Check Sendback': reviewStageContent2021SD,
  'Internal Quality Check': reviewStageContent2021SD,
  'Document Upload': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'Finish uploading your required documents before submitting your application for review.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  'Additional Documents Requested': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'Additional documents are required for us to review your loan forgiveness application.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  'Pending SBA Submission': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We are preparing your application for submission to the SBA for review.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  'Awaiting SBA Response': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We\'ve submitted your loan forgiveness application to the SBA. The SBA has up to 90 days to complete its review.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  'SBA Response Received': {
    actionRoute: 'forgiveness-detail',
    actionText: 'See Details',
    message: 'We\'ve submitted your loan forgiveness application to the SBA. The SBA has up to 90 days to complete its review.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  'SBA Additional Info Required': {
    actionRoute: 'forgiveness-summary',
    actionText: 'Upload Documents',
    message: 'The SBA has requested additional information regarding your forgiveness application. We will send you an email with more details.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  Declined: {
    actionRoute: 'forgiveness-detail',
    actionText: 'See SBA Approved Amount',
    message: 'We received your loan forgiveness decision from the SBA.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
  Forgiven: {
    actionRoute: 'forgiveness-detail',
    actionText: 'See SBA Approved Amount',
    message: 'We received your loan forgiveness decision from the SBA.',
    title: '2021 Second Draw PPP loan forgiveness application',
  },
};
