import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import resolve from '../../services/route.service';
import util, { closeNavMenu } from '../../util';
import Logo from '../logo/logo';
import Navigation from '../navigation/navigation';
import routes from '../../routes';
import withUrlParams from '../../hocs/with-url-params';

const LoggedInHeader = (props) => {
  const { country, user } = props;

  return (
    <div className="portal_header">
      <div className="vx_globalNav-main" role="banner">
        <div className="vx_globalNav-container">
          <Logo authorized country={country} />
          <div className="vx_globalNav-secondaryNav_mobile">
            <div className="vx_globalNav-listItem_mobileLogout">
              <Link to={resolve(routes.login.path, { country })} onClick={util.closeNav} className="vx_globalNav-link_mobileLogout">
                Log out
              </Link>
            </div>
            <div className="vx_globalNav-listItem_settings">
              <Link onClick={closeNavMenu} to={resolve(routes.settings.path, { country })} className="vx_globalNav-link_settings">
                <>
                  <span className="vx_globalNav-iconWrapper_secondary">
                    <span className="vx_globalNav-navIcon vx_globalNav-navIcon_linkSettings" />
                  </span>
                  <span className="vx_a11yText">Profile</span>
                </>
              </Link>
            </div>
            <div>
              <p className="vx_h5 vx_globalNav-displayName">
                {`${user.firstName} ${user.lastName}`}
              </p>
            </div>
          </div>
          <Navigation />
        </div>
      </div>
    </div>
  );
};

LoggedInHeader.propTypes = {
  country: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

LoggedInHeader.defaultProps = {
  country: undefined,
};

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(LoggedInHeader);

export const LoggedInHeaderComponent = LoggedInHeader;
