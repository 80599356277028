import * as actions from '../actions/country/country-action-types';

const country = (state = {}, action) => {
  if (action.type === actions.COUNTRY_OK) {
    return { ...state, country: action.country };
  }
  return state;
};

export default country;
