import React, { memo } from 'react';
import { Field } from 'redux-form';
import {
  BodyText, Col, Row, TextInput,
} from '@swift-paypal/pp-react';
import { numericality, required } from 'redux-form-validators';
import errors from '../../../../constants/errors';
import { useShallowSelector } from '../../../../hooks';
import { Sba3508SFormInstructionsLink } from '../sba3508SFormInstructionsLink';
import { currencyMask, fieldNames } from './sba3508SFormFields.constants';
import { fieldSelector, forgivenessAmountSelector } from '../sba3508SForm.selectors';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const RequestedForgivenessAmountField = () => {
  const fieldName = fieldNames.requestedForgivenessAmount;
  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);
  const pppLoanAmount = useShallowSelector(forgivenessAmountSelector);

  if (!pppLoanAmount) {
    return null;
  }

  return (
    <Row>
      <Col form="full">
        <BodyText as="label" htmlFor={fieldName}>
          Requested Loan Forgiveness Amount
        </BodyText>
        <Field
          component={MappedInput}
          errorText={touched ? error : undefined}
          helperText={helperText}
          key="requested-forgiveness-amount-field"
          name={fieldName}
          placeholder="$"
          validate={[
            required({ msg: errors.enterAnAmount }),
            numericality({ '>': 0, msg: errors.enterAnAmount }),
            numericality({ '<=': pppLoanAmount, msg: 'Entered amount cannot be greater than your PPP loan amount.' }),
          ]}
          value={value}
          {...currencyMask}
        />
      </Col>
    </Row>
  );
};

const helperText = (
  <>
    See
    {' '}
    <Sba3508SFormInstructionsLink
      anchor="#page=2"
      text="Page 2 of SBA Form 3508S Instructions"
    />
    {' '}
    for details on the types of costs that can be included in determining your Requested Loan Forgiveness Amount.
    Eligible nonpayroll costs cannot exceed 40% of the requested forgiveness amount.
  </>
);

export default memo(RequestedForgivenessAmountField);
