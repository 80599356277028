import Cookie from 'js-cookie';
import decode from 'jwt-decode';
import { push } from 'connected-react-router';
import resolve from '../../../services/route.service';
import { AlertTypes } from '../../../components/common/alert';
import { extractUserFromDecodedToken, isUserAuthenticated } from '../../../lib/token';
import fullStoryService from '../../../lib/full-story';
import heapService from '../../../services/heap.service';
import { addLoginPageMessageCreated } from '../login/login-page-action-types';
import { createLoginPageMessageAction } from '../login/login-page-actions';
import { createUrlBeforeLoggedOut, deleteUrlBeforeLoggedOut } from '../redirect-urls/redirect-urls-actions';
import constants from '../../../constants';
import { asyncNoAuthActionCreator } from '../util/async-action-creator';
import countryCodes from '../../../constants/countryCodes';
import routes from '../../../routes';
import authService from '../../../services/auth.service';
import { setLocalStorage } from '../../../services/window.service';
import * as actions from './auth-action-types';
import paypalAuthErrors from '../../../constants/paypalAuthErrors';
import migratedFlowConstant from '../../../constants/migratedFlowConstant';

const fullStory = fullStoryService();
const us = countryCodes.unitedStates.toLowerCase();

const setCookie = (environment, token) => {
  // Store the token for the benefit of client and server
  setLocalStorage('token', token);
  Cookie.set('token', token, { secure: environment === 'production' });
};

/* authentication methods */

export function authenticateAction({
  email, password, country, history, partner = 'sf',
}) {
  return async (dispatch, getState) => {
    try {
      const { environment } = getState().config;
      const res = await authService.login(getState().config.apiUrl, email, password, partner);

      dispatch(actions.addAuthenticated());
      setCookie(environment, res.token);

      fullStory.setUserInformation(res.token);
      heapService.setUserInformation(res.token);

      if (res.countryCode) {
        history.push(resolve(routes['security-question'].path, { country: res.countryCode }));
      }
      else {
        history.push(resolve(routes['security-question'].path, { country: country || us }));
      }
    }
    catch (error) {
      const message = resolveAuthErrorMessage(error.response);
      dispatch(createLoginPageMessageAction(message, AlertTypes.critical));
    }
  };
}

export const resolveAuthErrorMessage = (response) => {
  let message;
  if (response && response.status) {
    switch (response.status) {
      case (401):
        message = resolve401ErrorMessage(response);
        break;
      case (403):
        message = constants.errors.authentication;
        break;
      case (404):
        message = constants.errors.authentication;
        break;
      case (422):
        message = constants.errors.authentication;
        break;
      default:
        message = constants.errors.technicalIssue;
    }
  }
  return message;
};

function resolve401ErrorMessage(response) {
  switch (response.data) {
    case 'Password expired':
      return constants.errors.passwordExpired;
    default:
      return constants.errors.authentication;
  }
}

function handleMissingCode(code, error, history, dispatch) {
  if (!code) {
    const RoutePayload = {
      country: us,
      paypalAuthError: error,
    };
    history.push(resolve(routes.login.path, RoutePayload));
    dispatch(createLoginPageMessageAction(constants.errors.paypalAuthentication, AlertTypes.critical));
  }
}

function handleExceptionGetSSOTokenAction(e, history, dispatch, country, error, version) {
  if (e.response.status === 404) {
    let RoutePayload = null;
    if (version === migratedFlowConstant.URL_APPEND) {
      RoutePayload = {
        country: us,
        paypalAuthError: paypalAuthErrors.notFound,
        version,
      };
    }
    else {
      RoutePayload = {
        country: country || us,
        paypalAuthError: paypalAuthErrors.notFound,
      };
    }
    history.push(resolve(routes.login.path, RoutePayload));
    dispatch(createLoginPageMessageAction(constants.errors.paypalAuthentication, AlertTypes.warning));
    return;
  }
  let RoutePayload = null;
  if (version === migratedFlowConstant.URL_APPEND) {
    RoutePayload = {
      country: country || us,
      paypalAuthError: error,
      version,
    };
  }
  else {
    RoutePayload = {
      country: country || us,
      paypalAuthError: error,
    };
  }
  history.push(resolve(routes.login.path, RoutePayload));

  dispatch(createLoginPageMessageAction(constants.errors.paypalAuthentication, AlertTypes.critical));
}

export const getSsoTokenAction = (location, history, code = '') => async (dispatch, getState) => {
  const { environment } = getState().config;
  const params = new URLSearchParams(location.search);
  const error = params.get('error');
  handleMissingCode(code, error, history, dispatch);

  let country = us;
  try {
    // dispatch(actions.addSsoTokenRequested());
    const res = await authService.getOAuthToken(getState().config.apiUrl, code);

    country = (res.countryCode || us).toLowerCase();
    setCookie(environment, res.token);
    const user = extractUserFromDecodedToken();
    dispatch(actions.addAuthorized(user));

    dispatch(createUrlBeforeLoggedOut());
    const redirectUrl = getState().redirectUrls.urlBeforeLoggedOut;
    history.push(redirectUrl || resolve(routes.summary.path, { country: country || us }));
    dispatch(deleteUrlBeforeLoggedOut());
  }
  catch (e) {
    handleExceptionGetSSOTokenAction(e, history, dispatch, country, error);
  }
};

export const getSsoTokenActionMigratedFlow = (location, history, code = '') => async (dispatch, getState) => {
  const { environment } = getState().config;
  const params = new URLSearchParams(location.search);
  const error = params.get('error');
  handleMissingCode(code, error, history, dispatch);

  let country = us;
  try {
    // dispatch(actions.addSsoTokenRequested());
    const res = await authService.getOAuthTokenMigratedFlow(getState().config.apiUrl, code);

    country = (res.countryCode || us).toLowerCase();
    setCookie(environment, res.token);
    const user = extractUserFromDecodedToken();
    dispatch(actions.addAuthorized(user));

    dispatch(createUrlBeforeLoggedOut());
    const redirectUrl = getState().redirectUrls.urlBeforeLoggedOut;
    history.push(redirectUrl || resolve(routes.summary.path, { country: country || us }));
    dispatch(deleteUrlBeforeLoggedOut());
  }
  catch (e) {
    handleExceptionGetSSOTokenAction(e, history, dispatch, country, error, migratedFlowConstant.URL_APPEND);
  }
};

/* authorization methods */

export const authorizeAction = ({
  answer, country, history, questionId,
}) => async (dispatch, getState) => {
  try {
    const { environment } = getState().config;

    if (!isUserAuthenticated()) {
      dispatch(createLoginPageMessageAction(
        'Your session has expired. Please log back in to continue.',
        AlertTypes.critical,
      ));
      history.replace(resolve(routes.login.path, { country }));
    }

    const res = await authService.answerSecurityQuestions(getState().config.apiUrl, answer, questionId);
    const decodedToken = decode(res.token);

    const user = extractUserFromDecodedToken(decodedToken);
    dispatch(actions.addAuthorized(user));
    setCookie(environment, res.token);

    const redirectUrl = getState().redirectUrls.urlBeforeLoggedOut;
    history.replace(redirectUrl || resolve(routes.summary.path, { country }));
    dispatch(deleteUrlBeforeLoggedOut());
  }
  catch (error) {
    dispatch(actions.addAuthorizationError(constants.errors.authorization));
  }
};

export const getSecurityQuestionAction = () => async (dispatch, getState) => {
  if (!isUserAuthenticated()) {
    return dispatch(actions.addDeauthorized());
  }

  dispatch(actions.addSecurityQuestionRequested());

  try {
    const res = await authService.getSecurityQuestion(getState().config.apiUrl);

    return dispatch(actions.addSecurityQuestionReceived(res.question));
  }
  catch (e) {
    return dispatch(actions.addSecurityQuestionRequestError());
  }
};

export const getSecurityQuestionsAction = () => async (dispatch, getState) => {
  if (!isUserAuthenticated()) {
    return dispatch(actions.addDeauthorized());
  }

  dispatch(actions.addSecurityQuestionsRequested());

  try {
    const res = await authService.listSecurityQuestions(getState().config.apiUrl);
    return dispatch(actions.addSecurityQuestionsReceived(res.userQuestions, res.userQuestionIds));
  }
  catch (e) {
    return dispatch(actions.addSecurityQuestionsRequestError());
  }
};

export const createAccountSuccessAction = (country, token) => async (dispatch, getState) => {
  const { environment } = getState().config;
  const decodedToken = decode(token);

  const user = extractUserFromDecodedToken(decodedToken);
  dispatch(actions.addAuthorized(user));

  setCookie(environment, token);
  dispatch(push(resolve(routes.summary.path, { country })));
};

/* password methods */

export const forgotPasswordAction = ({ email, match }) => async (dispatch, getState) => {
  try {
    const { country } = match.params;
    dispatch(actions.addForgotPasswordEmailRequested());
    await authService.forgotPassword(getState().config.apiUrl, email);

    dispatch(actions.addForgotPasswordEmailSentReceived());
    dispatch(addLoginPageMessageCreated(constants.messages.forgotPasswordSuccess, AlertTypes.success));
    dispatch(push(resolve(routes.login.path, { country })));
  }
  catch (error) {
    const errorMessage = error.response.status === 403
      ? constants.errors.forgotPasswordForbidden(getState().config.customerSupportPhone)
      : constants.errors.forgotPasswordGeneric(getState().config.customerSupportPhone);
    dispatch(actions.addForgotPasswordEmailError(errorMessage));
  }
};

export const updatePasswordAction = asyncNoAuthActionCreator({
  pending: actions.UPDATE_PASSWORD_REQUEST_PENDING,
  complete: actions.UPDATE_PASSWORD_RECEIVED,
  error: actions.UPDATE_PASSWORD_REQUEST_ERROR,
},
(token, password) => (dispatch, getState) => {
  const { environment } = getState().config;
  return authService.updatePassword(getState().config.apiUrl, token, password)
    .then((data) => {
      setCookie(environment, data.token);
      return data;
    });
});

export const clearUpdatePasswordMessageAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_UPDATE_PASSWORD_MESSAGE });
};

export const setUpdatePasswordMessageAction = (messageBody, messageType) => async (dispatch) => {
  dispatch({ type: actions.SET_UPDATE_PASSWORD_MESSAGE, messageBody, messageType });
};

/* security questions methods */

export const listSecurityQuestionsAction = asyncNoAuthActionCreator({
  pending: actions.LIST_CREATE_SECURITY_QUESTIONS_REQUEST_PENDING,
  complete: actions.LIST_CREATE_SECURITY_QUESTIONS_RECEIVED,
  error: actions.LIST_CREATE_SECURITY_QUESTIONS_ERROR,
},
() => (dispatch, getState) => authService.listSecurityQuestions(getState().config.apiUrl));

export function createSecurityQuestionAction(country, question1, answer1, question2, answer2) {
  return async (dispatch, getState) => {
    await dispatch(saveSecurityQuestionAction(question1, answer1, question2, answer2));
    if (getState().createSecurityQuestions && getState().createSecurityQuestions.success) {
      return dispatch(createAccountSuccessAction(country, getState().createSecurityQuestions.token));
    }

    return undefined;
  };
}

export const saveSecurityQuestionAction = asyncNoAuthActionCreator({
  pending: actions.CREATE_SECURITY_QUESTIONS_REQUEST_PENDING,
  complete: actions.CREATE_SECURITY_QUESTIONS_RECEIVED,
  error: actions.CREATE_SECURITY_QUESTIONS_REQUEST_ERROR,
},
(question1, answer1, question2, answer2) => (dispatch, getState) => {
  const { config } = getState();
  return authService.createSecurityQuestions(config.apiUrl, question1, answer1, question2, answer2);
});

export const clearCreateSecurityQuestionsMessageAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_CREATE_SECURITY_QUESTIONS_MESSAGE });
};

export const setCreateSecurityQuestionsMessageAction = (messageBody, messageType) => async (dispatch) => {
  dispatch({ type: actions.SET_CREATE_SECURITY_QUESTIONS_MESSAGE, messageBody, messageType });
};

/* reset token methods */
export const verifyResetTokenAction = asyncNoAuthActionCreator(
  {
    pending: actions.VERIFY_RESET_TOKEN_REQUEST_PENDING,
    complete: actions.VERIFY_RESET_TOKEN_RECEIVED,
    error: actions.VERIFY_RESET_TOKEN_REQUEST_ERROR,
  },
  (token) => async (dispatch, getState) => {
    const res = await authService.verifyResetToken(getState().config.apiUrl, token);
    return res.data.email;
  },
);

export function clearResetTokenStatusAction() {
  return async (dispatch) => {
    dispatch({ type: actions.CLEAR_RESET_TOKEN_STATUS });
  };
}

/* misc auth methods */

export const confirmPaypalAccountAction = (token, countryCode = us) => async (dispatch, getState) => {
  try {
    await authService.confirmPaypalAccount(getState().config.apiUrl, token, countryCode);
    dispatch(push(routes['oauth-landing'].path));
  }
  catch (e) {
    const message = constants.errors.confirmPaypalAccount(getState().config.customerSupportPhone);
    dispatch(createLoginPageMessageAction(message, AlertTypes.critical));
    dispatch(push(resolve(routes.login.path, { country: countryCode })));
  }
};

export const confirmPaypalAccountActionMigratedFlow = (token, countryCode = us) => async (dispatch, getState) => {
  try {
    await authService.confirmPaypalAccountMigratedFlow(getState().config.apiUrl, token, countryCode);
    dispatch(push(routes['oauth-landing-migrated-flow'].path));
  }
  catch (e) {
    const message = constants.errors.confirmPaypalAccount(getState().config.customerSupportPhone);
    dispatch(createLoginPageMessageAction(message, AlertTypes.critical));
    dispatch(push(resolve(routes.login.path, { country: countryCode, version: migratedFlowConstant.URL_APPEND })));
  }
};

/* extend user session */

export const keepAliveAction = asyncNoAuthActionCreator(
  {
    pending: actions.KEEP_ALIVE_PENDING,
    complete: actions.KEEP_ALIVE_RECEIVED,
    error: actions.KEEP_ALIVE_ERROR,
  },
  () => async (dispatch, getState) => {
    const res = await authService.keepAlive(getState().config.apiUrl);
    return res.data;
  },
);

/* end the current user session */

export const expireAction = asyncNoAuthActionCreator(
  {
    pending: actions.EXPIRE_PENDING,
    complete: actions.EXPIRE_RECEIVED,
    error: actions.EXPIRE_ERROR,
  },
  () => async (dispatch, getState) => {
    const res = await authService.expire(getState().config.apiUrl);
    return res.data;
  },
);
