import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Button, Col, Container, Row,
} from '@swift-paypal/pp-react';
import resolve from '../../services/route.service';
import withUrlParams from '../../hocs/with-url-params';
import routes from '../../routes';

const UpdateProfileItems = (props) => {
  const { country } = props;
  return (
    <div className="action-items">
      <div className="list-items margin-top-xs margin-bottom-xs">
        <Container fluid>
          <Row className="stipulation">
            <Col xs={6} sm={8} md={7}>
              Finish setting up your profile
            </Col>
            <Col xs={6} sm={4} md={5}>
              <Link to={resolve(routes.settings.path, { country })}>
                <Button className="fixed-btn">
                  Get Started
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

UpdateProfileItems.propTypes = {
  country: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  withUrlParams,
)(UpdateProfileItems);
