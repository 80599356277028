import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { push as pushAction } from 'connected-react-router';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import IdleTimer from 'react-idle-timer';
import log from 'loglevel';
import SessionExtensionModal from '../components/common/session-extension-modal/session-extension-modal';
import resolve from '../services/route.service';
import { AlertTypes } from '../components/common/alert';
import { addAuthorized, addDeauthorized } from '../redux/actions/auth/auth-action-types';
import { isUserAuthorized, extractUserFromDecodedToken } from '../lib/token';
import { createUrlBeforeLoggedOut as createUrlBeforeLoggedOutAction } from '../redux/actions/redirect-urls/redirect-urls-actions';
import { createLoginPageMessageAction } from '../redux/actions/login/login-page-actions';
import {
  getWindow,
  getLocation,
  getPathname,
  getSearch,
} from '../services/window.service';
import { keepAliveAction } from '../redux/actions/auth/auth-actions';
import routes from '../routes';
import getDisplayName from '../lib/get-display-name';
import migratedFlowConstant from '../constants/migratedFlowConstant';

/*
 * Causes page component to be redirected to `/login` if there is no
 * `this.props.token` available. Tacks on `?redirect=` query param with the
 * `window.location` which the sign in page can redirect user back to on
 * successful authentication.
 */

const ensureLoggedIn = (Page) => {
  class EnsureLoggedIn extends Component {
    static propTypes = {
      authorized: PropTypes.bool,
      addAuthorizedToState: PropTypes.func.isRequired,
      addDeauthorizedToState: PropTypes.func.isRequired,
      country: PropTypes.string,
      createIndexPageMessage: PropTypes.func.isRequired,
      createUrlBeforeLoggedOut: PropTypes.func.isRequired,
      keepAlive: PropTypes.func.isRequired,
      location: ReactRouterPropTypes.location.isRequired,
      match: ReactRouterPropTypes.match.isRequired,
      push: PropTypes.func.isRequired,
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
      warningTimeOutMilliseconds: PropTypes.number.isRequired,
      logoutTimeOutMilliseconds: PropTypes.number.isRequired,
    };

    static defaultProps = {
      authorized: false,
      country: undefined,
      user: undefined,
    };

    constructor() {
      super();
      this.state = {
        initializeInterceptors: this.initializeInterceptors(),
        isOpen: false,
        isTimedOut: false,
      };
      this.idleTimer = null;
    }

    // checks for token and deauthorizes during page transitions
    componentDidMount() {
      const { addAuthorizedToState, user, warningTimeOutMilliseconds } = this.props;
      this.setState({ timeoutValue: warningTimeOutMilliseconds });
      if (process.browser) {
        if (!isUserAuthorized()) {
          this.deauthorizeUser();
        }
        else {
          addAuthorizedToState(user || extractUserFromDecodedToken());
        }
      }
    }

    // checks for token and deauthorizes during page transitions
    componentDidUpdate(prevProps) {
      const { addAuthorizedToState, user } = this.props;
      if (process.browser) {
        if (!isUserAuthorized()) {
          this.deauthorizeUser();
        }
        else if (!prevProps.authorized) {
          addAuthorizedToState(user || extractUserFromDecodedToken());
        }
      }
    }

    componentWillUnmount() {
      this.ejectInterceptors();
    }

    deauthorizeUser = () => {
      const {
        addDeauthorizedToState,
        createIndexPageMessage,
        createUrlBeforeLoggedOut,
        push,
        location,
        match,
      } = this.props;

      const url = getWindow() && getLocation()
        ? getPathname() + getSearch() : location.pathname;

      if (url !== '/') {
        createUrlBeforeLoggedOut(url);
      }

      addDeauthorizedToState();
      createIndexPageMessage('Your session has expired. Please log back in to continue.', AlertTypes.critical);
      const country = match?.params?.country || 'us';
      const urlParams = new URLSearchParams(location.search);
      const version = urlParams.get('version');
      // this is for email flow only where paypal-login for old flow
      // and /pp-app/paypal-login for new flow
      if (version === migratedFlowConstant.URL_APPEND) {
        push(resolve(routes.login.path, { country, version }));
      }
      else {
        push(resolve(routes.login.path, { country }));
      }
    };

    // checks 401 and deauthorizes during api calls' errors
    initializeInterceptors = () => ({
      requestInterceptor: axios.interceptors.request.use(null, this.errorHandler),
      responseInterceptor: axios.interceptors.response.use(this.responseHandler, this.errorHandler),
    });

    ejectInterceptors = () => {
      const { initializeInterceptors } = this.state;
      const { requestInterceptor, responseInterceptor } = initializeInterceptors;
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };

    errorHandler = (error) => {
      const { warningTimeOutMilliseconds } = this.props;
      const unauthorizedError = error && error.request && error.request.status === 401;

      // warning: do not check for token validity here AND inside this HOC's lifecycle hooks to avoid circular redirects
      if (unauthorizedError) {
        this.deauthorizeUser();
        this.ejectInterceptors();
      }
      else {
        this.setState({ isTimedOut: false, timeoutValue: warningTimeOutMilliseconds });
        if (this.idleTimer) {
          this.idleTimer.reset();
        }
      }

      return Promise.reject(error);
    };

    responseHandler = (response) => {
      if (this.idleTimer) {
        const { warningTimeOutMilliseconds } = this.props;
        this.setState({ isTimedOut: false, timeoutValue: warningTimeOutMilliseconds });
        this.idleTimer.reset();
      }
      return response;
    };

    toggleModal = () => {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen });
    }

    stayLoggedIn = async () => {
      const { keepAlive, warningTimeOutMilliseconds } = this.props;
      const keepAliveResponse = await keepAlive();
      if (keepAliveResponse.error) {
        log.error(keepAliveResponse);
        return;
      }
      this.setState({ isTimedOut: false, timeoutValue: warningTimeOutMilliseconds });
      if (this.idleTimer) {
        this.idleTimer.reset();
      }
      this.toggleModal();
    };

    handleOnIdle = () => {
      const { logoutTimeOutMilliseconds } = this.props;
      const { isTimedOut } = this.state;
      if (isTimedOut) {
        this.deauthorizeUser();
      }
      else {
        this.setState({ isOpen: true, timeoutValue: logoutTimeOutMilliseconds });
        if (this.idleTimer) {
          this.idleTimer.reset();
        }
        this.setState({ isTimedOut: true });
      }
    };

    render() {
      const { isOpen, timeoutValue } = this.state;
      return (
        <>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            key={timeoutValue}
            timeout={timeoutValue}
            events={[]}
            onIdle={this.handleOnIdle}
            debounce={250}
          />
          <SessionExtensionModal
            isOpen={isOpen}
            stayLoggedInFunc={this.stayLoggedIn}
          />
          <Page {...this.props} />
        </>
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    addAuthorizedToState: (user) => {
      dispatch(addAuthorized(user));
    },
    addDeauthorizedToState: () => {
      dispatch(addDeauthorized());
    },
    createUrlBeforeLoggedOut: (url) => dispatch(createUrlBeforeLoggedOutAction(url)),
    createIndexPageMessage: (...args) => dispatch(createLoginPageMessageAction(...args)),
    push: (path) => dispatch(pushAction(path)),
    keepAlive: () => dispatch(keepAliveAction()),
  });

  const mapStateToProps = (state) => ({
    authorized: state.authorize.authorized,
    user: state.authorize.user,
    warningTimeOutMilliseconds: state.config.warningTimeOutMilliseconds,
    logoutTimeOutMilliseconds: state.config.logoutTimeOutMilliseconds,
  });

  EnsureLoggedIn.displayName = `EnsureLoggedIn(${getDisplayName(Page)})`;

  return compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
  )(EnsureLoggedIn);
};

export default ensureLoggedIn;
