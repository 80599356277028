import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import SecurePage from '../../hocs/secure-page';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import routes from '../../routes';
import errors from '../../constants/errors';
import getStipulationsAction, { fulfillStipulationAction } from '../../redux/actions/stipulations/stipulations-actions';
import { updatePppObjectAction } from '../../redux/actions/paycheck-protection-plan/paycheck-protection-plan-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import ReductioninGrossReceiptsComponent from '../../components/reduction-in-gross-receipts/reduction-in-gross-receipts';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

const successStipulationGroupName = 'Reduction in Gross Receipts';

class ReductionInGrossReceiptsPage extends Component {
  static displayName = displayNames.ReductionInGrossReceiptsPage;

  static propTypes = {
    accountsById: PropTypes.objectOf(PropTypes.shape({
      businessEntityType: PropTypes.string.isRequired,
    })),
    country: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    fulfillStipulationResult: PropTypes.shape({
      error: PropTypes.bool,
      pending: PropTypes.bool,
    }),
    fulfillStipulation: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    getStipulations: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      accountId: PropTypes.string,
      loanVersion: PropTypes.string,
      paycheckProtectionPlanId: PropTypes.string.isRequired,
    })),
    push: PropTypes.func.isRequired,
    updatePppObjectFunc: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    fulfillStipulationResult: {},
    match: undefined,
    opportunitiesById: {},
    accountsById: {},
    stipulations: [],
  };

  constructor() {
    super();
    this.state = { submissionError: undefined };
  }

  async componentDidMount() {
    const {
      country,
      getOpportunities,
      getStipulations,
      opportunitiesById,
      match: { params: { opportunityId } },
    } = this.props;
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
    await getStipulations(opportunityId, country);
  }

  submit = async (answers) => {
    const {
      match, updatePppObjectFunc, opportunitiesById,
    } = this.props;

    const { opportunityId } = match.params;
    const { paycheckProtectionPlanId } = opportunitiesById[opportunityId];

    const updateDataObject = {
      ...answers.data,
      opportunityId,
      paycheckProtectionPlanId,
    };
    this.setState({ submissionError: undefined });

    let shouldFulfill = false;
    try {
      const result = await updatePppObjectFunc(updateDataObject);
      if (!result || result.error) {
        this.setState({ submissionError: errors.technicalIssue });
        return;
      }
      shouldFulfill = true;
    }
    catch (error) {
      this.setState({ submissionError: errors.technicalIssue });
      return;
    }

    this.redirectToSummaryConfirmation(shouldFulfill);
  }

  async redirectToSummaryConfirmation(shouldFulfillStipGroup = false) {
    const {
      country, push, match, fulfillStipulation, stipulations,
    } = this.props;
    const { opportunityId } = match.params;
    const RoutePayload = {
      country,
      successStipulationGroupName,
    };

    if (shouldFulfillStipGroup) {
      const stipulation = stipulations.find(({ stipulationMap: { name } }) => name === successStipulationGroupName);

      if (!stipulation) {
        return undefined;
      }

      await fulfillStipulation(opportunityId, stipulation.id);

      const { fulfillStipulationResult } = this.props;
      if (fulfillStipulationResult.error && !fulfillStipulationResult.pending) {
        return undefined;
      }
    }

    heapService.markUserStipulationAsCompleted(opportunityId, successStipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      opportunitiesById, match,
    } = this.props;
    const { submissionError } = this.state;

    const { opportunityId } = match.params;
    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;
    if (!(loanVersion === '21SDPPP')) {
      return null;
    }
    const ReductionInGrossReceiptsProps = {
      updatePppObjectFunc: this.submit,
      submissionError,
    };

    return (
      <>
        <div>
          <Helmet>
            <title>Reduction in Gross Receipts</title>
          </Helmet>
          <StandardLayout
            contentElement={<ReductioninGrossReceiptsComponent {...ReductionInGrossReceiptsProps} />}
            sidebarElement={<></>}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    environment: state.config.environment,
    fulfillStipulationResult: state.fulfillStipulation,
    loanForgiveness: state.entities.loanForgiveness,
    opportunitiesById: state.entities.opportunities?.byId,
    accountsById: state.entities.accounts?.byId,
    location: state.router.location,
  };
}

const mapDispatchToProps = {
  fulfillStipulationGroup: fulfillStipulationAction,
  getOpportunities: getOpportunitiesAction,
  getStipulations: getStipulationsAction,
  updatePppObjectFunc: updatePppObjectAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurePage(ReductionInGrossReceiptsPage));
