import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Container, Row } from '@swift-paypal/pp-react';
import util from '../../../util';
import CsvDownload from '../../common/csv-download';
import Transaction from './transaction';
import './transaction.less';

const TransactionList = ({ accountName, transactions }) => {
  const noTransactionsMessage = 'You do not have any recent transactions.';
  const csvHeader = [
    { id: 'postDate', displayName: 'Date' },
    { id: 'description', displayName: 'Description' },
    { id: 'amount', displayName: 'Amount' },
    { id: 'principal', displayName: 'Principal' },
    { id: 'fee', displayName: 'Fee' },
    { id: 'other', displayName: 'Other' },
  ];
  const csvContent = transactions && transactions.length ? getContentForDownload(transactions) : undefined;
  return (
    <Container fluid className="margin-bottom-sm">
      <Row>
        <Col xs={6}>
          <h3 data-automation-id="activityDetailsLabel">Activity Details</h3>
        </Col>
        <Col xs={6} className="download-link">
          {
            csvContent && (
            <CsvDownload
              content={csvContent}
              filename={accountName}
              linkStyleClass="clickable inline-block export-link"
              columns={csvHeader}
            />
            )
          }
        </Col>
      </Row>
      <ul className="transactionList full-width-phone">
        {transactions && transactions.length > 0 ? transactions.map((transaction) => (
          <Transaction key={transaction.id} transaction={transaction} />
        )) : <div className="phone-wrap">{noTransactionsMessage}</div>}
      </ul>
    </Container>
  );
};

function getContentForDownload(transactions) {
  return transactions.map((transaction) => ({
    postDate: moment(transaction.postDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
    description: transaction.description,
    amount: `"${util.getCurrency(transaction.amount)}"`,
    principal: `"${util.getCurrency(transaction.principal)}"`,
    fee: `"${util.getCurrency(transaction.fee)}"`,
    other: `"${util.getCurrency(transaction.other)}"`,
  }));
}

TransactionList.propTypes = {
  accountName: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.object),
};

TransactionList.defaultProps = {
  accountName: undefined,
  transactions: undefined,
};

export default TransactionList;
