import React from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@swift-paypal/pp-react';
import { STANDARD_DETAILS, UNDER_REVIEW_DETAILS } from '../../stipulations/stipulations.constants';
import UnderReviewSbaDetails from '../../../under-review-sba-details/under-review-sba-details';

export const OpportunitySummaryDetails = ({ businessEntityType, showUnderReviewDetails, isBLNStip }) => {
  if (!showUnderReviewDetails) {
    return (<BodyText as="p">{STANDARD_DETAILS}</BodyText>);
  }

  if (isBLNStip) {
    return (
      <BodyText as="p">{UNDER_REVIEW_DETAILS}</BodyText>
    );
  }

  return (
    <UnderReviewSbaDetails businessEntityType={businessEntityType} />
  );
};

OpportunitySummaryDetails.propTypes = {
  businessEntityType: PropTypes.string.isRequired,
  showUnderReviewDetails: PropTypes.bool.isRequired,
  isBLNStip: PropTypes.bool.isRequired,
};
