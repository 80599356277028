import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required, length, format } from 'redux-form-validators';
import { Col, Row } from '@swift-paypal/pp-react';
import { normalizePhone } from '../../lib/forms/normalizations';
import { Alert, AlertTypes } from '../common/alert';
import floatingLabelField from '../../lib/forms/floating-label-field';
import Title from '../common/title';
import constants from '../../constants';

const { errors } = constants;

const VendorReferencesForm = (props) => {
  const {
    messageBody, messageType, onSubmit, handleSubmit,
  } = props;
  const businessNameMaxLength = 255;
  const firstNameMaxLength = 40;
  const lastNameMaxLength = 80;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {messageType === AlertTypes.critical && (
        <Col xs={12}>
          <Alert message={messageBody} alertType={messageType} />
        </Col>
      )}
      <Row>
        <Col xs={12} sm={10}>
          {messageBody && <Alert message={messageBody} alertType={messageType} />}
          <Title text="Enter vendor references" />
          <div className="vx_text-body-md_medium">Vendor reference 1</div>
          <Col xs={12}>
            <Field
              name="firstVendor.businessName"
              className="form-control vx_form-control"
              component={floatingLabelField}
              manualSet
              type="text"
              label="Vendor name"
              validate={[
                required({ msg: errors.requiredField }),
                length({
                  max: businessNameMaxLength,
                  msg: `Vendor name must be at most ${businessNameMaxLength} characters long`,
                }),
              ]}
            />
          </Col>
          <Col xs={12}>
            <Field
              name="firstVendor.firstName"
              className="form-control vx_form-control"
              component={floatingLabelField}
              manualSet
              type="text"
              label="Contact first name"
              validate={[
                required({ msg: errors.requiredField }),
                length({
                  max: firstNameMaxLength,
                  msg: `First name must be at most ${firstNameMaxLength} characters long`,
                }),
              ]}
            />
          </Col>
          <Col xs={12}>
            <Field
              name="firstVendor.lastName"
              className="form-control vx_form-control"
              component={floatingLabelField}
              manualSet
              type="text"
              label="Contact last name"
              validate={[
                required({ msg: errors.requiredField }),
                length({
                  max: lastNameMaxLength,
                  msg: `Last name must be at most ${lastNameMaxLength} characters long`,
                }),
              ]}
            />
          </Col>
          <Col xs={12} className="margin-bottom-sm">
            <Field
              name="firstVendor.phone"
              label="Contact Phone number"
              component={floatingLabelField}
              manualSet
              type="text"
              className="vx_form-control form-control"
              validate={[
                required({ msg: errors.requiredField }),
                format({ with: /^\(\d{3}\) \d{3}-\d{4}/, msg: errors.invalidPhone }),
              ]}
              normalize={normalizePhone}
            />
          </Col>
          <div className="vx_text-body-md_medium">Vendor reference 2</div>
          <Col xs={12}>
            <Field
              name="secondVendor.businessName"
              className="form-control vx_form-control"
              component={floatingLabelField}
              manualSet
              type="text"
              label="Vendor name"
              validate={[
                required({ msg: errors.requiredField }),
                length({
                  max: businessNameMaxLength,
                  msg: `Vendor name must be at most ${businessNameMaxLength} characters long`,
                }),
              ]}
            />
          </Col>
          <Col xs={12}>
            <Field
              name="secondVendor.firstName"
              className="form-control vx_form-control"
              component={floatingLabelField}
              manualSet
              type="text"
              label="Contact first name"
              validate={[
                required({ msg: errors.requiredField }),
                length({
                  max: firstNameMaxLength,
                  msg: `First name must be at most ${firstNameMaxLength} characters long`,
                }),
              ]}
            />
          </Col>
          <Col xs={12}>
            <Field
              name="secondVendor.lastName"
              className="form-control vx_form-control"
              component={floatingLabelField}
              manualSet
              type="text"
              label="Contact last name"
              validate={[
                required({ msg: errors.requiredField }),
                length({
                  max: lastNameMaxLength,
                  msg: `Last name must be at most ${lastNameMaxLength} characters long`,
                }),
              ]}
            />
          </Col>
          <Col xs={12} className="margin-bottom-sm">
            <Field
              name="secondVendor.phone"
              label="Contact Phone number"
              component={floatingLabelField}
              manualSet
              type="text"
              className="vx_form-control form-control"
              validate={[
                required({ msg: errors.requiredField }),
                format({ with: /^\(\d{3}\) \d{3}-\d{4}/, msg: errors.invalidPhone }),
              ]}
              normalize={normalizePhone}
            />
          </Col>
        </Col>
      </Row>
    </form>
  );
};

VendorReferencesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  messageBody: PropTypes.string,
  messageType: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

VendorReferencesForm.defaultProps = {
  messageBody: undefined,
  messageType: undefined,
};

function mapStateToProps(state, props) {
  let firstVendor = {};
  let secondVendor = {};
  if (props.contacts) {
    const vendors = props.contacts.filter((contact) => contact.role === 'Vendor/Supplier');
    if (vendors && vendors.length > 0) {
      [firstVendor] = vendors;
    }
    if (vendors && vendors.length > 1) {
      [, secondVendor] = vendors;
    }
  }
  return {
    initialValues: {
      firstVendor,
      secondVendor,
    },
  };
}

const VendorReferencesFormComponent = reduxForm({
  form: 'vendor-references',
  enableReinitialize: true,
})(VendorReferencesForm);

export default connect(mapStateToProps)(VendorReferencesFormComponent);
