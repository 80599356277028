import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import PropTypes from 'prop-types';
import { initialize } from 'redux-form';
import util from '../util';
import { getCookie, removeLocalStorage } from '../services/window.service';
import { setUpdatePasswordMessageAction, updatePasswordAction, verifyResetTokenAction } from '../redux/actions/auth/auth-actions';
import PageTitle from '../components/common/page-title';
import SetPasswordFormComponent from '../components/set-password-form';
import constants from '../constants';
import { AlertTypes } from '../components/common/alert';
import AnonymousPage from '../hocs/anonymous-page';
import resolve from '../services/route.service';
import routes from '../routes';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

const { errors } = constants;
const { accounts } = constants;

class CreateAccountPage extends Component {
  static displayName = displayNames.CreateAccountPage;

  static propTypes = {
    customerSupportPhone: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    push: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    resetTokenIsInvalid: PropTypes.bool,
    setUpdatePasswordMessage: PropTypes.func.isRequired,
    successToken: PropTypes.string,
    token: PropTypes.string,
    updatePassword: PropTypes.func.isRequired,
    verifyResetToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customerSupportPhone: undefined,
    country: undefined,
    email: undefined,
    messageBody: undefined,
    messageType: undefined,
    resetTokenIsInvalid: undefined,
    successToken: undefined,
    token: undefined,
  };

  constructor(props) {
    super(props);
    const { token } = props;
    const inputToken = token ?? getCookie('accountToken');
    this.state = { title: accounts.createAccount, inputToken };
  }

  async componentDidMount() {
    const { verifyResetToken } = this.props;
    removeLocalStorage('token');
    const { inputToken } = this.state;

    if (inputToken) {
      await verifyResetToken(inputToken);
    }
    else {
      this.createErrorMessage();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { country, push } = this.props;
    if (nextProps.successToken) {
      push(resolve(routes['security-questions'].path, { country }));
      return false;
    }

    return true;
  }

  async componentDidUpdate(prevProps) {
    const { resetTokenIsInvalid } = this.props;
    const { inputToken } = this.state;
    if (inputToken && resetTokenIsInvalid !== prevProps.resetTokenIsInvalid) {
      if (resetTokenIsInvalid) {
        this.createErrorMessage();
      }
    }
  }

  submit = (values) => {
    const { resetForm, updatePassword } = this.props;
    const { inputToken } = this.state;
    util.blurOnSubmit();

    if (inputToken && values && values.password) {
      updatePassword(inputToken, values.password);
    }
    resetForm();
  };

  createErrorMessage() {
    const { customerSupportPhone, setUpdatePasswordMessage } = this.props;
    setUpdatePasswordMessage(errors.createError(customerSupportPhone), AlertTypes.critical);
  }

  render() {
    const
      {
        messageBody,
        messageType,
        email,
      } = this.props;

    const { inputToken, title } = this.state;

    if (!inputToken) {
      return null;
    }

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <>
              <PageTitle text={title} />
              <div>
                <SetPasswordFormComponent
                  hasToken={!!inputToken}
                  onSubmit={this.submit}
                  messageBody={messageBody}
                  messageType={messageType}
                  email={email}
                  showEmail
                />
              </div>
            </>
          )}
          sidebarElement={<></>}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerSupportPhone: state.config.customerSupportPhone,
  messageBody: state.updatePassword.messageBody,
  messageType: state.updatePassword.messageType,
  successToken: state.updatePassword.token,
  resetTokenIsInvalid: state.resetToken.error,
  email: state.resetToken.email,
});

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (token, password) => {
    dispatch(updatePasswordAction(token, password));
  },
  setUpdatePasswordMessage: (messageBody, messageType) => {
    dispatch(setUpdatePasswordMessageAction(messageBody, messageType));
  },
  resetForm: () => {
    dispatch(initialize('create-password'));
  },
  verifyResetToken: (resetToken) => {
    dispatch(verifyResetTokenAction(resetToken));
  },
  push: (path) => dispatch(pushAction(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousPage(CreateAccountPage));
