import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import displayNames from '../constants/displayNames';
import { getAdvancesAction } from '../redux/actions/advances/advances-actions';
import { getContactNotificationsAction } from '../redux/actions/notifications/notifications-actions';
import { getProductsAndBfesAction, renewLoanAction } from '../redux/actions/account-items/account-items-actions';
import { getHomePhoneAndBusinessWebAddressAction } from '../redux/actions/settings/settings-actions';
import { getOpportunitiesAction } from '../redux/actions/opportunities/opportunities-actions';
import { getOwnersAction } from '../redux/actions/application/ownership/ownership-actions';
import { getAccountsAction, getIsRenewalEligibleAction } from '../redux/actions/account/account-actions';
import { setActiveOpportunityId, clearActiveIds as clearActiveIdsAction } from '../redux/actions/active-ids/active-ids-actions';
import { updateConfigValuesAction } from '../redux/actions/config/config-actions';
import { overrideConfigWithQuery } from '../util';
import SidebarComponent from '../components/common/sidebar/sidebar';
import SummaryComponent from '../components/summary/summary';
import StandardLayout from '../layouts/StandardLayout';
import opportunityConstants from '../constants/opportunity';

class SummaryPage extends Component {
  static displayName = displayNames.SummaryPage;

  async componentDidMount() {
    const {
      clearActiveIds,
      environment,
      getAccounts,
      getAdvances,
      getContactNotifications,
      getHomePhoneAndBusinessWebAddress,
      getOpportunities,
      getOwners,
      getProductsAndBfes,
      location,
      setActiveOppId,
      updateConfigValues,
    } = this.props;
    clearActiveIds();
    await getProductsAndBfes();
    getAccounts();
    await getAdvances();
    await getOpportunities();
    await getContactNotifications(location);
    overrideConfigWithQuery(environment, location?.query, updateConfigValues);

    const {
      accounts,
      getIsRenewalEligible,
      opportunities,
    } = this.props;

    const { closedLost, closedWon } = opportunityConstants;

    const latestOpenOpportunity = Object.values(opportunities.byId)
      .filter(({ stageName }) => stageName !== closedLost && stageName !== closedWon)
      .reduce(
        (previousValue, currentValue) => {
          const previousDate = previousValue && moment(previousValue.applicationReceivedDate);
          const currentDate = moment(currentValue.applicationReceivedDate);

          if ((!previousDate || previousDate.isBefore(currentDate))) {
            return currentValue;
          }

          return previousValue;
        },
        undefined,
      );

    if (latestOpenOpportunity) {
      setActiveOppId(latestOpenOpportunity.id);
      await getOwners(latestOpenOpportunity.id);
    }

    if (accounts && accounts.length > 0) {
      const account = accounts[0];

      await getIsRenewalEligible(account.accountId);
      if (account.loans && account.loans.length > 0) {
        const loan = account.loans[0];
        await getHomePhoneAndBusinessWebAddress(loan.contactId);
      }
    }
  }

  render() {
    const {
      accounts,
      bfes,
      country,
      products,
      renewLoan,
      accountsPending,
    } = this.props;

    const nonSystemBfes = bfes && bfes.length ? bfes.filter((x) => !x.isSystemUser) : undefined;
    const accountsWithProducts = accounts?.filter(hasProducts);

    const summaryProps = {
      accounts,
      bfes,
      country,
      openAccounts: accountsWithProducts,
      products,
      renewFunc: renewLoan,
    };

    const sidebarProps = {
      bfes: bfes || nonSystemBfes,
      openAccounts: accountsWithProducts,
    };

    return (accountsPending === false) ? (
      <div className="summary-container">
        <Helmet>
          <title>Summary</title>
        </Helmet>
        <div className="standard-layout--desktop">
          <StandardLayout
            contentElement={<SummaryComponent {...summaryProps} />}
            sidebarElement={<SidebarComponent {...sidebarProps} />}
          />
        </div>
        <div className="standard-layout--mobile">
          <StandardLayout
            contentElement={(
              <SidebarComponent {...sidebarProps}>
                <SummaryComponent {...summaryProps} />
              </SidebarComponent>
            )}
          />
        </div>
      </div>
    ) : <></>;
  }
}

const hasProducts = (account) => account.loans.length > 0 || !account.hasClosedOpportunities
  || (account.inFlightOpportunities && account.inFlightOpportunities.length > 0);

SummaryPage.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  accountsPending: PropTypes.bool,
  bfes: PropTypes.arrayOf(PropTypes.object),
  clearActiveIds: PropTypes.func.isRequired,
  country: PropTypes.string,
  environment: PropTypes.string.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getAdvances: PropTypes.func.isRequired,
  getContactNotifications: PropTypes.func.isRequired,
  getHomePhoneAndBusinessWebAddress: PropTypes.func.isRequired,
  getIsRenewalEligible: PropTypes.func.isRequired,
  getOpportunities: PropTypes.func.isRequired,
  getOwners: PropTypes.func.isRequired,
  getProductsAndBfes: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  opportunities: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.object,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  renewLoan: PropTypes.func.isRequired,
  setActiveOppId: PropTypes.func.isRequired,
  updateConfigValues: PropTypes.func.isRequired,
};
SummaryPage.defaultProps = {
  accounts: undefined,
  accountsPending: false,
  bfes: undefined,
  country: undefined,
  products: undefined,
};

function mapStateToProps(state) {
  return {
    accounts: state.accountItems.accounts,
    accountsPending: state.accountItems.accountsPending,
    bfes: state.accountItems.bfes,
    environment: state.config.environment,
    location: state.router.location,
    opportunities: state.entities.opportunities,
    stipulations: state.stipulations.allStipulations,
  };
}

const mapDispatchToProps = {
  clearActiveIds: clearActiveIdsAction,
  getAccounts: getAccountsAction,
  getAdvances: getAdvancesAction,
  getContactNotifications: getContactNotificationsAction,
  getHomePhoneAndBusinessWebAddress: getHomePhoneAndBusinessWebAddressAction,
  getOpportunities: getOpportunitiesAction,
  getProductsAndBfes: getProductsAndBfesAction,
  getIsRenewalEligible: getIsRenewalEligibleAction,
  getOwners: getOwnersAction,
  renewLoan: renewLoanAction,
  setActiveOppId: setActiveOpportunityId,
  updateConfigValues: updateConfigValuesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage);
