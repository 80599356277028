import React from 'react';
import { Helmet } from 'react-helmet-async';
import displayNames from '../../../constants/displayNames';
import SecurePage from '../../../hocs/secure-page';
import { useActiveForgivenessSelected } from '../../../hooks';
import BetterStandardLayout from '../../../layouts/better-standard-layout/better-standard-layout';
import { ForgivenessGrossReceipts } from '../../features';

const ForgivenessGrossReceiptsPage = () => {
  useActiveForgivenessSelected();
  return (
    <div className="summary-container">
      <Helmet title="Reduction in Gross Receipts" />
      <BetterStandardLayout
        bodyElement={<ForgivenessGrossReceipts />}
      />
    </div>
  );
};

ForgivenessGrossReceiptsPage.displayName = displayNames.ForgivenessGrossReceiptsPage;

export default SecurePage(ForgivenessGrossReceiptsPage);
