import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useShallowSelector } from '../../../hooks';
import { clearActiveStipulationId } from '../../../redux/actions/active-ids/active-ids-actions';
import getStipulations from '../../../redux/actions/stipulations/stipulations-actions';
import { getCreditStipulations, getIsApplicationUnderReview } from '../stipulations/stipulations.utils';
import { StipulationList } from '../stipulations';
import { OpportunitySummaryDetails } from './opportunitySummaryDetails';
import { opportunitySummarySelector } from './opportunitySummary.selectors';

export const OpportunitySummary = ({ isBLNStip = false }) => {
  const dispatch = useDispatch();
  const { country } = useParams();

  const {
    activeOpportunityId,
    opportunity,
    stipulations,
  } = useShallowSelector(opportunitySummarySelector);

  useEffect(() => {
    dispatch(clearActiveStipulationId());
    if (activeOpportunityId) {
      dispatch(getStipulations(activeOpportunityId, country));
    }
  }, [activeOpportunityId]);

  if (!activeOpportunityId) {
    return null;
  }

  const activeStipulations = getCreditStipulations(activeOpportunityId, stipulations);

  const showUnderReviewDetails = getIsApplicationUnderReview(opportunity, activeStipulations);
  const showStipulations = !showUnderReviewDetails && activeStipulations?.some(({ fulfilled }) => !fulfilled);

  return (
    <>
      <OpportunitySummaryDetails
        businessEntityType={opportunity?.businessEntityType}
        showUnderReviewDetails={showUnderReviewDetails}
        isBLNStip={isBLNStip}
      />
      {showStipulations && <StipulationList activeStipulations={activeStipulations} isBLNStip={isBLNStip} />}
    </>
  );
};

OpportunitySummary.propTypes = {
  isBLNStip: PropTypes.bool.isRequired,
};
