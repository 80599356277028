import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Container } from '@swift-paypal/pp-react';
import getTermsOfUseAction from '../redux/actions/terms/terms-actions';
import AnonymousPage from '../hocs/anonymous-page';
import displayNames from '../constants/displayNames';

class TermsOfUsePage extends Component {
  static displayName = displayNames.TermsOfUsePage;

  static propTypes = {
    country: PropTypes.string,
    getTermsOfUse: PropTypes.func.isRequired,
    terms: PropTypes.shape({
      copy: PropTypes.string,
      latest_version: PropTypes.string,
    }),
  };

  static defaultProps = {
    country: undefined,
    terms: undefined,
  };

  componentDidMount() {
    const { getTermsOfUse, country } = this.props;
    getTermsOfUse(country);
  }

  static getMarkup(markup) {
    return { __html: markup };
  }

  render() {
    const { terms } = this.props;

    if (!terms) {
      return null;
    }

    return (
      <Container>
        <Helmet>
          <title>Terms of Use</title>
        </Helmet>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="terms-of-use-page" dangerouslySetInnerHTML={TermsOfUsePage.getMarkup(terms.copy)} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    terms: state.terms.termsOfUse,
  };
}

export default connect(mapStateToProps, { getTermsOfUse: getTermsOfUseAction })(AnonymousPage(TermsOfUsePage));
