import React, { memo } from 'react';
import {
  BodyText, Col, Row, Tooltip,
} from '@swift-paypal/pp-react';
import YesOrNoRadioField from '../../common/yes-or-no-radio-field';
import { fieldNames } from './field-constants';
import { useShallowSelector, fieldSelector } from '../questionnaire-selectors';

const QuestionnaireQ2 = () => {
  const {
    questionnaireQ2: fieldName,
  } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  return (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={content}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  );
};

const content = (
  <BodyText>
    During your Covered Period
    <Tooltip
      className="pp-react__tooltip--inline"
      description={(
        <>
          The
          {' '}
          <strong>Covered Period</strong>
          {' '}
          is a period that begins with your loan disbursement date and ends on a date
          selected by you that is at least 8 weeks from, but not more than 24 weeks after, your loan
          disbursement date.  You choose your covered period as part of your loan forgiveness application.
          Using the full 24-week covered period may help maximize your loan forgiveness, and we
          encourage you to evaluate the covered period length that works best for you.
        </>
      )}
    />
    , did you reduce the annual salary or hourly wages for any employee
    by more than 25% compared to the most recent full quarter before the start of your selected
    covered period?
    <Tooltip
      className="pp-react__tooltip--inline"
      description="Only applies for those employees that did not receive wages or salary at an annualized rate of pay of more than $100,000 at any point in 2019."
    />
  </BodyText>
);

export default memo(QuestionnaireQ2);
