import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireQ1 from './fields/questionnaire-q1';
import QuestionnaireQ2 from './fields/questionnaire-q2';
import QuestionnaireQ3 from './fields/questionnaire-q3';
import QuestionnaireQ4 from './fields/questionnaire-q4';
import QuestionnaireQ5 from './fields/questionnaire-q5';

const QuestionnaireFields = ({ loanVersion }) => (
  <>
    <QuestionnaireQ1 />
    <QuestionnaireQ2 />
    <QuestionnaireQ3 loanVersion={loanVersion} />
    <QuestionnaireQ4 loanVersion={loanVersion} />
    <QuestionnaireQ5 />
  </>
);

QuestionnaireFields.propTypes = {
  loanVersion: PropTypes.string,
};

QuestionnaireFields.defaultProps = {
  loanVersion: undefined,
};

export default QuestionnaireFields;
