import opportunityService from '../../../services/opportunity.service';
import advanceService from '../../../services/advance.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './advances-action-types';

export default asyncAuthorizedActionCreator(
  {
    pending: actions.ADVANCES_REQUEST_PENDING,
    complete: actions.ADVANCES_REQUEST_RECEIVED,
    error: actions.ADVANCES_REQUEST_ERROR,
  },
  (oppId) => (
    _dispatch,
    getState,
  ) => opportunityService.getAdvances(getState().config.apiUrl, oppId),
);

export const getAdvancesAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ADVANCES_REQUEST_PENDING,
    complete: actions.ADVANCES_REQUEST_RECEIVED,
    error: actions.ADVANCES_REQUEST_ERROR,
  },
  () => (
    _dispatch,
    getState,
  ) => advanceService.getAdvances(getState().config.apiUrl),
);
