export const SET_OPPORTUNITY_AS_LANDLORD_PENDING = 'set_opportunity_as_landlord_pending';
export const SET_OPPORTUNITY_AS_LANDLORD_ERROR = 'set_opportunity_as_landlord_error';
export const SET_OPPORTUNITY_AS_LANDLORD_RECEIVED = 'set_opportunity_as_landlord_received';

export const CREATE_LANDLORD_CONTACT_PENDING = 'create_landlord_contact_pending';
export const CREATE_LANDLORD_CONTACT_ERROR = 'create_landlord_contact_error';
export const CREATE_LANDLORD_CONTACT_RECEIVED = 'create_landlord_concact_received';

export const UPDATE_LANDLORD_MESSAGE = 'update_landlord_message';
export const CLEAR_LANDLORD_MESSAGE = 'clear_landlord_message';
export const LANDLORD_FORM_ERROR = 'landlord_form_error';

export const SET_OWN_BUSINESS_PROPERTY = 'set_own_business_property';

export const CLEAR_LANDLORD = 'clear_landlord';
