import {
  STIPULATIONS_REQUEST_RECEIVED,
  STIPULATIONS_REQUEST_PENDING,
  STIPULATIONS_REQUEST_ERROR,
  FULFILL_STIPULATION_CLEAR,
  FULFILL_STIPULATION_ERROR,
  FULFILL_STIPULATION_PENDING,
  FULFILL_STIPULATION_RECEIVED,
  NEW_STIPULATION_FLAG_RECEIVED,
  NEW_STIPULATION_FLAG_PENDING,
  NEW_STIPULATION_FLAG_ERROR,
} from '../actions/stipulations/stipulations-action-types';

export const stipulations = (state = {}, action) => {
  switch (action.type) {
    case STIPULATIONS_REQUEST_RECEIVED:
      return {
        ...state,
        stipulationsPending: false,
        error: null,
        allStipulations: action.payload,
      };
    case STIPULATIONS_REQUEST_PENDING:
      return {
        ...state,
        stipulationsPending: true,
      };
    case STIPULATIONS_REQUEST_ERROR:
      return {
        ...state,
        stipulationsPending: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export const fulfillStipulation = (state = null, action) => {
  switch (action.type) {
    case FULFILL_STIPULATION_PENDING:
      return {
        ...state,
        error: undefined,
        pending: true,
      };
    case FULFILL_STIPULATION_ERROR:
    case FULFILL_STIPULATION_RECEIVED:
      return {
        ...state,
        error: action.error,
        success: !action.error,
        pending: false,
      };
    case FULFILL_STIPULATION_CLEAR:
      return null;
    default:
      return state;
  }
};

export const newStipulationFlag = (state = {}, action) => {
  switch (action.type) {
    case NEW_STIPULATION_FLAG_PENDING:
      return {
        ...state,
        error: undefined,
        pending: true,
        isEnabled: false,
      };
    case NEW_STIPULATION_FLAG_ERROR:
      return {
        ...state,
        error: action.error,
        success: !action.error,
        pending: false,
        isEnabled: false,
      };
    case NEW_STIPULATION_FLAG_RECEIVED:
      return {
        ...state,
        error: action.error,
        success: !action.error,
        pending: false,
        isEnabled: action.payload.isEnabled,
      };
    default:
      return state;
  }
};
