import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { required, length } from 'redux-form-validators';
import { withRouter } from 'react-router-dom';
import { Col, Row } from '@swift-paypal/pp-react';
import { normalizeNumericTextField, normalizeRoutingNumber } from '../../lib/forms/normalizations';
import floatingLabelField from '../../lib/forms/floating-label-field';
import routingNumberField from '../../lib/forms/render-routing-number-field';
import constants from '../../constants';
import withUrlParams from '../../hocs/with-url-params';

const { countryCodes } = constants;
const { errors } = constants;

const BankAccountForm = (props) => {
  const {
    accountType,
    disabled,
    initialValues,
    institution,
    hasRouteNumberWarning,
  } = props;

  let { country } = props;
  country = country.toUpperCase();
  const isCanada = country === countryCodes.canada;
  const isPaypalAccount = initialValues && initialValues.isPaypalAccount;
  const accountLabel = accountType === constants.bank.DEPOSIT ? 'Wire Routing number' : 'ACH Routing number';
  return (
    <Row>
      <Col xs={12} sm={10}>
        <FormSection name={accountType}>
          {isCanada && (
            <>
              <Field
                name="transitNumber"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                label="Branch transit number"
                validate={disabled ? [] : [required({ msg: 'Required' }), length({ min: 5, max: 5, msg: errors.invalidBranchTransitNumber })]}
                normalize={normalizeNumericTextField}
                pattern="\d*"
                disabled={disabled}
              />
              <Field
                name="institutionNumber"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                label="Financial institution number"
                validate={disabled ? [] : [required({ msg: 'Required' }), length({ min: 3, max: 3, msg: errors.invalidFinancialInstitutionNumber })]}
                normalize={normalizeNumericTextField}
                pattern="\d*"
                disabled={disabled}
              />
            </>
          )}
          {!isCanada && !isPaypalAccount && (
            <Field
              name="routingNumber"
              manualSet
              className="form-control vx_form-control"
              component={routingNumberField}
              type="text"
              label={accountLabel}
              institution={disabled ? undefined : institution}
              validate={disabled ? [] : [required({ msg: 'Required' }), length({ min: 1, max: 9, msg: errors.invalidBankAccount })]}
              normalize={normalizeRoutingNumber}
              pattern="\d*"
              disabled={disabled}
              hasRouteNumberWarning={hasRouteNumberWarning}
            />
          )}
          {!isPaypalAccount && (
            <Field
              name="accountNumber"
              manualSet
              className="form-control vx_form-control do-not-record"
              component={floatingLabelField}
              type="text"
              maxLength="25"
              label={isCanada ? 'Financial institution account number' : 'Account number'}
              noErrorClass={accountType !== constants.bank.WITHDRAWAL ? 'margin-bottom-sm' : ''}
              validate={
                disabled ? [] : [required({ msg: 'Required' }),
                  length({
                    min: isCanada ? 1 : 4,
                    max: isCanada ? 12 : 17,
                    msg: isCanada ? errors.invalidFinancialInstitutionAccountNumber : errors.invalidBankAccount,
                  })]
              }
              normalize={normalizeNumericTextField}
              pattern="\d*"
              disabled={disabled}
            />
          )}
          {isPaypalAccount && (
            <Field
              name="paypalUsername"
              manualSet
              className="form-control vx_form-control do-not-record"
              component={floatingLabelField}
              type="text"
              maxLength="25"
              label="PayPal account"
              noErrorClass={accountType !== constants.bank.DEPOSIT ? 'margin-bottom-sm' : ''}
              disabled
            />
          )}
        </FormSection>
      </Col>
    </Row>
  );
};

BankAccountForm.propTypes = {
  accountType: PropTypes.string.isRequired,
  country: PropTypes.string,
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    isPaypalAccount: PropTypes.bool,
  }),
  institution: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  hasRouteNumberWarning: PropTypes.bool,
};

BankAccountForm.defaultProps = {
  country: undefined,
  disabled: false,
  initialValues: undefined,
  institution: undefined,
  hasRouteNumberWarning: false,
};

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(BankAccountForm);
