import moment from 'moment';
import {
  has, isEmpty, isObject, isNil, isUndefined,
} from 'lodash';
import { nonSelectableFiStatus } from './oneTimePayment.config';
import errorsConstants from '../../../constants/errors';
import constants from '../../../constants';

const { routingWarningConstantObj, oneTimePayments: oneTimePaymentContants, routingWarningBankMapping } = constants;

const bankInfoConstants = oneTimePaymentContants.BankInfoConstants;
const bankErrorFields = oneTimePaymentContants.BankInfoErrorFields;
const bankWarningFields = {
  AchRoutingNumberWarning: null,
};

export const getNextDate = () => {
  const today = new Date();
  const nextDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  const validNextDate = getNextWeekday(nextDate);
  return validNextDate;
};

export const getNext60Date = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 60);
};

export const isWeekend = (date) => {
  const dayOfWeek = date.getDay();
  // 6 = Saturday, 0 = Sunday
  return dayOfWeek === 6 || dayOfWeek === 0;
};

export const isChargeOffPeriod = (date, chargeOffDate) => {
  if (chargeOffDate !== null && date >= chargeOffDate) {
    return true;
  }
  return false;
};

export const getNextWeekday = (date) => {
  if (!isWeekend(date)) {
    return date;
  }
  const dayOfWeek = date.getDay();
  // // 6 = Saturday, 0 = Sunday
  if (dayOfWeek === 6) {
    const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
    return nextDate;
  }
  if (dayOfWeek === 0) {
    const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    return nextDate;
  }
  return date;
};

export const getDefaultBank = (
  bankList = {},
  selectedLoanAdvance,
  bankUpdatedDetailsAfterAddEdit = null,
) => {
  if (bankUpdatedDetailsAfterAddEdit !== null) {
    return bankUpdatedDetailsAfterAddEdit;
  }
  if (!Object.keys(bankList).length) return undefined;
  const bankInfo = bankList[selectedLoanAdvance.Id]?.BankAccounts?.filter(
    (bank) => bank.isDefaultBankOption === true,
  );
  if (bankInfo && bankInfo.length > 0) {
    return bankInfo[0];
  }
  return undefined;
};

export const getBankListForAdvance = (bankList = {}, selectedLoanAdvance) => {
  if (!Object.keys(bankList).length) return undefined;
  const bankListForAdv = bankList[selectedLoanAdvance.Id]?.BankAccounts;
  if (bankListForAdv && bankListForAdv.length > 0) {
    return bankListForAdv;
  }
  return undefined;
};

export const getMostDLQLoan = (loanList = []) => {
  const eligibleloans = loanList.filter((loan) => loan.IsEligibeForSSACH === true);
  if (!eligibleloans.length) {
    return undefined;
  }
  return eligibleloans.reduce((prev, curr) => {
    if (Number(prev.PastDueNumberOfDays) > Number(curr.PastDueNumberOfDays)) return prev;
    if (Number(prev.PastDueNumberOfDays) === Number(curr.PastDueNumberOfDays)) {
      if (Number(prev.Balance) > Number(curr.Balance)) return prev;
      return curr;
    }
    return curr;
  });
};

export const getDestructuredFIInfo = (bankList, selectedLoanAdvance) => {
  const listOfBanks = getBankListForAdvance(bankList, selectedLoanAdvance) || [];
  return listOfBanks.reduce(
    (acc, fi) => {
      if (nonSelectableFiStatus.includes(fi.BankAccountACHStatusDescription)) {
        acc.nonSelectableFis.push(fi);
      }
      else acc.selectableFis.push(fi);
      return acc;
    },
    { selectableFis: [], nonSelectableFis: [] },
  );
};

export const getLoansBasedOnDLQ = (loanList = []) => {
  /*
   * DLQ status Logic = Get the one with most PastDueNumberOfDays
   * For similar DLQ status, we will pick the one with the highest outstanding balance.
   * Logic for default loan = most DLQ status and eligibleForSSH
   * If all loan is non-eligible, select the one with latest DateWireIssued
   * Order of loan = In the order of latest to oldest DateWireIssued.
   */
  if (!loanList.length) {
    return {
      loanDropdownOptions: [],
      mostDLQLoanAdvance: null,
    };
  }
  const loanAdvanceList = [...loanList];
  let mostDLQLoanAdvance = getMostDLQLoan(loanAdvanceList);
  const loanDropdownOptions = loanAdvanceList.sort(
    (loanA, loanB) => moment(loanB.DateWireIssued).format('YYYYMMDD')
      - moment(loanA.DateWireIssued).format('YYYYMMDD'),
  );
  if (!mostDLQLoanAdvance) {
    [mostDLQLoanAdvance] = loanDropdownOptions;
  }
  return {
    loanDropdownOptions,
    mostDLQLoanAdvance,
  };
};

/**
 * This returns required message for fields
 * @param {any} field- field for which required message needs to be created
 */
const requiredError = (field) => `${field} is required.`;

/**
 * This returns didnt match error message for fields
 * @param {any} field- field for which required message needs to be created
 */
const fieldDidntMatchError = (field) => `${field} didn't match.`;
/**
 * This function matches the value of two field like bank accounts number and routing number
 * @param {any} fieldValue1 - value of the field in add/edit modal
 * @param {any} fieldValue2 - value of the field against which the matching is done
 * @param {object} errorObjToUpdate -  error object of context
 * @param {object} errorKey -  error key in which validation message is updated
 * @param {string} errorMessageToUpdate -  error message to update
 */
const MatchFieldValues = (fieldValue1, fieldValue2) => {
  if (
    !isUndefined(fieldValue1)
    && !isUndefined(fieldValue2)
    && fieldValue1 !== ''
    && fieldValue2 !== ''
  ) {
    if (fieldValue1 !== fieldValue2) {
      return true;
    }
  }
  return false;
};

/**
 * This function validates the fields for ADD/EDIT bank against regex we provide
 * @param {string} regexPattern - regex pattern for validation
 * @param {any} fieldValue - value of the field in add/edit modal
 * @param {object} errorObjToUpdate -  error object of context
 * @param {object} errorKey -  error key in which validation message is updated
 * @param {string} errorMessageToUpdate -  error message to update
 */
const regexValidation = (regexPattern, fieldValue) => {
  if (!isUndefined(fieldValue) && fieldValue !== '' && !isUndefined(regexPattern)) {
    if (!regexPattern.test(fieldValue)) {
      return true;
    }
  }
  return false;
};

const validationConfig = {
  [bankInfoConstants.BankAccount.BusinessName]: {
    errorFieldKey: bankErrorFields.BankAccount.BusinessNameError,
    isRequired: {
      error: requiredError('Business Name'),
    },
  },
  [bankInfoConstants.BankAccount.BankName]: {
    errorFieldKey: bankErrorFields.BankAccount.BankNameError,
    isRequired: {
      error: requiredError('Bank Name'),
    },
  },
  [bankInfoConstants.BankAccount.ACHRoutingNumber]: {
    errorFieldKey: bankErrorFields.BankAccount.ACHRoutingNumberError,
    isRequired: {
      error: requiredError('ACH routing Number'),
    },
    isFieldMatch: {
      matchFieldKey: bankInfoConstants.BankAccount.ReACHRoutingNumber,
      matchErrorFieldKey: bankErrorFields.BankAccount.ReACHRoutingNumberError,
      error: fieldDidntMatchError('Routing number'),
    },
  },
  [bankInfoConstants.BankAccount.ReACHRoutingNumber]: {
    errorFieldKey: bankErrorFields.BankAccount.ReACHRoutingNumberError,
    isRequired: {
      error: requiredError('Re entering ACH routing Number'),
    },
    isFieldMatch: {
      matchFieldKey: bankInfoConstants.BankAccount.ACHRoutingNumber,
      matchErrorFieldKey: bankErrorFields.BankAccount.ReACHRoutingNumberError,
      error: fieldDidntMatchError('Routing number'),
    },
  },
  [bankInfoConstants.BankAccount.BankAccountNumber]: {
    errorFieldKey: bankErrorFields.BankAccount.BankAccountNumberError,
    isRequired: {
      error: requiredError('Bank Account number'),
    },
    isFieldMatch: {
      matchFieldKey: bankInfoConstants.BankAccount.ReBankAccountNumber,
      matchErrorFieldKey: bankErrorFields.BankAccount.ReBankAccountNumberError,
      error: fieldDidntMatchError('Bank Account number'),
    },
    isRegex: {
      pattern: /^[0-9]{4,17}$/,
      error: errorsConstants.invalidBankAccount,
    },
  },
  [bankInfoConstants.BankAccount.ReBankAccountNumber]: {
    errorFieldKey: bankErrorFields.BankAccount.ReBankAccountNumberError,
    fieldName: '',
    isRequired: {
      error: requiredError('Re entering Bank Account Number'),
    },
    isFieldMatch: {
      matchFieldKey: bankInfoConstants.BankAccount.BankAccountNumber,
      matchErrorFieldKey: bankErrorFields.BankAccount.ReBankAccountNumberError,
      error: fieldDidntMatchError('Bank Account number'),
    },
  },
  [bankInfoConstants.BankAccount.StreetAddress1]: {
    errorFieldKey: bankErrorFields.BankAccount.StreetAddress1Error,
    isRequired: {
      error: requiredError('Address Line 1'),
    },
  },
  [bankInfoConstants.BankAccount.StreetAddress2]: {
    errorFieldKey: bankErrorFields.BankAccount.StreetAddress2Error,
    isRequired: {
      error: requiredError('Address Line 2'),
    },
  },
  [bankInfoConstants.BankAccount.State]: {
    errorFieldKey: bankErrorFields.BankAccount.StateError,
    isRequired: {
      error: requiredError('State'),
    },
  },
  [bankInfoConstants.BankAccount.City]: {
    errorFieldKey: bankErrorFields.BankAccount.CityError,
    isRequired: {
      error: requiredError('City'),
    },
  },
  [bankInfoConstants.BankAccount.Zip]: {
    errorFieldKey: bankErrorFields.BankAccount.ZipError,
    isRequired: {
      error: requiredError('Zip Code'),
    },
    isRegex: {
      pattern: /^\d{5}?$/,
      error: 'ZIP must be a valid Zip Code',
    },
  },
};

const requiredValidation = (fieldValueToBeChecked) => isEmpty(fieldValueToBeChecked);

/**
 * This function validates the fields for ADD/EDIT bank scenario and updates error object of
 * contextProvider based on valdation we provide
 * @param {string} fieldKey - field key in which value is mapped
 * @param {object} errorObject -  error object of context
 * @param {any} fieldValue - value of the field in add/edit modal
 * @param {object} bankInfo - object that holds all value
 */

export const validationUtil = (fieldKey, errorObject, fieldValue = null, bankInfo) => {
  const {
    isRequired, isFieldMatch, isRegex, errorFieldKey,
  } = validationConfig[fieldKey];
  if (has(bankErrorFields.BankAccount, errorFieldKey) && !isEmpty(bankInfo) && isObject(bankInfo)) {
    const fieldValueToBeChecked = isNil(fieldValue) ? bankInfo[fieldKey] : fieldValue;
    let isRequiredError;
    if (isRequired) {
      isRequiredError = requiredValidation(fieldValueToBeChecked);
      errorObject[errorFieldKey] = isRequiredError ? isRequired.error : null;
    }
    if (isFieldMatch && !isRequiredError) {
      const isMatchingError = MatchFieldValues(
        fieldValueToBeChecked,
        bankInfo[isFieldMatch.matchFieldKey],
      );
      errorObject[isFieldMatch.matchErrorFieldKey] = isMatchingError ? isFieldMatch.error : null;
    }
    if (isRegex && !isRequiredError) {
      const isRegexError = regexValidation(isRegex.pattern, fieldValueToBeChecked);
      errorObject[errorFieldKey] = isRegexError ? isRegex.error : null;
    }
  }
};

export const getIntialBankInfo = (type = 'info') => Object.values((type === 'error' ? bankErrorFields : bankInfoConstants).BankAccount).reduce(
  (acc, curr) => {
    acc[curr] = '';
    return acc;
  },
  {},
);

export const getInitialBankWarning = () => bankWarningFields;

export const getIntialDataForEdit = (fiData) => {
  const intialEditBankInfoData = {
    [bankInfoConstants.BankAccount.BusinessName]: fiData?.BankAccountNameOnAccount ?? '',
    [bankInfoConstants.BankAccount.BankName]: fiData?.FinancialInstitutionName ?? '',
    [bankInfoConstants.BankAccount.ACHRoutingNumber]: fiData?.BankAccountABA ?? '',
    [bankInfoConstants.BankAccount.BankAccountNumber]: fiData?.BankAccountNumber ?? '',
    [bankInfoConstants.BankAccount.ReACHRoutingNumber]: fiData?.BankAccountABA ?? '',
    [bankInfoConstants.BankAccount.ReBankAccountNumber]: fiData?.BankAccountNumber ?? '',
    [bankInfoConstants.BankAccount.StreetAddress1]: fiData?.AccountOwnerStreet ?? '',
    [bankInfoConstants.BankAccount.StreetAddress2]: fiData?.AccountOwnerStreet2 ?? '',
    [bankInfoConstants.BankAccount.State]: fiData?.AccountOwnerState ?? '',
    [bankInfoConstants.BankAccount.City]: fiData?.AccountOwnerCity ?? '',
    [bankInfoConstants.BankAccount.Zip]: fiData?.AccountOwnerZip ?? '',
  };
  return intialEditBankInfoData;
};

export function dateSort(a, b, ascending = true) {
  const aDate = moment(a.EffectiveDate);
  const bDate = moment(b.EffectiveDate);

  if (aDate === bDate) {
    return 0;
  }

  if (ascending) {
    return aDate > bDate ? -1 : 1;
  }

  return aDate > bDate ? 1 : -1;
}

/**
 * map the available routing number in more usable format
 * @param {routingList} routingList- static list of routing numbers we have
 */
export const MapRoutingToBank = (routingInstitutionList) => {
  const mapRouting = routingInstitutionList.reduce((accumulator, routingInfo) => {
    const bankCategory = accumulator.find((item) => item.bankName === routingInfo.Name);
    const routingVal = { routingNumber: routingInfo?.RoutingNumber__c, stateCode: routingInfo?.StateCode__c };
    if (bankCategory) {
      bankCategory.routingDetails.push(routingVal);
    }
    else {
      accumulator.push({ bankName: routingInfo.Name, routingDetails: [routingVal] });
    }
    return accumulator;
  }, []);

  return mapRouting;
};

/**
 * find out of user has entered flagged routing number which needs a suggestion
 * @param {string} fieldValue- field value for ACH routing
 * @param {string} businessBankState- selected business state on based of which we suggest routing number
 */
export function hasRoutingNumberWarning(fieldValue, businessBankState) {
  const excludedStates = routingWarningConstantObj[fieldValue];
  if (isUndefined(excludedStates)) {
    return false;
  }
  if (excludedStates.includes(businessBankState)) {
    return false;
  }
  return true;
}

/**
 * Suggest routing number to users if user entered routing number fall in ro3 flagged list
 * @param {string} fieldValue- field value for ACH routing
 * @param {string} businessBankState- selected business state on based of which we suggest routing number
 * @param {routingList} routingList- static list of routing numbers we have
 */
export function suggestRoutingNumber(fieldValue, businessBankState, routingList) {
  const bankName = routingWarningBankMapping[fieldValue];
  if (isUndefined(bankName) || isUndefined(businessBankState) || isUndefined(routingList)) {
    return null;
  }
  const selectedBankInfo = routingList.filter((el) => el.bankName === bankName);
  const bankRoutingList = selectedBankInfo?.[0]?.routingDetails;
  if (isUndefined(bankRoutingList)) {
    return null;
  }
  const stateSpecificRoutingNumber = bankRoutingList.filter((el) => el.stateCode === businessBankState);
  const routingNumberSuggestion = stateSpecificRoutingNumber?.[0]?.routingNumber;
  if (isUndefined(routingNumberSuggestion)) {
    return null;
  }
  const suggestionText = `The routing number you entered is primarily used for Wire transfers. Most commonly used ACH routing number for your bank is ${routingNumberSuggestion}. Please verify your routing number before proceeding.`;
  return suggestionText;
}
