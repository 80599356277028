import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Container, Row, Col } from '@swift-paypal/pp-react';
import AuthorizeFormComponent from '../components/authorize-form';
import AuthorizeFormComponentRevamp from '../components/authorize-form-revamp';
import { getSecurityQuestionAction, authorizeAction, getSecurityQuestionsAction } from '../redux/actions/auth/auth-actions';
import AnonymousPage from '../hocs/anonymous-page';
import PageTitle from '../components/common/page-title';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class SecurityQuestionPage extends Component {
  static displayName = displayNames.SecurityQuestionPage;

  static propTypes = {
    authorize: PropTypes.func.isRequired,
    country: PropTypes.string,
    errorMessage: PropTypes.string,
    getSecurityQuestion: PropTypes.func.isRequired,
    getSecurityQuestions: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    securityQuestion: PropTypes.string,
    securityQuestions: PropTypes.shape({
      userQuestions: PropTypes.arrayOf(PropTypes.string),
      userQuestionIds: PropTypes.arrayOf(PropTypes.number),
    }),
    loginRevamp: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    country: undefined,
    errorMessage: undefined,
    securityQuestion: undefined,
    securityQuestions: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { questionId: null };
  }

  async componentDidMount() {
    const { getSecurityQuestion, getSecurityQuestions } = this.props;
    await getSecurityQuestion();
    await getSecurityQuestions();

    const { securityQuestions, loginRevamp } = this.props;
    if (securityQuestions && loginRevamp) this.handleAltQuestion(securityQuestions.userQuestionIds[0]);
  }

  handleAltQuestion = (id) => {
    this.setState({ questionId: id });
  };

  submit = (values) => {
    const { questionId } = this.state;
    const { authorize, country, history } = this.props;
    authorize({
      ...values,
      country,
      history,
      questionId,
    });
  };

  render() {
    const {
      errorMessage, securityQuestion, securityQuestions, loginRevamp, country,
    } = this.props;
    return (
      <>
        {securityQuestion && (
          <Container className="security-container">
            <Helmet>
              <title>Security Question</title>
            </Helmet>
            {loginRevamp
              ? (
                <AuthorizeFormComponentRevamp
                  onSubmit={this.submit}
                  securityQuestion={securityQuestion}
                  userQuestions={securityQuestions?.userQuestions}
                  userQuestionIds={securityQuestions?.userQuestionIds}
                  authError={errorMessage}
                  handleAltQuestion={this.handleAltQuestion}
                  country={country}
                />
              )
              : (
                <StandardLayout
                  contentElement={(
                    <Row className="security-container">
                      <Col>
                        <PageTitle text="Security Question" />
                        <AuthorizeFormComponent
                          onSubmit={this.submit}
                          securityQuestion={securityQuestion}
                          authError={errorMessage}
                        />
                      </Col>
                    </Row>
                  )}
                  sidebarElement={<></>}
                />
              )}
          </Container>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    errorMessage: state.authorize.error,
    securityQuestion: state.securityQuestion.question,
    securityQuestions: state.securityQuestions,
    authorized: state.authorize.authorized,
    loginRevamp: state.config.loginRevamp,
  };
}

export default connect(
  mapStateToProps,
  {
    getSecurityQuestion: getSecurityQuestionAction,
    getSecurityQuestions: getSecurityQuestionsAction,
    authorize: authorizeAction,
  },
)(AnonymousPage(SecurityQuestionPage));
