import guarantyClaimsService from '../../../services/guarantyClaims.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './guaranty-claims-action-types';

export const getGuarantyClaimAction = asyncAuthorizedActionCreator(
  {
    pending: actions.GUARANTY_CLAIMS_REQUEST_PENDING,
    complete: actions.GUARANTY_CLAIMS_REQUEST_RECEIVED,
    error: actions.GUARANTY_CLAIMS_REQUEST_ERROR,
  },
  (advanceId) => (
    _dispatch,
    getState,
  ) => guarantyClaimsService.getGuarantyClaim(getState().config.apiUrl, advanceId),
);
