export const SELF_SERVICE_LOAN_REQUEST_PENDING = 'self_service_loan_request_pending';
export const SELF_SERVICE_LOAN_REQUEST_RECEIVED = 'self_service_loan_request_received';
export const SELF_SERVICE_LOAN_REQUEST_ERROR = 'self_service_loan_request_error';

export const SELF_SERVICE_BANKLIST_REQUEST_PENDING = 'self_service_banklist_request_pending';
export const SELF_SERVICE_BANKLIST_REQUEST_RECEIVED = 'self_service_banklist_request_received';
export const SELF_SERVICE_BANKLIST_REQUEST_ERROR = 'self_service_banklist_request_error';

export const SCHEDULE_SERVICE_ACH_PENDING = 'schedule_service_ach_pending';
export const SCHEDULE_SERVICE_ACH_RECEIVED = 'schedule_service_ach_received';
export const SCHEDULE_SERVICE_ACH_ERROR = 'schedule_service_ach_error';
export const SCHEDULE_SERVICE_ACH_STATUS_RESET = 'schedule_service_ach_reset';

export const SELF_SERVICE_BANK_UPDATE_REQUEST_PENDING = 'self_service_bank_update_request_pending';
export const SELF_SERVICE_BANK_UPDATE_REQUEST_RECEIVED = 'self_service_bank_update_request_received';
export const SELF_SERVICE_BANK_UPDATE_REQUEST_ERROR = 'self_service_bank_update_request_error';
export const SELF_SERVICE_BANK_UPDATE_REQUEST_RESET = 'self_service_bank_update_request_reset';

export const SELF_SERVICE_BANKLIST_REQUEST_MODAL_PENDING = 'self_service_bank_update_request_modal_pending';
