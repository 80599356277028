export const ADD_BUSINESS_WEB_ADDRESS_REQUEST_PENDING = 'add_business_web_address_request_pending';
export const ADD_BUSINESS_WEB_ADDRESS_RECEIVED = 'add_business_web_address_received';
export const ADD_BUSINESS_WEB_ADDRESS_REQUEST_ERROR = 'add_business_web_address_request_error';

export const ADD_HOME_PHONE_REQUEST_PENDING = 'add_home_phone_request_pending';
export const ADD_HOME_PHONE_RECEIVED = 'add_home_phone_received';
export const ADD_HOME_PHONE_REQUEST_ERROR = 'add_home_phone_request_error';

export const GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_REQUEST_PENDING = 'get_home_phone_and_business_web_address_request_pending';
export const GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_RECEIVED = 'get_home_phone_and_business_web_address_received';
export const GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_REQUEST_ERROR = 'get_home_phone_and_business_web_address_request_error';

export const CONTACT_ACCOUNTS_REQUEST_PENDING = 'contact_accounts_request_pending';
export const CONTACT_ACCOUNTS_RECEIVED = 'contact_accounts_received';
export const CONTACT_ACCOUNTS_REQUEST_ERROR = 'contact_accounts_request_error';

export const CHANGE_PASSWORD_REQUEST_PENDING = 'change_password_request_pending';
export const CHANGE_PASSWORD_RECEIVED = 'change_password_received';
export const CHANGE_PASSWORD_REQUEST_ERROR = 'change_password_request_error';

export const LIST_SECURITY_QUESTIONS_REQUEST_PENDING = 'list_security_questions_request_pending';
export const LIST_SECURITY_QUESTIONS_RECEIVED = 'list_security_questions_received';
export const LIST_SECURITY_QUESTIONS_ERROR = 'list_security_questions_error';

export const CHANGE_SECURITY_QUESTIONS_REQUEST_PENDING = 'change_security_questions_request_pending';
export const CHANGE_SECURITY_QUESTIONS_RECEIVED = 'change_security_questions_received';
export const CHANGE_SECURITY_QUESTIONS_REQUEST_ERROR = 'change_security_questions_request_error';

export const UPDATE_SETTINGS_MESSAGE = 'update_settings_message';
export const CLEAR_SETTINGS_MESSAGE = 'clear_settings_message';

export const CHANGE_EMAIL_REQUEST_PENDING = 'change_email_request_pending';
export const CHANGE_EMAIL_RECEIVED = 'change_email_received';
export const CHANGE_EMAIL_REQUEST_ERROR = 'change_email_request_error';

export const VERIFY_EMAIL_REQUEST_PENDING = 'verify_email_request_pending';
export const VERIFY_EMAIL_RECEIVED = 'verify_email_received';
export const VERIFY_EMAIL_REQUEST_ERROR = 'verify_email_request_error';
