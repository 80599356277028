import React from 'react';
import { Divider, HeadingText } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../hooks';
import { InfoItem } from './info-item';
import { InfoItemCaptions } from './sba-loan-information-sidebar-constants';
import { getInfoItemContent } from './sba-loan-information-sidebar-util';
import { loanInformationSelector } from './sba-loan-information-sidebar-selectors';
import './sba-loan-information-sidebar.less';

export const SbaLoanInformationSidebar = () => {
  const loanInformation = useShallowSelector(loanInformationSelector);

  return (
    <div className="sba-loan-information-sidebar">
      <HeadingText size="sm">PPP Loan Forgiveness Application</HeadingText>
      <Divider secondary />
      {Object.entries(loanInformation).map(([key, value]) => (
        (value && InfoItemCaptions[key]) ? (
          <InfoItem
            caption={InfoItemCaptions[key]}
            content={getInfoItemContent(key, value)}
            key={key}
          />
        ) : null
      ))}
    </div>
  );
};
