import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';

const EntityGroupTwo = () => (
  <>
    <BodyText className="payroll-verification__content-list-header">
      To substantiate your payroll costs calculation, please upload:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1040 Schedule C: a) form must have a positive value on line 7 and b)
        must be completed and provided even if it has not yet been filed with the IRS
        (may be applicable if you selected calendar year 2020 for your payroll costs calculation).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        Farmers &amp; ranchers will provide IRS Form 1040 Schedule, Schedule 1 and Schedule F – which must
        be completed and provided even if it has not yet been filed with the IRS (may be applicable if
        you selected calendar year 2020 for your payroll costs calculation).
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate that you were self-employed for the calculation timeframe you selected,
      please provide one of the following:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1099 MISC (if applicable) detailing nonemployee compensation received (box 7).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1099-K (if applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        If neither IRS Form 1099-MISC or IRS Form 1099-K are applicable, provide an invoice,
        bank statement or other book of record establishing you were self-employed during the
        selected payroll costs calculation timeframe.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate you were self-employed on Feb 15, 2020:
    </BodyText>
    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        A 2020 invoice, bank statement, or book of record to establish that you were in operation on Feb 15, 2020.
      </BodyText>
    </BodyText>
  </>
);

export default EntityGroupTwo;
