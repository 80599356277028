import { LOGIN_PAGE_MESSAGE_CLEARED, LOGIN_PAGE_MESSAGE_CREATED } from '../actions/login/login-page-action-types';

export default function loginPage(state = {}, action) {
  switch (action.type) {
    case LOGIN_PAGE_MESSAGE_CREATED:
      return { ...state, messageBody: action.messageBody, messageType: action.messageType };
    case LOGIN_PAGE_MESSAGE_CLEARED:
      return { ...state, messageBody: null, messageType: null };
    default:
      return state;
  }
}
