import {
  Alert, Button, Col, ProgressMeter, Row,
} from '@swift-paypal/pp-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import errors from '../../../constants/errors';
import { useRedirect } from '../../../hooks';
import {
  getLoanForgivenessAction,
  updateLastPageSubmittedAction,
  updateLoanForgivenessAction,
} from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { fulfillStipulationAction } from '../../../redux/actions/stipulations/stipulations-actions';
import routes from '../../../routes';
import { getFlatApplicationValues, mapEntityToField, mapFieldToRequestBody } from '../util';
import pages from './pages';
import './forgiveness-application.less';

const ForgivenessApplication = ({
  activeForgivenessId,
  advancesById,
  fulfillStipulation,
  getLoanForgiveness,
  lastPageSubmitted,
  loanForgiveness,
  page1Values,
  page2Values,
  page3Values,
  page4Values,
  page5Values,
  page6Values,
  stipulations,
  updateLastPageSubmitted,
  updateLoanForgiveness,
}) => {
  const [applicationPage, setApplicationPage] = useState(0);
  const [lastPageSubmitAttempted, setLastPageSubmitAttempted] = useState(0);
  const [submissionError, setSubmissionError] = useState(undefined);
  const redirect = useRedirect();
  const page = pages[applicationPage];
  const pageNumber = applicationPage + 1;
  const pageTotal = pages.length;

  const forgiveness = loanForgiveness.byId[activeForgivenessId];
  if (!forgiveness) {
    return <></>;
  }

  const prefillData = {};

  const advance = advancesById[forgiveness.advanceId];
  forgiveness.dateFirstWireIssued = advance?.dateFirstWireIssued;
  mapEntityToField(forgiveness, pageNumber, prefillData);

  const clearSubmissionError = () => {
    setSubmissionError(false);
  };

  const onSubmitClick = () => {
    if (pageNumber > lastPageSubmitAttempted) {
      setLastPageSubmitAttempted(pageNumber);
    }
  };

  const handleSubmit = async () => {
    clearSubmissionError();

    const values = getFlatApplicationValues(
      [page1Values, page2Values, page3Values, page4Values, page5Values, page6Values],
    );

    const updatedValues = {
      pageNumber: lastPageSubmitted,
      id: forgiveness.id,
    };

    mapFieldToRequestBody(values, updatedValues);

    if (pageNumber >= pages.length) {
      updatedValues.submittedTimestamp = moment().format();
    }

    try {
      const res = await updateLoanForgiveness(updatedValues);
      if (res.error) {
        throw new Error('Submission failed');
      }
      await getLoanForgiveness([forgiveness.advanceId]);
    }
    catch (error) {
      setSubmissionError(true);
      return;
    }

    const newPage = applicationPage + 1;
    if (newPage >= pages.length) {
      try {
        const { id } = stipulations.find(({ stipulationMap: { name } }) => name === 'SBA Form 3508');
        const res = await fulfillStipulation(advance.opportunityId, id);
        if (res.error) {
          throw new Error('Fulfill stipulation failed');
        }
      }
      catch (error) {
        setSubmissionError(true);
        return;
      }
      redirect(routes['forgiveness-summary'].path);
      return;
    }
    setApplicationPage(newPage);
    if (newPage > lastPageSubmitted) {
      updateLastPageSubmitted(newPage);
    }
  };

  const returnToStart = () => {
    setLastPageSubmitAttempted(0);
    setApplicationPage(0);
  };

  return (
    <div className="forgiveness-application">
      <ProgressMeter max="100" value={page?.percent} title={page?.description} steps={`Step ${pageNumber} of ${pageTotal}`} />
      <page.component
        onSubmit={handleSubmit}
        submitted={lastPageSubmitAttempted >= pageNumber}
        initialValues={prefillData[pageNumber]}
        returnToStart={returnToStart}
      >
        <Row>
          {submissionError && (
            <Col form="full">
              <Alert type="error" className="submission-error-alert">
                {errors.technicalIssue}
              </Alert>
            </Col>
          )}
          <Col form="full" className="action-buttons">
            <Button type="submit" onClick={onSubmitClick}>
              {pageNumber < pages.length && (<>Save and Continue</>)}
              {pageNumber >= pages.length && (<>Confirm and Continue</>)}
            </Button>
          </Col>
        </Row>
      </page.component>
    </div>
  );
};

ForgivenessApplication.defaultProps = {
  lastPageSubmitted: 0,
  page1Values: {},
  page2Values: {},
  page3Values: {},
  page4Values: {},
  page5Values: {},
  page6Values: {},
  stipulations: [],
};

ForgivenessApplication.propTypes = {
  activeForgivenessId: PropTypes.string.isRequired,
  advancesById: PropTypes.PropTypes.objectOf(PropTypes.object).isRequired,
  fulfillStipulation: PropTypes.func.isRequired,
  getLoanForgiveness: PropTypes.func.isRequired,
  lastPageSubmitted: PropTypes.number,
  loanForgiveness: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  page1Values: PropTypes.shape({
    standardCoveredPeriodEndDate: PropTypes.string,
  }),
  page2Values: PropTypes.shape({}),
  page3Values: PropTypes.shape({}),
  page4Values: PropTypes.shape({}),
  page5Values: PropTypes.shape({}),
  page6Values: PropTypes.shape({}),
  stipulations: PropTypes.arrayOf(PropTypes.object),
  updateLastPageSubmitted: PropTypes.func.isRequired,
  updateLoanForgiveness: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeForgivenessId: state.activeIds.forgivenessId,
  advancesById: state.entities.advances.byId,
  lastPageSubmitted: state.forgivenessForm?.lastPageSubmitted,
  loanForgiveness: state.entities.loanForgiveness,
  page1Values: state.form?.forgivenessPage1?.values,
  page2Values: state.form?.forgivenessPage2?.values,
  page3Values: state.form?.forgivenessPage3?.values,
  page4Values: state.form?.forgivenessPage4?.values,
  page5Values: state.form?.forgivenessPage5?.values,
  page6Values: state.form?.forgivenessPage6?.values,
  stipulations: state.stipulations.allStipulations,
});

const mapDispatchToProps = {
  fulfillStipulation: fulfillStipulationAction,
  getLoanForgiveness: getLoanForgivenessAction,
  updateLastPageSubmitted: updateLastPageSubmittedAction,
  updateLoanForgiveness: updateLoanForgivenessAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgivenessApplication);
