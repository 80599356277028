export { useShallowSelector } from '../../../../../hooks';

export const coveredPeriodEndDateSelector = ({ activeIds, ...state }) => {
  const advanceId = state.entities.loanForgiveness.byId[activeIds.forgivenessId]?.advanceId;

  return {
    dateFirstWireIssued: state.entities.advances.byId[advanceId]?.dateFirstWireIssued,
    error: state.form?.forgivenessPage1?.syncErrors?.standardCoveredPeriodEndDate,
    touched: state.form?.forgivenessPage1?.fields?.standardCoveredPeriodEndDate?.touched,
    value: state.form?.forgivenessPage1?.values?.standardCoveredPeriodEndDate,
  };
};
