import React from 'react';
import { BodyText, HeadingText } from '@swift-paypal/pp-react';

export const InReviewDetails = () => (
  <>
    <HeadingText size="sm">
      We&apos;re reviewing your loan forgiveness application
    </HeadingText>
    <BodyText as="p">
      We&apos;re reviewing your application and documents to confirm your loan forgiveness amount. Your Loan
      Forgiveness Specialist will contact you if we need more information. Our review may take up to 60 days
      from when you submitted your complete application.
    </BodyText>
  </>
);
