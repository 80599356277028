import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BetterStandardLayout from '../layouts/better-standard-layout/better-standard-layout';
import SecurePage from '../hocs/secure-page';
import { useActiveForgivenessSelected, useRedirect, useShallowSelector } from '../hooks';
import ForgivenessApplication from '../components/forgiveness/application/forgiveness-application';
import { ForgivenessApplicationSidebar } from '../components/forgiveness/forgiveness-application-sidebar';
import CaseWorkerTile from '../components/common/case-worker-tile/case-worker-tile';
import { getAdvancesAction } from '../redux/actions/advances/advances-actions';
import { setActiveForgivenessId } from '../redux/actions/active-ids/active-ids-actions';
import { getLoanForgivenessAction } from '../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { getOpportunitiesAction } from '../redux/actions/opportunities/opportunities-actions';
import getStipulations from '../redux/actions/stipulations/stipulations-actions';
import displayNames from '../constants/displayNames';
import routes from '../routes';

const ForgivenessApplicationPage = () => {
  const dispatch = useDispatch();
  const { country } = useParams();
  const redirect = useRedirect();
  const {
    advance,
    activeForgivenessId,
    forgivenessAdvanceId,
    loanForgiveness,
  } = useShallowSelector(pageSelector);

  useActiveForgivenessSelected();

  useEffect(() => {
    dispatch(getAdvancesAction());
    dispatch(getOpportunitiesAction());
  }, []);

  useEffect(() => {
    if (advance) {
      const { opportunityId } = advance;
      dispatch(getStipulations(opportunityId, country));
    }
  }, [advance]);

  useEffect(() => {
    if (forgivenessAdvanceId) {
      dispatch(getLoanForgivenessAction([forgivenessAdvanceId]));
    }
  }, [forgivenessAdvanceId]);

  useEffect(() => {
    if (loanForgiveness?.submittedTimestamp) {
      redirect(routes['forgiveness-summary'].path);
    }

    const forgivenessId = loanForgiveness?.id;

    if (forgivenessId && forgivenessId !== activeForgivenessId) {
      dispatch(setActiveForgivenessId(forgivenessId));
    }
  }, [loanForgiveness]);

  const caseWorker = loanForgiveness?.caseWorker;

  return (
    <div className="summary-container">
      <Helmet>
        <title>Summary</title>
      </Helmet>
      <BetterStandardLayout
        bodyElement={<ForgivenessApplication />}
        sidebarElement={<ForgivenessApplicationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

ForgivenessApplicationPage.displayName = displayNames.ForgivenessApplicationPage;

const pageSelector = ({ activeIds, entities: { advances, loanForgiveness } }) => ({
  advance: advances.byId[activeIds.advanceId],
  activeForgivenessId: activeIds.forgivenessId,
  forgivenessAdvanceId: activeIds.advanceId,
  loanForgiveness: loanForgiveness.byId[loanForgiveness.allIds.find(
    (loanForgivnessId) => loanForgiveness.byId[loanForgivnessId].advanceId === activeIds.advanceId,
  )],
});

export default SecurePage(ForgivenessApplicationPage);
