import {
  EZ_APP,
  STANDARD_APP,
  S_APP,
  FORGIVENESS_APPLICATION,
  FORGIVENESS_SUMMARY,
  USA,
} from './forgiveness-summary-tile-constants';
import routes from '../../../routes';

/**
 * determines what path that user needs to navigate to
 * @param {*} param0 destructured object for dependendencies
 */
export const evaluateAndResolveApplicationPath = ({
  applicationVersionSelected,
  ezQuestionnaireCompleted,
  redirectCallback,
}) => {
  const countryPayload = { country: USA };
  if (!ezQuestionnaireCompleted && !applicationVersionSelected) {
    redirectCallback(routes.questionnaire.path, countryPayload);
  }
  else {
    switch (applicationVersionSelected) {
      case STANDARD_APP: {
        redirectCallback(routes[FORGIVENESS_APPLICATION].path, countryPayload);
        break;
      }
      case S_APP:
      case EZ_APP:
      default: {
        redirectCallback(routes[FORGIVENESS_SUMMARY].path, countryPayload);
        break;
      }
    }
  }
};
