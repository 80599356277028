import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import SecurePage from '../hocs/secure-page';
import displayNames from '../constants/displayNames';
import BetterStandardLayout from '../layouts/better-standard-layout/better-standard-layout';
import CaseWorkerTile from '../components/common/case-worker-tile/case-worker-tile';
import { ForgivenessCustomerSignoff } from '../components/forgiveness/customer-signoff';
import { ForgivenessApplicationSidebar } from '../components/forgiveness/forgiveness-application-sidebar';
import { useActiveForgivenessSelected } from '../hooks';

const ForgivenessCustomerSignoffPage = ({
  activeForgivenessId, loanForgivenessById,
}) => {
  useActiveForgivenessSelected();

  if (!activeForgivenessId) {
    return null;
  }

  const caseWorker = loanForgivenessById[activeForgivenessId]?.caseWorker;

  return (
    <div className="summary-container">
      <Helmet>
        <title>Summary</title>
      </Helmet>
      <BetterStandardLayout
        bodyElement={<ForgivenessCustomerSignoff />}
        sidebarElement={<ForgivenessApplicationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

ForgivenessCustomerSignoffPage.displayName = displayNames.ForgivenessCustomerSignoffPage;

ForgivenessCustomerSignoffPage.defaultProps = {
  activeForgivenessId: undefined,
};

ForgivenessCustomerSignoffPage.propTypes = {
  activeForgivenessId: PropTypes.string,
  loanForgivenessById: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  activeForgivenessId: state.activeIds.forgivenessId,
  loanForgivenessById: state.entities.loanForgiveness.byId,
});

export default connect(mapStateToProps)(SecurePage(ForgivenessCustomerSignoffPage));
