import {
  OPPORTUNITIES_REQUEST_RECEIVED,
} from '../actions/opportunities/opportunities-action-types';

const defaultState = {
  byId: {},
  allIds: [],
};

const opportunities = (state = defaultState, action) => {
  const results = action.payload;
  switch (action.type) {
    case OPPORTUNITIES_REQUEST_RECEIVED:
      return {
        allIds: results.map((opp) => opp.id),
        byId: results.reduce((result, opp) => {
          result[opp.id] = opp;
          return result;
        }, {}),
      };
    default:
      return state;
  }
};

export default opportunities;
