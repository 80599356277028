import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col,
  Divider,
  Link,
  Row,
  BodyText,
  HeadingText,
  Container,
} from '@swift-paypal/pp-react';
import PhoneNumber from '../phone-number';
import withUrlParams from '../../../hocs/with-url-params';
import BfeTile from '../bfe-tile/bfe-tile';
import constants from '../../../constants';
import './have-questions-tile.less';

const HaveQuestionsTile = ({
  bfes,
  country,
  customerSupportPhone,
  customerSupportEmail,
  canadaCustomerSupportPhone,
  canadaCustomerSupportEmail,
  haveAnyPppOpps,
}) => {
  let supportEmail;
  let supportPhone;
  let serviceHours;

  if (country === 'ca') {
    supportEmail = canadaCustomerSupportEmail;
    supportPhone = canadaCustomerSupportPhone;
    serviceHours = constants.customerServiceHoursCA;
  }
  else if (haveAnyPppOpps) {
    supportEmail = '';
    supportPhone = constants.pppServicePhone;
    serviceHours = constants.pppServiceHours;
  }
  else {
    supportEmail = customerSupportEmail;
    supportPhone = customerSupportPhone;
    serviceHours = constants.customerServiceHours;
  }

  return (
    <Container fluid className="have-questions-container">
      <Row align="center">
        <Col>
          <HeadingText className="have-questions-title">
            Have questions?
          </HeadingText>
        </Col>
      </Row>
      {bfes && (<BfeTile bfes={bfes} country={country} />)}
      <Row align="center">
        <Col>
          {supportPhone && (
            <>
              <BodyText className="call-us-text">
                Call us
              </BodyText>
              <a href={`tel:+${supportPhone}`}>
                <PhoneNumber
                  number={supportPhone}
                />
              </a>
            </>
          )}
          {serviceHours && (
            <BodyText className="call-us-hours">
              {serviceHours}
            </BodyText>
          )}
        </Col>
      </Row>
      {supportEmail && (
        <Row align="center" className="email-us">
          <Col>
            <Divider secondary />
            <BodyText className="email-us__text">Email us</BodyText>
            <Link
              className="email-us__email-link"
              href={`mailto:${supportEmail}`}
            >
              {supportEmail}
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  );
};

HaveQuestionsTile.propTypes = {
  bfes: PropTypes.arrayOf(PropTypes.object),
  canadaCustomerSupportPhone: PropTypes.string,
  canadaCustomerSupportEmail: PropTypes.string,
  country: PropTypes.string,
  customerSupportPhone: PropTypes.string,
  customerSupportEmail: PropTypes.string,
  haveAnyPppOpps: PropTypes.bool,
};

HaveQuestionsTile.defaultProps = {
  bfes: undefined,
  canadaCustomerSupportPhone: undefined,
  canadaCustomerSupportEmail: undefined,
  country: undefined,
  customerSupportPhone: undefined,
  customerSupportEmail: undefined,
  haveAnyPppOpps: false,
};

const mapStateToProps = (state) => ({
  customerSupportPhone: state.config.customerSupportPhone,
  customerSupportEmail: state.config.customerSupportEmail,
  canadaCustomerSupportPhone: state.config.canadaCustomerSupportPhone,
  canadaCustomerSupportEmail: state.config.canadaCustomerSupportEmail,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(HaveQuestionsTile);
