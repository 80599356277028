export default {
  c3nonProfit: '501(c)(3) nonprofit',
  c6Organization: '501(c)(6) organization',
  cCorporation: 'C-Corp',
  corporation: 'Corporation',
  housingCooperative: 'Housing cooperative',
  limitedLiabilityCompany: 'Limited Liability Company',
  partnership: 'Partnership',
  soleProprietorship: 'Sole Proprietorship',
  nonProfit: 'Non-profit Organization',
  independentContractor: 'Independent contractor',
  eligibleSelfEmployedIndividual: 'Eligible self-employed individual',
  other: 'Other',
  veteransOrganization: '501(c)(19) veterans organization',
  sCorporation: 'S-Corp',
  tribalBusiness: 'Tribal business (sec. 31(b)(2)(C) of Small Business Act)',
  trust: 'Trust',
};
