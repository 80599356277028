import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Title from '../common/title';
import ApplicationActionItems from './application-action-items';
import AccountActionItems from './account-action-items';
import UpdateProfileItems from './update-profile-items';

const ActionItemsList = (props) => {
  const {
    stipulations,
    isRenewalEligible,
    homePhone,
    businessWebAddress,
  } = props;

  const actualStipulations = get({ stipulations }, 'stipulations[0].stipulations[0].stipulations');
  const renewalStipulations = !actualStipulations || isRenewalEligible
    ? actualStipulations : actualStipulations.filter((stip) => !stip.isRenewal);
  if (!homePhone
    || !businessWebAddress
    || (isRenewalEligible && renewalStipulations && renewalStipulations.length > 0)
  ) {
    return (
      <>
        {(!homePhone || !businessWebAddress)
        && <UpdateProfileItems />}
        {isRenewalEligible && renewalStipulations && renewalStipulations.length > 0
        && stipulations.map((stipulation) => (
          <div key={stipulation.accountId}>
            <Title text={`Action items for ${stipulation.accountName}`} />
            <ApplicationActionItems
              key={`ApplicationActionItems ${stipulation.applications} for ${stipulation.accountId}`}
              applications={stipulation.applications}
              fulfilledStips={props.fulfilledStips}
              clearStipFunc={props.clearStipFunc}
            />
            <AccountActionItems
              key={`AccountActionItems ${stipulation.stipulations} for ${stipulation.accountId}`}
              stipulations={stipulation.stipulations}
              fulfilledStips={props.fulfilledStips}
              renewFunc={props.renewFunc}
              clearStipFunc={props.clearStipFunc}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <div data-no-stipulations className="vx_text-4">There are no notifications.</div>
    </>
  );
};

ActionItemsList.propTypes = {
  clearStipFunc: PropTypes.func,
  fulfilledStips: PropTypes.arrayOf(PropTypes.object),
  isRenewalEligible: PropTypes.bool,
  renewFunc: PropTypes.func,
  stipulations: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessWebAddress: PropTypes.string,
  homePhone: PropTypes.string,
};

ActionItemsList.defaultProps = {
  clearStipFunc: undefined,
  fulfilledStips: undefined,
  isRenewalEligible: false,
  renewFunc: undefined,
  businessWebAddress: undefined,
  homePhone: undefined,
};

const mapStateToProps = (state) => ({
  isRenewalEligible: state.isRenewalEligible,
});

export default connect(mapStateToProps)(ActionItemsList);
