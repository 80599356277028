import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { BodyText, TextInput } from '@swift-paypal/pp-react';
import { createNumberMask } from 'redux-form-input-masks';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

const TotalSalaryReductionsField = ({
  error,
  submitted,
  touched,
}) => (
  <>
    <BodyText as="label" htmlFor="totalSalaryReductions">
      Total salary/hourly wage reductions from Table 1 &ndash; Box 3
    </BodyText>
    <Field
      key="total-salary-reductions"
      name="totalSalaryReductions"
      type="tel"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      validate={[
        required({ msg: 'Enter value from Schedule A Worksheet, Table 1 - Box 3' }),
        numericality(),
      ]}
      {...currencyMask}
    />
  </>
);

TotalSalaryReductionsField.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
};

TotalSalaryReductionsField.propTypes = {
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
};

export default memo(TotalSalaryReductionsField);
