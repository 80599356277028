import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CaseWorkerTile from '../../common/case-worker-tile/case-worker-tile';
import { ForgivenessSummary } from '../../features';
import { SbaLoanInformationSidebar } from '../../forgiveness/sba-loan-information-sidebar';
import displayNames from '../../../constants/displayNames';
import SecurePage from '../../../hocs/secure-page';
import { useShallowSelector, useActiveForgivenessSelected } from '../../../hooks';
import BetterStandardLayout from '../../../layouts/better-standard-layout/better-standard-layout';
import { getAccountsAction } from '../../../redux/actions/account/account-actions';
import { getAdvancesAction } from '../../../redux/actions/advances/advances-actions';
import { clearActiveOpportunityId, setActiveForgivenessId, setActiveOpportunityId } from '../../../redux/actions/active-ids/active-ids-actions';
import getStipulations, { clearFulfillStipulationAction } from '../../../redux/actions/stipulations/stipulations-actions';
import { uploadDocumentsRequestClearAction } from '../../../redux/actions/upload-document/upload-document-actions';
import { getLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { getOpportunitiesAction } from '../../../redux/actions/opportunities/opportunities-actions';
import { pageSelector } from './forgivenessSummaryPage.selectors';

const ForgivenessSummaryPage = () => {
  const dispatch = useDispatch();
  const { country } = useParams();

  const {
    activeAdvanceId,
    activeForgivenessId,
    advancesById,
    loanForgiveness,
  } = useShallowSelector(pageSelector);

  useActiveForgivenessSelected();
  useEffect(() => {
    dispatch(clearFulfillStipulationAction());
    dispatch(getAccountsAction());
    dispatch(getAdvancesAction());
    dispatch(getOpportunitiesAction());
    dispatch(uploadDocumentsRequestClearAction());

    return () => {
      dispatch(clearActiveOpportunityId());
    };
  }, []);

  useEffect(() => {
    const activeAdvance = advancesById[activeAdvanceId];
    if (activeAdvance) {
      const { id, opportunityId } = activeAdvance;
      dispatch(getLoanForgivenessAction([id]));
      dispatch(getStipulations(opportunityId, country));
      dispatch(setActiveOpportunityId(opportunityId));
    }
  }, [activeAdvanceId, advancesById]);

  useEffect(() => {
    const forgivenessId = advancesById[activeAdvanceId]?.loanForgivenessId;

    if (forgivenessId && forgivenessId !== activeForgivenessId) {
      dispatch(setActiveForgivenessId(forgivenessId));
    }
  }, [loanForgiveness]);

  const activeForgivenessObject = loanForgiveness.byId[activeForgivenessId];
  const caseWorker = activeForgivenessObject?.caseWorker;

  return (
    <div className="summary-container">
      <Helmet title="Summary" />
      <BetterStandardLayout
        bodyElement={<ForgivenessSummary />}
        sidebarElement={<SbaLoanInformationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

ForgivenessSummaryPage.displayName = displayNames.ForgivenessSummaryPage;

export default SecurePage(ForgivenessSummaryPage);
