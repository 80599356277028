import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Link as PPLink } from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './footer.less';
import PhoneNumber from '../common/phone-number';
import resolve from '../../services/route.service';
import routes from '../../routes';
import withUrlParams from '../../hocs/with-url-params';

const Footer = ({ canadaCustomerSupportPhone, country }) => {
  const version = process.env.REACT_APP_VERSION || '0.0.0-local';

  if (!country) {
    return null;
  }

  return (
    <div className="portal-footer vx_globalFooter">
      <div className="vx_globalFooter-content footer-margin">
        <div className="vx_globalFooter-list">
          {country === 'us' ? (
            <div className="vx_text-legal center first-line">
              The lender for LoanBuilder Loan, PayPal Business Loan and
              SBA Paycheck Protection Program Loan brought to you by PayPal is WebBank.
            </div>
          ) : null}
          <ul className="stacked">
            <li className="vx_text-legal center">
              ©1999-
              {moment().year()}
              {' '}
              PayPal, Inc. All rights reserved.
            </li>
            <span className="footer-vertical-line" />
            <li>
              <Link to={resolve(routes['privacy-policy'].path, { country })} target="_blank">
                Privacy Policy
              </Link>
            </li>
            {country === 'us'
              ? (
                <li>
                  <Link
                    to={resolve(routes['terms-of-use'].path, { country })}
                    target="_blank"
                  >
                    Terms of Use
                  </Link>
                </li>
              ) : null}
            {country === 'us'
              ? (
                <li>
                  <PPLink
                    as="a"
                    onClick={() => {
                      if (window.manageCookiePreferences) {
                        /** manageCookiePreferences is added by ncs.js script present in root html */
                        window.manageCookiePreferences();
                      }
                    }}
                  >
                    Cookies
                  </PPLink>
                </li>
              ) : null}
            {country === 'ca' && <span className="footer-vertical-line" />}
            {country === 'ca' && (
              <li className="vx_text-legal center ca-customer-support-phone">
                <span>Call us at</span>
                <span className="padding-left-sm">
                  <PhoneNumber number={canadaCustomerSupportPhone} />
                </span>
              </li>
            )}
          </ul>
        </div>
        <div className="footer-release-version vx_text-legal">
          v
          {version}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  canadaCustomerSupportPhone: PropTypes.string,
  country: PropTypes.string,
};

Footer.defaultProps = {
  canadaCustomerSupportPhone: undefined,
  country: undefined,
};

const mapStateToProps = (state) => ({
  customerSupportPhone: state.config.customerSupportPhone,
  canadaCustomerSupportPhone: state.config.canadaCustomerSupportPhone,
});

export default compose(connect(mapStateToProps), withRouter, withUrlParams)(Footer);
