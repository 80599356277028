import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Container } from '@swift-paypal/pp-react';
import getPrivacyPolicyAction from '../redux/actions/privacy/privacy-actions';
import AnonymousPage from '../hocs/anonymous-page';
import displayNames from '../constants/displayNames';

class PrivacyPolicyPage extends Component {
  static displayName = displayNames.PrivacyPolicyPage;

  static propTypes = {
    country: PropTypes.string,
    getPrivacyPolicy: PropTypes.func.isRequired,
    policy: PropTypes.shape({
      content: PropTypes.string,
      flavor: PropTypes.string,
      version: PropTypes.string,
    }),
    privacy: PropTypes.shape({
      privacyPolicy: PropTypes.object,
    }),
  };

  static defaultProps = {
    country: undefined,
    policy: undefined,
    privacy: undefined,
  };

  async componentDidMount() {
    const { country, getPrivacyPolicy } = this.props;
    await getPrivacyPolicy(country);
  }

  static getMarkup(markup) {
    return { __html: markup };
  }

  render() {
    const { policy } = this.props;

    if (!policy) {
      return null;
    }

    return (
      <Container>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="privacy-policy-page" dangerouslySetInnerHTML={PrivacyPolicyPage.getMarkup(policy.content)} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    policy: state.privacy.privacyPolicy,
  };
}

const mapDispatchToProps = {
  getPrivacyPolicy: getPrivacyPolicyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousPage(PrivacyPolicyPage));
