import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@swift-paypal/pp-react';
import Currency from '../common/currency';
import constants from '../../constants';
import withUrlParams from '../../hocs/with-url-params';
import ConditionalLeftRight from './conditional-left-right';
import { getAdvancesAction } from '../../redux/actions/advances/advances-actions';

const BasicActivityInfo = (props) => {
  const {
    advances, getAdvances, loan, isGuarantyClaimed,
  } = props;
  const {
    amount, balance, feeBalance, issuedDate, originalInterest, otherCharges, principalBalance,
  } = loan;

  useEffect(() => {
    const fetchData = async () => {
      await getAdvances();
    };
    fetchData();
  }, []);

  const validAdvance = Object.values(advances?.byId)
    .filter((advance) => loan.id === advance.id
                         && !!advance.issuedDate
                         && !advance.rescindedDate
                         && !advance.confirmedFraudDate)
    .find((advance) => advance.type === 'SBA');

  const { sbaLoanNumber } = validAdvance || {};

  moment.locale('en');
  return (
    <div>
      <ConditionalLeftRight
        main
        className="margin-bottom-sm"
        dataName="sba-loan-number"
        condition={validAdvance}
        left="SBA Loan Number:"
        right={sbaLoanNumber}
        leftId="sbaLoanNumberLabel"
        rightId="sbaLoanNumberValueLabel"
      />
      <ConditionalLeftRight
        main
        className="margin-bottom-sm"
        dataName="date-issued"
        condition={issuedDate}
        left="Date Issued:"
        right={moment(issuedDate).format('ll')}
        leftId="dateIssuedLabel"
        rightId="dateIssuedValueLabel"
      />
      <ConditionalLeftRight
        main
        className="margin-bottom-sm"
        dataName="loan-amount"
        condition={amount}
        left={(
          <>
            Original loan amount:
            <Tooltip
              description={constants.tooltips.originalLoanAmountTip}
              dataAutomationId="originalLoanAmountTooltip"
            />
          </>
        )}
        right={<Currency amount={amount} dataAutomationId="originalLoanAmountValueLabel" />}
        leftId="originalLoanAmountLabel"
      />
      <ConditionalLeftRight
        main
        className="margin-bottom-sm"
        dataName="total-fee"
        condition={originalInterest}
        left={(
          <>
            Original fee amount:
            <Tooltip
              description={constants.tooltips.originalFeeAmountTip}
              dataAutomationId="originalFeeAmountTooltip"
            />
          </>
        )}
        right={<Currency amount={originalInterest} dataAutomationId="OriginalFeeAmountValueLabel" />}
        leftId="OriginalFeeAmountLabel"
      />
      <hr className="activity-hr" />
      {!isGuarantyClaimed && (
      <ConditionalLeftRight
        main
        className="margin-bottom-sm"
        dataName="total-balance"
        condition={balance}
        left={(
          <>
            Total balance
            <Tooltip
              description={constants.tooltips.totalBalanceTip}
              dataAutomationId="totalBalanceTooltip"
            />
          </>
        )}
        right={<Currency amount={balance} dataAutomationId="totalBalanceValueLabel" />}
        leftId="totalBalanceLabel"
      />
      )}
      {!isGuarantyClaimed && (
      <div className="subsection">
        <ConditionalLeftRight
          className="margin-bottom-sm"
          dataName="balance-principal"
          condition={principalBalance}
          left={(
            <>
              Principal balance
              <Tooltip
                description={constants.tooltips.principalBalanceTip}
                dataAutomationId="principleBalanceTooltip"
              />
            </>
          )}
          right={<Currency amount={principalBalance} dataAutomationId="principleBalanceValueLabel" />}
          leftId="principleBalanceLabel"
        />
        <ConditionalLeftRight
          className="margin-bottom-sm"
          dataName="balance-fee"
          condition={feeBalance}
          left={(
            <>
              Fee balance
              <Tooltip
                description={constants.tooltips.feeBalanceTip}
                dataAutomationId="feeBalanceTooltip"
              />
            </>
          )}
          right={<Currency amount={feeBalance} dataAutomationId="feeBalanceValueLabel" />}
          leftId="feeBalanceLabel"
        />
        <ConditionalLeftRight
          className="margin-bottom-sm"
          dataName="balance-other"
          condition={otherCharges}
          left="Other charges balance"
          right={<Currency amount={otherCharges} dataAutomationId="otherChargesBalanceLabel" />}
        />
      </div>
      )}
    </div>
  );
};

BasicActivityInfo.propTypes = {
  advances: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.object,
  }),
  loan: PropTypes.shape({
    id: PropTypes.string,
    issuedDate: PropTypes.string,
    amount: PropTypes.number,
    originalInterest: PropTypes.number,
    balance: PropTypes.number,
    principalBalance: PropTypes.number,
    feeBalance: PropTypes.number,
    otherCharges: PropTypes.number,
    air: PropTypes.string,
  }).isRequired,
  getAdvances: PropTypes.func.isRequired,
  isGuarantyClaimed: PropTypes.bool,
};

BasicActivityInfo.defaultProps = {
  advances: undefined,
  isGuarantyClaimed: false,
};

const mapStateToProps = (state) => ({
  advances: state.entities.advances,
});

const mapDispatchToProps = {
  getAdvances: getAdvancesAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlParams,
)(BasicActivityInfo);
