import React, { useContext } from 'react';
import {
  BodyText, CaptionText, SingleSelectionGroup, CurrencyInput,
} from '@paypalcorp/pp-react';
import Currency from '../../common/currency';
import '../payments.less';
import { OneTimePaymentContext } from '../OneTimePaymentContext';

const currencyMaskOptions = {
  mask: Number,
  thousandsSeparator: ',',
  radix: '.',
  scale: 2,
  padFractionalZeros: true,
};

export const paymentAmountInputValidation = (paymentAmount, isPastDuePending, Balance) => {
  let amountErrorText = '';
  if (paymentAmount.amount) {
    if (Number(paymentAmount.amount) <= 1) {
      amountErrorText = 'Enter amount more than $1.00';
    }
    if (Number(paymentAmount.amount) > Number(Balance)) {
      amountErrorText = 'Amount should not be more than total outstanding amount';
    }
  }
  else {
    amountErrorText = 'Enter payment amount';
    if (!paymentAmount.amountType && isPastDuePending) {
      amountErrorText = 'You must select one';
    }
  }
  return amountErrorText;
};

export default function PaymentAmountInput() {
  const { selectedLoanAdvance, paymentAmount, setPaymentAmount } = useContext(OneTimePaymentContext);
  const isPastDuePending = !!selectedLoanAdvance?.PastDueAmountWithFees;

  const handleSelectedAmountType = (e) => {
    const chosenAmountType = e.target.value;
    let amount = null;
    if (chosenAmountType === 'past-due-amount') {
      amount = selectedLoanAdvance.PastDueAmountWithFees;
    }
    if (chosenAmountType === 'outstanding-amount') {
      amount = selectedLoanAdvance.Balance;
    }
    setPaymentAmount({
      amountType: chosenAmountType,
      amount,
      amountError: '',
    });
  };

  const handleAcceptOtherAmount = (_value, unmaskedValue) => {
    setPaymentAmount({
      ...paymentAmount,
      amount: unmaskedValue,
    });
  };
  const createPaymentsOptions = () => {
    const options = [];
    if (isPastDuePending) {
      options.push({
        label: <CaptionText>Past Due Amount</CaptionText>,
        secondaryLabel: (
          <BodyText>
            <Currency
              amount={Number(selectedLoanAdvance.PastDueAmountWithFees)}
              dataAutomationId="achPastDueAmountLabel"
            />
          </BodyText>
        ),
        value: 'past-due-amount',
      });
    }
    const opts = [{
      label: <CaptionText>Outstanding Loan Balance</CaptionText>,
      secondaryLabel: (
        <BodyText>
          <Currency
            amount={Number(selectedLoanAdvance.Balance)}
            dataAutomationId="achOutstandingAmountLabel"
          />
        </BodyText>
      ),
      value: 'outstanding-amount',
    },
    { label: <BodyText>Other Amount</BodyText>, value: 'other-amount' }];
    opts.forEach((op) => {
      options.push(op);
    });
    return options;
  };
  return (
    <>
      <CaptionText strong>Choose amount</CaptionText>
      <div className="input-container">
        <SingleSelectionGroup
          label="choose-amount-type"
          hideLabel
          errorText={!paymentAmount.amountType && paymentAmount.amountError}
          align="vertical"
          value={paymentAmount.amountType}
          alignContent="left"
          onChange={handleSelectedAmountType}
          className="single-select-label"
          options={createPaymentsOptions()}
        />
        <br />
        {(paymentAmount.amountType === 'other-amount') && (
          <CurrencyInput
            label="Other Amount"
            currencyType="USD"
            value={paymentAmount.amount}
            onAccept={handleAcceptOtherAmount}
            prefix="$"
            maskOptions={currencyMaskOptions}
            errorText={paymentAmount.amountError}
          />
        )}
      </div>
    </>
  );
}
