import { formName } from './sba3508SFormFields/sba3508SFormFields.constants';

export const dateFirstWireIssuedSelector = ({ activeIds: { forgivenessId }, ...state }) => {
  const advanceId = state.entities.loanForgiveness.byId[forgivenessId]?.advanceId;
  return state.entities.advances.byId[advanceId]?.dateFirstWireIssued;
};

export const forgivenessAmountSelector = ({ activeIds: { forgivenessId }, ...state }) => (
  state.entities.loanForgiveness.byId[forgivenessId]?.loanInformation?.pppLoanAmount
);

export const opportunitySelector = (opportunityId) => (state) => (
  state.entities.opportunities.byId[opportunityId]
);

export const fieldSelector = (fieldName) => (state) => ({
  error: state.form?.[formName]?.syncErrors?.[fieldName],
  registered: state.form?.[formName]?.registeredFields?.[fieldName],
  touched: state.form?.[formName]?.fields?.[fieldName]?.touched,
  value: state.form?.[formName]?.values?.[fieldName],
});

export const formSelector = (state) => ({
  activeForgivenessId: state.activeIds.forgivenessId,
  fulfillStipulation: state.fulfillStipulation,
  registeredFields: state.form?.[formName]?.registeredFields,
  updateLoanForgiveness: state.updateLoanForgiveness,
  syncErrors: state.form?.[formName]?.syncErrors,
  values: state.form?.[formName]?.values,
});

export const fulfilledFirstTimeSelector = (stipulationId) => (state) => (
  state.stipulations?.allStipulations?.find(({ id }) => id === stipulationId)?.fulfilledFirstTimestamp
);

export const lnbUrlSelector = (state) => state.config.loanbuilderUrl;

export const pageSelector = (state) => ({
  activeForgivenessId: state.activeIds.forgivenessId,
  advanceId: state.activeIds.advanceId,
  loanForgiveness: state.entities.loanForgiveness,
});
