export const payrollCostsFormSelector = (state) => ({
  loanbuilderUrl: state.config.loanbuilderUrl,
  page1Values: state.form?.forgivenessPage1?.values,
  selectedAverageFteTable1: state.form?.forgivenessPage2?.values?.averageFteTable1,
  selectedAverageFteTable2: state.form?.forgivenessPage2?.values?.averageFteTable2,
  selectedMaintainedHourlyWage: state.form?.forgivenessPage2?.values?.maintainedHourlyWage,
  selectedTotalSalaryReductions: state.form?.forgivenessPage2?.values?.totalSalaryReductions,
  selectedTotalCashCompensationTable1: state.form?.forgivenessPage2?.values?.totalCashCompensationTable1,
  selectedTotalCashCompensationTable2: state.form?.forgivenessPage2?.values?.totalCashCompensationTable2,
  syncErrors: state.form?.forgivenessPage2?.syncErrors || {},
});
