import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  Container, Row, Col, HeadingText, BodyText, Link,
} from '@swift-paypal/pp-react';
import CoveredPeriodEndDate from '../../fields/covered-period-end-date/covered-period-end-date';
import './covered-period-end-date-form.less';

const CoveredPeriodEndDateForm = ({ children, handleSubmit, submitted }) => {
  const lnbUrl = useSelector((state) => state.config.loanbuilderUrl);
  return (
    <Container
      form
      as="form"
      onSubmit={handleSubmit}
      noValidate
      className="covered-period-end-date-form"
    >
      <Row>
        <Col form="full">
          <HeadingText size="sm" as="h2">Choose your covered period</HeadingText>
        </Col>
      </Row>
      <Row>
        <Col>
          <BodyText strong as="h3">Choose a covered period length</BodyText>
          <BodyText as="p">
            You can now choose your desired forgiveness covered period during which you must use your
            loan proceeds for forgiveness eligible purposes. This is a period you can choose that is
            between 8 and 24 weeks from your loan disbursement date shown below. We recommend choosing the
            period length that best allows you to fully use your loan funds for
            {' '}
            <Link
              className="pp-react__link--inline"
              data-automation-id="sbaInstructionsLink"
              href={`${lnbUrl}/sba_form_3508.pdf#page=7`}
              target="_blank"
              rel="noopener noreferrer"
            >
              loan forgiveness eligible purposes
            </Link>
            {' '}
            and to maximize your loan forgiveness amount.
          </BodyText>
        </Col>
      </Row>
      <CoveredPeriodEndDate submitted={submitted} />
      {children}
    </Container>
  );
};

CoveredPeriodEndDateForm.defaultProps = {
  submitted: false,
};

CoveredPeriodEndDateForm.propTypes = {
  children: PropTypes.element.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
};

export default reduxForm({
  form: 'forgivenessPage1',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CoveredPeriodEndDateForm);
