import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';

export const history = createBrowserHistory();

const middleware = applyMiddleware(routerMiddleware(history), thunkMiddleware);
const composeEnhancers = composeWithDevTools({ trace: true });

export const store = createStore(
  reducer(history),
  process.env.NODE_ENV !== 'production' ? composeEnhancers(middleware) : middleware,
);
