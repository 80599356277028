import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row,
} from '@swift-paypal/pp-react';
import floatingLabelField from '../lib/forms/floating-label-field';
import Title from './common/title';
import { Alert, AlertTypes } from './common/alert';

export const AuthorizeForm = (props) => {
  const { handleSubmit, securityQuestion, authError } = props;
  return (
    <div className="form">
      <Container fluid>
        <form onSubmit={handleSubmit}>
          <Title text={securityQuestion} />
          <Row>
            <Col xs={12} sm={10}>
              {authError && <Alert message={authError} alertType={AlertTypes.critical} />}
              <Field
                name="answer"
                manualSet
                className="form-control vx_form-control do-not-record"
                component={floatingLabelField}
                type="password"
                label="Answer"
                noErrorClass="margin-bottom-sm"
                validate={[required({ msg: 'Required' })]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={8} sm={5} md={4}>
              <Button type="submit" className="pp-react__button--block">
                Continue
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

AuthorizeForm.propTypes = {
  authError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  securityQuestion: PropTypes.string.isRequired,
};

AuthorizeForm.defaultProps = {
  authError: undefined,
};

const AuthorizeFormComponent = reduxForm({
  form: 'authorize',
})(AuthorizeForm);

export default AuthorizeFormComponent;
