/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme as useEmotionTheme } from '@emotion/react';
import {
  Card,
  CaptionText,
  ActivityIcon,
  Col,
  Row,
  Container,
  BodyText,
  Link,
  Badge,
  Divider,
} from '@paypalcorp/pp-react';
import '../oneTimePaymentPage.less';
import moment from 'moment';
import { ResponsiveHeadingText } from '../../../one-time-payments/components/responsiveTypography';
import {
  statusIcons as icons,
  transactionStatusConfig as statusConfig,
} from '../oneTimePayment.config';
import { dateSort } from '../oneTimePayment.utils';
import PaypalBankIcon from '../../../one-time-payments/components/paypalBankIcon/paypalBankIcon';
import util from '../../../../util';
import constants from '../../../../constants';
import ScheduledTransactionStatus from './scheduledTransactionStatus';

const ScheduledTransactionSection = ({
  selectedLoanAdvance, queuedTransactions = [], editBankModalOpener, setScheduleTransactionEditBankData,
}) => {
  const { sysColorBackgroundMediumContrast } = useEmotionTheme();
  const transactionStatusFlag = true;
  const sortedTransactions = queuedTransactions.length
    ? queuedTransactions.sort(dateSort)
    : [];
  return (
    <div className="scheduled-payment-wrapper">
      <div className="scheduled-payment-header">
        <ResponsiveHeadingText
          mobile="title"
          desktop="sm"
          className="responsive-ach-offset"
        >
          Scheduled One-Time Payment
        </ResponsiveHeadingText>
        <Link href={`/activity/${selectedLoanAdvance.Id}`} size="sm">
          View All Activity
        </Link>
      </div>
      <Divider />
      <div className={`transactions-table ${!queuedTransactions.length && 'empty-table'}`}>
        {sortedTransactions.length ? (
          <>
            {sortedTransactions.map((transaction) => (
              <Card key={transaction.Id} className="transaction-card-wrapper">
                <Container>
                  <Row>
                    <Col xs={12} md={3} className="transaction-col-0">
                      <BodyText className="transaction-type">One-Time Payment</BodyText>
                      {!!transaction?.Status && statusConfig[transaction?.Status] ? (
                        <Badge
                          type={statusConfig[transaction.Status].type}
                          leftIcon={icons[statusConfig[transaction.Status].icon]}
                          leftIconLabel={statusConfig[transaction.Status].primaryText}
                        >
                          {statusConfig[transaction.Status].primaryText}
                        </Badge>
                      ) : null}
                    </Col>
                    <Col className="transaction-date-wrapper" xs={12} md={4}>
                      <BodyText className="transaction-date">
                        {transaction?.EffectiveDate
                          ? moment(transaction.EffectiveDate).format('LL')
                          : 'NA'}
                      </BodyText>
                      <CaptionText style={{ color: sysColorBackgroundMediumContrast }}>
                        Scheduled Payment Date
                      </CaptionText>
                    </Col>
                    <Col xs={6} md={2}>
                      <div className="transaction-account-info">
                        <PaypalBankIcon bank={transaction?.FinancialInstitution} />
                        <BodyText>
                          {transaction?.WireToAccount
                            ? `••${transaction.WireToAccount?.slice(-4)}`
                            : 'NA'}
                        </BodyText>
                      </div>
                    </Col>
                    <Col xs={6} md={3} className="transaction-col-3">
                      <BodyText>
                        {transaction?.TransactionAmount
                          ? `${util.generateCurrencyText(transaction.TransactionAmount)}`
                          : 'NA'}
                      </BodyText>
                    </Col>
                  </Row>
                  {
                    transactionStatusFlag && transaction?.transactionSource && (
                      <Row className="transaction-status-container">
                        <Col>
                          <ScheduledTransactionStatus transaction={transaction} editBankModalOpener={editBankModalOpener} setScheduleTransactionEditBankData={setScheduleTransactionEditBankData} loanAdvance={selectedLoanAdvance} />
                        </Col>
                      </Row>
                    )
                  }
                </Container>
              </Card>
            ))}
            <BodyText
              className="cancel-payment-text responsive-ach-offset"
              style={{ color: sysColorBackgroundMediumContrast }}
            >
              To cancel scheduled one-time payment, please call us at
              {` ${constants.oneTimePayments.customerCarePhoneNumber}`}
              .
            </BodyText>
          </>
        ) : (
          <>
            <ActivityIcon color="sysColorLinkMain" />
            <BodyText
              className="responsive-ach-offset"
              style={{ color: sysColorBackgroundMediumContrast }}
            >
              You do not have any scheduled one-time payment at this time.
            </BodyText>
          </>
        )}
      </div>
    </div>
  );
};

ScheduledTransactionSection.propTypes = {
  selectedLoanAdvance: PropTypes.object.isRequired,
  queuedTransactions: PropTypes.array.isRequired,
  editBankModalOpener: PropTypes.func.isRequired,
  setScheduleTransactionEditBankData: PropTypes.func,
};
ScheduledTransactionSection.defaultProps = {
  setScheduleTransactionEditBankData: () => {},
};

export default ScheduledTransactionSection;
