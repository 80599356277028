import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';
import {
  HeadingText, Alert, Button, Container, Link, CaptionText,
} from '@swift-paypal/pp-react';
import floatingLabelField from '../lib/forms/floating-label-field';
import lbAppLogo from '../static/images/logos/lbapps.svg';
import './forgot-password-revamp.less';

export const AuthorizeFormRevamp = ({
  handleSubmit,
  securityQuestion,
  userQuestions,
  userQuestionIds,
  authError,
  handleAltQuestion,
  country,
  customerSupportPhone,
  customerSupportEmail,
  canadaCustomerSupportPhone,
  canadaCustomerSupportEmail,
}) => {
  const [showAltQuestion, setShowAltQuestion] = useState(false);

  const [question, altQuestion] = (userQuestions) || [];
  const [id, altId] = (userQuestionIds) || [];

  const handleClick = () => {
    setShowAltQuestion(!showAltQuestion);
    handleAltQuestion((showAltQuestion) ? id : altId);
  };

  return (
    <Container as="form" className="form security-question align-center">
      <img src={lbAppLogo} alt="" className="lb-logo" />
      <HeadingText as="h2">Security Question</HeadingText>
      {showAltQuestion
        ? <HeadingText size="sm" className="question">{altQuestion}</HeadingText>
        : (
          <HeadingText size="sm" className="question">
            {question || securityQuestion}
          </HeadingText>
        ) }
      {altQuestion && <Button type="button" onClick={handleClick} className="vx_anchor diff-question"> Answer a different question </Button>}
      <form onSubmit={handleSubmit} className="fp-input">
        {authError && <Alert type="error">{authError}</Alert>}
        <Field
          name="answer"
          manualSet
          className="form-control vx_form-control do-not-record"
          component={floatingLabelField}
          type="password"
          label="Answer"
          noErrorClass="margin-bottom-sm"
          validate={[required({ msg: 'Required' })]}
        />
        <Button type="submit" className="next-button">Log In</Button>
        {showAltQuestion
          && (
            <Container className="need-help">
              <CaptionText className="divider text"><span>Need help?</span></CaptionText>
              <CaptionText className="text">
                <strong>
                  Call us at&nbsp;
                  {country === 'ca'
                    ? canadaCustomerSupportPhone
                    : customerSupportPhone}
                </strong>
                <br />

                9 AM - 5 PM ET, Monday - Friday
                <br />
                <strong>Email us at &nbsp;</strong>
                {country === 'ca'
                  ? <Link href={`mailto:${canadaCustomerSupportEmail}`}>{canadaCustomerSupportEmail}</Link>
                  : <Link href={`mailto:${customerSupportEmail}`}>{customerSupportEmail}</Link> }
                <br />
              </CaptionText>
            </Container>
          )}
      </form>
    </Container>
  );
};

AuthorizeFormRevamp.propTypes = {
  authError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleAltQuestion: PropTypes.func.isRequired,
  userQuestions: PropTypes.arrayOf(PropTypes.string),
  userQuestionIds: PropTypes.arrayOf(PropTypes.number),
  customerSupportPhone: PropTypes.string.isRequired,
  customerSupportEmail: PropTypes.string.isRequired,
  canadaCustomerSupportPhone: PropTypes.string.isRequired,
  canadaCustomerSupportEmail: PropTypes.string.isRequired,
  country: PropTypes.string,
  securityQuestion: PropTypes.string,
};

AuthorizeFormRevamp.defaultProps = {
  authError: undefined,
  userQuestionIds: undefined,
  userQuestions: undefined,
  country: undefined,
  securityQuestion: undefined,
};

function mapStateToProps(state) {
  return {
    customerSupportPhone: state.config.customerSupportPhone,
    customerSupportEmail: state.config.customerSupportEmail,
    canadaCustomerSupportPhone: state.config.canadaCustomerSupportPhone,
    canadaCustomerSupportEmail: state.config.canadaCustomerSupportEmail,
  };
}
const AuthorizeFormComponentRevamp = connect(
  mapStateToProps,
)(reduxForm({
  form: 'authorize',
})(AuthorizeFormRevamp));

export default AuthorizeFormComponentRevamp;
