import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Text,
  Link,
  Container,
} from '@swift-paypal/pp-react';
import PhoneNumber from '../phone-number';
import './case-worker-tile.less';
import constants from '../../../constants';

const CaseWorkerTile = (props) => {
  const {
    caseWorker,
  } = props;

  const customerServiceHours = constants.customerServiceHoursForgiveness;

  return (
    <Container className="case-worker-container">
      <Row align="center">
        <Col>
          <Text size="xl2" className="have-questions-title">Have questions?</Text>
        </Col>
      </Row>
      { caseWorker && caseWorker.customerFacingProfile ? (
        <Row align="center">
          <Col className="case-worker-content">
            {(caseWorker.firstName || caseWorker.lastName) && (
              <Row align="center">
                <Col>
                  <Text size="lg" className="case-worker-name" regular as="strong" data-automation-id="nameLabel">
                    {caseWorker.firstName}
                    {(caseWorker.firstName && caseWorker.lastName) ? ' ' : ''}
                    {caseWorker.lastName}
                  </Text>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Text as="span" size="md">Loan Forgiveness Specialist</Text>
              </Col>
            </Row>

            {caseWorker.phone && (
              <Row align="center">
                <Col>
                  <a href={`tel:+${caseWorker.phone}`}>
                    <PhoneNumber number={caseWorker.phone} ext={caseWorker.extension} dataAutomationId="phoneNumberLabel" />
                  </a>
                </Col>
              </Row>
            )}

            {caseWorker.email && (
              <Row>
                <Col>
                  <Link className="mail-link" data-automation-id="emailLink" href={`mailto:${caseWorker.email}`}>
                    {caseWorker.email}
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      ) : (
        <Row align="center">
          <Col>
            <Text size="md" className="call-us-text" regular>Call us</Text>
            <a href="tel:+1-866-740-0393">
              <PhoneNumber
                id="phoneNumberLabel"
                number="1-866-740-0393"
              />
            </a>
            <Text size="md" id="hoursLabel" className="call-us-hours">
              {customerServiceHours}
            </Text>
          </Col>
        </Row>
      )}
    </Container>
  );
};

CaseWorkerTile.propTypes = {
  caseWorker: PropTypes.shape({
    customerFacingProfile: PropTypes.bool,
    email: PropTypes.string,
    extension: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  }),
};

CaseWorkerTile.defaultProps = {
  caseWorker: undefined,
};

export default CaseWorkerTile;
