import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import log from 'loglevel';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import { Alert, AlertTypes } from '../../components/common/alert';
import uploadUtils from '../../lib/document-upload-utils';
import UploadDocumentsComponent from '../../components/common/upload-documents/upload-documents';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadDocumentsAction,
  uploadDocumentsClearFailedFilesAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import errors from '../../constants/errors';
import {
  forgivenessOptOutTextByGroup,
  mapParamToName,
  forgivenessStipPageTitle,
  forgivenessAppEnabledScheduleAWorksheet,
  forgivenessHelperTextByGroup,
} from '../../constants/forgivenessStipulationConstants';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import SidebarComponent from '../../components/common/sidebar/sidebar';
import DemographicInformationBodyText from '../../components/upload-loan-forgiveness/demographic-information-body-text';
import SBAForm3508BodyText from '../../components/upload-loan-forgiveness/sba-form-3508-body-text';
import FTEBodyText from '../../components/upload-loan-forgiveness/fte-body-text';
import MortgageInterestBodyText from '../../components/upload-loan-forgiveness/mortgage-interest-body-text';
import RentLeaseBodyText from '../../components/upload-loan-forgiveness/rent-lease-body-text';
import ScheduleAWorksheetBodyText from '../../components/upload-loan-forgiveness/schedule-a-worksheet/schedule-a-worksheet-body-text';
import PayrollSupportingBodyText from '../../components/upload-loan-forgiveness/payroll-supporting-body-text';
import UtilityBodyText from '../../components/upload-loan-forgiveness/utility-body-text';
import { allowedFileTypes, allowedSingleFileSize } from '../../constants/upload-document';
import {
  setActiveForgivenessId as setActiveForgivenessIdAction,
  setActiveStipulationId as setActiveStipulationIdAction,
} from '../../redux/actions/active-ids/active-ids-actions';
import getStipulationsAction, { fulfillStipulationAction } from '../../redux/actions/stipulations/stipulations-actions';
import { getOrCreateLoanForgivenessAction } from '../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { getAdvancesAction } from '../../redux/actions/advances/advances-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';

class UploadLoanForgivenessPage extends Component {
  static displayName = displayNames.UploadLoanForgivenessPage;

  static propTypes = {
    activeAdvanceId: PropTypes.string.isRequired,
    activeStipulationId: PropTypes.string.isRequired,
    advances: PropTypes.shape({
      allIds: PropTypes.arrayOf(PropTypes.string),
      byId: PropTypes.object,
    }),
    bfes: PropTypes.arrayOf(PropTypes.object),
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    failedFiles: PropTypes.arrayOf(PropTypes.object),
    forgivenessAppEnabled: PropTypes.bool.isRequired,
    fulfillStipulationById: PropTypes.func.isRequired,
    fulfillStipulation: PropTypes.shape({
      error: PropTypes.bool,
      pending: PropTypes.bool,
    }),
    getOrCreateLoanForgiveness: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getAdvances: PropTypes.func.isRequired,
    getStipulations: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    groupName: PropTypes.string.isRequired,
    loanForgiveness: PropTypes.shape({
      allIds: PropTypes.arrayOf(PropTypes.string),
      byId: PropTypes.object,
    }),
    loanForgivenessById: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
    })),
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    setActiveStipulationId: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    uploadDocuments: PropTypes.func.isRequired,
    uploadDocumentsClearFailedFiles: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    response: PropTypes.shape({
      params: PropTypes.object,
    }),
    userSetEZAppEligible: PropTypes.bool,
  };

  static defaultProps = {
    bfes: undefined,
    country: undefined,
    failedFiles: [],
    fulfillStipulation: {},
    match: undefined,
    opportunitiesById: {},
    opportunityId: undefined,
    stipulations: undefined,
    uploadPending: true,
    uploadError: undefined,
    response: undefined,
    advances: undefined,
    loanForgiveness: undefined,
    userSetEZAppEligible: false,
  };

  constructor() {
    super();
    this.state = {
      documentType: undefined,
      uploadedFiles: [],
      submitted: false,
    };
  }

  async componentDidMount() {
    const {
      activeAdvanceId,
      country,
      getProductsAndBfes,
      getStipulations,
      getOpportunities,
      opportunitiesById,
      loanForgiveness,
      match: { params: { opportunityId } },
      push,
      stipulations,
    } = this.props;

    const RoutePayload = {
      country,
    };
    if (!activeAdvanceId) {
      push(resolve(routes.summary.path, RoutePayload));
      return;
    }
    if (!stipulations?.length > 0) {
      await getStipulations(opportunityId, country);
    }

    await getProductsAndBfes();

    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }

    if (loanForgiveness?.allIds?.length) {
      return;
    }

    const { getAdvances, getOrCreateLoanForgiveness } = this.props;
    let isEligibleForLoanForgiveness = false;

    const results = await getAdvances();
    const sbaLatestAdvance = results.payload[activeAdvanceId];

    if (sbaLatestAdvance) {
      isEligibleForLoanForgiveness = sbaLatestAdvance.issuedDate
        && (moment(sbaLatestAdvance.issuedDate).add(56, 'days') < moment());

      if (isEligibleForLoanForgiveness) {
        await getOrCreateLoanForgiveness(sbaLatestAdvance.id);
      }
    }
  }

  componentDidUpdate() {
    const {
      activeStipulationId,
      failedFiles,
      groupName: paramCaseName,
      setActiveStipulationId,
      stipulations,
      uploadError,
      uploadPending,
    } = this.props;

    const {
      documentType,
    } = this.state;

    if (uploadPending === false && !uploadError && !failedFiles.length) {
      this.redirectToSummaryConfirmation(false);
      return;
    }

    if (!stipulations) {
      return;
    }

    const groupName = mapParamToName[paramCaseName];
    const { id, stipulationMap } = stipulations.find(({ stipulationMap: { name } }) => name === groupName);

    if (activeStipulationId !== id) {
      setActiveStipulationId(id);
    }

    if (documentType !== stipulationMap.documentType) {
      this.setDocumentType(stipulationMap.documentType);
    }
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending, uploadDocumentsClearFailedFiles } = this.props;
    uploadDocumentsClearFailedFiles();
    clearUploadError();
    clearUploadPending();
  }

  uploadFiles = async (files) => {
    try {
      const { match, uploadDocuments } = this.props;
      const { opportunityId } = match.params;
      const { documentType } = this.state;
      const payload = uploadUtils.buildMultipleFilesPayload(files, opportunityId, documentType);

      this.setState({ submitted: true });
      await uploadDocuments(payload);
    }
    catch (error) {
      log.error(error);
      throw error;
    }
  }

  shouldShowAlert = () => {
    const { uploadedFiles, submitted } = this.state;
    const errorsPresent = uploadedFiles.find((file) => file.errors.length);
    return errorsPresent && submitted;
  };

  setDocumentType = (value) => this.setState({ documentType: value });

  getTitle = (groupName, ezAppEligible, loanForgiveness) => {
    const { activeAdvanceId, advances, forgivenessAppEnabled } = this.props;
    const sbaLatestAdvance = advances.byId[activeAdvanceId];
    const forgivenessObject = Object.values(loanForgiveness.byId).find((lf) => lf.advanceId === sbaLatestAdvance.id);
    if (forgivenessObject?.applicationVersionSelected === '3508S' && groupName === 'SBA Form 3508') {
      return 'SBA Form 3508S';
    }
    if (forgivenessAppEnabled && ezAppEligible && groupName === 'SBA Form 3508') {
      return 'SBA Form 3508EZ';
    }
    return groupName;
  }

  getDescription = (groupName) => {
    const {
      activeAdvanceId, advances, loanForgiveness, opportunitiesById,
    } = this.props;
    const sbaLatestAdvance = advances.byId[activeAdvanceId];

    const { match } = this.props;
    const { opportunityId } = match.params;
    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;

    switch (groupName) {
      case 'Demographic Information':
      case 'Forgiveness Demographic Information':
        return (<DemographicInformationBodyText />);
      case 'FTE Documents':
        return (<FTEBodyText loanVersion={loanVersion} />);
      case 'Mortgage Interest Documents':
        return (
          <MortgageInterestBodyText loanVersion={loanVersion} />);
      case 'Rent Documents':
        return (<RentLeaseBodyText loanVersion={loanVersion} />);
      case 'SBA Form 3508':
        return (<SBAForm3508BodyText advanceId={sbaLatestAdvance?.id} loanForgiveness={loanForgiveness} />);
      case 'Schedule A Worksheet':
        return (<ScheduleAWorksheetBodyText />);
      case 'Payroll Supporting Documents':
        return (<PayrollSupportingBodyText loanVersion={loanVersion} />);
      case 'Utilities Documents':
        return (<UtilityBodyText loanVersion={loanVersion} />);
      default:
        return (<></>);
    }
  }

  async redirectToSummaryConfirmation(fulfillStipGroup = true) {
    const {
      activeStipulationId, country, fulfillStipulationById, push, match, groupName: paramCaseName,
    } = this.props;
    const { opportunityId } = match.params;
    const groupName = mapParamToName[paramCaseName];
    const RoutePayload = {
      country,
      successStipulationGroupName: paramCaseName,
    };
    if (fulfillStipGroup) {
      await fulfillStipulationById(opportunityId, activeStipulationId);

      const { fulfillStipulation } = this.props;
      if (!fulfillStipulation?.pending && fulfillStipulation?.error) {
        return undefined;
      }
    }

    heapService.markUserStipulationAsCompleted(opportunityId, groupName);
    return push(resolve(routes['forgiveness-summary'].path, RoutePayload));
  }

  render() {
    const {
      activeAdvanceId,
      advances,
      bfes,
      failedFiles,
      forgivenessAppEnabled,
      fulfillStipulation,
      groupName: paramCaseName,
      loanForgiveness,
      uploadPending,
      uploadError,
      response,
      userSetEZAppEligible,
    } = this.props;

    const { uploadedFiles, submitted } = this.state;

    const groupName = mapParamToName[paramCaseName];
    const nonSystemBfes = bfes && bfes.length ? bfes.filter((x) => !x.isSystemUser) : undefined;

    const sbaLatestAdvance = advances.byId[activeAdvanceId];

    const showServerError = (uploadPending === false && !!uploadError && submitted)
      || fulfillStipulation?.error;

    if (uploadError) {
      log.error(`response: ${response} uploadError: ${uploadError}`);
    }
    if (fulfillStipulation?.error) {
      log.error(`fulfillStipGroupResultError: ${fulfillStipulation?.error}`);
    }

    const hasFailedUploads = failedFiles.length > 0;
    let failedUploadsMessageElement;
    if (hasFailedUploads) {
      failedUploadsMessageElement = (
        <>
          <p>We failed to upload the following file(s) due to the reason(s) provided:</p>
          <ul>
            {failedFiles.map((file) => (
              <li key={file.filename}>
                {file.filename}
                :
                {' '}
                {file.failureReason}
              </li>
            ))}
          </ul>
        </>
      );
    }

    const allowedFileExtensions = [
      ...allowedFileTypes.csv,
      ...allowedFileTypes.images,
      ...allowedFileTypes.pdf,
      ...allowedFileTypes.xlsx,
      ...allowedFileTypes.docx,
    ];

    if (forgivenessAppEnabled) {
      forgivenessOptOutTextByGroup['Schedule A Worksheet'] = forgivenessAppEnabledScheduleAWorksheet;
      forgivenessOptOutTextByGroup['FTE Documents'] = undefined;
    }
    let optOutText = forgivenessOptOutTextByGroup[groupName];
    if (['Demographic Information', 'Forgiveness Demographic Information'].includes(groupName)) {
      optOutText = <strong>{optOutText}</strong>;
    }

    const helperText = forgivenessHelperTextByGroup[groupName];

    const isSBAForm3508 = groupName === 'SBA Form 3508';
    const title = this.getTitle(
      forgivenessStipPageTitle[groupName] || groupName,
      sbaLatestAdvance?.ezAppEligible || userSetEZAppEligible,
      loanForgiveness,
    );

    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div className="stipulation-content-container">
              <PageTitle text={title} bold />
              {this.getDescription(groupName)}
              {showServerError && (
                <Alert
                  message={errors.technicalIssue}
                  alertType={AlertTypes.critical}
                />
              )}
              {this.shouldShowAlert() && !hasFailedUploads && !showServerError && (
                <Alert
                  message={errors.uploads.uploadAllMessage}
                  alertType={AlertTypes.critical}
                />
              )}
              {hasFailedUploads && (
                <Alert
                  message={failedUploadsMessageElement}
                  alertType={AlertTypes.critical}
                />
              )}
              <UploadDocumentsComponent
                allowedFileExtensions={allowedFileExtensions}
                helperText={helperText}
                checkboxText={optOutText}
                customClassName="increased-width"
                isForgivenessStipulation
                maximumPerFileSizeInBytes={allowedSingleFileSize}
                maximumTotalSizeInBytes={allowedSingleFileSize}
                optOutCheckbox={optOutText && !isSBAForm3508}
                optOutSubmitFunc={() => this.redirectToSummaryConfirmation()}
                submitFunc={this.uploadFiles}
              />
              {uploadedFiles.length > 0 && (
                <div>
                  <h1>Files uploaded:</h1>
                  <ul>
                    {uploadedFiles.map((file) => <li key={file.name}>{file.name}</li>)}
                  </ul>
                </div>
              )}
            </div>
          )}
          sidebarElement={(
            <SidebarComponent bfes={nonSystemBfes} showLoanForgivenessView />
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAdvanceId: state.activeIds.advanceId,
    activeForgivenessId: state.activeIds.forgivenessId,
    activeStipulationId: state.activeIds.stipulationId,
    advances: state.entities.advances,
    bfes: state.accountItems.bfes,
    failedFiles: state.uploadDocument.failedFiles,
    forgivenessAppEnabled: state.config.forgivenessAppEnabled,
    fulfillStipGroupResult: state.fulfillStipulationGroup,
    loanForgiveness: state.entities.loanForgiveness,
    loanForgivenessById: state.entities.loanForgiveness?.byId,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    stipulations: state.stipulations.allStipulations,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
    response: state.uploadDocument.response,
    userSetEZAppEligible: state.userSetEZAppEligible,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    fulfillStipulationById: fulfillStipulationAction,
    getProductsAndBfes: getProductsAndBfesAction,
    uploadDocuments: uploadDocumentsAction,
    uploadDocumentsClearFailedFiles: uploadDocumentsClearFailedFilesAction,
    getOrCreateLoanForgiveness: getOrCreateLoanForgivenessAction,
    getAdvances: getAdvancesAction,
    getStipulations: getStipulationsAction,
    getOpportunities: getOpportunitiesAction,
    setActiveForgivenessId: setActiveForgivenessIdAction,
    setActiveStipulationId: setActiveStipulationIdAction,
  },
)(SecurePage(UploadLoanForgivenessPage));
