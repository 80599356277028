import { get } from 'lodash';
import * as actions from '../actions/auth/auth-action-types';
import constants from '../../constants';
import { AlertTypes } from '../../components/common/alert';

export function authenticate(state = {}, action) {
  switch (action.type) {
    case actions.AUTHENTICATED:
      return { ...state, authenticated: true, countryCode: action.countryCode };
    case actions.UNAUTHENTICATED:
      return { ...state, authenticated: false };
    default:
      return state;
  }
}

export function authorize(state = {}, action) {
  switch (action.type) {
    case actions.AUTHORIZED:
      return { ...state, authorized: true, user: action.user };
    case actions.AUTHORIZATION_ERROR:
      return { ...state, error: action.error };
    // case actions.SSO_TOKEN_REQUESTED:
    //   return state;
    default:
      return state;
  }
}

export function securityQuestion(state = {}, action) {
  switch (action.type) {
    case actions.SECURITY_QUESTION_REQUESTED:
      return state;
    case actions.SECURITY_QUESTION_RECEIVED:
      return { ...state, question: action.question };
    case actions.SECURITY_QUESTION_REQUEST_ERROR:
      return { ...state, error: actions.SECURITY_QUESTION_REQUEST_ERROR };
    default:
      return state;
  }
}

export function securityQuestions(state = {}, action) {
  switch (action.type) {
    case actions.SECURITY_QUESTIONS_REQUESTED:
      return state;
    case actions.SECURITY_QUESTIONS_RECEIVED:
      return { ...state, userQuestions: action.userQuestions, userQuestionIds: action.userQuestionIds };
    case actions.SECURITY_QUESTIONS_REQUEST_ERROR:
      return { ...state, error: actions.SECURITY_QUESTIONS_REQUEST_ERROR };
    default:
      return state;
  }
}

export function forgotPassword(state = {}, action) {
  switch (action.type) {
    case actions.FORGOT_PASSWORD_EMAIL_REQUESTED:
      return state;
    case actions.FORGOT_PASSWORD_EMAIL_SENT_RECEIVED:
      return state;
    case actions.FORGOT_PASSWORD_EMAIL_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

export function updatePassword(state = {}, action) {
  switch (action.type) {
    case actions.UPDATE_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        messageBody: handleResetPasswordError(action.payload),
        messageType: AlertTypes.critical,
      };
    case actions.UPDATE_PASSWORD_RECEIVED:
      return { ...state, token: action.payload.token };
    case actions.SET_UPDATE_PASSWORD_MESSAGE:
      return { ...state, messageBody: action.messageBody, messageType: action.messageType };
    case actions.CLEAR_UPDATE_PASSWORD_MESSAGE:
      return { ...state, messageBody: undefined, messageType: undefined };
    default:
      return state;
  }
}

export function createSecurityQuestions(state = {}, action) {
  switch (action.type) {
    case actions.CREATE_SECURITY_QUESTIONS_RECEIVED:
      return { ...state, success: true, token: action.payload.token };
    case actions.CREATE_SECURITY_QUESTIONS_REQUEST_ERROR:
      return {
        ...state, messageBody: action.payload.message, messageType: AlertTypes.critical, success: false,
      };
    case actions.SET_CREATE_SECURITY_QUESTIONS_MESSAGE:
      return { ...state, messageBody: action.messageBody, messageType: action.messageType };
    case actions.CLEAR_CREATE_SECURITY_QUESTIONS_MESSAGE:
      return {
        ...state,
        messageBody: undefined,
        messageType: undefined,
        success: undefined,
        token: undefined,
      };
    case actions.LIST_CREATE_SECURITY_QUESTIONS_RECEIVED:
      return { ...state, securityQuestions: action.payload };
    case actions.LIST_CREATE_SECURITY_QUESTIONS_ERROR:
      return { ...state, messageBody: action.payload.message, messageType: AlertTypes.critical };
    default:
      return state;
  }
}

export function resetToken(state = {}, action) {
  switch (action.type) {
    case actions.VERIFY_RESET_TOKEN_REQUEST_ERROR:
      // eslint-disable-next-line no-case-declarations
      const messageBody = get(action, 'payload.response.data.message', action.payload.message);
      return {
        ...state, messageBody, messageType: AlertTypes.critical, error: true,
      };
    case actions.VERIFY_RESET_TOKEN_RECEIVED:
      return { ...state, email: action.payload, error: false };
    case actions.CLEAR_RESET_TOKEN_STATUS:
      return { ...state, error: undefined };
    default:
      return state;
  }
}

const handleResetPasswordError = (error) => error?.response?.data?.message
  ?? error?.message
  ?? constants.errors.requestFailure;
