import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';

const EntityGroupOne = () => (
  <>
    <BodyText className="payroll-verification__content-list-header">
      To substantiate your payroll costs calculation, please upload:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 941 Taxable Medicare wages &amp; tips from each quarter or equivalent payroll processor
        records or IRS Wage and Tax Statements.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        In lieu of IRS Form 941, IRS Form W-2s and IRS Form W-3 or payroll processor reports, including
        quarterly and annual tax reports may be provided, as well as annual IRS Form 944 or annual IRS
        Form 943 (when applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 1120 or IRS Form 1120-S or other documentation of any group health, life, disability,
        vision, and dental insurance contributions and retirement contributions.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        State quarterly wage reporting forms to support employer state and local taxes
        assessed on employee compensation.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate your business was in operation on Feb 15, 2020, please upload:
    </BodyText>
    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        A payroll statement or similar documentation from the pay period that covered Feb 15, 2020 to
        establish that your busines was in operation on and had employees on Feb 15, 2020. This documentation
        is required for all applicants regardless of the timeframe you selected for your payroll costs calculation.
      </BodyText>
    </BodyText>
  </>
);

export default EntityGroupOne;
