import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tile } from '@swift-paypal/pp-react';
import './better-standard-layout.less';

const BetterStandardLayout = ({ bodyElement, sidebarElement, secondarySidebarElement }) => {
  const getContainerClassName = () => `
    better-standard-layout
    ${!sidebarElement && !secondarySidebarElement && 'better-standard-layout--body-only'}
  `;
  return (
    <Container fluid className={getContainerClassName()}>
      <div className="page-element sidebar">
        {sidebarElement && (
          <Tile card>
            {sidebarElement}
          </Tile>
        )}
      </div>
      <div className="page-element body">
        <Tile card>
          {bodyElement}
        </Tile>
      </div>
      <div className="page-element secondary-sidebar">
        {secondarySidebarElement}
      </div>
    </Container>
  );
};

BetterStandardLayout.defaultProps = {
  sidebarElement: undefined,
  secondarySidebarElement: undefined,
};

BetterStandardLayout.propTypes = {
  bodyElement: PropTypes.element.isRequired,
  sidebarElement: PropTypes.element,
  secondarySidebarElement: PropTypes.element,
};

export default React.memo(BetterStandardLayout);
