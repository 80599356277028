import React from 'react';
import { connect } from 'react-redux';
import './paypal-login.less';
import PropTypes from 'prop-types';
import { Button } from '@swift-paypal/pp-react';
import { navigateToSsoWindow } from '../../../lib/paypal-sso';
import migratedFlowConstant from '../../../constants/migratedFlowConstant';

class PaypalLogin extends React.Component {
  static propTypes = {
    country: PropTypes.string,
    paypalAuthorizeUrl: PropTypes.string,
    paypalBaseUrl: PropTypes.string,
    paypalClientId: PropTypes.string,
    paypalCallbackUrl: PropTypes.string,
    paypalCallbackUrlMigratedFlow: PropTypes.string,
  };

  static defaultProps = {
    country: undefined,
    paypalAuthorizeUrl: undefined,
    paypalBaseUrl: undefined,
    paypalClientId: undefined,
    paypalCallbackUrl: undefined,
    paypalCallbackUrlMigratedFlow: undefined,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      country,
      paypalBaseUrl,
      paypalAuthorizeUrl,
      paypalClientId,
      paypalCallbackUrl,
      paypalCallbackUrlMigratedFlow,
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const version = urlParams.get('version');
    if (version === migratedFlowConstant.URL_APPEND) {
      navigateToSsoWindow(paypalBaseUrl, paypalAuthorizeUrl, paypalClientId, paypalCallbackUrlMigratedFlow, country);
    }
    else {
      navigateToSsoWindow(paypalBaseUrl, paypalAuthorizeUrl, paypalClientId, paypalCallbackUrl, country);
    }
  }

  render() {
    return (
      <div className="paypal-login">
        <Button type="button" onClick={this.handleClick} className="btn-squared">
          <span className="vx_icon vx_globalNav-brand_desktop no-padding" />
          <span className="no-padding paypal-login-text">Continue With PayPal</span>
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    paypalBaseUrl: state.config.paypalBaseUrl,
    paypalAuthorizeUrl: state.config.paypalAuthorizeUrl,
    paypalClientId: state.config.paypalClientId,
    paypalCallbackUrl: state.config.paypalCallbackUrl,
    paypalCallbackUrlMigratedFlow: state.config.paypalCallbackUrlMigratedFlow,
  };
}

export default connect(mapStateToProps)(PaypalLogin);
