import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button, Col, Container, HeadingText, Row,
} from '@swift-paypal/pp-react';
import { useRedirect, useShallowSelector } from '../../../hooks';
import routes from '../../../routes';
import { clearUpdateLoanForgivenessAction, updateLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { clearFulfillStipulationAction, fulfillStipulationAction } from '../../../redux/actions/stipulations/stipulations-actions';
import { Sba3508SFormAlert } from './sba3508SFormAlert';
import { Sba3508SFormDetails } from './sba3508SFormDetails';
import { Sba3508SFormFields } from './sba3508SFormFields';
import { formName } from './sba3508SFormFields/sba3508SFormFields.constants';
import { formSelector } from './sba3508SForm.selectors';
import { buildSfPatchData, onSubmitFailHandler } from './sba3508SForm.utils';
import './sba3508SForm.less';

const Sba3508SForm = ({ handleSubmit }) => {
  const [state, setState] = useState({
    hasSubmissionError: false,
    isSubmitting: false,
  });
  const { hasSubmissionError, isSubmitting } = state;

  const dispatch = useDispatch();
  const redirect = useRedirect();

  const { opportunityId, stipulationId } = useParams();
  const {
    activeForgivenessId, fulfillStipulation, registeredFields, updateLoanForgiveness, values,
  } = useShallowSelector(formSelector);
  useEffect(() => onUpateLoanForgiveness(), [updateLoanForgiveness]);
  useEffect(() => onFulfillStipulation(), [fulfillStipulation]);
  useEffect(() => {
    if (hasSubmissionError) {
      setState({ ...state, isSubmitting: false });
    }
  }, [hasSubmissionError]);

  const onUpateLoanForgiveness = () => {
    setState({ ...state, hasSubmissionError: !!updateLoanForgiveness?.error });
    if (!updateLoanForgiveness?.pending && updateLoanForgiveness?.success && isSubmitting) {
      dispatch(fulfillStipulationAction(opportunityId, stipulationId));
    }
  };

  const onFulfillStipulation = () => {
    setState({ ...state, hasSubmissionError: !!fulfillStipulation?.error });
    if (!fulfillStipulation?.pending && fulfillStipulation?.success && isSubmitting) {
      redirect(routes['forgiveness-summary'].path, { country: 'us' });
    }
  };

  const onSubmit = () => {
    dispatch(clearFulfillStipulationAction());
    dispatch(clearUpdateLoanForgivenessAction());
    setState({ ...state, hasSubmissionError: false });

    const isReviewedAmountRegistered = registeredFields?.reviewedAdjustedForgivenessAmount;
    const reviewedAmountValue = values?.reviewedAdjustedForgivenessAmount;

    if (!isReviewedAmountRegistered || (isReviewedAmountRegistered && reviewedAmountValue === 'true')) {
      const sfPatchData = buildSfPatchData(values, registeredFields, activeForgivenessId);
      setState({ ...state, isSubmitting: true });
      dispatch(updateLoanForgivenessAction(sfPatchData));
    }
  };

  const handleCancel = () => {
    redirect(routes['forgiveness-summary'].path, { country: 'us' });
  };

  return (
    <>
      <HeadingText as="h1" size="md">
        SBA Form 3508S
      </HeadingText>
      <Sba3508SFormDetails />
      <Container className="sba-3508s-form" form>
        <Sba3508SFormFields />
        <Sba3508SFormAlert hasSubmissionError={hasSubmissionError} />
        <Row align="center">
          <Col form="auto" className="action-buttons">
            <Button className="sba-3508s-form__button" onClick={handleSubmit(onSubmit)} type="submit">
              Submit
            </Button>
          </Col>
          <Col form="auto">
            <Button className="sba-3508s-form__button" onClick={handleCancel} secondary type="cancel">
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Sba3508SForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: formName, onSubmitFail: (errors) => onSubmitFailHandler(errors) })(Sba3508SForm);
