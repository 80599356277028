import { GUARANTY_CLAIMS_REQUEST_RECEIVED } from '../actions/guaranty-claims/guaranty-claims-action-types';

const defaultState = false;

const isGuarantyClaimAvailable = (state = defaultState, action) => {
  const { payload } = action;

  switch (action.type) {
    case GUARANTY_CLAIMS_REQUEST_RECEIVED:
      return payload.guarantyClaims;
    default:
      return state;
  }
};

export default isGuarantyClaimAvailable;
