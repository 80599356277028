import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import PasswordReqs from './password-reqs';
import ShowHideButton from './show-hide-button';

const { errors, passwordLength, regex } = constants;

const newPasswordField = (props) => {
  const {
    input, label, show, toggleShow, meta, usernameRegex,
  } = props;
  const {
    error, active, visited, submitting, submitFailed,
  } = meta;
  if (submitting && show) {
    toggleShow();
  }
  const showError = (visited || submitFailed) && error;
  return (
    <div
      className={`vx_floatingLabel_active password-field
        ${showError ? 'vx_has-error-with-message' : ''}
        ${(input.value) ? 'hasValue' : ''}`}
      data-label-content={label}
    >
      <label htmlFor={input.name}>
        {label}
      </label>
      <input
        {...input}
        id={input.name}
        type={!show ? 'password' : 'text'}
        className={`form-control vx_form-control do-not-record ${!showError && !active ? 'margin-bottom-sm' : ''}`}
      />
      <ShowHideButton show={show} toggleShow={toggleShow} />
      {active && <PasswordReqs current={input.value} usernameRegex={usernameRegex} />}
      {!active && showError
      && (
      <span className="vx_form-control-message margin-bottom-sm error-list">
        {!regex.letterNumber.pattern.test(input.value) && <p>{regex.letterNumber.message}</p>}
        {(input.value.length < passwordLength.min || input.value.length > passwordLength.max) && (
          <p>{passwordLength.message}</p>
        )}
        {!regex.specialChars.pattern.test(input.value) && <p>{regex.specialChars.message}</p>}
        {usernameRegex && !usernameRegex.test(input.value) && <p>{errors.cannotBeUsername}</p>}
      </span>
      )}
    </div>
  );
};

newPasswordField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  show: PropTypes.bool,
  toggleShow: PropTypes.func.isRequired,
};

newPasswordField.defaultProps = {
  input: undefined,
  meta: undefined,
  show: false,
};

export default newPasswordField;
