import React from 'react';
import { connect } from 'react-redux';
import './login-revamp.less';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, HeadingText,
} from '@swift-paypal/pp-react';
import { navigateToSsoWindow } from '../../lib/paypal-sso';
import ppLogo from '../../static/images/icons/pp_logo_circle.svg';

const PaypalLogin = ({
  country, paypalBaseUrl, paypalAuthorizeUrl, paypalClientId, paypalCallbackUrl,
}) => {
  const handleClick = () => {
    navigateToSsoWindow(paypalBaseUrl, paypalAuthorizeUrl, paypalClientId, paypalCallbackUrl, country);
  };
  return (
    <Row onClick={handleClick}>
      <Col xs={2}>
        <img src={ppLogo} alt="" className="icon" />
      </Col>
      <Col className="login-option" xs={10}>
        <Button type="button" className="button">
          <HeadingText className="text">PayPal</HeadingText>
        </Button>
      </Col>
    </Row>
  );
};

PaypalLogin.propTypes = {
  country: PropTypes.string,
  paypalAuthorizeUrl: PropTypes.string,
  paypalBaseUrl: PropTypes.string,
  paypalClientId: PropTypes.string,
  paypalCallbackUrl: PropTypes.string,
};

PaypalLogin.defaultProps = {
  country: undefined,
  paypalAuthorizeUrl: undefined,
  paypalBaseUrl: undefined,
  paypalClientId: undefined,
  paypalCallbackUrl: undefined,
};

const mapStateToProps = (state) => ({
  paypalBaseUrl: state.config.paypalBaseUrl,
  paypalAuthorizeUrl: state.config.paypalAuthorizeUrl,
  paypalClientId: state.config.paypalClientId,
  paypalCallbackUrl: state.config.paypalCallbackUrl,
});

export default connect(mapStateToProps)(PaypalLogin);
