import React from 'react';
import { BodyText, HeadingText } from '@swift-paypal/pp-react';

export const SubmittedForReviewDetails = () => (
  <>
    <HeadingText size="sm">
      PPP Loan Forgiveness: Request Submitted for Initial Review
    </HeadingText>
    <BodyText as="p">
      We have received your uploaded documentation and initiated a review of the information provided.
      A PPP Loan Forgiveness Specialist will be in touch by phone or email, if additional Information
      or documentation is needed.
    </BodyText>
    <BodyText as="p">
      <strong>Note: </strong>
      Under SBA PPP rules, we have up to 60 days from submission of a completed PPP Loan Forgiveness
      Application to issue a forgiveness decision recommendation to the SBA. The SBA then has up to
      90 days to review and determine if it is in agreement with our recommendation. We will keep you
      informed through email on the progress of your PPP Loan Forgiveness Application during this process.
    </BodyText>
  </>
);
