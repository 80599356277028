import opportunityService from '../../../services/opportunity.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './opportunities-action-types';

export const getOpportunitiesAction = asyncAuthorizedActionCreator(
  {
    pending: actions.OPPORTUNITIES_REQUEST_PENDING,
    complete: actions.OPPORTUNITIES_REQUEST_RECEIVED,
    error: actions.OPPORTUNITIES_REQUEST_ERROR,
  },
  () => (
    dispatch,
    getState,
  ) => opportunityService.getOpportunities(getState().config.apiUrl),
);
