import _ from 'lodash';
import * as actions from '../actions/notifications/notifications-action-types';
import correctSalesforceDataService from '../../services/correctSalesforceData.service';

export default (state = { fulfilledStips: [] }, action) => {
  switch (action.type) {
    case actions.CONTACT_NOTIFICATIONS_RECEIVED: {
      const { autoDraftItems = [], actionItems, location } = action.payload;
      return {
        ...state,
        notificationsPending: false,
        ...mapActionItems(location, autoDraftItems, actionItems),
        error: null,
      };
    }
    case actions.CONTACT_NOTIFICATIONS_REQUEST_ERROR:
      return { ...state, notificationsPending: false, error: action.payload.message };
    case actions.CONTACT_NOTIFICATIONS_REQUEST_PENDING:
      return { ...state, notificationsPending: true };
    case actions.MARK_STIPULATION_FULFILLED:
      return {
        ...state,
        fulfilledStips:
          action.stipName !== 'BankStatements' // do not mark BankStatements as fulfilled
            ? state.fulfilledStips.concat([{ name: action.stipName, id: action.id }])
            : state.fulfilledStips,
      };
    default:
      return state;
  }
};

function mapActionItems(location, autoDraftItems, notifications) {
  const stipulations = mapNotifications(notifications);
  correctSalesforceDataService.correctStipulationStages(location, stipulations);
  const count = getTotalNotifications(stipulations) + autoDraftItems.length;
  const bfes = getApplicationBfes(stipulations);

  // get all the stips related to all accounts' applications' stips and account-level stips and present them
  // in a single 1-level array.
  const flattenedApplicationStipulations = _.flatten(
    stipulations.map((account) => _.flatten(
      account.applications.map((application) => application.stipulations.map((stipulation) => {
        stipulation.opportunityId = application.id;
        return stipulation;
      })),
    ).concat(account.stipulations)),
  );

  // get all the stips not related to accounts' applications' stips and account-level stips and present them
  // in a single 1-level array.
  const flattenedNonApplicationStipulations = _.flatten(
    stipulations.map((stip) => _.flatten(stip.stipulations.map((account) => account.stipulations))),
  );

  return {
    stipulations,
    autoDraftItems,
    count: count <= 99 ? count : 99,
    bfes,
    flattenedStipulations: flattenedApplicationStipulations.concat(
      flattenedNonApplicationStipulations,
    ),
  };
}

function mapNotifications(notifications) {
  const notificationsByAccount = _(notifications)
    .groupBy((x) => x.account.id)
    .map((value, key) => ({
      accountId: key,
      accountName: _.first(value).account.name,
      applications: value.filter((x) => x.isApplication),
      stipulations: value.filter((x) => !x.isApplication),
      numberOfStipulations: _.reduce(
        value,
        (len, account) => {
          len += account.stipulations.filter((x) => x.isRenewal).length;
          return len;
        },
        0,
      ),
    }))
    .value();

  return notificationsByAccount && notificationsByAccount.length > 1
    ? _.sortBy(notificationsByAccount, ['accountName'])
    : notificationsByAccount;
}

function getTotalNotifications(stipulations) {
  return _.reduce(
    stipulations,
    (len, stipulation) => {
      len += stipulation.numberOfStipulations;
      return len;
    },
    0,
  );
}

function getApplicationBfes(stipulations) {
  return _.reduce(
    stipulations,
    (bfe, stipulation) => {
      if (stipulation.applications) {
        _.forEach(stipulation.applications, (application) => {
          if (!application.bfe.isSystemUser) {
            bfe.push(application.bfe);
          }
        });
      }
      return bfe;
    },
    [],
  );
}
