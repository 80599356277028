import axios from 'axios';
import log from 'loglevel';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';
import { allowedSingleFileSize } from '../constants/upload-document';

export default {
  getTypes: asyncDebounce(getTypes),
  upload,
  uploadMultiple,
};

async function getTypes(apiUrl) {
  try {
    const res = await axios.get(`${apiUrl}/documents/types`);
    return res.data;
  }
  catch (error) {
    log.error(error);
    throw error;
  }
}

async function upload(apiUrl, data) {
  try {
    const options = {
      ...defaultRequestOptions(),
      params: {
        hideSpinner: true,
      },
      maxContentLength: allowedSingleFileSize,
    };

    const res = await axios.post(`${apiUrl}/documents`, data, options);
    log.info(['Uploading Document Response', res]);
    return res.data;
  }
  catch (error) {
    log.error(['Uploading Document Error', error]);
    throw error;
  }
}

async function uploadMultiple(apiUrl, data) {
  try {
    const options = {
      ...defaultRequestOptions(),
      params: {
        hideSpinner: true,
      },
      maxContentLength: allowedSingleFileSize,
    };

    const res = await axios.post(`${apiUrl}/v2/documents`, data, options);
    log.info(['Uploading Multiple Document Response', res]);
    return res.data;
  }
  catch (error) {
    log.error(['Uploading Multiple Document Error', error]);
    throw error;
  }
}
