export default {
  ownershipTip: 'Federal law requires all financial institutions to obtain, verify, and record information that '
    + 'identifies each person who opens an account. For businesses owned or managed by more than one person, we’re '
    + 'required to collect additional identification to verify how your business is structured. These people will not '
    + 'have access to the account or the loan.',
  businessOwnerTip: 'These are people who own 25% or more, either directly or indirectly, of the legal business entity, '
    + 'up to 4 people.',
  sbaBusinessOwnerTip: 'These are people who own 20% or more, either directly or indirectly, of the legal business entity, '
    + 'up to 5 people.',
  businessManagementTip: 'This manager role has a significant impact on business decisions. In a big business, it might '
    + 'be the CEO or CFO. In a smaller business, it might be the general manager or bookkeeper.',
  originalLoanAmountTip: 'The Original loan amount is the Loan Amount specified in your Business Loan Agreement.',
  originalFeeAmountTip: 'The Original fee amount is the Total Loan Fee specified in your Business Loan Agreement.',
  originalFeeAmountTipCA: 'The Original fee amount is the Total Loan Fee specified in your Business Loan Agreement.',
  totalBalanceTip: 'Total balance is the sum of the Principal balance, Fee balance and Other charges balance. Refer to '
    + 'your Business Loan Agreement for details on early payoff.',
  principalBalanceTip: 'The Principal balance is the outstanding balance of the initial Loan Amount specified in the '
    + 'Business Loan Agreement (Original loan amount).',
  feeBalanceTip: 'The fee balance is the outstanding balance of the Total Loan Fee specified in the Business '
    + 'Loan Agreement (Original fee amount).',
  feeBalanceTipCA: 'The fee balance is the outstanding balance of the Total Loan Fee specified in the Business '
    + 'Loan Agreement (Original fee amount).',
  additionalSignerTip: 'Cosigners must pass personal verification and are personal guarantors of the loan. The '
    + 'contract is delivered to the email identified for each signer.',
};
