import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Col, Row } from '@swift-paypal/pp-react';
import Currency from '../../common/currency';

class Transaction extends Component {
  static propTypes = {
    transaction: PropTypes.shape({
      amount: PropTypes.number,
      description: PropTypes.string,
      fee: PropTypes.number,
      other: PropTypes.number,
      principal: PropTypes.number,
      postDate: PropTypes.string,
    }),
  };

  static defaultProps = {
    transaction: undefined,
  };

  constructor(props) {
    super(props);
    const { transaction } = this.props;
    this.state = {
      showDetails: false,
      date: this.getDateParts(transaction.postDate),
    };
  }

  showDetails = () => {
    const { showDetails } = this.state;
    this.setState({
      showDetails: !showDetails,
    });
  };

  getDateParts = (date) => {
    date = moment(date);

    return {
      month: date.format('MMM'),
      day: date.format('D'),
      year: date.format('YYYY'),
      dateString: date.format('MMMM D, YYYY'),
    };
  };

  formatTransactionLabel = (description) => {
    if (description === 'Interest Charge') {
      return 'Total Loan Fee';
    }
    return description;
  }

  render() {
    const { transaction } = this.props;
    const { date, showDetails } = this.state;
    return (
      <li className="transactionRow">
        <div className={`transactionItem ${showDetails ? 'highlightTransactionRow' : ''}`}>
          <Row>
            <Col xs={2}>
              <div className="dateParts" data-automation-id="transactionDateLabel">
                <span className="dateMonth vx_text-body-md">{date.month}</span>
                <span className="dateDay">{date.day}</span>
                <span className="dateYear">{date.year}</span>
              </div>
            </Col>
            <Col xs={6} sm={6} md={7} className="transaction-details-container">
              <span className="transactionType" data-automation-id="transactionTypeLabel">{this.formatTransactionLabel(transaction.description)}</span>
              <span className="detailsLink">
                <button type="button" className="button-link ppvx_link pp-react__link--inline" data-automation-id="transactionShowDetailsButton" tabIndex="0" onClick={this.showDetails} onKeyDown={this.showDetails}>
                  {`${showDetails ? 'Hide' : 'Show'} details`}
                </button>
              </span>
            </Col>
            <Col xs={4} sm={4} md={3} className="transactionAmount">
              <span className="netAmount vx_h4">
                {transaction.amount > 0 && '+'}
                <Currency
                  amount={transaction.amount}
                  dataAutomationId="transactionTotalValueLabel"
                />
              </span>
            </Col>
          </Row>
        </div>
        <div className={`highlightTransactionPanel${showDetails ? '' : ' hide'}`}>
          <div className="inlineTransactionDetails">
            <Row>
              <Col xs={{ offset: 2, span: 10 }} className="mobileTransactionDetails">
                <dl>
                  <dt data-automation-id="transactionDetailsLabel">Details</dt>
                  <dd className="vx_small-text">
                    <Row>
                      <Col xs={4} sm={6} data-automation-id="transactionDetailDateLabel">Date</Col>
                      <Col xs={8} sm={6} className="dateString" data-automation-id="transactionDetailDateValueLabel">{date.dateString}</Col>
                    </Row>
                  </dd>
                  <dd>
                    <Row>
                      <Col xs={6} data-automation-id="transactionDetailPrincipalLabel">Principal</Col>
                      <Col xs={6} className="principalAmount"><Currency amount={transaction.principal} dataAutomationId="transactionDetailPrincipalValueLabel" /></Col>
                    </Row>
                  </dd>
                  <dd>
                    <Row>
                      <Col xs={6} data-automation-id="transactionDetailFeeLabel">Fee</Col>
                      <Col xs={6} className="feeAmount"><Currency amount={transaction.fee} dataAutomationId="transactionDetailFeeValueLabel" /></Col>
                    </Row>
                  </dd>
                  <dd>
                    <Row>
                      <Col xs={6} data-automation-id="transactionDetailOtherLabel">Other</Col>
                      <Col xs={6} className="otherAmount"><Currency amount={transaction.other} dataAutomationId="transactionDetailOtherValueLabel" /></Col>
                    </Row>
                  </dd>
                </dl>
              </Col>
            </Row>
          </div>
        </div>
      </li>
    );
  }
}

export default Transaction;
