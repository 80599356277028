import _ from 'lodash';
import React, { Component } from 'react';
import { submit, touch, blur } from 'redux-form';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Button, Col } from '@swift-paypal/pp-react';
import resolve from '../services/route.service';
import redirect from '../lib/redirect';
import PageTitle from '../components/common/page-title';
import BusinessPropertyFormComponent from '../components/business-property-form';
import {
  setOpportunityAsLandlordAction,
  createLandlordContactAction,
  updateLandlordMessageAction,
  clearLandlordAction,
  clearLandlordMessageAction,
  setOwnBusinessPropertyAction,
} from '../redux/actions/business-property/business-property-actions';
import {
  clearContactsAction, createContactsAction, getContactsAction,
  getProductsAndBfesAction,
} from '../redux/actions/account-items/account-items-actions';
import SecurePage from '../hocs/secure-page';
import { AlertTypes } from '../components/common/alert';
import constants from '../constants';
import notificationUtil from '../lib/notification-util';
import VendorReferencesFormComponent from '../components/vendor-references/vendor-references-form';
import routes from '../routes';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class ReferencesPage extends Component {
  static displayName = displayNames.ReferencesPage;

  static propTypes = {
    accountsPending: PropTypes.bool,
    businessPropertyForm: PropTypes.shape({
      registeredFields: PropTypes.object,
      syncErrors: PropTypes.object,
      values: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
    businessPropertyMessageBody: PropTypes.string,
    businessPropertyMessageType: PropTypes.string,
    clearContacts: PropTypes.func.isRequired,
    clearLandlordMessage: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.any),
    country: PropTypes.string,
    createContacts: PropTypes.func.isRequired,
    createLandlordContact: PropTypes.func.isRequired,
    getContacts: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    groupName: PropTypes.string.isRequired,
    history: ReactRouterPropTypes.history,
    inFlightOpportunities: PropTypes.arrayOf(PropTypes.object),
    markFormsToched: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match,
    notifications: PropTypes.arrayOf(PropTypes.object),
    notificationsPending: PropTypes.bool,
    opportunityId: PropTypes.string.isRequired,
    ownBusinessProperty: PropTypes.bool,
    setOpportunityAsLandlord: PropTypes.func.isRequired,
    setOwnBusinessProperty: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object),
    submitBusinessPropertyForm: PropTypes.func.isRequired,
    submitVendorReferencesForm: PropTypes.func.isRequired,
    updateLandlordMessage: PropTypes.func.isRequired,
    vendorReferenceMessageBody: PropTypes.string,
    vendorReferenceMessageType: PropTypes.string,
    vendorReferencesForm: PropTypes.shape({
      registeredFields: PropTypes.object,
      syncErrors: PropTypes.object,
      values: PropTypes.shape({}),
    }),
  };

  static defaultProps = {
    accountsPending: false,
    businessPropertyForm: undefined,
    businessPropertyMessageBody: undefined,
    businessPropertyMessageType: undefined,
    contacts: undefined,
    country: undefined,
    history: undefined,
    inFlightOpportunities: undefined,
    match: undefined,
    notifications: undefined,
    notificationsPending: false,
    ownBusinessProperty: false,
    stipulations: undefined,
    vendorReferenceMessageBody: undefined,
    vendorReferenceMessageType: undefined,
    vendorReferencesForm: undefined,
  };

  componentDidMount() {
    const {
      getContacts,
      getProductsAndBfes,
      opportunityId,
    } = this.props;

    getProductsAndBfes();
    getContacts(opportunityId);
  }

  componentWillUnmount() {
    const {
      clearContacts,
      clearLandlordMessage,
    } = this.props;

    clearLandlordMessage();
    clearContacts();
  }

  handleSubmit = () => {};

  getFilteredStips = () => {
    const { stipulations, opportunityId, groupName } = this.props;
    return stipulations.filter((stip) => stip.opportunityId === opportunityId && stip.groupName === groupName);
  };

  getApplicableStips = () => {
    const filteredStips = this.getFilteredStips();
    const landlordReferenceStip = filteredStips.find((stip) => stip.isLandlordReference && !stip.isFulfilled);
    const vendorReferenceStip = filteredStips.find((stip) => stip.isVendorReference && !stip.isFulfilled);

    return {
      landlordReferenceStip,
      vendorReferenceStip,
    };
  }

  submit = () => {
    const {
      businessPropertyForm,
      createContacts,
      vendorReferencesForm,
      submitBusinessPropertyForm,
      submitVendorReferencesForm,
      updateLandlordMessage,
      setOpportunityAsLandlord,
      createLandlordContact,
      markFormsToched,
      ownBusinessProperty,
      opportunityId,
    } = this.props;

    const { landlordReferenceStip, vendorReferenceStip } = this.getApplicableStips();

    if (businessPropertyForm && businessPropertyForm.registeredFields) {
      markFormsToched(businessPropertyForm, 'business-property-form');
    }
    if (vendorReferencesForm && vendorReferencesForm.registeredFields) {
      markFormsToched(vendorReferencesForm, 'vendor-references');
    }

    if (((businessPropertyForm && businessPropertyForm.syncErrors) || ownBusinessProperty)
      && (vendorReferencesForm && vendorReferencesForm.syncErrors)) {
      return;
    }

    // Update LR
    if (landlordReferenceStip && businessPropertyForm) {
      const businessPropertyErrors = businessPropertyForm.syncErrors;

      if (ownBusinessProperty === undefined) {
        updateLandlordMessage(constants.errors.mustSelectLandlordOption, AlertTypes.critical);
        return;
      }

      const role = 'Landlord';
      if (ownBusinessProperty && !businessPropertyErrors) {
        setOpportunityAsLandlord(opportunityId);
      }
      else if (!ownBusinessProperty && !businessPropertyErrors) {
        const { firstName, lastName, phone } = businessPropertyForm.values;
        createLandlordContact({
          firstName, lastName, phone, role, opportunityId,
        });
      }
      submitBusinessPropertyForm();
    }

    // Update VR
    if (vendorReferenceStip && vendorReferencesForm && vendorReferencesForm.values) {
      const vendorReferencesValues = vendorReferencesForm.values;
      const vendorReferencesErrors = vendorReferencesForm.syncErrors;

      if (vendorReferencesValues && !vendorReferencesErrors) {
        const contactArray = Object.keys(vendorReferencesValues).map((key) => {
          const contact = vendorReferencesValues[key];
          contact.role = 'Vendor/Supplier';
          contact.opportunityId = opportunityId;
          return contact;
        });
        createContacts(contactArray);
        submitVendorReferencesForm();
      }
    }
  };

  validateOpportunityAndStipulation() {
    const {
      accountsPending,
      businessPropertyMessageType,
      country,
      groupName,
      history,
      inFlightOpportunities,
      match,
      notifications,
      notificationsPending,
      opportunityId,
      vendorReferenceMessageType,
    } = this.props;

    if (
      businessPropertyMessageType === AlertTypes.success
      || vendorReferenceMessageType === AlertTypes.success
    ) {
      const RoutePayload = {
        country,
        successStipulationGroupName: groupName,
      };
      history.push(resolve(routes.summary.path, RoutePayload));
      return;
    }

    if (opportunityId && !notificationsPending
      && !accountsPending && inFlightOpportunities && notifications) {
      // validate opportunity with inflight opportunities
      // validate stipulation for forms
      const stipulations = notificationUtil.getAccountStipulations(opportunityId,
        inFlightOpportunities, notifications);
      if (stipulations) {
        const currentStipulations = _.head(
          stipulations.filter(
            (x) => (x.isLandlordReference && !x.isFulfilled) || (x.isVendorReference && !x.isFulfilled),
          ),
        );
        if (currentStipulations) {
          return;
        }
      }
      redirect(history, match);
    }
  }

  render() {
    const {
      accountsPending,
      country,
      clearLandlordMessage,
      notificationsPending,
      notifications,
      businessPropertyMessageType,
      vendorReferenceMessageType,
      businessPropertyMessageBody,
      vendorReferenceMessageBody,
      contacts,
      setOwnBusinessProperty,
      stipulations,
      ownBusinessProperty,
    } = this.props;
    if (!stipulations) {
      return null;
    }

    const pageTitle = businessPropertyMessageType === AlertTypes.success ? 'Success' : 'References';

    const { landlordReferenceStip, vendorReferenceStip } = this.getApplicableStips();

    return (
      <div>
        {this.validateOpportunityAndStipulation()}
        {((businessPropertyMessageType !== AlertTypes.success || vendorReferenceMessageType !== AlertTypes.success)
          || (!accountsPending && !notificationsPending && notifications)) && (
          <>
            <Helmet>
              <title>References</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <div>
                  <PageTitle text={pageTitle} />
                  {((landlordReferenceStip && businessPropertyMessageType !== AlertTypes.success)
                  || (vendorReferenceStip && vendorReferenceMessageType !== AlertTypes.success)) && (
                    <>
                      {landlordReferenceStip && businessPropertyMessageType !== AlertTypes.success
                      && (
                      <BusinessPropertyFormComponent
                        clearMessage={clearLandlordMessage}
                        messageBody={businessPropertyMessageBody}
                        messageType={businessPropertyMessageType}
                        setOwnBusinessProperty={setOwnBusinessProperty}
                        ownBusinessProperty={ownBusinessProperty}
                        onSubmit={this.handleSubmit}
                      />
                      )}

                      {vendorReferenceStip && vendorReferenceMessageType !== AlertTypes.success
                      && (
                      <VendorReferencesFormComponent
                        contacts={contacts}
                        messageBody={vendorReferenceMessageType}
                        messageType={vendorReferenceMessageBody}
                        onSubmit={this.handleSubmit}
                      />
                      )}

                      {(businessPropertyMessageType !== AlertTypes.success
                      || vendorReferenceMessageType !== AlertTypes.success)
                      && (
                      <Col xs={6} sm={5} md={4}>
                        <Button type="submit" onClick={this.submit} className="pp-react__button--block">
                          Continue
                        </Button>
                      </Col>
                      )}
                      <Col xs={6} sm={5} md={4}>
                        <Link to={resolve(routes.summary.path, { country })}>
                          <Button secondary type="button" className="pp-react__button--block">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </>
                  )}
                </div>
              )}
              sidebarElement={<></>}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    businessPropertyMessageType: state.businessProperty.messageType,
    businessPropertyMessageBody: state.businessProperty.messageBody,
    ownBusinessProperty: state.businessProperty.ownBusinessProperty,
    inFlightOpportunities: state.accountItems.inFlightOpportunities,
    notifications: state.notifications.stipulations,
    stipulations: state.notifications.flattenedStipulations,
    accountsPending: state.accountItems.accountsPending,
    notificationsPending: state.notifications.notificationsPending,
    error: state.accountItems.error,
    vendorReferenceMessageType:
      (state.accountItems.vendorReferences && state.accountItems.vendorReferences.messageType)
      || '',
    vendorReferenceMessageBody:
      (state.accountItems.vendorReferences && state.accountItems.vendorReferences.messageBody)
      || '',
    contacts: state.accountItems.opportunityContacts,
    businessPropertyForm: state.form['business-property-form'],
    vendorReferencesForm: state.form['vendor-references'],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLandlordMessage: (...args) => dispatch(updateLandlordMessageAction(...args)),
    setOpportunityAsLandlord: (opportunityId) => dispatch(setOpportunityAsLandlordAction(opportunityId)),
    createLandlordContact: (...args) => dispatch(createLandlordContactAction(...args)),
    getProductsAndBfes: () => dispatch(getProductsAndBfesAction()),
    clearLandlord: () => dispatch(clearLandlordAction()),
    clearLandlordMessage: () => dispatch(clearLandlordMessageAction()),
    setOwnBusinessProperty: (payload) => dispatch(setOwnBusinessPropertyAction(payload)),
    clearContacts: () => dispatch(clearContactsAction()),
    getContacts: (opportunityId) => dispatch(getContactsAction(opportunityId)),
    createContacts: (contacts) => dispatch(createContactsAction(contacts)),
    submitBusinessPropertyForm: () => dispatch(submit('business-property-form')),
    submitVendorReferencesForm: () => dispatch(submit('vendor-references')),
    markFormsToched: (form, formName) => {
      Object.keys(form.registeredFields).forEach((key) => {
        dispatch(touch(formName, key));
        dispatch(blur(formName, key));
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurePage(ReferencesPage));
