/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change as changeAction } from 'redux-form';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import { push as pushAction } from 'connected-react-router';
import { BodyText, HeadingText } from '@swift-paypal/pp-react';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  getBankInformation as getBankInformationAction,
  getRoutingNumberInstitution as getRoutingNumberInstitutionAction,
  saveBankInformation as saveBankInformationAction,
  getRoutingNumberList as getRoutingNumberListAction,
  addBankInformation as addBankInformationAction,
  resetAddBankError as resetAddBankErrorAction,
} from '../../redux/actions/application/verify-bank-accounts/verify-bank-accounts-actions';
import redirect from '../../lib/redirect';
import notificationUtil from '../../lib/notification-util';
import AddBankAccountsFormComponent from '../../components/verify-bank-accounts/add-bank-accounts-form';
import constants from '../../constants';
import countryCodes from '../../constants/countryCodes';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { getContactAccountsAction } from '../../redux/actions/settings/settings-actions';

const stipulationGroupName = 'Bank account information';
const { BankFormStipVersion } = constants;
class AddBankAccountsPage extends Component {
  static displayName = displayNames.AddBankAccountsPage;

  static propTypes = {
    apiUrl: PropTypes.string,
    accountsPending: PropTypes.bool,
    additionalInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    bankInformation: PropTypes.shape({}),
    change: PropTypes.func.isRequired,
    country: PropTypes.string,
    depositInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    getBankInformation: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getRoutingNumberInstitution: PropTypes.func.isRequired,
    inFlightOpportunities: PropTypes.arrayOf(PropTypes.object),
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    newStips: PropTypes.shape({
      allStipulations: PropTypes.arrayOf(PropTypes.object),
    }),
    notifications: PropTypes.arrayOf(PropTypes.object),
    notificationsPending: PropTypes.bool,
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    saveBankInfoError: PropTypes.bool,
    saveBankInformation: PropTypes.func.isRequired,
    addBankInformation: PropTypes.func.isRequired,
    saveBankInfoSuccess: PropTypes.bool,
    addBankInfoError: PropTypes.bool,
    addBankInfoSuccess: PropTypes.bool,
    addBankErrorMessage: PropTypes.string,
    withdrawalInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    hasRouteNumberWarning: PropTypes.bool,
    getContactAccounts: PropTypes.func.isRequired,
    getRoutingNumberList: PropTypes.func.isRequired,
    resetAddBankError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    accountsPending: false,
    additionalInstitution: undefined,
    bankInformation: undefined,
    country: undefined,
    depositInstitution: undefined,
    inFlightOpportunities: undefined,
    history: undefined,
    match: undefined,
    newStips: undefined,
    notifications: undefined,
    notificationsPending: false,
    opportunityId: undefined,
    saveBankInfoError: undefined,
    saveBankInfoSuccess: false,
    addBankInfoError: undefined,
    addBankInfoSuccess: false,
    withdrawalInstitution: undefined,
    hasRouteNumberWarning: false,
    apiUrl: undefined,
    addBankErrorMessage: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSbaLoan: false,
    };
  }

  async componentDidMount() {
    const {
      getBankInformation,
      getProductsAndBfes,
      opportunityId,
      inFlightOpportunities,
      getContactAccounts,
      apiUrl,
      getRoutingNumberList,
      resetAddBankError,
    } = this.props;
    getBankInformation(opportunityId);
    getProductsAndBfes();
    resetAddBankError();
    await getContactAccounts(apiUrl);
    await getRoutingNumberList();
    if (inFlightOpportunities?.[0].opportunity?.type === 'SBA') {
      this.markAsSbaLoan(true);
    }
  }

  componentDidUpdate() {
    this.validateOpportunityAndStipulation();
  }

  getStipulationInfo() {
    const { inFlightOpportunities, notifications, opportunityId } = this.props;
    let currentStipulation;
    const stipulations = notificationUtil.getAccountStipulations(opportunityId,
      inFlightOpportunities, notifications);
    if (stipulations) {
      currentStipulation = stipulations.find((x) => x.isBankAccountInformation);
    }
    return currentStipulation;
  }

  asyncValidate = async (values, dispatch, formAsyncErrors, field) => {
    const { getRoutingNumberInstitution } = this.props;
    // eslint-disable-next-line default-case
    switch (field) {
      case `${constants.bank.WITHDRAWAL}.routingNumber`:
        await getRoutingNumberInstitution(values[constants.bank.WITHDRAWAL].routingNumber,
          constants.bank.WITHDRAWAL);
        break;
      case `${constants.bank.DEPOSIT}.routingNumber`:
        await getRoutingNumberInstitution(values[constants.bank.DEPOSIT].routingNumber,
          constants.bank.DEPOSIT);
        break;
      case `${constants.bank.ADDITIONAL}.routingNumber`:
        await getRoutingNumberInstitution(values[constants.bank.ADDITIONAL].routingNumber,
          constants.bank.ADDITIONAL);
        break;
    }
  };

  markAsSbaLoan = (value) => {
    this.setState({ isSbaLoan: value });
  };

  submitUs = (values) => {
    const {
      depositInstitution,
      withdrawalInstitution,
      additionalInstitution,
      opportunityId,
      saveBankInformation,
      addBankInformation,
    } = this.props;
    const { isSbaLoan } = this.state;

    if (depositInstitution === constants.bank.INVALID_ROUTING_NUMBER
      || withdrawalInstitution === constants.bank.INVALID_ROUTING_NUMBER
      || additionalInstitution === constants.bank.INVALID_ROUTING_NUMBER
      || depositInstitution === constants.bank.ROUTING_NUMBER_PENDING
      || withdrawalInstitution === constants.bank.ROUTING_NUMBER_PENDING
      || additionalInstitution === constants.bank.ROUTING_NUMBER_PENDING) {
      return;
    }

    const hasAdditionalAccount = !!values[constants.bank.ADDITIONAL];

    const bankInfoPayLoad = () => {
      if (BankFormStipVersion === 'v2') {
        const isWithdrawalSameAsDepositSelected = values?.isWithdrawalSameAsDepositRadio === 'yes';
        const bankPayLoad = {
          OpportunityId: opportunityId,
          BankAccounts: [
            {
              RoutingType: 'WIRE',
              RoutingNumber: values[constants.bank.DEPOSIT].routingNumber,
              AccountNumber: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].accountNumber : values[constants.bank.DEPOSIT].accountNumber,
              AccountType: constants.bank.DEPOSIT,
              BusinessName: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].businessName : values[constants.bank.DEPOSIT].businessName,
              StreetAddress1: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].businessAddressLine1 : values[constants.bank.DEPOSIT].businessAddressLine1,
              StreetAddress2: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].businessAddressLine2 : values[constants.bank.DEPOSIT].businessAddressLine2,
              State: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].state : values[constants.bank.DEPOSIT].state,
              City: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].city : values[constants.bank.DEPOSIT].city,
              Zip: isWithdrawalSameAsDepositSelected ? values[constants.bank.WITHDRAWAL].zip : values[constants.bank.DEPOSIT].zip,
              IsPaypalUser: !!values[constants.bank.DEPOSIT].isPaypalAccount,
            },
            {

              RoutingType: 'ACH',
              RoutingNumber: values[constants.bank.WITHDRAWAL].routingNumber,
              AccountNumber: values[constants.bank.WITHDRAWAL].accountNumber,
              AccountType: constants.bank.WITHDRAWAL,
              BusinessName: values[constants.bank.WITHDRAWAL].businessName,
              StreetAddress1: values[constants.bank.WITHDRAWAL].businessAddressLine1,
              StreetAddress2: values[constants.bank.WITHDRAWAL].businessAddressLine2,
              State: values[constants.bank.WITHDRAWAL].state,
              City: values[constants.bank.WITHDRAWAL].city,
              Zip: values[constants.bank.WITHDRAWAL].zip,
              IsPaypalUser: !!values[constants.bank.WITHDRAWAL].isPaypalAccount,
            },
          ],
        };

        const paymentDay = { changedInPortal: !isSbaLoan, day: values.paymentDay };
        return {
          bankPayLoad,
          paymentDay,
        };
      }

      return {
        [constants.bank.WITHDRAWAL]:
          {
            changedInPortal: true,
            routingNumber: values[constants.bank.WITHDRAWAL].routingNumber,
            accountNumber: values[constants.bank.WITHDRAWAL].accountNumber,
            isPaypalAccount: values[constants.bank.WITHDRAWAL].isPaypalAccount,
          },
        [constants.bank.DEPOSIT]:
          {
            changedInPortal: true,
            routingNumber: values[constants.bank.DEPOSIT].routingNumber,
            accountNumber: values[constants.bank.DEPOSIT].accountNumber,
            isPaypalAccount: values[constants.bank.DEPOSIT].isPaypalAccount,
          },
        [constants.bank.ADDITIONAL]: hasAdditionalAccount
          ? {
            changedInPortal: true,
            routingNumber: values[constants.bank.ADDITIONAL].routingNumber,
            accountNumber: values[constants.bank.ADDITIONAL].accountNumber,
            isPaypalAccount: values[constants.bank.ADDITIONAL].isPaypalAccount,
          } : null,
        paymentDay: { changedInPortal: !isSbaLoan, day: values.paymentDay },
      };
    };

    if (BankFormStipVersion === 'v2') {
      addBankInformation(opportunityId, bankInfoPayLoad());
    }
    else {
      saveBankInformation(opportunityId, bankInfoPayLoad());
    }
  }

  submitCa = (values) => {
    const { opportunityId, saveBankInformation } = this.props;

    const bank = values[constants.bank.DEPOSIT];
    const { institutionNumber, transitNumber, accountNumber } = bank;

    saveBankInformation(opportunityId, {
      [constants.bank.WITHDRAWAL]:
        {
          institutionNumber,
          transitNumber,
          accountNumber,
          changedInPortal: true,
        },
      [constants.bank.DEPOSIT]:
        {
          institutionNumber,
          transitNumber,
          accountNumber,
          changedInPortal: true,
        },
      paymentDay: { changedInPortal: true, day: values.paymentDay },
    });
  };

  submit = (values) => {
    const { country } = this.props;
    switch (country.toUpperCase()) {
      case countryCodes.canada:
        this.submitCa(values);
        return;
      default:
        this.submitUs(values);
    }
  }

  validateOpportunityAndStipulation() {
    const {
      accountsPending,
      country,
      history,
      inFlightOpportunities,
      match,
      notifications,
      notificationsPending,
      opportunityId,
      saveBankInfoSuccess,
      saveBankInfoError,
      addBankInfoError,
      addBankInfoSuccess,
      push,
      newStips,
    } = this.props;
    const { isSbaLoan } = this.state;

    if (saveBankInfoSuccess || addBankInfoSuccess) {
      let RoutePayload;
      if (isSbaLoan || newStips) {
        RoutePayload = {
          country,
          successStipulationGroupName: stipulationGroupName,
        };
      }
      else {
        const currentStipulation = this.getStipulationInfo();
        RoutePayload = {
          country,
          successStipulationName: currentStipulation.name,
          successStipulationGroupName: currentStipulation.groupName,
        };
      }
      heapService.markUserStipulationAsCompleted(opportunityId, RoutePayload.successStipulationGroupName);
      push(resolve(routes.summary.path, RoutePayload));

      return;
    }

    if (saveBankInfoError || addBankInfoError || newStips) {
      return;
    }

    if (opportunityId && !notificationsPending
      && !accountsPending && inFlightOpportunities && notifications) {
      // validate opportunity with inflight opportunities
      // validate stipulation for forms
      const currentStipulation = this.getStipulationInfo();
      if (currentStipulation) {
        return;
      }
      redirect(history, match);
    }
  }

  render() {
    const {
      bankInformation,
      accountsPending,
      notificationsPending,
      notifications,
      withdrawalInstitution,
      depositInstitution,
      additionalInstitution,
      change,
      saveBankInfoError,
      saveBankInfoSuccess,
      addBankInfoError,
      addBankInfoSuccess,
      inFlightOpportunities,
      hasRouteNumberWarning,
      addBankErrorMessage,
    } = this.props;
    const pageTitle = 'Bank account information';
    const isSbaLoan = inFlightOpportunities?.[0]?.opportunity?.type === 'SBA';
    const pageTitleText = BankFormStipVersion === 'v2' ? 'To expedite your loan approval, please ensure the the details you provide below exactly match your business bank records. Discrepancies may delay or prevent approval.' : 'Please make sure that your bank account can accept wire transfers.';
    return (
      <div>
        { (saveBankInfoSuccess || addBankInfoSuccess || (!accountsPending && !notificationsPending && notifications)) && (
          <>
            <Helmet>
              <title>Bank Account Information</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <div>
                  {
                    BankFormStipVersion === 'v2' ? <HeadingText className="add-bank-heading" style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} size="lg">{ pageTitle }</HeadingText> : <PageTitle bold text={saveBankInfoSuccess || addBankInfoSuccess ? 'Success!' : pageTitle} />
                  }
                  {
                    BankFormStipVersion === 'v2' ? <BodyText>{pageTitleText}</BodyText> : <PageTitle text={pageTitleText} />
                  }

                  {(!saveBankInfoSuccess || !addBankInfoSuccess)
                  && (
                  <AddBankAccountsFormComponent
                    initialValues={bankInformation}
                    asyncValidate={this.asyncValidate}
                    customAsyncValidate={this.asyncValidate}
                    withdrawalInstitution={withdrawalInstitution}
                    depositInstitution={depositInstitution}
                    additionalInstitution={additionalInstitution}
                    saveBankInfoError={saveBankInfoError}
                    hasRouteNumberWarning={hasRouteNumberWarning}
                    addBankErrorMessage={addBankErrorMessage}
                    change={change}
                    onSubmit={this.submit}
                    isSbaLoan={isSbaLoan}
                    addBankInfoError={addBankInfoError}
                  />
                  )}
                </div>
              )}
              sidebarElement={<></>}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    bankInformation: state.getBankInformation.bankInformation,
    bfes: state.accountItems.bfes,
    inFlightOpportunities: state.accountItems.inFlightOpportunities,
    accountsPending: state.accountItems.accountsPending,
    newStips: state.stipulations,
    notificationsPending: state.notifications.notificationsPending,
    notifications: state.notifications.stipulations,
    withdrawalInstitution: state.getRoutingInstitution.withdrawalInstitution,
    additionalInstitution: state.getRoutingInstitution.additionalInstitution,
    depositInstitution: state.getRoutingInstitution.depositInstitution,
    saveBankInfoError: state.saveBankInformation.error,
    saveBankInfoSuccess: state.saveBankInformation.success,
    addBankInfoError: state.addBankInformation.error,
    addBankErrorMessage: state.addBankInformation.addBankErrorMessage,
    addBankInfoSuccess: state.addBankInformation.success,
    hasRouteNumberWarning: state.getRoutingInstitution?.hasRouteNumberWarning,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProductsAndBfes: (...args) => {
      dispatch(getProductsAndBfesAction(...args));
    },
    getBankInformation: (...args) => {
      dispatch(getBankInformationAction(...args));
    },
    getRoutingNumberInstitution: (...args) => {
      dispatch(getRoutingNumberInstitutionAction(...args));
    },
    change: (field, value) => {
      dispatch(changeAction('add-bank-accounts', field, value));
    },
    saveBankInformation: (...args) => {
      dispatch(saveBankInformationAction(...args));
    },
    addBankInformation: (...args) => {
      dispatch(addBankInformationAction(...args));
    },
    getContactAccounts: (...args) => {
      dispatch(getContactAccountsAction(...args));
    },
    getRoutingNumberList: (...args) => {
      dispatch(getRoutingNumberListAction(...args));
    },
    push: (path) => dispatch(pushAction(path)),
    resetAddBankError: () => dispatch(resetAddBankErrorAction()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurePage(AddBankAccountsPage));
