import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import log from 'loglevel';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import { Alert, AlertTypes } from '../../components/common/alert';
import uploadUtils from '../../lib/document-upload-utils';
import UploadDocumentsComponent from '../../components/common/upload-documents/upload-documents';
import { getAccountsAction } from '../../redux/actions/account/account-actions';
import { getAdvancesAction } from '../../redux/actions/advances/advances-actions';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import getStipulationsAction, { fulfillStipulationAction } from '../../redux/actions/stipulations/stipulations-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadDocumentsAction,
  uploadDocumentsClearFailedFilesAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import errors from '../../constants/errors';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import DownloadPayrollCalculator from '../../components/common/download-payroll-calculator';
import { allowedFileTypes, allowedSingleFileSize } from '../../constants/upload-document';
import PayrollVerificationContent from '../../components/payroll-verification/payroll-verification-content';

const stipulationGroupName = 'Payroll Verification';

class PayrollVerificationPage extends Component {
  static displayName = displayNames.PayrollVerificationPage;

  static propTypes = {
    advancesById: PropTypes.objectOf(PropTypes.shape({
      confirmedFraudDate: PropTypes.string,
      issuedDate: PropTypes.string,
      rescindedDate: PropTypes.string,
      type: PropTypes.string,
    })),
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    failedFiles: PropTypes.arrayOf(PropTypes.object),
    fulfillStipulation: PropTypes.func.isRequired,
    fulfillStipulationResult: PropTypes.shape({
      error: PropTypes.bool,
      pending: PropTypes.bool,
    }),
    getAccounts: PropTypes.func.isRequired,
    getAdvances: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getStipulations: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
      payPalExistingRelationship: PropTypes.bool,
    })),
    push: PropTypes.func.isRequired,
    uploadDocuments: PropTypes.func.isRequired,
    uploadDocumentsClearFailedFiles: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    response: PropTypes.shape({
      params: PropTypes.object,
    }),
    stipulations: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    advancesById: {},
    country: undefined,
    failedFiles: [],
    fulfillStipulationResult: {},
    match: undefined,
    opportunitiesById: {},
    uploadPending: true,
    uploadError: false,
    response: undefined,
    stipulations: [],
  };

  constructor() {
    super();
    this.state = {
      uploadedFiles: [],
      submitted: false,
    };
  }

  async componentDidMount() {
    const {
      advancesById,
      country,
      getAccounts,
      getAdvances,
      getProductsAndBfes,
      getOpportunities,
      getStipulations,
      match,
      opportunitiesById,
    } = this.props;
    const { opportunityId } = match.params;
    await getAccounts();
    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
    if (Object.entries(advancesById).length < 1) {
      await getAdvances();
    }
    await getStipulations(opportunityId, country);
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending, uploadDocumentsClearFailedFiles } = this.props;
    uploadDocumentsClearFailedFiles();
    clearUploadError();
    clearUploadPending();
  }

  uploadFiles = async (files) => {
    const { match, uploadDocuments } = this.props;
    const { opportunityId } = match.params;
    const payload = uploadUtils.buildMultipleFilesPayload(files, opportunityId, stipulationGroupName);

    this.setState({ submitted: true });
    uploadDocuments(payload);
  }

  shouldShowAlert = () => {
    const { uploadedFiles, submitted } = this.state;
    const errorsPresent = uploadedFiles.find((file) => file.errors.length);
    return errorsPresent && submitted;
  };

  async redirectToSummaryConfirmation(shouldFulfillStipGroup = false) {
    const {
      country, push, match, fulfillStipulation, stipulations,
    } = this.props;
    const { opportunityId } = match.params;
    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };

    if (shouldFulfillStipGroup) {
      const stipulation = stipulations.find(({ stipulationMap: { name } }) => name === stipulationGroupName);

      if (!stipulation) {
        return undefined;
      }

      await fulfillStipulation(opportunityId, stipulation.id);
      const { fulfillStipulationResult } = this.props;
      if (fulfillStipulationResult.error && !fulfillStipulationResult.pending) {
        return undefined;
      }
    }

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      uploadPending, uploadError, response, failedFiles, opportunitiesById, match,
    } = this.props;
    const { opportunityId } = match.params;
    const { uploadedFiles, submitted } = this.state;

    if (uploadPending === false && !uploadError && !failedFiles.length) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    const showServerError = uploadPending === false && !!uploadError && submitted;

    if (uploadError) {
      log.error(`response: ${response} uploadError: ${uploadError}`);
    }

    const hasFailedUploads = failedFiles.length > 0;
    let failedUploadsMessageElement;
    if (hasFailedUploads) {
      failedUploadsMessageElement = (
        <>
          <p>We failed to upload the following file(s) due to the reason(s) provided:</p>
          <ul>
            {failedFiles.map((file) => (
              <li key={file.filename}>
                {file.filename}
                :
                {' '}
                {file.failureReason}
              </li>
            ))}
          </ul>
        </>
      );
    }

    const allowedFileExtensions = [
      ...allowedFileTypes.csv,
      ...allowedFileTypes.images,
      ...allowedFileTypes.pdf,
      ...allowedFileTypes.xlsx,
      ...allowedFileTypes.docx,
    ];

    const { loanVersion } = opportunitiesById?.[opportunityId] || {};
    let content;
    if (['21PPPLoan', '21SDPPP'].includes(loanVersion)) {
      const pageTitle = 'Supporting Payroll Documents';
      const helperText = 'Upload your supporting payroll documents';

      content = (
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StandardLayout
            contentElement={(
              <div>
                <PageTitle text={pageTitle} />
                <PayrollVerificationContent className="payroll-verification" opportunityId={opportunityId} />
                {showServerError && (
                  <Alert
                    message={errors.technicalIssue}
                    alertType={AlertTypes.critical}
                  />
                )}
                {this.shouldShowAlert() && !hasFailedUploads && !showServerError && (
                  <Alert
                    message={errors.uploads.uploadAllMessage}
                    alertType={AlertTypes.critical}
                  />
                )}
                {hasFailedUploads && (
                  <Alert
                    message={failedUploadsMessageElement}
                    alertType={AlertTypes.critical}
                  />
                )}
                <UploadDocumentsComponent
                  allowedFileExtensions={allowedFileExtensions}
                  maximumPerFileSizeInBytes={allowedSingleFileSize}
                  maximumTotalSizeInBytes={allowedSingleFileSize}
                  helperText={helperText}
                  submitFunc={this.uploadFiles}
                  customClassName="increased-width"
                />
                {uploadedFiles.length > 0 && (
                  <div>
                    <h1>Files uploaded:</h1>
                    <ul>
                      {uploadedFiles.map((file) => <li key={file.name}>{file.name}</li>)}
                    </ul>
                  </div>
                )}
              </div>
            )}
            sidebarElement={<></>}
          />
        </div>
      );
    }
    else {
      const pageTitle = 'Payroll Verification';
      const description = 'In order to finalize your loan and loan amount, please '
        + 'provide the necessary details on your payroll and provide the requested supporting documents.';
      const uploadDocuments = 'Please upload the following documents below:';
      const questionTwo = '2. Your supporting documents for the entire period specified on the Average Monthly Payroll Calculator.';
      const selfEmployedDescription = 'Self-employed applicants must provide their 2019 1099-MISC and 1040 Schedule C forms.';
      const checkBoxTextMessage = 'I have provided all my information for this item.';
      content = (
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StandardLayout
            contentElement={(
              <div>
                <PageTitle text={pageTitle} />
                <div className="secondary-gray margin-bottom-sm">{description}</div>
                <div className="secondary-gray margin-bottom-sm"><strong>{uploadDocuments}</strong></div>
                <DownloadPayrollCalculator />
                <div className="secondary-gray margin-bottom-sm">{questionTwo}</div>
                <div className="secondary-gray margin-bottom-sm">{selfEmployedDescription}</div>
                {showServerError && (
                  <Alert
                    message={errors.technicalIssue}
                    alertType={AlertTypes.critical}
                  />
                )}
                {this.shouldShowAlert() && !hasFailedUploads && !showServerError && (
                  <Alert
                    message={errors.uploads.uploadAllMessage}
                    alertType={AlertTypes.critical}
                  />
                )}
                {hasFailedUploads && (
                  <Alert
                    message={failedUploadsMessageElement}
                    alertType={AlertTypes.critical}
                  />
                )}
                <UploadDocumentsComponent
                  allowedFileExtensions={allowedFileExtensions}
                  maximumPerFileSizeInBytes={allowedSingleFileSize}
                  maximumTotalSizeInBytes={allowedSingleFileSize}
                  submitFunc={this.uploadFiles}
                  checkboxText={checkBoxTextMessage}
                  customClassName="increased-width"
                />
                {uploadedFiles.length > 0 && (
                  <div>
                    <h1>Files uploaded:</h1>
                    <ul>
                      {uploadedFiles.map((file) => <li key={file.name}>{file.name}</li>)}
                    </ul>
                  </div>
                )}
              </div>
            )}
            sidebarElement={<></>}
          />
        </div>
      );
    }
    return content;
  }
}

function mapStateToProps(state) {
  return {
    advancesById: state.entities.advances?.byId,
    bfes: state.accountItems.bfes,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
    response: state.uploadDocument.response,
    failedFiles: state.uploadDocument.failedFiles,
    fulfillStipulationResult: state.fulfillStipulation,
    stipulations: state.stipulations.allStipulations,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    fulfillStipulation: fulfillStipulationAction,
    getAccounts: getAccountsAction,
    getAdvances: getAdvancesAction,
    getOpportunities: getOpportunitiesAction,
    getProductsAndBfes: getProductsAndBfesAction,
    getStipulations: getStipulationsAction,
    uploadDocuments: uploadDocumentsAction,
    uploadDocumentsClearFailedFiles: uploadDocumentsClearFailedFilesAction,
  },
)(SecurePage(PayrollVerificationPage));
