import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BodyText, Button, HeadingText } from '@swift-paypal/pp-react';
import { useRedirect, useShallowSelector } from '../../../../hooks';
import { setActiveAdvanceId, setActiveForgivenessId } from '../../../../redux/actions/active-ids/active-ids-actions';
import routes from '../../../../routes';
import { ForYouCard } from '../../../features';
import { forgivenessStageMessagingSelector } from './forgiveness-stage-messaging-selectors';
import { retrieveApplicationLabel, retrieveTileMessaging } from './forgiveness-stage-messaging-utils';
import { getLoanForgivenessAction, resumeLoanForgivenessAppErrorAction } from '../../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import loanForgivenessService from '../../../../services/loanForgivness.service';
import './forgiveness-stage-messaging.less';

const ForgivenessStageMessaging = ({ advanceId, forgivenessId }) => {
  const dispatch = useDispatch();
  const redirectCallback = useRedirect();
  const {
    apiUrl,
    country,
    forgivenessById,
    portalRedesignEnabled,
  } = useShallowSelector(forgivenessStageMessagingSelector);

  const { applicationVersionSelected, stage, loanInformation } = forgivenessById?.[forgivenessId] || {};

  const conditionalMap = {
    loanVersion: loanInformation.loanVersion,
    applicationVersionSelected,
  };

  const {
    actionRoute,
    actionText,
    message,
    title,
  } = retrieveTileMessaging(stage, conditionalMap);

  const stageAction = async () => {
    if (stage === 'Expired') {
      try {
        await loanForgivenessService.resumeLoanForgivenessApp(apiUrl, forgivenessId);
        dispatch(getLoanForgivenessAction([advanceId]));
      }
      catch (error) {
        dispatch(resumeLoanForgivenessAppErrorAction(true));
      }
    }
    else {
      dispatch(setActiveAdvanceId(advanceId));
      dispatch(setActiveForgivenessId(forgivenessId));
      redirectCallback(routes[actionRoute].path, { country });
    }
  };

  if (!portalRedesignEnabled) {
    return (
      <div className="forgiveness-stage-messaging">
        <HeadingText size="sm">
          {title}
        </HeadingText>
        <BodyText as="p">
          {message}
        </BodyText>
        <Button className="forgiveness-stage-messaging__button" onClick={stageAction}>
          {actionText}
        </Button>
      </div>
    );
  }

  return (
    <ForYouCard
      applicationLabel={retrieveApplicationLabel(loanInformation.loanVersion)}
      callToActionFunction={stageAction}
      callToActionText={actionText}
      cardTitle={title}
      description={message}
    />
  );
};

ForgivenessStageMessaging.propTypes = {
  advanceId: PropTypes.string.isRequired,
  forgivenessId: PropTypes.string,
};

ForgivenessStageMessaging.defaultProps = {
  forgivenessId: undefined,
};

export default ForgivenessStageMessaging;
