import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import AnonymousPage from '../hocs/anonymous-page';
import SpinnerStandaloneComponent from '../components/common/spinner-standalone/spinner-standalone';
import { confirmPaypalAccountActionMigratedFlow } from '../redux/actions/auth/auth-actions';
import { createLoginPageMessageAction } from '../redux/actions/login/login-page-actions';
import constants from '../constants';
import { AlertTypes } from '../components/common/alert';
import displayNames from '../constants/displayNames';
import migratedFlowConstant from '../constants/migratedFlowConstant';

import routes from '../routes';
import resolve from '../services/route.service';
import { getCookie } from '../services/window.service';

class ConfirmPaypalAccountMigratedFlowPage extends Component {
  static displayName = displayNames.ConfirmPaypalAccountMigratedFlowPage;

  static propTypes = {
    confirmPaypalAccount: PropTypes.func.isRequired,
    country: PropTypes.string,
    createLoginPageMessage: PropTypes.func.isRequired,
    token: PropTypes.string,
    customerSupportPhone: PropTypes.string,
    history: RouterPropTypes.history.isRequired,
  };

  static defaultProps = {
    country: undefined,
    token: undefined,
    customerSupportPhone: '',
  };

  componentDidMount() {
    const {
      confirmPaypalAccount,
      country,
      createLoginPageMessage,
      token,
      customerSupportPhone,
      history,
    } = this.props;

    const cookieToken = token ?? getCookie('accountToken');
    if (cookieToken) {
      confirmPaypalAccount(cookieToken, country);
    }
    else {
      createLoginPageMessage(constants.errors.confirmPaypalAccount(customerSupportPhone), AlertTypes.critical);
      history.push(resolve(routes.login.path, { country, version: migratedFlowConstant.URL_APPEND }));
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Confirm PayPal Account</title>
        </Helmet>
        <SpinnerStandaloneComponent />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ customerSupportPhone: state.config.customerSupportPhone });

export default connect(mapStateToProps,
  {
    confirmPaypalAccount: confirmPaypalAccountActionMigratedFlow,
    createLoginPageMessage: createLoginPageMessageAction,
  })(AnonymousPage(ConfirmPaypalAccountMigratedFlowPage));
