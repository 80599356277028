import { BodyText } from '@swift-paypal/pp-react';
import React from 'react';
import PropTypes from 'prop-types';
import { ppp21LoanVersions } from '../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';
import './body-text.less';

const RentLeaseBodyText = ({ loanVersion }) => (
  <div className="body-text">
    <BodyText as="p">
      Business rent/lease documents are required if
      {ppp21LoanVersions.includes(loanVersion)
        && ' such costs are'}
      {' '}
      included in your loan forgiveness request. If not, please see the check box below.
    </BodyText>
    <BodyText as="p">
      Upload supporting documents to verify your business rent/lease amounts and eligible
      payments.
    </BodyText>
    <BodyText as="p">
      Here&apos;s what you need to provide:
    </BodyText>
    <ul className="forgivenessListItems">
      <BodyText as="li">
        A copy of your
        {' '}
        <strong>current business lease agreement and receipts or cancelled checks</strong>
        {' '}
        verifying eligible payments paid or incurred during your selected covered period, or
      </BodyText>
      <BodyText as="li">
        <strong>Lessor account statements</strong>
        {' '}
        provided by the landlord from Feb 2020 and the months of your selected covered period
        through 1 month after the end of the covered period verifying eligible payments.
      </BodyText>
    </ul>
  </div>
);

RentLeaseBodyText.propTypes = {
  loanVersion: PropTypes.string.isRequired,
};

export default RentLeaseBodyText;
