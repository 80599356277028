import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field } from 'redux-form';
import {
  BodyText, Col, Row, TextInput,
} from '@swift-paypal/pp-react';
import { coveredPeriodEndDateSelector, useShallowSelector } from './covered-period-end-date-selectors';
import { getDateFieldAttributesByDevice } from '../../../../../lib/forms/date-field-utils';
import { FieldNames } from '../../../constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const CoveredPeriodEndDate = ({ submitted }) => {
  const {
    dateFirstWireIssued,
    error,
    touched,
    value,
  } = useShallowSelector(coveredPeriodEndDateSelector);
  const [dynamicAttributes, setDynamicAttributes] = useState({});

  useEffect(() => {
    if (dateFirstWireIssued) {
      setDynamicAttributes(getDateFieldAttributesByDevice(dateFirstWireIssued));
    }
  }, [dateFirstWireIssued]);

  if (!dateFirstWireIssued) {
    return null;
  }

  return (
    <Row>
      <Col form="full">
        <BodyText as="label" htmlFor={FieldNames.StandardCoveredPeriodEndDate}>
          Select the End Date for your covered period starting on
          {` ${moment(dateFirstWireIssued).format('MM/DD/YYYY')}`}
        </BodyText>
        <Field
          component={MappedInput}
          errorText={submitted || touched ? error : undefined}
          key="standard-covered-period-end-date"
          name={FieldNames.StandardCoveredPeriodEndDate}
          value={value}
          placeholder="mm/dd/yyyy"
          {...dynamicAttributes}
        />
      </Col>
    </Row>
  );
};

CoveredPeriodEndDate.propTypes = {
  submitted: PropTypes.bool.isRequired,
};

export default memo(CoveredPeriodEndDate);
