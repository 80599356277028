import { reset } from 'redux-form';
import caseService from '../../../services/case.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './contact-us-action-types';

export const getCaseTypesAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CASE_TYPES_REQUEST_PENDING,
    complete: actions.CASE_TYPES_RECEIVED,
    error: actions.CASE_TYPES_REQUEST_ERROR,
  },
  () => (dispatch, getState) => caseService.getCaseTypes(getState().config.apiUrl),
);

export const resetForm = () => async (dispatch) => {
  dispatch(reset('contact_us'));
};

export const clearState = () => async (dispatch) => {
  dispatch({ type: actions.CONTACT_US_CLEAR_STATE });
};

export const contactUsResetCompleteAction = () => async (dispatch) => {
  dispatch({ type: actions.CONTACT_US_RESET_COMPLETE });
};

export const submitCase = asyncAuthorizedActionCreator(
  {
    pending: actions.CASE_SUBMIT_REQUEST_PENDING,
    complete: actions.CASE_SUBMIT_RECEIVED,
    error: actions.CASE_SUBMIT_REQUEST_ERROR,
  },
  (accountId, caseType, message) => (dispatch, getState) => {
    const { config } = getState();
    return caseService.submitCase(config.apiUrl, accountId, caseType, message);
  },
);
