import React from 'react';
import PropTypes from 'prop-types';

const DocumentErrors = ({ errors }) => (
  <>
    {errors && errors.length
      && (
      <div className="display-flex margin-top-sm error-color">
        <div>
          <span className="vx_icon vx_icon-critical-xsmall" />
        </div>
        <div>
          {errors.map((error) => (
            <div key={error}>
              <span className="vx_text-body-sm_medium">{error.message}</span>
            </div>
          ))}
        </div>
      </div>
      )}
  </>
);

DocumentErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object),
};

DocumentErrors.defaultProps = {
  errors: undefined,
};

export default DocumentErrors;
