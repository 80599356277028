import React, { Component } from 'react';
import { push as pushAction } from 'connected-react-router';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import _, { get } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { Tooltip } from '@swift-paypal/pp-react';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import OwnershipComponent from '../../components/ownership/ownership';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import constants from '../../constants';
import {
  clearSelectedOpportunityAction,
  getOwnersAction,
  getAttestationAction,
  getAcceptanceAction,
  getTermsAndConditionsAction,
  saveBusinessRepresentative as saveBusinessRepresentativeAction,
  setControllingManagerAction,
  saveAdditionalSignersAction,
  getPossibleSignersAction,
  clearAdditionalSignersFulfilledAction,
} from '../../redux/actions/application/ownership/ownership-actions';
import getStipulationsAction from '../../redux/actions/stipulations/stipulations-actions';
import AdditionalSignersFormComponent from '../../components/ownership/additional-signers';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

const beneficialOwnerStipulationName = 'Beneficial Owner Information';
const controllingManagerStipulationName = 'Controlling manager Information';
const additionalSignerStipulationName = 'Additional Signer Information';

class BusinessOwnershipPage extends Component {
  static displayName = displayNames.BusinessOwnershipPage;

  static propTypes = {
    acceptance: PropTypes.arrayOf(PropTypes.object),
    attestation: PropTypes.shape({
      id: PropTypes.string,
    }),
    canStartAdditionalSigners: PropTypes.bool,
    clearAdditionalSignersFulfilled: PropTypes.func.isRequired,
    clearSelectedOpportunity: PropTypes.func.isRequired,
    country: PropTypes.string,
    getAcceptance: PropTypes.func.isRequired,
    getAttestation: PropTypes.func.isRequired,
    getOwners: PropTypes.func.isRequired,
    getPossibleSigners: PropTypes.func.isRequired,
    getStipulations: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getTermsAndConditions: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    opportunityId: PropTypes.string,
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
    })),
    owners: PropTypes.arrayOf(PropTypes.object),
    ownershipError: PropTypes.bool,
    push: PropTypes.func.isRequired,
    saveAdditionalSigners: PropTypes.func.isRequired,
    saveBusinessRepresentative: PropTypes.func.isRequired,
    setControllingManager: PropTypes.func.isRequired,
    termsAndConditions: PropTypes.shape({
      id: PropTypes.string,
    }),
    stipulations: PropTypes.shape({
      allStipulations: PropTypes.arrayOf(PropTypes.object),
    }),
  };

  static defaultProps = {
    acceptance: undefined,
    attestation: undefined,
    canStartAdditionalSigners: false,
    country: undefined,
    opportunityId: undefined,
    opportunitiesById: {},
    owners: undefined,
    ownershipError: undefined,
    termsAndConditions: undefined,
    stipulations: undefined,
  };

  async componentDidMount() {
    const {
      country,
      getProductsAndBfes,
      getOwners,
      getAttestation,
      getAcceptance,
      getStipulations,
      getTermsAndConditions,
      getOpportunities,
      opportunitiesById,
      opportunityId,
    } = this.props;

    getTermsAndConditions(country);

    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
    await getOwners(opportunityId);
    getAttestation();
    getAcceptance();
    await getStipulations(opportunityId, country);
  }

  componentWillUnmount() {
    const {
      clearSelectedOpportunity,
      clearAdditionalSignersFulfilled,
    } = this.props;
    clearSelectedOpportunity();
    clearAdditionalSignersFulfilled();
  }

  setControllingManager = (controllingManager) => {
    const { setControllingManager } = this.props;
    setControllingManager(controllingManager);
  };

  saveBusinessRepresentative = async ({
    contacts,
    saveAcceptance = false,
    saveAttestation = false,
  }) => {
    const {
      attestation,
      country,
      location,
      opportunityId,
      saveBusinessRepresentative,
      termsAndConditions,
    } = this.props;
    const applicant = _.head(contacts.filter((contact) => contact.isApplicant));

    await saveBusinessRepresentative({
      opportunityId,
      contacts,
      country,
      location,
      saveAcceptance,
      saveAttestation,
      accountId: applicant.accountId,
      documentId: termsAndConditions.id,
      attestationId: attestation.id,
    });
  };

  saveAdditionalSigners = ({ contacts }) => {
    const {
      opportunityId,
      country,
      saveAdditionalSigners,
    } = this.props;
    const applicant = _.head(contacts.filter((x) => x.isApplicant));

    saveAdditionalSigners(contacts, applicant.accountId, opportunityId, country);
  };

  render() {
    const {
      acceptance,
      attestation,
      canStartAdditionalSigners,
      country,
      getPossibleSigners,
      owners,
      ownershipError,
      termsAndConditions,
      stipulations,
      opportunitiesById,
    } = this.props;
    if (!owners) {
      return null;
    }

    const { opportunityId, push } = this.props;

    const account = get(this.props, 'accounts[0].inFlightOpportunities[0]');
    const ownershipFulfilled = get(this.props, 'ownershipFulfilled', false);
    const additionalSignersFulfilled = get(this.props, 'additionalSignersFulfilled', false);

    if (!account) {
      return null;
    }

    const isSbaLoan = account?.opportunity?.type === 'SBA';

    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;
    const showPPPLoan = ['21PPPLoan', '21SDPPP', '20PPPLoan'].includes(loanVersion);

    const stips = stipulations.allStipulations;

    if (!stips) {
      return null;
    }

    const beneficialOwnerStip = stips.find((stip) => stip.stipulationMap.name === beneficialOwnerStipulationName);
    const controllingManagerStip = stips.find(
      (stip) => stip.stipulationMap.name === controllingManagerStipulationName,
    );
    const additionalSignerStip = stips.find(
      (stip) => stip.stipulationMap.name === additionalSignerStipulationName,
    );

    const showBeneficialOwner = beneficialOwnerStip && !beneficialOwnerStip.fulfilled && !ownershipFulfilled;
    const showControllingManager = controllingManagerStip && !controllingManagerStip.fulfilled && !ownershipFulfilled;
    const showAdditionalSigner = additionalSignerStip && !additionalSignerStip.fulfilled
      && ownershipFulfilled && !additionalSignersFulfilled;
    const successStipulationGroupName = isSbaLoan ? 'Beneficial Owner Information' : 'BusinessOwnership';

    if (!showBeneficialOwner && !showControllingManager && !showAdditionalSigner) {
      const RoutePayload = {
        country,
        successStipulationGroupName,
      };

      heapService.markUserStipulationAsCompleted(opportunityId, successStipulationGroupName);
      push(resolve(routes.summary.path, RoutePayload));
    }

    let pageTitle;
    if (ownershipFulfilled) {
      pageTitle = 'Loan signers';
    }
    else if (isSbaLoan && showPPPLoan) {
      pageTitle = (
        <>
          Verify your additional business owners
        </>
      );
    }
    else {
      pageTitle = (
        <>
          Verify your business representatives
          <Tooltip
            description={constants.tooltips.ownershipTip}
            className="pp-react__tooltip--inline"
          />
        </>
      );
    }

    if (showAdditionalSigner && !canStartAdditionalSigners) {
      getPossibleSigners(opportunityId);
    }

    return (
      <>
        <Helmet>
          <title>{ isSbaLoan && showPPPLoan ? 'Beneficial Owner Information' : 'Business Ownership'}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <PageTitle bold text={pageTitle} />
              <>
                {owners && attestation && acceptance
                && (
                  <>
                    {(showBeneficialOwner || showControllingManager)
                    && (
                    <OwnershipComponent
                      attestation={attestation}
                      acceptance={acceptance}
                      ownershipError={ownershipError}
                      termsAndConditions={termsAndConditions}
                      setControllingManager={this.setControllingManager}
                      saveBusinessRepresentative={this.saveBusinessRepresentative}
                      owners={owners}
                      isSbaLoan={isSbaLoan}
                      showPPPLoan={showPPPLoan}
                    />
                    )}
                    {showAdditionalSigner && canStartAdditionalSigners
                    && (
                    <AdditionalSignersFormComponent
                      owners={owners}
                      initialValues={{
                        owners: owners.filter((x) => !(x.isApplicant || x.percentOwned < 1)),
                      }}
                      saveAdditionalSigners={this.saveAdditionalSigners}
                      ownershipError={ownershipError}
                      isSbaLoan={isSbaLoan}
                    />
                    )}
                  </>
                )}
              </>
            </div>
          )}
          sidebarElement={<></>}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    acceptance: state.ownership.acceptance,
    accounts: state.accountItems.accounts,
    attestation: state.ownership.attestation,
    bfes: state.accountItems.bfes,
    opportunitiesById: state.entities.opportunities?.byId,
    owners: state.ownership.selectedOpportunityOwners,
    ownershipError: state.ownership.error,
    ownershipFulfilled: state.ownership.ownershipFulfilled,
    additionalSignersFulfilled: state.ownership.additionalSignersFulfilled,
    termsAndConditions: state.ownership.termsAndConditions,
    accountsPending: state.accountItems.accountsPending,
    notificationsPending: state.notifications.notificationsPending,
    ownershipReceived: state.ownership.ownershipReceived,
    canStartAdditionalSigners: state.ownership.canStartAdditionalSigners,
    stipulations: state.stipulations,
  };
}

export default connect(mapStateToProps, {
  clearSelectedOpportunity: clearSelectedOpportunityAction,
  getProductsAndBfes: getProductsAndBfesAction,
  getOwners: getOwnersAction,
  getAttestation: getAttestationAction,
  getAcceptance: getAcceptanceAction,
  getStipulations: getStipulationsAction,
  getTermsAndConditions: getTermsAndConditionsAction,
  getOpportunities: getOpportunitiesAction,
  setControllingManager: setControllingManagerAction,
  saveBusinessRepresentative: saveBusinessRepresentativeAction,
  saveAdditionalSigners: saveAdditionalSignersAction,
  clearAdditionalSignersFulfilled: clearAdditionalSignersFulfilledAction,
  getPossibleSigners: getPossibleSignersAction,
  push: pushAction,
})(SecurePage(BusinessOwnershipPage));
