import * as actions from '../actions/privacy/privacy-action-types';

const policy = (state = {}, action) => {
  if (action.type === actions.PRIVACY_POLICY_REQUEST_RECEIVED) {
    return { ...state, error: null, privacyPolicy: action.payload };
  }
  return state;
};

export default policy;
