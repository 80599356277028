import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  BodyText, Col, Container, Row,
} from '@swift-paypal/pp-react';
import { compose } from 'redux';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import routes from '../../routes';
import uploadUtils from '../../lib/document-upload-utils';
import { allowedFileTypes, allowedSingleFileSize } from '../../constants/upload-document';
import PageTitle from '../common/page-title';
import UploadDocumentsComponent from '../common/upload-documents/upload-documents';
import getStipulationsAction, { fulfillStipulationAction } from '../../redux/actions/stipulations/stipulations-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadDocumentsAction,
  uploadDocumentsClearFailedFilesAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import FailedUploadsMessage from './failed-uploads-message';
import ForMore2483SDInformationLink from './for-more-2483sd-information';
import './reduction-in-gross-receipts-documents.less';
import Country from '../../hocs/country';

const ReductionInGrossReceiptsDocuments = ({
  country,
  clearUploadError,
  match: {
    params,
  },
  fulfillStipulation,
  getStipulations,
  history: {
    push,
  },
  stipulations,
  uploadDocuments,
  uploadDocumentsClearFailedFiles,
  uploadDocumentData: {
    failedFiles,
    uploadedFiles,
    uploadPending,
  },
}) => {
  const [submitted, setSubmitted] = useState(false);
  const { opportunityId } = params;

  const handleSubmitFunc = async (files) => {
    const payload = uploadUtils.buildMultipleFilesPayload(files, opportunityId, documentType);

    await uploadDocuments(payload);
    setSubmitted(true);
  };

  const redirectToSummaryOnSuccess = async () => {
    clearUploadError();
    uploadDocumentsClearFailedFiles();

    const stipulation = stipulations.find(({ stipulationMap: { name } }) => name === groupName);
    if (!stipulation) {
      return;
    }

    await fulfillStipulation(opportunityId, stipulation.id);
    heapService.markUserStipulationAsCompleted(opportunityId, groupName);
    push(resolve(routes.summary.path, { country: params.country }));
  };

  useEffect(() => {
    if (!country || !opportunityId) {
      return;
    }

    const fetch = async () => {
      await getStipulations(opportunityId, country);
    };

    fetch();
  }, [country, opportunityId]);

  useEffect(() => {
    if (!uploadPending && submitted && !(failedFiles?.length > 0) && uploadedFiles?.length > 0) {
      redirectToSummaryOnSuccess();
    }
  }, [uploadPending, submitted]);

  return (
    <Container className="reduction-in-gross-receipts-documents">
      <PageTitle text={groupName} />
      {descriptionSections.map(([sectionKey, sectionText]) => (
        <React.Fragment key={`description-${sectionKey}`}>
          <BodyText>
            {sectionText}
          </BodyText>
          <br />
        </React.Fragment>
      ))}
      <ForMore2483SDInformationLink />
      { submitted && (
        <>
          <br />
          <FailedUploadsMessage
            allFailed={!(uploadedFiles?.length > 0)}
            failedFiles={failedFiles}
          />
        </>
      )}
      <Row align="center">
        <Col xs={10}>
          <UploadDocumentsComponent
            allowedFileExtensions={allowedFileExtensions}
            customClassName="increased-width"
            helperText={helperText}
            maximumPerFileSizeInBytes={allowedSingleFileSize}
            maximumTotalSizeInBytes={allowedSingleFileSize}
            submitFunc={handleSubmitFunc}
          />
        </Col>
      </Row>
    </Container>
  );
};

const allowedFileExtensions = [
  ...allowedFileTypes.csv,
  ...allowedFileTypes.images,
  ...allowedFileTypes.pdf,
  ...allowedFileTypes.xlsx,
  ...allowedFileTypes.docx,
];

const descriptionSections = [
  ['section1', 'Upload the documents that can support the 25% or more reduction in Gross Receipts that you had claimed in your PPP loan application.'],
  ['section2', 'Applicants for loans of more than $150,000 must provide appropriate documentation to support the 25% or more gross revenue reduction between your selected 2020 Quarter and your 2019 Reference Quarter, or if you so elected, between Calendar Year 2020 and Calendar Year 2019. Or If you were not in business during 2019, but were in operation on February 15, 2020, documentation that demonstrates at least a 25 percent reduction from your gross receipts during the second, third, or fourth quarter of 2020 as compared to the first quarter of 2020.'],
  ['section3', 'If you elected an annual 2019 to 2020 gross receipts reduction comparison, you can submit copies of the applicant’s annual tax forms substantiating the gross receipts decline.'],
  ['section4', 'If you elected a 2019 quarter to corresponding 2020 quarter gross receipts reduction comparison, you must provide documentation substantiating the decline in gross receipts. The documentation must identify the applicable 2020 quarter, the 2019 reference quarter, state the gross receipts amounts for both quarters, and support the amounts provided.'],
  ['section5', 'If you were not in business during 2019, but were in operation on February 15, 2020, you must also provide documentation substantiating the decline in gross receipts. The documentation must identify the applicable 2020 quarter you selected for comparison purposes, the first quarter 2020 reference quarter, state the gross receipts amounts for both quarters, and support the amounts provided.'],
];

const groupName = 'Reduction in Gross Receipts Documents';
const documentType = 'Gross Receipts';
const helperText = 'Upload your Gross Receipts Documents';

ReductionInGrossReceiptsDocuments.propTypes = {
  clearUploadError: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  fulfillStipulation: PropTypes.func.isRequired,
  getStipulations: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  uploadDocumentsClearFailedFiles: PropTypes.func.isRequired,
  uploadDocumentData: PropTypes.shape({
    failedFiles: PropTypes.arrayOf(PropTypes.object),
    uploadedFiles: PropTypes.arrayOf(PropTypes.object),
    uploadPending: PropTypes.bool,
  }).isRequired,
  stipulations: PropTypes.arrayOf(PropTypes.object),
};

ReductionInGrossReceiptsDocuments.defaultProps = {
  stipulations: [],
};

const mapStateToProps = (state) => ({
  uploadDocumentData: state.uploadDocument,
  stipulations: state.stipulations.allStipulations,
});

const mapDispatchToProps = {
  clearUploadError: clearUploadErrorAction,
  clearUploadPending: clearUploadPendingAction,
  fulfillStipulation: fulfillStipulationAction,
  getOpportunities: getOpportunitiesAction,
  getStipulations: getStipulationsAction,
  uploadDocuments: uploadDocumentsAction,
  uploadDocumentsClearFailedFiles: uploadDocumentsClearFailedFilesAction,
};

export default compose(
  Country,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ReductionInGrossReceiptsDocuments);
