import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';

async function getGuarantyClaim(apiUrl, advanceId) {
  const res = await axios.get(`${apiUrl}/guaranty-claims/${advanceId}`, defaultRequestOptions());
  return res.data;
}

export default {
  getGuarantyClaim,
};
