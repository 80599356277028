import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import MobileHeader from '../components/header/mobile-header';
import AccountInfoBanner from '../components/account-info-banner/account-info-banner';
import UploadSpinnerModel from '../components/upload-document/upload-spinner/upload-spinner';
import { scrollToTop } from '../services/window.service';
import fullStoryService from '../lib/full-story';
import Spinner from '../components/common/spinner';
import getDisplayName from '../lib/get-display-name';
import '../static/styles.less';

const accountBannerPages = [
  'forgiveness-application',
  'forgiveness-detail',
  'forgiveness-summary',
  'questionnaire',
  'summary',
];

const oneTimePaymentPages = [
  'one-time-payment',
];

const summaryStylePages = [
  'covered-costs-documents',
  'lf/sba-3508s',
  'forgiveness-reduction-in-gross-receipts',
  'forgiveness-reduction-in-gross-receipts-documents',
  'forgiveness-application',
  'forgiveness-detail',
  'forgiveness-summary',
  'questionnaire',
];

const fullStory = fullStoryService();

// Wraps a global layout around a page
const wrapWithLayout = (Page) => {
  class WrapWithLayout extends Component {
    async componentDidMount() {
      const {
        fullstoryDebug,
        fullstoryHost,
        fullstoryOrgId,
        fullstoryScript,
      } = this.props;

      scrollToTop();

      await import('@swift-paypal/vxpattern-lib-paypal/dist/components/core/core');
      await import('@swift-paypal/vxpattern-lib-paypal/dist/components/inputs-selects/floating-label');
      fullStory.startRecording(fullstoryDebug, fullstoryHost, fullstoryOrgId, fullstoryScript);
    }

    render() {
      const {
        accounts,
        location: {
          pathname,
        },
        portalRedesignEnabled,
      } = this.props;
      const isMaintenance = pathname.includes('maintenance');
      if (!portalRedesignEnabled) {
        summaryStylePages.push('summary');
      }
      const isSummaryStylePage = summaryStylePages.some((pageName) => pathname.includes(pageName));
      const isAccountBannerPage = accountBannerPages.some((pageName) => pathname.includes(pageName));
      const isOneTimePaymentPage = oneTimePaymentPages.some((pageName) => pathname.includes(pageName));
      if (isOneTimePaymentPage) {
        fullStory.shutdown();
        fullStory.restart();
      }

      const portalContainerClass = 'vx_foreground-container portal-container '
        + `${isSummaryStylePage ? 'summary' : ''}`
        + `${isOneTimePaymentPage ? 'payment-bg' : ''}`;

      return (
        <UploadSpinnerModel>
          <Header />
          <div className={portalContainerClass}>
            <MobileHeader />
            <Spinner />
            <div className="vx_mainContent portal-content">
              <div className="banners">
                {isAccountBannerPage && (
                  <AccountInfoBanner accounts={accounts} />
                )}
              </div>
              <Page {...this.props} />
              {!isMaintenance && <Footer />}
            </div>
          </div>
        </UploadSpinnerModel>
      );
    }
  }

  WrapWithLayout.propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    bfes: PropTypes.arrayOf(PropTypes.object),
    country: PropTypes.string,
    fullstoryDebug: PropTypes.bool,
    fullstoryHost: PropTypes.string,
    fullstoryOrgId: PropTypes.string,
    fullstoryScript: PropTypes.string,
    location: ReactRouterPropTypes.location.isRequired,
    portalRedesignEnabled: PropTypes.bool.isRequired,
  };

  WrapWithLayout.defaultProps = {
    accounts: undefined,
    bfes: undefined,
    country: undefined,
    fullstoryDebug: false,
    fullstoryHost: undefined,
    fullstoryOrgId: undefined,
    fullstoryScript: undefined,
  };

  function mapStateToProps(state) {
    return {
      accounts: state.accountItems.accounts,
      country: state.country.country,
      fullstoryDebug: state.config.fullstoryDebug,
      fullstoryHost: state.config.fullstoryHost,
      fullstoryOrgId: state.config.fullstoryOrgId,
      fullstoryScript: state.config.fullstoryScript,
      location: state.router.location,
      portalRedesignEnabled: state.config.portalRedesignEnabled,
    };
  }

  WrapWithLayout.displayName = `WrapWithLayout(${getDisplayName(Page)})`;

  return connect(mapStateToProps)(WrapWithLayout);
};

export default wrapWithLayout;
