/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { ContextualAlert, Sheet } from '@swift-paypal/pp-react';
import RoutingNumberList from '../routing-number-list-component/routing-number-warning-component';

const RoutingNumberWarning = () => {
  const [modalState, changeModalState] = useState(false);
  const onClickHandler = () => changeModalState(true);
  const modalCloseHandler = () => changeModalState(false);
  const warningMessage = <>
    <div>
      <span> Please make sure that this is the correct ACH routing number</span>
      <span> Some banks have different routing numbers for wire transfers and ACH transactions &nbsp;</span>
      <span tabIndex="-1" role="button" style={{ color: '#0070ba', cursor: 'pointer' }} onClick={onClickHandler}>View routing numbers</span>
      <span>&nbsp; by your business account&apos;s state.</span>
    </div>
  </>;
  return (
    <div>
      <Sheet isOpen={modalState} onClose={modalCloseHandler}>
        <div>
          <RoutingNumberList />
        </div>
      </Sheet>
      <ContextualAlert type="warning">{warningMessage}</ContextualAlert>
    </div>

  );
};

export default RoutingNumberWarning;
