import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import {
  Button, Col, Container, Row,
} from '@swift-paypal/pp-react';
import { Alert } from '../../common/alert';
import Title from '../../common/title';
import floatingLabelField from '../../../lib/forms/floating-label-field';
import PasswordField from '../../../lib/forms/password/password-field';

const formName = 'reset-password';

export class ResetPasswordForm extends React.Component {
  static propTypes = {
    email: PropTypes.string,
    handleSubmit: PropTypes.func,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    securityQuestion: PropTypes.string.isRequired,
  };

  static defaultProps = {
    email: undefined,
    handleSubmit: undefined,
    messageBody: undefined,
    messageType: undefined,
  };

  static renderCustomTextField(name, labelText) {
    return (
      <Field
        name={name}
        manualSet
        label={labelText}
        component={floatingLabelField}
        noErrorClass="margin-bottom-sm"
        type="password"
        className="vx_form-control form-control do-not-record"
        validate={[required({ msg: 'Required' })]}
      />
    );
  }

  render() {
    const {
      handleSubmit, securityQuestion, messageBody, messageType, email,
    } = this.props;
    return (
      <div className="reset-password-form form">
        <Container>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={10}>
                {messageBody && messageType && <Alert message={messageBody} alertType={messageType} />}
                {<PasswordField name="password" label="New password" formName={formName} username={email} newPassword />}
                {(
                  <PasswordField
                    name="confirmPassword"
                    label="Confirm password"
                    formName={formName}
                    confirmationField="password"
                  />
                )}
                {<Title text={securityQuestion} />}
                {ResetPasswordForm.renderCustomTextField('answer', 'Answer')}
              </Col>
            </Row>
            <Row>
              <Col xs={8} sm={5} md={4}>
                <Button type="submit" className="pp-react__button--block">
                  Continue
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.resetToken.email,
});

export default compose(
  reduxForm({ form: formName }),
  connect(mapStateToProps),
)(ResetPasswordForm);
