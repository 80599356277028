const innerComponentNameRegex = /^[\w\d]*\((.*)\)$/;

export default function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || WrappedComponent.constructor.name || 'Component';
}

export function getInnerDisplayName(WrappedComponent) {
  let name = getDisplayName(WrappedComponent);
  while (name) {
    const match = name.match(innerComponentNameRegex);
    if (!match || match.length < 2) {
      break;
    }

    [, name] = match;
  }

  return name;
}
