import React, { useEffect, useState } from 'react';
import { push as pushAction } from 'connected-react-router';
import moment from 'moment';
import _ from 'lodash';
import {
  Container, Row, Col, HeadingText, BodyText, Button, Alert,
} from '@swift-paypal/pp-react';
import { useDispatch } from 'react-redux';
import TotalForgivenessAmountTable from './labels/total-forgiveness-amount/total-forgiveness-amount';
import { updateLoanForgivenessAction, clearUpdateLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { completeEntries } from './forgivenessCustomerSignoff.util';
import { componentSelector, useShallowSelector } from './forgivenessCustomerSignoff.selectors';
import util from '../../../util';
import errors from '../../../constants/errors';
import resolve from '../../../services/route.service';
import routes from '../../../routes';
import './forgivenessCustomerSignoff.less';

const ForgivenessCustomerSignoff = () => {
  const [state, setState] = useState({
    hasSubmissionError: false,
    isSubmitting: false,
  });
  const { hasSubmissionError, isSubmitting } = state;

  const dispatch = useDispatch();

  const {
    activeForgivenessId, country, loanForgivenessById, updateLoanForgiveness,
  } = useShallowSelector(componentSelector);
  useEffect(() => {
    if (hasSubmissionError) {
      setState({ ...state, isSubmitting: false });
    }
  }, [hasSubmissionError]);
  useEffect(() => {
    setState({ ...state, hasSubmissionError: !!updateLoanForgiveness?.error });
    if (!updateLoanForgiveness?.pending && updateLoanForgiveness?.success && isSubmitting) {
      dispatch(pushAction(resolve(routes['forgiveness-customer-signoff-completed'].path, { country })));
    }
  }, [updateLoanForgiveness]);

  const onSubmit = () => {
    dispatch(clearUpdateLoanForgivenessAction());
    setState({ ...state, hasSubmissionError: false });

    const data = {
      id: activeForgivenessId,
      customerSignoffTimestamp: moment().format(),
    };
    setState({ ...state, isSubmitting: true });
    dispatch(updateLoanForgivenessAction(data));
  };

  const forgivenessObject = loanForgivenessById[activeForgivenessId];
  if (!forgivenessObject) {
    return null;
  }
  const { customerSignoffTimestamp, applicationVersionSelected } = forgivenessObject;
  if (customerSignoffTimestamp) {
    dispatch(pushAction(resolve(routes['forgiveness-customer-signoff-completed'].path, { country })));
    return null;
  }
  const completeFields = Object.values(util.flattenObject(completeEntries));
  const tableValues = _.pick(forgivenessObject, completeFields);
  const is3508S = applicationVersionSelected === '3508S';
  const isEzApp = applicationVersionSelected === '3508EZ';
  let formName;
  if (is3508S) {
    formName = 'SBA Form 3508S';
  }
  else if (isEzApp) {
    formName = 'SBA Form 3508EZ';
  }
  else {
    formName = 'SBA Form 3508';
  }

  return (
    <Container className="forgiveness-customer-signoff">
      <Row>
        <Col>
          <HeadingText as="h2">
            Based on our review, we&apos;ll recommend
            <strong>{` ${util.getCurrency(forgivenessObject.actualForgivenessAmount)} `}</strong>
            in loan forgiveness to the SBA
          </HeadingText>
          <BodyText as="p">
            Once you agree to this amount, we&apos;ll email you an updated
            {` ${formName} `}
            for you to sign before we submit your forgiveness request to the SBA.
          </BodyText>
          <TotalForgivenessAmountTable tableValues={tableValues} isEzApp={isEzApp} is3508S={is3508S} />
          <BodyText as="p">
            The amount of actual loan forgiveness is subject to, and may change based on, SBA review.
            If you have any questions regarding the above, please contact your Loan Forgiveness Specialist.
          </BodyText>
        </Col>
      </Row>
      {hasSubmissionError && (
        <Row>
          <Col>
            <Alert type="error" className="submission-error-alert">
              {errors.requestFailure}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="agree-button">
          <Button onClick={onSubmit}>Agree to This Amount</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgivenessCustomerSignoff;
