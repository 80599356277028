import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

async function getLoans(apiUrl) {
  const res = await axios.get(`${apiUrl}/products/loans`, defaultRequestOptions());
  return res.data;
}
const debouncedGetLoans = asyncDebounce(getLoans);

async function getSelfServiceLoans(apiUrl) {
  const res = await axios.get(`${apiUrl}/products/selfserviceloans`, defaultRequestOptions());
  return res.data;
}
const debouncedGetSefServiceLoans = asyncDebounce(getSelfServiceLoans);

async function getLoanDetails(apiUrl, loans) {
  const promises = loans.map(async (loan) => {
    loan.detail = await getLoan(apiUrl, loan.loanId);
    return loan;
  });
  return Promise.all(promises);
}
const debouncedGetLoanDetails = asyncDebounce(getLoanDetails);

async function getLoanTransactions(apiUrl, loanId) {
  const res = await axios.get(`${apiUrl}/loans/${loanId}/transactions`, defaultRequestOptions());
  return res.data;
}
const debouncedGetLoanTransactions = asyncDebounce(getLoanTransactions);

async function getLoan(apiUrl, loanId) {
  const res = await axios.get(`${apiUrl}/loans/${loanId}`, defaultRequestOptions());
  return res.data;
}

export default {
  getLoan,
  getLoans: debouncedGetLoans,
  getLoanDetails: debouncedGetLoanDetails,
  getLoanTransactions: debouncedGetLoanTransactions,
  getSelfServiceLoans: debouncedGetSefServiceLoans,
};
