/* eslint-disable max-len */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { required, length, format } from 'redux-form-validators';
import { withRouter } from 'react-router-dom';
import {
  BodyText, Col, HeadingText, Row,
} from '@swift-paypal/pp-react';
import { sortBy } from 'lodash';
import { normalizeNumericTextField, normalizeRoutingNumber } from '../../lib/forms/normalizations';
import floatingLabelField from '../../lib/forms/floating-label-field';
import routingNumberField from '../../lib/forms/render-routing-number-field';
import constants from '../../constants';
import withUrlParams from '../../hocs/with-url-params';
import ChequeExample from '../../static/images/icons/Check-Example.svg';
import selectField from '../../lib/forms/select-field';
import states from '../../constants/states';

const { countryCodes } = constants;
const { errors } = constants;

const BankAccountFormV2 = (props) => {
  const {
    accountType,
    disabled,
    initialValues,
    institution,
    hasRouteNumberWarning,
    sameBankAccountFormOption,
  } = props;

  let { country } = props;
  country = country.toUpperCase();
  const isCanada = country === countryCodes.canada;
  const isPaypalAccount = (initialValues && initialValues.isPaypalAccount);
  const accountLabel = accountType === constants.bank.DEPOSIT ? 'Wire Transfer Number (deposit)' : 'ACH Routing number (payments)';
  const routingLabel = accountType === constants.bank.DEPOSIT ? 'We use this to locate your bank details for depositing the loan proceeds' : 'We use this to locate your bank details for making loan payments';
  const sameBankAccountOptionTurnedOn = !!(sameBankAccountFormOption === 'yes' && accountType === constants.bank.DEPOSIT);
  const sortedStates = sortBy(states, ['name']);
  const BusinessInfoDivider = () => (
    <>
      <br />
      <HeadingText size="sm">
        Confirm business information
      </HeadingText>
      <br />
      <BodyText as="label">
        Please ensure your business name and address matches your business bank records.
      </BodyText>
      <br />
      <br />
    </>
  );
  //
  return (
    <Row>
      <Col xs={12} sm={10}>
        <FormSection name={accountType}>
          {isCanada && (
            <>
              <Field
                name="transitNumber"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                label="Branch transit number"
                validate={disabled ? [] : [required({ msg: 'Required' }), length({ min: 5, max: 5, msg: errors.invalidBranchTransitNumber })]}
                normalize={normalizeNumericTextField}
                pattern="\d*"
                disabled={disabled}
              />
              <Field
                name="institutionNumber"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                label="Financial institution number"
                validate={disabled ? [] : [required({ msg: 'Required' }), length({ min: 3, max: 3, msg: errors.invalidFinancialInstitutionNumber })]}
                normalize={normalizeNumericTextField}
                pattern="\d*"
                disabled={disabled}
              />
            </>
          )}
          {!isCanada && !isPaypalAccount && (
            <>
              <Field
                name="routingNumber"
                manualSet
                className="form-control vx_form-control"
                component={routingNumberField}
                type="text"
                label={accountLabel}
                institution={disabled ? undefined : institution}
                validate={disabled ? [] : [required({ msg: 'Required' }), length({ min: 1, max: 9, msg: errors.invalidBankAccount })]}
                normalize={normalizeRoutingNumber}
                pattern="\d*"
                disabled={disabled}
                hasRouteNumberWarning={hasRouteNumberWarning}
              />
              <div id="routing-label" style={{ marginTop: '0.5rem' }}>
                <BodyText as="label">
                  {routingLabel}
                </BodyText>
              </div>
              {
                accountType === constants.bank.WITHDRAWAL && (
                <div id="check-image" style={{ marginTop: '1rem' }}>
                  <img src={ChequeExample} alt="cheque-example" className="cheque-icon" />
                </div>
                )
              }

              <br />
            </>
          )}
          {!isPaypalAccount && !isCanada && !sameBankAccountOptionTurnedOn && (
            <>
              <Field
                name="accountNumber"
                manualSet
                className="form-control vx_form-control do-not-record"
                component={floatingLabelField}
                type="text"
                maxLength="25"
                label={isCanada ? 'Financial institution account number' : 'Business Bank Account Number'}
                noErrorClass={accountType !== constants.bank.WITHDRAWAL ? 'margin-bottom-sm' : ''}
                validate={
                disabled ? [] : [required({ msg: 'Required' }),
                  length({
                    min: isCanada ? 1 : 4,
                    max: isCanada ? 12 : 17,
                    msg: isCanada ? errors.invalidFinancialInstitutionAccountNumber : errors.invalidBankAccount,
                  }),
                  // eslint-disable-next-line no-useless-escape
                  format({ without: /\b(\d)\1+\b/i, msg: errors.invalidBankAccountRepeating }),
                  format({ without: /(?:0123456789|123456789|987654321|0987654321)/i, msg: errors.invalidBankAccountSequential }),
                ]
              }
                normalize={normalizeNumericTextField}
                pattern="\d*"
                disabled={disabled}
              />
              <BusinessInfoDivider />
              <Field
                name="businessName"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                maxLength="150"
                label="Your Business Name"
                noErrorClass="margin-bottom-sm"
                validate={[required({ msg: 'Required' })]}
                disabled={disabled}
              />

              <Field
                name="businessAddressLine1"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                maxLength="255"
                label="Business Address Line 1"
                noErrorClass="margin-bottom-sm"
                validate={[required({ msg: 'Required' })]}
                disabled={disabled}
              />
              <Field
                name="businessAddressLine2"
                manualSet
                className="form-control vx_form-control"
                component={floatingLabelField}
                type="text"
                maxLength="255"
                label="Business Address Line 2"
                noErrorClass="margin-bottom-sm"
                validate={[required({ msg: 'Required' })]}
                disabled={disabled}
              />
              <Field
                name="state"
                id="state"
                label="State"
                component={selectField}
                className="vx_form-control form-control"
                noErrorClass="margin-bottom-sm"
                validate={[required({ msg: 'Required' })]}
              >
                <option value="" selected disabled hidden>State</option>
                {sortedStates.map(({ abbreviation, name }) => (<option key={abbreviation} value={abbreviation}>{name}</option>))}
              </Field>
              <Row style={{ marginTop: '15px' }}>
                <Col>
                  <Field
                    name="city"
                    manualSet
                    className="form-control vx_form-control"
                    component={floatingLabelField}
                    type="text"
                    maxLength="100"
                    label="City"
                    noErrorClass="margin-bottom-sm"
                    validate={[required({ msg: 'Required' })]}
                    disabled={disabled}
                  />
                </Col>
                <Col>
                  <Field
                    name="zip"
                    manualSet
                    className="form-control vx_form-control"
                    component={floatingLabelField}
                    type="text"
                    label="Zip"
                    noErrorClass="margin-bottom-sm"
                    pattern="(\d{5}([\-]\d{4})?)"
                    validate={
                        disabled ? [] : [required({ msg: 'Required' }),
                          length({
                            min: 5,
                            max: 5,
                            msg: 'Please enter a valid zip code',
                          })]
                      }
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </>
          )}
          {isPaypalAccount && (
            <Field
              name="paypalUsername"
              manualSet
              className="form-control vx_form-control do-not-record"
              component={floatingLabelField}
              type="text"
              maxLength="25"
              label="PayPal account"
              noErrorClass={accountType !== constants.bank.DEPOSIT ? 'margin-bottom-sm' : ''}
              disabled
            />
          )}
        </FormSection>
      </Col>
    </Row>
  );
};

BankAccountFormV2.propTypes = {
  accountType: PropTypes.string.isRequired,
  country: PropTypes.string,
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    isPaypalAccount: PropTypes.bool,
  }),
  institution: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  hasRouteNumberWarning: PropTypes.bool,
  sameBankAccountFormOption: PropTypes.string,
};

BankAccountFormV2.defaultProps = {
  country: undefined,
  disabled: false,
  initialValues: undefined,
  institution: undefined,
  hasRouteNumberWarning: false,
  sameBankAccountFormOption: undefined,
};

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(BankAccountFormV2);
