import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';

async function update(apiUrl, {
  id, businessName, firstName, lastName, phone, role,
}) {
  const url = `${apiUrl}/contacts/${id}`;
  const data = {
    businessName,
    firstName,
    lastName,
    phoneNumber: phone,
    role,
  };
  const res = await axios.post(url, data, defaultRequestOptions());
  return res.data;
}

async function createContact(apiUrl, {
  businessName, firstName, lastName, phone, role, opportunityId,
}) {
  const url = `${apiUrl}/contacts`;
  const requestData = {
    businessNameReference: businessName, firstName, lastName, phoneNumber: phone, role, opportunityId,
  };
  const res = await axios.post(url, requestData, defaultRequestOptions());
  return res.data;
}

async function createOrUpdateContacts(apiUrl, contacts) {
  const promises = contacts.map((contact) => {
    if (contact.id) {
      return update(apiUrl, contact);
    }

    return createContact(apiUrl, contact);
  });

  return Promise.all(promises);
}

export default {
  createOrUpdateContacts,
  createContact,
};
