import moment from 'moment';
import util from '../../../util';
import {
  DEFERRED, MONTHLY, SBA, SBA_DECISION_STAGES,
} from './constants';
import { FORGIVENESS_STAGES } from '../../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';

export const getLoanName = (name, partner) => `${util.getLoanName(partner)} (${name})`;

export const getPayFrequencyAndValue = ({
  paymentFrequency, currentPayment, type, forgivenessStage, firstPaymentDateExpected,
}) => {
  moment.locale('en');

  const shouldNotDisplayPayment = !util.isDateInPastOrToday(firstPaymentDateExpected) && type === SBA;

  let payment;

  if (!firstPaymentDateExpected
    || (type === SBA && SBA_DECISION_STAGES.includes(forgivenessStage))) {
    payment = util.getCurrency(currentPayment);
  }
  else {
    payment = shouldNotDisplayPayment ? DEFERRED : util.getCurrency(currentPayment);
  }

  return {
    frequency: type === SBA ? `${MONTHLY} payment` : `${paymentFrequency} payment`,
    payment,
  };
};

export const shouldHidePaymentFrequencyInfo = ({
  type, balance, forgivenessStage, estimatedMaturityDate, loanForgivenessId,
}) => {
  if (type !== SBA) {
    return false;
  }

  const isTermInPastOrToday = util.isDateInPastOrToday(estimatedMaturityDate);

  // borrower hasn't submitted the forgiveness application
  const isDocumentRequestedStage = !!loanForgivenessId
  && ![FORGIVENESS_STAGES.AWAITING_SBA_RESPONSE, FORGIVENESS_STAGES.FORGIVEN,
    FORGIVENESS_STAGES.DECLINED, FORGIVENESS_STAGES.SBA_ADDITIONAL_INFO_REQUIRED,
    FORGIVENESS_STAGES.SBA_RESPONSE_RECEIVED].includes(forgivenessStage);

  // forgiveness decision has been received from SBA
  const sbaDecisionReceievedStages = [FORGIVENESS_STAGES.FORGIVEN, FORGIVENESS_STAGES.DECLINED,
    FORGIVENESS_STAGES.SBA_RESPONSE_RECEIVED];

  // borrower applied but application was never completed and expired
  const applicationExpiredStages = [FORGIVENESS_STAGES.EXPIRED];

  if ((!loanForgivenessId || !forgivenessStage
     || (!!loanForgivenessId && [...sbaDecisionReceievedStages, ...applicationExpiredStages].includes(forgivenessStage))
     || isDocumentRequestedStage
  ) && balance > 0 && isTermInPastOrToday) {
    return true;
  }
  return false;
};
