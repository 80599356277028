import { addDeauthorized } from '../auth/auth-action-types';
import { isUserAuthorized } from '../../../lib/token';

export const asyncAuthorizedActionCreator = (asyncTypes, createThunk) => (...args) => {
  const thunk = createThunk(...args);

  return (dispatch) => {
    if (!isUserAuthorized()) {
      return dispatch(addDeauthorized());
    }

    dispatch({ type: asyncTypes.pending });

    return dispatch(thunk)
      .then((payload) => dispatch({
        type: asyncTypes.complete,
        payload,
      }))
      .catch((err) => dispatch({
        type: asyncTypes.error,
        error: true,
        payload: err,
      }));
  };
};

export const asyncNoAuthActionCreator = (asyncTypes, createThunk) => (...args) => {
  const thunk = createThunk(...args);

  return (dispatch) => {
    dispatch({ type: asyncTypes.pending });

    return dispatch(thunk)
      .then((payload) => dispatch({
        type: asyncTypes.complete,
        payload,
      }))
      .catch((err) => dispatch({
        type: asyncTypes.error,
        error: true,
        payload: err,
      }));
  };
};
