import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Container } from '@swift-paypal/pp-react';
import util from '../../util';
import redirect from '../../lib/redirect';
import Title from '../../components/common/title';
import PageTitle from '../../components/common/page-title';
import SecurePage from '../../hocs/secure-page';
import BusinessWebAddressFormComponent from '../../components/settings/business-web-address/business-web-address-form';
import { addBusinessWebAddressAction, clearSettingsMessageAction, getContactAccountsAction } from '../../redux/actions/settings/settings-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import updatedSettingNames from '../../constants/updatedSettingNames';

class AddBusinessWebAddressPage extends Component {
  static displayName = displayNames.AddBusinessWebAddressPage;

  static propTypes = {
    addBusinessWebAddress: PropTypes.func.isRequired,
    apiUrl: PropTypes.string.isRequired,
    clearSettingsMessage: PropTypes.func.isRequired,
    contactAccounts: PropTypes.arrayOf(PropTypes.object),
    contactAccountsPending: PropTypes.bool,
    getContactAccounts: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
  };

  static defaultProps = {
    contactAccounts: undefined,
    contactAccountsPending: false,
  };

  async componentDidMount() {
    const { apiUrl, getContactAccounts } = this.props;
    await getContactAccounts(apiUrl);
  }

  componentWillUnmount() {
    const { clearSettingsMessage } = this.props;
    clearSettingsMessage();
  }

  submit = (values) => {
    const {
      apiUrl,
      contactAccounts,
      addBusinessWebAddress,
      history,
      match,
    } = this.props;
    const { website } = values;
    util.blurOnSubmit();

    addBusinessWebAddress(apiUrl, website, contactAccounts[0].contact.id);
    const queryParams = {
      updatedSettingName: updatedSettingNames.businessWebAddress,
      updatedSettingValue: website,
    };
    redirect(history, match, routes.settings.path, queryParams);
  };

  render() {
    const {
      contactAccountsPending,
    } = this.props;

    return (
      <div data-automation-id="addBusinessWebAddressPage">
        {(contactAccountsPending === false) && (
          <div>
            <Helmet>
              <title>Add business web address</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <Container fluid>
                  <PageTitle text="Additional Information" />
                  <Title text="Business web address" />
                  <BusinessWebAddressFormComponent
                    onSubmit={this.submit}
                  />
                </Container>
              )}
              sidebarElement={<></>}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    bfes: state.accountItems.bfes,
    contactAccounts: state.settings.contactAccounts,
    contactAccountsPending: state.settings.contactAccountsPending,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addBusinessWebAddress: (apiUrl, website, contactId) => {
      dispatch(addBusinessWebAddressAction(apiUrl, website, contactId));
    },
    clearSettingsMessage: () => {
      dispatch(clearSettingsMessageAction());
    },
    getContactAccounts: (apiUrl) => {
      dispatch(getContactAccountsAction(apiUrl));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurePage(AddBusinessWebAddressPage));
