import { ONE_HUNDRED_FIFTY_THOUSAND } from '../../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';
import { LOAN_VERSION_ORDER } from './forgivenessTileList.constants';

export const filter2021ForgivenessAdvanceIds = (advanceIds, advancesById) => advanceIds.filter((id) => {
  const advance = advancesById[id];

  return advance?.loanVersion === '20PPPLoan';
});

export const filterAdvanceIdsWithOppData = (advanceIds, advancesById, opportunities) => advanceIds.filter((id) => {
  const opportunityId = advancesById[id]?.opportunityId;
  return opportunities.allIds.includes(opportunityId);
});

export const filterNonForgivenessAdvanceIds = (advanceIds, advancesById) => advanceIds.filter((id) => {
  const advance = advancesById[id];

  return advance
    && !!advance.issuedDate
    && !advance.rescindedDate
    && !advance.confirmedFraudDate
    && !advance.sbaLoanRescindedDate
    && advance.type === 'SBA';
});

export function getForgivenessAdvanceIdsToDisplay(advanceIds, advancesById) {
  const secondDrawAdvanceId = advanceIds.find((id) => advancesById[id].loanVersion === '21SDPPP');
  const secondDrawAdvance = advancesById[secondDrawAdvanceId];

  const firstDrawAdvanceId = advanceIds.find((id) => advancesById[id].loanVersion === '20PPPLoan');
  const firstDrawAdvance = advancesById[firstDrawAdvanceId];

  if (secondDrawAdvance?.amount <= ONE_HUNDRED_FIFTY_THOUSAND || firstDrawAdvance?.sbaRequestId) {
    return advanceIds;
  }

  return [firstDrawAdvanceId];
}

export const sortForgivenessAdvanceIdsByLoanVersion = (advanceIds, advancesById) => advanceIds.sort((idA, idB) => {
  const loanVersionA = advancesById[idA]?.loanVersion;
  const loanVersionB = advancesById[idB]?.loanVersion;
  return LOAN_VERSION_ORDER.indexOf(loanVersionA) - LOAN_VERSION_ORDER.indexOf(loanVersionB);
});

export function getForgivenessAdvanceIds(advances, opportunities, ppp21LoFoEnabled) {
  const { allIds, byId } = advances;

  const forgivenessAdvanceIds = filterNonForgivenessAdvanceIds(allIds, byId);
  const forgivenessAdvanceIdsWithOpps = filterAdvanceIdsWithOppData(forgivenessAdvanceIds, byId, opportunities);
  const sortedForgivenessAdvanceIds = sortForgivenessAdvanceIdsByLoanVersion(forgivenessAdvanceIdsWithOpps, byId);

  if (!ppp21LoFoEnabled) {
    return filter2021ForgivenessAdvanceIds(sortedForgivenessAdvanceIds, byId);
  }

  if (sortedForgivenessAdvanceIds.length > 1) {
    return getForgivenessAdvanceIdsToDisplay(sortedForgivenessAdvanceIds, byId);
  }

  return sortedForgivenessAdvanceIds;
}
