import { asyncNoAuthActionCreator, asyncAuthorizedActionCreator } from '../util/async-action-creator';
import documentUploadService from '../../../services/document-upload.service';
import * as notificationsActions from '../notifications/notifications-action-types';
import * as actions from './upload-document-action-types';

export const getDocumentTypesAction = asyncNoAuthActionCreator(
  {
    pending: actions.DOCUMENT_TYPES_REQUEST_PENDING,
    error: actions.DOCUMENT_TYPES_REQUEST_REQUEST_ERROR,
    complete: actions.DOCUMENT_TYPES_REQUEST_RECEIVED,
  },
  () => (dispatch, getState) => documentUploadService.getTypes(getState().config.apiUrl),
);

export const uploadDocumentAction = asyncAuthorizedActionCreator(
  {
    pending: actions.DOCUMENT_UPLOAD_REQUEST_PENDING,
    error: actions.DOCUMENT_UPLOAD_REQUEST_ERROR,
    complete: actions.DOCUMENT_UPLOAD_REQUEST_COMPLETED,
  },
  (data) => (dispatch, getState) => documentUploadService.upload(getState().config.apiUrl, data),
);

const asyncFileUpload = async (files, apiUrl) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    // eslint-disable-next-line no-await-in-loop
    await documentUploadService.upload(apiUrl, file);
  }
};

export const uploadBatchDocumentsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.DOCUMENT_UPLOAD_REQUEST_PENDING,
    error: actions.DOCUMENT_UPLOAD_REQUEST_ERROR,
    complete: actions.DOCUMENT_UPLOAD_REQUEST_COMPLETED,
  },
  (dataArray) => (dispatch, getState) => asyncFileUpload(dataArray, getState().config.apiUrl),
);

export const uploadDocumentsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.DOCUMENTS_UPLOAD_REQUEST_PENDING,
    error: actions.DOCUMENTS_UPLOAD_REQUEST_ERROR,
    complete: actions.DOCUMENTS_UPLOAD_REQUEST_COMPLETED,
  },
  (data) => (dispatch, getState) => documentUploadService.uploadMultiple(getState().config.apiUrl, data),
);

export const uploadDocumentsRequestClearAction = () => (dispatch) => {
  dispatch({ type: actions.DOCUMENTS_UPLOAD_REQUEST_CLEAR });
};

export const uploadDocumentsClearFailedFilesAction = () => (dispatch) => {
  dispatch({ type: actions.DOCUMENTS_UPLOAD_CLEAR_FAILED_FILES });
};

export const stipulationSuccessAction = (stipName, id) => async (dispatch) => {
  dispatch({ type: notificationsActions.MARK_STIPULATION_FULFILLED, stipName, id });
  dispatch({ type: actions.DOCUMENT_UPLOAD_STIPULATION_SUCCESS });
};

export const clearStipulationSuccessAction = () => async (dispatch) => {
  dispatch({ type: actions.DOCUMENT_UPLOAD_CLEAR_STIPULATION_SUCCESS });
};

export const clearUploadErrorAction = () => (dispatch) => {
  dispatch({ type: actions.DOCUMENT_UPLOAD_CLEAR_REQUEST_ERROR });
};

export const clearUploadPendingAction = () => (dispatch) => {
  dispatch({ type: actions.DOCUMENT_UPLOAD_CLEAR_PENDING });
};
