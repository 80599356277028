import React, { Component } from 'react';
import { initialize } from 'redux-form';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { Col, Row } from '@swift-paypal/pp-react';
import resolve from '../services/route.service';
import { scrollTo } from '../services/window.service';
import { Alert, AlertTypes } from '../components/common/alert';
import PageTitle from '../components/common/page-title';
import ChangeSecurityQuestionsFormComponent from '../components/settings/security-questions/change-security-questions-form';
import {
  listSecurityQuestionsAction,
  createSecurityQuestionAction,
  clearCreateSecurityQuestionsMessageAction,
  setCreateSecurityQuestionsMessageAction,
} from '../redux/actions/auth/auth-actions';
import { getToken } from '../lib/token';
import AnonymousPage from '../hocs/anonymous-page';
import util from '../util';
import constants from '../constants';
import routes from '../routes';
import displayNames from '../constants/displayNames';

class SecurityQuestionsPage extends Component {
  static displayName = displayNames.SecurityQuestionsPage;

  static propTypes = {
    create: PropTypes.func.isRequired,
    clearMessage: PropTypes.func.isRequired,
    country: PropTypes.string,
    listSecurityQuestions: PropTypes.func.isRequired,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    push: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    securityQuestions: PropTypes.shape({
      userQuestions: PropTypes.arrayOf(PropTypes.string),
      defaultQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    setMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: undefined,
    messageBody: undefined,
    messageType: undefined,
    securityQuestions: undefined,
  };

  async componentDidMount() {
    const { push, listSecurityQuestions } = this.props;
    const decodedToken = getToken();

    // old app checked for auth_type === 'verification' here; the check below covers that as well
    // as other invalid cases for arriving to this page (should only be auth_type authenticated,
    // and should not have a security_question_id in the token)
    if (!decodedToken || decodedToken.auth_type !== 'authenticated' || decodedToken.security_question_id) {
      push(resolve(routes.login.path, { country: decodedToken ? decodedToken.country_code : undefined }));
      return;
    }

    listSecurityQuestions();
  }

  componentWillUnmount() {
    const { clearMessage } = this.props;
    clearMessage();
  }

  submit = (values) => {
    const {
      clearMessage,
      country,
      create,
      resetForm,
      setMessage,
    } = this.props;
    const {
      question1, answer1, question2, answer2, question1Custom, question2Custom,
    } = values;
    util.blurOnSubmit();

    if (util.securityQuestionsAreInvalid(values)) {
      setMessage(constants.errors.securityQuestionsMatchError, AlertTypes.critical);
      if (process.browser && window) {
        scrollTo(0, 0);
      }
      return;
    }
    clearMessage();

    resetForm();
    create(country, question1Custom || question1, answer1, question2Custom || question2, answer2);
    scrollTo(0, 0);
  };

  render() {
    const { messageType, messageBody, securityQuestions } = this.props;

    return (
      <div className="create-security-questions">
        <Helmet>
          <title>Create Security Questions</title>
        </Helmet>
        <PageTitle text="Create Security Questions" />
        <Row>
          <Col xs={12} sm={12} md={7}>
            {messageBody && (
              <div className="main-right-margin">
                <Alert message={messageBody} alertType={messageType} />
              </div>
            )}
            {securityQuestions
              && <ChangeSecurityQuestionsFormComponent onSubmit={this.submit} securityQuestions={securityQuestions} />}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageBody: state.createSecurityQuestions.messageBody,
    messageType: state.createSecurityQuestions.messageType,
    securityQuestions: state.createSecurityQuestions.securityQuestions,
    success: state.createSecurityQuestions.success,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMessage: (messageBody, messageType) => {
      dispatch(setCreateSecurityQuestionsMessageAction(messageBody, messageType));
    },
    clearMessage: () => {
      dispatch(clearCreateSecurityQuestionsMessageAction());
    },
    listSecurityQuestions: () => {
      dispatch(listSecurityQuestionsAction());
    },
    create: (country, q1, a1, q2, a2) => {
      dispatch(createSecurityQuestionAction(country, q1, a1, q2, a2));
    },
    resetForm: () => {
      dispatch(initialize('change-security-questions'));
    },
    push: (path) => dispatch(pushAction(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousPage(SecurityQuestionsPage));
