import React from 'react';
import PropTypes from 'prop-types';
import VerticalProgressTracker from './vertical-progress-tracker';
import HorizontalProgressTracker from './horizontal-progress-tracker';

const ProgressTracker = ({ activeStage, stages }) => {
  const applicableStages = stages
    && stages
      // first filter out stages that shouldn't be displayed given the current active stage
      .filter((stage) => !(stage.dontDisplayIfActive
        && stage.dontDisplayIfActive.find((id) => id === activeStage.id) !== undefined))
      // then filter out stages that shouldn't be displayed if they aren't currently active
      .filter((stage) => stage.displayIfNotActive || stage.id === activeStage.id);

  return (
    <div className="progress-tracker__layout">
      <HorizontalProgressTracker
        applicableStages={applicableStages}
        activeStage={activeStage}
      />
      <VerticalProgressTracker stages={applicableStages} activeStage={activeStage} />
    </div>
  );
};

ProgressTracker.propTypes = {
  activeStage: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  stages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProgressTracker;
