import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Col, Link, Row } from '@swift-paypal/pp-react';
import countryCodes from '../../constants/countryCodes';
import Title from '../common/title';
import resolve from '../../services/route.service';
import withUrlParams from '../../hocs/with-url-params';
import routes from '../../routes';
import Business from './business';
import './settings.less';

export const Settings = (props) => {
  const {
    contactAccounts, user, country, businessWebAddress, homePhone,
  } = props;

  return (
    <Row className="settings mobile-reverse-order">
      {contactAccounts && (
        <Col md={12} lg={8} className="mobile-reverse-bottom">
          {contactAccounts.map((contactAccount) => (
            <Business
              key={contactAccount.contact.id}
              contactAccount={contactAccount}
              homePhone={homePhone}
              businessWebAddress={businessWebAddress}
            />
          ))}
        </Col>
      )}
      <Col sm={12} md={6} lg={4} className="padding-bottom-sm mobile-reverse-top">
        <Title text="Profile" />
        <div className="left-gutter">
          {user && <div className="vx_text-4 padding-bottom-sm">{`${user.firstName} ${user.lastName}`}</div>}
          {user && user.payPalSso && country.toUpperCase() === countryCodes.canada && (
            <div>
              Please visit the link below if you need any assistance with managing your PayPal account settings.
              <br />
              <br />
              <a href="https://www.paypal.com/us/smarthelp/topic/MY_ACCOUNT" target="_blank" rel="noopener noreferrer">PayPal Account Settings</a>
            </div>
          )}
          {user && !user.payPalSso && (
            <>
              <Link as={RouterLink} className="align-left display-block padding-top-bottom-xs" to={resolve(routes['change-password'].path, { country })}>
                Change password
              </Link>
              <Link as={RouterLink} className="align-left display-block padding-top-bottom-xs" to={resolve(routes['change-security-questions'].path, { country })}>
                Change security questions
              </Link>
              <Link as={RouterLink} className="align-left display-block padding-top-bottom-xs" to={resolve(routes['change-email'].path, { country })}>
                Change email address
              </Link>
            </>
          )}
          {(!homePhone || !businessWebAddress)
            && (
              <div>
                <hr className="vx_hr account-hr" />
                <div className="vx_text-body-sm padding-bottom-sm"><i>Additional contact information</i></div>
                {!homePhone
                  && (
                    <Link as={RouterLink} className="align-left display-block padding-top-bottom-xs" to={resolve(routes['add-home-phone'].path, { country })}>
                      Add home phone number
                    </Link>
                  )}
                {!businessWebAddress
                  && (
                    <Link as={RouterLink} className="align-left display-block padding-top-bottom-xs" to={resolve(routes['add-business-web-address'].path, { country })}>
                      Add business web address
                    </Link>
                  )}
              </div>
            )}
        </div>
      </Col>
    </Row>
  );
};

Settings.propTypes = {
  businessWebAddress: PropTypes.string,
  contactAccounts: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.string,
  homePhone: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    payPalSso: PropTypes.bool,
  }),
};

Settings.defaultProps = {
  businessWebAddress: undefined,
  contactAccounts: undefined,
  country: undefined,
  homePhone: undefined,
  user: undefined,
};

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(Settings);
