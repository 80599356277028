import React from 'react';
import { BodyText, HeadingText } from '@swift-paypal/pp-react';

export const SAppDetails = () => (
  <>
    <HeadingText size="sm" className="forgiveness-heading-text">
      Provide Information for your Forgiveness Application
    </HeadingText>
    <BodyText as="p">
      To see what&apos;s required for your application, click
      Get Started for each category and provide the requested information.
    </BodyText>
  </>
);
