import React from 'react';
import PropTypes from 'prop-types';

const NoProducts = (props) => {
  const { account } = props;
  return (
    <p className="vx_text-body-md">
      {account.accountName}
      {' '}
      does not have any open applications or loans at the moment.
    </p>
  );
};

NoProducts.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
  }),
};

NoProducts.defaultProps = {
  account: undefined,
};

export default NoProducts;
