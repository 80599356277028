import React, { memo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  BodyText, Row, Col, Divider, Button, Tabs, TabPanel,
} from '@swift-paypal/pp-react';
import util from '../../../../../util';
import {
  completeEntries,
  entryLabels,
  expandedEntries,
  ezEntries,
  form3508S,
  formatFixedDisplayValue,
  getEntryValueType,
  standardEntries,
} from '../../forgivenessCustomerSignoff.util';
import './total-forgiveness-amount.less';

const TotalForgivenessAmountTable = ({
  tableValues,
  isEzApp,
  is3508S,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTableCategory, setSelectedTableCategory] = useState(0);
  const toggleShowDetails = () => setShowDetails(!showDetails);
  const toggleSelectedTableCategory = (event, tabIdx) => setSelectedTableCategory(tabIdx);

  let fteReductionQuotientText = '0';
  let srFteReductionQuotientText = '0';
  const { fteReductionQuotient, srFteReductionQuotient } = tableValues;
  if (fteReductionQuotient) {
    fteReductionQuotientText = formatFixedDisplayValue(fteReductionQuotient);
  }
  if (srFteReductionQuotient) {
    srFteReductionQuotientText = formatFixedDisplayValue(srFteReductionQuotient);
  }

  const getEntryValue = (entryLabel, useSrAmount = true) => {
    const label = useSrAmount ? completeEntries[entryLabel].sr : completeEntries[entryLabel].approved;
    switch (entryLabel) {
      case entryLabels.fteReductionQuotient:
        return useSrAmount ? srFteReductionQuotientText : fteReductionQuotientText;
      case entryLabels.totalPayrollCost:
      case entryLabels.mortgageInterestPayments:
      case entryLabels.rentOrLeasePayments:
      case entryLabels.utilityPayments:
      case entryLabels.totalSalaryReductions:
      case entryLabels.forgivenessAmount:
      case entryLabels.totalCashCompensationTable1:
      case entryLabels.totalCashCompensationTable2:
      case entryLabels.employeeHealthInsurance:
      case entryLabels.employeeRetirementPlans:
      case entryLabels.stateAndLocalTaxes:
      case entryLabels.ownerCompensationTotal:
      case entryLabels.actualForgivenessAmount:
      case entryLabels.coveredOperationsExpenditures:
      case entryLabels.coveredPropertyDamageCosts:
      case entryLabels.coveredSupplierCosts:
      case entryLabels.coveredWorkerProtectionExpenditures:
        return util.getCurrency(tableValues[label]);
      default:
        return '0';
    }
  };

  const getDivider = (label) => {
    switch (label) {
      case entryLabels.actualForgivenessAmount:
        return (<Divider className="solid-divider" />);
      case entryLabels.totalPayrollCost:
      case entryLabels.totalCashCompensationTable1:
        return (<Divider className="hidden-divider" />);
      default:
        return (<Divider secondary />);
    }
  };

  const getPayrollCostDetailsButton = () => (
    <Button
      inverse
      className="payroll-cost-details-button"
      aria-expanded={showDetails}
      onClick={toggleShowDetails}
    >
      {entryLabels.totalPayrollCost}
    </Button>
  );

  const getDesktopRowEntry = (label) => (
    <Fragment key={label}>
      {getDivider(label)}
      <Row className="row">
        <Col xs={6}>
          <BodyText as="span" className="label">{(label === entryLabels.totalPayrollCost && !isEzApp) ? '' : label}</BodyText>
        </Col>
        <Col xs={3} className="right-align">
          <BodyText
            as="span"
            className="amount"
            data-testid={`${completeEntries[label].sr}-desktop`}
          >
            {getEntryValue(label, true)}
          </BodyText>
        </Col>
        <Col xs={3} className="right-align">
          <BodyText
            strong={label === entryLabels.totalPayrollCost || label === entryLabels.actualForgivenessAmount}
            as="span"
            className="amount"
            data-testid={`${completeEntries[label].approved}-desktop`}
          >
            {getEntryValue(label, false)}
          </BodyText>
        </Col>
      </Row>
    </Fragment>
  );

  const getMobileRowEntry = (label, useSrAmount, hideDivider) => (
    <Fragment key={label}>
      {hideDivider
        ? <><Divider className="hidden-divider" /></>
        : <>{getDivider(label)}</>}

      <Row className="row">
        <Col xs={8}>
          <BodyText as="span" className="label">{(label === entryLabels.totalPayrollCost && !isEzApp) ? '' : label}</BodyText>
        </Col>
        <Col xs={4} className="right-align">
          <BodyText
            strong={label === entryLabels.actualForgivenessAmount}
            as="span"
            className="amount"
            data-testid={`${getEntryValueType(label, useSrAmount)}-mobile`}
          >
            {getEntryValue(label, useSrAmount)}
          </BodyText>
        </Col>
      </Row>
    </Fragment>
  );

  const Form3508SContent = () => (
    <div>
      <Divider className="hidden-divider" />
      <Row className="row">
        <Col xs={6}>
          <BodyText>Forgiveness amount</BodyText>
        </Col>
        {!showDetails && (
        <>
          <Col xs={3} className="right-align">
            <BodyText
              as="span"
              className="amount"
              data-testid={`${getEntryValueType(entryLabels.actualForgivenessAmount, true)}-desktop`}
            >
              {getEntryValue(entryLabels.actualForgivenessAmount, true)}
            </BodyText>
          </Col>
          <Col xs={3} className="right-align">
            <BodyText
              as="span"
              className="amount"
              data-testid={`${getEntryValueType(entryLabels.actualForgivenessAmount, false)}-desktop`}
            >
              {getEntryValue(entryLabels.actualForgivenessAmount, false)}
            </BodyText>
          </Col>
        </>
        )}
      </Row>
    </div>
  );

  const getTableContent = (isMobileView, useSrAmount) => {
    if (!isMobileView && is3508S) {
      return <Form3508SContent />;
    }
    if (!isMobileView) {
      return (
        <>
          {!isEzApp && (
          <>
            <Divider className="hidden-divider" />
            <Row className="row">
              <Col xs={6}>
                {getPayrollCostDetailsButton()}
              </Col>
              {!showDetails && (
              <>
                <Col xs={3} className="right-align">
                  <BodyText
                    as="span"
                    className="amount"
                    data-testid={`${getEntryValueType(entryLabels.totalPayrollCost, true)}-desktop`}
                  >
                    {getEntryValue(entryLabels.totalPayrollCost, true)}
                  </BodyText>
                </Col>
                <Col xs={3} className="right-align">
                  <BodyText
                    as="span"
                    className="amount"
                    data-testid={`${getEntryValueType(entryLabels.totalPayrollCost, false)}-desktop`}
                    strong
                  >
                    {getEntryValue(entryLabels.totalPayrollCost, false)}
                  </BodyText>
                </Col>
              </>
              )}
            </Row>
          </>
          )}
          {showDetails && Object.keys(expandedEntries).map((label) => getDesktopRowEntry(label))}
          {isEzApp
            ? Object.keys(ezEntries).map((label) => getDesktopRowEntry(label))
            : Object.keys(standardEntries).slice(1).map((label) => getDesktopRowEntry(label))}
        </>
      );
    }
    return (
      <>
        {!isEzApp && !is3508S && (
        <>
          <Divider className="hidden-divider" />
          <Row className="row">
            <Col xs={8}>
              {getPayrollCostDetailsButton()}
            </Col>
            {!showDetails && (
            <>
              <Col xs={4} className="right-align">
                <BodyText
                  as="span"
                  className="amount"
                  data-testid={`${getEntryValueType(entryLabels.totalPayrollCost, useSrAmount)}-mobile`}
                >
                  {getEntryValue(entryLabels.totalPayrollCost, useSrAmount)}
                </BodyText>
              </Col>
            </>
            )}
          </Row>
        </>
        )}
        {!is3508S && (
          <>
            {showDetails && Object.keys(expandedEntries).map((label) => getMobileRowEntry(label, useSrAmount))}
            {isEzApp
              ? Object.keys(ezEntries).map((label) => getMobileRowEntry(label, useSrAmount))
              : Object.keys(standardEntries).slice(1).map((label) => getMobileRowEntry(label, useSrAmount))}
          </>
        )}
        {is3508S && Object.keys(form3508S).map((label) => getMobileRowEntry(label, useSrAmount, true))}
      </>
    );
  };

  return (
    <div className="total-forgiveness-amount-table">
      <div className="desktop-view">
        <Row className="row">
          <Col xs={9} className="right-align">
            <BodyText as="span" strong className="col-name">Amounts you requested</BodyText>
          </Col>
          <Col xs={3} className="right-align">
            <BodyText as="span" strong className="col-name">Recommended to SBA</BodyText>
          </Col>
        </Row>
        {getTableContent(false)}
      </div>
      <div className="mobile-view">
        <Tabs
          name="TableHeaderTabsGroup"
          selectedTabIdx={selectedTableCategory}
          center
          tabs={[{
            label: 'Amounts you requested',
            id: 'tab1',
            panelId: 'panel1',
          }, {
            label: 'Recommended to SBA',
            id: 'tab2',
            panelId: 'panel2',
          }]}
          onClick={toggleSelectedTableCategory}
        >
          <TabPanel id="panel1">
            {getTableContent(true, true)}
          </TabPanel>
          <TabPanel id="panel2">
            {getTableContent(true, false)}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

TotalForgivenessAmountTable.propTypes = {
  tableValues: PropTypes.shape({
    srFteReductionQuotient: PropTypes.number,
    fteReductionQuotient: PropTypes.number,
  }).isRequired,
  isEzApp: PropTypes.bool.isRequired,
  is3508S: PropTypes.bool.isRequired,
};

export default memo(TotalForgivenessAmountTable);
