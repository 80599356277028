/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, HeadingText, DropdownMenu, Button, Row, Col,
} from '@paypalcorp/pp-react';
import { isEmpty } from 'lodash';
import { ResponsiveHeadingText } from '../../one-time-payments/components/responsiveTypography';
import {
  useShallowSelector,
  oneTimePaymentSelector,
} from '../../one-time-payments/one-time-payment-selectors';
import displayNames from '../../../constants/displayNames';
import SecurePage from '../../../hocs/secure-page';
import './oneTimePaymentPage.less';
import {
  getSelfServiceLoansAction,
  getSelfServicePaymentBankListAction,
  resetPaymentProcessingStatus,
  resetSelfServiceBankAddAction,
} from '../../../redux/actions/one-time-payment/one-time-payment';
import {
  getRoutingNumberList,
} from '../../../redux/actions/application/verify-bank-accounts/verify-bank-accounts-actions';
import PaymentTile from './paymentTile/paymentTile';
import util from '../../../util';
import PaymentModal from '../../one-time-payments/paymentModal';
import ScheduledTransactionSection from './scheduleTransactionSection/scheduledTransactionSection';
import SpinnerStandaloneComponent from '../../common/spinner-standalone/spinner-standalone';
import { PaymentTriggerSource } from '../../one-time-payments/paymentComponentMappings';

const OneTimePaymentPage = () => {
  const dispatch = useDispatch();
  const {
    ssAchError,
    loanAdvances,
    defaultLoanAdvance,
    loading,
    loanDropdownOptions = [],
  } = useShallowSelector(oneTimePaymentSelector);
  const routingInstitutionInfo = useSelector((state) => state?.getRoutingInstitution);
  const routingInstitutionList = routingInstitutionInfo?.institutionList || [];

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [selectedLoanAdvance, setSelectedLoanAdvance] = useState(null);
  const [paymentTiggeredSource, setPaymentTiggeredSource] = useState(PaymentTriggerSource.MakeAPaymentButton);
  const [scheduleTransactionEditBankData, setScheduleTransactionEditBankData] = useState(null);

  useEffect(() => {
    setSelectedLoanAdvance(defaultLoanAdvance);
  }, [defaultLoanAdvance]);

  useEffect(() => {
    dispatch(getSelfServiceLoansAction());
  }, []);

  useEffect(() => {
    if (selectedLoanAdvance?.Id !== undefined) {
      dispatch(getSelfServicePaymentBankListAction(selectedLoanAdvance.Id));
    }
  }, [selectedLoanAdvance]);

  const openSheet = () => {
    if (isEmpty(routingInstitutionList)) {
      dispatch(getRoutingNumberList());
    }
    dispatch(resetPaymentProcessingStatus());
    dispatch(resetSelfServiceBankAddAction());
    setIsPaymentModalOpen(true);
  };

  const openSheetForPaymentFlow = () => {
    setScheduleTransactionEditBankData(null);
    setPaymentTiggeredSource(PaymentTriggerSource.MakeAPaymentButton);
    openSheet();
  };
  const openSheetForTrxn = () => {
    setPaymentTiggeredSource(PaymentTriggerSource.TransactionList);
    openSheet();
  };

  const handleLoanDropdown = (event) => {
    setSelectedLoanAdvance(loanAdvances.find((advance) => advance.Id === event.target.value));
  };

  return (
    <>
      <Helmet title="One Time Payment" />
      {loading ? (
        <SpinnerStandaloneComponent />
      ) : !selectedLoanAdvance || ssAchError ? (
        <div className="error-view-flex">
          <img
            alt="error"
            className="stop-svg"
            src="https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/statements-stop.svg"
          />
          <HeadingText size="title">
            {ssAchError
              ? 'Something went wrong, Please try again later.'
              : 'You do not have any active loan(s), hence you will not be able to make a payment at this time.'}
          </HeadingText>
          <Button as="a" href="/summary" role="button">
            Go to Summary
          </Button>
        </div>
      ) : (
        <div className="payment-page-layout">
          {selectedLoanAdvance?.PastDueAmountWithFees ? (
            <Alert type="error">
              Your loan is
              {' '}
              {util.generateCurrencyText(selectedLoanAdvance.PastDueAmountWithFees)}
              {' '}
              past due.
            </Alert>
          ) : null}
          <div className="payment-header">
            <Row>
              <Col xs={12} lg={8}>
                <ResponsiveHeadingText
                  mobile="sm"
                  desktop="lg"
                  className="responsive-ach-offset"
                >
                  One-Time Payment
                </ResponsiveHeadingText>
              </Col>
              <Col xs={12} lg={4}>
                <div className="loan-dropdown-wrapper">
                  <DropdownMenu
                    label="Loan Number"
                    options={loanDropdownOptions.map(({ Id, Name }) => ({
                      value: Id,
                      primaryText: Name,
                    }))}
                    value={selectedLoanAdvance?.Id}
                    onChange={handleLoanDropdown}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <PaymentTile loanAdvance={selectedLoanAdvance} buttonClickHandler={openSheetForPaymentFlow} />
          <ScheduledTransactionSection
            selectedLoanAdvance={selectedLoanAdvance}
            queuedTransactions={selectedLoanAdvance?.QueueTransactions || []}
            editBankModalOpener={openSheetForTrxn}
            setScheduleTransactionEditBankData={setScheduleTransactionEditBankData}
          />
          {isPaymentModalOpen && (
            <PaymentModal
              isPaymentModalOpen={isPaymentModalOpen}
              setIsPaymentModalOpen={setIsPaymentModalOpen}
              selectedLoanAdvance={selectedLoanAdvance}
              paymentTiggeredSource={paymentTiggeredSource}
              scheduleTransactionEditBankData={scheduleTransactionEditBankData}
            />
          )}
        </div>
      )}
    </>
  );
};
OneTimePaymentPage.displayName = displayNames.OneTimePaymentPage;

export default SecurePage(OneTimePaymentPage);
