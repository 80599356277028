import axios from 'axios';
import { asyncDebounce } from '../util';

export default {
  check: asyncDebounce(check),
};

async function check(apiUrl) {
  const res = await axios.get(apiUrl);
  return res.data;
}
