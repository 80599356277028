import React, { Component } from 'react';
import { BodyText, Link } from '@swift-paypal/pp-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import log from 'loglevel';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import { Alert, AlertTypes } from '../../components/common/alert';
import uploadUtils from '../../lib/document-upload-utils';
import UploadDocumentsComponent from '../../components/common/upload-documents/upload-documents';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import getStipulationsAction, { fulfillStipulationAction } from '../../redux/actions/stipulations/stipulations-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadDocumentsAction,
  uploadDocumentsClearFailedFilesAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import errors from '../../constants/errors';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { allowedFileTypes, allowedSingleFileSize } from '../../constants/upload-document';

const stipulationGroupName = 'Demographic Information';

class DemographicInformationPage extends Component {
  static displayName = displayNames.DemographicInformationPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    failedFiles: PropTypes.arrayOf(PropTypes.object),
    fulfillStipulation: PropTypes.func.isRequired,
    fulfillStipulationResult: PropTypes.shape({
      error: PropTypes.bool,
      pending: PropTypes.bool,
    }),
    getOpportunities: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getStipulations: PropTypes.func.isRequired,
    loanbuilderUrl: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        opportunityId: PropTypes.string,
      }),
    }).isRequired,
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
    })),
    push: PropTypes.func.isRequired,
    uploadDocuments: PropTypes.func.isRequired,
    uploadDocumentsClearFailedFiles: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    response: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    stipulations: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    country: undefined,
    failedFiles: [],
    fulfillStipulationResult: {},
    opportunitiesById: {},
    uploadPending: true,
    uploadError: false,
    response: undefined,
    stipulations: [],
  };

  constructor() {
    super();
    this.state = {
      uploadedFiles: [],
      submitted: false,
    };
  }

  async componentDidMount() {
    const {
      country,
      getProductsAndBfes,
      getOpportunities,
      getStipulations,
      match,
      opportunitiesById,
    } = this.props;
    const { opportunityId } = match.params;
    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
    await getStipulations(opportunityId, country);
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending, uploadDocumentsClearFailedFiles } = this.props;
    uploadDocumentsClearFailedFiles();
    clearUploadError();
    clearUploadPending();
  }

  uploadFiles = async (files) => {
    const { match, uploadDocuments } = this.props;
    const { opportunityId } = match.params;
    const payload = uploadUtils.buildMultipleFilesPayload(files, opportunityId, stipulationGroupName);

    this.setState({ submitted: true });
    uploadDocuments(payload);
  }

  shouldShowAlert = () => {
    const { uploadedFiles, submitted } = this.state;
    const errorsPresent = uploadedFiles.find((file) => file.errors.length);
    return errorsPresent && submitted;
  };

  async redirectToSummaryConfirmation(shouldFulfillStipGroup = false) {
    const {
      country, push, match, fulfillStipulation, stipulations,
    } = this.props;
    const { opportunityId } = match.params;
    const stipName = 'Borrower Demographic Information';
    const stipulation = stipulations.find(({ stipulationMap: { name } }) => name === stipName);

    if (!stipulation) {
      return undefined;
    }

    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };

    if (shouldFulfillStipGroup) {
      await fulfillStipulation(opportunityId, stipulation.id);
      const { fulfillStipulationResult } = this.props;
      if (fulfillStipulationResult.error && !fulfillStipulationResult.pending) {
        return undefined;
      }
    }

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      uploadPending, uploadError, response, failedFiles, loanbuilderUrl,
    } = this.props;
    const { uploadedFiles, submitted } = this.state;

    if (uploadPending === false && !uploadError && !failedFiles.length) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    const showServerError = uploadPending === false && !!uploadError && submitted;

    if (uploadError) {
      log.error(`response: ${response} uploadError: ${uploadError}`);
    }

    const hasFailedUploads = failedFiles.length > 0;
    let failedUploadsMessageElement;
    if (hasFailedUploads) {
      failedUploadsMessageElement = (
        <>
          <p>We failed to upload the following file(s) due to the reason(s) provided:</p>
          <ul>
            {failedFiles.map((file) => (
              <li key={file.filename}>
                {file.filename}
                :
                {' '}
                {file.failureReason}
              </li>
            ))}
          </ul>
        </>
      );
    }

    const allowedFileExtensions = [
      ...allowedFileTypes.csv,
      ...allowedFileTypes.images,
      ...allowedFileTypes.pdf,
      ...allowedFileTypes.xlsx,
      ...allowedFileTypes.docx,
    ];
    const pageTitle = 'Optional borrower demographic information form';
    const helperText = 'Upload the PPP Borrower Demographic information form';
    const optOutText = 'Or click here if you\'d prefer not to upload the PPP Borrower Demographic information form (optional)';

    return (
      <div>
        <Helmet>
          <title>Demographic Information</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <PageTitle strong text={pageTitle} />
              <div className="secondary-gray margin-bottom-sm">
                <BodyText as="h3">
                  You can upload the Optional PPP Borrower Demographic information form as part of your
                  PPP loan application.
                </BodyText>
                <BodyText as="h3">
                  This form requests data about
                  <strong> veteran status, gender, race,</strong>
                  {' '}
                  and
                  {' '}
                  <strong>ethnicity</strong>
                  {' '}
                  for each of the Borrower&apos;s Principals for program reporting purposes only.
                  Disclosure is voluntary and will have no bearing on the loan application decision.
                </BodyText>
                <BodyText as="h3">
                  Download and complete the
                  {' '}
                  <Link
                    href={`${loanbuilderUrl}/sba_form_3508.pdf#page=5`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-automation-id="downloadDemographicInformationFormLink"
                  >
                    Optional PPP Borrower Demographic Information Form
                  </Link>
                  .
                </BodyText>
              </div>
              {showServerError && (
                <Alert
                  message={errors.technicalIssue}
                  alertType={AlertTypes.critical}
                />
              )}
              {this.shouldShowAlert() && !hasFailedUploads && !showServerError && (
                <Alert
                  message={errors.uploads.uploadAllMessage}
                  alertType={AlertTypes.critical}
                />
              )}
              {hasFailedUploads && (
                <Alert
                  message={failedUploadsMessageElement}
                  alertType={AlertTypes.critical}
                />
              )}
              <UploadDocumentsComponent
                allowedFileExtensions={allowedFileExtensions}
                maximumPerFileSizeInBytes={allowedSingleFileSize}
                maximumTotalSizeInBytes={allowedSingleFileSize}
                submitFunc={this.uploadFiles}
                customClassName="button-link increased-width"
                helperText={helperText}
                checkboxText={optOutText}
                optOutCheckbox
                optOutSubmitFunc={() => this.redirectToSummaryConfirmation(true)}
              />
              {uploadedFiles.length > 0 && (
                <div>
                  <h1>Files uploaded:</h1>
                  <ul>
                    {uploadedFiles.map((file) => <li key={file.name}>{file.name}</li>)}
                  </ul>
                </div>
              )}
            </div>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    loanbuilderUrl: state.config.loanbuilderUrl,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
    response: state.uploadDocument.response,
    failedFiles: state.uploadDocument.failedFiles,
    fulfillStipulationResult: state.fulfillStipulation,
    stipulations: state.stipulations.allStipulations,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    fulfillStipulation: fulfillStipulationAction,
    getOpportunities: getOpportunitiesAction,
    getProductsAndBfes: getProductsAndBfesAction,
    getStipulations: getStipulationsAction,
    uploadDocuments: uploadDocumentsAction,
    uploadDocumentsClearFailedFiles: uploadDocumentsClearFailedFilesAction,
  },
)(SecurePage(DemographicInformationPage));
