import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@swift-paypal/pp-react';
import './title.less';

const Title = ({
  text,
  tooltipText,
  tooltipImage,
  imageClassName,
}) => (
  <div>
    <div className="vx_text-4 account-name">
      {text}
      {
          (!!tooltipText || !!tooltipImage)
          && <Tooltip description={tooltipText} className="pp-react__tooltip--inline" imageElement={<img src={tooltipImage} className={imageClassName} alt="" />} />
        }
    </div>
    <hr className="vx_hr account-hr" />
  </div>
);

Title.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  tooltipText: PropTypes.string,
  tooltipImage: PropTypes.string,
  imageClassName: PropTypes.string,
};

Title.defaultProps = {
  tooltipText: '',
  tooltipImage: '',
  imageClassName: '',
};

export default Title;
