import React, { memo } from 'react';
import { Field } from 'redux-form';
import { numericality, required } from 'redux-form-validators';
import {
  BodyText, Col, Row, TextInput,
} from '@swift-paypal/pp-react';
import errors from '../../../../../constants/errors';
import { useShallowSelector } from '../../../../../hooks';
import { Sba3508SFormInstructionsLink } from '../../sba3508SFormInstructionsLink';
import { fieldSelector } from '../../sba3508SForm.selectors';
import { currencyMask, fieldNames } from '../sba3508SFormFields.constants';
import { payrollCostsSelector } from './payrollCostsField.selectors';
import { calculateLoanThreshold } from './payrollCostsField.utils';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const PayrollCostsField = () => {
  const fieldName = fieldNames.payrollCosts;
  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);
  const { requestedForgivenessAmount, pppLoanAmount } = useShallowSelector(payrollCostsSelector);

  if (!pppLoanAmount) {
    return null;
  }
  return (
    <Row>
      <Col form="full">
        <BodyText as="label" htmlFor={fieldName}>
          Amount of loan spent on payroll costs
        </BodyText>
        <Field
          component={MappedInput}
          errorText={touched ? error : undefined}
          helperText={helperText}
          key="payroll-costs-field"
          name={fieldName}
          placeholder="$"
          validate={[
            required({ msg: errors.enterAnAmount }),
            numericality({ '>': 0, msg: errors.enterAnAmount }),
            numericality({ '<=': pppLoanAmount, msg: 'Entered amount cannot be greater than your PPP loan amount.' }),
            numericality({ '>=': calculateLoanThreshold({ loanAmount: requestedForgivenessAmount }), msg: 'At least 60% of loan proceeds must be used for payroll costs.' }),
          ]}
          value={value}
          {...currencyMask}
        />
      </Col>
    </Row>
  );
};

const helperText = (
  <>
    See
    {' '}
    <Sba3508SFormInstructionsLink
      anchor="#page=2"
      text="Page 2 of SBA Form 3508S Instructions"
    />
    {' '}
    for details on the types of costs that can be included as Payroll Costs.
  </>
);

export default memo(PayrollCostsField);
