import PropTypes from 'prop-types';
import React from 'react';
import { Col } from '@swift-paypal/pp-react';

class OwnershipTile extends React.Component {
  constructor(props) {
    super(props);
    this.editOwner = this.editOwner.bind(this);
    this.removeOwner = this.removeOwner.bind(this);
  }

  editOwner() {
    const { editOwner, owner } = this.props;
    editOwner(owner);
  }

  removeOwner() {
    const { owner, removeOwner } = this.props;
    removeOwner(owner);
  }

  render() {
    const { owner, showEmail } = this.props;
    return (
      <div className="vx_panel-well ownership-tile">
        <Col xs={owner?.isApplicant ? 12 : 8} className="vx_panel-info">
          <div className="vx_panel-header">
            {`${owner.name.first} ${owner.name.last}`}
          </div>
          <div className="vx_panel-text">
            <em>{`${owner.percentOwned}% Ownership`}</em>
            {showEmail && <div>{`${owner.email}`}</div>}
          </div>
        </Col>
        { !owner.isApplicant
        && (
        <Col xs={6} className="action-container display-flex">
          {/* eslint-disable-next-line react/prop-types */}
          { !owner.id
          && (
          <button type="button" className="button-link vx_panel-action remove clickable" tabIndex="0" onClick={this.removeOwner}>
            Remove
          </button>
          ) }
          <button type="button" className="button-link vx_panel-action edit clickable" tabIndex="0" onClick={this.editOwner}>
            Edit
          </button>
        </Col>
        )}
      </div>
    );
  }
}

OwnershipTile.defaultProps = {
  editOwner: undefined,
  removeOwner: undefined,
  showEmail: false,
};

OwnershipTile.propTypes = {
  editOwner: PropTypes.func,
  owner: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    isApplicant: PropTypes.bool,
    name: PropTypes.object,
    percentOwned: PropTypes.number,
  }).isRequired,
  removeOwner: PropTypes.func,
  showEmail: PropTypes.bool,
};

export default OwnershipTile;
