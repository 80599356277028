import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';

const EntityGroupFive = () => (
  <>
    <BodyText className="payroll-verification__content-list-header">
      Organization information necessary for nonprofit organizations:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Determination Letter that includes organization&apos;s name and EIN.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        Signed Board Resolution or Right to Borrow Letter on organization letterhead,
        or existing bylaw that authorizes the organization to secure loans and for the
        authorized representative to execute loan documents.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        Fictitious Name Filing or other proof of DBA (if applicable).
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To substantiate your payroll costs calculation for the employees:
    </BodyText>

    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        IRS Form 941 Taxable Medicare wages &amp; tips from each quarter or equivalent payroll processor
        records or IRS Wage and Tax Statements.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        In lieu of IRS Form 941, IRS Form W-2s and IRS Form W-3 or payroll processor reports,
        including quarterly and annual tax reports may be provided, as well as annual IRS Form
        944 or annual IRS Form 943 (only when applicable).
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        For nonprofits that are required to file IRS Form 990 or 990EZ, Form 990 or 990EZ is
        required for group health, life, disability, vision, and dental insurance contributions
        and retirement contributions.
      </BodyText>
      <BodyText as="li" className="payroll-verification__content-list-item">
        State quarterly wage reporting forms to support employer state and local taxes
        assessed on employee compensation.
      </BodyText>
    </BodyText>

    <BodyText className="payroll-verification__content-list-header">
      To demonstrate your organization was in operation on Feb 15, 2020:
    </BodyText>
    <BodyText as="ul">
      <BodyText as="li" className="payroll-verification__content-list-item">
        Payroll statement or similar documentation from the pay period that covered
        Feb 15, 2020 to establish the business was in operation on and had employees on Feb 15, 2020.
      </BodyText>
    </BodyText>
  </>
);

export default EntityGroupFive;
