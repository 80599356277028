import { isEmpty, isString } from 'lodash';
import moment from 'moment';

export function getShouldUseNewStips(country, opportunityType) {
  if (!isString(country) || isEmpty(country)) {
    throw new Error('No valid value provided for "country" in useNewStips call');
  }

  if (!isString(opportunityType) || isEmpty(opportunityType)) {
    throw new Error('No valid value provided for "opportunityType" in useNewStips call');
  }

  return country === 'ca' || opportunityType === 'SBA';
}

/**
 * Returns an array of months' names and years, counting back from startDate, excluding current month
 * @param {Date} [startDate=new Date()] start date
 * @param {number} [monthsCount=0] amount of months back needs to be returned
 * @returns {string[]} array of months back, i.e. ['January 2019', 'December 2018']
 */
export function getMonthsBack(startDate = new Date(), monthsCount = 0) {
  if (!monthsCount || Number.isNaN(monthsCount)) {
    return [];
  }

  const result = [];
  const date = moment(startDate);
  if (!date.isValid()) {
    return [];
  }
  if (date.date() <= 10) {
    date.subtract(1, 'months');
  }
  for (let i = 1; i <= +monthsCount; i++) {
    date.subtract(1, 'months');
    result.push(`${date.format('MMMM')} ${date.year()}`);
  }

  return result;
}
