export const documentUploadPageSelector = ({
  activeIds,
  fulfillStipulation,
  stipulations,
  uploadDocument,
}) => ({
  activeForgivenessId: activeIds.forgivenessId,
  activeOpportunityId: activeIds.opportunityId,
  activeStipulationId: activeIds.stipulationId,
  fulfillStipulation,
  opportunityId: stipulations.allStipulations?.find(({ id }) => id === activeIds.stipulationId)?.opportunityId,
  uploadDocument,
});
