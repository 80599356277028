import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { email } from 'redux-form-validators';
import { Alert, Button } from '@swift-paypal/pp-react';
import withUrlParams from '../../../hocs/with-url-params';
import floatingLabelField from '../../../lib/forms/floating-label-field';
import { trimWhitespace } from '../../../lib/forms/normalizations';
import errors from '../../../constants/errors';
import constants from '../../../constants';
import './login-form-revamp.less';

const LoginFormRevamp = (props) => {
  const {
    submitFunc, handleSubmit, message, country,
  } = props;

  if (!country) {
    return null;
  }

  return (
    <form className="login-form-revamp" onSubmit={handleSubmit(submitFunc)}>
      {message === errors.authentication
        && (
        <Alert className="alert" type="error">Some of your info is incorrect. Please check it and try again.</Alert>
        )}
      {message === constants.messages.forgotPasswordSuccess
        && (
        <Alert className="alert" type="success">Check your email for password reset instructions.</Alert>
        )}
      <Field
        name="email"
        manualSet
        className="form-control vx_form-control"
        component={floatingLabelField}
        type="text"
        label="Email"
        noErrorClass="margin-bottom-sm"
        validate={[email({ msg: 'Enter a valid email address' })]}
        normalize={trimWhitespace}
      />
      <Field
        name="password"
        manualSet
        className="form-control vx_form-control do-not-record"
        component={floatingLabelField}
        type="password"
        label="Password"
        noErrorClass="margin-bottom-sm"
      />
      <Button type="submit" className="login-button">Log In</Button>
    </form>
  );
};

LoginFormRevamp.propTypes = {
  country: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  submitFunc: PropTypes.func.isRequired,
};

LoginFormRevamp.defaultProps = {
  country: undefined,
  message: undefined,
};

export default compose(
  reduxForm({ form: 'authenticate' }),
  connect(),
  withRouter,
  withUrlParams,
)(LoginFormRevamp);
