import React from 'react';
import some from 'lodash/some';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col,
  Divider,
  Link,
  Row,
  Text,
} from '@swift-paypal/pp-react';
import PhoneNumber from '../phone-number';
import withUrlParams from '../../../hocs/with-url-params';
import opportunityConstants from '../../../constants/opportunity';
import { getProductsAndBfesAction } from '../../../redux/actions/account-items/account-items-actions';
import './bfe-tile.less';

const BfeTile = ({ accounts, bfes, country }) => {
  if (!country || country === 'ca') {
    return null;
  }

  const showBfes = some(accounts, (account) => some(account.inFlightOpportunities,
    (opp) => opp && opp.opportunity && opp.opportunity.stageName
      && (opp.opportunity.stageName !== opportunityConstants.closedWon)
      && (opp.opportunity.stageName !== opportunityConstants.closedLost)));
  const bfe = bfes && bfes.length && !bfes[0]?.isSystemUser ? bfes[0] : undefined;

  return showBfes && bfe ? (
    <Row align="center" className="bfe-tile">
      <Col className="bfe-content">
        {(bfe.firstName || bfe.lastName) && (
        <Row align="center">
          <Col>
            <Text size="lg" className="bfe-name" regular as="strong" data-automation-id="nameLabel">
              {bfe.firstName}
              {(bfe.firstName && bfe.lastName) ? ' ' : ''}
              {bfe.lastName}
            </Text>
          </Col>
        </Row>
        )}

        <Row>
          <Col>
            <Text as="span" size="md">Business Funding Expert</Text>
          </Col>
        </Row>

        {bfe.phone && (
        <Row align="center">
          <Col>
            <a href={`tel:+${bfe.phone}`}>
              <PhoneNumber number={bfe.phone} ext={bfe.extension} dataAutomationId="phoneNumberLabel" />
            </a>
          </Col>
        </Row>
        )}

        {bfe.email && (
          <Row>
            <Col>
              <Link className="mail-link" data-automation-id="emailLink" href={`mailto:${bfe.email}`}>
                {bfe.email}
              </Link>
            </Col>
          </Row>
        )}
        <Divider secondary />
      </Col>
    </Row>
  ) : (<></>);
};

BfeTile.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  bfes: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.string,
};

BfeTile.defaultProps = {
  accounts: undefined,
  bfes: undefined,
  country: undefined,
};

function mapStateToProps(state) {
  return {
    accounts: state.accountItems.accounts,
  };
}

const mapDispatchToProps = {
  getProductsAndBfes: getProductsAndBfesAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlParams,
)(BfeTile);
