import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useShallowSelector } from '../../hooks';
import { activeIdsSelector } from '../../redux/selectors';
import StipulationItem from './stipulation-items/stipulation-item';

const ApplicationStipulationItems = ({ applications, clearStipFunc }) => {
  const { opportunityId } = useShallowSelector(activeIdsSelector);
  const groupStipulations = (stipulations) => _(stipulations).groupBy((x) => x.groupName).map((value, key) => {
    const bankAccountVerificationStip = value.find((x) => x.isBankAccountVerification);
    return {
      groupName: key,
      name: bankAccountVerificationStip ? bankAccountVerificationStip.name : key,
      displayName: key,
      hasFulfilledStipulations: value.filter((x) => x.isFulfilled).length > 0,
      isFulfilled: value.filter((x) => !x.isFulfilled).length === 0,
      stipulations: value.filter((x) => !x.isFulfilled),
      isDocument: value.filter((x) => x.isDocument).length > 0,
      index: getGroupIndex(key),
      isIncompleteOwnershipStipulation: value.filter(
        (x) => !x.showManagers && !x.showOwners && !x.isFulfilled,
      ).length > 0,
      isBankAccountInformation: value.find((x) => x.isBankAccountInformation),
      isBankAccountVerification: value.find((x) => x.isBankAccountVerification),
      isReference: value.filter((x) => x.isVendorReference || x.isLandlordReference).length > 0,
      type: value.filter((x) => !x.isFulfilled).length === 1 ? value.filter((x) => !x.isFulfilled)[0].type : key,
    };
  }).value();

  const getGroupIndex = (groupName) => {
    if (groupName.toLowerCase().includes('bank account')) {
      return 10;
    }
    if (groupName.toLowerCase().includes('business ownership')) {
      return 20;
    }
    if (groupName.toLowerCase().includes('bank statement')) {
      return 30;
    }
    if (groupName.toLowerCase().includes('financial statement')) {
      return 40;
    }
    if (groupName.toLowerCase().includes('identity')) {
      return 50;
    }
    if (groupName.toLowerCase().includes('reference')) {
      return 60;
    }
    return 100;
  };

  return (
    <>
      {applications && !!applications.length && applications.map(
        (application) => (
          application.stages.find((stage) => stage.stageName === application.stage).showStipulations
          && application.id === opportunityId
            ? (
              <div className="stipulation-items" key={application.id}>
                <div data-application-stipulation-text className="vx_text-body-md">
                  Please complete the following action items so that we can proceed with the review of your
                  loan application.
                </div>
                <div data-application-stipulation className="list-items margin-top-xs margin-bottom-xs">
                  {application.stipulations && _.orderBy(groupStipulations(application.stipulations), ['index'])
                    .map((stipulation) => (
                      <StipulationItem
                        key={stipulation.index}
                        stipulation={stipulation}
                        opportunity={application}
                        clearStipFunc={clearStipFunc}
                      />
                    ))}
                </div>
              </div>
            )
            : null),
      )}
    </>
  );
};

ApplicationStipulationItems.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.object),
  clearStipFunc: PropTypes.func,
};

ApplicationStipulationItems.defaultProps = {
  applications: undefined,
  clearStipFunc: undefined,
};

export default ApplicationStipulationItems;
