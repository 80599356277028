import {
  ACTIVE_IDS_CLEAR_ADVANCE_ID,
  ACTIVE_IDS_CLEAR_IDS,
  ACTIVE_IDS_CLEAR_FORGIVENESS_ID,
  ACTIVE_IDS_CLEAR_OPPORTUNITY_ID,
  ACTIVE_IDS_CLEAR_STIPULATION_ID,
  ACTIVE_IDS_SET_ADVANCE_ID,
  ACTIVE_IDS_SET_FORGIVENESS_ID,
  ACTIVE_IDS_SET_OPPORTUNITY_ID,
  ACTIVE_IDS_SET_STIPULATION_ID,
} from './active-ids-action-types';

export const setActiveAdvanceId = (id) => ({
  id,
  type: ACTIVE_IDS_SET_ADVANCE_ID,
});

export const setActiveForgivenessId = (id) => ({
  id,
  type: ACTIVE_IDS_SET_FORGIVENESS_ID,
});

export const setActiveOpportunityId = (id) => ({
  id,
  type: ACTIVE_IDS_SET_OPPORTUNITY_ID,
});

export const setActiveStipulationId = (id) => ({
  id,
  type: ACTIVE_IDS_SET_STIPULATION_ID,
});

export const clearActiveAdvanceId = () => ({ type: ACTIVE_IDS_CLEAR_ADVANCE_ID });

export const clearActiveForgivenessId = () => ({ type: ACTIVE_IDS_CLEAR_FORGIVENESS_ID });

export const clearActiveIds = () => ({ type: ACTIVE_IDS_CLEAR_IDS });

export const clearActiveOpportunityId = () => ({ type: ACTIVE_IDS_CLEAR_OPPORTUNITY_ID });

export const clearActiveStipulationId = () => ({ type: ACTIVE_IDS_CLEAR_STIPULATION_ID });
