import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required, url } from 'redux-form-validators';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import resolve from '../../../services/route.service';
import floatingLabelField from '../../../lib/forms/floating-label-field';
import routes from '../../../routes';
import withUrlParams from '../../../hocs/with-url-params';
import constants from '../../../constants';

const { errors } = constants;

const BusinessWebAddressForm = ({
  handleSubmit,
  country,
}) => {
  const history = useHistory();

  return (
    <div className="form" data-automation-id="businessWebAddressForm">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={10}>
            <Field
              name="website"
              label="Web address"
              manualSet
              component={floatingLabelField}
              type="text"
              className="form-control vx_form-control"
              noErrorClass="margin-bottom-sm"
              validate={[
                required({ msg: errors.requiredField }),
                url({
                  protocolIdentifier: false,
                  msg: 'Please enter a valid URL',
                }),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={5} md={4}>
            <Button type="submit" className="pp-react__button--block continue-button">
              Continue
            </Button>
          </Col>
          <Col xs={6} sm={5} md={4}>
            <Button
              secondary
              type="button"
              className="pp-react__button--block"
              onClick={() => history.push(resolve(routes.settings.path, { country }))}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

BusinessWebAddressForm.propTypes = {
  country: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
};

BusinessWebAddressForm.defaultProps = {
  handleSubmit: undefined,
};

const BusinessWebAddressFormComponent = reduxForm({
  form: 'add-business-web-address',
})(BusinessWebAddressForm);

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(BusinessWebAddressFormComponent);
