import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../common/page-title';
import './maintenance.less';
import gear from '../../static/images/icons/gear.svg';

const MaintenanceComponent = (props) => {
  const { phone, email } = props;

  return (
    <div className="maintenance center-content">
      <div className="gear-icon"><img alt="Gear icon" src={gear} /></div>
      <PageTitle text="We will be back soon." />
      <div>
        Our site is temporarily unavailable as we upgrade it with technology and features.
        Give us a call at
        {' '}
        <a href={`tel:${phone}`}>{phone}</a>
        ,
        Monday - Friday, 9 a.m. - 5 p.m. or email us at
        {' '}
        <a href={`mailto:${email}`}>{email}</a>
        .
      </div>
    </div>
  );
};

MaintenanceComponent.propTypes = {
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default MaintenanceComponent;
