import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  getAccounts,
  renewLoan,
  getFundingAttributes: asyncDebounce(getFundingAttributes),
  getIsRenewalEligible: asyncDebounce(getIsRenewalEligible),
  createPppLead: asyncDebounce(createPppLead),
};

async function getAccounts(apiUrl) {
  const url = `${apiUrl}/v2/accounts`;
  const res = await axios.get(url, defaultRequestOptions());
  return res.data;
}

async function renewLoan(apiUrl, loanId) {
  const campaignId = window.localStorage.getItem('utm');
  const res = await axios.post(`${apiUrl}/accounts/${loanId}/renew`, { campaignId }, defaultRequestOptions());
  return res.data.loanApplicationUrl;
}

async function getFundingAttributes(apiUrl, accountId) {
  const res = await axios.get(`${apiUrl}/accounts/${accountId}/funding-attributes`, defaultRequestOptions());
  return res.data;
}

async function getIsRenewalEligible(apiUrl, accountId) {
  const getIsRenewalEligibleResponse = await axios.get(`${apiUrl}/accounts/${accountId}/is-renewal-eligible`, defaultRequestOptions());

  return getIsRenewalEligibleResponse.data;
}

async function createPppLead(apiUrl, accountId, contactId) {
  const campaignId = window.localStorage.getItem('utm');
  const getCreatePppLeadResponse = await axios.post(`${apiUrl}/accounts/${accountId}/create-ppp-lead`, { contactId, campaignId }, defaultRequestOptions());
  return getCreatePppLeadResponse.data.loanApplicationUrl;
}
