import React from 'react';
import './spinner-standalone.less';

const SpinnerStandaloneComponent = () => (
  <div className="spinner-standalone-container">
    <div className="vx_spinner-large" />
  </div>
);

export default SpinnerStandaloneComponent;
