import MakeAPayment from './features/makeAPayment/makeAPayment';
import ChangePaymentMethod from './features/changePaymentMethod/changePaymentMethod';
import PaymentConfirmation from './features/paymentConfirmation/paymentConfirmation';
import PaymentAuthorization from './features/paymentAuthorization/paymentAuthorization';
import AddBusinessInfo from './features/addBank/addBusinessInfo';
import AddBankInfo from './features/addBank/addBankInfo';

export const PaymentComponentMapping = {
  MakeAPayment,
  ChangePaymentMethod,
  PaymentConfirmation,
  PaymentAuthorization,
  AddBusinessInfo,
  AddBankInfo,
};

export const getPrevNavigationComponent = (componentName, currentBank) => {
  switch (componentName) {
    case 'ChangePaymentMethod':
      return 'MakeAPayment';
    case 'PaymentAuthorization':
      return 'MakeAPayment';
    case 'AddBankInfo':
      return 'AddBusinessInfo';
    case 'AddBusinessInfo': {
      if (!currentBank) return 'MakeAPayment';
      return 'ChangePaymentMethod';
    }
    default:
      return null;
  }
};

export const shouldShowBackAction = (componentName) => !!getPrevNavigationComponent(componentName);

export const bankErrorAlertMapping = {
  AddBankInfo: ['FAILED_INVALID_ROUTING_NUMBER', 'FAILED_INVALID_ACCOUNT', 'FAILED_DEFAULT', 'FAILED_WIRE_ROUTING_NUMBER'],
  AddBusinessInfo: ['FAILED_ACCOUNT_MISMATCH', 'INVALID_BANK_BUSINESS_NAME'],
};

export const PaymentTriggerSource = Object.freeze({
  MakeAPaymentButton: 'MakeAPaymentButton',
  TransactionList: 'TransactionList',
});
