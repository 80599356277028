import { isNumber } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Container, Row, Col, Divider, BodyText, CaptionText,
} from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../hooks';
import util from '../../../util';
import { forgivenessApplicationSidebarSelector } from './forgiveness-application-sidebar-selectors';
import './forgiveness-application-sidebar.less';

const ForgivenessApplicationSidebar = ({ children }) => {
  const {
    advances,
    activeForgivenessId,
    loanForgiveness,
    lastPageSubmitted,
    opportunitiesById,
    page1Values,
    page5Values,
    page6Values,
  } = useShallowSelector(forgivenessApplicationSidebarSelector);

  if (!activeForgivenessId) {
    return <></>;
  }

  const loanForgivenessObject = loanForgiveness.byId[activeForgivenessId];
  const sbaAdvance = advances.byId[loanForgivenessObject?.advanceId];
  const opportunity = opportunitiesById[sbaAdvance?.opportunityId];

  if (!opportunity) {
    return <></>;
  }

  if (!sbaAdvance) {
    return <></>;
  }

  const { dateFirstWireIssued } = sbaAdvance;
  const applicationSubmitted = loanForgivenessObject ? loanForgivenessObject.submittedTimestamp : undefined;

  const advanceAmountString = util.getCurrency(opportunity.advanceAmount);

  let totalPayrollCost,
    fteReductionQuotient,
    coveredStart,
    coveredEnd;

  if (loanForgivenessObject?.standardCoveredPeriodEndDate || page1Values?.standardCoveredPeriodEndDate) {
    coveredStart = moment(dateFirstWireIssued).format('ll');
    coveredEnd = moment(loanForgivenessObject?.standardCoveredPeriodEndDate).format('ll') || moment(page1Values?.standardCoveredPeriodEndDate).format('ll');
  }

  const {
    mortgageInterestPayments,
    utilityPayments,
    rentOrLeasePayments,
  } = page5Values;
  const { actualLoanAmount } = page6Values;

  let requestedLoanForgivenessAmount = actualLoanAmount ? util.getCurrency(actualLoanAmount) : undefined;

  if (loanForgivenessObject) {
    const { actualForgivenessAmount } = loanForgivenessObject;
    requestedLoanForgivenessAmount = (!requestedLoanForgivenessAmount && actualForgivenessAmount)
      ? util.getCurrency(actualForgivenessAmount) : requestedLoanForgivenessAmount;

    totalPayrollCost = loanForgivenessObject.totalPayrollCost;
    fteReductionQuotient = loanForgivenessObject.fteReductionQuotient;
  }
  let totalPayrollCostString,
    totalNonPayrollCostString,
    fteReductionQuotientString;

  if (isNumber(totalPayrollCost)) {
    totalPayrollCostString = util.getCurrency(totalPayrollCost);
  }

  if (mortgageInterestPayments || utilityPayments || rentOrLeasePayments) {
    const totalNonPayrollCost = (mortgageInterestPayments || 0)
      + (utilityPayments || 0)
      + (rentOrLeasePayments || 0);
    totalNonPayrollCostString = util.getCurrency(totalNonPayrollCost);
  }

  if (fteReductionQuotient) {
    fteReductionQuotientString = util.formatFloat(fteReductionQuotient);
  }

  return (
    <Container fluid className="forgiveness-application-sidebar">
      <Row>
        <Col>
          <BodyText as="h2" className="header" strong>PPP Loan Forgiveness Application</BodyText>
        </Col>
      </Row>
      <Divider secondary />
      <Row className="tile-contents">
        <Col>
          <BodyText>{advanceAmountString}</BodyText>
          <CaptionText className="vx_text-body-sm label-caption">Loan Amount</CaptionText>
        </Col>
      </Row>
      {(applicationSubmitted || lastPageSubmitted >= 1)
        && coveredStart
        && coveredEnd
        && (
          <Row className="tile-contents">
            <Col>
              <BodyText>
                {`${coveredStart} `}
                &ndash;
                {` ${coveredEnd}`}
              </BodyText>
              <CaptionText className="vx_text-body-sm label-caption">
                Covered period
              </CaptionText>
            </Col>
          </Row>
        )}
      {(applicationSubmitted && requestedLoanForgivenessAmount) && (
        <Row>
          <Col>
            <BodyText as="h2" className="header" strong>Quick Summary</BodyText>
          </Col>
        </Row>
      )}
      {(lastPageSubmitted >= 5 && !applicationSubmitted) && (
        <Row>
          <Col>
            <BodyText as="h2" className="header" strong>Quick Review</BodyText>
          </Col>
        </Row>
      )}
      {lastPageSubmitted >= 5 && (<Divider secondary />)}
      {!applicationSubmitted && (
        <>
          {lastPageSubmitted >= 5 && totalPayrollCostString && (
            <Row className="tile-contents">
              <Col>
                <BodyText>{totalPayrollCostString}</BodyText>
                <CaptionText className="vx_text-body-sm label-caption">Total payroll cost</CaptionText>
              </Col>
            </Row>
          )}
          {lastPageSubmitted >= 5 && totalNonPayrollCostString && (
            <Row className="tile-contents">
              <Col>
                <BodyText>{totalNonPayrollCostString}</BodyText>
                <CaptionText className="vx_text-body-sm label-caption">Total non-payroll cost</CaptionText>
              </Col>
            </Row>
          )}
          {lastPageSubmitted >= 5 && fteReductionQuotientString && (
            <Row className="tile-contents">
              <Col>
                <BodyText>{fteReductionQuotientString}</BodyText>
                <CaptionText className="vx_text-body-sm label-caption">FTE reduction quotient</CaptionText>
              </Col>
            </Row>
          )}
        </>
      )}
      { applicationSubmitted && requestedLoanForgivenessAmount && (
        <Row className="tile-contents">
          <Col>
            <BodyText>{requestedLoanForgivenessAmount}</BodyText>
            <CaptionText className="vx_text-body-sm label-caption">Requested forgiveness amount</CaptionText>
          </Col>
        </Row>
      )}
      {children}
    </Container>
  );
};

ForgivenessApplicationSidebar.defaultProps = {
  children: null,
};

ForgivenessApplicationSidebar.propTypes = {
  children: PropTypes.element,
};

export default ForgivenessApplicationSidebar;
