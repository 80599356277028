import React, { useEffect } from 'react';
import {
  Container, HeadingText, BodyText,
} from '@swift-paypal/pp-react';
import util, { updateApplicationVersionSelectedEffect } from '../../../util';
import { updateLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { componentSelector, useShallowSelector } from './forgiveness-signoff-completed.selector';
import './forgiveness-signoff-completed.less';

const ForgivenessSignoffCompleted = () => {
  const {
    activeForgivenessId, advancesById, loanForgivenessById,
  } = useShallowSelector(componentSelector);

  const forgivenessObject = loanForgivenessById[activeForgivenessId];
  if (!forgivenessObject) {
    return null;
  }

  const { actualForgivenessAmount, advanceId } = forgivenessObject;
  const { ezAppEligible } = advancesById[advanceId];

  useEffect(updateApplicationVersionSelectedEffect(
    forgivenessObject,
    ezAppEligible,
    updateLoanForgivenessAction,
  ), []);

  const is3508S = forgivenessObject?.applicationVersionSelected === '3508S';
  const isEz = forgivenessObject?.applicationVersionSelected === '3508EZ';

  let formName;
  if (is3508S) {
    formName = 'SBA Form 3508S';
  }
  else if (isEz || ezAppEligible) {
    formName = 'SBA Form 3508EZ';
  }
  else {
    formName = 'SBA Form 3508';
  }

  return (
    <Container className="forgiveness-signoff-completed" fluid>
      <HeadingText size="md">
        We emailed you an updated
        {` ${formName}`}
      </HeadingText>
      <BodyText as="p">
        You&apos;ll need to electronically sign the new
        {` ${formName} `}
        we emailed you before we submit your forgiveness request to the SBA.
      </BodyText>
      <div className="forgiveness-amount">
        <BodyText className="forgiveness-amount__label">Recommended forgiveness amount to the SBA</BodyText>
        <BodyText className="forgiveness-amount__value" strong>
          {util.getCurrency(actualForgivenessAmount)}
        </BodyText>
      </div>
      <BodyText as="p">
        Please contact your Loan Forgiveness Specialist if you don&apos;t
        receive this email within 2 business days or if you have any questions.
      </BodyText>
    </Container>
  );
};

export default ForgivenessSignoffCompleted;
