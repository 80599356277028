import moment from 'moment';

const jwt = require('jsonwebtoken');

function fullStoryService() {
  const service = {
    clearUserCookie,
    setUserInformation,
    startRecording,
    restart,
    shutdown,
  };

  return service;

  function clearUserCookie() {
    window.FS.clearUserCookie();
  }
  function restart() {
    if (window.FS !== undefined && window.FS.restart !== undefined) {
      window.FS.restart();
    }
  }
  function shutdown() {
    if (window.FS !== undefined && window.FS.shutdown !== undefined) {
      window.FS.shutdown();
    }
  }
  function setUserInformation(jwtToken) {
    if (!jwtToken) {
      return;
    }

    const decodedToken = jwt.decode(jwtToken);
    const appVersion = process.env.REACT_APP_VERSION || '0.0.0-local';

    if (decodedToken && decodedToken.guid && decodedToken.email) {
      window.FS.identify(decodedToken.guid.toString(), { email: decodedToken.email });
    }

    if (decodedToken && decodedToken.partner_id !== undefined) {
      window.FS.setUserVars({ partner_id: decodedToken.partner_id });
    }

    window.FS.setUserVars({ app_version: appVersion });
  }

  function startRecording(fullstoryDebug, fullstoryHost, fullstoryOrgId, fullstoryScript, userId, emailAddress) {
    /* eslint-disable camelcase, func-names, no-underscore-dangle */
    window._fs_debug = fullstoryDebug;
    window._fs_host = fullstoryHost;
    window._fs_script = fullstoryScript;
    window._fs_org = fullstoryOrgId;
    window._fs_namespace = 'FS';

    (function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        } return;
      }
      // eslint-disable-next-line no-multi-assign
      g = m[e] = function (a, b) {
        // eslint-disable-next-line no-unused-expressions
        g.q ? g.q.push([a, b]) : g._api(a, b);
      };
      g.q = [];
      o = n.createElement(t);
      o.async = 1;
      o.src = `https://${window._fs_script}/s/fs.js`;
      // eslint-disable-next-line prefer-destructuring
      y = n.getElementsByTagName(t)[0];
      y.parentNode.insertBefore(o, y);
      g.identify = function (i, v) {
        g(l, { uid: i });
        if (v) {
          g(l, v);
        }
      };
      g.shutdown = function () {
        g('rec', !1);
      };
      g.restart = function () {
        g('rec', !0);
      };
      g.setUserVars = function (v) {
        // eslint-disable-next-line no-undef
        FS(l, v);
      }; // eslint-disable-line no-undef
      g.identifyAccount = function (i, v) {
        o = 'account';
        v = v || {};
        v.acctId = i;
        FS(o, v); // eslint-disable-line no-undef
      };
      g.clearUserCookie = function (d, i) {
        d = n.domain;
        // eslint-disable-next-line no-constant-condition
        while (1) {
          n.cookie = `fs_uid=;domain=${d};path=/;expires=${moment(0)}`;
          i = d.indexOf('.');

          if (i < 0) {
            break;
          }

          d = d.slice(i + 1);
        }
      };

      if (emailAddress && userId) {
        window.FS.identify(userId.toString(), { email: emailAddress });
      }
    }(window, document, window._fs_namespace, 'script', 'user'));

    /* eslint-enable camelcase, func-names, no-underscore-dangle */
  }
}

export default fullStoryService;
