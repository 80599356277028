import _ from 'lodash';

export default {
  getAccountStipulations,
};

function getAccountStipulations(opportunityId, opportunities, stipulations) {
  const currentOpportunity = _.head(opportunities.filter((x) => x.opportunity.id === opportunityId));
  if (currentOpportunity) {
    const currentAccount = _.head(stipulations
      .filter((x) => x.accountId === currentOpportunity.accountId));
    if (currentAccount) {
      const accountApplication = _.head(currentAccount.applications
        .filter((x) => x.id === currentOpportunity.opportunity.id));
      if (accountApplication && accountApplication.stipulations && accountApplication.stipulations.length) {
        return accountApplication.stipulations;
      }
    }
  }
  return null;
}
