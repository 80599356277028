import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Col, Row, Badge, Button, HeadingText,
} from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../hooks';
import { activeIdsSelector } from '../../../redux/selectors';
import { setActiveOpportunityId } from '../../../redux/actions/active-ids/active-ids-actions';
import util from '../../../util';
import StandardTerms from './standard-terms';
import RenewalTerms from './renewal-terms';
import './loan-terms.less';

const LoanTerms = ({
  advances,
  hideDisclosures,
  opportunitiesById,
  opportunityId,
  showLoanTerms,
  isSba2Loan,
}) => {
  const { opportunityId: activeOpportunityId } = useShallowSelector(activeIdsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const processedLoans = processAdvances(advances, terms.opportunityId);
    const loanPayoff = processedLoans.reduce((accumulator, loan) => accumulator + loan.totalPayoff, 0);
    const amountAdjustment = opportunity.type === 'SBA' ? 0 : loanPayoff;
    const cashDisbursed = util.conditionallyDisplayCents(terms.loanAmount - amountAdjustment);
    setExistingLoanPayoff(util.conditionallyDisplayCents(loanPayoff));
    setNewCashDisbursed({
      currencySymbol: cashDisbursed[0],
      loanAmount: cashDisbursed.slice(1),
    });
    setMappedLoans(processedLoans);
  }, [advances]);

  const [detailsVisible, setDetailsVisibility] = useState(false);
  const [mappedLoans, setMappedLoans] = useState();
  const [existingLoanPayoff, setExistingLoanPayoff] = useState();
  const [newCashDisbursed, setNewCashDisbursed] = useState({});
  const opportunity = opportunitiesById[opportunityId];

  if (!opportunity) {
    return <></>;
  }

  const terms = {
    loanAmount: opportunity.advanceAmount,
    totalInterestCharge: util.conditionallyDisplayCents(opportunity.premiumAmount),
    totalRepaymentAmount: util.conditionallyDisplayCents(opportunity.repaymentAmount),
    numberOfPayments: opportunity.numberOfPayments,
    weeklyPayment: util.conditionallyDisplayCents(opportunity.averagePayment),
    product: opportunity.product,
    air: opportunity.annualInterestRate,
    opportunityId: opportunity.id,
  };

  function openApplication() {
    dispatch(setActiveOpportunityId(opportunity.id));
  }

  function toggleDetails() {
    setDetailsVisibility(!detailsVisible);
  }

  function processAdvances(advancesToProcess, oppId) {
    return advancesToProcess
      .filter((advance) => advance.opportunityId === oppId)
      .map((advance) => {
        advance.waiveInterest = advance.product === 'EIR' && terms.product === 'EIR';
        advance.totalPayoff = advance.waiveInterest
          ? advance.taPrincipalAdvancePortion
          : advance.taPrincipalAdvancePortion + advance.taInterestPremiumPortion;
        return advance;
      });
  }

  return (
    <div className="loan-terms">
      {isSba2Loan && (
        <HeadingText size="sm">
          PPP Loan
        </HeadingText>
      )}
      {showLoanTerms && (
      <>
        <div className="loan-amount">
          <div className="loan-amount__text">{`${newCashDisbursed.currencySymbol}${newCashDisbursed.loanAmount}`}</div>
          {(opportunity.stageName !== 'Closed Won' && opportunity.stageName !== 'Closed Lost')
            ? <Badge className="loan-amount__badge">NEW</Badge>
            : <></>}
        </div>
        <div className="loan-amount-label vx_text-body-sm">
          {opportunity.renewal ? 'New Cash Disbursed*' : 'Loan Amount'}
        </div>
      </>
      )}
      {opportunity.renewal ? (
        <RenewalTerms
          terms={terms}
          detailsVisible={detailsVisible}
          loans={mappedLoans}
          cashDisbursed={newCashDisbursed}
          existingLoanPayoff={existingLoanPayoff}
        />
      ) : (
        <StandardTerms opportunityId={opportunity.id} detailsVisible={detailsVisible} />
      )}
      {opportunity.id === activeOpportunityId
        && opportunity.stageName !== 'Closed Lost'
        && showLoanTerms
        && !hideDisclosures
        && (
          <Row>
            <Col className="details-toggle">
              <Button inverse className="show-details-button" onClick={toggleDetails}>
                {detailsVisible ? 'Hide details' : 'Show details'}
              </Button>
            </Col>
          </Row>
        )}
      {opportunity.id !== activeOpportunityId
        && (
          <Row>
            <Col className="open-application-toggle">
              <Button secondary className="open-application-button" onClick={openApplication}>
                Open Application
              </Button>
            </Col>
          </Row>
        )}
    </div>
  );
};

LoanTerms.propTypes = {
  advances: PropTypes.arrayOf(PropTypes.shape({
    opportunityId: PropTypes.string,
    product: PropTypes.string,
    taInterestPremiumPortion: PropTypes.number,
    taPrincipalAdvancePortion: PropTypes.number,
  })),
  hideDisclosures: PropTypes.bool.isRequired,
  opportunitiesById: PropTypes.objectOf(PropTypes.shape({
    advanceAmount: PropTypes.number,
    annualInterestRate: PropTypes.number,
    averagePayment: PropTypes.number,
    id: PropTypes.string.isRequired,
    numberOfPayments: PropTypes.number,
    partner: PropTypes.string,
    premiumAmount: PropTypes.number,
    product: PropTypes.string,
    renewal: PropTypes.bool,
    repaymentAmount: PropTypes.number,
    stageName: PropTypes.string,
    type: PropTypes.string,
  })),
  opportunityId: PropTypes.string.isRequired,
  showLoanTerms: PropTypes.bool.isRequired,
  isSba2Loan: PropTypes.bool.isRequired,
};

LoanTerms.defaultProps = {
  advances: [],
  opportunitiesById: {},
};

const mapStateToProps = (state) => ({
  advances: Object.values(state.entities.advances?.byId || {}),
  hideDisclosures: state.config.hideDisclosures,
  opportunitiesById: state.entities.opportunities?.byId,
});

export default connect(mapStateToProps)(LoanTerms);
