import React from 'react';
import log from 'loglevel';
import getDisplayName from '../lib/get-display-name';

/*
 * Enabled making environment variables available to pages loaded on the
 * server or client. This won't work at all unless every page in the app uses
 * this HoC because it relies on the initial server-rendered page to pass it
 * along to client-rendered pages. E.g.
 *
 * ```
 * const injectApiUrl = injectEnvironmentVar('API_URL')
 * const Home = () => <div>Welcome home!</div>
 * export default injectApiUrl(Home)
 * ```
 */

const injectEnvironmentVar = (varName) => (Page) => {
  // Blow the whole thing up if the env var isn't present
  if (!process.browser && typeof (process.env[varName]) === 'undefined') {
    log.error(`${varName} environment variable is required`);
    process.exit(1);
  }

  const InjectEnvironmentVar = (props) => <Page {...props} />;

  InjectEnvironmentVar.displayName = `InjectEnvironmentVar(${getDisplayName(Page)})`;

  return InjectEnvironmentVar;
};

export default injectEnvironmentVar;
