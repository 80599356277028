import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useShallowSelector, useActiveForgivenessSelected } from '../hooks';
import BetterStandardLayout from '../layouts/better-standard-layout/better-standard-layout';
import ForgivenessSignoffCompleted from '../components/forgiveness/customer-signoff-completed/forgiveness-signoff-completed';
import { ForgivenessApplicationSidebar } from '../components/forgiveness/forgiveness-application-sidebar';
import CaseWorkerTile from '../components/common/case-worker-tile/case-worker-tile';
import SecurePage from '../hocs/secure-page';
import displayNames from '../constants/displayNames';

const ForgivenessSignoffCompletedPage = () => {
  const {
    activeForgivenessId,
    loanForgiveness,
  } = useShallowSelector(forgivenessSignoffCompletedSelector);
  useActiveForgivenessSelected();

  if (!loanForgiveness.byId[activeForgivenessId]) {
    return null;
  }

  const activeForgivenessObject = loanForgiveness.byId[activeForgivenessId];
  const { caseWorker } = activeForgivenessObject;
  return (
    <div className="summary-container">
      <Helmet>
        <title>Summary</title>
      </Helmet>
      <BetterStandardLayout
        bodyElement={<ForgivenessSignoffCompleted />}
        sidebarElement={<ForgivenessApplicationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

ForgivenessSignoffCompletedPage.displayName = displayNames.ForgivenessSignoffCompletedPage;

const forgivenessSignoffCompletedSelector = (state) => ({
  activeForgivenessId: state.activeIds.forgivenessId,
  loanForgiveness: state.entities.loanForgiveness,
});

export default SecurePage(ForgivenessSignoffCompletedPage);
