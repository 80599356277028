import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { required } from 'redux-form-validators';
import { BodyText, TextInput } from '@swift-paypal/pp-react';
import constants from '../../../../constants';

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <TextInput {...input} {...props} />;

const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

const { errors } = constants;

const EmployeeRetirementPlans = ({
  error, submitted, touched, value,
}) => (
  <>
    <BodyText as="label" htmlFor="employeeRetirementPlans">
      Total employer contributions paid or incurred for employee retirement plans
    </BodyText>
    <Field
      className="field__mapped-input"
      key="employee-retirement-plans"
      name="employeeRetirementPlans"
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      placeholder="$"
      value={value}
      validate={[required({ msg: errors.currencyZeroError })]}
      {...currencyMask}
    />
  </>
);

EmployeeRetirementPlans.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
  value: undefined,
};

EmployeeRetirementPlans.propTypes = {
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.number,
};

export default memo(EmployeeRetirementPlans);
