import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BodyText } from '@swift-paypal/pp-react';
import { entityGroups } from './payroll-verification-constants';
import EntityGroupOne from './entity-group-one';
import EntityGroupTwo from './entity-group-two';
import EntityGroupThree from './entity-group-three';
import EntityGroupFour from './entity-group-four';
import EntityGroupFive from './entity-group-five';
import EntityGroupSix from './entity-group-six';
import './payroll-verification-content.less';

const PayrollVerificationContent = ({ opportunityId }) => {
  const { opportunitiesById } = useSelector(payrollVerificationContentSelector, shallowEqual);
  const { businessEntityType, numberOfEmployees, payPalExistingRelationship } = opportunitiesById[opportunityId];

  const entityGroup = Object.entries(entityGroups).find(
    ([, value]) => value.includes(businessEntityType),
  )?.[0];

  return (
    <>
      <BodyText className="payroll-verification__content-item">
        In order for us to continue processing your loan, please provide the documentation necessary
        to substantiate the amounts you provided on the Payroll Data Calculation form you completed
        and submitted through the application portal.
      </BodyText>

      <BodyText className="payroll-verification__content-item">
        You will need to provide this documentation
        {' '}
        <strong>
          for the timeframe that you selected
        </strong>
        {' '}
        (e.g., calendar year 2019, 2020, or other timeframe, as permitted under the SBA PPP rules).
        {payPalExistingRelationship && (
          <strong>
            {' '}
            If you received your initial PPP loan through PayPal and are planning to use 2019 payroll
            data you previously provided to us for the purposes of your Second Draw PPP loan,
            you do not need to provide your 2019 payroll documents again, unless we request additional
            or clarifying documentation.
          </strong>
        )}
      </BodyText>

      <BodyText className="payroll-verification__content-item">
        <strong>
          {payPalExistingRelationship ? 'However' : 'In addition'}
          , all applicants are required to provide documentation that
          demonstrates that their business was in operation on Feb 15, 2020.
        </strong>
      </BodyText>

      {!entityGroup && <EntityGroupOne paypalExistingRelationship={payPalExistingRelationship} />}
      {entityGroup === 'groupOne' && <EntityGroupOne paypalExistingRelationship={payPalExistingRelationship} />}
      {entityGroup === 'groupsTwoAndThree' && numberOfEmployees <= 1
        && <EntityGroupTwo paypalExistingRelationship={payPalExistingRelationship} />}
      {entityGroup === 'groupsTwoAndThree' && numberOfEmployees > 1
        && <EntityGroupThree paypalExistingRelationship={payPalExistingRelationship} />}
      {entityGroup === 'groupFour' && <EntityGroupFour payPalExistingRelationship={payPalExistingRelationship} />}
      {entityGroup === 'groupFive' && <EntityGroupFive payPalExistingRelationship={payPalExistingRelationship} />}
      {entityGroup === 'groupSix' && <EntityGroupSix payPalExistingRelationship={payPalExistingRelationship} />}

      {!payPalExistingRelationship && (
        <BodyText className="payroll-verification__content-item">
          The documents you upload must support the amounts entered into your Payroll
          Data Calculation form you completed and submitted through the application portal.
        </BodyText>
      )}
    </>
  );
};

const payrollVerificationContentSelector = (state) => ({
  opportunitiesById: state.entities.opportunities.byId,
});

PayrollVerificationContent.propTypes = {
  opportunityId: PropTypes.string.isRequired,
};

export default PayrollVerificationContent;
