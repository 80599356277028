import { combineReducers } from 'redux';
import {
  ACTIVE_IDS_CLEAR_FORGIVENESS_ID,
  ACTIVE_IDS_CLEAR_ADVANCE_ID,
  ACTIVE_IDS_CLEAR_IDS,
  ACTIVE_IDS_CLEAR_OPPORTUNITY_ID,
  ACTIVE_IDS_CLEAR_STIPULATION_ID,
  ACTIVE_IDS_SET_ADVANCE_ID,
  ACTIVE_IDS_SET_FORGIVENESS_ID,
  ACTIVE_IDS_SET_STIPULATION_ID,
  ACTIVE_IDS_SET_OPPORTUNITY_ID,
} from '../actions/active-ids/active-ids-action-types';

function advanceId(state = '', action) {
  switch (action.type) {
    case ACTIVE_IDS_SET_ADVANCE_ID:
      return action.id;
    case ACTIVE_IDS_CLEAR_IDS:
    case ACTIVE_IDS_CLEAR_ADVANCE_ID:
      return '';
    default:
      return state;
  }
}

function forgivenessId(state = '', action) {
  switch (action.type) {
    case ACTIVE_IDS_SET_FORGIVENESS_ID:
      return action.id;
    case ACTIVE_IDS_CLEAR_IDS:
    case ACTIVE_IDS_CLEAR_FORGIVENESS_ID:
      return '';
    default:
      return state;
  }
}

function opportunityId(state = '', action) {
  switch (action.type) {
    case ACTIVE_IDS_SET_OPPORTUNITY_ID:
      return action.id;
    case ACTIVE_IDS_CLEAR_IDS:
    case ACTIVE_IDS_CLEAR_OPPORTUNITY_ID:
      return '';
    default:
      return state;
  }
}

function stipulationId(state = '', action) {
  switch (action.type) {
    case ACTIVE_IDS_SET_STIPULATION_ID:
      return action.id;
    case ACTIVE_IDS_CLEAR_IDS:
    case ACTIVE_IDS_CLEAR_STIPULATION_ID:
      return '';
    default:
      return state;
  }
}

export const activeIds = combineReducers({
  advanceId,
  forgivenessId,
  opportunityId,
  stipulationId,
});
