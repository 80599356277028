import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme as useEmotionTheme } from '@emotion/react';
import {
  Card, CaptionText, HeadingText, BodyText, Button, Row, Col,
} from '@paypalcorp/pp-react';
import '../oneTimePaymentPage.less';
import util from '../../../../util';
import constants from '../../../../constants';

const PaymentTile = ({ loanAdvance, buttonClickHandler }) => {
  const { sysColorBackgroundMediumContrast } = useEmotionTheme();

  const isMakePaymentBtnVisible = loanAdvance.IsEligibeForSSACH && loanAdvance.Balance > 0;

  const getValidationText = useCallback(() => {
    if (!(loanAdvance?.Balance > 0)) {
      return 'The loan currently has no outstanding balance.';
    }
    if (!loanAdvance.IsEligibeForSSACH) {
      return 'You are not able to make a one-time payment online at this time.';
    }
    return 'You are not able to make a one-time payment online at this time.';
  }, [loanAdvance.IsEligibeForSSACH]);

  return (
    <div className="payment-tile-wrapper">
      <Row>
        <Col
          md={7}
          xs={12}
        >
          <Card size="sm" className="payment-tile">
            <HeadingText size="title">PayPal Business Loan</HeadingText>
            <HeadingText size="lg">{util.generateCurrencyText(loanAdvance.Balance)}</HeadingText>
            <CaptionText style={{ color: sysColorBackgroundMediumContrast }}>
              Current balance
            </CaptionText>
            {isMakePaymentBtnVisible ? (
              <Button onClick={buttonClickHandler} className="payment-btn" size="md">
                Make a One-Time Payment
              </Button>
            ) : (
              <BodyText>
                {getValidationText()}
                <br />
                Please call us at
                {` ${constants.oneTimePayments.customerCarePhoneNumber} `}
                for more information.
              </BodyText>
            )}
            {loanAdvance.PastDueAmountWithFees ? (
              <CaptionText style={{ color: sysColorBackgroundMediumContrast }}>
                Your account is
                {' '}
                {util.generateCurrencyText(loanAdvance.PastDueAmountWithFees)}
                {' '}
                past due
              </CaptionText>
            ) : null}
          </Card>
        </Col>

      </Row>

    </div>
  );
};

PaymentTile.propTypes = {
  buttonClickHandler: PropTypes.func.isRequired,
  loanAdvance: PropTypes.shape({
    Balance: PropTypes.any,
    IsEligibeForSSACH: PropTypes.any,
    PastDueAmountWithFees: PropTypes.any,
  }).isRequired,
};

export default PaymentTile;
