import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RadioButton, BodyText } from '@swift-paypal/pp-react';
import { required } from 'redux-form-validators';
import constants from '../../constants';
import './yes-or-no-radio-field.less';

const { errors } = constants;

// eslint-disable-next-line react/prop-types
const MappedInput = ({ input, ...props }) => <RadioButton {...input} {...props} />;

const YesOrNoRadioField = ({
  error, submitted, touched, value, name, content,
}) => (
  <>
    <BodyText as="label" htmlFor={name}>
      {content}
    </BodyText>
    <Field
      className="yes-or-no-radio-field"
      name={name}
      value={value}
      errorText={submitted || touched ? error : undefined}
      component={MappedInput}
      options={[
        {
          label: 'Yes',
          value: 'true',
          className: 'radio-item',
        },
        {
          label: 'No',
          value: 'false',
          className: 'radio-item',
        },
      ]}
      validate={[
        required({ msg: errors.chooseYesOrNo }),
      ]}
    />
  </>
);

YesOrNoRadioField.defaultProps = {
  error: undefined,
  submitted: false,
  touched: false,
  value: undefined,
};

YesOrNoRadioField.propTypes = {
  error: PropTypes.string,
  submitted: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default memo(YesOrNoRadioField);
