import { isSafari, isIE } from 'react-device-detect';
import { createNumberMask } from 'redux-form-input-masks';
import { isSafariOrIeValidationPayload, isNotSafariOrIeValidationPayload } from './calculate-payroll-data-constants';

export const currencyMask = createNumberMask({
  allowEmpty: true,
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
});

export const safariOrIeDateValidation = () => {
  const result = isSafari || isIE ? isSafariOrIeValidationPayload : isNotSafariOrIeValidationPayload;
  return result;
};
