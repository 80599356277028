import migratedFlowConstant from './migratedFlowConstant';

export default {
  // add routes that need a jwt token of 'authenticated' type
  ROUTES_REQUIRING_AUTHENTICATED_TOKEN:
    [
      '/security-question',
    ],

  // add routes that need a special key or non-jwt token to complete the flow
  ROUTES_REQUIRING_SECRET_KEY:
    [
      '/reset-password',
      '/reset-account',
      '/change-security-questions',
    ],

  // add routes that can be visited by an anonymous user
  ROUTES_ACCESSIBLE_BY_ANONYMOUS_USER:
    [
      '/confirm-paypal-account',
      `/${migratedFlowConstant.URL_APPEND}/confirm-paypal-account`,
      '/oauth/landing',
      `/${migratedFlowConstant.URL_APPEND}/oauth/landing`,
    ],
};
