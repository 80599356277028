import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveOpportunityId } from '../redux/actions/active-ids/active-ids-actions';
import { documentUploadPageSelector } from '../redux/selectors';
import { useRedirect, useShallowSelector } from '.';

/**
 * @param redirectParams Params for the redirect callback.
 * @param redirectParams.path The path to redirect to.
 * @param redirectParams.additionalParams Additional Params for the redirect util.
 */
export function useDocumentUploadPageEffect({ path, additionalParams }) {
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const {
    activeForgivenessId,
    activeOpportunityId,
    activeStipulationId,
    fulfillStipulation,
    opportunityId,
    uploadDocument,
  } = useShallowSelector(documentUploadPageSelector);

  useEffect(() => {
    if (!fulfillStipulation?.pending && fulfillStipulation?.success) {
      redirect(path, additionalParams);
    }

    if (!uploadDocument.uploadPending
      && !uploadDocument.error
      && !uploadDocument.failedFiles?.length
      && uploadDocument.uploadedFiles?.length > 0) {
      redirect(path, additionalParams);
    }
  }, [fulfillStipulation, uploadDocument]);

  useEffect(() => {
    if (!activeForgivenessId || !activeStipulationId || !opportunityId) {
      redirect(path, additionalParams);
    }
    else if (activeOpportunityId !== opportunityId) {
      dispatch(setActiveOpportunityId(opportunityId));
    }
  }, [activeForgivenessId, activeOpportunityId, activeStipulationId, opportunityId]);
}
