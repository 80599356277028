import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowSelector } from '../../../hooks';
import { getLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import { ForgivenessSummaryTile } from '../../summary/forgiveness-summary-tile';
import { PppLoanPaymentDeferral } from '..';
import { forgivenessTileListSelector } from './forgivenessTileList.selectors';
import { getForgivenessAdvanceIds } from './forgivenessTileList.utils';

export const ForgivenessTileList = () => {
  const dispatch = useDispatch();
  const {
    advances, opportunities, portalRedesignEnabled, ppp21LoFoEnabled,
  } = useShallowSelector(forgivenessTileListSelector);

  useEffect(() => {
    const advanceIdsWithForgivenessIds = advances.allIds.filter((id) => (
      advances.byId[id]?.type === 'SBA' && advances.byId[id]?.loanForgivenessId
    ));
    if (advanceIdsWithForgivenessIds?.length > 0) {
      dispatch(getLoanForgivenessAction(advanceIdsWithForgivenessIds));
    }
  }, [advances]);

  const forgivenessAdvanceIds = getForgivenessAdvanceIds(advances, opportunities, ppp21LoFoEnabled);

  if (!forgivenessAdvanceIds?.length) {
    return null;
  }

  return (
    <>
      {forgivenessAdvanceIds.map((forgivenessAdvanceId) => (
        <ForgivenessSummaryTile advanceId={forgivenessAdvanceId} key={forgivenessAdvanceId} />
      ))}
      {!portalRedesignEnabled && <PppLoanPaymentDeferral />}
    </>
  );
};
