import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BodyText, Col, Row } from '@swift-paypal/pp-react';
import YesOrNoRadioField from '../../common/yes-or-no-radio-field';
import {
  fieldNames,
  q3Prompt2020,
  q3Promot2021,
  q3Question,
} from './field-constants';
import { useShallowSelector, fieldSelector } from '../questionnaire-selectors';

const QuestionnaireQ3 = ({ loanVersion }) => {
  const {
    questionnaireQ3: fieldName,
  } = fieldNames;

  const { error, touched, value } = useShallowSelector(fieldSelector, fieldName);

  return (
    <Row>
      <Col form="full">
        <YesOrNoRadioField
          content={generateContent(loanVersion)}
          error={error}
          name={fieldName}
          touched={touched}
          value={value}
        />
      </Col>
    </Row>
  );
};

const generateContent = (loanVersion) => (
  <>
    <BodyText>
      {q3Question}
    </BodyText>
    <BodyText as="p" className="ppvx_radio-group__helper-text">
      {
        loanVersion === '20PPPLoan' && (
          <>
            {q3Prompt2020}
          </>
        )
      }
      {
        loanVersion !== '20PPPLoan' && (
          <>
            {q3Promot2021}
          </>
        )
      }
    </BodyText>
  </>
);

QuestionnaireQ3.propTypes = {
  loanVersion: PropTypes.string,
};

QuestionnaireQ3.defaultProps = {
  loanVersion: undefined,
};

export default memo(QuestionnaireQ3);
