import { asyncNoAuthActionCreator } from '../util/async-action-creator';
import legalService from '../../../services/legal.service';
import * as actions from './terms-action-types';

export default asyncNoAuthActionCreator(
  {
    pending: actions.TERMS_OF_USE_REQUEST_PENDING,
    complete: actions.TERMS_OF_USE_REQUEST_RECEIVED,
    error: actions.TERMS_OF_USE_REQUEST_ERROR,
  },
  (country) => (dispatch, getState) => {
    const { config } = getState();
    return legalService.getTermsOfUse(config.apiUrl, country);
  },
);
