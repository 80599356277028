/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HeadingText, BodyText, TextInput, Icon, Table, Row, Col } from '@swift-paypal/pp-react';
import './routing-number-warning-component.less';
import constants from '../../../constants';

const RoutingNumberList = () => {
  const stateMap = {};
  constants.states.forEach((el) => {
    stateMap[el.abbreviation] = el.name;
  });
  const routingInstitutionInfo = useSelector((state) => state?.getRoutingInstitution);
  const settingsState = useSelector((state) => state.settings);
  const businessState = settingsState?.contactAccounts[0]?.account?.state || '';
  const businessStateMapping = businessState === '' ? '' : stateMap[businessState];
  const routingInstitutionList = routingInstitutionInfo?.institutionList || [];
  const selectedRoutingBank = routingInstitutionInfo?.withdrawalInstitution?.name || '';
  const bankDetailsAvailable = constants.bankDetails[selectedRoutingBank] !== undefined;
  const [routingInformation, setRoutingInformation] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableRow, setTableRows] = useState(rows);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const mapRouting = routingInstitutionList.reduce((accumulator, routingInfo) => {
      const bankCatergory = accumulator.find((item) => item.bankName === routingInfo.Name);
      const routingVal = { routingNumber: routingInfo.RoutingNumber__c, stateCode: stateMap[routingInfo.StateCode__c] };
      if (bankCatergory) {
        bankCatergory.routingDetails.push(routingVal);
      }
      else {
        accumulator.push({ bankName: routingInfo.Name, routingDetails: [routingVal] });
      }
      return accumulator;
    }, []);
    setRoutingInformation(mapRouting);
  }, []);

  const createRows = (bankRoutingList) => {
    const rowArray = [];
    bankRoutingList.forEach((listEl, index) => {
      const rowObj = {};
      rowObj.id = `row-${index}`;
      rowObj.state = { value: listEl?.stateCode };
      rowObj.routingNumber = { value: listEl?.routingNumber };
      rowArray.push(rowObj);
    });
    return rowArray;
  };
  const searchChangeHandler = (value) => {
    setSearchValue(value);
    updateTableValue(value);
  };
  const updateTableValue = (val) => {
    if (val === '') {
      setTableRows(rows);
    }
    else {
      const filteredRows = rows?.filter((el) => el.state.value.toLowerCase().startsWith(val.toLowerCase()));
      setTableRows(filteredRows);
    }
  };
  useEffect(() => {
    if (selectedRoutingBank !== '' && routingInformation.length > 0) {
      const bankRoutingList = routingInformation.filter((el) => el.bankName === selectedRoutingBank);
      if (bankRoutingList.length > 0) {
        const builtRows = createRows(bankRoutingList[0]?.routingDetails);
        builtRows.sort((a, b) => a.state.value.localeCompare(b.state.value));
        setRows(builtRows);
        setTableRows(builtRows);
      }
    }
  }, [routingInformation, selectedRoutingBank]);
  return (
    <div>
      <HeadingText size="md" theme="v2">{constants.messages.routingNumberModalHeading}</HeadingText>
      <br />
      <BodyText>
        {constants.messages.routingNumberModalMessage}
      </BodyText>
      <br />
      <TextInput
        name="help2"
        value={searchValue}
        label="Search Bank Account State"
        theme="v2"
        leftIcon={<Icon size="sm" name="search" />}
        onChange={(e) => searchChangeHandler(e.target.value)}
      />
      <br />
      <Row className="demo-row">
        { bankDetailsAvailable && <Col xs="auto" sm="auto" md="auto"><img src={constants.bankDetails[selectedRoutingBank].logo} alt="" /></Col> }
        <Col>
          <HeadingText size="sm">
            { bankDetailsAvailable ? constants.bankDetails[selectedRoutingBank].name : selectedRoutingBank }
          </HeadingText>
        </Col>
      </Row>

      <br />
      <div>
        <Table style={{ 'max-height': '40rem' }} align="center" scroll>
          <thead>
            <tr>
              <th className="table-row-width">{constants.messages.routingNumberModalTableBankAcct}</th>
              <th className="table-row-width">{constants.messages.routingNumberModalTableRoutingNo}</th>
            </tr>
          </thead>
          <tbody>
            {
              tableRow?.map((el) => (
                <tr id={el.id} className={businessStateMapping.toLowerCase() === el.state.value.toLowerCase() ? 'state-row' : ''}>
                  <td>{el.state.value}</td>
                  <td>{el.routingNumber.value}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </div>

  );
};

export default RoutingNumberList;
