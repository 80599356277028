import { allowedFileTypes, allowedSingleFileSize } from '../../../constants/upload-document';

export const HEADING_TEXT = 'Reduction in Gross Receipts Documents';
export const UPLOAD_HELPER_TEXT = 'Upload your Gross Receipts Documents';

export const AllowedFileExtensions = [
  ...allowedFileTypes.csv,
  ...allowedFileTypes.images,
  ...allowedFileTypes.pdf,
  ...allowedFileTypes.xlsx,
  ...allowedFileTypes.docx,
];

export const AllowedSingleFileSize = allowedSingleFileSize;
