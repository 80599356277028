import React, { Component } from 'react';
import getDisplayName from '../lib/get-display-name';
import { addEventListener, removeEventListener } from '../services/window.service';

/*
 * Dredges up a `token` object from cookie or localStorage and, if present,
 * injects it as a prop. Also keeps track of the current token in
 * component state so that multiple tabs open in the same browser can react
 * to sign ins/outs.
 */

const injectToken = (Page) => {
  class InjectToken extends Component {
    constructor(props) {
      super(props);
      // As the token changes we need a spot to store in a way that updates
      // children components
      this.state = {};
    }

    componentDidMount() {
      // Use component state to track the token
      if (process.browser) {
        addEventListener('storage', this.handleStorageChange);
      }
    }

    componentWillUnmount() {
      // Stop tracking token
      if (process.browser) {
        removeEventListener('storage', this.handleStorageChange);
      }
    }

    handleStorageChange = (event) => {
      // Keep component state up to date with current token as it changes.
      // This is so other tabs open in the same browser can respond to token changes.
      if (event.key === 'token') {
        if (event.newValue) {
          this.setState({ token: typeof event.newValue === 'string' ? event.newValue : JSON.parse(event.newValue) });
        }
        else {
          this.setState({ token: null });
        }
      }
    };

    render() {
      // Pass on whatever props exist and state
      return <Page {...this.props} {...this.state} />;
    }
  }

  InjectToken.displayName = `InjectToken(${getDisplayName(Page)})`;
  return InjectToken;
};

export default injectToken;
