import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';
import { CoveredCostsDetailsContent } from './coveredCostsDocuments.constants';

export const CoveredCostsDocumentsDetails = () => CoveredCostsDetailsContent.map(({ key, header, body }) => (
  <BodyText as="p" className="margin-top-sm body-text" key={key}>
    <BodyText strong>{header}</BodyText>
    {body}
  </BodyText>
));
