import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@swift-paypal/pp-react';
import withUrlParams from '../../../hocs/with-url-params';
import './renewal-tile.less';

class RenewalTile extends React.Component {
  static propTypes = {
    country: PropTypes.string,
    loan: PropTypes.shape({
      accountName: PropTypes.string,
      accountId: PropTypes.string,
    }).isRequired,
    renewFunc: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: undefined,
  };

  constructor(props) {
    super(props);
    this.renew = this.renew.bind(this);
  }

  renew() {
    const { loan, renewFunc } = this.props;
    renewFunc(loan.accountId);
  }

  render() {
    const { country, loan } = this.props;

    if (!country || country === 'ca') {
      return null;
    }

    return (
      <div className="renewal-tile">
        <div className="renewal-well">
          <div className="vx_panel-info">
            <div className="renewal-header">You are now eligible to apply for another loan.</div>
            <div className="vx_panel-text reversed">
              Complete the online questionnaire to see if
              {' '}
              {loan.accountName}
              {' '}
              may qualify for additional funding.
            </div>
            <div>
              <Button inverse type="button" className="fixed-btn" onClick={this.renew}>
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(RenewalTile);
