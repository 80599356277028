export const forgivenessGroupNames = [
  'Demographic Information',
  'Forgiveness Demographic Information',
  'FTE Documents',
  'Mortgage Interest Documents',
  'Payroll Supporting Documents',
  'Rent Documents',
  'SBA Form 3508',
  'SBA Form 3508S',
  'Schedule A Worksheet',
  'Utilities Documents',
];

export const mapParamToName = {
  'demographic-information': 'Demographic Information',
  'forgiveness-demographic-information': 'Forgiveness Demographic Information',
  'fte-documents': 'FTE Documents',
  'mortgage-interest-documents': 'Mortgage Interest Documents',
  'payroll-supporting-documents': 'Payroll Supporting Documents',
  'rent-documents': 'Rent Documents',
  'sba-form-3508': 'SBA Form 3508',
  'sba-form-3508s': 'SBA Form 3508S',
  'schedule-a-worksheet': 'Schedule A Worksheet',
  'utilities-documents': 'Utilities Documents',
};

export const mapNameToParam = {
  'Demographic Information': 'demographic-information',
  'Forgiveness Demographic Information': 'forgiveness-demographic-information',
  'FTE Documents': 'fte-documents',
  'Mortgage Interest Documents': 'mortgage-interest-documents',
  'Payroll Supporting Documents': 'payroll-supporting-documents',
  'Rent Documents': 'rent-documents',
  'SBA Form 3508': 'sba-form-3508',
  'SBA Form 3508S': 'sba-form-3508s',
  'Schedule A Worksheet': 'schedule-a-worksheet',
  'Utilities Documents': 'utilities-documents',
};

export const forgivenessOptOutTextByGroup = {
  'Demographic Information': 'Or click here if you\'d prefer not to upload the PPP Demographic Information Form (optional).',
  'Forgiveness Demographic Information': 'Or click here if you\'d prefer not to upload the PPP Demographic Information Form.',
  'FTE Documents': 'Or click here if you aren\'t uploading documents because you are using the SBA 3508EZ Form.',
  'Mortgage Interest Documents': 'Or click here if you aren\'t uploading documents '
    + 'because you didn\'t include business mortgage interest expenses in your forgiveness application.',
  'Payroll Supporting Documents': undefined,
  'Rent Documents': 'Or click here if you aren’t uploading documents because you didn\'t include business rent/lease '
    + 'expenses in your forgiveness application.',
  'Schedule A Worksheet': 'Or click here if you aren\'t going to upload documents because you are using the SBA 3508EZ '
    + 'Form or the Standard SBA 3508 Form and you prefer not to upload the Schedule A Worksheet.',
  'SBA Form 3508': undefined,
  'SBA Form 3508S': undefined,
  'Utilities Documents': 'Or click here if you aren\'t uploading documents because you didn\'t include business utility '
    + 'expenses in your forgiveness application.',
};

export const forgivenessAppEnabledScheduleAWorksheet = 'Or click here if you prefer not to upload the Schedule A Worksheet.';

export const forgivenessHelperTextByGroup = {
  'Demographic Information': 'Upload the PPP Borrower Demographic Information Form',
  'Forgiveness Demographic Information': 'Upload the PPP Borrower Demographic Information Form',
  'FTE Documents': 'Upload FTE Documents',
  'Mortgage Interest Documents': 'Upload business mortgage interest documents',
  'Payroll Supporting Documents': 'Upload payroll documents',
  'Rent Documents': 'Upload business rent/lease documents',
  'Schedule A Worksheet': 'Upload your completed Schedule A Worksheet',
  'Utilities Documents': 'Upload business utility documents',
};

export const forgivenessStipPageTitle = {
  'Demographic Information': 'Borrower demographic information form',
  'Forgiveness Demographic Information': 'Borrower demographic information form',
  'FTE Documents': 'FTE documents',
  'Rent Documents': 'Business rent/lease documents',
  'Payroll Supporting Documents': 'Payroll documents',
  'Utilities Documents': 'Business utility documents',
  'Mortgage Interest Documents': 'Business mortgage interest documents',
};

export default {
  forgivenessAppEnabledScheduleAWorksheet,
  forgivenessGroupNames,
  forgivenessOptOutTextByGroup,
  forgivenessStipPageTitle,
};
