import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import UploadMultipleDocumentsComponent from '../../components/upload-multiple-documents/upload-multiple-documents';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadBatchDocumentsAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import getStipulationsAction from '../../redux/actions/stipulations/stipulations-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { CreditStipulations } from '../../components/features/stipulations/stipulations.constants';
import { getMonthsBack } from '../../lib/stipulation-utils';

const stipulationGroupName = 'BankStatements';

class UploadCanadianBankStatementsPage extends Component {
  static displayName = displayNames.UploadCanadianBankStatementsPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    getProductsAndBfes: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
      applicationReceivedDate: PropTypes.string,
      numberOfBankStatementsAlreadyUploaded: PropTypes.number,
    })),
    push: PropTypes.func.isRequired,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.bool,
    stipulations: PropTypes.array,
    getStipulations: PropTypes.func.isRequired,
  };

  static defaultProps = {
    country: undefined,
    match: undefined,
    opportunitiesById: {},
    uploadPending: true,
    uploadError: false,
    stipulations: [],
  };

  async componentDidMount() {
    const {
      getProductsAndBfes,
      getOpportunities,
      match,
      opportunitiesById,
    } = this.props;
    const { opportunityId } = match.params;
    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
  }

  async componentDidUpdate() {
    const {
      getStipulations,
      stipulations,
      country,
      match,
    } = this.props;
    const { opportunityId } = match.params;
    if (!stipulations.length) {
      await getStipulations(opportunityId, country);
    }
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = (files) => {
    const { match, uploadBatchDocuments } = this.props;
    const { opportunityId } = match.params;
    const payloads = [];
    files.forEach((file) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, stipulationGroupName));
      }
    });
    uploadBatchDocuments(payloads);
  }

  redirectToSummaryConfirmation() {
    const { country, push, match } = this.props;
    const { opportunityId } = match.params;
    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      uploadPending, uploadError, country, opportunitiesById, match, stipulations,
    } = this.props;
    const { opportunityId } = match.params;

    // eslint-disable-next-line max-len
    const bankStip = stipulations.find(({ stipulationMap }) => stipulationMap.name === CreditStipulations.BankStatements);
    const fakeStips = [];
    const opportunity = opportunitiesById[opportunityId];

    if (opportunity && bankStip) {
      const { countRequired } = bankStip;
      const startDate = moment(opportunity.applicationReceivedDate).subtract(opportunity.numberOfBankStatementsAlreadyUploaded, 'months');
      const monthsRequired = getMonthsBack(startDate, countRequired);

      monthsRequired.forEach((x) => {
        fakeStips.push({
          displayName: x,
          description: (
            <>
              <p>
                <strong>
                  Please upload a copy of your business bank statement to verify your bank account information.
                </strong>
              </p>
            </>
          ),
        });
      });
    }

    let pageTitle = 'Verify bank information';

    let groupDescription = 'Please upload a document to verify your bank account. The business name and '
      + 'address should match the information provided on your application.';

    let fakeStipulation = {
      displayName: 'Upload documents',
      description: (
        <>
          <p>
            <strong>
              {country === 'ca'
                ? 'Upload most recent bank statement or voided cheque'
                : 'Please upload a copy of your business bank statement to verify your bank account information.'}
            </strong>
          </p>
        </>
      ),
    };

    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;
    const showPPPLoan = ['21PPPLoan', '21SDPPP', '20PPPLoan'].includes(loanVersion);

    if (country !== 'ca' && showPPPLoan) {
      pageTitle = 'Verify bank information';

      groupDescription = 'Please upload a recent bank statement to verify your bank account. The business name and '
        + 'address should match the information provided on your PPP Loan application.';

      fakeStipulation = {
        displayName: 'Upload a recent bank statement',
      };
    }

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    return (
      <>
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StandardLayout
            contentElement={(
              <div>
                <PageTitle text={pageTitle} />
                <UploadMultipleDocumentsComponent
                  groupDescription={groupDescription}
                  uploadError={uploadError}
                  uploadPending={uploadPending}
                  stipulations={country !== 'ca' ? fakeStips : [fakeStipulation]}
                  submit={this.submit}
                  showPPPLoan
                />
              </div>
            )}
            sidebarElement={<></>}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
    stipulations: state.stipulations.allStipulations,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    getProductsAndBfes: getProductsAndBfesAction,
    getOpportunities: getOpportunitiesAction,
    uploadBatchDocuments: uploadBatchDocumentsAction,
    getStipulations: getStipulationsAction,
  },
)(SecurePage(UploadCanadianBankStatementsPage));
