import React, { useContext, useEffect } from 'react';
import { BodyText } from '@paypalcorp/pp-react';
import { useSelector } from 'react-redux';
import '../../payments.less';
import moment from 'moment';
import displayNames from '../../../../constants/displayNames';
import { OneTimePaymentContext } from '../../OneTimePaymentContext';
import { ResponsiveHeadingText } from '../../components/responsiveTypography';
import Currency from '../../../common/currency';
import constants from '../../../../constants';

const PaymentAuthorization = () => {
  const {
    setFooterContent, currentBank, selectedLoanAdvance, paymentAmount, paymentDate,
  } = useContext(OneTimePaymentContext);
  const phoneNumber = constants.oneTimePayments.customerCarePhoneNumber;
  const { user } = useSelector((state) => state.authorize);

  useEffect(() => () => {
    setFooterContent(null);
  });
  return (
    <div>
      <ResponsiveHeadingText className="heading" mobile="sm" desktop="lg">
        Payment
        <br />
        Authorization
      </ResponsiveHeadingText>
      <BodyText>
        {user.firstName}
        {' '}
        {user.lastName}
        {' '}
        (“Borrower”) hereby authorizes PayPal, Inc. and its
        subsidiaries, Bill Me Later, Inc. and Swift Financial, LLC (collectively or individually,
        “PayPal”) to initiate a one-time, Automated Clearing House (“ACH”) payment on bank account
        ending in (****
        {currentBank?.BankAccountNumber?.slice(-4)}
        ) (“Bank Account”) for
        {' '}
        <Currency
          amount={Number(paymentAmount.amount)}
          dataAutomationId="paymentAuthorizationtext"
        />
        {' '}
        on
        {' '}
        {moment(paymentDate).format('LL')}
        {' '}
        as payment towards the Loan
        {' '}
        {selectedLoanAdvance.Name}
        {' '}
        (“Loan”). This ACH payment is an electronic funds transfer and will typically occur within
        two (2) business days of the [Scheduled Payment Date], but the exact timing is dependent on
        the processing schedule of Borrower’s bank. If the scheduled payment date above falls on a
        weekend or holiday, Borrower understands that the ACH payment will be made on the following
        business day. This Payment Authorization is for this one-time ACH payment only and does not
        provide additional authorization for any other unrelated charges to Borrower’s Loan account.
        <br />
        <br />
        Borrower promises that the Bank Account Borrower is using is a business bank account and
        that Borrower has the power and authority to: (i) initiate payments from such Bank Account
        and (ii) authorize PayPal (including our service providers) to initiate payments from such
        Bank Account. Borrower promises that each Bank Account is a legitimate, open, and active
        bank account used solely for business purposes and not for personal, family or household
        purposes. Borrower authorizes PayPal to verify any information Borrower has provided about
        the Bank Account and to correct any missing, erroneous or out-of-date information.
        <br />
        <br />
        Borrower agrees not dispute this scheduled ACH payment with any bank, including Borrower’s
        bank, so long as the ACH payment corresponds to the terms provided in this Payment
        Authorization. Borrower acknowledges that an ACH payment must comply with U.S. law and
        applicable network rules. Borrower agrees to be bound by the rules of the National Automated
        Clearing House Association (“NACHA”).
        <br />
        <br />
        To cancel this ACH payment, Borrower must notify PayPal by calling the customer service
        phone number [
        {phoneNumber}
        ]. Borrower understands that PayPal requires at least two (2) business days prior notice to
        cancel this ACH payment.
        <br />
        <br />
        Borrower agrees that PayPal may submit up to two times any ACH payment that is dishonored.
        Borrower’s bank may charge Borrower fees for any dishonored or otherwise returned ACH
        payment. Borrower agrees that PayPal will have no liability to Borrower for such bank
        charges or fees.
      </BodyText>
    </div>
  );
};

PaymentAuthorization.displayName = displayNames.PaymentAuthorization;
export default PaymentAuthorization;
