export const CREATE_LOAN_FORGIVENESS_REQUEST_PENDING = 'create_loan_forgiveness_request_pending';
export const CREATE_LOAN_FORGIVENESS_REQUEST_RECEIVED = 'create_loan_forgiveness_request_received';
export const CREATE_LOAN_FORGIVENESS_REQUEST_ERROR = 'create_loan_forgiveness_request_error';

export const GET_LOAN_FORGIVENESS_REQUEST_PENDING = 'get_loan_forgiveness_request_pending';
export const GET_LOAN_FORGIVENESS_REQUEST_RECEIVED = 'get_loan_forgiveness_request_received';
export const GET_LOAN_FORGIVENESS_REQUEST_ERROR = 'get_loan_forgiveness_request_error';

export const UPDATE_LOAN_FORGIVENESS_REQUEST_PENDING = 'update_loan_forgiveness_request_pending';
export const UPDATE_LOAN_FORGIVENESS_REQUEST_RECEIVED = 'update_loan_forgiveness_request_received';
export const UPDATE_LOAN_FORGIVENESS_REQUEST_ERROR = 'update_loan_forgiveness_request_error';
export const UPDATE_LOAN_FORGIVENESS_REQUEST_CLEAR = 'update_loan_forgiveness_request_clear';

export const UPDATE_LAST_PAGE_SUBMITTED = 'update_last_page_submitted';

export const ADD_FORGIVENESS_ID_REQUESTED = 'add_forgiveness_id_requested';

export const RESUME_LOAN_FORGIVENESS_REQUEST_ERROR = 'resume_loan_forgiveness_request_error';
