import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from '@swift-paypal/pp-react';
import './action-item/action-item.less';
import constants from '../../constants';

const AutoDraftItemsList = (props) => {
  const { autoDraftItems } = props;

  return (
    <div className="action-items">
      <div className="list-items margin-top-xs margin-bottom-xs">
        {autoDraftItems.map(({ advanceId }) => (
          <Container key={`AutoDraftItems - ${advanceId}`} fluid>
            <Row className="stipulation">
              <Col xs={6} sm={8} md={7}>
                Your Loan account
                {' XXXX'}
                {advanceId.slice(-4)}
                {' '}
                does not have a weekly payment schedule set up. Please call us
                at
                {' '}
                {constants.oneTimePayments.customerCarePhoneNumber}
                {' '}
                to set one up.
              </Col>
            </Row>
          </Container>
        ))}
      </div>
    </div>
  );
};
AutoDraftItemsList.propTypes = {
  autoDraftItems: PropTypes.array,
};

AutoDraftItemsList.defaultProps = {
  autoDraftItems: [],
};

export default AutoDraftItemsList;
