import advanceService from '../../../services/advance.service';
import loanForgivenessService from '../../../services/loanForgivness.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './loan-forgiveness-action-types';

export const getOrCreateLoanForgivenessAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CREATE_LOAN_FORGIVENESS_REQUEST_PENDING,
    complete: actions.CREATE_LOAN_FORGIVENESS_REQUEST_RECEIVED,
    error: actions.CREATE_LOAN_FORGIVENESS_REQUEST_ERROR,
  },
  (advanceId, formType) => (
    _dispatch,
    getState,
  ) => advanceService.getOrCreateLoanForgiveness(getState().config.apiUrl, advanceId, formType),
);

export const getLoanForgivenessAction = asyncAuthorizedActionCreator(
  {
    pending: actions.GET_LOAN_FORGIVENESS_REQUEST_PENDING,
    complete: actions.GET_LOAN_FORGIVENESS_REQUEST_RECEIVED,
    error: actions.GET_LOAN_FORGIVENESS_REQUEST_ERROR,
  },
  (advanceIds) => (
    _dispatch,
    getState,
  ) => advanceService.getLoanForgiveness(getState().config.apiUrl, advanceIds),
);

export const updateLoanForgivenessAction = asyncAuthorizedActionCreator(
  {
    pending: actions.UPDATE_LOAN_FORGIVENESS_REQUEST_PENDING,
    complete: actions.UPDATE_LOAN_FORGIVENESS_REQUEST_RECEIVED,
    error: actions.UPDATE_LOAN_FORGIVENESS_REQUEST_ERROR,
  },
  (data) => (
    _dispatch,
    getState,
  ) => loanForgivenessService.updateLoanForgiveness(getState().config.apiUrl, data),
);

export const clearUpdateLoanForgivenessAction = () => ({
  type: actions.UPDATE_LOAN_FORGIVENESS_REQUEST_CLEAR,
});

export const updateLastPageSubmittedAction = (pageNumber) => ({
  type: actions.UPDATE_LAST_PAGE_SUBMITTED,
  payload: pageNumber,
});

export const resumeLoanForgivenessAppErrorAction = (error) => ({
  type: actions.RESUME_LOAN_FORGIVENESS_REQUEST_ERROR,
  error,
});
