import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './body-text.less';
import { BodyText } from '@swift-paypal/pp-react';

const DemographicInformationBodyText = () => {
  const {
    loanbuilderUrl,
  } = useSelector(demographicInformationBodyTextSelector, shallowEqual);

  return (
    <div className="body-text">
      <BodyText as="p">
        You can upload your completed
        {' '}
        <a
          href={`${loanbuilderUrl}/sba_form_3508.pdf#page=5`}
          target="_blank"
          rel="noopener noreferrer"
          data-automation-id="downloadDemographicInformationFormLink"
        >
          PPP Borrower Demographic Information Form
        </a>
        {' '}
        as part of your loan forgiveness application or you can choose the option at the bottom
        of the page if you prefer not to provide demographic information with your PPP loan
        forgiveness application.
      </BodyText>
      <BodyText as="p">
        Important note: While providing this information is optional, your application won&apos;t
        progress until this step is complete.
        {' '}
        <strong>
          If you have not completed this step within 5 calendar days of submitting all other required loan
          forgiveness application information, we will mark the &quot;prefer not to upload&quot;
          check box for your optional borrower demographic information.
        </strong>
        {' '}
        This will allow us to begin our review of the information you provided on your PPP loan
        forgiveness request.
      </BodyText>
      <BodyText as="p">
        The optional demographic information form requests data about veteran status, gender, race,
        and ethnicity for each of the Borrower&apos;s principals, for program reporting purposes
        only.
      </BodyText>
      <BodyText as="p">
        Disclosure is voluntary and will have no bearing on the loan forgiveness decision.
      </BodyText>
    </div>
  );
};

const demographicInformationBodyTextSelector = (state) => ({
  loanbuilderUrl: state.config.loanbuilderUrl,
});

export default DemographicInformationBodyText;
