import React from 'react';
import PropTypes from 'prop-types';
import util from '../../util';

const Currency = ({ amount, dataAutomationId, decimals }) => (
  <span data-automation-id={dataAutomationId}>{util.getCurrency(amount, decimals)}</span>
);

Currency.propTypes = {
  amount: PropTypes.number,
  dataAutomationId: PropTypes.string,
  decimals: PropTypes.number,
};

Currency.defaultProps = {
  amount: undefined,
  dataAutomationId: 'currencyLabel',
  decimals: undefined,
};

export default Currency;
