import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import UploadMultipleDocumentsComponent from '../../components/upload-multiple-documents/upload-multiple-documents';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadBatchDocumentsAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { stipulationGroupsDescriptions } from '../../constants/upload-document';

const stipulationGroupName = 'ProofOfIdentity';
const fakeStipulation = {
  displayName: 'Valid proof of identity',
  description: (
    <>
      <p>
        Upload one of the following: Photo ID, Driver&apos;s license, Utility bill,
        Lease/Mortgage doc, Tax Return, Pay Statement.
      </p>
    </>
  ),
};

class ProofOfIdentityPage extends Component {
  static displayName = displayNames.ProofOfIdentityPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    getOpportunities: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
    })),
    push: PropTypes.func.isRequired,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.bool,
  };

  static defaultProps = {
    country: undefined,
    match: undefined,
    opportunitiesById: {},
    uploadPending: true,
    uploadError: false,
  };

  async componentDidMount() {
    const {
      getProductsAndBfes,
      getOpportunities,
      match,
      opportunitiesById,
    } = this.props;
    const { opportunityId } = match.params;
    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = (files) => {
    const { match, uploadBatchDocuments } = this.props;
    const { opportunityId } = match.params;
    const payloads = [];
    files.forEach((file) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, stipulationGroupName));
      }
    });
    uploadBatchDocuments(payloads);
  }

  redirectToSummaryConfirmation() {
    const { country, push, match } = this.props;
    const { opportunityId } = match.params;
    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      uploadPending, uploadError, opportunitiesById, match,
    } = this.props;
    const { opportunityId } = match.params;
    const pageTitle = 'Identity Verification';

    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;

    const groupDescription = loanVersion ? '' : stipulationGroupsDescriptions.Default;

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    return (
      <>
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StandardLayout
            contentElement={(
              <div>
                <PageTitle text={pageTitle} />
                <UploadMultipleDocumentsComponent
                  groupDescription={groupDescription}
                  uploadError={uploadError}
                  uploadPending={uploadPending}
                  stipulations={[fakeStipulation]}
                  minimumStipulationsRequired={1}
                  submit={this.submit}
                  showPPPLoan={!!loanVersion}
                />
              </div>
            )}
            sidebarElement={<></>}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    getOpportunities: getOpportunitiesAction,
    getProductsAndBfes: getProductsAndBfesAction,
    uploadBatchDocuments: uploadBatchDocumentsAction,
  },
)(SecurePage(ProofOfIdentityPage));
