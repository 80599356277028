import React from 'react';
import { HeadingText, BodyText } from '@swift-paypal/pp-react';
import { COLLATERAL_TITLE, COLLATERAL_SUBTEXT } from './collateral-package-messaging-constants';
import './collateral-package-messaging.less';

const CollateralPackageMessaging = () => (
  <div className="collateral-package-messaging">
    <HeadingText size="sm">
      {COLLATERAL_TITLE}
    </HeadingText>
    <BodyText as="p">
      {COLLATERAL_SUBTEXT}
    </BodyText>
  </div>
);

export default CollateralPackageMessaging;
