import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Button, Icon } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import { setActiveStipulationId } from '../../../../redux/actions/active-ids/active-ids-actions';
import { ClassNames } from '../stipulations.constants';
import { stipulationSelector } from '../stipulations.selectors';
import { getClassName, getStipulationRouteData } from '../stipulations.utils';

export const StipulationAction = ({ stipulationId }) => {
  const dispatch = useDispatch();
  const { country } = useParams();
  const selectedState = useShallowSelector(stipulationSelector, stipulationId);

  const actionData = {
    country,
    stipulationId,
    ...selectedState,
  };

  const handleActionClick = () => {
    dispatch(setActiveStipulationId(stipulationId));
  };

  const route = getStipulationRouteData(actionData);

  if (!route) {
    return null;
  }

  return selectedState.fulfilled
    ? (
      <Icon
        className={getClassName(ClassNames.Button, ClassNames.Fulfilled)}
        name="check-circle-alt"
        size="sm"
      />
    ) : (
      <Button as={Link} to={route} className={getClassName(ClassNames.Button)} onClick={handleActionClick} size="sm">
        Get Started
      </Button>
    );
};

StipulationAction.propTypes = {
  stipulationId: PropTypes.string.isRequired,
};
