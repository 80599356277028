import { API_HEALTH_ERROR, API_HEALTH_OK, API_HEALTH_PENDING } from '../actions/maintenance/maintenance-action-types';

export default function apiHealth(state = {}, action) {
  switch (action.type) {
    case API_HEALTH_PENDING:
      return state;
    case API_HEALTH_OK:
      return state;
    case API_HEALTH_ERROR:
      return state;
    default:
      return state;
  }
}
