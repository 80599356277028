import React from 'react';
import { HeadingText, ProgressMeter } from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';

const HorizontalProgressTracker = ({ applicableStages, activeStage }) => {
  const currentStageId = applicableStages.findIndex((stage) => activeStage.id === stage.id) + 1;

  return (
    <div className="progress-tracker__layout--horizontal">
      <ProgressMeter
        max={applicableStages.length}
        value={currentStageId}
        steps={`Step ${currentStageId} of ${applicableStages.length}`}
      />
      <HeadingText size="md" className="progress-tracker__summary-title">
        {activeStage.displayName}
      </HeadingText>
    </div>
  );
};

HorizontalProgressTracker.propTypes = {
  activeStage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  applicableStages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HorizontalProgressTracker;
