/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { CaptionText, DateInput, Calendar } from '@paypalcorp/pp-react';
import { useTheme as useEmotionTheme } from '@emotion/react';
import '../payments.less';
import moment from 'moment';
import { OneTimePaymentContext } from '../OneTimePaymentContext';
import {
  getNextDate, getNext60Date, isWeekend, isChargeOffPeriod,
} from '../../pages/oneTimePaymentPage/oneTimePayment.utils';
import oneTimePaymentConstants from '../../../constants/oneTimePayments';
import Currency from '../../common/currency';

const PaymentCalendarInput = () => {
  const {
    paymentDate, selectedLoanAdvance, setPaymentDate, invalidPaymentDateError,
    setInvalidPaymentDateError,
  } = useContext(OneTimePaymentContext);
  const { sysColorBackgroundMediumContrast } = useEmotionTheme();
  const [dateError, setDateError] = useState(null);
  const [isCalendarVisible, showCalendar] = useState(false);
  const wrapperRef = React.useRef(null);
  const chargeOffDate = selectedLoanAdvance?.chargeOffDate ? new Date(selectedLoanAdvance?.chargeOffDate) : null;
  const isDateDisabled = (date) => date < getNextDate() || date > getNext60Date() || isWeekend(date)
  || isChargeOffPeriod(date, chargeOffDate);

  const handleSelection = (date) => {
    setInvalidPaymentDateError(null);
    if (isDateDisabled(date)) {
      setInvalidPaymentDateError(oneTimePaymentConstants.invalidDateErrorMessage);
    }
    setPaymentDate(date);
    showCalendar(false);
  };

  const calendarElm = (
    <Calendar
      selectedDate={paymentDate}
      onSelection={handleSelection}
      isDateDisabled={isDateDisabled}
    />
  );

  return (
    <>
      <CaptionText strong>Pay on</CaptionText>
      <div className="input-container" ref={wrapperRef}>
        <DateInput
          name="dateSelectorACH"
          label="Select Date"
          calendar={calendarElm}
          selectedDate={paymentDate}
          wrapperContainerRef={wrapperRef}
          onSelection={handleSelection}
          isCalendarVisible={isCalendarVisible}
          onShowCalendar={showCalendar}
          errorText={invalidPaymentDateError}
        />
        {(selectedLoanAdvance.NextAutoDraftACHPaymentDate && selectedLoanAdvance.CurrentPayment) ? (
          <CaptionText style={{ color: sysColorBackgroundMediumContrast, marginTop: '0.5rem' }}>
            This is a one-time payment. Your upcoming payment due on
            <br />
            {moment(selectedLoanAdvance.NextAutoDraftACHPaymentDate).format('LL')}
            {' '}
            in the amount of
            {' '}
            <Currency
              amount={Number(selectedLoanAdvance.CurrentPayment)}
              dataAutomationId="loanCurrentPaymentText"
            />
            {' '}
            remains the same.
          </CaptionText>
        ) : null}
      </div>
    </>
  );
};

export default PaymentCalendarInput;
