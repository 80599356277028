import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { isUserAuthenticated, isUserAuthorized, getToken } from '../lib/token';
import setCountryAction from '../redux/actions/country/country-actions';
import withUrlParams from './with-url-params';
import getDisplayName from '../lib/get-display-name';

/*
 * What the hell does this HOC do? Well I'll tell you..
 * The url may not have the country code in it, and it needs to. So this will correct that.
 *
 * Scenarios to correct in the url:
 *
 * my.loanbuilder.com => my.loanbuilder.com/us/login
 * my.loanbuilder.com/US => my.loanbuilder.com/us/login
 * my.loanbuilder.com/au (not a valid country) => my.loanbuilder.com/us/login
 * my.loanbuilder.com/summary (authorized with US loan) => my.loanbuilder.com/us/summary
 * my.loanbuilder.com/summary (authorized with CA loan) => my.loanbuilder.com/ca/summary
 * my.loanbuilder.com/us/summary (authorized with CA loan) => my.loanbuilder.com/ca/summary
 * my.loanbuilder.com/ca/summary (not authorized) => my.loanbuilder.com/ca/login
 * my.loanbuilder.com/oauth/landing => (no change)
 * my.loanbuilder.com/oauth/paypal => (no change)
 * my.loanbuilder.com/reset-account?token=123 => my.loanbuilder.com/us/reset-account?token=123
 */

const Country = (Page) => {
  class CountryComponent extends Component {
    static propTypes = {
      countryStored: PropTypes.string,
      getCountryToState: PropTypes.func.isRequired,
      location: ReactRouterPropTypes.location.isRequired,
      match: ReactRouterPropTypes.match.isRequired,
      push: PropTypes.func.isRequired,
      urlParts: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
      countryStored: undefined,
      urlParts: undefined,
    };

    componentDidMount() {
      const {
        getCountryToState,
        location,
        match,
        push,
      } = this.props;
      const { country } = match.params;
      let countryCode = country;

      if (!isUserAuthenticated() && !isUserAuthorized()) {
        getCountryToState(countryCode);
        return;
      }

      const decodedToken = getToken();
      if (!decodedToken || !decodedToken.country_code || country === decodedToken.country_code.toLowerCase()) {
        getCountryToState(countryCode);
        return;
      }

      // everything's fine with the country, but the actual country on the user's opp
      // may be different than the url... so switch them to the right one here..
      countryCode = decodedToken.country_code.toLowerCase();
      getCountryToState(countryCode);

      const urlParts = match.url.split('/');
      urlParts[1] = decodedToken.country_code.toLowerCase();

      const fixedUrl = `${urlParts.join('/')}${location.search || ''}`;
      push(fixedUrl);
    }

    render() {
      const { countryStored } = this.props;

      if (!countryStored) {
        return <></>;
      }

      return <Page {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    countryStored: state.country?.country,
  });

  const mapDispatchToProps = {
    getCountryToState: setCountryAction,
    push: pushAction,
  };

  CountryComponent.displayName = `Country(${getDisplayName(Page)})`;

  return compose(
    withRouter,
    withUrlParams,
    connect(mapStateToProps, mapDispatchToProps),
  )(CountryComponent);
};

export default Country;
