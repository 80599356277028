import paycheckProtectionPlanService from '../../../services/paycheckProtectionPlan.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './paycheck-protection-plan-action-types';

export const updatePppObjectAction = asyncAuthorizedActionCreator(
  {
    pending: actions.UPDATE_PPP_OBJECT_PENDING,
    complete: actions.UPDATE_PPP_OBJECT_RECEIVED,
    error: actions.UPDATE_PPP_OBJECT_ERROR,
  },
  (data) => (
    dispatch,
    getState,
  ) => paycheckProtectionPlanService.updatePppObject(getState().config.apiUrl, data),
);
