export { useShallowSelector } from '../../../hooks';

export const accountAdvanceIdsSelector = (accountId) => ({ entities: { advances } }) => {
  const { allIds, byId } = advances;
  return allIds.filter((id) => byId[id]?.loanId && byId[id]?.accountId === accountId);
};

export const loanSummarySelector = (advanceId) => ({ entities: { advances, loanForgiveness, opportunities } }) => {
  const advance = advances.byId[advanceId];
  const forgivenessId = loanForgiveness.allIds.find((id) => loanForgiveness.byId[id]?.advanceId === advanceId);
  const opportunityId = advance?.opportunityId;
  return {
    advance,
    forgivenessStage: loanForgiveness.byId[forgivenessId]?.stage,
    oppPartnerName: opportunities.byId[opportunityId]?.partnerName,
  };
};
