import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../logo/logo';
import NotificationIcon from '../navigation/notification-icon';
import withUrlParams from '../../hocs/with-url-params';
import './header.less';

// Mobile Header is split out because vxLib requires that it be put in a different position in the document
// separate from the rest of the header markup

const MobileHeader = ({ authorized, country, loginRevamp }) => (
  <div className="portal_header">
    <div className="vx_globalNav-main_mobile">
      <div className="vx_globalNav-headerSection_trigger">
        {!loginRevamp
          && (
          <div className="vx_globalNav-toggleTrigger-container" role="button" tabIndex="0" onClick={toggleMenu} onKeyDown={toggleMenu}>
            <div className="vx_globalNav-toggleTrigger_animated">
              <span />
              <div className="vx_globalNav-toggleTrigger_animated_open">Menu</div>
              <div className="vx_globalNav-toggleTrigger_animated_close">Close</div>
            </div>
          </div>
          )}
      </div>
      <div className="vx_globalNav-headerSection_logo vertical-align-middle">
        <Logo mobile authorized={authorized} country={country} />
      </div>
      <ul className="vx_globalNav-headerSection_actions">
        <li className="vx_globalNav-actionItem_mobile vx_globalNav-notificationItem_mobile">
          <NotificationIcon />
        </li>
      </ul>
    </div>
  </div>
);

const toggleMenu = () => {
  if (document.body.classList.contains('vx_hasOpenNav')) {
    document.body.classList.remove('vx_hasOpenNav');
  }
  else {
    document.body.classList.add('vx_hasOpenNav');
  }
};

MobileHeader.propTypes = {
  authorized: PropTypes.bool,
  country: PropTypes.string,
  loginRevamp: PropTypes.bool.isRequired,
};

MobileHeader.defaultProps = {
  country: undefined,
  authorized: undefined,
};

const mapStateToProps = (state) => ({
  authorized: state.authorize.authorized,
  country: state.country,
  loginRevamp: state.config.loginRevamp,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(MobileHeader);
