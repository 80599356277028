import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from '@swift-paypal/pp-react';
import PageTitle from '../components/common/page-title';
import SecurePage from '../hocs/secure-page';
import SettingsComponent from '../components/settings/settings';
import { getContactAccountsAction, getHomePhoneAndBusinessWebAddressAction } from '../redux/actions/settings/settings-actions';
import displayNames from '../constants/displayNames';
import updatedSettingNames from '../constants/updatedSettingNames';
import withUrlParams from '../hocs/with-url-params';

class SettingsPage extends Component {
  static displayName = displayNames.SettingsPage;

  static propTypes = {
    apiUrl: PropTypes.string,
    contactAccounts: PropTypes.arrayOf(PropTypes.object),
    contactAccountsPending: PropTypes.bool,
    getContactAccounts: PropTypes.func.isRequired,
    getHomePhoneAndBusinessWebAddress: PropTypes.func,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    homePhoneAndBusinessWebAddress: PropTypes.shape({
      homePhone: PropTypes.string,
      businessWebAddress: PropTypes.string,
    }),
    updatedSettingName: PropTypes.string,
    updatedSettingValue: PropTypes.string,
  };

  static defaultProps = {
    apiUrl: undefined,
    homePhoneAndBusinessWebAddress: {
      businessWebAddress: undefined,
      homePhone: undefined,
    },
    contactAccounts: undefined,
    contactAccountsPending: false,
    getHomePhoneAndBusinessWebAddress: undefined,
    updatedSettingName: undefined,
    updatedSettingValue: undefined,
    user: undefined,
  };

  async componentDidMount() {
    const {
      apiUrl,
      getContactAccounts,
      getHomePhoneAndBusinessWebAddress,
    } = this.props;
    await getContactAccounts(apiUrl);
    const {
      contactAccounts,
    } = this.props;
    if (contactAccounts && contactAccounts.length > 0) {
      await getHomePhoneAndBusinessWebAddress(contactAccounts[0].contact.id);
    }
  }

  render() {
    const {
      contactAccounts,
      user,
      contactAccountsPending,
      homePhoneAndBusinessWebAddress,
      updatedSettingName,
      updatedSettingValue,
    } = this.props;
    return (
      <Container>
        {(contactAccountsPending === false) && (
          <>
            <Helmet>
              <title>Settings</title>
            </Helmet>
            <PageTitle text="Settings" />
            <SettingsComponent
              user={user}
              contactAccounts={contactAccounts}
              homePhone={
                (updatedSettingName === updatedSettingNames.homePhone)
                  ? updatedSettingValue : homePhoneAndBusinessWebAddress.homePhone
              }
              businessWebAddress={
                (updatedSettingName === updatedSettingNames.businessWebAddress)
                  ? updatedSettingValue : homePhoneAndBusinessWebAddress.businessWebAddress
              }
            />
          </>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    contactAccounts: state.settings.contactAccounts,
    user: state.authorize.user,
    contactAccountsPending: state.settings.contactAccountsPending,
    homePhoneAndBusinessWebAddress: state.settings.homePhoneAndBusinessWebAddress,
  };
}

export default compose(
  withRouter,
  withUrlParams,
  connect(mapStateToProps, {
    getContactAccounts: getContactAccountsAction,
    getHomePhoneAndBusinessWebAddress: getHomePhoneAndBusinessWebAddressAction,
  }),
)(SecurePage(SettingsPage));
