export const DETAILS_SECTION_1 = 'Upload the documents to support the 25% or more reduction in Gross Receipts that you acknowledged and certified to in your PPP loan application.';
export const DETAILS_SECTION_2 = 'Your documents should support a 25% or more gross receipt reduction between your selected 2020 Quarter and your 2019 Reference Quarter, or if you chose an annual comparison period, between Calendar Year 2020 and Calendar Year 2019. If you were not in business during 2019, but were in operation on Feb 15, 2020, please upload documentation that demonstrates at least a 25% reduction from your gross receipts during the second, third, or fourth quarter of 2020 as compared to the first quarter of 2020.';
export const DETAILS_SECTION_3 = 'If you elected an annual 2019 to 2020 gross receipts reduction comparison, you can submit copies of annual tax forms substantiating the gross receipts decline.';
export const DETAILS_SECTION_4 = 'If you elected a 2019 quarter to corresponding 2020 quarter gross receipts reduction comparison, you must provide documentation substantiating the decline in gross receipts. The documentation must identify the applicable 2020 quarter, the 2019 reference quarter, state the gross receipts amounts for both quarters, and support the amounts provided.';
export const DETAILS_SECTION_5 = 'If you were not in business during 2019, but were in operation on Feb 15, 2020, you must also provide documentation substantiating the decline in gross receipts. The documentation must identify the applicable 2020 quarter you selected for comparison purposes, the first quarter 2020 reference quarter, state the gross receipts amounts for both quarters, and support the amounts provided.';
export const DETAILS_SECTION_6_HEADER = 'Gross receipts include all revenue received or accrued from any source, including from the sales of products or services, interest, dividends, rents, royalties, fees, or commissions, reduced by returns and allowances, but do not include the following:';
export const DETAILS_SECTION_6_ITEM_1 = 'Taxes collected for and remitted to a taxing authority if included in gross or total income';
export const DETAILS_SECTION_6_ITEM_2 = 'Proceeds from transactions between a concern and its domestic or foreign affiliates';
export const DETAILS_SECTION_6_ITEM_3 = 'Amounts collected for another by a travel agent, real estate agent, advertising agent, conference management service provider, freight forwarder or customs broker';
export const DETAILS_SECTION_7_LINK_1_ADDRESS = 'https://www.sba.gov/document/sba-form-2483-sd-ppp-second-draw-borrower-application-form';
export const DETAILS_SECTION_7_LINK_1_TEXT = '2483-SD';
export const DETAILS_SECTION_7_LINK_2_ADDRESS = 'https://www.sba.gov/document/sba-form-2483-sd-c-second-draw-borrower-application-form-schedule-c-filers-using-gross-income';
export const DETAILS_SECTION_7_LINK_2_TEXT = '2483-SD-C';
export const DETAILS_SECTION_7_PART_1 = 'Gross receipts of a borrower must be aggregated with the gross receipts of its affiliates. If you are a nonprofit organization, veterans organization, nonprofit news organization, 501(c)(6) organization, or destination marketing organization, gross receipts has the meaning in section 6033 of the Internal Revenue Code of 1986. For more information on the Gross Receipt reduction documentation requirements, see the SBA-Form';
export const DETAILS_SECTION_7_PART_2 = 'or';
export const DETAILS_SECTION_7_PART_3 = 'Instructions, as applicable to your 2021 Second Draw loan application.';
