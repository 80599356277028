import notificationsService from '../../../services/notifications.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './notifications-action-types';

export const getContactNotificationsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CONTACT_NOTIFICATIONS_REQUEST_PENDING,
    complete: actions.CONTACT_NOTIFICATIONS_RECEIVED,
    error: actions.CONTACT_NOTIFICATIONS_REQUEST_ERROR,
  },
  (location) => (
    dispatch,
    getState,
  ) => notificationsService.getContactNotifications(getState().config.apiUrl, location),
);

export const markStipulationFulfilledAction = (stipName, id) => async (dispatch) => {
  dispatch({ type: actions.MARK_STIPULATION_FULFILLED, stip: { name: stipName, id } });
};
