import contactService from '../../../services/contacts.service';
import opportunityService from '../../../services/opportunity.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import * as actions from './business-property-action-types';

export const setOpportunityAsLandlordAction = asyncAuthorizedActionCreator(
  {
    pending: actions.SET_OPPORTUNITY_AS_LANDLORD_PENDING,
    complete: actions.SET_OPPORTUNITY_AS_LANDLORD_RECEIVED,
    error: actions.SET_OPPORTUNITY_AS_LANDLORD_ERROR,
  },
  (opportunityId) => (dispatch, getState) => opportunityService.setAsOwner(getState().config.apiUrl, opportunityId),
);

export const createLandlordContactAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CREATE_LANDLORD_CONTACT_PENDING,
    complete: actions.CREATE_LANDLORD_CONTACT_RECEIVED,
    error: actions.CREATE_LANDLORD_CONTACT_ERROR,
  },
  (businessName, firstName, lastName, phone, role) => (dispatch, getState) => {
    const { config } = getState();
    return contactService.createContact(config.apiUrl, businessName, firstName, lastName, phone, role);
  },
);

export const clearLandlordAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_LANDLORD });
};

export const updateLandlordMessageAction = (messageBody, messageType) => async (dispatch) => {
  dispatch({ type: actions.UPDATE_LANDLORD_MESSAGE, messageBody, messageType });
};

export const clearLandlordMessageAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_LANDLORD_MESSAGE });
};

// export const setOwnBusinessPropertyAction = payload => async (dispatch) => {
//   dispatch({ type: actions.SET_OWN_BUSINESS_PROPERTY, payload });
// };

export const setOwnBusinessPropertyAction = (payload) => ({
  payload,
  type: actions.SET_OWN_BUSINESS_PROPERTY,
});
