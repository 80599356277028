import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import redirect from '../lib/redirect';
import SecurePage from '../hocs/secure-page';
import ActivityWrapper from '../components/activity/activity-wrapper';
import PageTitle from '../components/common/page-title';
import {
  getLoanAction,
  getProductsAction,
  getProductsAndBfesAction,
  clearSelectedLoanAction,
  getTransactionsAction,
} from '../redux/actions/account-items/account-items-actions';
import { getIsRenewalEligibleAction } from '../redux/actions/account/account-actions';
import displayNames from '../constants/displayNames';
import StandardLayout from '../layouts/StandardLayout';

class ActivityPage extends Component {
  static displayName = displayNames.ActivityPage;

  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    accountsPending: PropTypes.bool,
    clearSelectedLoan: PropTypes.func.isRequired,
    getIsRenewalEligible: PropTypes.func.isRequired,
    getProductsAndBfes: PropTypes.func.isRequired,
    getTransactions: PropTypes.func.isRequired,
    getLoan: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    loanError: PropTypes.string,
    loanId: PropTypes.string,
    match: ReactRouterPropTypes.match.isRequired,
    transactions: PropTypes.arrayOf(PropTypes.object),
    transactionsPending: PropTypes.bool,
  };

  static defaultProps = {
    accounts: undefined,
    accountsPending: false,
    loanError: undefined,
    loanId: undefined,
    transactions: undefined,
    transactionsPending: undefined,
  };

  async componentDidMount() {
    await this.loadPageData();
  }

  async componentDidUpdate(prevProps) {
    const { loanId } = this.props;
    if (loanId === prevProps.loanId) {
      return;
    }

    await this.loadPageData();
  }

  componentWillUnmount() {
    const { clearSelectedLoan } = this.props;
    clearSelectedLoan();
  }

  async loadPageData() {
    const {
      accounts,
      loanId,
      getIsRenewalEligible,
      getLoan,
      getProductsAndBfes,
      getTransactions,
    } = this.props;
    getLoan(loanId);
    getTransactions(loanId);
    await getProductsAndBfes();
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      await getIsRenewalEligible(account.accountId);
    }
  }

  validateAccount() {
    const {
      accounts, accountsPending, history, loanError, loanId, match,
    } = this.props;
    let shouldRedirect = true;
    if (loanError && !accountsPending) {
      // check accounts for loan id
      if (accounts) {
        _.forEach(accounts, (account) => {
          if (account.loans && account.loans.length) {
            const currentLoans = account.loans.filter((x) => x.loanId === loanId);
            if (currentLoans && currentLoans.length) {
              // valid account and loan; so lets not redirect
              shouldRedirect = false;
              return false;
            }
          }
          return undefined;
        });
      }
      if (shouldRedirect) {
        redirect(history, match);
      }
    }
  }

  render() {
    const
      {
        loanError,
        transactions,
        transactionsPending,
      } = this.props;

    return (
      <div>
        {(transactionsPending === false) && (
          <div>
            <Helmet>
              <title>Activity</title>
            </Helmet>
            <StandardLayout
              contentElement={(
                <div>
                  <PageTitle text="Activity" />
                  {!loanError && <ActivityWrapper transactions={transactions} /> }
                  {loanError && this.validateAccount()}
                </div>
              )}
              sidebarElement={<></>}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    accounts: state.accountItems.accounts,
    accountsPending: state.accountItems.accountsPending,
    loanError: state.accountItems.loanRequestError,
    transactionsError: state.accountItems.transactionsError,
    transactions: state.accountItems.transactions || [],
    transactionsPending: state.accountItems.transactionsPending,
  };
}

export default connect(
  mapStateToProps,
  {
    getLoan: getLoanAction,
    getProducts: getProductsAction,
    getTransactions: getTransactionsAction,
    clearSelectedLoan: clearSelectedLoanAction,
    getProductsAndBfes: getProductsAndBfesAction,
    getIsRenewalEligible: getIsRenewalEligibleAction,
  },
)(SecurePage(ActivityPage));
