import * as actions from '../actions/settings/settings-action-types';
import constants from '../../constants/index';
import { AlertTypes } from '../../components/common/alert';

const defaultState = {
  securityQuestions: {
    defaultQuestions: [],
    userQuestions: [],
  },
};

const settings = (state = defaultState, action) => {
  switch (action.type) {
    case actions.CONTACT_ACCOUNTS_REQUEST_PENDING:
      return { ...state, contactAccountsPending: true };
    case actions.CONTACT_ACCOUNTS_RECEIVED:
      return { ...state, contactAccounts: action.payload, contactAccountsPending: false };
    case actions.CONTACT_ACCOUNTS_REQUEST_ERROR:
      return { ...state, error: action.payload.message, contactAccountsPending: false };
    case actions.CHANGE_PASSWORD_RECEIVED:
      return { ...state, messageBody: 'Your new password has been updated successfully!', messageType: AlertTypes.success };
    case actions.CHANGE_PASSWORD_REQUEST_ERROR:
      return { ...state, messageBody: handlePasswordChangeError(action.payload), messageType: AlertTypes.critical };
    case actions.GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_REQUEST_PENDING:
      return { ...state, getHomePhoneAndBusinessWebAddressPending: true };
    case actions.GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_RECEIVED:
      return {
        ...state,
        getHomePhoneAndBusinessWebAddressPending: false,
        homePhoneAndBusinessWebAddress: action.payload,
      };
    case actions.GET_HOME_PHONE_AND_BUSINESS_WEB_ADDRESS_REQUEST_ERROR:
      return {
        ...state,
        getHomePhoneAndBusinessWebAddressPending: false,
        getHomePhoneAndBusinessWebAddressError: true,
      };
    case actions.LIST_SECURITY_QUESTIONS_RECEIVED:
      return { ...state, securityQuestions: action.payload };
    case actions.LIST_SECURITY_QUESTIONS_ERROR:
      return { ...state, listSecurityQuestionsError: action.payload.message };
    case actions.CHANGE_SECURITY_QUESTIONS_RECEIVED:
      return { ...state, messageBody: 'Security questions updated.', messageType: AlertTypes.success };
    case actions.CHANGE_SECURITY_QUESTIONS_REQUEST_ERROR:
      return { ...state, messageBody: action.payload.message, messageType: AlertTypes.critical };
    case actions.UPDATE_SETTINGS_MESSAGE:
      return { ...state, messageBody: action.messageBody, messageType: action.messageType };
    case actions.CLEAR_SETTINGS_MESSAGE:
      return { ...state, messageBody: undefined, messageType: undefined };
    case actions.CHANGE_EMAIL_RECEIVED:
      return { ...state, messageBody: 'Check your email to confirm the new email address', messageType: AlertTypes.success };
    case actions.CHANGE_EMAIL_REQUEST_ERROR:
      return { ...state, messageBody: handleEmailChangeError(action.payload), messageType: AlertTypes.critical };
    case actions.VERIFY_EMAIL_RECEIVED:
      return { ...state, verifyEmailError: false };
    case actions.VERIFY_EMAIL_REQUEST_ERROR:
      return { ...state, verifyEmailError: true };
    default:
      return state;
  }
};

export default settings;

const handleEmailChangeError = (error) => {
  if (error.response.status === 422) {
    return 'Invalid Password';
  }
  return error.response.data.message;
};

const handlePasswordChangeError = (error) => {
  if (error.response.status === 401) {
    return constants.errors.unauthorized;
  }
  return error.response && error.response.data && error.response.data.message
    ? error.response.data.message : error.message;
};
