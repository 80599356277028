import React from 'react';
import { Route } from 'react-router-dom';
import {
  CoveredCostsDocumentsPage,
  ForgivenessGrossReceiptsPage,
  ForgivenessGrossReceiptsDocumentsPage,
  ForgivenessSummaryPage,
  Sba3508SFormPage,
  SummaryPage,
  OneTimePaymentPage,
} from './components/pages';
import LoginPage from './pages/login';
import AddBusinessWebAddressPage from './pages/settings/add-business-web-address';
import AddHomePhonePage from './pages/settings/add-home-phone';
import AdditionalIdentificationPage from './pages/application/additional-identification';
import ActionItemsPage from './pages/action-items';
import ActivityPage from './pages/activity';
import BusinessLicensePage from './pages/application/business-license';
import OwnershipPage from './pages/application/ownership';
import UploadDocumentGroupPage from './pages/upload-document-group';
import VerifyBankAccountsPage from './pages/application/verify-bank-accounts';
import CalculatePayrollDataPage from './pages/application/calculate-payroll-data';
import ChangeEmailPage from './pages/settings/change-email';
import ChangePasswordPage from './pages/settings/change-password';
import ChangeSecurityQuestionsPage from './pages/settings/change-security-questions';
import ConfirmPaypalAccountPage from './pages/confirm-paypal-account';
import ConfirmPaypalAccountMigratedFlowPage from './pages/confirm-paypal-account-migrated-flow';
import ContactUsPage from './pages/contact-us';
import CreateAccountPage from './pages/create-account';
import DemographicInformationPage from './pages/application/demographic-information';
import DriversLicensePage from './pages/application/drivers-license';
import ForgotPasswordPage from './pages/forgot-password';
import ForgivenessApplicationPage from './pages/forgiveness-application';
import ForgivenessCustomerSignoffPage from './pages/forgiveness-customer-signoff';
import ForgivenessSignoffCompletedPage from './pages/forgiveness-signoff-completed';
import ForgivenessDetailPage from './pages/forgiveness-detail';
import IdentificationPage from './pages/application/identification';
import MaintenancePage from './pages/maintenance';
import ReferencesPage from './pages/references';
import ProofOfBusinessPage from './pages/application/proof-of-business';
import BusinessOwnershipPage from './pages/application/business-ownership';
import PrivacyPolicyPage from './pages/privacy-policy';
import QuestionnairePage from './pages/questionnaire';
import ReductionInGrossReceiptsPage from './pages/application/reduction-in-gross-receipts';
import ResetAccountPage from './pages/reset-account';
import ResetPasswordPage from './pages/reset-password';
import SecurityQuestionPage from './pages/security-question';
import SecurityQuestionsPage from './pages/security-questions';
import SettingsPage from './pages/settings';
import TermsOfUsePage from './pages/terms-of-use';
import TermsAndConditionsPage from './pages/terms-and-conditions';
import UploadAddendumAPage from './pages/application/upload-addendum-a';
import UploadAddendumBPage from './pages/application/upload-addendum-b';
import UploadCanadianBankStatementsPage from './pages/application/upload-canadian-bank-statements';
import VerifyEmailPage from './pages/verify-email';
import AddBankAccountsPage from './pages/application/add-bank-accounts';
import OAuthLandingPage from './pages/oauth/landing';
import OAuthLandingPageMigratedFlow from './pages/oauth/landingMigratedFlow';
import OAuthPayPalPage from './pages/oauth/paypal';
import OAuthPayPalPageMigratedFlow from './pages/oauth/paypalMigratedFlow';
import PayrollVerificationPage from './pages/application/payroll-verification';
import UploadLoanForgivenessPage from './pages/application/upload-loan-forgiveness';
import ReductionInGrossReceiptsDocumentsPage from './pages/application/reduction-in-gross-receipts-documents';
import TaxReturnPage from './pages/application/tax-return';
import ProofOfIdentityPage from './pages/application/proof-of-identity';
import VoidedCheckPage from './pages/application/voided-check';
import routes from './routes';
import { getInnerDisplayName } from './lib/get-display-name';

const components = [
  AddBankAccountsPage,
  AddHomePhonePage,
  AddBusinessWebAddressPage,
  AdditionalIdentificationPage,
  ActionItemsPage,
  ActivityPage,
  BusinessLicensePage,
  BusinessOwnershipPage,
  CalculatePayrollDataPage,
  ChangeEmailPage,
  ChangePasswordPage,
  ChangeSecurityQuestionsPage,
  ConfirmPaypalAccountPage,
  ConfirmPaypalAccountMigratedFlowPage,
  ContactUsPage,
  CoveredCostsDocumentsPage,
  CreateAccountPage,
  DemographicInformationPage,
  DriversLicensePage,
  ForgotPasswordPage,
  ForgivenessApplicationPage,
  ForgivenessCustomerSignoffPage,
  ForgivenessGrossReceiptsPage,
  ForgivenessGrossReceiptsDocumentsPage,
  ForgivenessSignoffCompletedPage,
  ForgivenessSummaryPage,
  ForgivenessDetailPage,
  Sba3508SFormPage,
  IdentificationPage,
  LoginPage,
  MaintenancePage,
  OAuthLandingPage,
  OAuthLandingPageMigratedFlow,
  OAuthPayPalPage,
  OAuthPayPalPageMigratedFlow,
  OwnershipPage,
  PayrollVerificationPage,
  PrivacyPolicyPage,
  ProofOfBusinessPage,
  ProofOfIdentityPage,
  QuestionnairePage,
  ReductionInGrossReceiptsDocumentsPage,
  ReferencesPage,
  ResetAccountPage,
  ResetPasswordPage,
  ReductionInGrossReceiptsPage,
  SecurityQuestionPage,
  SecurityQuestionsPage,
  SettingsPage,
  SummaryPage,
  OneTimePaymentPage,
  TaxReturnPage,
  TermsOfUsePage,
  TermsAndConditionsPage,
  UploadAddendumAPage,
  UploadAddendumBPage,
  UploadDocumentGroupPage,
  UploadCanadianBankStatementsPage,
  UploadLoanForgivenessPage,
  VoidedCheckPage,
  VerifyBankAccountsPage,
  VerifyEmailPage,
];

const componentsByName = components.reduce((map, component) => {
  const name = getInnerDisplayName(component);
  map[name] = component;
  return map;
}, {});

export default function getRoutes() {
  return Object.values(routes).map((route) => {
    const component = componentsByName[route.componentName];
    if (!component) {
      throw new Error(`Could not find component ${route.componentName} when mapping ${route.path}.`);
    }
    return <Route exact key={route.path} path={route.path} component={component} />;
  });
}
