import React from 'react';
import './select-field.less';
import PropTypes from 'prop-types';

const selectField = ({
  id,
  input,
  label,
  className,
  noErrorClass = '',
  children,
  disabled,
  meta: {
    touched, error, warning, pristine,
  },
}) => (
  <div className={`custom-floating-point-select ${label?.length > 0 ? 'vx_floatingLabel' : ''}
      ${touched && error ? 'vx_has-error-with-message' : ''}`}
  >
    <label htmlFor={id}>{label}</label>
    {/* force label to display if input is pristine and preselected; set label content as undefined if
          no value is preselected and the field is pristine */}
    <div
      className={`vx_select proper-arrow-spot ${pristine && !!input.value ? 'force-show-label' : ''}`}
      data-label-content={isEmpty(input.value) ? undefined : label}
      aira-label={label}
    >
      {/* if no preselection, adjust the padding on the placeholder; unround bottom corners if error */}
      <select
        id={id || label}
        disabled={disabled}
        className={`${className}
          ${(!error || !touched) ? noErrorClass : 'unround-corners'}
          ${!input.value ? ' no-label-padding' : ''}`}
        {...input}
      >
        {children}
      </select>
      {touched
          && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
            || (warning && (
              <span className="vx_form-control-message margin-bottom-sm">{warning}</span>
            )))}
    </div>
  </div>
);

function isEmpty(string) {
  return (!string || string.length === 0);
}

selectField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    pristine: PropTypes.bool,
  }),
  noErrorClass: PropTypes.string,
  type: PropTypes.string,
};

selectField.defaultProps = {
  children: undefined,
  className: undefined,
  disabled: false,
  id: undefined,
  input: undefined,
  meta: undefined,
  noErrorClass: undefined,
  type: undefined,
};

export default selectField;
