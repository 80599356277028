/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Row,
  Tooltip,
  HeadingText,
} from '@swift-paypal/pp-react';
import { RadioButton } from '@paypalcorp/pp-react';
import Title from '../common/title';
import constants from '../../constants';
import { Alert, AlertTypes } from '../common/alert';
import countryCodes from '../../constants/countryCodes';
import resolve from '../../services/route.service';
import routes from '../../routes';
import checkHoverUs from '../../static/images/check-hover-us.png';
import checkHoverCa from '../../static/images/check-hover-ca.png';
import withUrlParams from '../../hocs/with-url-params';
import BankAccountForm from './bank-account-form';
import PaymentDayForm from './payment-day-form';
import './verify-bank-accounts.less';
import LaunchPlaidButton from '../launch-plaid-button';
import errors from '../../constants/errors';
import BankAccountFormV2 from './bank-account-form-v2';

export class AddBankAccountsForm extends React.Component {
  static propTypes = {
    additionalAccountNumber: PropTypes.string,
    additionalInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    additionalRoutingNumber: PropTypes.string,
    change: PropTypes.func.isRequired,
    country: PropTypes.string,
    customAsyncValidate: PropTypes.func.isRequired,
    depositAccountNumber: PropTypes.string,
    depositInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    depositRoutingNumber: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      deposit: PropTypes.object,
      withdrawal: PropTypes.object,
    }),
    isSbaLoan: PropTypes.bool,
    isWithdrawalSameAsDeposit: PropTypes.bool,
    paymentDay: PropTypes.string,
    plaidEnabled: PropTypes.bool.isRequired,
    saveBankInfoError: PropTypes.bool,
    addBankInfoError: PropTypes.bool,
    withdrawalAccountNumber: PropTypes.string,
    withdrawalInstitution: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    withdrawalRoutingNumber: PropTypes.string,
    hasRouteNumberWarning: PropTypes.bool,
    isWithdrawalSameAsDepositRadio: PropTypes.string,
    addBankErrorMessage: PropTypes.string,
  };

  static defaultProps = {
    additionalAccountNumber: undefined,
    additionalInstitution: undefined,
    additionalRoutingNumber: undefined,
    country: undefined,
    depositAccountNumber: undefined,
    depositInstitution: undefined,
    depositRoutingNumber: undefined,
    initialValues: undefined,
    isSbaLoan: false,
    isWithdrawalSameAsDeposit: false,
    paymentDay: undefined,
    saveBankInfoError: undefined,
    addBankInfoError: undefined,
    withdrawalAccountNumber: undefined,
    withdrawalInstitution: undefined,
    withdrawalRoutingNumber: undefined,
    hasRouteNumberWarning: false,
    isWithdrawalSameAsDepositRadio: 'no',
    addBankErrorMessage: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      additionalForm: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      customAsyncValidate, isWithdrawalSameAsDeposit, withdrawalRoutingNumber, isWithdrawalSameAsDepositRadio,
    } = this.props;
    // if the box goes from checked to unchecked, rerun routing number async validation
    if (!isWithdrawalSameAsDeposit && isWithdrawalSameAsDeposit !== prevProps.isWithdrawalSameAsDeposit) {
      customAsyncValidate({ withdrawal: { routingNumber: withdrawalRoutingNumber } },
        null, null, 'withdrawal.routingNumber');
    }

    if (isWithdrawalSameAsDeposit && isWithdrawalSameAsDeposit !== prevProps.isWithdrawalSameAsDeposit) {
      this.copyBankAccount();
    }
  }

  showAdditionalForm = () => {
    const { change } = this.props;
    change(`${constants.bank.ADDITIONAL}.routingNumber`, '');
    change(`${constants.bank.ADDITIONAL}.accountNumber`, '');
    this.setState({ additionalForm: true });
  };

  copyBankAccount = () => {
    const { change, depositAccountNumber, depositRoutingNumber } = this.props;
    change(`${constants.bank.WITHDRAWAL}.routingNumber`, depositRoutingNumber || '');
    change(`${constants.bank.WITHDRAWAL}.accountNumber`, depositAccountNumber || '');
  };

  showNewBankInfo = () => {
    this.setState({ additionalForm: true });
  };

  hideNewBankInfo = () => {
    const { change } = this.props;
    change(`${constants.bank.ADDITIONAL}.routingNumber`, '');
    change(`${constants.bank.ADDITIONAL}.accountNumber`, '');
    this.setState({ additionalForm: false });
  };

  BankFormVersionPicker = (version) => {
    if (version === 'v2') {
      return BankAccountFormV2;
    }
    return BankAccountForm;
  }

  renderCheckbox = ({ input, value }) => (
    <span className="vx_checkbox margin-left-sm margin-top-sm vertical-align-middle">
      <input
        {...input}
        id="isWithdrawalSameAsDeposit"
        type="checkbox"
        value={value}
      />
      <label htmlFor="isWithdrawalSameAsDeposit">
        Same as deposit account
      </label>
    </span>
  );

  render() {
    const {
      additionalInstitution,
      depositInstitution,
      handleSubmit,
      initialValues,
      isSbaLoan,
      isWithdrawalSameAsDeposit,
      plaidEnabled,
      saveBankInfoError,
      withdrawalInstitution,
      hasRouteNumberWarning,
      addBankInfoError,
      addBankErrorMessage,
    } = this.props;

    if (plaidEnabled) {
      return (
        <div className="add-bank-accounts-form">
          <LaunchPlaidButton />
        </div>
      );
    }

    let { country } = this.props;
    const { isWithdrawalSameAsDepositRadio } = this.props;
    country = country.toUpperCase();
    const { additionalForm } = this.state;
    const { BankFormStipVersion } = constants;
    const BankAccountFormComponent = this.BankFormVersionPicker(BankFormStipVersion);
    const depositText = BankFormStipVersion === 'v2' ? 'What bank account should we use to deposit your loan proceeds?' : 'Account to deposit loan proceeds';
    const withdrawalAccText = BankFormStipVersion === 'v2' ? 'What bank would you like to use to make payments?' : 'Account from which payments will be made';
    const accountOptionText = 'Would you prefer to use the same bank account to receive your loan proceeds and make payments from?';
    const isPayPalAccountFlag = initialValues?.deposit?.isPaypalAccount === true;
    const paypalDepositText = 'Account to deposit loan proceeds';

    return (
      <div className="add-bank-accounts-form">
        {addBankInfoError && Array.isArray(addBankErrorMessage) && addBankErrorMessage.length > 0 && addBankErrorMessage.map((errorMessage) => <Alert message={!errorMessage ? errors.technicalIssue : errorMessage} alertType={AlertTypes.critical} />) }
        {saveBankInfoError && <Alert message={errors.technicalIssue} alertType={AlertTypes.critical} />}
        <form onSubmit={handleSubmit}>
          <div>
            {(country === countryCodes.canada) && (
              <Title
                aria-label="Deposit and Withdrawal Account"
                text="Deposit and Withdrawal Account"
                tooltipImage={checkHoverCa}
                imageClassName="check-image canada"
              />
            )}
            {(country !== countryCodes.canada) && (
              // eslint-disable-next-line no-nested-ternary
              BankFormStipVersion === 'v2' ? !isPayPalAccountFlag ? (
                (
                  <>
                    <div id="isWithdrawalSameAsDepositRadio" style={{ marginBottom: '2rem', marginTop: '2rem' }}>
                      <HeadingText>{ accountOptionText }</HeadingText>
                      <Field
                        name="isWithdrawalSameAsDepositRadio"
                        component={({ input, ...props }) => (
                          <RadioButton
                            options={[
                              { label: 'Yes', value: 'yes', checked: isWithdrawalSameAsDepositRadio === 'yes' },
                              { label: 'No', value: 'no', checked: isWithdrawalSameAsDepositRadio === 'no' },
                            ]}
                            {...input}
                            {...props}
                          />
                        )}
                        label=""
                        className="radioButtonHorizontal"
                      />
                    </div>

                    {
                      isWithdrawalSameAsDepositRadio !== 'yes' && (
                        <>
                          <HeadingText>
                            {depositText}
                          </HeadingText>
                          <Divider />
                        </>

                      )
                    }

                  </>
                )
              ) : (
                <>
                  <br />
                  <HeadingText>
                    {paypalDepositText}
                  </HeadingText>
                </>
              )
                : (
                  <Title
                    aria-label="Account to deposit loan proceeds"
                    text={depositText}
                    tooltipImage={checkHoverUs}
                    imageClassName="check-image"
                  />
                )
            )}

            <div>
              <BankAccountFormComponent
                accountType={constants.bank.DEPOSIT}
                institution={depositInstitution}
                initialValues={initialValues ? initialValues.deposit : {}}
                sameBankAccountFormOption={isWithdrawalSameAsDepositRadio}
              />
            </div>
          </div>
          <div>
            {(country !== countryCodes.canada) && (
              <>

                {
                  BankFormStipVersion === 'v2' ? !isPayPalAccountFlag ? ((
                    <div>

                      {
                        isWithdrawalSameAsDepositRadio !== 'yes' && (
                          <>
                            <HeadingText>
                              {withdrawalAccText}
                            </HeadingText>
                            <Divider />
                          </>

                        )
                      }

                    </div>
                  ))
                    : (

                      <div>
                        <br />
                        <HeadingText>
                          {withdrawalAccText}
                        </HeadingText>
                      </div>
                    ) : (
                      <div>
                        <span className="vx_text-4 account-name">{withdrawalAccText}</span>
                        <Tooltip
                          imageElement={<img src={checkHoverUs} alt="" className="check-image" />}
                          className="pp-react__tooltip--inline"
                        />
                        <hr className="vx_hr" />
                      </div>
                  )
                }
                <div>
                  <BankAccountFormComponent
                    accountType={constants.bank.WITHDRAWAL}
                    institution={withdrawalInstitution}
                    disabled={isWithdrawalSameAsDeposit}
                    initialValues={initialValues ? initialValues.withdrawal : {}}
                    hasRouteNumberWarning={hasRouteNumberWarning}
                    sameBankAccountFormOption={isWithdrawalSameAsDepositRadio}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            {(!additionalForm && country !== countryCodes.canada && BankFormStipVersion !== 'v2')
              ? (
                <button type="button" className="vx_addBtn-primary margin-top-sm" onClick={this.showNewBankInfo}>
                  Add additional account
                </button>
              )
              : <></>}
          </div>
          <div>
            {additionalForm
              ? (
                <div className="margin-top-sm">
                  <Title
                    text="Additional Account"
                    tooltipImage={checkHoverUs}
                    imageClassName="check-image"
                  />
                </div>
              )
              : <></>}
            {additionalForm
              ? (
                <div>
                  <BankAccountFormComponent
                    accountType={constants.bank.ADDITIONAL}
                    institution={additionalInstitution}
                  />
                </div>
              )
              : <></>}
          </div>
          <div>
            {additionalForm
              ? <button type="button" className="button-link ppvx_link" onClick={this.hideNewBankInfo}>Remove</button>
              : <></>}
          </div>
          <div>&nbsp;</div>
          {!isSbaLoan && (
            <div>
              <Title text="Payment Day" />
              <div>
                <PaymentDayForm />
              </div>
            </div>
          )}
          <Row className="margin-top-sm">
            <Col xs={6} sm={5} md={4}>
              <Button type="submit" className="pp-react__button--block">
                Submit
              </Button>
            </Col>
            <Col xs={6} sm={5} md={4}>
              <Button as={Link} to={resolve(routes.summary.path, { country })} secondary type="button" className="pp-react__button--block">
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const AddBankAccountsFormComponent = reduxForm({
  form: 'add-bank-accounts',
  asyncBlurFields: [
    `${constants.bank.DEPOSIT}.routingNumber`,
    `${constants.bank.WITHDRAWAL}.routingNumber`,
    `${constants.bank.ADDITIONAL}.routingNumber`],
  enableReinitialize: true,
  initialValues: {
    isWithdrawalSameAsDepositRadio: 'no',
  },
})(AddBankAccountsForm);

const selector = formValueSelector('add-bank-accounts');
const mapStateToProps = (state) => ({
  additionalAccountNumber: selector(state, 'additional.accountNumber'),
  additionalRoutingNumber: selector(state, 'additional.routingNumber'),
  depositAccountNumber: selector(state, 'deposit.accountNumber'),
  depositRoutingNumber: selector(state, 'deposit.routingNumber'),
  isWithdrawalSameAsDeposit: selector(state, 'isWithdrawalSameAsDeposit'),
  paymentDay: selector(state, 'paymentDay'),
  plaidEnabled: state.config.plaidEnabled,
  withdrawalAccountNumber: selector(state, 'withdrawal.accountNumber'),
  withdrawalRoutingNumber: selector(state, 'withdrawal.routingNumber'),
  isWithdrawalSameAsDepositRadio: selector(state, 'isWithdrawalSameAsDepositRadio'),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withUrlParams,
)(AddBankAccountsFormComponent);
