export { useShallowSelector } from '../../hooks';

export const oneTimePaymentSelector = ({ selfServiceLoan }) => {
  const {
    loading,
    loanAdvances,
    error,
    scheduledPaymentAmount,
    scheduledPaymentStatus,
    scheduledPaymentErrorMsg,
    defaultLoanAdvance,
    loanDropdownOptions,
    bankList,
    ssAchError,
    bankUpdateStatus,
    bankUpdateMessage,
    bankUpdatedDetails,
    bankUpdateStatusCode,
  } = selfServiceLoan;

  return {
    loading,
    loanAdvances,
    defaultLoanAdvance,
    loanDropdownOptions,
    bankList,
    error,
    scheduledPaymentStatus,
    scheduledPaymentErrorMsg,
    scheduledPaymentAmount,
    ssAchError,
    bankUpdateStatus,
    bankUpdateMessage,
    bankUpdateStatusCode,
    bankUpdatedDetails,
  };
};
