import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Col, Row, BodyText, Link,
} from '@swift-paypal/pp-react';
import redirect from '../../lib/redirect';
import util from '../../util';
import Title from '../common/title';
import BasicActivityInfo from './basic-activity-info';
import TransactionList from './transaction/transaction-list';
import TotalBalanceTile from './total-balance-tile/total-balance-tile';
import { getGuarantyClaimAction } from '../../redux/actions/guaranty-claims/guaranty-claims-action';
import './activity.less';

const GUARANTY_CLAIMED_DESCRIPTION = 'An SBA Guaranty Purchase has been completed on your PPP loan. Questions concerning SBA Guaranty purchase of PPP loans can be directed to the SBA at ';
const PPPSbaEmailAddress = 'PPPloanpurchase@sba.gov';

const ActivityComponent = (props) => {
  const { getGuarantyClaim, isGuarantyClaimAvailable, loan } = props;

  const validateLoan = (loanArg) => {
    const { history, match } = props;
    if (!loanArg.account) {
      redirect(history, match);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (loan && loan.id) {
        await getGuarantyClaim(loan.id);
      }
    };
    fetchData();
  }, []);

  const { transactions, renewLoanAction } = props;
  const loanType = loan && loan.opportunity ? util.getLoanName(loan.opportunity.partner) : undefined;
  const sortedTransactions = transactions && transactions.length ? transactions.sort(util.dateSort) : [];
  return loan && validateLoan(loan) ? (
    <Row className="activity">
      <Col xs={12}>
        <div className="desktop">
          <Title text={(
            <div>
              <span className="vx_text-4" data-automation-id="accountAdvanceNameLabel">{loan.account.name}</span>
              &nbsp;&nbsp;
              <span>
                <i data-automation-id="accountAdvanceValueLabel">
                  {loanType}
                    &nbsp;(
                  {loan.name}
                  )
                </i>
              </span>
            </div>
              )}
          />
        </div>
        <div className="vx_visible-phone-block vx_text-4">
          <div>{loan.account.name}</div>
          <div>
            <i>
              {loanType}
                &nbsp;(
              {loan.name}
              )
            </i>
          </div>
          <hr className="vx_hr" />
        </div>
        <div className="vx_visible-phone-block negative-left-padding">
          {!isGuarantyClaimAvailable ? <TotalBalanceTile loan={loan} renewFunc={renewLoanAction} />
            : (
              <div className="guaranty-claim-messaging">
                <BodyText as="p" strong>
                  {GUARANTY_CLAIMED_DESCRIPTION}
                  <Link
                    className="pp-react__link--inline"
                    data-automation-id="pppLoanInfoLink"
                    href={`mailto:${PPPSbaEmailAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {PPPSbaEmailAddress}
                  </Link>
                  .
                </BodyText>
              </div>
            )}
        </div>
        <div>
          <div className="desktop">
            {!isGuarantyClaimAvailable ? <TotalBalanceTile loan={loan} renewFunc={renewLoanAction} />
              : (
                <div className="guaranty-claim-messaging">
                  <BodyText as="p" strong>
                    {GUARANTY_CLAIMED_DESCRIPTION}
                    <Link
                      className="pp-react__link--inline"
                      data-automation-id="pppLoanInfoLink"
                      href={`mailto:${PPPSbaEmailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {PPPSbaEmailAddress}
                    </Link>
                    .
                  </BodyText>
                </div>
              )}
          </div>
          <BasicActivityInfo loan={loan} isGuarantyClaimed={isGuarantyClaimAvailable} />
          <div className="transaction-separator" />
          {!isGuarantyClaimAvailable
          && <TransactionList transactions={sortedTransactions} accountName={loan.account.name} />}
        </div>
      </Col>
    </Row>
  ) : <></>;
};

ActivityComponent.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  loan: PropTypes.shape({
    id: PropTypes.string,
    account: PropTypes.object,
    name: PropTypes.string,
    opportunity: PropTypes.object,
  }),
  match: ReactRouterPropTypes.match.isRequired,
  renewLoanAction: PropTypes.func,
  transactions: PropTypes.arrayOf(PropTypes.object),
  isGuarantyClaimAvailable: PropTypes.bool,
  getGuarantyClaim: PropTypes.func.isRequired,
};

ActivityComponent.defaultProps = {
  loan: undefined,
  renewLoanAction: undefined,
  transactions: undefined,
  isGuarantyClaimAvailable: false,
};

const mapStateToProps = (state) => ({
  isGuarantyClaimAvailable: state.isGuarantyClaimAvailable,
});

const mapDispatchToProps = {
  getGuarantyClaim: getGuarantyClaimAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ActivityComponent);
