export const estimateForgivenessErrorCheck = ({
  payrollCost60Percent,
  totalEligibleExpenses,
  totalPayrollCost,
  fteReductionQuotient,
  actualForgivenessAmount,
}) => (
  !(payrollCost60Percent >= 0)
  || !(totalEligibleExpenses >= 0)
  || !(totalPayrollCost >= 0)
  || !(fteReductionQuotient >= 0)
  || !(actualForgivenessAmount >= 0)
);

export const generateTotalCostsProps = ({
  fteReductionQuotient,
  modifiedForgivenessAmount,
  totalPayrollCost,
  page5Values,
  page2Values,
  totalEligibleExpenses,
}) => {
  const totalCostsProps = {};
  totalCostsProps.fteReductionQuotient = fteReductionQuotient;
  totalCostsProps.modifiedForgivenessAmount = modifiedForgivenessAmount;
  totalCostsProps.totalPayrollCost = totalPayrollCost;
  totalCostsProps.mortgageInterestPayments = page5Values.mortgageInterestPayments || 0;
  totalCostsProps.utilityPayments = page5Values.utilityPayments || 0;
  totalCostsProps.rentOrLeasePayments = page5Values.rentOrLeasePayments || 0;
  totalCostsProps.totalSalaryReduction = page2Values.totalSalaryReduction || 0;
  totalCostsProps.coveredOperationsExpenditures = page5Values.coveredOperationsExpenditures || 0;
  totalCostsProps.coveredSupplierCosts = page5Values.coveredSupplierCosts || 0;
  totalCostsProps.coveredWorkerProtectionExpenditures = page5Values.coveredWorkerProtectionExpenditures || 0;
  totalCostsProps.coveredPropertyDamageCosts = page5Values.coveredPropertyDamageCosts || 0;
  totalCostsProps.totalCostsAdjusted = modifiedForgivenessAmount;
  totalCostsProps.subtotal = totalEligibleExpenses;
  return totalCostsProps;
};
