import React from 'react';
import { Helmet } from 'react-helmet-async';
import displayNames from '../../../constants/displayNames';
import SecurePage from '../../../hocs/secure-page';
import { useDocumentUploadPageEffect, useShallowSelector } from '../../../hooks';
import BetterStandardLayout from '../../../layouts/better-standard-layout/better-standard-layout';
import { caseWorkerSelector } from '../../../redux/selectors';
import CaseWorkerTile from '../../common/case-worker-tile/case-worker-tile';
import { CoveredCostsDocuments } from '../../features';
import { SbaLoanInformationSidebar } from '../../forgiveness/sba-loan-information-sidebar';
import { RedirectParams } from './coveredCostsDocumentsPage.constants';

const CoveredCostsDocumentsPage = () => {
  const caseWorker = useShallowSelector(caseWorkerSelector);

  useDocumentUploadPageEffect(RedirectParams);

  return (
    <div className="summary-container">
      <Helmet title="Covered costs / expenditures documents" />
      <BetterStandardLayout
        bodyElement={<CoveredCostsDocuments />}
        sidebarElement={<SbaLoanInformationSidebar />}
        secondarySidebarElement={<CaseWorkerTile caseWorker={caseWorker} />}
      />
    </div>
  );
};

CoveredCostsDocumentsPage.displayName = displayNames.CoveredCostsDocumentsPage;

export default SecurePage(CoveredCostsDocumentsPage);
