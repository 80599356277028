import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { push as pushAction } from 'connected-react-router';
import countryCodes from '../../constants/countryCodes';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import UploadMultipleDocumentsComponent from '../../components/upload-multiple-documents/upload-multiple-documents';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import {
  uploadBatchDocumentsAction,
  clearUploadErrorAction, clearUploadPendingAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

class IdentificationPage extends Component {
  static displayName = displayNames.IdentificationPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    getProductsAndBfes: PropTypes.func.isRequired,
    groupName: PropTypes.string,
    opportunityId: PropTypes.string,
    push: PropTypes.func.isRequired,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadError: PropTypes.bool,
    uploadPending: PropTypes.bool,
  };

  static defaultProps = {
    country: undefined,
    groupName: undefined,
    opportunityId: undefined,
    uploadError: false,
    uploadPending: true,
  };

  async componentDidMount() {
    const { getProductsAndBfes } = this.props;
    await getProductsAndBfes();
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = async (files) => {
    const { opportunityId, uploadBatchDocuments } = this.props;
    const payloads = [];
    files.forEach((file) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, 'ProofOfIdentity'));
      }
    });

    await uploadBatchDocuments(payloads);
  };

  redirectToSummaryConfirmation() {
    const {
      country, groupName, push, opportunityId,
    } = this.props;
    const successStipulationGroupName = groupName || 'Identification';
    const RoutePayload = {
      country,
      successStipulationGroupName,
    };

    heapService.markUserStipulationAsCompleted(opportunityId, successStipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    let { country } = this.props;
    const { uploadPending, uploadError } = this.props;
    country = country.toUpperCase();

    const pageTitle = 'Identity Verification';

    let groupDescription = `For each person listed, please upload 2 of the 3 document types described below.
      Refer to each category for a list of acceptable documents. Please note that the
      same document cannot be used to fulfill an additional document type.`;

    let fakeStipulations = [
      {
        displayName: 'Proof of financials',
        description: (
          <>
            <p>
              Upload one of the following: Bank account, credit card statement, loan
              account statement.
            </p>
            <p>
              Note: Must show logo, full name, full address, unique identifier (i.e.
              account number, student ID, Tax ID) and be most current statement.
            </p>
          </>
        ),
      },
      {
        displayName: 'Proof of identity',
        description: (
          <>
            <p>
              Upload one of the following: Financial Statement or Student Record
              from registered Canadian College or University (post-secondary
              institution), Notice of Assessment or Reassessment, Statement from
              Insurer (life, home or auto), Statement of CPP Contributions from
              customer Service Canada online account, Recognized government
              agency issued statement (i.e. Ministry related)
            </p>
            <p>
              Note: All documents must show full name and DOB.
            </p>
          </>
        ),
      },
      {
        displayName: 'Proof of address',
        description: (
          <>
            <p>
              Upload one of the following: College or University (post-secondary
              institution), CRA statement (e.g. requirements to pay, installment
              notice or benefits statement), Financial Statement or Student Record
              from registered Canadian University, Municipal Property Tax
              Assessment, Notice of Assessment or Reassessment, Recognized
              government agency issued statement (i.e. Ministry related), utility bill.
            </p>
            <p>
              Note: Must show logo, full name, full address, unique identifier (i.e.
              account number, student ID, Tax ID) and be most current statement.
            </p>
          </>
        ),
      },
    ];

    if (country === countryCodes.canada) {
      groupDescription = (
        <>
          <strong>Please fulfill 2 of 3 requests as seen below</strong>
          {' '}
          to help us verify personal information. Only 1 document is needed for each request.
          The same document cannot be used more than once.
        </>
      );

      fakeStipulations = [
        {
          displayName: 'Financials',
          description: (
            <>
              <p>
                Upload any financial statement dated within the last 3 months. It must
                display full name, full address, account identifier (e.g. Account number,
                tax number) and be the most current statement.
              </p>
            </>
          ),
        },
        {
          displayName: 'Identity',
          description: (
            <>
              <p>
                Upload one of the documents listed below. It must include full name and Date
                of Birth.
              </p>
              <ul>
                <li>Statement from Insurer (e.g. life, home, auto)</li>
                <li>Canadian Pension Plan (CPP) statement from online account</li>
                <li>CRA Notice of assessment or reassessment</li>
                <li>Any government agency issued statement</li>
              </ul>
            </>
          ),
        },
        {
          displayName: 'Address',
          description: (
            <>
              <p>
                Upload one of the documents listed below. It must show full name, full address,
                account identifier (e.g. Account number, tax number) and be the most current
                statement.
              </p>
              <ul>
                <li>Canada Revenue Agency (CRA) statement</li>
                <li>Property Tax Assessment</li>
                <li>Utility bill</li>
                <li>Any government agency issued statement</li>
              </ul>
            </>
          ),
        },
      ];
    }

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    const stipulations = fakeStipulations;

    return stipulations ? (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <div>
              <PageTitle text={pageTitle} />
              <UploadMultipleDocumentsComponent
                groupDescription={groupDescription}
                minimumStipulationsRequired={2}
                stipulations={stipulations}
                submit={this.submit}
                uploadError={uploadError}
                uploadPending={uploadPending}
              />
            </div>
          )}
          sidebarElement={<></>}
        />
      </>
    ) : <></>;
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    getProductsAndBfes: getProductsAndBfesAction,
    uploadBatchDocuments: uploadBatchDocumentsAction,
    push: pushAction,
  },
)(SecurePage(IdentificationPage));
