import React from 'react';
import PropTypes from 'prop-types';
import './editable-tile.less';

const EditableTile = (props) => {
  const {
    line1, line2, canEdit, editPressed,
  } = props;
  return (
    <div className="vx_panel-well editable-tile">
      <div className="vx_panel-info">
        <div className="vx_panel-header">{line1}</div>
        {line2 && <div className="vx_panel-text"><em>{line2}</em></div>}
      </div>
      {canEdit
      && (
      <button
        className={`button-link vx_panel-action edit clickable ${!line2 ? 'one-line-edit' : ''}`}
        type="button"
        tabIndex="0"
        onClick={editPressed}
        onKeyDown={editPressed}
      >
        Edit
      </button>
      )}
    </div>
  );
};

EditableTile.propTypes = {
  canEdit: PropTypes.bool,
  editPressed: PropTypes.func,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
};

EditableTile.defaultProps = {
  canEdit: false,
  editPressed: undefined,
  line2: undefined,
};

export default EditableTile;
