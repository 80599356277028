import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import {
  Button, Col, Container, Divider, HeadingText, Row,
} from '@swift-paypal/pp-react';
import { useRedirect } from '../../hooks';
import routes from '../../routes';
import FormAlert from './form-alert';
import { updateLoanForgivenessAction } from '../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import QuestionnaireFields from './questionnaire-fields';
import QuestionnaireDetails from './questionnaire-details';
import startAppIcon from '../../static/images/icons/start_app.svg';
import { fieldNames, formName } from './fields/field-constants';
import buildSfPatchData from './questionnaire-utils';
import { componentSelector, useShallowSelector } from './questionnaire-selectors';
import './questionnaire.less';

const QuestionnaireComponent = ({ handleSubmit }) => {
  const [state, setState] = useState({
    hasSubmissionError: false,
    isUpdatingApplicationVersion: false,
    isSubmitting: false,
    isCancelled: false,
  });
  const {
    hasSubmissionError,
    isSubmitting,
    isUpdatingApplicationVersion,
  } = state;

  const dispatch = useDispatch();
  const redirect = useRedirect();
  const {
    advanceId,
    advances,
    forgivenessId,
    loanForgiveness,
    opportunities,
    registeredFields,
    updateLoanForgiveness,
    values,
  } = useShallowSelector(componentSelector);
  useEffect(() => {
    if (!loanForgiveness || !loanForgiveness.allIds.length || state.isCancelled) {
      redirect(routes.summary.path);
    }
  }, [state.isCancelled]);
  useEffect(() => {
    if (hasSubmissionError) {
      setState({ ...state, isSubmitting: false });
    }
  }, [hasSubmissionError]);

  useEffect(() => {
    if (!updateLoanForgiveness?.pending && updateLoanForgiveness?.success && isSubmitting) {
      if (forgivenessObject && !forgivenessObject.applicationVersionSelected && !isUpdatingApplicationVersion) {
        const patchData = {
          applicationVersionSelected: forgivenessObject?.ezApp ? '3508EZ' : '3508',
          id: forgivenessObject?.id,
        };
        setState({ ...state, isUpdatingApplicationVersion: true });
        dispatch(updateLoanForgivenessAction(patchData));
      }
      else {
        setState({ ...state, isUpdatingApplicationVersion: false });
        switch (forgivenessObject?.applicationVersionSelected) {
          case '3508':
            redirect(routes['forgiveness-application'].path);
            break;
          case '3508EZ':
          case '3508S':
            redirect(routes['forgiveness-summary'].path);
            break;
          default:
            return;
        }
      }
    }

    if (updateLoanForgiveness?.error) {
      setState({ ...state, hasSubmissionError: true });
    }
  }, [forgivenessObject, updateLoanForgiveness]);

  const { questionnaireQ5 } = fieldNames;
  const sbaOppId = advances.byId[advanceId]?.opportunityId;
  const pppId = opportunities.byId[sbaOppId]?.paycheckProtectionPlanId;
  const forgivenessObject = loanForgiveness.byId[forgivenessId];

  const onSubmit = () => {
    setState({ ...state, hasSubmissionError: false });
    const sfPatchData = buildSfPatchData(values, registeredFields, forgivenessId, sbaOppId, pppId);

    if (isSubmitting || (questionnaireQ5 in registeredFields && values[questionnaireQ5] === 'false')) {
      return;
    }

    setState({ ...state, isSubmitting: true });
    dispatch(updateLoanForgivenessAction(sfPatchData));
  };

  const onClickCancel = () => {
    setState({ ...state, isCancelled: true });
  };

  return (
    <Container className="questionnaire-component" form>
      <HeadingText size="md" className="questionnaire-heading-text">
        <img alt="" src={startAppIcon} className="start-app-icon" />
        Your application overview
      </HeadingText>
      <QuestionnaireDetails />
      <Divider secondary />
      <QuestionnaireFields loanVersion={forgivenessObject?.loanInformation?.loanVersion} />
      <FormAlert hasSubmissionError={hasSubmissionError} />
      <Row align="center">
        <Col form="auto">
          <Button className="questionnaire-component__button" onClick={handleSubmit(onSubmit)} type="submit">
            Continue
          </Button>
        </Col>
        <Col form="auto">
          <Button secondary className="questionnaire-component__button" onClick={onClickCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

QuestionnaireComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: formName })(QuestionnaireComponent);
