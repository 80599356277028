import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import OriginalSummary from '../../../pages/summary';
import displayNames from '../../../constants/displayNames';
import SecurePage from '../../../hocs/secure-page';
import { useShallowSelector } from '../../../hooks';
import { summaryPageSelector } from './summaryPage.selectors';
import { getAccountsAction } from '../../../redux/actions/account/account-actions';
import { getProductsAndBfesAction } from '../../../redux/actions/account-items/account-items-actions';
import { getAdvancesAction } from '../../../redux/actions/advances/advances-actions';
import { getOpportunitiesAction } from '../../../redux/actions/opportunities/opportunities-actions';
import { clearActiveIds } from '../../../redux/actions/active-ids/active-ids-actions';
import { ForYou, YourLoans } from '../../features';
import './summaryPage.less';

const SummaryPage = () => {
  const dispatch = useDispatch();
  const { portalRedesignEnabled } = useShallowSelector(summaryPageSelector);
  useEffect(() => {
    if (!portalRedesignEnabled) {
      return;
    }
    dispatch(clearActiveIds());
    dispatch(getAccountsAction());
    dispatch(getAdvancesAction());
    dispatch(getOpportunitiesAction());
    dispatch(getProductsAndBfesAction());
  }, []);

  if (!portalRedesignEnabled) {
    return <OriginalSummary />;
  }

  return (
    <>
      <Helmet title="Summary" />
      <div className="summary-page-layout">
        <ForYou />
        <YourLoans />
      </div>
    </>
  );
};

SummaryPage.displayName = displayNames.SummaryPage;

export default SecurePage(SummaryPage);
