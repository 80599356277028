import PropTypes from 'prop-types';
import React from 'react';
import {
  LoadingSpinner,
  HeadingText,
  BodyText,
} from '@paypalcorp/pp-react';
import '../payments.less';

const PaymentModalLoader = ({ paymentLoaderText }) => {
  const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
  };
  return (
    <>
      <div className="centered-container">
        <div style={spinnerStyle}>
          <LoadingSpinner screenReaderText={paymentLoaderText} size="xl" />
        </div>
        <HeadingText size="lg">
          One Moment...
        </HeadingText>
        <br />
        <BodyText size="title">{paymentLoaderText}</BodyText>
      </div>
    </>
  );
};

PaymentModalLoader.propTypes = {
  paymentLoaderText: PropTypes.string.isRequired,
};

export default PaymentModalLoader;
