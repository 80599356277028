import React from 'react';
import PropTypes from 'prop-types';

export const AlertTypes = {
  help: 'HELP',
  success: 'SUCCESS',
  warning: 'WARNING',
  critical: 'CRITICAL',
};

export const AlertClasses = {
  HELP: 'vx_alert-help',
  SUCCESS: 'vx_alert-success',
  WARNING: 'vx_alert-warning',
  CRITICAL: 'vx_alert-critical',
};

export const Alert = (props) => {
  const {
    message, alertType, dataAutomationId, additionalClass,
  } = props;
  return (
    <div>
      <div
        className={`vx_alert ${AlertClasses[alertType]} margin-bottom-sm`}
        role="alert"
        aria-live={alertType === AlertTypes.critical ? 'assertive' : 'polite'}
      >
        <div className={`vx_alert-text align-left word-break ${additionalClass}`} data-automation-id={dataAutomationId}>{message}</div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string, PropTypes.element,
  ]).isRequired,
  alertType: PropTypes.string.isRequired,
  dataAutomationId: PropTypes.string,
  additionalClass: PropTypes.string,
};

Alert.defaultProps = {
  dataAutomationId: undefined,
  additionalClass: '',
};
