/*   Authentication actions        */
export const UNAUTHENTICATED = 'unauthenticated';
export const addUnauthenticated = () => ({ type: UNAUTHENTICATED });

export const AUTHENTICATED = 'authenticated_user';
export const addAuthenticated = () => ({ type: AUTHENTICATED });

// export const SSO_TOKEN_REQUESTED = 'sso_token_requested';
// export const addSsoTokenRequested = () => ({ type: SSO_TOKEN_REQUESTED });

/*   Authorization actions          */
export const AUTHORIZED = 'authorized_user';
export const addAuthorized = (user) => ({ type: AUTHORIZED, user });

export const DEAUTHORIZED = 'deauthorized';
export const addDeauthorized = () => ({ type: DEAUTHORIZED });

export const AUTHORIZATION_ERROR = 'authorization_error';
export const addAuthorizationError = (error) => ({ type: AUTHORIZATION_ERROR, error });

/*   Security questions actions     */
export const SECURITY_QUESTION_REQUESTED = 'security_question_requested';
export const addSecurityQuestionRequested = () => ({ type: SECURITY_QUESTION_REQUESTED });

export const SECURITY_QUESTION_RECEIVED = 'security_question_received';
export const addSecurityQuestionReceived = (question) => ({ type: SECURITY_QUESTION_RECEIVED, question });

export const SECURITY_QUESTION_REQUEST_ERROR = 'security_question_request_error';
export const addSecurityQuestionRequestError = () => ({ type: SECURITY_QUESTION_REQUEST_ERROR });

/*  Multiple security questions actions     */
export const SECURITY_QUESTIONS_REQUESTED = 'security_questions_requested';
export const addSecurityQuestionsRequested = () => ({ type: SECURITY_QUESTIONS_REQUESTED });

export const SECURITY_QUESTIONS_RECEIVED = 'security_questions_received';
export const addSecurityQuestionsReceived = (userQuestions, userQuestionIds) => ({
  type: SECURITY_QUESTIONS_RECEIVED, userQuestions, userQuestionIds,
});

export const SECURITY_QUESTIONS_REQUEST_ERROR = 'security_questions_request_error';
export const addSecurityQuestionsRequestError = () => ({ type: SECURITY_QUESTIONS_REQUEST_ERROR });

/*  Forgot password actions       */
export const FORGOT_PASSWORD_EMAIL_REQUESTED = 'forgot_password_email_requested';
export const addForgotPasswordEmailRequested = () => ({ type: FORGOT_PASSWORD_EMAIL_REQUESTED });

export const FORGOT_PASSWORD_EMAIL_SENT_RECEIVED = 'forgot_password_email_sent_received';
export const addForgotPasswordEmailSentReceived = () => ({ type: FORGOT_PASSWORD_EMAIL_SENT_RECEIVED });

export const FORGOT_PASSWORD_EMAIL_ERROR = 'forgot_password_email_error';
export const addForgotPasswordEmailError = (error) => ({ type: FORGOT_PASSWORD_EMAIL_ERROR, error });

/*  Update password actions       */
export const UPDATE_PASSWORD_REQUEST_PENDING = 'update_password_request_pending';
export const UPDATE_PASSWORD_RECEIVED = 'update_password_received';
export const UPDATE_PASSWORD_REQUEST_ERROR = 'update_password_request_error';
export const CLEAR_UPDATE_PASSWORD_MESSAGE = 'clear_update_password_message';
export const SET_UPDATE_PASSWORD_MESSAGE = 'set_update_password_message';

/*  Create security question actions  */
export const CREATE_SECURITY_QUESTIONS_REQUEST_PENDING = 'create_security_questions_request_pending';
export const CREATE_SECURITY_QUESTIONS_RECEIVED = 'create_security_questions_received';
export const CREATE_SECURITY_QUESTIONS_REQUEST_ERROR = 'create_security_questions_request_error';
export const CLEAR_CREATE_SECURITY_QUESTIONS_MESSAGE = 'clear_create_security_questions_message';
export const SET_CREATE_SECURITY_QUESTIONS_MESSAGE = 'set_create_security_questions_message';
export const LIST_CREATE_SECURITY_QUESTIONS_REQUEST_PENDING = 'list_create_security_questions_request_pending';
export const LIST_CREATE_SECURITY_QUESTIONS_RECEIVED = 'list_create_security_questions_received';
export const LIST_CREATE_SECURITY_QUESTIONS_ERROR = 'list_create_security_questions_error';

/* Reset token actions */
export const VERIFY_RESET_TOKEN_REQUEST_PENDING = 'verify_reset_token_request_pending';
export const VERIFY_RESET_TOKEN_RECEIVED = 'verify_reset_token_received';
export const VERIFY_RESET_TOKEN_REQUEST_ERROR = 'verify_reset_token_request_error';
export const CLEAR_RESET_TOKEN_STATUS = 'clear_reset_token_status';

/* extend user session */
export const KEEP_ALIVE_PENDING = 'keep-alive_pending';
export const KEEP_ALIVE_RECEIVED = 'keep-alive_received';
export const KEEP_ALIVE_ERROR = 'keep-alive_error';

/* end the current user session */
export const EXPIRE_PENDING = 'expire_pending';
export const EXPIRE_RECEIVED = 'expire_received';
export const EXPIRE_ERROR = 'expire_error';
