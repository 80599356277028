import accountService from '../../../services/account.service';
import loanService from '../../../services/loan.service';
import opportunityService from '../../../services/opportunity.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import contactsService from '../../../services/contacts.service';
import { redirect } from '../../../services/window.service';
import * as actions from './account-items-action-types';

export const getLoanAgreementStatusAction = asyncAuthorizedActionCreator(
  {
    pending: actions.LOAN_AGREEMENT_STATUS_REQUEST_PENDING,
    complete: actions.LOAN_AGREEMENT_STATUS_RECEIVED,
    error: actions.LOAN_AGREEMENT_STATUS_REQUEST_ERROR,
  },
  () => async (dispatch, getState) => {
    const oppId = getState().accountItems.inFlightOpportunities[0].opportunity.id;
    const loanAgreementStatus = await opportunityService.getLoanAgreementStatus(getState().config.apiUrl, oppId);
    return loanAgreementStatus;
  },
);

export const getProductsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.PRODUCTS_REQUEST_PENDING,
    complete: actions.PRODUCTS_RECEIVED,
    error: actions.PRODUCTS_REQUEST_ERROR,
  },
  () => async (dispatch, getState) => {
    const products = await loanService.getLoans(getState().config.apiUrl);
    const loans = products.filter((x) => x.loanId);
    await loanService.getLoanDetails(getState().config.apiUrl, loans);
    return products;
  },
);

export const getProductsAndBfesAction = asyncAuthorizedActionCreator(
  {
    pending: actions.PRODUCTS_BFES_REQUEST_PENDING,
    complete: actions.PRODUCTS_BFES_RECEIVED,
    error: actions.PRODUCTS_BFES_REQUEST_ERROR,
  },
  () => async (dispatch) => {
    await dispatch(getProductsAction());
    return dispatch(getBfesAction());
  },
);

export const getLoanAction = asyncAuthorizedActionCreator(
  {
    pending: actions.LOAN_REQUEST_PENDING,
    complete: actions.LOAN_REQUEST_RECEIVED,
    error: actions.LOAN_REQUEST_ERROR,
  },
  (loanId) => (dispatch, getState) => loanService.getLoan(getState().config.apiUrl, loanId),
);

export const getBfesAction = asyncAuthorizedActionCreator(
  {
    pending: actions.BFES_REQUEST_PENDING,
    complete: actions.BFES_RECEIVED,
    error: actions.BFES_REQUEST_ERROR,
  },
  () => (dispatch, getState) => {
    const { config } = getState();
    return opportunityService.getBfes(config.apiUrl, getState().accountItems.inFlightOpportunities);
  },
);

export const renewLoanAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ACCOUNT_RENEWAL_PENDING,
    complete: actions.ACCOUNT_RENEWAL_RECEIVED,
    error: actions.ACCOUNT_RENEWAL_ERROR,
  },
  (accountId) => (dispatch, getState) => accountService.renewLoan(getState().config.apiUrl, accountId)
    .then((renewalApplicationUrl) => {
      redirect(renewalApplicationUrl);
      return renewalApplicationUrl;
    }),
);

export const clearSelectedLoanAction = () => (dispatch) => dispatch({ type: actions.LOAN_REQUEST_CLEAR });

export const getTransactionsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.TRANSACTIONS_REQUEST_PENDING,
    complete: actions.TRANSACTIONS_RECEIVED,
    error: actions.TRANSACTIONS_REQUEST_ERROR,
  },
  (loanId) => (dispatch, getState) => loanService.getLoanTransactions(getState().config.apiUrl, loanId),
);

export const getContactsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CONTACTS_REQUEST_PENDING,
    complete: actions.CONTACTS_REQUEST_RECEIVED,
    error: actions.CONTACTS_REQUEST_ERROR,
  },
  (opportunityId) => (dispatch, getState) => opportunityService.getContacts(getState().config.apiUrl, opportunityId),
);

export const createContactsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CREATE_CONTACTS_REQUEST_PENDING,
    complete: actions.CREATE_CONTACTS_REQUEST_RECEIVED,
    error: actions.CREATE_CONTACTS_REQUEST_ERROR,
  },
  (contacts) => (dispatch, getState) => contactsService.createOrUpdateContacts(getState().config.apiUrl, contacts),
);

export const clearContactsAction = () => (dispatch) => dispatch({ type: actions.CONTACTS_REQUEST_CLEAR });
