import MortgageInterestPayments from '../../fields/mortgage-interest-payments';
import RentOrLeasePayments from '../../fields/rent-or-lease-payments';
import UtilityPayments from '../../fields/utility-payments';
import EmployeesAtApplicationTime from '../../fields/employees-at-application-time';
import { CoveredOperationsExpenditures } from '../../fields/covered-operations-expenditures';
import { CoveredPropertyDamageCosts } from '../../fields/covered-property-damage-costs';
import { CoveredSupplierCosts } from '../../fields/covered-supplier-costs';
import { CoveredWorkerProtectionExpenditures } from '../../fields/covered-worker-protection-expenditures';
import LoanGreaterThan2Million from '../../fields/loan-greater-than-two-million/loan-greater-than-two-million';

export const otherBusinessDetailsFields = [
  [
    EmployeesAtApplicationTime,
    'employeesAtApplicationTime',
  ],
  [
    MortgageInterestPayments,
    'mortgageInterestPayments',
  ],
  [
    UtilityPayments,
    'utilityPayments',
  ],
  [
    RentOrLeasePayments,
    'rentOrLeasePayments',
  ],
  [
    CoveredOperationsExpenditures,
    'coveredOperationsExpenditures',
  ],
  [
    CoveredPropertyDamageCosts,
    'coveredPropertyDamageCosts',
  ],
  [
    CoveredSupplierCosts,
    'coveredSupplierCosts',
  ],
  [
    CoveredWorkerProtectionExpenditures,
    'coveredWorkerProtectionExpenditures',
  ],
  [
    LoanGreaterThan2Million,
    'loanGreaterThan2Million',
  ],
];
