import moment from 'moment';
import util from '../../../util';
import { PPP_LOAN_AMOUNT, PPP_LOAN_ISSUE_DATE } from './sba-loan-information-sidebar-constants';

export const getInfoItemContent = (key, value) => {
  if (key === PPP_LOAN_AMOUNT) {
    return util.getCurrency(value);
  }

  if (key === PPP_LOAN_ISSUE_DATE) {
    return moment(value).format('ll');
  }

  return value;
};
