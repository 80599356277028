import React from 'react';
import PropTypes from 'prop-types';

/* component for conditionally displaying left then right aligned text in the same line:
 * main: set to true if text should be bolded
 * condition: checks condition to see if text should be displayed
 * left: left-aligned text/element
 * right: right-aligned text/element
 * className: class to apply to the entire line
 * */
const ConditionalLeftRight = (props) => {
  const {
    main, condition, left, right, className, dataName, leftId, rightId,
  } = props;
  return condition !== undefined && condition !== null && condition !== false
    ? (
      <div className={className}>
        <span className={`align-left vx_text-body-lg ${main ? 'bold-body-text' : ''}`} data-automation-id={leftId}>{left}</span>
        <span data-prop={dataName} className={`activity-pull-right vx_text-body-lg ${main ? 'bold-body-text' : ''}`} data-automation-id={rightId}>{right}</span>
      </div>
    ) : <></>;
};

ConditionalLeftRight.propTypes = {
  className: PropTypes.string,
  dataName: PropTypes.string,
  condition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  main: PropTypes.bool,
  right: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  leftId: PropTypes.string,
  rightId: PropTypes.string,
};

ConditionalLeftRight.defaultProps = {
  className: undefined,
  dataName: undefined,
  condition: undefined,
  leftId: undefined,
  rightId: undefined,
  main: false,
};

export default ConditionalLeftRight;
