import {
  PendingPausedIcon,
  CriticalIcon,
  CheckmarkBackgroundIcon,
  WarningIcon,
  ClockIcon,
}
from '@paypalcorp/pp-react';

export const transactionStatusConfig = {
  Q: {
    primaryText: 'Scheduled',
    type: 'neutral',
    icon: 'PendingPausedIcon',
  },
  F: {
    primaryText: 'Failed',
    icon: 'CriticalIcon',
    type: 'critical',
  },
  S: {
    primaryText: 'Processed',
    icon: 'CheckmarkBackgroundIcon',
    type: 'success',
  },
  C: {
    primaryText: 'Voided',
    icon: 'WarningIcon',
    type: 'warning',
  },
  PA: {
    primaryText: 'Scheduled',
    type: 'neutral',
    icon: 'PendingPausedIcon',
  },
};

export const paymentBadgesConfig = {
  'Recurring Payments ON': {
    icon: 'ClockIcon',
    type: 'success',
  },
};

export const statusIcons = {
  PendingPausedIcon,
  CriticalIcon,
  CheckmarkBackgroundIcon,
  WarningIcon,
  ClockIcon,
};

export const nonSelectableFiStatus = ['Needs Attention', 'Pending'];
