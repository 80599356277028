import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, FormSection, reduxForm } from 'redux-form';
import {
  date, email, format, length, numericality, required,
} from 'redux-form-validators';
import {
  Button, Col, Row, Tile,
} from '@swift-paypal/pp-react';
import {
  normalizeDate,
  normalizeCanadianDateOfBirth,
  normalizeNumber,
  normalizePhone,
  normalizePostalCode,
  normalizeSocialInsurance,
  normalizeSocialSecurity,
  normalizeZip,
} from '../../lib/forms/normalizations';
import constants from '../../constants';
import countryCodes from '../../constants/countryCodes';
import validators from '../../lib/forms/validators';
import floatingLabelField from '../../lib/forms/floating-label-field';
import selectField from '../../lib/forms/select-field';
import { Alert, AlertTypes } from '../common/alert';
import util from '../../util';
import withUrlParams from '../../hocs/with-url-params';

const { errors } = constants;

export class OwnershipForm extends React.Component {
  states = _.sortBy(constants.states, ['name']);

  provinces = _.sortBy(constants.provinces, ['name']);

  yesOrNo = ['Yes', 'No'];

  async componentDidMount() {
    await import('../../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/core/core');
    await import('../../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/inputs-selects/floating-label');
  }

  businessOwnerValidators = () => [
    required({ msg: errors.requiredField }),
    this.validateTotalOwnership,
    this.validateOwnershipPercentage,
  ];

  controllingManagerValidators = () => [
    required({ msg: errors.requiredField }),
    this.validateTotalOwnership,
    numericality({
      int: true,
      '>=': 0,
      msg: errors.ownership.addControllingManagerUnderMinimum,
    }),
  ];

  additionalSignerValidators = () => [
    required({ msg: errors.requiredField }),
    this.validateTotalOwnership,
    numericality({
      int: true,
      '>=': 1,
      msg: errors.ownership.addAdditionalSignerUnderMinimum,
    }),
  ];

  socialInsuranceValidators = () => [
    required({ msg: errors.requiredField }),
    validators.sinValidator,
    this.validateSocialInsurance,
  ];

  socialSecurityValidators = () => [
    required({ msg: errors.requiredField }),
    validators.ssnValidator,
    this.validateSocialSecurity,
  ];

  postalCodeValidators = () => [
    required({ msg: errors.requiredField }),
    validators.postalCodeValidator,
  ];

  continue = (owner) => {
    const { continueFunc, closeForm } = this.props;
    continueFunc(owner);
    closeForm();
  };

  continueAndAddAnother = (owner) => {
    const { continueFunc, reset, reopenForm } = this.props;
    continueFunc(owner);
    reset();
    reopenForm();
  };

  emailValidators = () => [
    required({ msg: errors.requiredField }),
    email({ msg: errors.invalidEmail }),
    length({ max: 75, msg: errors.emailLength }),
    this.validateEmailIsNotDuplicate,
  ];

  showEmailDuplicateMessage = (list, value) => {
    const owners = list.filter((owner) => owner.email.trim() === value.trim());
    return owners.length > 0 ? errors.ownership.duplicateEmail : undefined;
  }

  validateEmailIsNotDuplicate = (value) => {
    const { owners, editExisting, initialValues } = this.props;
    if (editExisting) {
      // remove the current editing owner from the owners list for checking duplicated emails
      const unEditedOwners = owners.filter((owner) => owner.email.trim() !== initialValues.email.trim());
      return this.showEmailDuplicateMessage(unEditedOwners, value);
    }
    return this.showEmailDuplicateMessage(owners, value);
  }

  validateOwnershipPercentage = (value) => {
    const { isSbaLoan } = this.props;
    const minimunPerncentage = isSbaLoan ? 20 : 25;
    if (value >= minimunPerncentage) {
      return undefined;
    }
    return (isSbaLoan ? errors.ownership.addSbaOwnerUnderMinimum : errors.ownership.addOwnerUnderMinimum);
  }

  validateTotalOwnership = (value) => {
    const {
      initialValues,
      owners,
    } = this.props;

    const initialPercent = initialValues && initialValues.percentOwned
      ? parseInt(value, 10) - parseInt(initialValues.percentOwned, 10) : parseInt(value, 10);
    const totalOwnership = owners
      .reduce((acc, owner) => acc + parseInt(owner.percentOwned, 10), initialPercent) || 0;
    return totalOwnership > 100 ? errors.ownership.addOwnerOverLimit : undefined;
  };

  validateSocialInsurance = (value) => {
    const {
      businessOwner,
      customerSupportPhone,
      initialValues,
      owners,
    } = this.props;

    const initialSin = initialValues && initialValues.sin
      ? initialValues.sin : undefined;
    const ownersWithSameSin = owners
      .filter((owner) => util.cleanSin(owner.sin) === util.cleanSin(value));
    const hasApplicant = ownersWithSameSin.length
      && ownersWithSameSin.filter((owner) => owner.isApplicant).length > 0;

    if (hasApplicant) {
      // cannot add applicant as owner or controlling manager
      return businessOwner
        ? errors.ownership.addApplicantAsOwner(customerSupportPhone)
        : errors.ownership.addApplicantAsManager(customerSupportPhone);
    }
    if (ownersWithSameSin.length) {
      if (initialSin) {
        if (ownersWithSameSin.length > 1) {
          return errors.ownership.duplicateSin;
        }
        if (util.cleanSin(ownersWithSameSin[0].sin) === util.cleanSin(initialSin)) {
          return undefined;
        }
        return errors.ownership.duplicateSin;
      }

      return errors.ownership.duplicateSin;
    }
    return undefined;
  };

  validateSocialSecurity = (value) => {
    const {
      businessOwner,
      customerSupportPhone,
      initialValues,
      owners,
    } = this.props;

    const initialSsn = initialValues && initialValues.ssn
      ? initialValues.ssn : undefined;
    const ownersWithSameSsn = owners
      .filter((owner) => util.cleanSsn(owner.ssn) === util.cleanSsn(value));
    const hasApplicant = ownersWithSameSsn.length
      && ownersWithSameSsn.filter((owner) => owner.isApplicant).length > 0;

    if (hasApplicant) {
      // cannot add applicant as owner or controlling manager
      return businessOwner
        ? errors.ownership.addApplicantAsOwner(customerSupportPhone)
        : errors.ownership.addApplicantAsManager(customerSupportPhone);
    }
    if (ownersWithSameSsn.length) {
      if (initialSsn) {
        if (ownersWithSameSsn.length > 1) {
          return errors.ownership.duplicateSsn;
        }
        if (util.cleanSin(ownersWithSameSsn[0].ssn) === util.cleanSsn(initialSsn)) {
          return undefined;
        }
        return errors.ownership.duplicateSsn;
      }

      return errors.ownership.duplicateSsn;
    }
    return undefined;
  };

  render() {
    const {
      businessOwner,
      ownerCanBecomeNonowner,
      additionalSigner,
      submitError,
      closeForm,
      editEmailOnly,
      handleSubmit,
      editExisting,
      isSbaLoan,
    } = this.props;
    let { country } = this.props;
    country = country.toUpperCase();

    return (
      <Tile card className="ownership-form">
        <Tile.Content>
          <Row>
            <Col xs={12}>
              <button type="button" onClick={closeForm} className="ownership-form-close pull-right">
                <span className="vx_text-3 vx_icon vx_icon-close-small" />
              </button>
            </Col>
          </Row>
          <form id="ownership-form">
            <Col xs={12}>
              {submitError && <Alert message={submitError} alertType={AlertTypes.critical} />}
            </Col>
            { editEmailOnly
            && (
            <div>
              All signers are guarantors on the loan. This email will be used to administer the contract for signature.
            </div>
            ) }
            { !editEmailOnly && (
              <FormSection name="name">
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      name="first"
                      label="First name"
                      manualSet
                      component={floatingLabelField}
                      type="text"
                      className="vx_form-control form-control"
                      validate={[
                        required({ msg: errors.requiredField }),
                        length({ max: 100, msg: errors.firstNameLength }),
                      ]}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      name="last"
                      label="Last name"
                      manualSet
                      component={floatingLabelField}
                      type="text"
                      className="vx_form-control form-control"
                      validate={[
                        required({ msg: errors.requiredField }),
                        length({ max: 100, msg: errors.lastNameLength }),
                      ]}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            {!editEmailOnly && isSbaLoan
            && (
            <FormSection name="jobTitle">
              <Row>
                <Col>
                  <Field
                    name="job"
                    label="Job title"
                    manualSet
                    component={floatingLabelField}
                    type="text"
                    className="vx_form-control form-control"
                    validate={[
                      required({ msg: errors.requiredField }),
                      length({ max: 128, msg: errors.ownership.titleLength }),
                    ]}
                  />
                </Col>
              </Row>
            </FormSection>
            )}
            {!editEmailOnly
            && (
            <FormSection name="postalAddress">
              <Row>
                <Col>
                  <Field
                    name="street"
                    label="Street address"
                    manualSet
                    component={floatingLabelField}
                    type="text"
                    className="vx_form-control form-control"
                    validate={[required({ msg: errors.requiredField })]}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Field
                    name="city"
                    label="City"
                    manualSet
                    component={floatingLabelField}
                    type="text"
                    className="vx_form-control form-control"
                    validate={[required({ msg: errors.requiredField })]}
                  />
                </Col>
                {country === countryCodes.unitedStates
                && (
                <Col md={4}>
                  <Field
                    name="state"
                    label="State"
                    component={selectField}
                    className="vx_form-control form-control"
                    validate={[required({ msg: errors.requiredField })]}
                    options={this.states}
                  >
                    <option value="" key="" disabled>State</option>
                    {this.states.map((state) => (
                      <option key={state.abbreviation} value={state.abbreviation}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                </Col>
                )}
                {country === countryCodes.canada
                && (
                <Col md={4}>
                  <Field
                    name="province"
                    label="Province"
                    component={selectField}
                    className="vx_form-control form-control"
                    validate={[required({ msg: errors.requiredField })]}
                    options={this.provinces}
                  >
                    <option value="" key="" disabled>Province</option>
                    {this.provinces.map((province) => (
                      <option key={province.abbreviation} value={province.abbreviation}>
                        {province.name}
                      </option>
                    ))}
                  </Field>
                </Col>
                )}
                {country === countryCodes.unitedStates
                && (
                <Col md={4}>
                  <Field
                    name="zip"
                    label="ZIP"
                    manualSet
                    component={floatingLabelField}
                    type="text"
                    className="vx_form-control form-control"
                    validate={[
                      required({ msg: errors.requiredField }),
                      length({ is: 5, msg: errors.requiredField }),
                    ]}
                    normalize={normalizeZip}
                  />
                </Col>
                )}
                {country === countryCodes.canada
                && (
                <Col md={4}>
                  <Field
                    name="postal"
                    label="Postal Code"
                    manualSet
                    component={floatingLabelField}
                    type="text"
                    className="vx_form-control form-control"
                    validate={this.postalCodeValidators()}
                    normalize={normalizePostalCode}
                  />
                </Col>
                )}
              </Row>
            </FormSection>
            )}
            {!editEmailOnly
            && (
              <Row>
                <Col xs={12}>
                  <p className="vx_text-legal">
                    This must be a physical address, not a PO Box number.
                  </p>
                </Col>
              </Row>
            )}
            {!editEmailOnly
            && (
              <Row>
                <Col xs={12}>
                  <Field
                    name="phone"
                    label="Phone number"
                    manualSet
                    component={floatingLabelField}
                    type="text"
                    className="vx_form-control form-control"
                    validate={[
                      required({ msg: errors.requiredField }),
                      format({ with: /^\(\d{3}\) \d{3}-\d{4}/, msg: errors.invalidPhone }),
                    ]}
                    normalize={normalizePhone}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <Field
                  name="email"
                  label="Contact email"
                  manualSet
                  component={floatingLabelField}
                  type="email"
                  className="vx_form-control form-control"
                  validate={this.emailValidators()}
                />
              </Col>
            </Row>
            <Row>
              {!editEmailOnly
              && (
                <Col xs={12}>
                  <Field
                    name="percentOwned"
                    label="What % of business does this individual own?"
                    manualSet
                    component={floatingLabelField}
                    type="number"
                    className="vx_form-control form-control"
                    validate={
                      // eslint-disable-next-line no-nested-ternary
                      businessOwner && !ownerCanBecomeNonowner
                        ? this.businessOwnerValidators()
                        : additionalSigner ? this.additionalSignerValidators() : this.controllingManagerValidators()
                    }
                    normalize={normalizeNumber}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {!editEmailOnly && country === countryCodes.unitedStates
              && (
              <Col xs={6}>
                <Field
                  name="dateOfBirth"
                  label="Date of birth"
                  manualSet
                  component={floatingLabelField}
                  type="text"
                  className="vx_form-control form-control"
                  validate={[
                    required({ msg: errors.requiredField }),
                    date({ format: 'mm/dd/yyyy', msg: errors.invalidBirthDateFormat }),
                    date({
                      format: 'mm/dd/yyyy', '<=': 'today', '>=': new Date(1900, 0, 0), msg: errors.invalidBirthDate,
                    }),
                  ]}
                  normalize={normalizeDate}
                />
              </Col>
              )}
              {!editEmailOnly && country === countryCodes.canada
              && (
              <Col xs={6}>
                <Field
                  name="dateOfBirth"
                  label="Date of birth"
                  manualSet
                  component={floatingLabelField}
                  type="text"
                  className="vx_form-control form-control"
                  validate={[
                    required({ msg: errors.requiredField }),
                    date({ format: 'yyyy/mm/dd', msg: errors.invalidCanadianBirthDate }),
                    date({
                      format: 'yyyy/mm/dd', '<=': 'today', '>=': new Date(1900, 0, 0), msg: errors.invalidBirthDate,
                    }),
                  ]}
                  normalize={normalizeCanadianDateOfBirth}
                />
              </Col>
              )}
              {!editEmailOnly && country === countryCodes.unitedStates
              && (
              <Col xs={6}>
                <Field
                  name="ssn"
                  label="Social Security number"
                  manualSet
                  component={floatingLabelField}
                  type="text"
                  className="vx_form-control form-control do-not-record"
                  validate={this.socialSecurityValidators()}
                  normalize={normalizeSocialSecurity}
                />
              </Col>
              )}
              {!editEmailOnly && country === countryCodes.canada
              && (
              <Col xs={6}>
                <Field
                  name="sin"
                  label="Social Insurance number"
                  manualSet
                  component={floatingLabelField}
                  type="text"
                  className="vx_form-control form-control do-not-record"
                  validate={this.socialInsuranceValidators()}
                  normalize={normalizeSocialInsurance}
                />
              </Col>
              )}
            </Row>
            <div className="display-none">
              {/* maintain hidden states in this block */}
              <Field
                name="isBeneficialOwner"
                label="IsBeneficialOwner"
                id="isBeneficialOwner"
                component={floatingLabelField}
                type="checkbox"
              />
              <Field
                name="isControllingManager"
                label="IsControllingManager"
                id="isControllingManager"
                component={floatingLabelField}
                type="checkbox"
              />
              <Field
                name="isSigner"
                label="IsAdditionalSigner"
                id="isSigner"
                component={floatingLabelField}
                type="checkbox"
              />
            </div>
          </form>
          <Row>
            <Col
              xs={{ offset: 2, span: 8 }}
              sm={{ offset: 3, span: 6 }}
              md={{ offset: 4, span: 4 }}
            >
              <Button
                type="submit"
                className="pp-react__button--block"
                form="ownership-form"
                onClick={handleSubmit(this.continue)}
              >
                Continue
              </Button>
            </Col>
          </Row>
          <Row>
            {!editExisting
            && (
            <Col
              className="center"
              xs={{ offset: 2, span: 8 }}
              sm={{ offset: 3, span: 6 }}
              md={{ offset: 4, span: 4 }}
            >
              <a
                className="vx_anchor"
                href="/#"
                onClick={handleSubmit(this.continueAndAddAnother)}
              >
                Confirm and add another
              </a>
            </Col>
            )}
          </Row>
        </Tile.Content>
      </Tile>
    );
  }
}

OwnershipForm.propTypes = {
  additionalSigner: PropTypes.bool,
  businessOwner: PropTypes.bool,
  closeForm: PropTypes.func,
  continueFunc: PropTypes.func,
  country: PropTypes.string,
  customerSupportPhone: PropTypes.string,
  editEmailOnly: PropTypes.bool,
  editExisting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    percentOwned: PropTypes.string,
    sin: PropTypes.string,
    ssn: PropTypes.string,
    email: PropTypes.string,
  }),
  ownerCanBecomeNonowner: PropTypes.bool,
  owners: PropTypes.arrayOf(PropTypes.object),
  reopenForm: PropTypes.func,
  reset: PropTypes.func,
  submitError: PropTypes.string,
  isSbaLoan: PropTypes.bool,
};

OwnershipForm.defaultProps = {
  additionalSigner: false,
  businessOwner: true,
  closeForm: undefined,
  continueFunc: undefined,
  country: undefined,
  customerSupportPhone: undefined,
  editEmailOnly: false,
  editExisting: false,
  handleSubmit: undefined,
  initialValues: undefined,
  ownerCanBecomeNonowner: undefined,
  owners: undefined,
  reopenForm: undefined,
  reset: undefined,
  submitError: undefined,
  isSbaLoan: false,
};

const mapStateToProps = (state) => ({
  customerSupportPhone: state.config.customerSupportPhone,
});

const OwnershipFormComponent = compose(
  reduxForm({}),
  withRouter,
  withUrlParams,
  connect(mapStateToProps),
)(OwnershipForm);

export default OwnershipFormComponent;
