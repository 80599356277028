import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, BodyText, CaptionText } from '@swift-paypal/pp-react';
import util from '../../../../../util';
import './total-costs-fte-reduction.less';

const TotalCostsFteReductionText = ({
  fteReductionQuotient,
  mortgageInterestPayments,
  rentOrLeasePayments,
  subtotal,
  totalCostsAdjusted,
  totalPayrollCost,
  totalSalaryReduction,
  utilityPayments,
  coveredOperationsExpenditures,
  coveredSupplierCosts,
  coveredWorkerProtectionExpenditures,
  coveredPropertyDamageCosts,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const toggleShowDetails = () => setShowDetails(!showDetails);

  let fteReductionQuotientText = '0';
  if (fteReductionQuotient) {
    fteReductionQuotientText = (fteReductionQuotient
      - (Math.round((fteReductionQuotient + Number.EPSILON) * 10) / 10) === 0)
      ? fteReductionQuotient.toFixed(1)
      : fteReductionQuotient.toFixed(2);
  }

  const totalPayrollCostText = util.generateCurrencyText(totalPayrollCost);
  const mortgageInterestPaymentsText = util.generateCurrencyText(mortgageInterestPayments);
  const utilityPaymentsText = util.generateCurrencyText(utilityPayments);
  const rentOrLeasePaymentsText = util.generateCurrencyText(rentOrLeasePayments);
  const totalSalaryReductionText = util.generateCurrencyText(totalSalaryReduction);
  const coveredOperationsExpendituresText = util.generateCurrencyText(coveredOperationsExpenditures);
  const coveredSupplierCostsText = util.generateCurrencyText(coveredSupplierCosts);
  const coveredWorkerProtectionExpendituresText = util.generateCurrencyText(coveredWorkerProtectionExpenditures);
  const coveredPropertyDamageCostsText = util.generateCurrencyText(coveredPropertyDamageCosts);
  const subtotalText = util.generateCurrencyText(subtotal);
  const totalCostsAdjustedText = util.generateCurrencyText(totalCostsAdjusted);

  return (
    <div className="total-costs-fte-reduction-text">
      <div className="row">
        <BodyText as="span" className="text">Total costs adjusted for FTE reduction quotient</BodyText>
        <Divider className="dot-leader" secondary />
        <BodyText as="span" className="text sign" />
        <BodyText as="span" strong className="text amount">{totalCostsAdjustedText}</BodyText>
      </div>
      <div className="row">
        <button className="link-button ppvx_link" type="button" onClick={toggleShowDetails}>
          {`${showDetails ? 'Hide' : 'Show'} details`}
        </button>
      </div>
      {showDetails && (
        <>
          <div className="row">
            <CaptionText as="span" className="text caption">Total payroll cost</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign" />
            <BodyText as="span" className="text amount">{totalPayrollCostText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Business mortgage interest cost</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{mortgageInterestPaymentsText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Business rent/lease cost</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{rentOrLeasePaymentsText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Business utility cost</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{utilityPaymentsText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Covered operations expenditures</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{coveredOperationsExpendituresText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Covered supplier costs</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{coveredSupplierCostsText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Covered property damage costs</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{coveredPropertyDamageCostsText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Covered worker protection expenditures</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">+</BodyText>
            <BodyText as="span" className="text amount">{coveredWorkerProtectionExpendituresText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">Total salary/hourly wage reduction</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">-</BodyText>
            <BodyText as="span" className="text amount">{totalSalaryReductionText}</BodyText>
          </div>
          <div className="row">
            <BodyText as="span" strong className="text">Subtotal</BodyText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">=</BodyText>
            <BodyText as="span" strong className="text amount total">{subtotalText}</BodyText>
          </div>
          <div className="row">
            <CaptionText as="span" className="text caption">FTE reduction quotient</CaptionText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">*</BodyText>
            <BodyText as="span" className="text amount">{fteReductionQuotientText}</BodyText>
          </div>
          <div className="row">
            <BodyText as="span" strong className="text">Total</BodyText>
            <Divider className="dot-leader" secondary />
            <BodyText as="span" className="text sign">=</BodyText>
            <BodyText as="span" strong className="text amount total">{totalCostsAdjustedText}</BodyText>
          </div>
        </>
      )}
    </div>
  );
};

TotalCostsFteReductionText.propTypes = {
  fteReductionQuotient: PropTypes.number.isRequired,
  mortgageInterestPayments: PropTypes.number.isRequired,
  rentOrLeasePayments: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  totalCostsAdjusted: PropTypes.number.isRequired,
  totalPayrollCost: PropTypes.number.isRequired,
  totalSalaryReduction: PropTypes.number.isRequired,
  utilityPayments: PropTypes.number.isRequired,
  coveredOperationsExpenditures: PropTypes.number.isRequired,
  coveredPropertyDamageCosts: PropTypes.number.isRequired,
  coveredSupplierCosts: PropTypes.number.isRequired,
  coveredWorkerProtectionExpenditures: PropTypes.number.isRequired,
};

export default memo(TotalCostsFteReductionText);
