import * as actions from '../actions/upload-document/upload-document-action-types';

const uploadDocument = (state = { stipulationSuccess: false }, action) => {
  switch (action.type) {
    case actions.DOCUMENT_TYPES_REQUEST_PENDING:
      return state;
    case actions.DOCUMENT_TYPES_REQUEST_RECEIVED:
      return { ...state, documentTypes: action.payload };
    case actions.DOCUMENT_TYPES_REQUEST_REQUEST_ERROR:
      return { ...state, error: action.payload.message };
    case actions.DOCUMENT_UPLOAD_REQUEST_PENDING:
      return {
        ...state, uploadPending: true, error: undefined, blockBackButton: true,
      };
    case actions.DOCUMENT_UPLOAD_REQUEST_COMPLETED:
      return { ...state, uploadPending: false, blockBackButton: false };
    case actions.DOCUMENT_UPLOAD_REQUEST_ERROR:
      return {
        ...state,
        uploadPending: false,
        error: action.payload.message,
        response: action.payload.response?.data,
        blockBackButton: false,
      };
    case actions.DOCUMENTS_UPLOAD_REQUEST_PENDING:
      return {
        ...state, uploadPending: true, error: undefined, blockBackButton: true,
      };
    case actions.DOCUMENTS_UPLOAD_REQUEST_COMPLETED:
      return {
        ...state,
        uploadPending: false,
        error: undefined,
        blockBackButton: false,
        uploadedFiles: action.payload.success,
        failedFiles: action.payload.failure,
      };
    case actions.DOCUMENTS_UPLOAD_REQUEST_ERROR:
      return {
        ...state,
        uploadPending: false,
        error: action.payload.message,
        response: action.payload.response?.data,
        blockBackButton: false,
        failedFiles: [],
      };
    case actions.DOCUMENTS_UPLOAD_REQUEST_CLEAR:
      return { stipulationSuccess: false };
    case actions.DOCUMENTS_UPLOAD_CLEAR_FAILED_FILES:
      return {
        ...state,
        failedFiles: [],
      };
    case actions.DOCUMENT_UPLOAD_CLEAR_REQUEST_ERROR:
      return { ...state, error: undefined };
    case actions.DOCUMENT_UPLOAD_CLEAR_PENDING:
      return { ...state, uploadPending: undefined };
    case actions.DOCUMENT_UPLOAD_STIPULATION_SUCCESS:
      return {
        ...state,
        stipulationSuccess: true,
      };
    case actions.DOCUMENT_UPLOAD_CLEAR_STIPULATION_SUCCESS:
      return { ...state, stipulationSuccess: undefined };
    default:
      return state;
  }
};

export default uploadDocument;
