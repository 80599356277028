export const FieldNames = {
  AlternativeCoveredPeriodEndDate: 'alternativeCoveredPeriodEndDate',
  AlternativeCoveredPeriodStartDate: 'alternativeCoveredPeriodStartDate',
  AverageFteDuringChosenPeriod: 'averageFteDuringChosenPeriod',
  AverageFteTable1: 'averageFteTable1',
  AverageFteTable2: 'averageFteTable2',
  CoveredOperationsExpenditures: 'coveredOperationsExpenditures',
  CoveredPeriod24Week: 'coveredPeriod24Week',
  CoveredPeriodLength: 'coveredPeriodLength',
  CoveredPropertyDamageCosts: 'coveredPropertyDamageCosts',
  CoveredSupplierCosts: 'coveredSupplierCosts',
  CoveredWorkerProtectionExpenditures: 'coveredWorkerProtectionExpenditures',
  EidlAmount: 'eidlAmount',
  EidlLoanNumber: 'eidlLoanNumber',
  EmployeeHealthInsurance: 'employeeHealthInsurance',
  EmployeeRetirementPlans: 'employeeRetirementPlans',
  EmployeesAtApplicationTime: 'employeesAtApplicationTime',
  FteSafeHarbor1: 'fteSafeHarbor1',
  FteSafeHarbor2: 'fteSafeHarbor2',
  HasCoveredFteReductions: 'hasCoveredFteReductions',
  LoanGreaterThan2Million: 'loanGreaterThan2Million',
  MaintainedHourlyWage: 'maintainedHourlyWage',
  MortgageInterestPayments: 'mortgageInterestPayments',
  NoReductionInEmployeeAverageHours: 'noReductionInEmployeeAverageHours',
  NumberOfOwners: 'numberOfOwners',
  OwnerCompensationTotal: 'ownerCompensationTotal',
  PayrollSchedule: 'payrollSchedule',
  PreferredCoveredPeriod: 'preferredCoveredPeriod',
  RentOrLeasePayments: 'rentOrLeasePayments',
  StandardCoveredPeriodEndDate: 'standardCoveredPeriodEndDate',
  StandardCoveredPeriodStartDate: 'standardCoveredPeriodStartDate',
  StateAndLocalTaxes: 'stateAndLocalTaxes',
  TotalCashCompensationTable1: 'totalCashCompensationTable1',
  TotalCashCompensationTable2: 'totalCashCompensationTable2',
  TotalSalaryReductions: 'totalSalaryReductions',
  UseAlternativeCoveredPeriod: 'useAlternativeCoveredPeriod',
  UtilityPayments: 'utilityPayments',
};

export const Alternative = 'alternative';
export const Boolean = 'boolean';
export const Eight = '8';
export const FormatYYYYMMDD = 'YYYY-MM-DD';
export const Standard = 'standard';
export const TwentyFour = '24';

export const sfFieldsByPage = {
  1: [
    FieldNames.CoveredPeriod24Week,
    FieldNames.PayrollSchedule,
    FieldNames.UseAlternativeCoveredPeriod,
    FieldNames.StandardCoveredPeriodStartDate,
    FieldNames.StandardCoveredPeriodEndDate,
    FieldNames.AlternativeCoveredPeriodStartDate,
    FieldNames.AlternativeCoveredPeriodEndDate,
  ],
  2: [
    FieldNames.AverageFteTable1,
    FieldNames.AverageFteTable2,
    FieldNames.TotalCashCompensationTable1,
    FieldNames.TotalCashCompensationTable2,
    FieldNames.MaintainedHourlyWage,
    FieldNames.TotalSalaryReductions,
  ],
  3: [
    FieldNames.EmployeeHealthInsurance,
    FieldNames.EmployeeRetirementPlans,
    FieldNames.StateAndLocalTaxes,
    FieldNames.NumberOfOwners,
    FieldNames.OwnerCompensationTotal,
  ],
  4: [
    FieldNames.NoReductionInEmployeeAverageHours,
    FieldNames.FteSafeHarbor1,
    FieldNames.FteSafeHarbor2,
    FieldNames.AverageFteDuringChosenPeriod,
    FieldNames.HasCoveredFteReductions,
  ],
  5: [
    FieldNames.EidlAmount,
    FieldNames.EidlLoanNumber,
    FieldNames.EmployeesAtApplicationTime,
    FieldNames.LoanGreaterThan2Million,
    FieldNames.MortgageInterestPayments,
    FieldNames.RentOrLeasePayments,
    FieldNames.UtilityPayments,
    FieldNames.CoveredOperationsExpenditures,
    FieldNames.CoveredPropertyDamageCosts,
    FieldNames.CoveredWorkerProtectionExpenditures,
    FieldNames.CoveredSupplierCosts,
  ],
  6: [],
};

export const conversionTypes = {
  stringToBoolean: [
    FieldNames.MaintainedHourlyWage,
    FieldNames.NoReductionInEmployeeAverageHours,
    FieldNames.HasCoveredFteReductions,
    FieldNames.FteSafeHarbor1,
    FieldNames.FteSafeHarbor2,
    FieldNames.LoanGreaterThan2Million,
  ],
  stringToNumber: [
    FieldNames.AverageFteDuringChosenPeriod,
  ],
};
