import { combineReducers } from 'redux';
import opportunities from './opportunity-reducers';
import accounts from './accounts-reducers';
import advances from './advance-reducers';
import { loanForgiveness } from './loan-forgiveness-reducers';
import stipulations from './stipulations-entity-reducers';

export default combineReducers({
  accounts,
  advances,
  loanForgiveness,
  opportunities,
  stipulations,
});
