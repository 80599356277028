import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyText, Col, Divider, Row,
} from '@swift-paypal/pp-react';
import './or-divider.less';

const OrDivider = (props) => {
  const { forceHorizontal } = props;
  const horizontalDivider = (
    <Row align="center">
      <Col xs={3}>
        <Divider />
      </Col>
      <Col xs={2}>
        <BodyText className="divider-text-mobile">Or</BodyText>
      </Col>
      <Col xs={3}>
        <Divider />
      </Col>
    </Row>
  );

  const verticalDivider = (
    <>
      <div className="divider-desktop" />
      <BodyText className="divider-text">Or</BodyText>
      <div className="divider-desktop" />
    </>
  );

  return (
    <div className="divider">
      { forceHorizontal
        // no wrapper added to ensure horizontal orientation
        ? (
          <>
            {horizontalDivider}
          </>
        )
        : (
          <>
            <div className="vertical">
              {verticalDivider}
            </div>
            <div className="horizontal">
              {horizontalDivider}
            </div>
          </>
        ) }
    </div>
  );
};

OrDivider.propTypes = {
  forceHorizontal: PropTypes.bool,
};

OrDivider.defaultProps = {
  forceHorizontal: false,
};

export default OrDivider;
