import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Button, Col, Container, Row,
} from '@swift-paypal/pp-react';
import constants from '../constants';
import PasswordField from '../lib/forms/password/password-field';
import { Alert } from './common/alert';
import Title from './common/title';

const { errors } = constants;
const formName = 'create-password';

const SetPasswordForm = (props) => {
  const {
    customerSupportPhone, handleSubmit, messageBody, messageType, hasToken, email, showEmail,
  } = props;

  const displayField = (messageBody !== errors.resetError(customerSupportPhone)
    && messageBody !== errors.createError(customerSupportPhone));

  return (
    <>
      <Title text="Create and confirm your password." />
      <div className="change-password-form form">
        <Container>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={10}>
                {showEmail && <div className="vx_text-body-md_medium">{email}</div>}
                {messageBody && <Alert message={messageBody} alertType={messageType} />}
                {hasToken && displayField
                  && <PasswordField name="password" label="Password" formName={formName} username={email} newPassword />}
                {hasToken && displayField
                  && (
                  <PasswordField
                    name="confirmPassword"
                    label="Confirm password"
                    formName={formName}
                    confirmationField="password"
                  />
                  )}
              </Col>
            </Row>
            {hasToken && displayField && (
              <Row>
                <Col xs={8} sm={5} md={4}>
                  <Button type="submit" className="pp-react__button--block">
                    Continue
                  </Button>
                </Col>
              </Row>
            )}
          </form>
        </Container>
      </div>
    </>
  );
};

SetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  messageBody: PropTypes.string,
  messageType: PropTypes.string,
  hasToken: PropTypes.bool.isRequired,
  email: PropTypes.string,
  showEmail: PropTypes.bool,
  customerSupportPhone: PropTypes.string,
};

SetPasswordForm.defaultProps = {
  customerSupportPhone: PropTypes.string,
  email: undefined,
  handleSubmit: undefined,
  messageBody: undefined,
  messageType: undefined,
  showEmail: false,
};

function mapStateToProps(state) {
  return {
    customerSupportPhone: state.config.customerSupportPhone,
  };
}

const SetPasswordFormComponent = connect(
  mapStateToProps,
)(reduxForm({
  form: 'create-password',
})(SetPasswordForm));

export default SetPasswordFormComponent;
