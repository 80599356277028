import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '@swift-paypal/pp-react';
import ApplicationStipulationItems from './application-stipulation-items';
import AccountStipulationItems from './account-stipulation-items';

const StipulationItemsList = ({ stipulations, clearStipFunc, renewFunc }) => (
  <Row align="center">
    <Col>
      {stipulations?.map((stipulation) => (
        <React.Fragment key={stipulation.accountId}>
          <ApplicationStipulationItems
            key={stipulation.applications}
            applications={stipulation.applications}
            clearStipFunc={clearStipFunc}
          />
          <AccountStipulationItems
            key={stipulation.stipulations}
            stipulations={stipulation.stipulations}
            renewFunc={renewFunc}
            clearStipFunc={clearStipFunc}
          />
          <hr className="vx_hr" />
        </React.Fragment>
      ))}
    </Col>
  </Row>
);

StipulationItemsList.propTypes = {
  clearStipFunc: PropTypes.func,
  renewFunc: PropTypes.func,
  stipulations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

StipulationItemsList.defaultProps = {
  clearStipFunc: undefined,
  renewFunc: undefined,
};

export default StipulationItemsList;
