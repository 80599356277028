import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field, FieldArray, reduxForm, formValueSelector, arrayPush, arraySplice, destroy,
} from 'redux-form';
import {
  Button,
  Col,
  Row,
  Tooltip,
} from '@swift-paypal/pp-react';
import constants from '../../constants';
import ownershipService from '../../services/ownership.service';
import { Alert, AlertTypes } from '../common/alert';
import renderCheckField from '../../lib/forms/render-check-field';
import OwnershipFormComponent from './ownership-form';
import OwnershipTile from './ownership-tile';
import './ownership.less';

const ownershipErrors = constants.errors.ownership;
const additionalSignerAlert = 'error-additional-signer';
const additionalSignerForm = 'additional-signer-form';
const individualSignerForm = 'individual-signer-form';

export class AdditionalSignersComponent extends React.Component {
  static propTypes = {
    additionalSignerError: PropTypes.string,
    customerSupportPhone: PropTypes.string,
    destroyForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.string,
      isBeneficialOwner: PropTypes.bool,
      isControllingManager: PropTypes.bool,
      isSigner: PropTypes.bool,
    }),
    owners: PropTypes.arrayOf(PropTypes.object),
    ownersFieldArray: PropTypes.arrayOf(PropTypes.object),
    ownershipError: PropTypes.bool,
    push: PropTypes.func.isRequired,
    saveAdditionalSigners: PropTypes.func.isRequired,
    splice: PropTypes.func.isRequired,
    isSbaLoan: PropTypes.bool,
  };

  static defaultProps = {
    additionalSignerError: undefined,
    customerSupportPhone: undefined,
    initialValues: undefined,
    owners: undefined,
    ownersFieldArray: undefined,
    ownershipError: undefined,
    isSbaLoan: false,
  };

  constructor(props) {
    super(props);
    const { owners } = this.props;
    const applicant = _.head(owners.filter((x) => x.isApplicant));
    this.state = {
      applicant,
    };
  }

  renderOwners = ({ fields }) => (
    <>
      {fields.map((owner, index) => (
        <Row key={owner.id}>
          <Col xs={1} className="signer-margin-top">
            <Field
              id={`${owner}.isSigner`}
              name={`${owner}.isSigner`}
              component={renderCheckField}
              label=""
              type="checkbox"
              className="vx_form-control form-control"
            />
          </Col>
          <Col xs={11}>
            <div data-loan-signer-tile>
              <OwnershipTile
                owner={fields.get(index)}
                showEmail
                editOwner={this.editAdditionalSigners}
                removeOwner={this.removeAdditionalSigners}
              />
            </div>
          </Col>
        </Row>
      ))}
    </>
  );

  addAdditionalSigners = () => {
    this.setState({
      showAdditionalSigner: true,
      errorAdditionalSigners: null,
      additionalSigner: {
        initialValues: {
          isControllingManager: false,
          isBeneficialOwner: false,
          isSigner: true,
        },
      },
      additionalSignerIndex: -1,
    });
  };

  closeAdditionalSigners = () => {
    this.setState({
      showAdditionalSigner: false,
      errorAdditionalSigners: null,
      additionalSigner: {
        initialValues: null,
      },
      additionalSignerIndex: null,
      editExisting: false,
    });
  };

  editAdditionalSigners = (initialValues) => {
    const { destroyForm, ownersFieldArray } = this.props;
    const { additionalSignerIndex } = this.state;
    const signerIndex = ownersFieldArray.findIndex((owner) => owner === initialValues);
    if (additionalSignerIndex !== signerIndex) {
      destroyForm(individualSignerForm);
    }
    this.setState({
      showAdditionalSigner: true,
      errorAdditionalSigners: null,
      additionalSigner: {
        initialValues,
      },
      additionalSignerIndex: signerIndex,
      editExisting: true,
    });
  };

  reOpenAdditionalSigners = () => {
    this.closeAdditionalSigners();
    this.addAdditionalSigners();
  };

  removeAdditionalSigners = (signer) => {
    const {
      ownersFieldArray,
      splice,
    } = this.props;

    const { additionalSigner } = this.state;

    // make sure signer is not currently being edited elsewhere
    if (additionalSigner && signer === additionalSigner.initialValues) {
      this.setState({
        errorAdditionalSigners: ownershipErrors.deleteAdditionalSigners,
      });
    }
    else {
      const signerIndex = ownersFieldArray.indexOf(signer);
      if (signerIndex !== -1) {
        splice(signerIndex, 1);
        this.setState({
          errorAdditionalSigners: null,
        });
      }
    }
  };

  addOrUpdateAdditionalSigners = (signer) => {
    const {
      customerSupportPhone,
      owners,
      ownersFieldArray,
      push,
      splice,
    } = this.props;

    const { additionalSignerIndex } = this.state;

    if (ownershipService.isApplicant(owners, signer)) {
      this.setState({
        error: ownershipErrors.addApplicantAsOwner(customerSupportPhone),
      });
    }
    else {
      const signerIndex = additionalSignerIndex;
      signer.isSigner = true;
      // when a signer has 25% ownership, add to business owner
      signer.isBeneficialOwner = signer.percentOwned >= 25;

      if (signerIndex === null || signerIndex === undefined
        || signerIndex === -1 || signerIndex >= ownersFieldArray.length) {
        push(signer);
      }
      else {
        splice(signerIndex, 1, signer);
      }

      this.setState({
        additionalSigner: null,
        error: null,
      });
    }
  };

  validateForm = () => {
    const {
      ownersFieldArray,
      saveAdditionalSigners,
    } = this.props;

    const { applicant } = this.state;

    let hasErrors = false;

    const owners = ownersFieldArray.filter((owner) => owner.isSigner);
    if (owners.length > 3) {
      hasErrors = true;
      this.setState({ tooManySigners: true });
      this.scrollToTop();
    }

    if (!hasErrors) {
      saveAdditionalSigners({ contacts: [applicant, ...ownersFieldArray] });
    }
  };

  scrollToTop = () => {
    if (process.browser && document && window) {
      document.getElementById('top').scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      additionalSignerError,
      customerSupportPhone,
      handleSubmit,
      ownersFieldArray,
      ownershipError,
      isSbaLoan,
    } = this.props;

    const {
      additionalSigner,
      applicant,
      editExisting,
      error,
      errorAdditionalSigners,
      showAdditionalSigner,
      tooManySigners,
    } = this.state;

    let totalPercentOwned = 0;
    if (ownersFieldArray && ownersFieldArray.length) {
      totalPercentOwned = _.reduce(
        [applicant, ...ownersFieldArray],
        (sum, cur) => (cur.isSigner ? sum + (cur && cur.percentOwned ? +cur.percentOwned : 0) : sum),
        0,
      );
    }

    return (
      <>
        <div id="top">
          {tooManySigners
          && (
          <div>
            <Alert id="too-many-signers" message={ownershipErrors.tooManySigners(customerSupportPhone)} alertType={AlertTypes.critical} />
          </div>
          )}
          {ownershipError
          && (
          <div className="margin-top-sm">
            <Alert message={constants.errors.technicalIssue} alertType={AlertTypes.critical} />
          </div>
          )}
          <p className="vx_text-body-md_medium">
            Loan signers
            {' '}
            <Tooltip description={constants.tooltips.additionalSignerTip} className="pp-react__tooltip--inline" />
          </p>
          <p>
            We require that at least 50% of business ownership agree
            to the loan terms by signing the agreement. Please select who you would
            like to add as cosigner below, or add another owner.
          </p>
        </div>
        <form id={additionalSignerForm}>
          {additionalSignerError
          && (
          <div className="margin-top-sm">
            <Alert message={additionalSignerError} alertType={AlertTypes.critical} />
          </div>
          )}
          <div className="margin-top-lg">
            <div data-additional-signer-tile>
              <OwnershipTile
                owner={applicant}
                editOwner={this.editAdditionalSigners}
                removeOwner={this.removeAdditionalSigners}
              />
            </div>
            <p className="vx_text-body-md_medium">
              Please identify co-signer(s) for this business.
            </p>
            {totalPercentOwned < 50
            && (
            <div className="margin-top-sm">
              <Alert message="By clicking submit, we may need to collect signer information before issuing a contract." alertType={AlertTypes.critical} />
            </div>
            )}
            <Row>
              <Col xs={12} id={additionalSignerAlert}>
                {errorAdditionalSigners
                && (
                <Alert
                  message={errorAdditionalSigners}
                  alertType={AlertTypes.critical}
                />
                )}
              </Col>
            </Row>
            {/* https://redux-form.com/8.1.0/examples/fieldarrays/ */}
            <FieldArray name="owners" component={this.renderOwners} />
            {!showAdditionalSigner
            && (
            <p>
              <button
                className="button-link vx_anchor vx_embeddedIcon vx_embeddedIcon-simple clickable"
                data-add-loan-signer
                type="button"
                tabIndex="0"
                onClick={this.addAdditionalSigners}
                onKeyDown={this.addAdditionalSigners}
              >
                <span className="vx_icon vx_icon-large vx_icon-add-small" />
                Add co-signer
              </button>
            </p>
            )}
            {showAdditionalSigner
            && (
            <div data-loan-signer-form>
              <OwnershipFormComponent
                owners={[...ownersFieldArray, applicant]}
                key="additionalSigner"
                businessOwner={additionalSigner.initialValues.isBeneficialOwner}
                ownerCanBecomeNonowner
                additionalSigner={additionalSigner.initialValues.isSigner}
                initialValues={additionalSigner.initialValues}
                form={individualSignerForm}
                closeForm={this.closeAdditionalSigners}
                editEmailOnly={!!additionalSigner.initialValues.id}
                openForm={this.addAdditionalSigners}
                continueFunc={this.addOrUpdateAdditionalSigners}
                reopenForm={this.reOpenAdditionalSigners}
                editExisting={editExisting}
                submitError={error}
                isSbaLoan={isSbaLoan}
              />
            </div>
            )}
            <hr className="vx_hr" />
          </div>
          <Row>
            <Col xs={8} sm={8} className="margin-top-sm">
              <Button
                type="submit"
                className="pp-react__button--block"
                data-submit-form
                form={additionalSignerForm}
                onClick={handleSubmit(this.validateForm)}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  splice: (index, num, val) => {
    dispatch(arraySplice(additionalSignerForm, 'owners', index, num, val));
  },
  push: (val) => {
    dispatch(arrayPush(additionalSignerForm, 'owners', val));
  },
  destroyForm: (formName) => dispatch(destroy(formName)),
});

const AdditionalSignersFormComponentNoSelectors = reduxForm({
  form: additionalSignerForm,
})(AdditionalSignersComponent);

const selector = formValueSelector(additionalSignerForm);
const AdditionalSignersFormComponent = connect((state) => {
  const ownersFieldArray = selector(state, 'owners');
  return {
    ownersFieldArray,
    customerSupportPhone: state.config.customerSupportPhone,
  };
},
mapDispatchToProps)(AdditionalSignersFormComponentNoSelectors);

export default AdditionalSignersFormComponent;
