import React from 'react';
import moment from 'moment';
import { required } from 'redux-form-validators';
import { Field } from 'redux-form';
import { Col, Row } from '@swift-paypal/pp-react';
import selectField from '../../lib/forms/select-field';

const PaymentDayForm = () => (
  <Row>
    <Col xs={12} sm={10}>
      <Field
        name="paymentDay"
        id="paymentDay"
        label="Payment day"
        component={selectField}
        className="vx_form-control form-control"
        validate={[required({ msg: 'Required' })]}
      >
        {moment.weekdays().slice(1, 6).map((weekday) => (<option key={weekday} value={weekday}>{weekday}</option>))}
      </Field>
    </Col>
  </Row>
);

export default PaymentDayForm;
