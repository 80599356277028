import React from 'react';
import { useShallowSelector } from '../../../hooks';
import { StipulationList } from '../stipulations';
import { getForgivenessDetailsKey, getForgivenessStipulations } from '../stipulations/stipulations.utils';
import { ForgivenessDetails } from './forgivenessSummaryDetails';
import { forgivenessSummarySelector } from './forgivenessSummary.selectors';

export const ForgivenessSummary = () => {
  const {
    activeOpportunityId,
    applicationVersionSelected,
    loanVersion,
    stage,
    stipulations,
  } = useShallowSelector(forgivenessSummarySelector);

  if (!activeOpportunityId) {
    return null;
  }

  const activeStipulations = getForgivenessStipulations(
    activeOpportunityId,
    stipulations,
    applicationVersionSelected,
    loanVersion,
  );

  const forgivenessDetailsKey = getForgivenessDetailsKey(stage, applicationVersionSelected, activeStipulations);
  const ForgivenessDetailsComponent = ForgivenessDetails.get(forgivenessDetailsKey);

  return (
    <>
      {forgivenessDetailsKey && <ForgivenessDetailsComponent />}
      {activeStipulations?.length > 0 && <StipulationList activeStipulations={activeStipulations} />}
    </>
  );
};
