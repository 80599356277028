import { get } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import withUrlParams from '../../hocs/with-url-params';

const NotificationIcon = (props) => {
  // in the state, country doesn't get populated until the country hoc runs
  // and is initially undefined. once it's populated this re-renders

  const {
    country,
    stipulations,
    isRenewalEligible,
    autoDraftItems,
    homePhoneAndBusinessWebAddress,
  } = props;
  if (!country) {
    return null;
  }

  const actualStipulations = get({ stipulations }, 'stipulations[0].stipulations[0].stipulations');
  const renewalStipulations = !actualStipulations || isRenewalEligible
    ? actualStipulations
    : actualStipulations.filter((stip) => !stip.isRenewal);

  let actualCount = renewalStipulations ? renewalStipulations.length : 0;
  if (autoDraftItems.length) actualCount += autoDraftItems.length;
  if (
    homePhoneAndBusinessWebAddress
    && (!homePhoneAndBusinessWebAddress.homePhone
      || !homePhoneAndBusinessWebAddress.businessWebAddress)
  ) {
    actualCount += 1;
  }

  return (
    <Link
      to={`/${country}/action-items`}
      className="vx_globalNav-link_notifications vx_isCritical focus-highlight"
      data-automation-id="notificationsPageLink"
    >
      <span className="vx_globalNav-iconWrapper_secondary">
        <span className="vx_globalNav-navIcon vx_globalNav-navIcon_linkNotifications" />
        {actualCount > 0 && <span className="vx_notificationCount">{actualCount}</span>}
      </span>
      <span className="vx_a11yText">Notifications</span>
    </Link>
  );
};

NotificationIcon.propTypes = {
  country: PropTypes.string,
  isRenewalEligible: PropTypes.bool,
  stipulations: PropTypes.arrayOf(PropTypes.object),
  autoDraftItems: PropTypes.array,
  homePhoneAndBusinessWebAddress: PropTypes.shape({
    homePhone: PropTypes.string,
    businessWebAddress: PropTypes.string,
  }),
};

NotificationIcon.defaultProps = {
  country: undefined,
  isRenewalEligible: false,
  stipulations: undefined,
  autoDraftItems: [],
  homePhoneAndBusinessWebAddress: undefined,
};

const mapStateToProps = (state) => ({
  stipulations: state.notifications.stipulations,
  count: state.notifications.count || 0,
  autoDraftItems: state.notifications.autoDraftItems,
  isRenewalEligible: state.isRenewalEligible,
  homePhoneAndBusinessWebAddress: state.settings.homePhoneAndBusinessWebAddress,
});

export default compose(connect(mapStateToProps), withRouter, withUrlParams)(NotificationIcon);

export const NotificationIconComponent = NotificationIcon;
