import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../constants';
import FormMessage from '../../components/common/form-message';
import './floating-label-field.js.less';
import RoutingNumberWarning from '../../components/routing-number-warning/routing-number-warning-banner/routing-number-warning-banner';

const renderRoutingNumberField = ({
  input,
  label,
  manualSet = false,
  type,
  className,
  noErrorClass,
  institution,
  disabled = false,
  meta: { touched, error },
  hasRouteNumberWarning,
}) => (
  <div
    className={`${manualSet ? 'vx_floatingLabel_active' : 'vx_floatingLabel'}
        ${disabled ? 'vx_is-disabled' : ''}
        ${touched && (error || institution === constants.bank.INVALID_ROUTING_NUMBER) ? 'vx_has-error-with-message' : ''}
        ${input.value ? 'hasValue' : ''}`}
    data-label-content={label}
  >
    <label htmlFor={input.name}>
      {label}
    </label>
    <input
      {...input}
      id={input.name}
      disabled={disabled}
      type={type}
      className={`${className} ${(!error || !touched) && noErrorClass ? noErrorClass : ''}
        ${touched && institution ? 'unround-corners' : ''}`}
    />

    {touched && !disabled
      && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
        || (institution === constants.bank.INVALID_ROUTING_NUMBER
          && <span className="vx_form-control-message margin-bottom-sm">{constants.errors.routingNumberError}</span>)
        || ((institution && institution !== constants.bank.ROUTING_NUMBER_PENDING)
          && <FormMessage message={institution.name} iconClass="vx_icon-positive-large" />))}
    {hasRouteNumberWarning && input.value !== '' && institution !== constants.bank.ROUTING_NUMBER_PENDING ? <RoutingNumberWarning /> : null}
  </div>

);

renderRoutingNumberField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  institution: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  label: PropTypes.string.isRequired,
  manualSet: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  noErrorClass: PropTypes.string,
  type: PropTypes.string.isRequired,
  hasRouteNumberWarning: PropTypes.bool,
};

renderRoutingNumberField.defaultProps = {
  className: undefined,
  disabled: false,
  input: undefined,
  institution: undefined,
  manualSet: false,
  meta: undefined,
  noErrorClass: undefined,
  hasRouteNumberWarning: false,
};

export default renderRoutingNumberField;
