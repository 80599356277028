import decode from 'jwt-decode';

export const extractUserFromDecodedToken = () => {
  const token = getToken();

  return token ? {
    firstName: token.first_name,
    lastName: token.last_name,
    email: token.email,
    payPalSso: !!token.pp_access_token,
  } : undefined;
};

// only checks for 'authorized' type
export const isUserAuthorized = () => {
  const decodedToken = getToken();

  // token must not have a security question id, and has to be of type authorized
  return (decodedToken
    && !(decodedToken.security_question_id
    && typeof (decodedToken.security_question_id) === 'number')
    && decodedToken.auth_type === 'authorized');
};

// only checks for 'authenticated' type
export const isUserAuthenticated = () => {
  const decodedToken = getToken();

  // token must have a security question id, and has to be of type authenticated
  return (decodedToken
    && decodedToken.security_question_id
    && typeof (decodedToken.security_question_id) === 'number'
    && decodedToken.auth_type === 'authenticated');
};

export const getToken = () => {
  const token = window.localStorage.getItem('token');
  try {
    return token ? decode(token) : undefined;
  }
  catch (error) {
    return undefined;
  }
};
