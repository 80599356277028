import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearFields } from 'redux-form';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Container } from '@swift-paypal/pp-react';
import PageTitle from '../../components/common/page-title';
import SecurePage from '../../hocs/secure-page';
import ChangeEmailFormComponent from '../../components/settings/email/change-email-form';
import util from '../../util';
import Title from '../../components/common/title';
import { changeEmailAction, clearSettingsMessageAction } from '../../redux/actions/settings/settings-actions';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';

class ChangeEmailPage extends Component {
  static displayName = displayNames.ChangeEmailPage;

  static propTypes = {
    apiUrl: PropTypes.string,
    changeEmail: PropTypes.func.isRequired,
    clearSettingsMessage: PropTypes.func.isRequired,
    clearPassword: PropTypes.func.isRequired,
    country: PropTypes.string,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
  };

  static defaultProps = {
    apiUrl: undefined,
    country: undefined,
    messageBody: undefined,
    messageType: undefined,
  };

  componentWillUnmount() {
    const { clearSettingsMessage } = this.props;
    clearSettingsMessage();
  }

  submit = (values) => {
    const { apiUrl, clearPassword, changeEmail } = this.props;
    const { newEmail, password } = values;
    util.blurOnSubmit();

    clearPassword();
    changeEmail(apiUrl, newEmail, password);
  };

  render() {
    const
      {
        messageBody,
        messageType,
        country,
      } = this.props;

    return (
      <div className="change-email">
        <Helmet>
          <title>Change LoanBuilder Email</title>
        </Helmet>
        <StandardLayout
          contentElement={(
            <Container fluid>
              <PageTitle text="Change LoanBuilder Email" />
              <Title text="Use the form below to change your email address." />
              <ChangeEmailFormComponent
                messageBody={messageBody}
                messageType={messageType}
                onSubmit={this.submit}
                country={country}
              />
            </Container>
          )}
          sidebarElement={<></>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiUrl: state.config.apiUrl,
    bfes: state.accountItems.bfes,
    messageBody: state.settings.messageBody,
    messageType: state.settings.messageType,
    user: state.authorize.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeEmail: (password, newPassword, confirmPassword) => {
      dispatch(changeEmailAction(password, newPassword, confirmPassword));
    },
    clearSettingsMessage: () => {
      dispatch(clearSettingsMessageAction());
    },
    clearPassword: () => {
      dispatch(clearFields('change-email', false, false, 'password'));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurePage(ChangeEmailPage));
