import * as actions from '../../actions/application/ownership/ownership-action-types';
import { AlertTypes } from '../../../components/common/alert';

function ownership(state = {}, action) {
  switch (action.type) {
    case actions.ACCEPTANCE_REQUEST_RECEIVED:
      return { ...state, acceptance: action.payload, error: null };
    case actions.ATTESTATION_REQUEST_RECEIVED:
      return { ...state, attestation: action.payload, error: null };
    case actions.OWNERS_REQUEST_RECEIVED:
      return {
        ...state,
        selectedOpportunityOwners: action.payload,
        canStartAdditionalSigners: true,
        error: null,
        businessRepresentative: null,
      };
    case actions.OWNERS_REQUEST_CLEAR:
      return { ...state, selectedOpportunityOwners: null, error: null };
    case actions.OWNERS_CONTACT_REQUEST_RECEIVED:
      return {
        ...state, ownerContact: action.payload, error: null, businessRepresentative: null,
      };
    case actions.SAVE_ACCEPTANCE_REQUEST_RECEIVED:
      return { ...state, error: null, saveAcceptance: action.payload };
    case actions.SAVE_ATTESTATION_REQUEST_RECEIVED:
      return { ...state, error: null, saveAttestation: action.payload };
    case actions.SAVE_OWNERSHIP_REQUEST_RECEIVED:
      return {
        ...state, error: null, saveOwnership: action.payload, ownershipReceived: true,
      };
    case actions.TERMS_AND_CONDITIONS_REQUEST_RECEIVED:
      return { ...state, error: null, termsAndConditions: action.payload };
    case actions.SET_CONTROLLING_MANAGER:
      return { ...state, error: null, controllingManager: action.controllingManager };
    case actions.OWNERSHIP_SAVED_SUCCESSFULLY:
      return {
        ...state,
        error: null,
        ownershipFulfilled: true,
        businessRepresentative: {
          messageBody: 'You have provided us with your Business Representatives.',
          messageType: AlertTypes.success,
        },
        canStartAdditionalSigners: false,
      };
    case actions.SAVE_ADDITIONAL_SIGNERS_RECEIVED:
      return {
        ...state,
        error: null,
        additionalSignersFulfilled: true,
        businessRepresentative: {
          messageBody: 'You have provided us with your Business Representatives.',
          messageType: AlertTypes.success,
        },
      };
    case actions.CLEAR_ADDITIONAL_SIGNERS_FULFILLED:
      return { ...state, additionalSignersFulfilled: undefined, canStartAdditionalSigners: false };
    case actions.GET_POSSIBLE_SIGNERS_RECEIVED:
      return {
        ...state,
        canStartAdditionalSigners: true,
        selectedOpportunityOwners: action.payload,
        error: null,
      };
    case actions.ACCEPTANCE_REQUEST_ERROR:
    case actions.ATTESTATION_REQUEST_ERROR:
    case actions.OWNERS_CONTACT_REQUEST_ERROR:
    case actions.OWNERS_REQUEST_ERROR:
    case actions.SAVE_ACCEPTANCE_REQUEST_ERROR:
    case actions.SAVE_ATTESTATION_REQUEST_ERROR:
    case actions.SAVE_OWNERSHIP_REQUEST_ERROR:
    case actions.TERMS_AND_CONDITIONS_REQUEST_ERROR:
    case actions.SAVE_ADDITIONAL_SIGNERS_REQUEST_ERROR:
    case actions.GET_POSSIBLE_SIGNERS_REQUEST_ERROR:
      return { ...state, error: true, businessRepresentative: null };
    case actions.OWNERS_REQUEST_PENDING:
    case actions.OWNERS_CONTACT_REQUEST_PENDING:
    case actions.ACCEPTANCE_REQUEST_PENDING:
    case actions.ATTESTATION_REQUEST_PENDING:
    case actions.SAVE_ACCEPTANCE_REQUEST_PENDING:
    case actions.SAVE_ATTESTATION_REQUEST_PENDING:
    case actions.SAVE_OWNERSHIP_REQUEST_PENDING:
    case actions.TERMS_AND_CONDITIONS_REQUEST_PENDING:
    case actions.SAVE_ADDITIONAL_SIGNERS_REQUEST_PENDING:
    case actions.GET_POSSIBLE_SIGNERS_REQUEST_PENDING:
    default:
      return state;
  }
}

export default ownership;
