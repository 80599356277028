import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import ReactGA from 'react-ga';
import getDisplayName from '../lib/get-display-name';

const googleAnalytics = (Page) => {
  class GoogleAnalytics extends Component {
    componentDidMount() {
      const { location } = this.props;
      ReactGA.pageview(location.pathname);
    }

    render() {
      // Pass on whatever props exist and state
      return <Page {...this.props} {...this.state} />;
    }
  }

  GoogleAnalytics.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
  };

  function mapStateToProps(state) {
    return {
      location: state.router.location,
    };
  }

  GoogleAnalytics.displayName = `GoogleAnalytics(${getDisplayName(Page)})`;

  return connect(mapStateToProps)(GoogleAnalytics);
};

export default googleAnalytics;
