import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyText, Col, Row,
} from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import { ClassNames } from '../stipulations.constants';
import { stipulationSelector } from '../stipulations.selectors';
import { getClassName, getStipulationDisplayName } from '../stipulations.utils';
import { StipulationAction } from '../stipulationAction';
import './stipulationItem.less';

export const StipulationItem = ({ stipulationId }) => {
  const {
    applicationVersionSelected,
    documentName,
    fulfilled,
    stipulationName,
  } = useShallowSelector(stipulationSelector, stipulationId);

  const fulfilledModifier = fulfilled ? ClassNames.Fulfilled : '';

  return (
    <Row className={getClassName(ClassNames.Row, fulfilledModifier)}>
      <Col sm={8} xs={12}>
        <BodyText className={getClassName(ClassNames.StipulationName, fulfilledModifier)}>
          {getStipulationDisplayName(documentName, stipulationName, applicationVersionSelected)}
        </BodyText>
      </Col>
      <Col className={getClassName(ClassNames.ButtonColumn, fulfilledModifier)} sm={4} xs={12}>
        <StipulationAction stipulationId={stipulationId} />
      </Col>
    </Row>
  );
};

StipulationItem.propTypes = {
  stipulationId: PropTypes.string.isRequired,
};
