import migratedFlowConstant from './constants/migratedFlowConstant';

/**
 * Don't alphabetize these. React router evaluates these from top to bottom, so it's important to keep
 * the simpler URLs at the top of this file so that they don't get misinterpreted.
 */
const routes = {
  'oauth-landing': { path: '/oauth/landing', componentName: 'OAuthLandingPage' },
  'oauth-landing-migrated-flow': { path: `/${migratedFlowConstant.URL_APPEND}/oauth/landing`, componentName: 'OAuthLandingPageMigratedFlow' },
  'oauth-paypal': { path: '/oauth/paypal', componentName: 'OAuthPayPalPage' },
  'oauth-paypal-migrated-flow': { path: `/${migratedFlowConstant.URL_APPEND}/oauth/paypal`, componentName: 'OAuthPayPalPageMigratedFlow' },
  'action-items': { path: '/:country/action-items', componentName: 'ActionItemsPage' },
  activity: { path: '/:country/activity/:loanId', componentName: 'ActivityPage' },
  login: { path: '/:country/login', componentName: 'LoginPage' },
  'application-action': { path: '/:country/action-items/:opportunityId', componentName: 'ActionItemsPage' },
  'application-ownership': { path: '/:country/application/:opportunityId/ownership', componentName: 'OwnershipPage' },
  'upload-document-group': { path: '/:country/upload-document-group', componentName: 'UploadDocumentGroupPage' },
  'application-verify-bank-accounts': {
    path: '/:country/application/:opportunityId/verify-bank-accounts',
    componentName: 'VerifyBankAccountsPage',
  },
  'add-home-phone': { path: '/:country/settings/add-home-phone', componentName: 'AddHomePhonePage' },
  'add-business-web-address': { path: '/:country/settings/add-business-web-address', componentName: 'AddBusinessWebAddressPage' },
  'change-email': { path: '/:country/settings/change-email', componentName: 'ChangeEmailPage' },
  'change-password': { path: '/:country/settings/change-password', componentName: 'ChangePasswordPage' },
  'change-security-questions': { path: '/:country/settings/change-security-questions', componentName: 'ChangeSecurityQuestionsPage' },
  'confirm-paypal-account': { path: '/:country/confirm-paypal-account', componentName: 'ConfirmPaypalAccountPage' },
  'confirm-paypal-account-migrated-flow': { path: `/:country/${migratedFlowConstant.URL_APPEND}/confirm-paypal-account`, componentName: 'ConfirmPaypalAccountMigratedFlowPage' },
  'contact-us': { path: '/:country/contact-us', componentName: 'ContactUsPage' },
  'create-account': { path: '/:country/create-account', componentName: 'CreateAccountPage' },
  'forgiveness-application': { path: '/:country/forgiveness-application', componentName: 'ForgivenessApplicationPage' },
  'forgiveness-customer-signoff': { path: '/:country/forgiveness-customer-signoff', componentName: 'ForgivenessCustomerSignoffPage' },
  'forgiveness-customer-signoff-completed': { path: '/:country/forgiveness-customer-signoff-completed', componentName: 'ForgivenessSignoffCompletedPage' },
  'forgiveness-detail': { path: '/:country/forgiveness-detail', componentName: 'ForgivenessDetailPage' },
  'forgot-password': { path: '/:country/forgot-password', componentName: 'ForgotPasswordPage' },
  maintenance: { path: '/:country/maintenance', componentName: 'MaintenancePage' },
  references: { path: '/:country/references', componentName: 'ReferencesPage' },
  questionnaire: { path: '/:country/questionnaire', componentName: 'QuestionnairePage' },
  'privacy-policy': { path: '/:country/privacy-policy', componentName: 'PrivacyPolicyPage' },
  'reset-account': { path: '/:country/reset-account', componentName: 'ResetAccountPage' },
  'reset-password': { path: '/:country/reset-password', componentName: 'ResetPasswordPage' },
  'security-question': { path: '/:country/security-question', componentName: 'SecurityQuestionPage' },
  'security-questions': { path: '/:country/security-questions', componentName: 'SecurityQuestionsPage' },
  settings: { path: '/:country/settings', componentName: 'SettingsPage' },
  summary: { path: '/:country/summary', componentName: 'SummaryPage' },
  'one-time-payment': { path: '/:country/one-time-payment', componentName: 'OneTimePaymentPage' },
  'terms-of-use': { path: '/:country/terms-of-use', componentName: 'TermsOfUsePage' },
  'terms-and-conditions': { path: '/:country/terms-and-conditions', componentName: 'TermsAndConditionsPage' },
  upload: { path: '/:country/upload-document/:opportunityId/:stipulationName', componentName: 'UploadDocumentGroupPage' },
  'upload-document': { path: '/:country/upload-document', componentName: 'UploadDocumentGroupPage' },
  'verify-email': { path: '/:country/verify-email', componentName: 'VerifyEmailPage' },
  'application-add-bank-accounts': { path: '/:country/application/:opportunityId/add-bank-accounts', componentName: 'AddBankAccountsPage' },
  'additional-identification': { path: '/:country/:opportunityId/additional-identification', componentName: 'AdditionalIdentificationPage' },
  'calculate-payroll-data': { path: '/:country/:opportunityId/calculate-payroll-data', componentName: 'CalculatePayrollDataPage' },
  'reduction-in-gross-receipts': { path: '/:country/:opportunityId/reduction-in-gross-receipts', componentName: 'ReductionInGrossReceiptsPage' },
  identification: { path: '/:country/:opportunityId/identification', componentName: 'IdentificationPage' },
  'proof-of-business': { path: '/:country/:opportunityId/proof-of-business', componentName: 'ProofOfBusinessPage' },
  'business-ownership': { path: '/:country/:opportunityId/business-ownership', componentName: 'BusinessOwnershipPage' },
  'upload-canadian-bank-statements': { path: '/:country/:opportunityId/upload-bank-statements', componentName: 'UploadCanadianBankStatementsPage' },
  'upload-addendum-a': { path: '/:country/:opportunityId/upload-addendum-a', componentName: 'UploadAddendumAPage' },
  'upload-addendum-b': { path: '/:country/:opportunityId/upload-addendum-b', componentName: 'UploadAddendumBPage' },
  'payroll-verification': { path: '/:country/:opportunityId/payroll-verification', componentName: 'PayrollVerificationPage' },
  'business-license': { path: '/:country/:opportunityId/business-license', componentName: 'BusinessLicensePage' },
  'drivers-license': { path: '/:country/:opportunityId/drivers-license', componentName: 'DriversLicensePage' },
  'demographic-information': { path: '/:country/:opportunityId/demographic-information', componentName: 'DemographicInformationPage' },
  'reduction-in-gross-receipts-documents': { path: '/:country/:opportunityId/reduction-in-gross-receipts-documents', componentName: 'ReductionInGrossReceiptsDocumentsPage' },
  'upload-loan-forgiveness': { path: '/:country/forgiveness/:opportunityId/:groupName', componentName: 'UploadLoanForgivenessPage' },
  'sba-3508s': { path: '/:country/lf/sba-3508s/:opportunityId/:stipulationId', componentName: 'Sba3508SFormPage' },
  'forgiveness-summary': { path: '/:country/forgiveness-summary', componentName: 'ForgivenessSummaryPage' },
  'covered-costs-documents': { path: '/:country/forgiveness/covered-costs-documents', componentName: 'CoveredCostsDocumentsPage' },
  'forgiveness-reduction-in-gross-receipts': { path: '/:country/forgiveness-reduction-in-gross-receipts', componentName: 'ForgivenessGrossReceiptsPage' },
  'forgiveness-reduction-in-gross-receipts-documents': { path: '/:country/forgiveness-reduction-in-gross-receipts-documents', componentName: 'ForgivenessGrossReceiptsDocumentsPage' },
  'tax-return': { path: '/:country/:opportunityId/tax-return', componentName: 'TaxReturnPage' },
  'proof-of-identity': { path: '/:country/:opportunityId/proof-of-identity', componentName: 'ProofOfIdentityPage' },
  'voided-check': { path: '/:country/:opportunityId/voided-check', componentName: 'VoidedCheckPage' },
};

export default routes;
