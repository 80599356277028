import accountService from '../../../services/account.service';
import { asyncAuthorizedActionCreator } from '../util/async-action-creator';
import { redirect } from '../../../services/window.service';
import * as actions from './account-action-types';

export const getIsRenewalEligibleAction = asyncAuthorizedActionCreator(
  {
    pending: actions.IS_RENEWAL_ELIGIBLE_REQUEST_PENDING,
    complete: actions.IS_RENEWAL_ELIGIBLE_REQUEST_RECEIVED,
    error: actions.IS_RENEWAL_ELIGIBLE_REQUEST_ERROR,
  },
  (args) => (dispatch, getState) => accountService.getIsRenewalEligible(getState().config.apiUrl, args),
);

export const getAccountsAction = asyncAuthorizedActionCreator(
  {
    pending: actions.ACCOUNTS_REQUEST_PENDING,
    complete: actions.ACCOUNTS_REQUEST_RECEIVED,
    error: actions.ACCOUNTS_REQUEST_ERROR,
  },
  () => (
    _dispatch,
    getState,
  ) => accountService.getAccounts(getState().config.apiUrl),
);

export const createPppLeadAction = asyncAuthorizedActionCreator(
  {
    pending: actions.CREATE_PPP_LEAD_REQUEST_PENDING,
    complete: actions.CREATE_PPP_LEAD_REQUEST_RECEIVED,
    error: actions.CREATE_PPP_LEAD_REQUEST_ERROR,
  },
  (accountId, contactId) => (
    dispatch,
    getState,
  ) => accountService.createPppLead(getState().config.apiUrl, accountId, contactId)
    .then((loanApplicationUrl) => {
      redirect(loanApplicationUrl);
      return loanApplicationUrl;
    }),
);
