import React from 'react';
import PropTypes from 'prop-types';

const Detail = (props) => {
  const { label, amount, highlight } = props;
  return (
    <div className="detail">
      <div className="detail-label">
        {label}
        :
      </div>
      <div className="dot-leader" />
      <div className={`loan-details-amount ${highlight ? 'highlight' : ''}`}>{amount}</div>
    </div>
  );
};

Detail.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  highlight: PropTypes.bool,
  label: PropTypes.string,
};

Detail.defaultProps = {
  amount: undefined,
  highlight: false,
  label: undefined,
};

export default Detail;
