import React from 'react';
import { BodyText, Link } from '@swift-paypal/pp-react';

const ForMore2483SDInformationLink = () => (
  <BodyText>
    For more information see the SBA Form
    {' '}
    <Link
      className="form-link"
      data-automation-id="sbaForm2483SDInstructions"
      href="https://www.sba.gov/document/sba-form-2483-sd-ppp-second-draw-borrower-application-form"
      target="_blank"
    >
      2483-SD Instructions
    </Link>
    .
  </BodyText>
);

export default ForMore2483SDInformationLink;
