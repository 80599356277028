import React, { useEffect } from 'react';
import { BodyText, HeadingText } from '@swift-paypal/pp-react';
import { updateLoanForgivenessAction } from '../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import util, { updateApplicationVersionSelectedEffect } from '../../../util';
import spotCelebrate from '../../../static/images/icons/spot-celebrate.svg';
import { componentSelector, useShallowSelector } from './forgiveness-detail.selectors';
import { forgivenTypes, stageContent } from './forgiveness-detail.constants';
import './forgiveness-detail.less';

const ForgivenessDetail = () => {
  const {
    activeAdvanceId, advances, loanForgiveness,
  } = useShallowSelector(componentSelector);

  const advance = advances.byId[activeAdvanceId];
  const {
    ezAppEligible, id: advanceId, amount: advanceAmount, loanVersion,
  } = advance;
  const loanForgivenessObject = Object.values(loanForgiveness.byId).find((lf) => lf.advanceId === advanceId);
  const { actualForgivenessAmount, finalForgivenessPrincipalOnly, stage } = loanForgivenessObject;
  let forgivenessStage = stage;
  if (forgivenessStage === 'Forgiven') {
    forgivenessStage = finalForgivenessPrincipalOnly < advanceAmount
      ? forgivenTypes.partial
      : forgivenTypes.full;
  }
  const content = stageContent(loanVersion);
  const { titleText, messageText, amountLabel } = content[forgivenessStage];
  const forgivenessAmount = (forgivenessStage === 'Awaiting SBA Response' || forgivenessStage === 'Pending SBA Submission') ? actualForgivenessAmount : finalForgivenessPrincipalOnly;
  const forgivenessAmountText = util.getCurrency(forgivenessAmount);
  const fullyForgiven = forgivenessStage === forgivenTypes.full;
  const forgivenessTitleClassname = `forgiveness-detail__title ${fullyForgiven ? 'fully-forgiven' : ''}`;

  useEffect(updateApplicationVersionSelectedEffect(
    loanForgivenessObject,
    ezAppEligible,
    updateLoanForgivenessAction,
  ), []);

  return (
    <div className="forgiveness-detail">
      <div className="forgiveness-detail__heading-container">
        {fullyForgiven && (
          <img
            alt="Celebrate Illustration"
            className="heading-illustration"
            src={spotCelebrate}
          />
        )}
        <div className="titles">
          <HeadingText className={forgivenessTitleClassname}>
            {forgivenessStage === forgivenTypes.partial
              ? getPartialForgivenessTitle(forgivenessAmountText)
              : titleText}
          </HeadingText>
          <BodyText className="forgiveness-detail__subtitle">
            {messageText}
          </BodyText>
        </div>
      </div>
      {amountLabel && (
        <div className="forgiveness-detail__messaging">
          <BodyText className="label">{amountLabel}</BodyText>
          <BodyText className="value" strong>
            {forgivenessAmountText}
          </BodyText>
        </div>
      )}
    </div>
  );
};

const getPartialForgivenessTitle = (forgivenessAmountText) => (
  <>
    The SBA has approved loan forgiveness of
    <strong>{` ${forgivenessAmountText} `}</strong>
    for your PPP Loan
  </>
);

export default ForgivenessDetail;
