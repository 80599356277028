import React from 'react';
import PropTypes from 'prop-types';
import StipulationItem from './stipulation-items/stipulation-item';

const AccountStipulationItems = (props) => {
  const { stipulations } = props;

  return (
    <>
      {stipulations && !!stipulations.length && stipulations.map((accountStipulation) => (
        <div className="stipulation-items" key={accountStipulation.id}>
          <div data-account-stipulation className="list-items margin-top-xs margin-bottom-xs">
            {accountStipulation.stipulations && accountStipulation.stipulations.map((stipulation) => (
              <StipulationItem
                key={stipulation.index}
                stipulation={stipulation}
                renewFunc={props.renewFunc}
                clearStipFunc={props.clearStipFunc}
                opportunity={accountStipulation}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

AccountStipulationItems.propTypes = {
  clearStipFunc: PropTypes.func,
  renewFunc: PropTypes.func,
  stipulations: PropTypes.arrayOf(PropTypes.object),
};

AccountStipulationItems.defaultProps = {
  clearStipFunc: undefined,
  renewFunc: undefined,
  stipulations: undefined,
};

export default AccountStipulationItems;
