import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
  Container, Row, Col, HeadingText, BodyText, Alert,
} from '@swift-paypal/pp-react';
import AmountOptionText from '../../labels/amount-option/amount-option';
import { TotalCostsFteReduction } from '../../labels/total-costs-fte-reduction';
import util from '../../../../../util';
import { estimateForgivenessErrorCheck, generateTotalCostsProps } from './estimate-forgiveness-amount-utils';
import heapService from '../../../../../services/heap.service';
import errors from '../../../../../constants/errors';
import { useShallowSelector } from '../../../../../hooks';
import { estimateForgivenessAmountSelector } from './estimate-forgiveness-amount-selectors';
import './estimate-forgiveness-amount.less';

const EstimateForgivenessAmountForm = ({
  children,
  handleSubmit,
  returnToStart,
}) => {
  const {
    advance,
    loanForgiveness,
    page2Values,
    page5Values,
  } = useShallowSelector(estimateForgivenessAmountSelector);
  if (!advance) {
    return <></>;
  }

  const sfForgivnessObj = loanForgiveness.byId[loanForgiveness.allIds.find(
    (loanForgivnessId) => loanForgiveness.byId[loanForgivnessId].advanceId === advance.id,
  )];

  if (!sfForgivnessObj) {
    return <></>;
  }

  const {
    payrollCost60Percent,
    totalEligibleExpenses,
    totalPayrollCost,
    fteReductionQuotient,
    actualForgivenessAmount,
    modifiedForgivenessAmount,
  } = sfForgivnessObj;

  const totalCostsProps = generateTotalCostsProps({
    fteReductionQuotient,
    modifiedForgivenessAmount,
    totalPayrollCost,
    page5Values,
    page2Values,
    totalEligibleExpenses,
  });

  const isError = estimateForgivenessErrorCheck({
    payrollCost60Percent,
    totalEligibleExpenses,
    totalPayrollCost,
    fteReductionQuotient,
    actualForgivenessAmount,
  });

  if (isError) {
    heapService.setMissingDataEvent(sfForgivnessObj);
  }

  const pppLoanAmount = advance.amount || 0;

  const pppLoanAmountText = util.generateCurrencyText(pppLoanAmount);
  const payrollCost60PercentText = util.generateCurrencyText(payrollCost60Percent);
  const actualLoanAmountText = util.generateCurrencyText(actualForgivenessAmount);

  return (
    <>
      {
        isError && (
          <Alert type="error" className="data-missing-error-alert">
            {errors.dataMissing}
          </Alert>
        )
      }
      {
        !isError && (
          <Container form as="form" className="estimate-forgiveness-amount" onSubmit={handleSubmit} noValidate>
            <Row>
              <Col>
                <HeadingText as="h2">
                  You may be eligible for up to
                  <strong>{` ${actualLoanAmountText} `}</strong>
                  in loan forgiveness
                </HeadingText>
                <BodyText as="p">
                  The amount of loan forgiveness you may request under SBA rules is the
                  <strong>{' smallest '}</strong>
                  of the following 3 values:
                </BodyText>
                <AmountOptionText
                  label="PPP loan amount"
                  value={pppLoanAmountText}
                />
                <AmountOptionText
                  label="Payroll cost 60% requirement"
                  caption="Total payroll cost / 0.6"
                  value={payrollCost60PercentText}
                />
                <TotalCostsFteReduction
                  {...totalCostsProps}
                />
                <BodyText as="p">
                  Make sure these amounts are correct. If this looks right, you can continue to the
                  next step and upload your application documents. If not,
                  {' '}
                  <button className="link-button ppvx_link" type="button" onClick={returnToStart}>
                    edit your application
                  </button>
                  {' '}
                  to change these amounts.
                </BodyText>
              </Col>
            </Row>
            {children}
          </Container>
        )
      }
    </>
  );
};

EstimateForgivenessAmountForm.propTypes = {
  children: PropTypes.element.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  returnToStart: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'forgivenessPage6',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(EstimateForgivenessAmountForm);
