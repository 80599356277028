import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import {
  BodyText, Button, Col, Row, Tile, Tooltip,
} from '@swift-paypal/pp-react';
import {
  required, numericality, date,
} from 'redux-form-validators';
import moment from 'moment';
import { useShallowSelector } from '../../hooks';
import constants from '../../constants';
import PageTitle from '../common/page-title';
import { Alert, AlertTypes } from '../common/alert';
import './calculate-payroll-data.less';
import RadioField from './radio-field';
import TextField from './text-field';
import SelectionField from './selection-field';
import { currencyMask, safariOrIeDateValidation } from './utils';
import {
  QUESTION_TO_FIELD_MAP,
  BOOLEAN_QUESTION_NAME_ARRAY,
  formInstructions,
  toolTipInfo,
  formQuestions,
  TAB_KEYCODE,
  MONTH_PERIOD_12,
  YEAR_2019,
  YEAR_2020,
  SUBMIT,
} from './calculate-payroll-data-constants';
import { formSelector } from './calculate-payroll-data-selectors';

export const CalculatePayrollFormComponent = ({
  updatePppObjectFunc,
  handleSubmit,
  isFirstDraw,
  hideThirdOption,
  submissionError,
}) => {
  const params = useParams();
  const { fields, syncErrors, registeredFields } = useShallowSelector(formSelector);
  const [booleanQuestionObj, setBooleanQuestionObj] = useState({
    q1: undefined,
    q10: undefined,
    q10Plus: undefined,
  });

  const { errors } = constants;
  const { q1, q10, q10Plus } = booleanQuestionObj;

  const booleanQuestionHandler = (event) => {
    const { value, name } = event.target;
    if (value && name) {
      const isYes = value === 'Yes' || value === 'true';
      setBooleanQuestionObj({
        ...booleanQuestionObj,
        [name]: isYes,
      });
    }
  };

  const submitHandler = (values) => {
    const { opportunityId } = params;

    const data = {};
    Object.values(registeredFields).forEach((field) => {
      const questionName = field.name;
      const sfField = QUESTION_TO_FIELD_MAP[questionName];

      if (sfField) {
        data[sfField] = questionName === 'q2Yes'
          ? moment(values[questionName])
          : values[questionName];
      }
    });

    const updatePayrollObject = { data, opportunityId };

    updatePppObjectFunc(updatePayrollObject);
  };

  const onKeyDownHandler = (event) => {
    const { key, target } = event;
    const { name } = target;
    if (key === TAB_KEYCODE && BOOLEAN_QUESTION_NAME_ARRAY.includes(name)) {
      booleanQuestionHandler(event);
    }
  };

  return (
    <Tile card className="calculate-payroll-data-tile">
      <div className="calculate-payroll-date-component">
        <PageTitle text="Payroll Calculation Data" />
        <BodyText as="h3">{formInstructions[0]}</BodyText>
        <BodyText as="h3">{formInstructions[1]}</BodyText>
        <form id="calculatePayrollDataForm">
          <Row>
            <Col xs={12} sm={10}>
              {submissionError && (<Alert message={submissionError} alertType={AlertTypes.critical} />)}
              <RadioField
                name="q1"
                label={(
                  <>
                    {formQuestions.q1}
                    <Tooltip
                      className="pp-react__tooltip--inline"
                      description={toolTipInfo.q1}
                    />
                  </>
                )}
                error={syncErrors?.q1}
                touched={fields?.q1?.touched}
                onClick={booleanQuestionHandler}
                onKeyDown={onKeyDownHandler}
                options={[
                  {
                    className: 'form-control',
                    label: 'Yes',
                    name: 'q1',
                    type: 'radio',
                    value: 'Yes',
                  },
                  {
                    className: 'form-control',
                    label: 'No',
                    name: 'q1',
                    type: 'radio',
                    value: 'No',
                  },
                ]}
                validate={[
                  required({ msg: errors.requiredField }),
                ]}
              />
              {q1 && (
                <TextField
                  name="q2Yes"
                  label={(
                    <>
                      {formQuestions.q2Yes}
                      <Tooltip
                        className="pp-react__tooltip--inline"
                        description={toolTipInfo.q2Yes}
                      />
                    </>
                  )}
                  errorText={fields?.q2Yes?.touched && syncErrors?.q2Yes}
                  error={syncErrors?.q2Yes}
                  touched={fields?.q2Yes?.touched}
                  type="date"
                  autoComplete="off"
                  validate={[
                    required({ msg: errors.requiredField }),
                    date(safariOrIeDateValidation()),
                  ]}
                />
              )}
              {!q1 && (
                <>
                  <SelectionField
                    size="sm"
                    name="q2No"
                    errorText={fields?.q2No?.touched && syncErrors?.q2No}
                    label={(
                      <>
                        {formQuestions.q2No}
                        <Tooltip
                          className="pp-react__tooltip--inline"
                          description={toolTipInfo.q2No}
                        />
                      </>
                    )}
                    validate={[required({ message: 'Required' })]}
                  >
                    <option value="" key="" disabled>Year</option>
                    ,
                    <option
                      key={YEAR_2019}
                      value={YEAR_2019}
                    >
                      {YEAR_2019}
                    </option>
                    <option
                      key={YEAR_2020}
                      value={YEAR_2020}
                    >
                      {YEAR_2020}
                    </option>
                    {!hideThirdOption && (
                      <option
                        key={MONTH_PERIOD_12}
                        value={MONTH_PERIOD_12}
                      >
                        {MONTH_PERIOD_12}
                      </option>
                    )}
                  </SelectionField>
                </>
              )}
              <BodyText as="h1">
                {formInstructions[2]}
              </BodyText>
              <TextField
                name="q3"
                label={(
                  <>
                    {formQuestions.q3}
                    <Tooltip
                      className="pp-react__tooltip--inline"
                      description={toolTipInfo.q3}
                    />
                  </>
                )}
                error={syncErrors?.q3}
                touched={fields?.q3?.touched}
                validate={[
                  required({ msg: errors.enterAnAmount }),
                  numericality({ '>=': 0, msg: errors.enterAnAmount }),
                ]}
                {...currencyMask}
              />
              <TextField
                name="q4"
                label={formQuestions.q4}
                error={syncErrors?.q4}
                touched={fields?.q4?.touched}
                validate={[
                  required({ msg: errors.enterAnAmount }),
                  numericality({ '>=': 0, msg: errors.enterAnAmount }),
                ]}
                {...currencyMask}
              />
              <TextField
                name="q5"
                label={formQuestions.q5}
                error={syncErrors?.q5}
                touched={fields?.q5?.touched}
                validate={[
                  required({ msg: errors.enterAnAmount }),
                  numericality({ '>=': 0, msg: errors.enterAnAmount }),
                ]}
                {...currencyMask}
              />
              <TextField
                name="q6"
                label={formQuestions.q6}
                error={syncErrors?.q6}
                touched={fields?.q6?.touched}
                validate={[
                  required({ msg: errors.enterAnAmount }),
                  numericality({ '>=': 0, msg: errors.enterAnAmount }),
                ]}
                {...currencyMask}
              />
              <TextField
                name="q7"
                label={(
                  <>
                    {formQuestions.q7}
                  </>
                )}
                error={syncErrors?.q7}
                touched={fields?.q7?.touched}
                validate={[
                  required({ msg: errors.enterAnAmount }),
                  numericality({ '>=': 0, msg: errors.enterAnAmount }),
                ]}
                {...currencyMask}
              />
              <TextField
                name="q8"
                label={formQuestions.q8}
                error={syncErrors?.q8}
                touched={fields?.q8?.touched}
                validate={[
                  required({ msg: errors.enterAnAmount }),
                  numericality({ '>=': 0, msg: errors.enterAnAmount }),
                ]}
                {...currencyMask}
              />
              {isFirstDraw && (
                <>
                  <RadioField
                    name="q10"
                    label={formQuestions.q10}
                    error={syncErrors?.q10}
                    touched={fields?.q10?.touched}
                    onClick={booleanQuestionHandler}
                    onKeyDown={onKeyDownHandler}
                    options={[
                      {
                        className: 'form-control',
                        label: 'Yes',
                        name: 'q10',
                        type: 'radio',
                        value: 'true',
                      },
                      {
                        className: 'form-control',
                        label: 'No',
                        name: 'q10',
                        type: 'radio',
                        value: 'false',
                      },
                    ]}
                    validate={[
                      required({ msg: errors.requiredField }),
                    ]}
                  />
                  {q10 && (
                  <>
                    <RadioField
                      name="q10Plus"
                      label={formQuestions.q10Plus}
                      error={syncErrors?.q10Plus}
                      touched={fields?.q10Plus?.touched}
                      onClick={booleanQuestionHandler}
                      onKeyDown={onKeyDownHandler}
                      options={[
                        {
                          className: 'form-control',
                          label: 'Yes',
                          name: 'q10Plus',
                          type: 'radio',
                          value: 'true',
                        },
                        {
                          className: 'form-control',
                          label: 'No',
                          name: 'q10Plus',
                          type: 'radio',
                          value: 'false',
                        },
                      ]}
                      validate={[
                        required({ msg: errors.requiredField }),
                      ]}
                    />
                  </>
                  )}
                  {q10Plus && q10 && (
                    <>
                      <TextField
                        name="q11"
                        label={formQuestions.q11}
                        error={syncErrors?.q11}
                        touched={fields?.q11?.touched}
                        validate={[required({ msg: errors.mustBeNumber }), numericality({ '>=': 0, msg: errors.mustBeNumber })]}
                      />
                      <TextField
                        name="q12"
                        label={formQuestions.q12}
                        error={syncErrors?.q12}
                        touched={fields?.q12?.touched}
                        validate={[
                          required({ msg: errors.enterAnAmount }),
                          numericality({ '>=': 0, msg: errors.enterAnAmount }),
                        ]}
                        {...currencyMask}
                      />
                    </>
                  )}
                </>
              )}
              <div className="calculate-payroll-date-component__btn">
                <Button
                  data-submit-form
                  form="calculatePayrollDataForm"
                  onClick={handleSubmit(submitHandler)}
                  type="submit"
                >
                  {SUBMIT}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </Tile>
  );
};

CalculatePayrollFormComponent.propTypes = {
  updatePppObjectFunc: PropTypes.func.isRequired,
  submissionError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  isFirstDraw: PropTypes.bool.isRequired,
  hideThirdOption: PropTypes.bool.isRequired,
};

CalculatePayrollFormComponent.defaultProps = {
  submissionError: undefined,
};

export default reduxForm({ form: 'calculatePayrollDataForm' })(CalculatePayrollFormComponent);
