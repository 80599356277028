import React, { useContext, useState } from 'react';
import {
  Button, RadioButton, AddIcon,
} from '@paypalcorp/pp-react';
import { useShallowSelector, oneTimePaymentSelector } from '../../one-time-payment-selectors';
import '../../payments.less';
import { OneTimePaymentContext } from '../../OneTimePaymentContext';
import { PaymentComponentMapping } from '../../paymentComponentMappings';
import displayNames from '../../../../constants/displayNames';
import { RenderFiContainer } from '../../components/renderFiContainer';
import { ResponsiveHeadingText } from '../../components/responsiveTypography';
import {
  getDefaultBank,
  getBankListForAdvance,
} from '../../../pages/oneTimePaymentPage/oneTimePayment.utils';

const ChangePaymentMethod = () => {
  const {
    selectedLoanAdvance, setVisibleComponent, setCurrentBank, setEditData, resetEditData,
  } = useContext(OneTimePaymentContext);
  const { bankList } = useShallowSelector(oneTimePaymentSelector);
  const bankFis = getBankListForAdvance(bankList, selectedLoanAdvance) || [];

  const [paymentRadioValue, setPaymentValue] = useState(() => {
    const defaultOptions = getDefaultBank(bankList, selectedLoanAdvance);
    if (defaultOptions !== undefined) {
      return defaultOptions.Id;
    }
    return null;
  });

  const handleRadioStateChange = (e) => {
    const { target } = e;
    setPaymentValue(target.value);
  };

  const handleCancelEvent = () => {
    setVisibleComponent(PaymentComponentMapping.MakeAPayment);
  };

  const handlePaymentSelection = () => {
    if (bankFis.length && paymentRadioValue) {
      const selectedBank = bankFis.find((fi) => fi.Id === paymentRadioValue);
      if (selectedBank) {
        setCurrentBank(selectedBank);
      }
    }
    setVisibleComponent(PaymentComponentMapping.MakeAPayment);
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditBank = (fiInfo) => {
    // should be EditBankInfo
    resetEditData();
    const editInfo = {
      isEditMode: true,
      fiData: fiInfo,
    };
    setEditData(editInfo);
    setVisibleComponent(PaymentComponentMapping.AddBusinessInfo);
  };

  const createRadioButtonOptions = (fis) => {
    const optionsArray = [];
    fis?.forEach((fi) => {
      const optionsContainer = (
        <RenderFiContainer key={fi.Id} fiInfo={fi} handleEditBank={handleEditBank} />
      );
      const optionsObject = {
        label: optionsContainer,
        value: fi.Id,
      };
      optionsArray.push(optionsObject);
    });
    return optionsArray;
  };

  return (
    <div>
      <ResponsiveHeadingText className="heading" mobile="sm" desktop="lg">
        Change payment method
      </ResponsiveHeadingText>
      <RadioButton
        id="paymentradiobtn"
        name="paymentselection"
        value={paymentRadioValue}
        onChange={handleRadioStateChange}
        options={createRadioButtonOptions(bankFis)}
      />
      <div>
        <Button
          onClick={() => {
            resetEditData();
            setVisibleComponent(PaymentComponentMapping.AddBusinessInfo);
          }}
          tertiary
          iconComponent={AddIcon}
        >
          Add a new bank
        </Button>
      </div>
      <div className="btn-wrapper select-payment-btn">
        <Button size="lg" onClick={handlePaymentSelection}>
          Select Payment Method
        </Button>
        <Button size="lg" tertiary onClick={handleCancelEvent}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

ChangePaymentMethod.displayName = displayNames.ChangePaymentMethod;
export default ChangePaymentMethod;
