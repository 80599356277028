import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { BodyText } from '@swift-paypal/pp-react';
import heapService from '../../services/heap.service';
import resolve from '../../services/route.service';
import SecurePage from '../../hocs/secure-page';
import PageTitle from '../../components/common/page-title';
import uploadUtils from '../../lib/document-upload-utils';
import UploadMultipleDocumentsComponent from '../../components/upload-multiple-documents/upload-multiple-documents';
import { getProductsAndBfesAction } from '../../redux/actions/account-items/account-items-actions';
import { getOpportunitiesAction } from '../../redux/actions/opportunities/opportunities-actions';
import {
  clearUploadErrorAction,
  clearUploadPendingAction,
  uploadBatchDocumentsAction,
} from '../../redux/actions/upload-document/upload-document-actions';
import routes from '../../routes';
import displayNames from '../../constants/displayNames';
import StandardLayout from '../../layouts/StandardLayout';
import { stipulationGroupsDescriptions } from '../../constants/upload-document';

const stipulationGroupName = 'Ownership and Affiliations';

class UploadAddendumAPage extends Component {
  static displayName = displayNames.UploadAddendumAPage;

  static propTypes = {
    clearUploadError: PropTypes.func.isRequired,
    clearUploadPending: PropTypes.func.isRequired,
    country: PropTypes.string,
    getProductsAndBfes: PropTypes.func.isRequired,
    getOpportunities: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    opportunitiesById: PropTypes.objectOf(PropTypes.shape({
      loanVersion: PropTypes.string,
    })),
    push: PropTypes.func.isRequired,
    uploadBatchDocuments: PropTypes.func.isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.bool,
  };

  static defaultProps = {
    country: undefined,
    match: undefined,
    opportunitiesById: {},
    uploadPending: true,
    uploadError: false,
  };

  async componentDidMount() {
    const {
      getProductsAndBfes,
      getOpportunities,
      match,
      opportunitiesById,
    } = this.props;
    const { opportunityId } = match.params;
    await getProductsAndBfes();
    if (!opportunitiesById[opportunityId]) {
      await getOpportunities();
    }
  }

  componentWillUnmount() {
    const { clearUploadError, clearUploadPending } = this.props;
    clearUploadError();
    clearUploadPending();
  }

  submit = (files) => {
    const { match, uploadBatchDocuments } = this.props;
    const { opportunityId } = match.params;
    const payloads = [];
    files.forEach((file) => {
      if (file.fileBlob) {
        payloads.push(uploadUtils.buildPayload(file.fileBlob, opportunityId, stipulationGroupName));
      }
    });
    uploadBatchDocuments(payloads);
  }

  redirectToSummaryConfirmation() {
    const { country, push, match } = this.props;
    const { opportunityId } = match.params;
    const RoutePayload = {
      country,
      successStipulationGroupName: stipulationGroupName,
    };

    heapService.markUserStipulationAsCompleted(opportunityId, stipulationGroupName);
    return push(resolve(routes.summary.path, RoutePayload));
  }

  render() {
    const {
      uploadPending, uploadError, opportunitiesById, match,
    } = this.props;
    const { opportunityId } = match.params;

    let pageTitle = 'Ownership and Affiliations Documentation';

    let groupDescription = stipulationGroupsDescriptions.Default;

    let fakeStipulation = {
      displayName: 'Ownership and Affiliations: Upload documents',
      description: (
        <>
          <p>
            Please list and upload a document of all businesses that are owned by or share a common management
            {' '}
            structure with the applicant or any other owner of the business in a document titled Addendum A.
          </p>
        </>
      ),
    };

    const loanVersion = opportunitiesById[opportunityId]?.loanVersion;
    const showPPPLoan = ['21PPPLoan', '21SDPPP'].includes(loanVersion);

    if (showPPPLoan) {
      pageTitle = 'Ownership & Affiliations: Addendum A';
      groupDescription = (
        <>
          <BodyText as="h3">
            As you have indicated that the Applicant or any owner of the Applicant is an owner of
            another business, or has common management with another business, you are required to
            provide additional information to us in a document that the SBA refers to as Addendum A.
          </BodyText>
          <BodyText as="h3">
            This document should list all other businesses that the Applicant or any owner of the
            Applicant is also an owner of, or any other business that has common management,
            (including their TINs if available) and describe the relationship between such
            businesses and the Applicant.
          </BodyText>
        </>
      );
      fakeStipulation = {
        displayName: 'Upload the Addendum A',
      };
    }

    if (uploadPending === false && !uploadError) {
      this.redirectToSummaryConfirmation();
      return null;
    }

    return (
      <>
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StandardLayout
            contentElement={(
              <div>
                <PageTitle text={pageTitle} />
                <UploadMultipleDocumentsComponent
                  groupDescription={groupDescription}
                  uploadError={uploadError}
                  uploadPending={uploadPending}
                  stipulations={[fakeStipulation]}
                  minimumStipulationsRequired={1}
                  submit={this.submit}
                  showPPPLoan={showPPPLoan}
                />
              </div>
            )}
            sidebarElement={<></>}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    location: state.router.location,
    opportunitiesById: state.entities.opportunities?.byId,
    uploadPending: state.uploadDocument.uploadPending,
    uploadError: state.uploadDocument.error,
  };
}

export default connect(
  mapStateToProps,
  {
    clearUploadError: clearUploadErrorAction,
    clearUploadPending: clearUploadPendingAction,
    getProductsAndBfes: getProductsAndBfesAction,
    getOpportunities: getOpportunitiesAction,
    uploadBatchDocuments: uploadBatchDocumentsAction,
  },
)(SecurePage(UploadAddendumAPage));
