import React from 'react';
import PropTypes from 'prop-types';

const renderCheckField = ({
  input,
  id,
  label,
  className,
  value,
  meta: { touched, error, warning },
}) => (
  <div>
    <div className={`vx_checkbox ${(touched && error) ? 'vx_has-error' : ''}`}>
      <input
        {...input}
        id={id}
        type="checkbox"
        value={value}
        className={className}
      />
      <label htmlFor={id}>
        {label}
      </label>
    </div>
    {touched
      && ((error && <span className="vx_form-control-message margin-bottom-sm">{error}</span>)
        || (warning && <span className="vx_form-control-message margin-bottom-sm">{warning}</span>))}
  </div>

);

renderCheckField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  noErrorClass: PropTypes.string,
  value: PropTypes.bool,
};

renderCheckField.defaultProps = {
  className: undefined,
  input: undefined,
  meta: undefined,
  noErrorClass: undefined,
  value: false,
};

export default renderCheckField;
