import axios from 'axios';
import defaultRequestOptions from './defaultRequestOptions';
import { asyncDebounce } from '../util';

export default {
  updatePppObject: asyncDebounce(updatePppObject),
};

async function updatePppObject(apiUrl, data) {
  const res = await axios.patch(`${apiUrl}/paycheckProtectionPlan/update-paycheck-protection-plan`, { data }, defaultRequestOptions());
  return res.data;
}
