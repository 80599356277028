import React from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@swift-paypal/pp-react';
import './body-text.less';
import { ppp21LoanVersions } from '../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';

const UtilityBodyText = ({ loanVersion }) => (
  <div className="body-text">
    <BodyText as="p">
      Business utility documents are required if
      {ppp21LoanVersions.includes(loanVersion)
        && ' such costs are'}
      {' '}
      included in your loan forgiveness request. If not, please see the check box below.
    </BodyText>
    <BodyText as="p">
      Upload supporting documents to verify your business utility costs for electricity,
      gas, water, transportation, telephone, and internet during the covered period.
      Here&apos;s what you need to provide:
    </BodyText>
    <ul className="forgivenessListItems">
      <BodyText as="li">
        A copy of
        <strong> utility invoices from Feb 2020</strong>
        , and
      </BodyText>
      <BodyText as="li">
        Copies of your
        <strong> utility invoices paid </strong>
        during your selected covered period, and
      </BodyText>
      <BodyText as="li">
        <strong>Receipts, cancelled checks or utility account statements</strong>
        {' '}
        verifying eligible payments paid or incurred during the covered period.
      </BodyText>
    </ul>
  </div>
);

UtilityBodyText.propTypes = {
  loanVersion: PropTypes.string.isRequired,
};

export default UtilityBodyText;
