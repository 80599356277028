import React from 'react';
import PropTypes from 'prop-types';
import LoanSummary from '../loan-summary';
import { accountAdvanceIdsSelector, useShallowSelector } from '../loan-summary-lib/selectors';

const LoanSummaryList = ({ accountId }) => {
  const accountAdvanceIds = useShallowSelector(accountAdvanceIdsSelector, accountId);

  return accountAdvanceIds.map((advanceId) => <LoanSummary advanceId={advanceId} key={advanceId} />);
};

LoanSummaryList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default LoanSummaryList;
