import {
  URL_BEFORE_API_WENT_DOWN_CREATED,
  URL_BEFORE_API_WENT_DOWN_DELETED,
  URL_BEFORE_LOGGED_OUT_CREATED,
  URL_BEFORE_LOGGED_OUT_DELETED,
} from '../actions/redirect-urls/redirect-urls-action-types';

export default function redirectUrls(state = {}, action) {
  switch (action.type) {
    case URL_BEFORE_LOGGED_OUT_CREATED:
      // need to use localStorage for sso redirects
      if (action.url && !action.url.includes('login')) {
        localStorage.setItem('urlBeforeLoggedOut', action.url);
      }
      return { ...state, urlBeforeLoggedOut: localStorage.getItem('urlBeforeLoggedOut') };
    case URL_BEFORE_LOGGED_OUT_DELETED:
      localStorage.removeItem('urlBeforeLoggedOut');
      return { ...state, urlBeforeLoggedOut: null };
    case URL_BEFORE_API_WENT_DOWN_CREATED:
      return { ...state, urlBeforeApiWentDown: action.url };
    case URL_BEFORE_API_WENT_DOWN_DELETED:
      return { ...state, urlBeforeApiWentDown: null };
    default:
      return state;
  }
}
