import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import withUrlParams from '../../../hocs/with-url-params';
import successPlane from '../../../static/images/icons/paper_plane-success-graphic.svg';
import './loan-state.less';

const LoanProcessing = () => (
  <div className="loan-state">
    <img alt="Success logo" src={successPlane} />
    <h3 className="vx_text-3">
      Your loan agreement has been received.
    </h3>
    <div>
      Your business loan agreement is being reviewed. If approved, funding should occur in 1 to 2 business days.
    </div>
  </div>
);

export default compose(
  withRouter,
  withUrlParams,
)(LoanProcessing);
