import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from '@swift-paypal/pp-react';
import { useShallowSelector } from '../../../../hooks';
import { fieldSelector } from '../sba3508SForm.selectors';
import { fieldNames } from '../sba3508SFormFields/sba3508SFormFields.constants';
import { Sba3508SFormInstructionsLink } from '../sba3508SFormInstructionsLink';
import errors from '../../../../constants/errors';

const Sba3508SFormAlert = ({ hasSubmissionError }) => {
  const { reviewedAdjustedForgivenessAmount } = fieldNames;

  const reviewedAmount = useShallowSelector(fieldSelector, reviewedAdjustedForgivenessAmount);

  if (reviewedAmount?.registered && reviewedAmount?.value === 'false') {
    return (
      <Row>
        <Col form="full">
          <Alert type="error">
            You must use the
            {' '}
            <Sba3508SFormInstructionsLink
              formName="sba_form_3508.pdf"
              text="SBA Form 3508 Calculation Form, Worksheet and Instructions"
            />
            {' '}
            to calculate your Requested Loan Forgiveness Amount that you provide in the field above
            for inclusion in your SBA Form 3508S Loan Forgiveness Application.
          </Alert>
        </Col>
      </Row>
    );
  }

  return hasSubmissionError ? (
    <Col form="full">
      <Alert type="error">{errors.technicalIssue}</Alert>
    </Col>
  ) : null;
};

Sba3508SFormAlert.propTypes = {
  hasSubmissionError: PropTypes.bool.isRequired,
};

export default memo(Sba3508SFormAlert);
