import { Container, HeadingText } from '@swift-paypal/pp-react';
import React from 'react';
import { useShallowSelector } from '../../../hooks';
import LoanSummaryList from '../../loan-summary/loan-summary-list/loan-summary-list';
import { yourLoansSelector } from './yourLoans.selector';

const YourLoans = () => {
  const { accounts } = useShallowSelector(yourLoansSelector);
  return (
    <div className="summary-page-layer">
      <div className="summary-page-layer__content">
        <HeadingText size="sm">Your loans</HeadingText>
        <Container fluid>
          {/* Placeholder for new Loan Summary tiles */}
          {accounts.allIds.map((id) => <LoanSummaryList accountId={id} key={id} />)}
        </Container>
      </div>
    </div>
  );
};

export default YourLoans;
