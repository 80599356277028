import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required, format, length } from 'redux-form-validators';
import {
  Col, Container, RadioButton, Row,
} from '@swift-paypal/pp-react';
import Title from './common/title';
import constants from '../constants';
import floatingLabelField from '../lib/forms/floating-label-field';
import { normalizePhone } from '../lib/forms/normalizations';
import { Alert } from './common/alert';

const { errors } = constants;
const yesId = 'yes-own-property';
const noId = 'no-own-property';

export class BusinessPropertyForm extends React.Component {
  static propTypes = {
    clearMessage: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    messageBody: PropTypes.string,
    messageType: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    ownBusinessProperty: PropTypes.bool,
    setOwnBusinessProperty: PropTypes.func.isRequired,
  };

  static defaultProps = {
    messageBody: undefined,
    messageType: undefined,
    ownBusinessProperty: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (event) => {
    const {
      clearMessage,
      setOwnBusinessProperty,
    } = this.props;
    if (event.target.id === yesId) {
      clearMessage();
      setOwnBusinessProperty(true);
    }
    else if (event.target.id === noId) {
      clearMessage();
      setOwnBusinessProperty(false);
    }
  };

  BusinessPropertyInfoForm = () => (
    <div>
      <Title text="Provide landlord contact information if you do not own the business property." />
      <Row className="margin-bottom-md">
        <Col xs={12} sm={10} className="margin-bottom-sm">
          <Field
            name="firstName"
            label="Landlord first name"
            manualSet
            component={floatingLabelField}
            type="text"
            className="vx_form-control form-control"
            validate={[
              required({ msg: errors.requiredField }),
              length({ max: 40, msg: errors.businessFirstNameLength }),
            ]}
          />
          <Field
            name="lastName"
            label="Landlord last name"
            manualSet
            component={floatingLabelField}
            type="text"
            className="vx_form-control form-control"
            validate={[
              required({ msg: errors.requiredField }),
              length({ max: 50, msg: errors.businessLastNameLength }),
            ]}
          />
          <Field
            name="phone"
            label="Landlord contact phone number"
            manualSet
            component={floatingLabelField}
            type="text"
            className="vx_form-control form-control"
            validate={[
              required({ msg: errors.requiredField }),
              format({ with: /^\(\d{3}\) \d{3}-\d{4}/, msg: errors.invalidPhone }),
            ]}
            normalize={normalizePhone}
          />
        </Col>
      </Row>
    </div>
  );

  render() {
    const {
      messageBody, messageType, ownBusinessProperty, handleSubmit, onSubmit,
    } = this.props;

    return (
      <div className="form">
        <Container fluid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title text="Do you own the business property?" />
            <Row className="margin-bottom-md">
              {messageBody
              && (
              <Col xs={12}>
                <Alert message={messageBody} alertType={messageType} />
              </Col>
              )}
              <Col xs={12}>
                <RadioButton
                  name="ownBusinessProperty"
                  errorText={ownBusinessProperty === undefined ? 'custom_has-error' : ''}
                  options={[
                    {
                      className: 'form-control',
                      id: yesId,
                      label: 'Yes',
                      name: 'ownBusinessProperty',
                      onClick: this.handleChange,
                      type: 'radio',
                    },
                    {
                      className: 'form-control',
                      id: noId,
                      label: 'No',
                      name: 'ownBusinessProperty',
                      onClick: this.handleChange,
                      type: 'radio',
                    },
                  ]}
                />
              </Col>
            </Row>
            {ownBusinessProperty === false && this.BusinessPropertyInfoForm()}
          </form>
        </Container>
      </div>
    );
  }
}

const BusinessPropertyFormComponent = reduxForm({
  form: 'business-property-form',
})(BusinessPropertyForm);

export default BusinessPropertyFormComponent;
