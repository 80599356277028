import settingsService from '../../../services/settings.service';
import authService from '../../../services/auth.service';
import { asyncNoAuthActionCreator } from '../util/async-action-creator';
import * as actions from './reset-password-action-types';

export const resetPasswordAction = asyncNoAuthActionCreator(
  {
    pending: actions.RESET_PASSWORD_REQUEST_PENDING,
    complete: actions.RESET_PASSWORD_RECEIVED,
    error: actions.RESET_PASSWORD_REQUEST_ERROR,
  },
  (token, password, question, answer) => (dispatch, getState) => {
    const { config } = getState();
    return settingsService.resetPassword(config.apiUrl, token, password, question, answer);
  },
);

export const clearResetPasswordAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_RESET_PASSWORD });
};

export function getSecurityQuestionTokenAction(resetToken) {
  return async (dispatch, getState) => {
    if (!resetToken) {
      return dispatch(actions.addDeauthorized());
    }

    dispatch(actions.addSecurityQuestionWithTokenRequested());

    try {
      const res = await authService.getSecurityQuestionByToken(getState().config.apiUrl, resetToken);

      return dispatch(actions.addSecurityQuestionWithTokenReceived(res.question));
    }
    catch (e) {
      return dispatch(actions.addSecurityQuestionWithTokenRequestError());
    }
  };
}

export const setResetToken = (resetToken) => async (dispatch) => {
  dispatch({ type: actions.SET_RESET_TOKEN, resetToken });
};

export const updateResetPasswordMessageAction = (messageBody, messageType) => async (dispatch) => {
  dispatch({ type: actions.UPDATE_RESET_PASSWORD_MESSAGE, messageBody, messageType });
};

export const clearResetPasswordMessageAction = () => async (dispatch) => {
  dispatch({ type: actions.CLEAR_RESET_PASSWORD_MESSAGE });
};
