import React, { useContext } from 'react';
import { sortBy } from 'lodash';
import {
  Container,
  DropdownMenu,
  BodyText,
  Row,
  Col,
  Alert,
  TextInput,
  Button,
} from '@paypalcorp/pp-react';
import '../../payments.less';
import displayNames from '../../../../constants/displayNames';
import { OneTimePaymentContext } from '../../OneTimePaymentContext';
import { PaymentComponentMapping, bankErrorAlertMapping } from '../../paymentComponentMappings';
import oneTimePaymentContants from '../../../../constants/oneTimePayments';
import states from '../../../../constants/states';
import { ResponsiveHeadingText } from '../../components/responsiveTypography';
import { useShallowSelector, oneTimePaymentSelector } from '../../one-time-payment-selectors';
import './addBank.less';

const AddBusinessInfo = () => {
  const sortedStates = sortBy(states, ['name']);
  const {
    setVisibleComponent,
    bankInfo,
    updateBankInfoContext,
    bankValidationError,
    updateBankErrorContext,
    editData,
  } = useContext(OneTimePaymentContext);
  const bankInfoConstants = oneTimePaymentContants.BankInfoConstants;
  const bankErrorFields = oneTimePaymentContants.BankInfoErrorFields;

  const { bankUpdateMessage, bankUpdateStatus, bankUpdateStatusCode } = useShallowSelector(oneTimePaymentSelector);

  const handleCancelEvent = () => {
    setVisibleComponent(PaymentComponentMapping.MakeAPayment);
  };

  const handleContinueAddBusinessInfo = (event) => {
    event.preventDefault();
    const validationArray = [
      bankInfoConstants.BankAccount.BusinessName,
      bankInfoConstants.BankAccount.StreetAddress1,
      bankInfoConstants.BankAccount.State,
      bankInfoConstants.BankAccount.City,
      bankInfoConstants.BankAccount.Zip,
    ];
    const isErrorFree = updateBankErrorContext(validationArray);
    if (isErrorFree) {
      setVisibleComponent(PaymentComponentMapping.AddBankInfo);
    }
  };

  return (
    <div>
      {bankUpdateStatus === 'error'
      && bankErrorAlertMapping.AddBusinessInfo.includes(bankUpdateStatusCode) ? (
        <Alert type="error">{bankUpdateMessage}</Alert>
        ) : null}
      <ResponsiveHeadingText desktop="lg" mobile="sm" className="heading">
        {editData.isEditMode ? 'Confirm Business Information' : 'Add a New Business Bank Account'}
      </ResponsiveHeadingText>
      <form>
        <Container form className="add-container">
          <Row>
            <Col form="full">
              <BodyText strong>Business Info</BodyText>
              <BodyText>The information provided must match your bank records.</BodyText>
            </Col>
          </Row>
          <Row>
            <Col form="full">
              <TextInput
                name="business-name"
                value={bankInfo[bankInfoConstants.BankAccount.BusinessName]}
                onChange={(evt) => updateBankInfoContext(
                  bankInfoConstants.BankAccount.BusinessName,
                  evt.target.value,
                )}
                placeholder="Your Business Name"
                label="Your Business Name"
                errorText={bankValidationError[bankErrorFields.BankAccount.BusinessNameError]}
              />
            </Col>
          </Row>
          <Row>
            <Col form="half">
              <TextInput
                name="address-line-1"
                label="Address line 1"
                placeholder="Address line 1"
                errorText={bankValidationError[bankErrorFields.BankAccount.StreetAddress1Error]}
                value={bankInfo[bankInfoConstants.BankAccount.StreetAddress1]}
                onChange={(evt) => updateBankInfoContext(
                  bankInfoConstants.BankAccount.StreetAddress1,
                  evt.target.value,
                )}
              />
            </Col>
            <Col form="half">
              <TextInput
                name="address-line-2"
                label="Address line 2"
                placeholder="Address line 2"
                errorText={bankValidationError[bankErrorFields.BankAccount.StreetAddress2Error]}
                value={bankInfo[bankInfoConstants.BankAccount.StreetAddress2]}
                onChange={(evt) => updateBankInfoContext(
                  bankInfoConstants.BankAccount.StreetAddress2,
                  evt.target.value,
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col form="full">
              <DropdownMenu
                errorText={bankValidationError[bankErrorFields.BankAccount.StateError]}
                value={bankInfo[bankInfoConstants.BankAccount.State]}
                label="State"
                options={sortedStates.map(({ abbreviation, name }) => ({
                  value: abbreviation,
                  primaryText: name,
                }))}
                onChange={(evt) => updateBankInfoContext(bankInfoConstants.BankAccount.State, evt.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col form="half">
              <TextInput
                name="city"
                placeholder="City"
                label="City"
                errorText={bankValidationError[bankErrorFields.BankAccount.CityError]}
                value={bankInfo[bankInfoConstants.BankAccount.City]}
                onChange={(evt) => updateBankInfoContext(bankInfoConstants.BankAccount.City, evt.target.value)}
              />
            </Col>
            <Col form="half">
              <TextInput
                name="zip"
                placeholder="Zip"
                label="Zip"
                errorText={bankValidationError[bankErrorFields.BankAccount.ZipError]}
                value={bankInfo[bankInfoConstants.BankAccount.Zip]}
                onChange={(evt) => updateBankInfoContext(bankInfoConstants.BankAccount.Zip, evt.target.value)}
              />
            </Col>
          </Row>
        </Container>
        <Row align="center">
          <Col form="auto">
            <Button size="lg" onClick={handleContinueAddBusinessInfo}>
              Continue
            </Button>
          </Col>
        </Row>
        {!editData.isEditMode && (
          <Row align="center">
            <Col form="auto">
              <Button tertiary onClick={handleCancelEvent}>
                Cancel
              </Button>
            </Col>
          </Row>
        )}
      </form>
    </div>
  );
};

AddBusinessInfo.displayName = displayNames.AddBusinessInfo;
export default AddBusinessInfo;
