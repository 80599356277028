export const OWNERS_REQUEST_PENDING = 'owners_pending';
export const OWNERS_REQUEST_RECEIVED = 'owners_received';
export const OWNERS_REQUEST_ERROR = 'owners_request_error';
export const OWNERS_REQUEST_CLEAR = 'owners_request_clear';

export const ACCEPTANCE_REQUEST_PENDING = 'acceptance_pending';
export const ACCEPTANCE_REQUEST_RECEIVED = 'acceptance_received';
export const ACCEPTANCE_REQUEST_ERROR = 'acceptance_request_error';

export const ATTESTATION_REQUEST_PENDING = 'attestation_pending';
export const ATTESTATION_REQUEST_RECEIVED = 'attestation_received';
export const ATTESTATION_REQUEST_ERROR = 'attestation_request_error';

export const TERMS_AND_CONDITIONS_REQUEST_PENDING = 'terms_and_conditions_pending';
export const TERMS_AND_CONDITIONS_REQUEST_RECEIVED = 'terms_and_conditions_received';
export const TERMS_AND_CONDITIONS_REQUEST_ERROR = 'terms_and_conditions_request_error';

export const OWNERS_CONTACT_REQUEST_PENDING = 'owners_contact_pending';
export const OWNERS_CONTACT_REQUEST_RECEIVED = 'owners_contact_received';
export const OWNERS_CONTACT_REQUEST_ERROR = 'owners_contact_request_error';

export const SAVE_ACCEPTANCE_REQUEST_PENDING = 'save_acceptance_pending';
export const SAVE_ACCEPTANCE_REQUEST_RECEIVED = 'save_acceptance_received';
export const SAVE_ACCEPTANCE_REQUEST_ERROR = 'save_acceptance_request_error';

export const SAVE_ATTESTATION_REQUEST_PENDING = 'save_attestation_pending';
export const SAVE_ATTESTATION_REQUEST_RECEIVED = 'save_attestation_received';
export const SAVE_ATTESTATION_REQUEST_ERROR = 'save_attestation_request_error';

export const SAVE_OWNERSHIP_REQUEST_PENDING = 'save_ownership_pending';
export const SAVE_OWNERSHIP_REQUEST_RECEIVED = 'save_ownership_received';
export const SAVE_OWNERSHIP_REQUEST_ERROR = 'save_ownership_request_error';

export const SET_CONTROLLING_MANAGER = 'set_controlling_manager';
export const ERROR_WHILE_PROCESSING_OWNERSHIP = 'error_while_processing_ownership';
export const OWNERSHIP_SAVED_SUCCESSFULLY = 'ownership_saved_successfully';

export const SAVE_ADDITIONAL_SIGNERS_REQUEST_PENDING = 'save_additional_signers_request_pending';
export const SAVE_ADDITIONAL_SIGNERS_RECEIVED = 'save_additional_signers_received';
export const SAVE_ADDITIONAL_SIGNERS_REQUEST_ERROR = 'save_additional_signers_request_error';
export const CLEAR_ADDITIONAL_SIGNERS_FULFILLED = 'clear_additional_signers_fulfilled';

export const GET_POSSIBLE_SIGNERS_REQUEST_PENDING = 'get_possible_signers_request_pending';
export const GET_POSSIBLE_SIGNERS_RECEIVED = 'get_possible_signers_received';
export const GET_POSSIBLE_SIGNERS_REQUEST_ERROR = 'get_possible_signers_request_error';
