import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  BodyText, Button, Container, Link,
} from '@swift-paypal/pp-react';
import { useRedirect, useShallowSelector } from '../../../../hooks';
import { setActiveAdvanceId } from '../../../../redux/actions/active-ids/active-ids-actions';
import { getAdvancesAction } from '../../../../redux/actions/advances/advances-actions';
import { getOrCreateLoanForgivenessAction } from '../../../../redux/actions/loan-forgiveness/loan-forgiveness-actions';
import heartInHand from '../../../../static/images/icons/heart-in-hand.svg';
import { evaluateAndResolveApplicationPath } from '../forgiveness-summary-tile.service';
import { ForYouCard } from '../../../features/forYou/forYouCard/forYouCard';
import {
  APPLY,
  FORGIVENESS_MESSAGE_PART_1,
  FORGIVENESS_MESSAGE_PART_2,
  FORGIVENESS_MESSAGE_PART_3,
  FORGIVENESS_MESSAGE_PART_4,
  FORGIVENESS_MESSAGE_PART_5,
  LEARN_MORE,
  LEARN_MORE_URL_LINK,
  REDESIGN_FORGIVENESS_MESSAGE,
  REDESIGN_FORGIVENESS_SUBTEXT,
  GET_STARTED,
  REDESIGN_HEADER_TEXT,
} from './forgiveness-standard-messaging-constants';
import { forgivenessStandardMessagingSelector } from './forgiveness-standard-messaging-selectors';
import { fillLoanYear } from './forgivenessStandardMessaging.utils';
import './forgiveness-standard-messaging.less';

const ForgivenessStandardMessaging = ({ advanceId, setIsFirstTimeApplying, isFirstTimeApplying }) => {
  const {
    advancesById,
    loanForgiveness,
    portalRedesignEnabled,
  } = useShallowSelector(forgivenessStandardMessagingSelector);
  const dispatch = useDispatch();
  const redirectCallback = useRedirect();

  useEffect(() => {
    const loanForgivenessId = loanForgiveness.allIds.find((id) => loanForgiveness.byId[id].advanceId === advanceId);
    const forgivenessObject = loanForgiveness.byId[loanForgivenessId];
    if (isFirstTimeApplying && forgivenessObject) {
      const { applicationVersionSelected } = forgivenessObject;
      dispatch(getAdvancesAction());
      evaluateAndResolveApplicationPath({ applicationVersionSelected, ezQuestionnaireCompleted, redirectCallback });
    }
  }, [loanForgiveness, isFirstTimeApplying]);

  const forgivenessEligibleAdvance = advancesById[advanceId];

  const { ezQuestionnaireCompleted, loanVersion } = forgivenessEligibleAdvance || {};

  const applyNowOnClickHandler = () => {
    if (!forgivenessEligibleAdvance.loanForgivenessId) {
      dispatch(getOrCreateLoanForgivenessAction(forgivenessEligibleAdvance.id));
    }
    dispatch(setActiveAdvanceId(advanceId));
    setIsFirstTimeApplying(true);
  };

  if (!forgivenessEligibleAdvance) {
    return (<></>);
  }

  if (portalRedesignEnabled) {
    return (
      <ForYouCard
        applicationLabel={REDESIGN_HEADER_TEXT}
        cardTitle={fillLoanYear(REDESIGN_FORGIVENESS_MESSAGE, loanVersion)}
        description={fillLoanYear(REDESIGN_FORGIVENESS_SUBTEXT, loanVersion)}
        callToActionFunction={() => {}}
        callToActionText={GET_STARTED}
      />
    );
  }

  return (
    <Container className="forgiveness-tile" fluid>
      <div className="forgiveness-tile__header">
        <img alt="Heart in hand" className="heart-in-hand" src={heartInHand} />
        <BodyText className="ppp-fund-text" strong>
          {fillLoanYear(FORGIVENESS_MESSAGE_PART_1, loanVersion)}
        </BodyText>
      </div>
      <div className="forgiveness-tile__body">
        <BodyText as="p">
          {fillLoanYear(FORGIVENESS_MESSAGE_PART_2, loanVersion)}
        </BodyText>
        <BodyText as="p">
          {FORGIVENESS_MESSAGE_PART_3}
          {' '}
          <Link
            className="pp-react__link--inline"
            href={LEARN_MORE_URL_LINK}
            target="_blank"
            rel="noopener noreferrer"
            data-automation-id="pppLoanInfoLoanbuilderLink"
          >
            {LEARN_MORE}
          </Link>
          .
        </BodyText>
        <BodyText as="p">
          {FORGIVENESS_MESSAGE_PART_4}
        </BodyText>
        <BodyText as="p">
          {FORGIVENESS_MESSAGE_PART_5}
        </BodyText>
        <Button
          size="sm"
          className="apply-now-btn"
          onClick={applyNowOnClickHandler}
        >
          {APPLY}
        </Button>
      </div>
    </Container>
  );
};

ForgivenessStandardMessaging.propTypes = {
  advanceId: PropTypes.string.isRequired,
  setIsFirstTimeApplying: PropTypes.func.isRequired,
  isFirstTimeApplying: PropTypes.bool.isRequired,
};

export default ForgivenessStandardMessaging;
