import * as actions from '../actions/reset-password/reset-password-action-types';
import constants from '../../constants';
import { AlertTypes } from '../../components/common/alert';

const { errors } = constants;

const resetPassword = (state = {}, action) => {
  switch (action.type) {
    case actions.RESET_PASSWORD_RECEIVED:
      return {
        ...state, resetPasswordSuccess: true, messageBody: undefined, messageType: undefined,
      };
    case actions.RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        messageBody: action.payload.response.data.message,
        messageType: AlertTypes.critical,
      };
    case actions.CLEAR_RESET_PASSWORD:
      return { ...state, messageBody: undefined, messageType: undefined };
    case actions.SECURITY_QUESTION_WITH_TOKEN_REQUESTED:
      return state;
    case actions.SECURITY_QUESTION_WITH_TOKEN_RECEIVED:
      return { ...state, question: action.question };
    case actions.SECURITY_QUESTION_WITH_TOKEN_REQUEST_ERROR:
      return {
        ...state,
        messageBody: errors.resetPasswordErrorMessage(state.config?.customerSupportPhone),
        messageType: AlertTypes.critical,
      };
    case actions.UPDATE_RESET_PASSWORD_MESSAGE:
      return { ...state, messageBody: action.messageBody, messageType: action.messageType };
    case actions.CLEAR_RESET_PASSWORD_MESSAGE:
      return { ...state, messageBody: undefined, messageType: undefined };
    case actions.SET_RESET_TOKEN:
      return { ...state, resetToken: action.resetToken };
    default:
      return state;
  }
};

export default resetPassword;
