import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../logo/logo';

const LoggedOutHeader = ({ country }) => (
  <div className="portal_header">
    <div className="vx_globalNav-main logged-out vx_hidden-phone" role="banner">
      <div className="vx_globalNav-container">
        <Logo country={country} />
      </div>
    </div>
  </div>
);

LoggedOutHeader.propTypes = {
  country: PropTypes.string,
};

LoggedOutHeader.defaultProps = {
  country: undefined,
};

export default LoggedOutHeader;
