import { ppp21LoanVersions } from '../../summary/forgiveness-summary-tile/forgiveness-summary-tile-constants';

export const forgivenTypes = {
  full: 'fullyForgiven',
  partial: 'partiallyForgiven',
};

export const stageContent = (loanVersion) => ({
  'Pending SBA Submission': {
    titleText: 'We are preparing your application for submission to the SBA for review',
    messageText: 'We will let you know once your application has been submitted to the SBA for review.',
    amountLabel: 'Requested forgiveness amount',
  },
  'Awaiting SBA Response': {
    titleText: 'We\'ve submitted your loan forgiveness application to the SBA',
    messageText: 'The SBA has up to 90 days to complete its review.',
    amountLabel: 'Requested forgiveness amount',
  },
  Declined: {
    titleText: 'The SBA has not approved loan forgiveness for your PPP Loan',
    messageText: `
      We will send you an email with details, including your outstanding loan balance,
      first payment due date and monthly payment amount.
    `,
  },
  fullyForgiven: {
    titleText: 'Congratulations, your loan has been fully forgiven!',
    messageText: ppp21LoanVersions.includes(loanVersion) ? 'You won\'t have to make any repayments, and there\'s nothing else you need to do. You will receive a separate email confirming your loan forgiveness amount and details.'
      : 'You won\'t have to make any repayments, and there\'s nothing else you need to do.',
    amountLabel: 'Loan forgiveness amount',
  },
  partiallyForgiven: {
    messageText: `
      Your PPP Loan balance will be adjusted to reflect the remaining amount you have to repay.
      We will send you an email with details, including your remaining loan balance,
      first payment due date and monthly payment amount.
    `,
  },
});
