import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet-async';
import AnonymousPage from '../hocs/anonymous-page';
import displayNames from '../constants/displayNames';
import ForgotPasswordFormComponent from '../components/forgot-password-form/forgot-password-form';
import ForgotPasswordRevamp from '../components/forgot-password-revamp';
import { forgotPasswordAction } from '../redux/actions/auth/auth-actions';

class ForgotPasswordPage extends React.Component {
  static displayName = displayNames.ForgotPasswordPage;

  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    forgotPasswordError: PropTypes.string,
    loginRevamp: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    forgotPasswordError: undefined,
  };

  submit = (values) => {
    const { forgotPassword, match } = this.props;
    forgotPassword({ ...values, match });
  };

  render() {
    const { forgotPasswordError, loginRevamp } = this.props;
    return (
      <>
        <Helmet>
          <title>Forgot LoanBuilder Password</title>
        </Helmet>
        {loginRevamp
          ? (<ForgotPasswordRevamp submitFunc={this.submit} forgotPasswordError={forgotPasswordError} />)
          : (
            <ForgotPasswordFormComponent
              submitFunc={this.submit}
              forgotPasswordError={forgotPasswordError}
            />
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bfes: state.accountItems.bfes,
    forgotPasswordError: state.forgotPassword.error,
    loginRevamp: state.config.loginRevamp,
  };
}

export default connect(
  mapStateToProps,
  { forgotPassword: forgotPasswordAction },
)(AnonymousPage(ForgotPasswordPage));
