import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ScrollToHOC } from 'react-scroll-to';
import { Button } from '@swift-paypal/pp-react';
import UploadDocumentsComponent from '../../common/upload-documents/upload-documents';
import './loan-agreement.less';
import contractIcon from '../../../static/images/icons/contract_icon.svg';
import errors from '../../../constants/errors';
import { Alert, AlertTypes } from '../../common/alert';
import LoanProcessing from '../loan-state/loan-processing';
import VoidLoanAgreement from '../loan-state/void-loan-agreement';
import { displayContractAction, uploadContractAction } from '../../../redux/actions/contract/contract-actions';
import { getLoanAgreementStatusAction } from '../../../redux/actions/account-items/account-items-actions';
import { maxBytesPerFile, maxBytesTotal } from '../../../constants/contract';

const allowedFileExtensions = ['jpg', 'gif', 'png', 'pdf'];

class LoanAgreementComponent extends Component {
  static propTypes = {
    completionStatus: PropTypes.string,
    displayContract: PropTypes.func.isRequired,
    getLoanAgreementStatus: PropTypes.func.isRequired,
    opportunityId: PropTypes.string,
    scrollTo: PropTypes.func.isRequired,
    uploadContract: PropTypes.func.isRequired,
    uwReviewStatus: PropTypes.string,
  }

  static defaultProps = {
    completionStatus: undefined,
    opportunityId: undefined,
    uwReviewStatus: undefined,
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    uploadedFiles: [],
  }

  componentDidMount() {
    const { getLoanAgreementStatus } = this.props;
    getLoanAgreementStatus();
  }

  uploadFiles = async (files) => {
    const {
      getLoanAgreementStatus,
      opportunityId,
      scrollTo,
      uploadContract,
    } = this.props;

    await uploadContract(opportunityId, files);
    scrollTo({ x: 0, y: 0 });
    await getLoanAgreementStatus();
  }

  getContract = async () => {
    const { displayContract, opportunityId } = this.props;
    await displayContract(opportunityId);
  }

  render() {
    const { uploadedFiles } = this.state;
    const {
      completionStatus,
      uwReviewStatus,
    } = this.props;

    if (completionStatus === 'Voided') {
      return <VoidLoanAgreement />;
    }

    if (uwReviewStatus === 'Needs Review') {
      return <LoanProcessing />;
    }

    return (
      <div className="loan-agreement">
        { uwReviewStatus === 'Rejected'
        && (
        <Alert
          message={errors.loanAgreementWarning}
          alertType={AlertTypes.warning}
          dataAutomationId="loanAgreementWarningAlert"
          additionalClass="alertbox"
        />
        )}
        <div className="number">1</div>
        <h4>Your loan agreement is ready to sign.</h4>
        <img className="left" src={contractIcon} alt="Contract icon" />
        <div className="step-one-copy">
          <p>
            Almost done! Once we review and approve your loan agreement,
            you will receive funding in 1 to 2 business days.
          </p>
          <p>
            Print your loan agreement to sign.
          </p>
          <Button
            type="submit"
            className="print-button pp-react__button--block center-align"
            onClick={() => this.getContract()}
          >
            Print loan agreement
          </Button>
        </div>
        <div className="number">2</div>
        <h4>Upload your signed loan agreement</h4>
        <p>
          After signing your loan agreement, upload it here. Make sure all fields
          in the document are complete, including:
        </p>
        <ul>
          <li>Your bank account information</li>
          <li>Your signature</li>
          <li>Any additional signatures requested</li>
        </ul>
        <UploadDocumentsComponent
          allowedFileExtensions={allowedFileExtensions}
          maximumPerFileSizeInBytes={maxBytesTotal}
          maximumTotalSizeInBytes={maxBytesPerFile}
          customClassName="center-align"
          submitFunc={this.uploadFiles}
        />
        {uploadedFiles.length > 0 && (
          <div>
            <h1>Files uploaded:</h1>
            <ul>
              {uploadedFiles.map((file) => <li key={file.name}>{file.name}</li>)}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  displayContract: displayContractAction,
  getLoanAgreementStatus: getLoanAgreementStatusAction,
  uploadContract: uploadContractAction,
};

function mapStateToProps(state) {
  return {
    completionStatus: state.loanAgreementStatus.completionStatus,
    uwReviewStatus: state.loanAgreementStatus.uwReviewStatus,
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  ScrollToHOC,
)(LoanAgreementComponent);
