import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { required, email } from 'redux-form-validators';
import { Button } from '@swift-paypal/pp-react';
import withUrlParams from '../../../hocs/with-url-params';
import floatingLabelField from '../../../lib/forms/floating-label-field';
import { trimWhitespace } from '../../../lib/forms/normalizations';
import '../login.less';
import './login-form.less';
import { Alert } from '../../common/alert';

const LoginForm = (props) => {
  const {
    submitFunc, handleSubmit, message, type, country,
  } = props;

  if (!country) {
    return null;
  }

  return (
    <form className="login-form" onSubmit={handleSubmit(submitFunc)}>
      <div className="vx_form-group-hiddenLabel fluid-right-margin">
        {message
        && (
        <Alert
          message={message}
          alertType={type}
        />
        )}
        <Field
          name="email"
          manualSet
          className="form-control vx_form-control"
          component={floatingLabelField}
          type="text"
          label="Email address"
          noErrorClass="margin-bottom-sm"
          validate={[required({ msg: 'Email address is required' }), email({ msg: 'Invalid email address' })]}
          normalize={trimWhitespace}
        />
        <Field
          name="password"
          manualSet
          className="form-control vx_form-control do-not-record"
          component={floatingLabelField}
          type="password"
          label="Password"
          noErrorClass="margin-bottom-sm"
          validate={[required({ msg: 'Password is required' })]}
        />
        <Link to={`/${country}/forgot-password`} className="vx_anchor forgot-password-margin">Forgot Password?</Link>
        <br />
        <br />
        <Button type="submit" className="loan-builder-login">
          <span className="no-padding lb-logo inline-block" />
          <span className="loan-builder-login-text inline-block">Continue with LoanBuilder</span>
        </Button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  country: PropTypes.string,
  handleSubmit: PropTypes.func,
  message: PropTypes.string,
  submitFunc: PropTypes.func.isRequired,
  type: PropTypes.string,
};

LoginForm.defaultProps = {
  country: undefined,
  handleSubmit: undefined,
  message: undefined,
  type: undefined,
};

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(LoginForm);
