import React from 'react';
import PropTypes from 'prop-types';
import { CaptionText } from '@swift-paypal/pp-react';

const ShowHideButton = ({ show, toggleShow }) => (
  <CaptionText>
    <button
      className="button-link ppvx_link show-hide-button"
      onClick={toggleShow}
      type="button"
    >
      {!show ? 'Show' : 'Hide'}
    </button>
  </CaptionText>
);

ShowHideButton.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
};

export default ShowHideButton;
