import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  required, confirmation, email, length,
} from 'redux-form-validators';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import resolve from '../../../services/route.service';
import { Alert } from '../../common/alert';
import floatingLabelField from '../../../lib/forms/floating-label-field';
import routes from '../../../routes';
import withUrlParams from '../../../hocs/with-url-params';
import constants from '../../../constants';

const { errors } = constants;

const ChangeEmailForm = ({
  handleSubmit,
  messageBody,
  messageType,
  country,
}) => {
  const history = useHistory();

  return (
    <div className="change-password-form form">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={10}>
            {messageBody && <Alert message={messageBody} alertType={messageType} />}
            <Field
              name="newEmail"
              manualSet
              className="form-control vx_form-control"
              component={floatingLabelField}
              type="email"
              label="New email address"
              autocomplete="off"
              noErrorClass="margin-bottom-sm"
              validate={[email({ msg: errors.invalidEmail }), length({
                max: 75,
                msg: errors.emailLength,
              })]}
            />
            <Field
              name="confirmEmail"
              manualSet
              className="form-control vx_form-control"
              component={floatingLabelField}
              type="email"
              label="Confirm email address"
              autocomplete="off"
              noErrorClass="margin-bottom-sm"
              validate={[
                confirmation({
                  field: 'newEmail',
                  fieldLabel: 'Email',
                  message: errors.emailsMustMatch,
                }),
                required({ msg: errors.requiredField }),
                length({ max: 75, msg: errors.emailLength }),
              ]}
            />
            <Field
              name="password"
              manualSet
              className="form-control vx_form-control do-not-record"
              component={floatingLabelField}
              type="password"
              label="Password"
              autocomplete="new-password"
              noErrorClass="margin-bottom-sm"
              validate={[required({ msg: errors.requiredField })]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={5} md={4}>
            <Button type="submit" className="pp-react__button--block">
              Continue
            </Button>
          </Col>
          <Col xs={6} sm={5} md={4}>
            <Button
              secondary
              type="button"
              className="pp-react__button--block"
              onClick={() => history.push(resolve(routes.settings.path, { country }))}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

ChangeEmailForm.propTypes = {
  country: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  messageBody: PropTypes.string,
  messageType: PropTypes.string,
};

ChangeEmailForm.defaultProps = {
  handleSubmit: undefined,
  messageBody: undefined,
  messageType: undefined,
};

const ChangeEmailFormComponent = reduxForm({
  form: 'change-email',
})(ChangeEmailForm);

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(ChangeEmailFormComponent);
