import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { DEAUTHORIZED } from '../actions/auth/auth-action-types';
import accountItems from './account-items-reducers';
import { activeIds } from './active-ids-reducers';
import config from './config-reducers';
import contactUs from './contact-us-reducers';
import country from './country-reducers';
import entities from './entities-reducer';
import settings from './settings-reducers';
import {
  authenticate,
  authorize,
  securityQuestion,
  securityQuestions,
  forgotPassword,
  updatePassword,
  createSecurityQuestions,
  resetToken,
} from './auth-reducers';
import notifications from './notifications-reducers';
import privacy from './privacy-reducers';
import isRenewalEligible from './is-renewal-eligible-reducers';
import loanAgreementStatus from './loan-agreement-status';
import resetPassword from './reset-password-reducers';
import businessProperty from './business-property-reducers';
import apiHealth from './maintenance-reducers';
import loginPage from './index-page-reducers';
import redirectUrls from './redirect-urls-reducers';
import ownership from './application/ownership-reducers';
import terms from './terms-reducers';
import uploadDocument from './upload-document-reducers';
import {
  getBankInformation,
  getRoutingInstitution,
  saveBankInformation,
  addBankInformation,
} from './application/verify-bank-accounts-reducers';
import { stipulations, fulfillStipulation, newStipulationFlag } from './stipulation-reducers';
import {
  userSetEZAppEligible, forgivenessForm, updateLoanForgiveness,
} from './loan-forgiveness-reducers';
import isGuarantyClaimAvailable from './guaranty-claims-reducers';
import { selfServiceLoan } from './one-time-payment-reducers';

// super sensitive data, will be destroyed on DEAUTHORIZED action
// use it for user data and everything that requires 'authorized' token
const authorizedStateDomain = {
  accountItems,
  activeIds,
  authorize,
  businessProperty,
  contactUs,
  entities,
  forgivenessForm,
  forgotPassword,
  fulfillStipulation,
  getBankInformation,
  getRoutingInstitution,
  isRenewalEligible,
  loanAgreementStatus,
  notifications,
  ownership,
  saveBankInformation,
  addBankInformation,
  settings,
  stipulations,
  updateLoanForgiveness,
  uploadDocument,
  userSetEZAppEligible,
  isGuarantyClaimAvailable,
  newStipulationFlag,
  selfServiceLoan,
};

// less sensitive data, will be destroyed on DEAUTHORIZED action
// use it for special workflows that involve using 'authenticated' token only
const authenticatedStateDomain = {
  authenticate,
  securityQuestion,
  securityQuestions,
};

// non-sensitive data, keep alive until hard reload
const anonymousStateDomain = {
  apiHealth,
  config,
  country,
  createSecurityQuestions,
  indexPage: loginPage,
  privacy,
  redirectUrls,
  resetPassword,
  resetToken,
  terms,
  updatePassword,

  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form: formReducer,
};

const appReducer = (history) => combineReducers({
  // DO NOT ADD ANYTHING HERE, ADD YOUR REDUCER TO A DOMAIN ABOVE
  ...authorizedStateDomain,
  ...authenticatedStateDomain,
  ...anonymousStateDomain,
  router: connectRouter(history),
  // DO NOT ADD ANYTHING HERE, ADD YOUR REDUCER TO A DOMAIN ABOVE
});

export default (history) => (state, action) => {
  let storeToReturn;

  if (action.type === DEAUTHORIZED) {
    storeToReturn = { ...state };
    const destroyKey = (key) => delete storeToReturn[key];

    // clear authorizedStateDomain
    const authorizedStateDomainKeys = Object.keys(authorizedStateDomain);
    authorizedStateDomainKeys.forEach(destroyKey);

    // clear authorizedStateDomain
    const authenticatedStateDomainKeys = Object.keys(authenticatedStateDomain);
    authenticatedStateDomainKeys.forEach(destroyKey);
  }

  return appReducer(history)(storeToReturn || state, action);
};
