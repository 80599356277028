import React from 'react';
import PropTypes from 'prop-types';

const Percentage = (props) => {
  const { number, dataAutomationId } = props;

  if (!number || number === 0) {
    return <span />;
  }

  const formattedNumber = parseFloat(Math.round(number * 100) / 100).toFixed(2);
  return (
    <span data-automation-id={dataAutomationId}>
      {formattedNumber}
      %
    </span>
  );
};

Percentage.propTypes = {
  number: PropTypes.number,
  dataAutomationId: PropTypes.string,
};

Percentage.defaultProps = {
  number: undefined,
  dataAutomationId: undefined,
};

export default Percentage;
