export const GET_BANK_INFORMATION_REQUEST_PENDING = 'get_bank_information_request_pending';
export const GET_BANK_INFORMATION_RECEIVED = 'get_bank_informations_received';
export const GET_BANK_INFORMATION_REQUEST_ERROR = 'get_bank_information_request_error';

export const GET_ROUTING_INSTITUTION_REQUEST_PENDING = 'get_routing_institution_request_pending';
export const GET_ROUTING_INSTITUTION_RECEIVED = 'get_routing_institution_received';
export const GET_ROUTING_INSTITUTION_REQUEST_ERROR = 'get_routing_institution_request_error';

export const BANK_ACCOUNT_SAVE_PENDING = 'bank_account_save_pending';
export const BANK_ACCOUNT_SAVE_RECEIVED = 'bank_account_save_received';
export const BANK_ACCOUNT_SAVE_ERROR = 'bank_account_save_error';

export const BANK_ACCOUNT_ADD_PENDING = 'bank_account_add_pending';
export const BANK_ACCOUNT_ADD_RECEIVED = 'bank_account_add_received';
export const BANK_ACCOUNT_ADD_ERROR = 'bank_account_add_error';
export const BANK_ACCOUNT_ADD_RESET_ERROR = 'bank_account_add_reset_error';

export const GET_ROUTING_INSTITUTION_LIST_RECEIVED = 'get_routing_institution_list_received';
export const GET_ROUTING_INSTITUTION_LIST_REQUEST_ERROR = 'get_routing_institution_list_request_error';
