export default {
  afghanistan: 'AF',
  alandIslands: 'AX',
  albania: 'AL',
  algeria: 'DZ',
  americanSamoa: 'AS',
  andorra: 'AD',
  angola: 'AO',
  anguilla: 'AI',
  antarctica: 'AQ',
  antiguaAndBarbuda: 'AG',
  argentina: 'AR',
  armenia: 'AM',
  aruba: 'AW',
  australia: 'AU',
  austria: 'AT',
  azerbaijan: 'AZ',
  bahamas: 'BS',
  bahrain: 'BH',
  bangladesh: 'BD',
  barbados: 'BB',
  belarus: 'BY',
  belgium: 'BE',
  belize: 'BZ',
  benin: 'BJ',
  bermuda: 'BM',
  bhutan: 'BT',
  bolivia: 'BO',
  bosniaAndHerzegovina: 'BA',
  botswana: 'BW',
  bouvetIsland: 'BV',
  brazil: 'BR',
  britishIndianOceanTerritory: 'IO',
  bruneiDarussalam: 'BN',
  bulgaria: 'BG',
  burkinaFaso: 'BF',
  burundi: 'BI',
  cambodia: 'KH',
  cameroon: 'CM',
  canada: 'CA',
  capeVerde: 'CV',
  caymanIslands: 'KY',
  centralAfricanRepublic: 'CF',
  chad: 'TD',
  chile: 'CL',
  china: 'CN',
  christmasIsland: 'CX',
  cocosKeelingIslands: 'CC',
  colombia: 'CO',
  comoros: 'KM',
  congo: 'CG',
  congoDemocraticRepublic: 'CD',
  cookIslands: 'CK',
  costaRica: 'CR',
  coteDIvoire: 'CI',
  croatia: 'HR',
  cuba: 'CU',
  cyprus: 'CY',
  czechRepublic: 'CZ',
  denmark: 'DK',
  djibouti: 'DJ',
  dominica: 'DM',
  dominicanRepublic: 'DO',
  ecuador: 'EC',
  egypt: 'EG',
  elSalvador: 'SV',
  equatorialGuinea: 'GQ',
  eritrea: 'ER',
  estonia: 'EE',
  eswatini: 'SZ',
  ethiopia: 'ET',
  falklandIslands: 'FK',
  faroeIslands: 'FO',
  fiji: 'FJ',
  finland: 'FI',
  france: 'FR',
  frenchGuiana: 'GF',
  frenchPolynesia: 'PF',
  frenchSouthernTerritories: 'TF',
  gabon: 'GA',
  gambia: 'GM',
  georgia: 'GE',
  germany: 'DE',
  ghana: 'GH',
  gibraltar: 'GI',
  greece: 'GR',
  greenland: 'GL',
  grenada: 'GD',
  guadeloupe: 'GP',
  guam: 'GU',
  guatemala: 'GT',
  guernsey: 'GG',
  guinea: 'GN',
  guineaBissau: 'GW',
  guyana: 'GY',
  haiti: 'HT',
  heardIslandMcdonaldIslands: 'HM',
  holySeeVaticanCityState: 'VA',
  honduras: 'HN',
  hongKong: 'HK',
  hungary: 'HU',
  iceland: 'IS',
  india: 'IN',
  indonesia: 'ID',
  iran: 'IR',
  iraq: 'IQ',
  ireland: 'IE',
  isleOfMan: 'IM',
  israel: 'IL',
  italy: 'IT',
  jamaica: 'JM',
  japan: 'JP',
  jersey: 'JE',
  jordan: 'JO',
  kazakhstan: 'KZ',
  kenya: 'KE',
  kiribati: 'KI',
  korea: 'KR',
  kuwait: 'KW',
  kyrgyzstan: 'KG',
  laoPeoplesDemocraticRepublic: 'LA',
  latvia: 'LV',
  lebanon: 'LB',
  lesotho: 'LS',
  liberia: 'LR',
  libyanArabJamahiriya: 'LY',
  liechtenstein: 'LI',
  lithuania: 'LT',
  luxembourg: 'LU',
  macao: 'MO',
  macedonia: 'MK',
  madagascar: 'MG',
  malawi: 'MW',
  malaysia: 'MY',
  maldives: 'MV',
  mali: 'ML',
  malta: 'MT',
  marshallIslands: 'MH',
  martinique: 'MQ',
  mauritania: 'MR',
  mauritius: 'MU',
  mayotte: 'YT',
  mexico: 'MX',
  micronesia: 'FM',
  moldova: 'MD',
  monaco: 'MC',
  mongolia: 'MN',
  montenegro: 'ME',
  montserrat: 'MS',
  morocco: 'MA',
  mozambique: 'MZ',
  myanmar: 'MM',
  namibia: 'NA',
  nauru: 'NR',
  nepal: 'NP',
  netherlands: 'NL',
  netherlandsAntilles: 'AN',
  newCaledonia: 'NC',
  newZealand: 'NZ',
  nicaragua: 'NI',
  niger: 'NE',
  nigeria: 'NG',
  niue: 'NU',
  norfolkIsland: 'NF',
  northernMarianaIslands: 'MP',
  norway: 'NO',
  oman: 'OM',
  pakistan: 'PK',
  palau: 'PW',
  palestinianTerritory: 'PS',
  panama: 'PA',
  papuaNewGuinea: 'PG',
  paraguay: 'PY',
  peru: 'PE',
  philippines: 'PH',
  pitcairn: 'PN',
  poland: 'PL',
  portugal: 'PT',
  puertoRico: 'PR',
  qatar: 'QA',
  reunion: 'RE',
  romania: 'RO',
  russianFederation: 'RU',
  rwanda: 'RW',
  saintBarthelemy: 'BL',
  saintHelena: 'SH',
  saintKittsAndNevis: 'KN',
  saintLucia: 'LC',
  saintMartin: 'MF',
  saintPierreAndMiquelon: 'PM',
  saintVincentAndGrenadines: 'VC',
  samoa: 'WS',
  sanMarino: 'SM',
  saoTomeAndPrincipe: 'ST',
  saudiArabia: 'SA',
  senegal: 'SN',
  serbia: 'RS',
  seychelles: 'SC',
  sierraLeone: 'SL',
  singapore: 'SG',
  slovakia: 'SK',
  slovenia: 'SI',
  solomonIslands: 'SB',
  somalia: 'SO',
  southAfrica: 'ZA',
  southGeorgiaAndSandwichIsl: 'GS',
  spain: 'ES',
  sriLanka: 'LK',
  sudan: 'SD',
  suriname: 'SR',
  svalbardAndJanMayen: 'SJ',
  sweden: 'SE',
  switzerland: 'CH',
  syrianArabRepublic: 'SY',
  taiwan: 'TW',
  tajikistan: 'TJ',
  tanzania: 'TZ',
  thailand: 'TH',
  timorLeste: 'TL',
  togo: 'TG',
  tokelau: 'TK',
  tonga: 'TO',
  trinidadAndTobago: 'TT',
  tunisia: 'TN',
  turkey: 'TR',
  turkmenistan: 'TM',
  turksAndCaicosIslands: 'TC',
  tuvalu: 'TV',
  uganda: 'UG',
  ukraine: 'UA',
  unitedArabEmirates: 'AE',
  unitedKingdom: 'GB',
  unitedStates: 'US',
  unitedStatesOutlyingIslands: 'UM',
  uruguay: 'UY',
  uzbekistan: 'UZ',
  vanuatu: 'VU',
  venezuela: 'VE',
  vietNam: 'VN',
  virginIslandsBritish: 'VG',
  virginIslandsUS: 'VI',
  wallisAndFutuna: 'WF',
  westernSahara: 'EH',
  yemen: 'YE',
  zambia: 'ZM',
  zimbabwe: 'ZW',
};
