/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container, BodyText, Row, Alert, Col, TextInput, Button, ContextualAlert,
} from '@paypalcorp/pp-react';
import { ResponsiveHeadingText } from '../../components/responsiveTypography';
import displayNames from '../../../../constants/displayNames';
import { OneTimePaymentContext } from '../../OneTimePaymentContext';
import { PaymentComponentMapping, bankErrorAlertMapping } from '../../paymentComponentMappings';
import oneTimePaymentContants from '../../../../constants/oneTimePayments';
import {
  selfServiceBankAddAction,
  getSelfServicePaymentBankListActionForModal,
} from '../../../../redux/actions/one-time-payment/one-time-payment';
import { useShallowSelector, oneTimePaymentSelector } from '../../one-time-payment-selectors';
import PaymentModalLoader from '../../components/paymentModalLoader';
import ChequeExample from '../../../../static/images/icons/Check-Example.svg';
import './addBank.less';

const LOADER_EDIT_BANK_TEXT = 'We are updating your business bank account';
const LOADER_ADD_BANK_TEXT = 'We are adding your business bank account';

const AddBankInfo = () => {
  const {
    setVisibleComponent,
    bankInfo,
    updateBankInfoContext,
    updateBankErrorContext,
    bankValidationError,
    selectedLoanAdvance,
    setCurrentBank,
    setBankErrorVisited,
    bankErrorVisited,
    editData,
    bankValidationWarning,
    checkRo3Warning,
  } = useContext(OneTimePaymentContext);

  const {
    bankUpdateStatus, bankUpdateMessage, bankUpdateStatusCode, bankUpdatedDetails,
  } = useShallowSelector(oneTimePaymentSelector);
  const { BankInfoConstants: bankInfoConstants, BankInfoErrorFields: bankErrorFields } = oneTimePaymentContants;
  const handleCancelEvent = () => {
    setVisibleComponent(PaymentComponentMapping.MakeAPayment);
  };
  const dispatch = useDispatch();
  const createBankApiRequestPayload = () => {
    const {
      ACHRoutingNumber,
      BankAccountNumber,
      BusinessName,
      City,
      State,
      StreetAddress1,
      StreetAddress2,
      Zip,
    } = bankInfo;
    const payload = {
      AdvanceId: selectedLoanAdvance.Id,
      BankAccounts: [
        {
          BankAccountId: editData.isEditMode ? editData.fiData?.Id ?? '' : '',
          BusinessName,
          ACHRoutingNumber,
          BankAccountNumber,
          StreetAddress1,
          StreetAddress2,
          State,
          City,
          Zip,
        },
      ],
    };
    return { payload, isEditMode: editData.isEditMode };
  };

  const addBankClickHandler = (event) => {
    event.preventDefault();
    setBankErrorVisited(false);
    const validationArray = [
      bankInfoConstants.BankAccount.ACHRoutingNumber,
      bankInfoConstants.BankAccount.ReACHRoutingNumber,
      bankInfoConstants.BankAccount.BankAccountNumber,
      bankInfoConstants.BankAccount.ReBankAccountNumber,
    ];
    const isErrorFree = updateBankErrorContext(validationArray);
    if (isErrorFree) {
      const reqPayload = createBankApiRequestPayload();
      dispatch(selfServiceBankAddAction(reqPayload));
    }
  };

  const isNumberWithoutDecimalPattern = useCallback((inputString) => {
    const regex = /^[0-9]+$/;
    return regex.test(inputString) && !inputString.endsWith('.');
  });

  // check ro3 warning on landing
  useEffect(() => {
    const prevFilledAchValue = bankInfo[bankInfoConstants.BankAccount.ACHRoutingNumber];
    if (prevFilledAchValue !== '') {
      checkRo3Warning(bankInfoConstants.BankAccount.ACHRoutingNumber, prevFilledAchValue);
    }
  }, []);

  useEffect(() => {
    if (bankUpdateStatus === 'success') {
      if (bankUpdatedDetails !== undefined) {
        setCurrentBank(bankUpdatedDetails);
      }
      dispatch(getSelfServicePaymentBankListActionForModal(selectedLoanAdvance.Id));
      setVisibleComponent(PaymentComponentMapping.MakeAPayment);
    }
    else if (bankUpdateStatus === 'error' && !bankErrorVisited) {
      if (bankErrorAlertMapping.AddBusinessInfo.includes(bankUpdateStatusCode)) {
        setVisibleComponent(PaymentComponentMapping.AddBusinessInfo);
        setBankErrorVisited(true);
      }
    }
  }, [bankUpdateStatus, bankErrorVisited, bankUpdateStatusCode]);
  return (
    <>
      {bankUpdateStatus === 'pending' ? (
        <PaymentModalLoader
          paymentLoaderText={editData.isEditMode ? LOADER_EDIT_BANK_TEXT : LOADER_ADD_BANK_TEXT}
        />
      ) : (
        <div>
          {bankUpdateStatus === 'error'
          && bankErrorAlertMapping.AddBankInfo.includes(bankUpdateStatusCode) ? (
            <Alert type="error">{bankUpdateMessage}</Alert>
            ) : null}
          <ResponsiveHeadingText desktop="lg" mobile="sm" className="heading">
            {editData.isEditMode
              ? 'Confirm Business Bank Information'
              : 'Add a New Business Bank Account'}
          </ResponsiveHeadingText>
          <form>
            <Container form className="add-container">
              <Row>
                <Col form="full">
                  <BodyText strong>Bank Info</BodyText>
                </Col>
              </Row>
              <Row>
                <Col form="full">
                  <img src={ChequeExample} alt="cheque-example" className="cheque-icon" />
                </Col>
              </Row>
              <Row />
              <Row>
                <Col form="full">
                  <TextInput
                    name="ach-routing-number"
                    maxLength="9"
                    value={bankInfo[bankInfoConstants.BankAccount.ACHRoutingNumber]}
                    errorText={
                      bankValidationError[bankErrorFields.BankAccount.ACHRoutingNumberError]
                    }
                    onBlur={(evt) => checkRo3Warning(bankInfoConstants.BankAccount.ACHRoutingNumber, evt.target.value)}
                    onChange={(evt) => updateBankInfoContext(
                      bankInfoConstants.BankAccount.ACHRoutingNumber,
                      evt.target.value,
                      isNumberWithoutDecimalPattern,
                    )}
                    label="ACH Routing Number"
                    placeholder="ACH Routing Number"
                  />
                  {bankValidationWarning.AchRoutingNumberWarning && <ContextualAlert id="routing-warning-ach" type="warning">{bankValidationWarning.AchRoutingNumberWarning}</ContextualAlert>}
                </Col>
              </Row>
              <Row>
                <Col form="full">
                  <TextInput
                    maxLength="9"
                    name="re-ach-routing-number"
                    value={bankInfo[bankInfoConstants.BankAccount.ReACHRoutingNumber]}
                    errorText={
                      bankValidationError[bankErrorFields.BankAccount.ReACHRoutingNumberError]
                    }
                    onChange={(evt) => updateBankInfoContext(
                      bankInfoConstants.BankAccount.ReACHRoutingNumber,
                      evt.target.value,
                      isNumberWithoutDecimalPattern,
                    )}
                    label="Re-enter ACH Routing Number"
                    placeholder="Re-enter ACH Routing Number"
                  />
                </Col>
              </Row>
              <Row>
                <Col form="full">
                  <TextInput
                    maxLength="25"
                    inputClassName="do-not-record"
                    name="business-bank-account-number"
                    value={bankInfo[bankInfoConstants.BankAccount.BankAccountNumber]}
                    errorText={
                      bankValidationError[bankErrorFields.BankAccount.BankAccountNumberError]
                    }
                    onChange={(evt) => updateBankInfoContext(
                      bankInfoConstants.BankAccount.BankAccountNumber,
                      evt.target.value,
                      isNumberWithoutDecimalPattern,
                    )}
                    label="Business Bank Account Number"
                    placeholder="Business Bank Account Number"
                  />
                </Col>
              </Row>
              <Row>
                <Col form="full">
                  <TextInput
                    name="re-business-bank-account-number"
                    maxLength="25"
                    inputClassName="do-not-record"
                    value={bankInfo[bankInfoConstants.BankAccount.ReBankAccountNumber]}
                    errorText={
                      bankValidationError[bankErrorFields.BankAccount.ReBankAccountNumberError]
                    }
                    onChange={(evt) => updateBankInfoContext(
                      bankInfoConstants.BankAccount.ReBankAccountNumber,
                      evt.target.value,
                      isNumberWithoutDecimalPattern,
                    )}
                    label="Re-enter Business Bank Account Number"
                    placeholder="Re-enter Business Bank Account Number"
                  />
                </Col>
              </Row>
            </Container>
            <Row align="center">
              <Col form="auto">
                <Button type="submit" onClick={addBankClickHandler}>
                  {editData.isEditMode ? 'Confirm' : 'Add Bank'}
                </Button>
              </Col>
            </Row>
            {!editData.isEditMode && (
              <Row align="center">
                <Col form="auto">
                  <Button tertiary onClick={handleCancelEvent}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
          </form>
        </div>
      )}
    </>
  );
};

AddBankInfo.displayName = displayNames.AddBankInfo;
export default AddBankInfo;
